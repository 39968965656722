import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import postIntervalSegmentGraphYnApi from '../api/postIntervalSegmentGraphYn.api';

export interface PostSegmentGraphYnThunkResult {
	id: number;
}

export interface PostSegmentGraphYnThunkProps {
	segmentId: number;
}

interface PostSegmentGraphYnThunkError {
	id: number;
	// eslint-disable-next-line
	error: any;
}

export const postIntervalSegmentGraphYnThunk = createAsyncThunk<
	PostSegmentGraphYnThunkResult,
	PostSegmentGraphYnThunkProps,
	{ state: RootState; rejectValue: PostSegmentGraphYnThunkError }
>('sectionSegment/graphYn/post', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId } = args;

	try {
		await postIntervalSegmentGraphYnApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			segId: segmentId,
		});

		return { id: segmentId };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ id: segmentId, error: error.response.data });
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { LANG_PATH } from 'common/data/constants';

import { changeIsAskModalVisible } from 'common/context/modalSlice';
import { toggleIsHelpMenuVisible } from 'common/context/layoutSlice';

const SaasHelpMenu = forwardRef<HTMLDivElement>((_, ref) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isFreetrialNotiVisible, language } = useSelector((state: RootState) => state.account);

	const { isHelpMenuVisible } = useSelector((state: RootState) => state.layout);

	const askClickHandler = () => {
		dispatch(changeIsAskModalVisible());
		dispatch(toggleIsHelpMenuVisible());
	};

	const documentClickHandler = () => {
		dispatch(toggleIsHelpMenuVisible());

		if (language === 'KOREAN') {
			window.open('https://app.archbee.com/public/PREVIEW-1S-PNQ3_nKKf6X3NAl_nd');
			return;
		}

		window.open('https://app.archbee.com/public/PREVIEW-Bz-n9CZknDmJxjwMgHzRC');
	};

	const aboutSurffyClickHandler = () => {
		dispatch(toggleIsHelpMenuVisible());
		window.open(`${process.env.REACT_APP_LANDING_URL}/${LANG_PATH[language]}`, '_blank');
	};

	if (!isHelpMenuVisible) return null;

	return (
		<Portal id="help-menu">
			<div css={container(isFreetrialNotiVisible)} ref={ref}>
				<div css={noBorderBox}>
					<div css={menuSt} aria-hidden onClick={documentClickHandler}>
						{t('console.sidebar.documents')}
					</div>
					<div css={menuSt} aria-hidden onClick={aboutSurffyClickHandler}>
						About Surffy
					</div>
				</div>
				<div css={menuBox}>
					<div css={menuSt} aria-hidden onClick={askClickHandler}>
						{t('console.sidebar.ask')}
					</div>
				</div>
			</div>
		</Portal>
	);
});

export default SaasHelpMenu;

const container = (isFreetrialNotiVisible: boolean) => css`
	position: absolute;

	top: ${isFreetrialNotiVisible ? '120px' : '56px'};
	right: 56px;

	width: 192px;
	height: 136px;

	padding: 8px 0;

	border: 1px solid ${paletteSDS.gray['200']};

	border-radius: 4px;
	background-color: ${paletteSDS.gray['50']};

	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);

	z-index: 1000;
`;

const noBorderBox = css`
	width: 100%;
	height: fit-content;
`;

const menuBox = css`
	width: 100%;
	height: fit-content;

	border-top: 1px solid ${paletteSDS.gray['200']};
`;

const menuSt = css`
	display: flex;
	align-items: center;

	height: 40px;

	padding: 16px;

	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['700']};

	cursor: pointer;

	:hover {
		background: ${paletteSDS.gray['100']};
	}
`;

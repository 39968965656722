/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { paletteSDS, CopyIcon } from 'surf-design-system';

interface Props {
	text: string;
	width: string;
}

export default function TextCopy(props: Props) {
	const { text, width } = props;
	return (
		<div css={container(width)}>
			<div css={textBox}>{text}</div>
			<div css={iconBox}>
				<CopyIcon
					size="xSmall"
					fill={paletteSDS.gray[700]}
					onClick={e => {
						e.stopPropagation();
						navigator.clipboard.writeText(text);
					}}
				/>
			</div>
		</div>
	);
}

const container = (width: string) => css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid ${paletteSDS.gray[200]};
	border-radius: 2px;
	width: ${width};
	height: 24px;
	padding: 4px 6px;
	background-color: ${paletteSDS.gray[75]};
`;

const textBox = css`
	width: calc(100% - 28px);
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${paletteSDS.gray[700]};
	font-size: 12px;
	font-weight: 400;
	line-height: 100%;
`;

const iconBox = css`
	width: 16px;
	height: 16px;

	& > svg {
		&:hover {
			fill: ${paletteSDS.blue[600]};
		}
	}
`;

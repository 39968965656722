/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
// etc
import { backgroundPalette, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

export default function PlanOptionName() {
	const { t } = useTranslation();

	const bigBoxCss = [bigBoxSt, colorPalette.black, backgroundPalette.gray[200]];

	const smallBoxCss = [smallBoxSt, colorPalette.black, backgroundPalette.gray[200]];

	const memberBoxCss = [memberBoxSt, colorPalette.black, backgroundPalette.gray[200]];

	return (
		<div
			css={[
				themeCss.fontSize[13],
				colorPalette.gray[800],
				themeCss.fontWeight.regular,
				borderBottomLeft,
				borderTopLeft,
			]}
		>
			<div css={[titleBoxSt, backgroundPalette.gray[200], borderTopLeft]} />
			<div css={bigBoxCss}>
				<div css={themeCss.fontWeight.bold}>MAU</div>
				<div>{t('planSubscription.monthlyAU')}</div>
			</div>
			<div css={bigBoxCss}>
				<div css={themeCss.fontWeight.bold}>MWU</div>
				<div>{t('planSubscription.monthlyWU')}</div>
			</div>
			<div css={bigBoxCss}>
				<div css={themeCss.fontWeight.bold}>Peak CCU</div>
				<div>{t('planSubscription.peakCCU')}</div>
			</div>
			<div css={[smallBoxCss, themeCss.fontWeight.bold]}>{t('planSubscription.vwr1')}</div>
			<div css={memberBoxCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.basic')}</div>
			<div css={memberBoxCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.path')}</div>
			<div css={[smallBoxCss, themeCss.fontWeight.bold]}>{t('planSubscription.members')}</div>
			<div css={memberBoxCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.admin')}</div>
			<div css={memberBoxCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.operator')}</div>
			<div css={[memberBoxCss]}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.monitor')}</div>
			<div css={bigBoxCss}>
				<div css={themeCss.fontWeight.bold}>EUM</div>
				<div>End User Monitoring</div>
			</div>
		</div>
	);
}

const borderTopLeft = css`
	border-top-left-radius: 7px;
`;

const borderBottomLeft = css`
	border-bottom-left-radius: 7px;
`;

const titleBoxSt = css`
	width: 250px;
	height: 48px;
`;

const bigBoxSt = css`
	display: flex;
	flex-direction: column;
	justify-content: center;

	gap: 4px;

	width: 250px;
	height: 64px;

	padding-left: 16px;

	border-top: 1px solid ${paletteSDS.gray[300]};
`;

const smallBoxSt = css`
	width: 250px;
	height: 32px;

	line-height: 32px;

	padding-left: 16px;

	border-top: 1px solid ${paletteSDS.gray[300]};
`;

const memberBoxSt = css`
	width: 250px;
	height: 32px;

	line-height: 32px;

	padding-left: 16px;
`;

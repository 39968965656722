/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { addNumberComma } from 'features/plan/utils/formatFunc';

export interface URLDashboardSummaryCardProps {
	title?: React.ReactNode;
	averageCount: number | string;
	maxCount: number | string;
	averageProcessTime: number | string;
	maxProcessTime: number | string;
}

export default function URLDashboardSummaryCard({
	title,
	averageCount,
	averageProcessTime,
	maxProcessTime,
	maxCount,
}: URLDashboardSummaryCardProps) {
	const { t } = useTranslation();

	let averageCountContent: string;
	let maxCountContent: string;
	let averageProcessTimeContent: string;
	let maxProcessTimeContent: string;

	if (typeof averageCount === 'number') {
		averageCountContent = addNumberComma(averageCount.toString());
	} else {
		averageCountContent = averageCount;
	}

	if (typeof maxCount === 'number') {
		maxCountContent = addNumberComma(maxCount.toString());
	} else {
		maxCountContent = maxCount;
	}

	if (typeof averageProcessTime === 'number') {
		averageProcessTimeContent = (averageProcessTime / 1000).toFixed(1);
	} else {
		averageProcessTimeContent = averageProcessTime;
	}

	if (typeof maxProcessTime === 'number') {
		maxProcessTimeContent = (maxProcessTime / 1000).toFixed(1);
	} else {
		maxProcessTimeContent = maxProcessTime;
	}

	return (
		<div css={container}>
			{title}
			<div css={summaryBoxSt}>
				<div css={averageMaxBox}>
					<div css={itemValueBox}>
						<div css={summaryItem}>{t('eum.averageCount')}</div>
						<div css={summaryValue}>{averageCountContent}</div>
					</div>
					<div css={itemValueBox}>
						<div css={summaryItem}>{t('eum.maxCount')}</div>
						<div css={summaryValue}>{maxCountContent}</div>
					</div>
				</div>
				<div css={averageMaxBox}>
					<div css={itemValueBox}>
						<div css={summaryItem}>{t('eum.averageProcessTime')}</div>
						<div css={summaryValue}>{averageProcessTimeContent}</div>
					</div>
					<div css={itemValueBox}>
						<div css={summaryItem}>{t('eum.maxProcessTime')}</div>
						<div css={summaryValue}>{maxProcessTimeContent}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 360px;
	height: 248px;

	padding-top: 8px;
	padding-right: 24px;
`;

const averageMaxBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const summaryBoxSt = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const itemValueBox = css`
	display: flex;
	flex-direction: column;
	gap: 6px;
	padding-left: 24px;
`;

const summaryItem = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['700']};
	line-height: 128%;
`;

const summaryValue = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['20']};
	color: ${paletteSDS.blue['900']};
`;

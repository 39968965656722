import axios, { AxiosError } from 'axios';

import { userStatusType, productType, userRoleType } from 'common/types/getOrganizationList.type';
import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutMemberPermissionApiResponse } from 'features/setting/types/putMemberPermission.v2.onPrem.type';

export interface PutMemberPermissionApiProps {
	organizationId: number;
	memberKey: string;
	state: userStatusType;
	product: productType;
	role: userRoleType;
}

export default async function putMemberPermissionApi({
	organizationId,
	memberKey,
	state,
	product,
	role,
}: PutMemberPermissionApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken) {
		const { data } = await axios.put<PutMemberPermissionApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/organization/member/permission`,
			{ userKey: memberKey, state, productType: product, role },
			{
				headers: {
					Authorization: cognitoToken,
					organizationId,
					userKey,
				},
			}
		);
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

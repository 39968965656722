/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import type { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';

import { getSpeedStatus } from 'features/nfBasic/utils/funnelFunc';
import ProjectSummaryInfoLine from 'features/nfBasic/components/ProjectSummaryInfoLine';
import FunnelTextProject from 'features/nfBasic/components/FunnelTextProject';
import { calcOutflowRate } from 'features/nfInterval/utils/monitoringDataFunc';
import NetFunnelProjectClassic from './NetFunnelProjectClassic';

const { gray, systemColor } = paletteSDS;
const { fontWeight, fontSize } = themeSDS;

export interface IntervalProjectSummaryClassicProps {
	subTitle: string;
}

export default function PathControlProjectClassic() {
	const { t } = useTranslation();

	const { isLoaded, totalTickets, maxNumbersWaiting, maxProcessTime, maxWaitTime, classic } = useSelector(
		(state: RootState) => state.nfControl.project
	);

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { activatedYn } = projectList[selectedProjectIndex];

	if (!isLoaded) return <div css={projectSummaryClassicCss} />;

	const speedStatus = getSpeedStatus(classic.wait_tm);

	const outflowRate = calcOutflowRate(classic.complete_succ, classic.chk_enter_succ);

	return (
		<div css={projectSummaryClassicCss}>
			<div css={titleWrap}>
				<div css={titleCss}>{t('projectMonitoring.project')}</div>
			</div>

			<div css={bodyCss}>
				<NetFunnelProjectClassic funnelData={classic} speedStatus={speedStatus} disabled={!activatedYn} />
				<FunnelTextProject
					name={[t('projectMonitoring.waitTicketsClassic1'), t('projectMonitoring.waitTicketsClassic2')]}
					value={activatedYn ? classic.new_key_chk_enter : classic.bypass}
					tooltipText={t('projectMonitoring.ttlWaitTicketsTooltip')}
					flexJustify="flex-end"
					top={0}
					right={0}
				/>
				<FunnelTextProject
					disabled={!activatedYn}
					name={[t('projectMonitoring.queue')]}
					value={classic.wait_user}
					flexJustify="flex-end"
					top={60}
					right={0}
					textColor={paletteSDS.blue[400]}
				/>
				<div css={dashedNormalLineCss} />
				<FunnelTextProject
					disabled={!activatedYn}
					name={[t('projectMonitoring.waitTimeClassic1'), t('projectMonitoring.waitTimeClassic2')]}
					tooltipName={[t('projectMonitoring.waitTime')]}
					value={classic.wait_tm}
					top={92}
					left={0}
					unit="s"
					decimal={1}
				/>
				<FunnelTextProject
					name={[t('projectMonitoring.inflow')]}
					disabled={!activatedYn}
					value={classic.chk_enter_succ}
					tooltipText={t('projectMonitoring.ttlInflowTooltip')}
					flexJustify="flex-end"
					top={145}
					right={0}
				/>
				<div css={dashedBrokenLineCss} />
				<FunnelTextProject
					name={[t('projectMonitoring.limitedInflowClassic1'), t('projectMonitoring.limitedInflowClassic2')]}
					value={classic.limit}
					flexJustify="flex-end"
					top={210}
					right={3}
				/>
				<FunnelTextProject
					disabled={!activatedYn}
					name={[t('projectMonitoring.outflow')]}
					value={classic.complete_succ}
					flexJustify="flex-end"
					top={380}
					right={-2}
				/>
				<FunnelTextProject
					disabled={!activatedYn}
					name={[t('projectMonitoring.outflowRateClassic1'), t('projectMonitoring.outflowRateClassic2')]}
					value={outflowRate}
					top={400}
					left={0}
					unit="%"
				/>
			</div>

			<div css={dividerWrap}>
				<DividerWithTxt dividerColor={paletteSDS.gray[200]} />
			</div>

			<div css={bottomCss}>
				<ul css={infoBoxCss}>
					<ProjectSummaryInfoLine label={`${t('projectMonitoring.totalWaitTickets')}`} value={totalTickets} />
					<ProjectSummaryInfoLine
						label={`${t('projectMonitoring.maxWaitTime')}(s)`}
						value={maxWaitTime}
						disabled={!activatedYn}
					/>
					<ProjectSummaryInfoLine
						label={t('projectMonitoring.maxQueue')}
						value={maxNumbersWaiting}
						disabled={!activatedYn}
					/>
					<ProjectSummaryInfoLine
						label={`${t('projectMonitoring.maxPrcsTime')}(s)`}
						value={maxProcessTime}
						disabled={!activatedYn}
					/>
				</ul>
			</div>
		</div>
	);
}

const projectSummaryClassicCss = css`
	position: relative;
	width: 265px;
	height: 804px;
	padding: 24px;
	background-color: ${systemColor.white};
	border: 1px solid ${gray[300]};
	border-radius: 4px;
`;

const dividerWrap = css`
	margin: 16px 0;
`;

const dashedNormalLineCss = css`
	position: absolute;
	width: 106px;
	top: 115px;
	left: 55px;
	height: 1px;
	border-top: 1px dashed ${paletteSDS.gray[300]};
	z-index: 1001;
`;

const dashedBrokenLineCss = css`
	position: absolute;
	top: 203px;
	right: -3px;
	width: 57px;
	height: 168px;
	border-top: 1px dashed ${paletteSDS.gray[300]};
	border-right: 1px dashed ${paletteSDS.gray[300]};
	border-bottom: 1px dashed ${paletteSDS.gray[300]};
	border-radius: 4px;
`;

const titleWrap = css`
	display: flex;
	flex-direction: column;
	gap: 9px;
`;

const titleCss = css`
	height: 24px;
	margin-bottom: 15px;
	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.semiBold};
	line-height: 128%;
	letter-spacing: -0.008em;
`;

const infoBoxCss = css`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const bodyCss = css`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 447px;
`;

const bottomCss = css`
	width: 100%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

// components
import { paletteSDS, themeSDS, Button, AddIcon } from 'surf-design-system';

import Tooltip from 'common/components/Tooltip';

// etc
import { RootState, useAppDispatch } from 'common/redux/store';
import { setIsSegmentAddModalVisible, setIsSegmentMaxNumModalVisible } from 'common/context/modalSlice';
import SegmentGraphModal from 'features/nfBasic/components/SegmentMaxGraphModal';
import OnPremSegmentMaxNumModal from 'features/nfControl/components/OnPremSegmentMaxNumModal';
import getProjectSegmentCheckThunk from 'features/nfControl/thunk/getProjectSegmentCheck.v2.onPrem.thunk';

const { fontWeight, fontSize } = themeSDS;
const { gray, systemColor } = paletteSDS;

export default function OnPremBasicControlSegmentLegend() {
	const dispatch = useAppDispatch();

	const { userRole } = useSelector((state: RootState) => state.account);

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { activatedYn } = projectList[selectedProjectIndex];

	const { t } = useTranslation();

	const legendHeader = {
		segmentTitle: `${t('commonWords.segTitle')}`,
		segmentKey: t('commonWords.segKey'),
		segmentStatus: `${t('projectMonitoring.segSts')}`,
	};

	const segmentLegendList = [
		{
			id: 1,
			name: `${t('projectMonitoring.waitTickets')}`,
			tooltip: `${t('projectMonitoring.ttlWaitTicketsTooltip')}`,
		},
		{ id: 2, name: `${t('projectMonitoring.queue')}` },
		{
			id: 3,
			name: `${t('projectMonitoring.waitTime')}(${t('commonUnit.sec')})`,
		},
		{
			id: 4,
			name: `${t('projectMonitoring.inflow')}`,
			tooltip: `${t('projectMonitoring.ttlInflowTooltip')}`,
		},
		{ id: 5, name: `${t('projectMonitoring.limitedInflow')}` },
		{ id: 6, name: `${t('projectMonitoring.resourceUsageRate')}` },
		{ id: 7, name: `${t('projectMonitoring.outflowRate')}` },
		{
			id: 8,
			name: `${t('projectMonitoring.prcsTime')}(${t('commonUnit.sec')})`,
		},
	];

	const addButtonClickHandler = async () => {
		const segCheckAction = await dispatch(getProjectSegmentCheckThunk());
		const segCheck = unwrapResult(segCheckAction);
		if (segCheck.creatableYn) {
			dispatch(setIsSegmentAddModalVisible(true));
			return;
		}

		dispatch(setIsSegmentMaxNumModalVisible(true));
	};

	return (
		<div css={SegmentLegendCss}>
			<div css={segmentTitleCss}>{legendHeader.segmentTitle}</div>
			<div css={segmentTitleCss}>{legendHeader.segmentKey}</div>
			<div css={segmentStatusCss}>{legendHeader.segmentStatus}</div>
			<ul css={nameListCss}>
				{segmentLegendList.map((legend, index) => (
					<li css={legendCss} key={legend.id}>
						<div css={nameCss(legend.tooltip, index !== 0 && index !== 4 && !activatedYn)}>{legend.name}</div>
						{legend.tooltip && <Tooltip message={legend.tooltip} disabled={!activatedYn} position="top" />}
					</li>
				))}
			</ul>
			<div css={buttonWrapperCss}>
				<Button
					size="small"
					icon={<AddIcon fill={gray[50]} />}
					iconPosition="left"
					width="140px"
					onClick={addButtonClickHandler}
					disabled={userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR'}
				>
					{t('projectMonitoring.add')}
				</Button>
			</div>
			<OnPremSegmentMaxNumModal />
			<SegmentGraphModal />
		</div>
	);
}

const SegmentLegendCss = css`
	height: 100%;
	width: 188px;
	min-width: 188px;
	padding: 2px 16px 10px 16px;
	border: 1px solid ${gray[300]};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: ${systemColor.white};
	color: ${gray[700]};
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;
`;

const segmentTitleCss = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 32px;
`;

const segmentStatusCss = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 32px;
`;

const nameListCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 296px;
`;

const legendCss = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const nameCss = (hasTooltip: string | undefined, disabled: boolean) => css`
	margin-right: ${hasTooltip ? '0.3125rem' : ''};
	white-space: nowrap;
	color: ${disabled && gray['300']};
`;

const buttonWrapperCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 12px;
`;

import { createSlice } from '@reduxjs/toolkit';

import type { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';
import { getSubscriptionListThunk } from 'features/plan/thunk/getSubscriptionList.v2.thunk';

export interface SurffySubscriptionData {
	// eslint-disable-next-line
	error: any;
	isLoaded: boolean;
	products: SubscriptionData[];
}

const initialState: SurffySubscriptionData = {
	isLoaded: false,
	error: null,
	products: [],
};

const subscriptionSlice = createSlice({
	name: 'subscriptionInfo',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(getSubscriptionListThunk.fulfilled, (state, action) => {
			return { ...state, error: null, isLoaded: true, products: action.payload.products.slice() };
		});

		builder.addCase(getSubscriptionListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoaded: true };
		});
	},
});

export default subscriptionSlice.reducer;

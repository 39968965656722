import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import getProjectSegmentCheckApi from 'features/nfControl/api/getProjectSegmentCheck.v2.onPrem.api';
import { SegmentCheckData } from 'features/nfControl/type/getProjectSegmentCheck.v2.onPrem.type';

const getProjectSegmentCheckThunk = createAsyncThunk<SegmentCheckData, void, { state: RootState }>(
	'get/project/segment/check/v2',
	async (_, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
			projectInfo: { selectedProjectId },
		} = thunkAPI.getState();

		try {
			const { data } = await getProjectSegmentCheckApi({
				tenantId: tenantId as string,
				organizationId,
				projectId: selectedProjectId,
			});

			return data;

			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export default getProjectSegmentCheckThunk;

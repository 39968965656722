/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { Outlet } from 'react-router-dom';

import SaasSNB from 'common/components/SaasSNB';
import Footer from 'common/components/Footer';

export default function SaasProductLayout() {
	return (
		<div css={container}>
			<div css={main}>
				<SaasSNB />
				<div css={outletContainer}>
					<Outlet />
				</div>
			</div>
			<Footer />
		</div>
	);
}

const container = css`
	flex: auto;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
`;

const main = css`
	flex: auto;
	display: flex;
	width: 100%;
`;

const outletContainer = css`
	width: 100%;
	height: 100%;
	overflow-x: auto;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import type { RootState } from 'common/redux/store';

import useLineChartOptions, { lineTooltipCss } from 'features/nfBasic/hooks/useLineChartOptions';
import RealTimeLineChart from 'features/nfBasic/components/RealtimeLineChart';
import { processTimeDecimalInFloat } from 'features/nfBasic/data/constants';

import ChartBox from 'features/nfControl/components/ChartBox';

const { fontSize, fontWeight } = themeSDS;
const { gray, systemColor } = paletteSDS;

interface ProcessTimeChartProps {
	isClassic?: boolean;
}

export default function BasicControlProcessTimeChart({ isClassic = false }: ProcessTimeChartProps) {
	const { t } = useTranslation();

	const processTimeGraphY = useSelector(
		(state: RootState) =>
			state.nfControl.segment.basic.value
				.filter(el => el.segmentGraphYn)
				.map(el => ({
					name: el.segmentName,
					data: el.processTimeY,
					color: el.segmentColor,
				})),
		shallowEqual
	);

	const {
		meta,
		value: segmentValue,
		isValueLoaded,
		isAnimate,
	} = useSelector((state: RootState) => state.nfControl.segment.basic);

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const projectMetaIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const processTimeSeries =
		segmentValue.length !== 0 && processTimeGraphY.length === 0
			? segmentValue.map(noData => ({
					name: noData.segmentName,
					data: noData.processTimeY.map(el => ({ ...el, y: null })),
					color: noData.segmentColor,
			  }))
			: processTimeGraphY;

	const maxY = useSelector((state: RootState) =>
		Math.max(...state.nfControl.segment.basic.value.map(el => el.maxProcessTime))
	);

	const { timeZone } = useSelector((state: RootState) => state.account);

	const segmentGraphYExist = meta.reduce((prev, curr) => prev || curr.segmentGraphYn, false);

	const { apexLineOption: processTimeChartOption } = useLineChartOptions({
		maxY: Math.max(maxY, 8),
		unit: t('commonUnit.sec'),
		timeZone,
		decimalsInFloat: processTimeDecimalInFloat,
		isAnimate,
		isForceNiceTrim: true,
	});

	if (!isValueLoaded)
		return (
			<ChartBox isClassic={isClassic}>
				<div />
			</ChartBox>
		);

	if (!projectList[projectMetaIndex].activatedYn) {
		return (
			<ChartBox isClassic={isClassic}>
				<div css={processTimeLineChartCss(isClassic, segmentGraphYExist)}>
					<div css={chartHeaderCss}>
						<span css={titleCss}>{t('projectMonitoring.prcsTime')}</span>
						<span css={unitCss}>({t('commonUnit.sec')})</span>
					</div>
					<div css={chartBodyCss}>
						<p>{t('projectMonitoring.projectDeactivated')}</p>
					</div>
				</div>
			</ChartBox>
		);
	}

	return (
		<ChartBox isClassic={isClassic}>
			<div css={processTimeLineChartCss(isClassic, segmentGraphYExist)}>
				<div css={chartHeaderCss}>
					<span css={titleCss}>{t('projectMonitoring.prcsTime')}</span>
					<span css={unitCss}>({t('commonUnit.sec')})</span>
				</div>
				<div css={chartBodyCss}>
					{segmentValue.length < 1 ? (
						<p>{t('projectMonitoring.defaultMsg')}</p>
					) : (
						<RealTimeLineChart options={processTimeChartOption} series={processTimeSeries} />
					)}
				</div>
			</div>
		</ChartBox>
	);
}

const processTimeLineChartCss = (isClassic: boolean, showTooltip: boolean) => css`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: ${isClassic ? 330 : 398}px;
	padding: 24px;
	background-color: ${systemColor.white};
	border-radius: 4px;
	${lineTooltipCss(showTooltip)}
`;

const chartHeaderCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const titleCss = css`
	font-weight: 600;
	font-size: ${fontSize[16]};
	line-height: 100%;
`;

const unitCss = css`
	color: ${gray[700]};
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;
`;

const chartBodyCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	color: ${gray[600]};
`;

import { validationSDS } from 'surf-design-system';
import { rNumEnSC } from '../utils/regex';

export interface ChangePasswordForm {
	currentPassword: string;
	currentPasswordValidation: validationSDS;
	isCurrentPasswordValid: boolean;
	isCurrentPasswordChanged: boolean;
	currentPasswordHelpText: string;
	password: string;
	passwordValidation: validationSDS;
	isPasswordValid: boolean;
	isPasswordChanged: boolean;
	verifyPassword: string;
	verifyPasswordValidation: validationSDS;
	isVerifyPasswordChanged: boolean;
	isVerifyPasswordValid: boolean;
	passwordHelpText: string;
	verifyPasswordHelpText: string;
}

export interface ChangePasswordFormAction {
	type?: string;
	payload?: string;
}

export const initialChangePasswordForm: ChangePasswordForm = {
	currentPassword: '',
	currentPasswordValidation: 'failed',
	isCurrentPasswordChanged: false,
	isCurrentPasswordValid: false,
	currentPasswordHelpText: 'singnUp.errorPwdMsg',
	password: '',
	passwordValidation: 'failed',
	isPasswordChanged: false,
	passwordHelpText: 'singnUp.errorPwdMsg',
	verifyPassword: '',
	isVerifyPasswordValid: false,
	isVerifyPasswordChanged: false,
	verifyPasswordValidation: 'failed',
	isPasswordValid: false,
	verifyPasswordHelpText: 'resetPassword.error.tryAgain',
};

export const UPDATE_CURRENT_PASSWORD = 'UPDATE_CURRENT_PASSWORD';
export const VALIDATE_CURRENT_PASSWORD = 'VALIDATE_CURRENT_PASSWORD';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';

export const UPDATE_VERIFY_PASSWORD = 'UPDATE_VERIFY_PASSWORD';
export const VALIDATE_VERIFY_PASSWORD = 'VALIDATE_VERIFY_PASSWORD';

export const UPDATE_PW_HELP_TEXT = 'UPDATE_PW_HELP_TEXT';
export const UPDATE_CURRENT_PW_HELP_TEXT = 'UPDATE_CURRENT_PW_HELP_TEXT';

export const changePasswordFormReducer = (
	state: ChangePasswordForm,
	action: ChangePasswordFormAction
): ChangePasswordForm => {
	switch (action.type) {
		case UPDATE_PASSWORD: {
			const isPasswordPayloadValid = rNumEnSC.test(action.payload ? action.payload : '');

			const isPasswordPayloadSameWithCurrentPassword = state.isCurrentPasswordChanged
				? state.currentPassword === action.payload
				: false;

			const isPasswordPayloadSameWithVerifyPassword = state.verifyPassword === action.payload;

			let passwordHelpText = 'singnUp.errorPwdMsg';

			if (isPasswordPayloadValid && isPasswordPayloadSameWithCurrentPassword) {
				passwordHelpText = 'resetPassword.error.samePassword';
			}

			return {
				...state,
				password: action.payload ? action.payload : '',
				passwordValidation: isPasswordPayloadValid && !isPasswordPayloadSameWithCurrentPassword ? 'none' : 'failed',
				isPasswordChanged: true,
				isPasswordValid: isPasswordPayloadValid && !isPasswordPayloadSameWithCurrentPassword,
				passwordHelpText,
				isVerifyPasswordValid: state.isVerifyPasswordChanged
					? isPasswordPayloadSameWithVerifyPassword
					: state.isVerifyPasswordValid,
				verifyPasswordValidation: isPasswordPayloadSameWithVerifyPassword ? 'none' : 'failed',
			};
		}

		case UPDATE_VERIFY_PASSWORD: {
			const isVerifyPasswordPayloadValid = action.payload === state.password;
			return {
				...state,
				verifyPassword: action.payload ? action.payload : '',
				verifyPasswordValidation: isVerifyPasswordPayloadValid ? 'none' : 'failed',
				isVerifyPasswordChanged: true,
				isVerifyPasswordValid: isVerifyPasswordPayloadValid,
			};
		}

		case UPDATE_CURRENT_PASSWORD: {
			const isCurrentPasswordPayloadValid = (action.payload || '').trim().length > 0;

			const isCurrentPasswordPayloadSameWithPassword = state.password === action.payload;

			const isPasswordStateFormValid = rNumEnSC.test(state.password);

			let passwordStateHelpText = '';

			if (isCurrentPasswordPayloadSameWithPassword) {
				passwordStateHelpText = 'resetPassword.error.samePassword';
			}

			if (!isPasswordStateFormValid) {
				passwordStateHelpText = 'singnUp.errorPwdMsg';
			}

			return {
				...state,
				currentPassword: action.payload || '',
				currentPasswordValidation: isCurrentPasswordPayloadValid ? 'none' : 'failed',
				isCurrentPasswordChanged: true,
				isCurrentPasswordValid: isCurrentPasswordPayloadValid,
				currentPasswordHelpText: '',
				isPasswordValid: state.isPasswordChanged
					? isPasswordStateFormValid && !isCurrentPasswordPayloadSameWithPassword
					: false,
				passwordValidation: isPasswordStateFormValid && !isCurrentPasswordPayloadSameWithPassword ? 'none' : 'failed',
				passwordHelpText: passwordStateHelpText,
			};
		}

		case VALIDATE_CURRENT_PASSWORD: {
			const isCurrentPasswordStateValid = state.currentPassword.trim().length > 0;

			return {
				...state,
				isCurrentPasswordValid: state.isCurrentPasswordChanged ? isCurrentPasswordStateValid : false,
				currentPasswordValidation: state.isCurrentPasswordChanged && isCurrentPasswordStateValid ? 'none' : 'failed',
			};
		}

		case VALIDATE_PASSWORD: {
			const isPasswordStateValid = rNumEnSC.test(state.password);

			const isPasswordStateSameWithCurrentPassword = state.password === state.currentPassword;

			let passwordStateHelpText = '';

			if (isPasswordStateSameWithCurrentPassword) {
				passwordStateHelpText = 'resetPassword.error.samePassword';
			}

			if (!isPasswordStateValid) {
				passwordStateHelpText = 'singnUp.errorPwdMsg';
			}

			return {
				...state,
				isPasswordValid: state.isPasswordChanged
					? isPasswordStateValid && !isPasswordStateSameWithCurrentPassword
					: false,
				passwordValidation:
					state.isPasswordChanged && isPasswordStateValid && !isPasswordStateSameWithCurrentPassword
						? 'none'
						: 'failed',
				passwordHelpText: passwordStateHelpText,
			};
		}

		case VALIDATE_VERIFY_PASSWORD: {
			const isVerifyPasswordStateValid = state.password === state.verifyPassword;

			return {
				...state,
				isVerifyPasswordValid: state.isVerifyPasswordChanged ? isVerifyPasswordStateValid : false,
				verifyPasswordValidation: state.isVerifyPasswordChanged && isVerifyPasswordStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_PW_HELP_TEXT: {
			return {
				...state,
				isPasswordValid: false,
				passwordValidation: 'failed',
				passwordHelpText: action.payload ? action.payload : '',
			};
		}

		case UPDATE_CURRENT_PW_HELP_TEXT: {
			return {
				...state,
				isCurrentPasswordValid: false,
				currentPasswordValidation: 'failed',
				currentPasswordHelpText: action.payload ? action.payload : '',
			};
		}

		default:
			return initialChangePasswordForm;
	}
};

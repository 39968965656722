import { validationSDS } from 'surf-design-system';
import { rName } from 'features/vwr/utils/regex';

export interface VWRNameForm {
	enteredName: string;
	isEnteredNameChanged: boolean;
	isEnteredNameValid: boolean;
	enteredNameValidation: validationSDS;
	enteredNameHelpText: string;
}

export interface ProfileFormAction {
	type?: string;
	payload?: string;
}

export const initialNameForm: VWRNameForm = {
	enteredName: '',
	isEnteredNameChanged: false,
	isEnteredNameValid: false,
	enteredNameHelpText: '',
	enteredNameValidation: 'failed',
};

export const UPDATE_NAME = 'UPDATE_NAME';
export const VALIDATE_NAME = 'VALIDATE_NAME';
export const UPDATE_NAME_HELP_TEXT = 'UPDATE_NAME_HELP_TEXT';

export const vwrNameFormReducer = (state: VWRNameForm, action: ProfileFormAction): VWRNameForm => {
	switch (action.type) {
		case UPDATE_NAME: {
			const isNamePayloadNotEmpty = (action.payload || '').trim().length > 0;

			const isNamePayloadFormatValid = rName.test(action.payload as string);

			const namePayloadWithoutDefault = (action.payload as string)
				.replace('Waiting Room', '')
				.replace('대기창', '')
				.trim();

			const isNamePayloadWithoutDefaultNumber = !Number.isNaN(Number(namePayloadWithoutDefault));

			const isNamePayloadDefaultForm =
				(!(action.payload as string).includes('Waiting Room') && !(action.payload as string).includes('대기창')) ||
				namePayloadWithoutDefault === ''
					? false
					: isNamePayloadWithoutDefaultNumber;

			let helpText = '';

			if (isNamePayloadDefaultForm) {
				helpText = 'vwr.defaultFormatError';
			}

			if (!isNamePayloadNotEmpty) {
				helpText = 'vwr.emptyWaitingRoomNameError';
			}

			if (!isNamePayloadFormatValid) {
				helpText = 'vwr.noSpecialCharacter';
			}

			return {
				...state,
				enteredName: action.payload || '',
				isEnteredNameValid: isNamePayloadNotEmpty && isNamePayloadFormatValid && !isNamePayloadDefaultForm,
				enteredNameValidation:
					isNamePayloadNotEmpty && isNamePayloadFormatValid && !isNamePayloadDefaultForm ? 'none' : 'failed',
				isEnteredNameChanged: true,
				enteredNameHelpText: helpText,
			};
		}

		case VALIDATE_NAME: {
			const isNameStateNotEmpty = state.enteredName.trim().length > 0;

			const isNameStateFormatValid = rName.test(state.enteredName);

			const nameStateWithoutDefault = state.enteredName.replace('Waiting Room', '').replace('대기창', '').trim();

			const isNameStateWithoutDefaultNumber = !Number.isNaN(Number(nameStateWithoutDefault));

			const isNameStateDefaultForm =
				(!state.enteredName.includes('대기창') && !state.enteredName.includes('Waiting Room')) ||
				nameStateWithoutDefault === ''
					? false
					: isNameStateWithoutDefaultNumber;

			let helpText = '';

			if (isNameStateDefaultForm) {
				helpText = 'vwr.defaultFormatError';
			}

			if (!isNameStateNotEmpty) {
				helpText = 'vwr.emptyWaitingRoomNameError';
			}

			if (!isNameStateFormatValid) {
				helpText = 'vwr.noSpecialCharacter';
			}

			const isNameStateValid = isNameStateFormatValid && isNameStateNotEmpty && !isNameStateDefaultForm;

			return {
				...state,
				isEnteredNameValid: state.isEnteredNameChanged ? isNameStateValid : false,
				enteredNameValidation: state.isEnteredNameChanged && isNameStateValid ? 'none' : 'failed',
				enteredNameHelpText: helpText,
			};
		}

		case UPDATE_NAME_HELP_TEXT: {
			return {
				...state,
				isEnteredNameValid: false,
				enteredNameValidation: 'failed',
				isEnteredNameChanged: true,
				enteredNameHelpText: action.payload as string,
			};
		}

		default:
			return initialNameForm;
	}
};

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';
import { PutProjectNameApiResponse } from '../types/putProjectName.type';

export interface PutProjectNameApiProps {
	tenantId: string;
	tenantUrl: string;
	projectId: number;
	organizationId: number;
	projectName: string;
}

export const putProjectNameApi = async ({
	tenantId,
	tenantUrl,
	projectId,
	organizationId,
	projectName,
}: PutProjectNameApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken && organizationId !== 0) {
		const { data } = await axios.put<PutProjectNameApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}/name`,
			{
				projectName,
			},
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

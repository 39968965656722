import axios, { AxiosError } from 'axios';
import { COGNITO_TOKEN, ONPREM_EUM_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import { GetEUMMapApiResponse } from '../types/getEUMMap.type';

export interface GetEUMMapCityApiProps {
	tenantUrl: string;
	organizationId: number;
	mainDomain: string | null;
	beginTime: number;
	endTime: number;
	countryCode: string;
	projectKey: string;
}

export default async function getEUMMapCityApi({
	tenantUrl,
	organizationId,
	mainDomain,
	beginTime,
	endTime,
	countryCode,
	projectKey,
}: GetEUMMapCityApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);
	const baseUrl =
		process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
			? getOnPremBaseUrl(ONPREM_EUM_API_PATH)
			: `https://${tenantUrl.slice(0, -5)}:9091/eum`;

	if (cognitoToken && userKey && organizationId && mainDomain) {
		const { data } = await axios.get<GetEUMMapApiResponse>(`${baseUrl}/map/city`, {
			params: { mainDomain, begin: beginTime, end: endTime, countryCode, projectKey },
			headers: {
				Authorization: cognitoToken,
				userKey,
				organizationId,
			},
		});
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LineChartData } from 'features/nfEUM/hooks/useTXNLineChartOptions';
import { getTXNRealtimeThunk } from 'features/nfEUM/thunk/getTXNRealtime.thunk';
import { lineChartAnnotationFn } from 'features/nfEUM/utils/chartFunc';

export interface LineChartAnnotation {
	web: LineChartData;
	native: LineChartData;
}

export interface TXNLoadState {
	data: { web: LineChartData[]; native: LineChartData[] };
	annotation: LineChartAnnotation;
	totalCount: number;
}

export interface TXNIntervalTime {
	beginTime: number;
	endTime: number;
}

export interface TXNRealtimeSliceData extends TXNIntervalTime {
	hasError: boolean;
	isLoaded: boolean;
	load: TXNLoadState;
	loadTime: TXNLoadState;
	error: TXNLoadState;
}

const initialState: TXNRealtimeSliceData = {
	hasError: false,
	isLoaded: false,
	beginTime: 0,
	endTime: 0,
	load: {
		data: { web: [], native: [] },
		annotation: {
			web: {
				x: 0,
				y: 0,
			},
			native: {
				x: 0,
				y: 0,
			},
		},
		totalCount: 0,
	},
	loadTime: {
		data: { web: [], native: [] },
		annotation: {
			web: {
				x: 0,
				y: 0,
			},
			native: {
				x: 0,
				y: 0,
			},
		},
		totalCount: 0,
	},
	error: {
		data: { web: [], native: [] },
		annotation: {
			web: {
				x: 0,
				y: 0,
			},
			native: {
				x: 0,
				y: 0,
			},
		},
		totalCount: 0,
	},
};

const eumTXNRealtimeSlice = createSlice({
	name: 'eumTXNRealtime',
	initialState,
	reducers: {
		setRealtimeIntervalTime: (state, action: PayloadAction<TXNIntervalTime>) => {
			const { payload } = action;
			return { ...state, beginTime: payload.beginTime, endTime: payload.endTime };
		},
		resetRealtimeData: () => {
			return { ...initialState };
		},
		cleanupRealtimeData: state => {
			return {
				...state,
				load: {
					...state.load,
					data: {
						...state.load.data,
						web: state.load.data.web.slice(-120),
						native: state.load.data.native.slice(-120),
					},
				},
				loadTime: {
					...state.loadTime,
					data: {
						...state.loadTime.data,
						web: state.loadTime.data.web.slice(-120),
						native: state.loadTime.data.native.slice(-120),
					},
				},
				error: {
					...state.error,
					data: {
						...state.error.data,
						web: state.error.data.web.slice(-120),
						native: state.error.data.native.slice(-120),
					},
				},
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(getTXNRealtimeThunk.rejected, state => {
			return { ...state, hasError: true, isLoaded: true };
		});

		builder.addCase(getTXNRealtimeThunk.fulfilled, (state, action) => {
			const { payload } = action;

			// loadWeb
			const newLoadDataWeb: LineChartData[] = payload.loadData.webVisitList.map(el => {
				if (el.value < 0) return { x: el.time, y: 0 };
				return { x: el.time, y: el.value };
			});
			const loadDataWeb: LineChartData[] = [...state.load.data.web, ...newLoadDataWeb];
			const loadAnnotationWeb: LineChartData = lineChartAnnotationFn(loadDataWeb.slice(-100));

			// loadNative
			const nweLoadTimeDataNative: LineChartData[] = payload.loadData.nativeVisitList.map(el => {
				if (el.value < 0) return { x: el.time, y: 0 };
				return { x: el.time, y: el.value };
			});
			const loadDataNative: LineChartData[] = [...state.load.data.native, ...nweLoadTimeDataNative];
			const loadAnnotationNative: LineChartData = lineChartAnnotationFn(loadDataNative.slice(-100));

			// loadTotalCount
			let loadTotalCount = 0;
			loadDataWeb.slice(-100).forEach(el => {
				loadTotalCount += el.y || 0;
			});
			loadDataNative.slice(-100).forEach(el => {
				loadTotalCount += el.y || 0;
			});

			// loadTimeWeb
			const newLoadTimeDataWeb: LineChartData[] = payload.loadTimeData.webLoad.map(el => {
				if (el.value < 0) return { x: el.time, y: 0 };
				return { x: el.time, y: Number((el.value / 1000).toFixed(3)) };
			});
			const loadTimeDataWeb: LineChartData[] = [...state.loadTime.data.web, ...newLoadTimeDataWeb];
			const loadTimeAnnotationWeb: LineChartData = lineChartAnnotationFn(loadTimeDataWeb.slice(-100));

			// loadTimeNative
			const newLoadTimeDataNative: LineChartData[] = payload.loadTimeData.nativeLoad.map(el => {
				if (el.value < 0) return { x: el.time, y: 0 };
				return { x: el.time, y: Number((el.value / 1000).toFixed(3)) };
			});
			const loadTimeDataNative: LineChartData[] = [...state.loadTime.data.native, ...newLoadTimeDataNative];
			const loadTimeAnnotationNative: LineChartData = lineChartAnnotationFn(loadTimeDataNative.slice(-100));

			// errorWeb
			const newErrorDataWeb: LineChartData[] = payload.errorData.webErrorList.map(el => {
				if (el.value < 0) return { x: el.time, y: 0 };
				return { x: el.time, y: el.value };
			});
			const errorDataWeb: LineChartData[] = [...state.error.data.web, ...newErrorDataWeb];
			const errorAnnotationWeb: LineChartData = lineChartAnnotationFn(errorDataWeb.slice(-100));

			// errorNative
			const newErrorDataNative: LineChartData[] = payload.errorData.nativeErrorList.map(el => {
				if (el.value < 0) return { x: el.time, y: 0 };
				return { x: el.time, y: el.value };
			});
			const errorDataNative: LineChartData[] = [...state.error.data.native, ...newErrorDataNative];
			const errorAnnotationNative: LineChartData = lineChartAnnotationFn(errorDataNative.slice(-100));

			// errorTotalCount
			let errorTotalCount = 0;
			errorDataWeb.slice(-300).forEach(el => {
				errorTotalCount += el.y || 0;
			});
			errorDataNative.slice(-300).forEach(el => {
				errorTotalCount += el.y || 0;
			});

			return {
				...state,
				hasError: false,
				isLoaded: true,
				load: {
					...state.load,
					data: { web: loadDataWeb, native: loadDataNative },
					annotation: { web: loadAnnotationWeb, native: loadAnnotationNative },
					totalCount: loadTotalCount,
				},
				loadTime: {
					...state.loadTime,
					data: { web: loadTimeDataWeb, native: loadTimeDataNative },
					annotation: { web: loadTimeAnnotationWeb, native: loadTimeAnnotationNative },
				},
				error: {
					...state.error,
					data: { web: errorDataWeb, native: errorDataNative },
					annotation: { web: errorAnnotationWeb, native: errorAnnotationNative },
					totalCount: errorTotalCount,
				},
			};
		});
	},
});

export const { resetRealtimeData, setRealtimeIntervalTime, cleanupRealtimeData } = eumTXNRealtimeSlice.actions;

export default eumTXNRealtimeSlice.reducer;

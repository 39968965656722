/** @jsxRuntime classic */

/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Link } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';

export default function BackToSignin() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<div css={linkWrapSt}>
			<div css={linkTxtWrap}>
				<Link onClick={() => navigate(`/${getI18nPath()}/signin`)}>{t('forgotPassword.backToSignIn')}</Link>
			</div>
		</div>
	);
}

const linkWrapSt = css`
	display: flex;
	justify-content: center;
`;

const linkTxtWrap = css`
	display: flex;
	gap: 0.25rem;
	align-items: center;
`;

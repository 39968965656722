import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getLicenseListApi from 'features/license/api/getLicenseList.api';

import type { LicenseData } from 'features/license/types/getLicenseList.type';

export interface GetLicenseListThunkResult {
	license: LicenseData[];
}

export const getLicenseListThunk = createAsyncThunk<GetLicenseListThunkResult, void, { state: RootState }>(
	'license/list/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
		} = thunkAPI.getState();

		try {
			const { data } = await getLicenseListApi({
				tenantId: tenantId || '',
				organizationId,
			});

			return {
				license: [...data],
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { GetProjectListApiResponse } from 'features/nfSetting/types/getProjectList.v2.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface GetProjectListApiProps {
	tenantId: string;
	organizationId: number;
}

export const getProjectListApi = async ({ tenantId, organizationId }: GetProjectListApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken && organizationId !== 0) {
		const { data } = await axios.get<GetProjectListApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/list`,
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

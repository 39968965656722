/** @jsxRuntime classic */
/** @jsx jsx */

import ReactApexChart from 'react-apexcharts';
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'common/redux/store';
import useTXNDonutChartOptions from 'features/nfEUM/hooks/useTXNDonutChartOptions';
import TXNChartTitle from 'features/nfEUM/components/TXNChartTitle';
import DonutChartLegend from 'features/nfEUM/components/DonutChartLegend';

export default function TXNBrowserChart() {
	const { t } = useTranslation();
	const { browser } = useSelector((state: RootState) => state.eumTXNShare);
	const { apexChartOption } = useTXNDonutChartOptions({
		chartType: browser.labels[0] === 'No Data' ? 'noData' : 'browser',
		labelsData: browser.labels,
		seriesData: browser.series,
		annotation: browser.annotation,
	});

	return (
		<div>
			<TXNChartTitle title={t('eum.browser')} />
			<div css={chart}>
				<ReactApexChart type="donut" width="220px" height="220px" options={apexChartOption} series={browser.series} />
				<DonutChartLegend
					chartName={browser.labels[0] === 'No Data' ? 'noData' : 'browser'}
					labels={browser.labels[0] === 'No Data' ? [t('eum.noData')] : browser.labels}
					series={browser.labels[0] === 'No Data' ? [] : browser.series}
				/>
			</div>
		</div>
	);
}

const chart = css`
	display: flex;
	justify-content: flex-start;
	gap: 16px;
	width: 100%;

	/* .apx-legend-position-right {
		top: 16px !important;
		left: 220px !important;
		right: 0 !important;
		display: flex;
		justify-content: center !important;
		height: 180px !important;
	}

	.apexcharts-legend-series {
		display: flex;
		align-items: center !important;
		gap: 3px;
	}

	.apexcharts-legend-text {
		display: flex;
		align-items: center !important;
		gap: 3px;
	} */
`;

import { Route } from 'react-router-dom';

import OnPremLayout from 'common/layout/OnPremLayout';
import OnPremAuthLayout from 'common/layout/OnPremAuthLayout';
import EmptyOutlet from 'common/layout/EmptyOutlet';
import OnPremProductLayout from 'common/layout/OnPremProductLayout';
import OnPremConsoleLayout from 'common/layout/OnPremConsoleLayout';

import OnPremSigninForm from 'features/signin/components/OnPremSigninForm';
import NetfunnelBasicControlLayout from 'features/nfControl/layout/NetfunnelBasicControlLayout';
import NetfunnelPathControlLayout from 'features/nfControl/layout/NetfunnelPathControlLayout';
import NetfunnelEUMLayout from 'features/nfEUM/layout/NetfunnelEUMLayout';
import AgentLayout from 'features/nfSetting/layout/AgentLayout';
import OnPremPersonalSettingLayout from 'features/setting/layout/OnPremPersonalSettingLayout';
import OnPremUserProfile from 'features/setting/components/OnPremUserProfile';
import OrganizationSettingLayout from 'features/setting/layout/OrganizationSettingLayout';
import UsageLicenseLayout from 'features/license/layout/UsageLicenseLayout';
import LicenseDetailLayout from 'features/license/layout/LicenseDetailLayout';

import SurffyHomePage from 'pages/onPrem/SurffyHome';
import AccountSetting from 'pages/onPrem/AccountSetting';
import MainPage from 'pages/onPrem/NetfunnelMain';
import OnPremNetfunnelBasicControlDefaultView from 'pages/onPrem/OnPremNetfunnelBasicControlDefaultView';
import OnPremNetfunnelBasicControlClassicView from 'pages/onPrem/OnPremNetfunnelBasicControlClassicView';
import OnPremNetfunnelPathControlDefaultView from 'pages/onPrem/OnPremNetfunnelPathControlDefaultView';
import OnPremNetfunnelPathControlClassicView from 'pages/onPrem/OnPremNetfunnelPathControlClassicView';
import ProjectManagement from 'pages/onPrem/ProjectManagement';
import NetfunnelHome from 'pages/onPrem/NetfunnelHome';
import VWRList from 'pages/common/VWRList';
import OnPremInstallWebAgent from 'common/components/OnPremInstallWebAgent';
import OnPremInstallIOSAgent from 'common/components/OnPremInstallIOSAgent';
import OnPremInstallAOSAgent from 'common/components/OnPremInstallAOSAgent';
import EUMTransactionPage from 'pages/common/EUMTransaction';
import EUMUrlPage from 'pages/common/EUMUrl';
import VWRCreate from 'pages/common/VWRCreate';
import VWREdit from 'pages/common/VWREdit';
import Preference from 'pages/common/Preference';
import ChangePassword from 'pages/onPrem/ChangePassword';
import Notification from 'pages/common/Notification';
import Organization from 'pages/common/Organization';
import Usage from 'pages/onPrem/Usage';
import License from 'pages/onPrem/License';
import LicenseDetail from 'pages/onPrem/LicenseDetail';
import LicenseUsage from 'pages/onPrem/LicenseUsage';
import Members from 'pages/onPrem/Members';

export default function OnPremRoute() {
	return (
		<Route path="/:locale/on_prem" element={<EmptyOutlet />}>
			<Route path="signin" element={<OnPremAuthLayout />}>
				<Route path="" element={<OnPremSigninForm />} />
			</Route>
			<Route path="account_setting" element={<AccountSetting />} />
			<Route path="home" element={<OnPremLayout />}>
				<Route path="" element={<SurffyHomePage />} />
				<Route path="notification" element={<Notification />} />
				<Route path="personal_setting" element={<OnPremPersonalSettingLayout />}>
					<Route path="profile" element={<OnPremUserProfile />} />
					<Route path="preference" element={<Preference />} />
					<Route path="change_password" element={<ChangePassword />} />
				</Route>
				<Route path="organization_setting" element={<OrganizationSettingLayout />}>
					<Route path="member" element={<Members />} />
					<Route path="organization" element={<Organization />} />
				</Route>
				<Route path="usage_license" element={<UsageLicenseLayout />}>
					<Route path="usage" element={<Usage />} />
					<Route path="license" element={<EmptyOutlet />}>
						<Route path="" element={<License />} />
						<Route path=":licenseId" element={<LicenseDetailLayout />}>
							<Route path="detail" element={<LicenseDetail />} />
							<Route path="usage" element={<LicenseUsage />} />
						</Route>
					</Route>
				</Route>
			</Route>
			<Route path="console" element={<OnPremConsoleLayout />}>
				<Route path="product" element={<EmptyOutlet />}>
					<Route path="nf" element={<EmptyOutlet />}>
						<Route path="home" element={<NetfunnelHome />} />
						<Route path=":id" element={<OnPremProductLayout />}>
							<Route path="main" element={<MainPage />} />
							<Route path="settings" element={<EmptyOutlet />}>
								<Route path="management" element={<EmptyOutlet />}>
									<Route path="project" element={<ProjectManagement />} />
								</Route>
								<Route path="vwr" element={<EmptyOutlet />}>
									<Route path="" element={<VWRList />} />
									<Route path="create" element={<VWRCreate />} />
									<Route path="edit/:vwrId" element={<VWREdit />} />
								</Route>
								<Route path="agent" element={<AgentLayout />}>
									<Route path="web" element={<OnPremInstallWebAgent />} />
									<Route path="ios" element={<OnPremInstallIOSAgent />} />
									<Route path="aos" element={<OnPremInstallAOSAgent />} />
								</Route>
							</Route>
							<Route path="controls" element={<EmptyOutlet />}>
								<Route path="basic" element={<NetfunnelBasicControlLayout />}>
									<Route path="default" element={<OnPremNetfunnelBasicControlDefaultView />} />
									<Route path="classic" element={<OnPremNetfunnelBasicControlClassicView />} />
								</Route>
								<Route path="path" element={<NetfunnelPathControlLayout />}>
									<Route path="default" element={<OnPremNetfunnelPathControlDefaultView />} />
									<Route path="classic" element={<OnPremNetfunnelPathControlClassicView />} />
								</Route>
							</Route>
							<Route path="eum" element={<NetfunnelEUMLayout />}>
								<Route path="url" element={<EUMUrlPage />} />
								<Route path="transaction" element={<EUMTransactionPage />} />
							</Route>
						</Route>
					</Route>
				</Route>
			</Route>
		</Route>
	);
}

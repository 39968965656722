/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Modal, Portal, paletteSDS } from 'surf-design-system';

import usePrompt from 'common/hooks/usePrompt';

type VWRPromptModalProps = {
	when: boolean;
};

/**
 * author: ian
 * createdAt: 2022-10-27
 * updatedAt: 2022-10-27
 * description: vwr 세팅 페이지 이탈 시 경고 모달
 */
export default function BillingInfoPromptModal({ when }: VWRPromptModalProps) {
	const { t } = useTranslation();

	const { showPrompt, cancelNavigation, confirmNavigation } = usePrompt(when);

	const secondaryButtonClickHandler = () => {
		cancelNavigation();
	};

	const primaryButtonClickHandler = () => {
		confirmNavigation();
	};

	return (
		<Portal>
			<Modal
				visible={showPrompt}
				top="30%"
				actionType="double"
				primaryBtnLabel={t('modal.segConfirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				onClickOverlay={secondaryButtonClickHandler}
				isCloseIconVisible={false}
			>
				<div css={textSt}>{t('modal.vwrPreviewConfirmMsg')}</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	margin-top: 1rem;

	color: ${paletteSDS.gray[900]};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dropdown, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import useApiRequestNoArg from 'common/hooks/useApiRequestNoArg';

import getTimezoneListApi from '../api/getTimezoneList.api';
import { putTimezoneThunk } from '../thunk/putTimezone.thunk';

/**
 * author: Jay
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-19
 * description: 사용자 이름 및 이미지 변경 컴포넌트
 * todo: 사용자 이름 및 이미지 변경시 에러핸들링
 */
export default function TimezoneForm() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { response, sendRequest } = useApiRequestNoArg(getTimezoneListApi);

	const { timeZone } = useSelector((state: RootState) => state.account);

	const timezoneChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		dispatch(putTimezoneThunk({ timezone: e.target.value }));
	};

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	return (
		<div css={profileWrapperCss}>
			<div css={pageTitleSt}>{t('personalSettings.timeZone')}</div>
			{response === null ? (
				<div />
			) : (
				<Dropdown
					menu={response.data.map(el => ({ value: el, name: el }))}
					width="18rem"
					onChange={timezoneChangeHandler}
					bindedValue={timeZone}
				/>
			)}
			<div css={timezoneGuideSt}>{t('personalSettings.timezoneGuide')}</div>
		</div>
	);
}

const profileWrapperCss = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

const timezoneGuideSt = css`
	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};

	line-height: 160%;
`;

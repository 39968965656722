import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import type { RootState } from 'common/redux/store';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import postEUMNetworkStatDataApi from 'features/nfEUM/api/postEUMNetworkStatData.api';
import type { ChartData } from 'features/nfBasic/context/monitoringSlice';
import { PostEUMSummaryApiData } from 'features/nfEUM/types/postEUMSummary.type';
import { PostEUMUriStatusApiData } from 'features/nfEUM/types/postEUMUriStatus.type';
import postEUMUriStatusApi from 'features/nfEUM/api/postEUMUriStatus.api';
import postEUMSummaryApi from 'features/nfEUM/api/postEUMSummary.api';
import { X_AXIS_NUM, X_AXIS_VALUE_INTERVAL } from 'features/nfEUM/data/constants';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface PostEUMIntervalStartThunkResult {
	processTimeY: ChartData[];
	countY: ChartData[];
	summaryData: PostEUMSummaryApiData;
	uriStatusData: PostEUMUriStatusApiData[];
}

export const postEUMIntervalStartThunk = createAsyncThunk<PostEUMIntervalStartThunkResult, void, { state: RootState }>(
	'eum/interval/start/post',
	async (_, thunkAPI) => {
		const {
			account: { tenantUrl, organizationId, customerDomain },
			eum: { selectedDate },
			projectInfo: { projectList, selectedProjectId },
			plan: { priceType },
		} = thunkAPI.getState();

		const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
		const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

		try {
			const updatedData = await Promise.all([
				postEUMNetworkStatDataApi({
					tenantUrl,
					organizationId,
					beginTime: Number(moment(selectedDate).subtract(7, 'minutes').format('X')),
					endTime: Number(moment(selectedDate).format('X')),
					type: 'M',
					mainDomain:
						priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
							? getDomainWithoutSubdomain(selectedDomain)
							: customerDomain,
					projectId: selectedProjectId,
					projectKey: selectedProjectKey,
				}),
				postEUMUriStatusApi({
					tenantUrl,
					organizationId,
					timestamp: Number(moment(selectedDate).format('X')),
					mainDomain:
						priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
							? getDomainWithoutSubdomain(selectedDomain)
							: customerDomain,
					projectId: selectedProjectId,
					projectKey: selectedProjectKey,
				}),
				postEUMSummaryApi({
					tenantUrl,
					organizationId,
					timestamp: Number(moment(selectedDate).format('X')),
					mainDomain:
						priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
							? getDomainWithoutSubdomain(selectedDomain)
							: customerDomain,
					projectId: selectedProjectId,
					projectKey: selectedProjectKey,
				}),
			]);

			let processTimeY: ChartData[] = [];
			let countY: ChartData[] = [];

			if (updatedData[0].data.length > X_AXIS_NUM) {
				processTimeY = updatedData[0].data
					.slice(updatedData[0].data.length - X_AXIS_NUM)
					.map(processData => ({ x: processData.ts, y: Number((processData.avg_pt / 1000).toFixed(1)) }));

				countY = updatedData[0].data
					.slice(updatedData[0].data.length - X_AXIS_NUM)
					.map(countData => ({ x: countData.ts, y: countData.sum_count }));
			}

			if (updatedData[0].data.length === X_AXIS_NUM) {
				processTimeY = updatedData[0].data.map(processData => ({
					x: processData.ts,
					y: Number((processData.avg_pt / 1000).toFixed(1)),
				}));
				countY = updatedData[0].data.map(countData => ({ x: countData.ts, y: countData.sum_count }));
			}

			if (updatedData[0].data.length > 0 && updatedData[0].data.length < X_AXIS_NUM) {
				const firstTimestamp = updatedData[0].data[0].ts;

				const nullChartData = Array.from<unknown, ChartData>(
					{ length: X_AXIS_NUM - updatedData[0].data.length },
					(nullEl, nullIdx) => ({
						y: null,
						x: firstTimestamp - (X_AXIS_NUM - updatedData[0].data.length - nullIdx) * X_AXIS_VALUE_INTERVAL,
					})
				);

				processTimeY = nullChartData.slice().concat(
					updatedData[0].data.map(processData => ({
						x: processData.ts,
						y: Number((processData.avg_pt / 1000).toFixed(1)),
					}))
				);

				countY = nullChartData
					.slice()
					.concat(updatedData[0].data.map(countData => ({ x: countData.ts, y: countData.sum_count })));
			}

			if (updatedData[0].data.length === 0) {
				const nullChartData = Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (nullEl, nullIdx) => ({
					y: null,
					x: Number(moment(selectedDate).subtract(6, 'minutes').format('X')) + (nullIdx + 1) * X_AXIS_VALUE_INTERVAL,
				}));

				processTimeY = nullChartData.slice();
				countY = nullChartData.slice();
			}
			thunkAPI.dispatch(setInitialToast());

			return {
				uriStatusData: updatedData[1].data,
				summaryData: updatedData[2].data,
				processTimeY,
				countY,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import DividerWithTxt from 'common/components/DividerWithTxt';
import { RootState, useAppDispatch } from 'common/redux/store';

import PaymentMethod from 'features/plan/components/PaymentMethod';
import PlanUsageSummary from 'features/plan/components/PlanUsageSummary';
import SubscriptionSummary from 'features/plan/components/SubscriptionSummary';

import { getOrganizationPlanInfoThunk } from 'features/plan/thunk/getOrganizationPlanInfo.thunk';
import { getOrganizationPaymentInfoThunk } from 'features/plan/thunk/getOrganizationPaymentInfo.thunk';

export default function Plan() {
	const dispatch = useAppDispatch();

	const { isLoaded: isPaymentLoaded } = useSelector((state: RootState) => state.payment);
	const { isLoaded: isPlanLoaded } = useSelector((state: RootState) => state.plan);

	useEffect(() => {
		dispatch(getOrganizationPlanInfoThunk());
		dispatch(getOrganizationPaymentInfoThunk());
	}, [dispatch]);

	if (!isPaymentLoaded || !isPlanLoaded) return <div />;

	return (
		<div css={containerSt}>
			<div css={summarySectionSt}>
				<SubscriptionSummary />
				<PaymentMethod titleSize="large" />
			</div>
			<DividerWithTxt />
			<PlanUsageSummary />
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const summarySectionSt = css`
	display: flex;
	justify-content: space-between;

	width: 100%;
`;

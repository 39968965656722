/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// etc
import { backgroundPalette, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { PLAN_ENTERPRISE_ID } from 'common/data/constants';

import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';

import { PlanInfo } from '../types/getPlanList.type';
import { addNumberComma } from '../utils/formatFunc';

/**
 * author: Jay
 * createdAt: 2022-08-08
 * updatedAt: 2022-08-08
 * description: plan 선택 항목
 * todo: (1) 다운그레이드시 feature 조건 초과하는 경우 plan 블록 및 초과 조건 highlight
 * todo: (2) planName 별로 플랜 선택 버튼 색상 다르게 나타내는 함수
 */

interface PlanItemProps {
	plan: PlanInfo;
	selectedPlanId: number;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	disabled: boolean;
}

export default function PlanItem({ plan, selectedPlanId, onChange, disabled }: PlanItemProps) {
	const { t } = useTranslation();

	const inputRef = useRef<HTMLInputElement>(null);

	const { language } = useSelector((state: RootState) => state.account);

	const planLang = plan.planLangs.find(item => item.langType === language) as PlanLangs;

	let unitChar = '$';
	let priceContent = <div css={[themeCss.fontWeight.bold, colorPalette.blue[400], talktoSalesSt]}>Talk to Sales</div>;

	if (plan.priceUnit === 'KRW') {
		unitChar = '₩';
	}

	if (plan.planId !== PLAN_ENTERPRISE_ID) {
		priceContent = (
			<div css={[colorPalette.gray[700], themeCss.fontWeight.regular, themeCss.fontSize[14], priceCss]}>
				<div css={[colorPalette.gray[900], themeCss.fontWeight.bold, priceUnitCss]}>
					{plan.price !== 0 ? unitChar : null}
					{plan.price !== 0 ? addNumberComma(String(plan.price)) : t('planSubscription.freePrice')}
				</div>
				<div>/ {t('planSubscription.billingCycle')}</div>
			</div>
		);
	}

	const disabledCss = css`
		opacity: ${disabled ? 0.24 : ''};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
	`;

	return (
		<div
			css={[planCss(selectedPlanId, plan.planId), backgroundPalette.white, disabledCss]}
			onClick={() => {
				if (inputRef.current && !disabled) inputRef.current.click();
			}}
			aria-hidden
		>
			<div css={inputBoxSt}>
				<input
					ref={inputRef}
					type="radio"
					css={radioButtonCss}
					checked={selectedPlanId === plan.planId}
					onChange={onChange}
					value={plan.planId}
					disabled={disabled}
				/>
			</div>
			<div css={nameCommentWrap}>
				<div css={[themeCss.fontSize[24], themeCss.fontWeight.bold]}>{planLang.name}</div>
				<div css={[colorPalette.gray[600], themeCss.fontWeight.regular, themeCss.fontSize[14], planCommentCss]}>
					{planLang.description}
				</div>
			</div>
			{priceContent}
		</div>
	);
}

const planCss = (selectedPlanId: number, currPlanId: number) => css`
	position: relative;

	display: flex;

	gap: 11px;

	padding: 1.5rem;

	width: 39.3125rem;
	height: 6.5938rem;

	border: 1px solid ${selectedPlanId === currPlanId ? paletteSDS.blue[500] : paletteSDS.gray[300]};

	border-radius: 8px;
`;

const priceCss = css`
	position: absolute;

	display: flex;
	align-items: center;

	gap: 4px;

	top: 1.5rem;
	right: 1.5rem;

	line-height: 160%;
	letter-spacing: -0.008em;
`;

const priceUnitCss = css`
	font-size: 20px;
	line-height: 128%;
`;

const planCommentCss = css`
	line-height: 160%;
`;

const inputBoxSt = css`
	margin-top: 2px;
`;

const radioButtonCss = css`
	min-width: 1rem;
	min-height: 1rem;
	background: ${paletteSDS.gray[50]};
	border: ${paletteSDS.gray[500]} 1px solid;
	border-radius: 50%;
	margin: 0.1875rem;
	cursor: pointer;

	&:checked {
		accent-color: ${paletteSDS.blue[700]};
		& + label {
			color: ${paletteSDS.blue[700]};
		}
	}

	&:disabled {
		background: ${paletteSDS.gray[300]};
		border: 1px solid ${paletteSDS.gray[500]};

		&checked {
			accent-color: ${paletteSDS.gray[500]};
		}

		& + label {
			color: ${paletteSDS.gray[500]};
		}
		cursor: not-allowed;
	}
`;

const talktoSalesSt = css`
	position: absolute;

	top: 1.5rem;
	right: 1.5rem;

	font-size: 20px;
	letter-spacing: -0.008em;
`;

const nameCommentWrap = css`
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
`;

// export function getDomainWithoutSubdomain(url: string) {
// 	let fullUrl = url;

// 	const isUrlContainsProtocol = url.startsWith('http://') || url.startsWith('https://');

// 	if (!isUrlContainsProtocol) {
// 		fullUrl = `https://${url}`;
// 	}

// 	const urlParts = new URL(fullUrl).hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/);

// 	return urlParts && urlParts[1];
// }

export function getDomainWithoutSubdomain(url: string) {
	return url.replace(/^https?:\/\//, '');
}

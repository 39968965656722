import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getVWRDataThunk } from '../thunk/getVWRData.thunk';
import { putVWRDataThunk } from '../thunk/putVWRData.thunk';

export interface ChangeFileProps {
	src: string;
	name: string;
}

export interface VWRElement {
	logoImagePathSrc: string;
	logoImagePathName: string;
	title: string;
	followingWaitYn: boolean;
	guideText: string;
	imagePathSrc: string;
	imagePathName: string;
	waitCancelYn: boolean;
	waitTimeDisplayYn: boolean;
}

export interface VWRDataChange {
	isLogoImagePathChanged: boolean;
	isTitleChanged: boolean;
	isFollowingWaitYnChanged: boolean;
	isGuideTextChanged: boolean;
	isImagePathChanged: boolean;
	isWaitCancelYnChanged: boolean;
	isWaitTimeDisplayYnChanged: boolean;
}

export interface VWRData {
	form: VWRElement & VWRDataChange;
	preview: VWRElement;
	isLoaded: boolean;
	isLoading: boolean;
	// eslint-disable-next-line
	error: any;
}

const initialState: VWRData = {
	form: {
		logoImagePathSrc: '',
		logoImagePathName: '',
		title: '',
		followingWaitYn: false,
		guideText: '',
		imagePathSrc: '',
		imagePathName: '',
		waitCancelYn: false,
		isFollowingWaitYnChanged: false,
		isGuideTextChanged: false,
		isImagePathChanged: false,
		isLogoImagePathChanged: false,
		isTitleChanged: false,
		isWaitCancelYnChanged: false,
		isWaitTimeDisplayYnChanged: false,
		waitTimeDisplayYn: false,
	},
	preview: {
		logoImagePathSrc: '',
		logoImagePathName: '',
		title: '',
		followingWaitYn: false,
		guideText: '',
		imagePathSrc: '',
		imagePathName: '',
		waitCancelYn: false,
		waitTimeDisplayYn: false,
	},
	isLoaded: false,
	isLoading: false,
	error: null,
};

const vwrSlice = createSlice({
	name: 'vwr',
	initialState,
	reducers: {
		changeLogoImagePath: (state, action: PayloadAction<ChangeFileProps>): VWRData => {
			return {
				...state,
				form: {
					...state.form,
					logoImagePathSrc: action.payload.src,
					isLogoImagePathChanged: true,
					logoImagePathName: action.payload.name,
				},
			};
		},
		changeImagePath: (state, action: PayloadAction<ChangeFileProps>): VWRData => {
			return {
				...state,
				form: {
					...state.form,
					imagePathSrc: action.payload.src,
					isImagePathChanged: true,
					imagePathName: action.payload.name,
				},
			};
		},
		changeTitle: (state, action: PayloadAction<string>): VWRData => {
			return { ...state, form: { ...state.form, title: action.payload, isTitleChanged: true } };
		},
		changeFollowingWaitYn: (state, action: PayloadAction<boolean>): VWRData => {
			return { ...state, form: { ...state.form, followingWaitYn: action.payload, isFollowingWaitYnChanged: true } };
		},
		changeGuideText: (state, action: PayloadAction<string>): VWRData => {
			return { ...state, form: { ...state.form, guideText: action.payload, isGuideTextChanged: true } };
		},
		changeWaitCancelYn: (state, action: PayloadAction<boolean>): VWRData => {
			return { ...state, form: { ...state.form, waitCancelYn: action.payload, isWaitCancelYnChanged: true } };
		},
		changeWaitTimeDisplayYn: (state, action: PayloadAction<boolean>): VWRData => {
			return { ...state, form: { ...state.form, waitTimeDisplayYn: action.payload, isWaitTimeDisplayYnChanged: true } };
		},
		setPreviewData: (state, action: PayloadAction<VWRElement>): VWRData => {
			return { ...state, preview: { ...action.payload }, form: { ...state.form, title: action.payload.title } };
		},
		setCreateVWRName: (state, action: PayloadAction<string>) => {
			return { ...state, createVWRName: action.payload };
		},
	},
	extraReducers(builder) {
		builder.addCase(getVWRDataThunk.fulfilled, (state, action): VWRData => {
			return {
				...state,
				isLoaded: true,
				isLoading: false,
				form: {
					...action.payload,
					isFollowingWaitYnChanged: false,
					isGuideTextChanged: false,
					isImagePathChanged: false,
					isLogoImagePathChanged: false,
					isTitleChanged: false,
					isWaitCancelYnChanged: false,
					isWaitTimeDisplayYnChanged: false,
				},
				preview: { ...action.payload },
				error: null,
			};
		});

		builder.addCase(getVWRDataThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoaded: true };
		});

		builder.addCase(putVWRDataThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putVWRDataThunk.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				form: {
					isFollowingWaitYnChanged: false,
					isGuideTextChanged: false,
					isImagePathChanged: false,
					isLogoImagePathChanged: false,
					isTitleChanged: false,
					isWaitCancelYnChanged: false,
					isWaitTimeDisplayYnChanged: false,
					...action.payload,
				},
				preview: { ...action.payload },
				error: null,
			};
		});

		builder.addCase(putVWRDataThunk.rejected, (state, action) => {
			return { ...state, error: { ...(action.payload as object) }, isLoaded: true, isLoading: false };
		});
	},
});

export const {
	changeFollowingWaitYn,
	changeGuideText,
	changeLogoImagePath,
	changeTitle,
	changeWaitCancelYn,
	setPreviewData,
	changeImagePath,
	changeWaitTimeDisplayYn,
	setCreateVWRName,
} = vwrSlice.actions;

export default vwrSlice.reducer;

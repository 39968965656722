/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ChevronRightIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import type { TableColumn, TableRow } from 'common/components/Table';
import Table from 'common/components/Table';
import { LANG_PATH } from 'common/data/constants';

import LicenseStatusLabel from 'features/license/components/LicenseStatusLabel';
import LicenseTableProjectElement from 'features/license/components/LicenseTableProjectElement';
import type { ccuStatus } from 'features/license/types/getLicenseProjectUsage.type';
import { addNumberComma } from 'features/plan/utils/formatFunc';

export default function LicenseTable() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { language } = useSelector((state: RootState) => state.account);

	const { licenseList } = useSelector((state: RootState) => state.license);

	const licenseColumns: TableColumn[] = useMemo(
		() => [
			{
				id: 1,
				label: t('onPrem.license.license'),
				padding: '0',
				backgroundColor: paletteSDS.gray['200'],
				width: '80px',
				elementCss: columnSt,
			},
			{
				id: 2,
				label: t('onPrem.license.project'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '400px',
				elementCss: columnSt,
			},
			{
				id: 3,
				label: t('onPrem.license.peakCCUTimes'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '144px',
				elementCss: columnSt,
			},
			{
				id: 4,
				label: t('onPrem.license.peakCCUCount'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '144px',
				elementCss: columnSt,
			},
			{
				id: 5,
				label: t('onPrem.license.segments'),
				padding: '0',
				backgroundColor: paletteSDS.gray['200'],
				width: '80px',
				elementCss: columnSt,
			},
			{
				id: 6,
				label: '',
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '64px',
				elementCss: css``,
			},
		],
		[t]
	);

	const licenseRows: TableRow[] = licenseList.map(el => ({
		id: el.projectId,
		items: [
			{
				elementId: 1,
				element: (
					<span>
						<LicenseStatusLabel licenseExpiredYn={el.licenseExpiredYn} />
					</span>
				),
				padding: '0px 16px',
				width: '80px',
				height: '130px',
				elementCss: rowElementSt,
			},
			{
				elementId: 2,
				element: <LicenseTableProjectElement data={el} />,
				padding: '12px 16px',
				width: '400px',
				height: '130px',
				elementCss: css`
					flex: none;
					background: none;
				`,
			},
			{
				elementId: 3,
				element: (
					<p>
						<span css={exceedCss(el.totalExceededLicPeakCcu >= el.licensedTotalExceededPeakCcu)}>
							{addNumberComma(el.totalExceededLicPeakCcu.toString())}
						</span>
						<span>&nbsp;/&nbsp;{addNumberComma(el.licensedTotalExceededPeakCcu.toString())}</span>
					</p>
				),
				padding: '0px 16px',
				width: '144px',
				height: '130px',
				elementCss: rowElementSt,
			},
			{
				elementId: 4,
				element: (
					<p>
						<span css={ccuStatusCss(el.maxDailyPeakCcuStatus)}>{addNumberComma(el.maxDailyPeakCcu.toString())}</span>
						<span>&nbsp;/&nbsp;{addNumberComma(el.licensedPeakCcu.toString())}</span>
					</p>
				),
				padding: '0px 16px',
				width: '144px',
				height: '130px',
				elementCss: rowElementSt,
			},
			{
				elementId: 5,
				element: (
					<span>
						{el.segCount} / {el.licensedSegCount}
					</span>
				),
				padding: '0px 16px',
				width: '80px',
				height: '130px',
				elementCss: rowElementSt,
			},
			{
				elementId: 56,
				element: <ChevronRightIcon />,
				padding: '0px 16px',
				width: '64px',
				height: '130px',
				elementCss: css`
					flex: none;
					background: none;
					cursor: pointer;
				`,
				onClick: () => {
					navigate(`/${LANG_PATH[language]}/on_prem/home/usage_license/license/${el.projectId}/detail`);
				},
			},
		],
		rowCss: rowSt,
	}));

	return <Table columns={licenseColumns} rows={licenseRows} />;
}

const columnSt = css`
	justify-content: center;
	flex: none;
	text-align: center;
	word-break: keep-all;
`;

const rowSt = css`
	background: ${paletteSDS.gray['50']};
`;

const rowElementSt = css`
	flex: none;
	background: none;
	> div {
		justify-content: center;
	}
`;

const exceedCss = (isExceed: boolean) => css`
	${isExceed && `font-weight : ${themeSDS.fontWeight.semiBold}`};
	color: ${isExceed ? paletteSDS.red[500] : paletteSDS.gray[900]};
`;

const ccuStatusCss = (status: ccuStatus) => {
	switch (status) {
		case 'DANGER':
			return css`
				font-weight: ${themeSDS.fontWeight.semiBold};
				color: ${paletteSDS.categoryColor.orange};
			`;
		case 'EXCEEDED':
			return css`
				font-weight: ${themeSDS.fontWeight.semiBold};
				color: ${paletteSDS.red[500]};
			`;
		case 'NORMAL':
			return css`
				${paletteSDS.gray[900]}
			`;
		default:
			return css``;
	}
};

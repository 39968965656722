/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';

import { paletteSDS } from 'surf-design-system';

interface FunnelProjectTooltipProps {
	isShow: boolean;
	value: number;
	title: string;
	text: string;
}

export default function FunnelProjectTooltip({
	isShow = false,
	value = 0,
	title = '',
	text = '',
}: FunnelProjectTooltipProps) {
	return (
		<React.Fragment>
			{isShow && (
				<div css={FunnelProjectTooltipCss}>
					<div css={headerCss}>
						<span css={valueCss}>{value}</span>
						<span css={titleCss}>{title}</span>
					</div>
					<div css={bodyCss}>
						<p css={textCss}>{text}</p>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}

const FunnelProjectTooltipCss = css`
	position: absolute;
	top: 0;
	right: -208px;
	width: 200px;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	overflow: hidden;
	z-index: 2000;
`;

const headerCss = css`
	display: flex;
	justify-content: space-between;
	padding: 4px 8px;
	background-color: ${paletteSDS.systemColor.white};
`;

const valueCss = css`
	color: ${paletteSDS.gray[900]};
	font-weight: 600;
	font-size: 12px;
	line-height: 100%;
`;

const titleCss = css`
	color: ${paletteSDS.gray[600]};
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: 0.32px;
`;

const bodyCss = css`
	padding: 4px 8px 8px 8px;
	background-color: ${paletteSDS.gray[100]};
`;

const textCss = css`
	color: ${paletteSDS.gray[800]};
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.32px;
	word-break: break-all;
`;

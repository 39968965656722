/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import moment from 'moment';

import useApiRequestNoArg from 'common/hooks/useApiRequestNoArg';
import { getProductListApi } from 'common/api/getProductInfoList.api';

import Table from 'common/components/Table';
import type { TableColumn, TableRow } from 'common/components/Table';

import { paletteSDS, themeSDS } from 'surf-design-system';

export default function VersionTable() {
	const { sendRequest, isLoading, response, error } = useApiRequestNoArg(getProductListApi);

	const productColumns: TableColumn[] = useMemo(
		() => [
			{ id: 0, label: '', width: '50px', padding: '11px 20px 11px 30px', backgroundColor: '#EEEEEE', maxWidth: '50px' },
			{
				id: 1,
				label: '제품명',
				maxWidth: '300px',
				minWidth: '200px',
				padding: '11px 8px 11px 8px',
				backgroundColor: '#EEEEEE',
			},
			{
				id: 2,
				label: '버전',
				maxWidth: '100px',
				minWidth: '80px',
				padding: '11px 8px 11px 8px',
				backgroundColor: '#EEEEEE',
			},
			{
				id: 3,
				label: '생성일(+UTC09:00)',
				maxWidth: '300px',
				minWidth: '200px',
				padding: '11px 8px 11px 8px',
				backgroundColor: '#EEEEEE',
			},
			{
				id: 4,
				label: '수정일(+UTC09:00)',
				maxWidth: '300px',
				minWidth: '200px',
				padding: '11px 8px 11px 8px',
				backgroundColor: '#EEEEEE',
			},
		],
		[]
	);

	const productRows: TableRow[] = useMemo(
		() =>
			response
				? response.data.map(el => ({
						id: el.productCode,
						items: [
							{
								elementId: `${el.productCode}-empty`,
								element: '',
								width: '50px',
								padding: '11px 10px 11px 30px',
								maxWidth: '50px',
							},
							{
								elementId: `${el.productCode}-uri`,
								element: el.productName,
								maxWidth: '300px',
								minWidth: '200px',
								fontSize: themeSDS.fontSize['16'],
								padding: '11px 8px 11px 8px',
							},
							{
								elementId: 2,
								element: el.productVersion,
								maxWidth: '100px',
								minWidth: '80px',
								fontSize: themeSDS.fontSize['16'],
								padding: '11px 8px 11px 8px',
							},
							{
								elementId: 3,
								element: moment(el.createdAt).utcOffset('+UTC09:00').format('YYYY-MM-DD HH:mm:ss'),
								maxWidth: '300px',
								minWidth: '200px',
								fontSize: themeSDS.fontSize['16'],
								padding: '11px 8px 11px 8px',
							},
							{
								elementId: 4,
								element: moment(el.updatedAt).utcOffset('+UTC09:00').format('YYYY-MM-DD HH:mm:ss'),
								maxWidth: '300px',
								minWidth: '200px',
								fontSize: themeSDS.fontSize['16'],
								padding: '11px 8px 11px 8px',
							},
						],
				  }))
				: [],
		[response]
	);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	if (isLoading) {
		return (
			<div>
				<Table columns={productColumns} rows={productRows} />
				<div css={emptyUriSt}>로딩중...</div>
			</div>
		);
	}

	if (error) {
		return (
			<div>
				<Table columns={productColumns} rows={productRows} />
				<div css={emptyUriSt}>에러가 발생하였습니다.</div>
				<div>{error.toString()}</div>
			</div>
		);
	}

	return <Table columns={productColumns} rows={productRows} />;
}

const emptyUriSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 240px;

	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.regular};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { paletteSDS, themeSDS } from 'surf-design-system';

import DividerWithTxt from 'common/components/DividerWithTxt';

export default function CheckEmail() {
	const { t } = useTranslation();

	const { email } = useParams();

	const convertedEmail = decodeURIComponent(email || '');

	return (
		<React.Fragment>
			<DividerWithTxt dividerColor={paletteSDS.gray[300]} />
			<div css={wrapperCss}>
				<div css={iconSt} />
				<div css={contentCss}>
					<div css={listSt}>{t('commonWords.email')}</div>
					<div css>{convertedEmail}</div>
				</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray[300]} />
			<div css={guidanceCss}>{t('forgotPassword.error.PwdMsg')}</div>
		</React.Fragment>
	);
}

const wrapperCss = css`
	display: flex;
	gap: 1rem;
	width: 100%;
	height: 1.5rem;
	align-items: center;
`;

const iconSt = css`
	width: 0.25rem;
	height: 1.625rem;
	border-radius: 4px;

	background: ${paletteSDS.blue[100]};
`;

const contentCss = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;

	width: 100%;
`;

const listSt = css`
	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray[700]};
`;

const guidanceCss = css`
	font-size: ${themeSDS.fontSize[14]};
	color: ${paletteSDS.gray[700]};

	white-space: pre-wrap;

	line-height: 160%;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import type { RootState } from 'common/redux/store';
import getEUMMapCityApi from 'features/nfEUM/api/getEUMMapCity.api';
import { GetEUMMapApiData } from 'features/nfEUM/types/getEUMMap.type';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface GetTXNMapCityThunkResult {
	cityData: GetEUMMapApiData[];
}

export const getTXNMapCityThunk = createAsyncThunk<GetTXNMapCityThunkResult, void, { state: RootState }>(
	'eum/txn/map/city/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantUrl, organizationId, customerDomain },
			eumTXNMap: { beginTime, endTime, countryCode },
			projectInfo: { projectList, selectedProjectId },
			plan: { priceType },
		} = thunkAPI.getState();

		const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
		const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

		try {
			const apiResponse = await getEUMMapCityApi({
				tenantUrl,
				organizationId,
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				beginTime,
				endTime,
				countryCode,
				projectKey: selectedProjectKey,
			});
			thunkAPI.dispatch(setInitialToast());
			return { cityData: apiResponse.data };
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

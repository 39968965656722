import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import type { RootState } from 'common/redux/store';

import { getVWRPageNumberApi } from 'features/vwr/api/getVWRPageNumber.v2.api';

interface GetVWRPageNumberThunkResult {
	name: string;
}

export const getVWRPageNumberThunk = createAsyncThunk<GetVWRPageNumberThunkResult, void, { state: RootState }>(
	'vwr/get/pageNumber/v2',
	async (args, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
			projectInfo: { selectedProjectId },
		} = thunkAPI.getState();

		try {
			const { data } = await getVWRPageNumberApi({
				tenantId: tenantId as string,
				projectId: selectedProjectId,
				organizationId,
			});

			return { name: i18next.t('vwr.createInitialName', { value: data.nextNumber }) };

			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Modal, Portal, paletteSDS, Loader } from 'surf-design-system';

import { toggleIsVWRPutConfirmModalVisible } from 'common/context/modalSlice';
import { useAppDispatch, RootState } from 'common/redux/store';

import { putVWRDataThunk } from 'features/vwr/thunk/putVWRData.v2.thunk';
import { postVWRThunk } from 'features/vwr/thunk/postVWR.v2.thunk';

interface VWRConfirmModal {
	isCreate?: boolean;
	onSuccessPost?: () => void;
}

export default function VWRConfirmModal({ isCreate = false, onSuccessPost = () => {} }: VWRConfirmModal) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isVWRPutConfirmModalVisible } = useSelector((state: RootState) => state.modal);

	const { isLoading } = useSelector((state: RootState) => state.vwr2);

	const primaryButtonClickHandler = async () => {
		if (isCreate) {
			dispatch(postVWRThunk({ onSuccess: onSuccessPost }));
			return;
		}

		await dispatch(putVWRDataThunk());
	};

	const secondaryButtonClickHandler = () => {
		dispatch(toggleIsVWRPutConfirmModalVisible());
	};

	return (
		<Portal>
			<Modal
				visible={isVWRPutConfirmModalVisible}
				actionType="double"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t('modal.segConfirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				secondaryBtnDisable={isLoading}
				isCloseIconVisible={false}
				primaryBtnDisable={isLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<div css={textSt}>{t('modal.vwrConfirmMsg')}</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 1rem;

	color: ${paletteSDS.gray[900]};
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';

import type { PostPathControlSegmentApiResponse } from 'features/nfControl/type/postPathControlSegment.v2.type';

import type { PathControlSegmentDetailData } from 'features/nfControl/type/getPathControlSegmentDetail.v2.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface PostPathControlSegmentApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
	body: Omit<
		PathControlSegmentDetailData,
		'segmentColor' | 'segmentKey' | 'segmentGraphYn' | 'vwrPage' | 'id' | 'segmentOrder'
	>;
}

export default async function postPathControlSegmentApi({
	tenantId,
	organizationId,
	projectId,
	body,
}: PostPathControlSegmentApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.post<PostPathControlSegmentApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/section-segment`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createSlice } from '@reduxjs/toolkit';
import { getTenantHealthCheckListThunk } from 'common/thunk/getTenantHealthCheckList.thunk';
import { TenantHealthData } from 'common/types/getTenantHealthCheckList.type';

interface TenantData {
	tenantHealthList: TenantHealthData[];
}

const initialState: TenantData = {
	tenantHealthList: [],
};

const tenantSlice = createSlice({
	name: 'tenant',
	initialState,
	reducers: {
		setInitialData: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getTenantHealthCheckListThunk.fulfilled, (state, action) => {
			return { ...state, tenantHealthList: action.payload.tenantHealthList };
		});

		builder.addCase(getTenantHealthCheckListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});
	},
});

export const { setInitialData } = tenantSlice.actions;

export default tenantSlice.reducer;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';

import { paletteSDS } from 'surf-design-system';

export interface ChartBoxProps {
	children?: React.ReactNode;
	isClassic?: boolean;
}

export default function ChartBox({ children, isClassic = false }: ChartBoxProps) {
	return <div css={chartBoxCss(isClassic)}>{children}</div>;
}

const chartBoxCss = (isClassic: boolean) => css`
	width: ${isClassic ? 509 : 500}px;
	height: ${isClassic ? 330 : 400}px;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	background-color: ${paletteSDS.white};
	overflow: hidden;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
// etc

import { backgroundPalette, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

import { PLAN_ENTERPRISE_ID, PLAN_LARGE_ID, PLAN_MEDIUM_ID } from 'common/data/constants';
import { RootState } from 'common/redux/store';

import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';
import { addNumberComma, convertNumToKMB } from '../utils/formatFunc';
import { PlanInfo } from '../types/getPlanList.type';
import OverageEnterprise from './OverageEnterprise';

interface OverageValueProps {
	plan: PlanInfo;
}
export default function OverageValue({ plan }: OverageValueProps) {
	const { language } = useSelector((state: RootState) => state.account);

	const planLang = plan.planLangs.find(item => item.langType === language) as PlanLangs;

	/**
	 * description: 멤버 관련 옵션이 아닌 것만 추출 (optionValue)
	 */
	let planColor = '#13a1f6';
	let overageUnit = '₩';

	if (plan.planId === PLAN_MEDIUM_ID) {
		planColor = '#4a70ff';
	}

	if (plan.planId === PLAN_LARGE_ID) {
		planColor = '#4046ca';
	}

	if (plan.priceUnit === 'USD') {
		overageUnit = '$';
	}

	const planColorCss = css`
		color: ${planColor};
	`;

	const bigBoxCss = [
		bigBoxSt,
		colorPalette.black,
		backgroundPalette.gray[50],
		themeCss.fontSize[14],
		themeCss.fontWeight.bold,
	];

	const overageValue = (value: number, unitValue: number) => {
		return `${overageUnit}${addNumberComma(value.toString())} / ${convertNumToKMB(unitValue)} ${
			unitValue === 1 ? 'User' : 'Users'
		}`;
	};

	if (plan.planId === PLAN_ENTERPRISE_ID) {
		return <OverageEnterprise />;
	}

	return (
		<div css={container}>
			<div css={[planColorCss, titleBoxSt, backgroundPalette.white, themeCss.fontSize[16], themeCss.fontWeight.bold]}>
				{planLang.name}
			</div>
			<div css={bigBoxCss}>{overageValue(plan.planOverages[0].price, plan.planOverages[0].perUnitValue)}</div>
			<div css={bigBoxCss}>{overageValue(plan.planOverages[1].price, plan.planOverages[1].perUnitValue)}</div>
			<div css={bigBoxCss}>{overageValue(plan.planOverages[2].price, plan.planOverages[2].perUnitValue)}</div>
		</div>
	);
}

const titleBoxSt = css`
	width: 100%;
	height: 48px;

	line-height: 48px;
	text-align: center;
`;

const bigBoxSt = css`
	width: 100%;
	height: 80px;

	text-align: center;
	line-height: 80px;

	border-top: 1px solid ${paletteSDS.gray[300]};
`;

const container = css`
	width: 100%;
`;

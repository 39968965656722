import { css } from '@emotion/react';
import { useMemo } from 'react';
import moment from 'moment';
import type { ApexOptions } from 'apexcharts';

import { paletteSDS, themeSDS } from 'surf-design-system';
import { ChartData } from '../context/monitoringSlice';

/**
 * author: root
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-08
 * @returns: chart option
 */

const { fontSize, fontWeight } = themeSDS;
const { gray } = paletteSDS;

export interface OptionsParameter {
	tickamountY?: number;
	maxY: number;
	unit: string;
	timeZone: string;
	decimalsInFloat: number;
	isAnimate: boolean;
	isForceNiceTrim: boolean;
}

export interface ApexChratSeries {
	data: ChartData[];
}

export interface CustomTooltipProps {
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			seriesNames: string;
			colors: string;
			initialSeries: Array<ApexChratSeries>;
		};
	};
	series: Array<ChartData[]>;
}

export default function useLineChartOptions({
	tickamountY,
	maxY,
	unit,
	timeZone,
	decimalsInFloat,
	isAnimate,
	isForceNiceTrim,
}: OptionsParameter) {
	const options: ApexOptions = useMemo(
		() => ({
			chart: {
				id: 'realtime',
				width: '100%',
				height: '100%',
				type: 'line',
				animations: {
					enabled: isAnimate,
					easing: 'linear',
					dynamicAnimation: {
						speed: 200,
					},
				},
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			stroke: {
				width: 1,
				curve: 'straight',
			},
			xaxis: {
				type: 'datetime',
				axisTicks: { show: false },
				axisBorder: { show: false },
				range: 18,
				tickAmount: 6,
				labels: {
					show: true,
					rotate: 0,
					rotateAlways: false,
					trim: false,
					// eslint-disable-next-line
					formatter(value, timestamp?, opts?) {
						const time = moment
							.unix(Number(value) + 3)
							.utcOffset(timeZone.slice(3))
							.format('HH:mm:ss');

						return time;
					},

					style: {
						colors: `${gray[600]}`,
						fontWeight: '300',
						fontSize: '10',
						cssClass: 'apexcharts-xaxis-label',
					},
					// offsetY: 5,
					offsetX: 22,
					hideOverlappingLabels: false,
				},
				tooltip: { enabled: false },
			},
			yaxis: {
				decimalsInFloat,
				labels: {
					style: { colors: `${gray[600]}`, fontWeight: '400', fontSize: '10' },
					offsetX: -20,
					minWidth: 20,
				},
				max: maxY,
				min: 0,
				forceNiceScale: isForceNiceTrim,
				tickAmount: tickamountY,
			},
			grid: {
				show: true,
				borderColor: `${gray[100]}`,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
				padding: {
					right: 20,
					left: -10,
				},
			},
			tooltip: {
				shared: false,
				followCursor: true,
				custom({ series, seriesIndex, dataPointIndex, w }: CustomTooltipProps) {
					const {
						globals: { seriesNames, colors },
					} = w;

					const currSeries = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

					const timeNum = currSeries.x;

					const time = moment.unix(timeNum).utcOffset(timeZone.slice(3)).format('HH:mm:ss');

					const tooltip = `
          <div>
            <div class="top">
              <div class="color" style="background-color:${colors[seriesIndex]};"></div>
              <div class="name">${seriesNames[seriesIndex]}</div>
            </div>
            <div class="bottom">
              <div class="time">${time}</div>
              <div class="wrapper">
                <div class="value">${series[seriesIndex][dataPointIndex]}</div>
                <div class="unit">${unit}</div>
              </div>
          </div>
          `;
					return tooltip;
				},
			},
			legend: {
				show: false,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxY, isAnimate]
	);

	return {
		apexLineOption: options,
	};
}

export const lineTooltipCss = (showTooltip: boolean) => css`
	.apexcharts-tooltip {
		display: ${showTooltip ? 'block' : 'none'};
		width: 100px;
		height: 66px;
		border: 1px solid ${gray[400]};
		border-radius: 4px;
	}

	.top {
		display: flex;
		align-items: center;
		width: 100%;
		height: 22px;
		padding: 4px 8px;
		color: ${gray[900]};
		font-size: ${fontSize[12]};
		font-weight: ${fontWeight.regular};
		line-height: 100%;
	}

	.color {
		width: 7.5px;
		height: 7.5px;
		margin-right: 4.25px;
		border-radius: 50%;
	}

	.bottom {
		width: 100%;
		height: 100%;
		padding: 6px 8px;
		background-color: ${gray[100]};
		color: ${gray[800]};
		font-size: ${fontSize[12]};
		font-weight: ${fontWeight.regular};
		line-height: 100%;
	}

	.time {
		margin-bottom: 6px;
	}

	.wrapper {
		display: flex;
		justify-content: space-between;
	}

	.unit {
		color: ${gray[600]};
		font-size: ${fontSize[12]};
		font-weight: ${fontWeight.regular};
		line-height: 100%;
	}
`;

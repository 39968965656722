import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import type { userStatusType, productType, userRoleType } from 'common/types/getOrganizationList.type';
import {
	toggleIsMemberPermissionChangeFailToastVisible,
	toggleIsMemberPermissionChangeSuccessToastVisible,
} from 'common/context/toastSlice';
import { isAxiosError } from 'common/api/isAxiosError';

import { PutMemberPermissionApiError } from 'features/setting/types/putMemberPermission.type';
import { getAllMemberListThunk } from 'features/setting/thunk/getAllMemberList.thunk';

import type { MemberData } from '../types/getMemberList.type';

import putMemberPermissionApi from '../api/putMemberPermission.api';

export interface PutMemberPermissionThunkProps {
	memberKey: string;
	state: userStatusType;
	product: productType;
	role: userRoleType;
	onClose: () => void;
}

export interface PutMemberPermissionThunkResult extends MemberData {}

export const putMemberPermissionThunk = createAsyncThunk<
	PutMemberPermissionThunkResult,
	PutMemberPermissionThunkProps,
	{ state: RootState }
>('permission/put', async (args: PutMemberPermissionThunkProps, thunkAPI) => {
	const { memberKey, state, product, role, onClose } = args;

	const {
		account: { organizationId },
	} = thunkAPI.getState();

	try {
		const { data } = await putMemberPermissionApi({ organizationId, memberKey, state, product, role });

		onClose();

		thunkAPI.dispatch(toggleIsMemberPermissionChangeSuccessToastVisible());

		return { ...data };
		// eslint-disable-next-line
	} catch (error: any) {
		onClose();

		if (
			isAxiosError<PutMemberPermissionApiError>(error) &&
			(error.response?.data.errorCode === 'RO1000' ||
				error.response?.data.errorCode === 'RO1001' ||
				error.response?.data.errorCode === 'RO1002')
		) {
			thunkAPI.dispatch(toggleIsMemberPermissionChangeFailToastVisible());
			thunkAPI.dispatch(getAllMemberListThunk());
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

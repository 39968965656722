/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { ChangeEvent, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Textinput, Portal, Button, paletteSDS, Loader } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import { putProjectNameThunk } from 'features/nfSetting/thunk/putProjectName.v1.thunk';
import {
	projectNameFormReducer,
	initialProjectNameForm,
	UPDATE_PROJECT_NAME,
	VALIDATE_PROJECT_NAME,
	UPDATE_PROJECT_NAME_HELP_TEXT,
} from 'features/nfSetting/context/projectNameForm.reducer';
import { resetProjectError } from 'features/nfSetting/context/projectInfoSlice';

export default function UpdateProjectNameModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isLoading, error } = useSelector((state: RootState) => state.projectInfo);

	const { projectName, projectKey, selectedProjectId } = useSelector(
		(state: RootState) => state.modal.projectManagementModal
	);

	const [projectNameFormData, setProjectNameFormData] = useReducer(projectNameFormReducer, {
		...initialProjectNameForm,
		projectName,
	});

	const {
		projectName: enteredProjectName,
		isProjectNameChanged,
		isProjectNameValid,
		projectNameValidation,
		projectNameHelpText,
	} = projectNameFormData;

	const enteredProjectNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setProjectNameFormData({ type: UPDATE_PROJECT_NAME, payload: e.target.value });
	};

	const enteredProjectNameBlurHandler = () => {
		setProjectNameFormData({ type: VALIDATE_PROJECT_NAME });
	};

	const cancelButtonClickHandler = () => {
		dispatch(initializeProjectManagementModal());
	};

	const editProjectNameSubmitHandler: React.FormEventHandler = async e => {
		e.preventDefault();

		await dispatch(putProjectNameThunk({ projectName: enteredProjectName, selectedProjectId }));
	};

	useEffect(() => {
		if (error && error?.errorCode === 'P1001') {
			setProjectNameFormData({
				type: UPDATE_PROJECT_NAME_HELP_TEXT,
				payload: 'console.nfHome.projectNameAlreadyInUse',
			});
		}
	}, [error]);

	useEffect(() => {
		return () => {
			dispatch(resetProjectError());
		};
	}, [dispatch]);

	return (
		<Portal>
			<Modal visible title={t('modal.editProjectName')} actionType="none" isCloseIconVisible={false}>
				<form onSubmit={editProjectNameSubmitHandler} css={formWrapSt}>
					<Textinput
						label={t('commonWords.prjTitle')}
						maxLength={50}
						onChange={enteredProjectNameChangeHandler}
						value={enteredProjectName}
						validation={isProjectNameChanged ? projectNameValidation : 'none'}
						helpText={isProjectNameChanged && !isProjectNameValid && t(projectNameHelpText)}
						onBlur={enteredProjectNameBlurHandler}
						showIcon
					/>
					<Textinput value={projectKey} label={t('commonWords.prjKey')} readOnly />
					<div css={buttonWrapSt}>
						<Button
							type="button"
							onClick={cancelButtonClickHandler}
							mould="secondary"
							width="100%"
							disabled={isLoading}
						>
							{t('commonWords.cancel')}
						</Button>
						<Button
							type="submit"
							mould="custom"
							disabled={!isProjectNameValid || isLoading}
							width="100%"
							buttonDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
							buttonColor={paletteSDS.blue['700']}
							buttonHoverColor={paletteSDS.blue['800']}
							labelColor={paletteSDS.gray['50']}
						>
							{isLoading ? <Loader height="16px" /> : t('modal.projectNameSave')}
						</Button>
					</div>
				</form>
			</Modal>
		</Portal>
	);
}

const formWrapSt = css`
	display: flex;
	flex-direction: column;

	gap: 1rem;
`;

const buttonWrapSt = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	margin-top: 1.5rem;
`;

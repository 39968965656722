/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, DropdownElement, Dropdown } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { setSelectedProjectId } from 'features/plan/context/usageSlice';

export default function SaasUsageHeader() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { selectedProjectId, isUsageSummaryLoaded } = useSelector((state: RootState) => state.usage);

	const projectDropdownElement: DropdownElement[] = useSelector((state: RootState) =>
		state.projectInfo.projectList.map(el => ({ name: el.projectName, value: el.id }))
	);

	const projectIdChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		dispatch(setSelectedProjectId(Number(e.target.value)));
	};

	return (
		<div css={container}>
			<div css={titleSt}>{t('planSubscription.usageTitle')}</div>
			<Dropdown
				menu={projectDropdownElement}
				bindedValue={isUsageSummaryLoaded ? selectedProjectId : undefined}
				onChange={projectIdChangeHandler}
				size="medium"
				width="260px"
			/>
		</div>
	);
}

const container = css`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	height: 40px;
`;

const titleSt = css`
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 128%;
	letter-spacing: -0.008em;
	color: ${paletteSDS.gray[900]};
`;

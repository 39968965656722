import axios, { AxiosError } from 'axios';
import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import {
	NonKoreaCompanyAddress,
	PostOrganizationPaymentInfoApiResponse,
} from '../types/postOrganizationPaymentInfo.type';

export interface PostOrganizationPaymentInfoApiProps {
	cardNumber: string;
	expirateDate: string;
	birthDate?: string;
	cardPassword?: string;
	organizationId: number;
	cvc?: string;
	address?: NonKoreaCompanyAddress;
}

export default async function postOrganizationPaymentInfoApi({
	cardNumber,
	expirateDate,
	birthDate,
	cardPassword,
	organizationId,
	cvc,
	address,
}: PostOrganizationPaymentInfoApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.post<PostOrganizationPaymentInfoApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/payment/card`,
			{
				cardNumber,
				expiry: expirateDate,
				birth: birthDate,
				pwd2Digit: cardPassword,
				cvc,
				address,
			},
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Modal, Portal, paletteSDS } from 'surf-design-system';

import usePrompt from 'common/hooks/usePrompt';
import { useEffect } from 'react';

type VWRPromptModalProps = {
	when: boolean;
	isConfirmNavigate: boolean;
};

export default function VWRPromptModal({ when, isConfirmNavigate }: VWRPromptModalProps) {
	const { t } = useTranslation();

	const { showPrompt, cancelNavigation, confirmNavigation } = usePrompt(when);

	const secondaryButtonClickHandler = () => {
		cancelNavigation();
	};

	const primaryButtonClickHandler = () => {
		confirmNavigation();
	};

	useEffect(() => {
		if (isConfirmNavigate) {
			confirmNavigation();
		}
	}, [isConfirmNavigate, confirmNavigation]);

	return (
		<Portal>
			<Modal
				visible={showPrompt}
				top="30%"
				actionType="double"
				primaryBtnLabel={t('modal.segConfirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				onClickOverlay={secondaryButtonClickHandler}
				isCloseIconVisible={false}
			>
				<div css={textSt}>{t('modal.vwrPreviewConfirmMsg')}</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 1rem;

	color: ${paletteSDS.gray[900]};
`;

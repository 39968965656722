import axios from 'axios';

import type { GetOauthUserInfoApiResponse } from 'features/signup/types/getOauthUserInfo.type';

export interface GetOauthUserInfoApiProps {
	accessToken: string;
}

export default async function getOauthUserInfoApi({ accessToken }: GetOauthUserInfoApiProps) {
	const { data } = await axios.get<GetOauthUserInfoApiResponse>(`${process.env.REACT_APP_BASE_URL}/v1/user/oauth`, {
		headers: {
			Authorization: accessToken,
		},
	});
	return data;
}

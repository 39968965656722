/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import { CloseIcon, paletteSDS, themeSDS } from 'surf-design-system';

export interface SegmentUriElementProps {
	label: string;
	onClickCloseIcon: () => void;
}

export default function SegmentUriElement({ label, onClickCloseIcon }: SegmentUriElementProps) {
	return (
		<div css={container}>
			<div css={guideSt} />
			<div css={labelContainer}>
				<div css={labelSt}>{label}</div>
				<CloseIcon size="xSmall" onClick={onClickCloseIcon} />
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const guideSt = css`
	width: 4px;
	height: 24px;

	border-radius: 4px;

	background: ${paletteSDS.blue['100']};
`;

const labelSt = css`
	flex-grow: 1;

	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize['12']};
`;

const labelContainer = css`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { colorPalette, paletteSDS, themeSDS } from 'surf-design-system';

import { LicenseData } from 'features/license/types/getLicenseList.type';

interface LicenseTableProjectElementProps {
	data: LicenseData;
}

export default function LicenseTableProjectElement({ data }: LicenseTableProjectElementProps) {
	const { t } = useTranslation();

	let netfunnelStatusText = t('onPrem.license.fine');
	let netfunnelStatusColor = colorPalette.categoryColor.mint;

	if (data.licenseExpiredYn) {
		netfunnelStatusText = t('onPrem.license.byPass');
		netfunnelStatusColor = colorPalette.red['500'];
	}

	if (data.licenseReachedYn && !data.licenseExpiredYn) {
		netfunnelStatusText = t('onPrem.license.overageBypass');
		netfunnelStatusColor = colorPalette.red['500'];
	}

	return (
		<div css={containerCss}>
			<div css={titleCss}>{data.projectName}</div>
			<div css={domainCss}>{data.customerServiceDomain}</div>
			<div css={labelContainerCss}>
				<span>{t('onPrem.license.nfStatus')}</span>
				<span css={dividerSt} />
				<span css={netfunnelStatusColor}>{netfunnelStatusText}</span>
			</div>
			<div css={expiredCss}>
				<span>{t('onPrem.license.expirationDate')}</span>
				<span>{moment(data.licenseExpiredAt).utcOffset('+UTC:09:00').format('YYYY-MM-DD')}</span>
			</div>
		</div>
	);
}

const containerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	line-height: 140%;
`;

const titleCss = css`
	width: 100%;
	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const domainCss = css`
	color: ${paletteSDS.gray[600]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.light};
`;

const expiredCss = css`
	font-size: ${themeSDS.fontSize[12]};

	span:nth-of-type(1) {
		margin-right: 4px;
		color: ${paletteSDS.gray[900]};
		font-weight: ${themeSDS.fontWeight.semiBold};
	}

	span:nth-of-type(2) {
		color: ${paletteSDS.gray[700]};
		font-weight: ${themeSDS.fontWeight.light};
	}
`;

const labelContainerCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 25px;
	padding: 4px 8px;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	letter-spacing: 0.16px;
`;

const dividerSt = css`
	margin: 0 6px;
	width: 1px;
	height: 15px;
	background-color: ${paletteSDS.gray[300]};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link, paletteSDS, themeSDS } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';

export default function SigninTitle() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div css={authUpperContentsWrapSt}>
			<div css={authHeaderBtnWrapSt}>
				<div css={authUpperHeaderWrapSt}>
					<div css={titleSt}>{t('signIn.signIntoSurffy')}</div>
					<div css={labelWrapSt}>
						<div>{t('signIn.doNotHaveAnAccount')}</div>
						<Link size="medium" onClick={() => navigate(`/${getI18nPath()}/signup`)}>
							{t('signIn.signUp')}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

const authUpperContentsWrapSt = css`
	display: flex;
	flex-direction: column;
`;

const authHeaderBtnWrapSt = css`
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 2rem;
`;
const authUpperHeaderWrapSt = css`
	display: flex;
	flex-direction: column;

	gap: 1rem;
`;

const titleSt = css`
	text-align: center;
	letter-spacing: -0.008em;
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.gray[900]};
`;

const labelWrapSt = css`
	display: flex;
	gap: 0.25rem;

	& > span {
		line-height: 19px;
	}
`;

import axios from 'axios';

import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import { ONPREM_CENTER_API_PATH } from 'common/data/constants';

import type { PostLoginApiResponse } from 'features/signin/types/postLogin.onPrem.type';

export interface PostLoginApiProps {
	id: string;
	password: string;
}

export default async function postLoginOnPremApi({ id, password }: PostLoginApiProps) {
	const { data } = await axios.post<PostLoginApiResponse>(
		`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/login`,
		{
			id,
			password,
		}
	);

	return data;
}

import type { GetOrganizationListApiResponse } from 'common/types/getOrganizationList.type';
import { ONPREM_CENTER_API_PATH } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import axiosInstance from 'common/utils/axiosInstance';

export default async function getOrganizationListApi() {
	const { data } = await axiosInstance.get<GetOrganizationListApiResponse>('/v1/user/organizations', {
		baseURL:
			process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
				? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
				: process.env.REACT_APP_BASE_URL,
	});

	return data;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getBillingHistoryApi from '../api/getBillingHistory.api';

import type { BillingHistoryData } from '../types/getBillingHistory.type';

export const getBillingHistoryThunk = createAsyncThunk<BillingHistoryData[], void, { state: RootState }>(
	'billingHistory/get',
	async (_, thnkAPI) => {
		const {
			account: { organizationId },
		} = thnkAPI.getState();

		try {
			const { data } = await getBillingHistoryApi({ organizationId });

			return data;
			// eslint-disable-next-line
		} catch (error: any) {
			return thnkAPI.rejectWithValue(error.response.data);
		}
	}
);

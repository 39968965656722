/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { paletteSDS, TabElement, Tabs } from 'surf-design-system';

export default function NetfunnelEUMLayout() {
	const navigate = useNavigate();
	const location = useLocation();

	const { t } = useTranslation();
	const { pathname } = location;

	const [selectedTabId, setSelectedTabId] = useState<number | null>(null);

	const tabList: TabElement[] = [
		{
			id: 0,
			onClick: () => {
				navigate('transaction');
			},
			tabLabel: t('eum.transaction'),
		},
		{
			id: 1,
			onClick: () => {
				navigate('url');
			},
			tabLabel: 'URL',
		},
	];

	useEffect(() => {
		if (pathname.includes('transaction')) {
			setSelectedTabId(0);
		} else {
			setSelectedTabId(1);
		}
	}, [pathname]);

	return (
		<div css={container}>
			<div css={title}>EUM</div>
			<Tabs tabList={tabList} selectedTabId={selectedTabId} marginBottom="0" />
			<Outlet />
		</div>
	);
}

const container = css`
	flex: 1 0 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px;
`;

const title = css`
	color: ${paletteSDS.gray[900]};
	font-weight: 700;
	font-size: 24px;
	line-height: 140%;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProjectListApi } from 'common/api/getProjectList.api';
import type { ProjectInformation } from 'common/types/getProjectList.type';
import type { RootState } from 'common/redux/store';

interface GetProjectListThunkProps {
	initialProjectId?: number;
}

export interface GetProjectListThunkResult {
	projectMetaData: ProjectInformation[];
	initialProjectId?: number;
}

export const getProjectListThunk = createAsyncThunk<
	GetProjectListThunkResult,
	GetProjectListThunkProps,
	{ state: RootState }
>('prj/get/list', async (arg, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
	} = thunkAPI.getState();

	const { initialProjectId } = arg;

	try {
		const { data } = await getProjectListApi({ organizationId, tenantId: tenantId as string, tenantUrl });

		return { projectMetaData: data, initialProjectId };
		// eslint-disable-next-line
	} catch (error: any) {
		if (error.response?.data) {
			return thunkAPI.rejectWithValue(error.response.data);
		}

		return thunkAPI.rejectWithValue(error.message);
	}
});

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { GetBillingHistoryApiResponse } from '../types/getBillingHistory.type';

export interface GetBillingHistoryApiProps {
	organizationId: number;
}

export default async function getBillingHistoryApi({ organizationId }: GetBillingHistoryApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && organizationId) {
		const { data } = await axios.get<GetBillingHistoryApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/payment/charge/history`,
			{
				headers: {
					organizationId,
					userKey,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

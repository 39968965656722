import moment from 'moment';
import { paymentFlagType } from '../types/getBillingHistory.type';

export function addNumberComma(number: string) {
	const numberRegex = number.toString().split('.');
	numberRegex[0] = numberRegex[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return numberRegex.join('.');
}

/**
 * description: 언어에 따라 다른 날짜 형식 보여주기 (인보이스에서 사용)
 */
export function getStartDateFormat(date: string, language: string) {
	let dateFormat = '';

	const idx = 0;

	if (language === 'KOREAN') {
		dateFormat = moment(date).format('MM월 DD일');
	} else {
		dateFormat = moment(date).format('ll').split(',')[idx];
	}

	return dateFormat;
}

/**
 * description: 언어에 따라 다른 날짜 형식 보여주기
 */
export function getDateFormatLanguage(date: string | null, language: string) {
	let dateFormat = '';

	const convertedDate = date === null ? moment().format('YYYY-MM-DD') : date;

	if (language === 'KOREAN') {
		dateFormat = moment(convertedDate).format('YYYY년 MM월 DD일');
	} else {
		dateFormat = moment(convertedDate).format('ll');
	}

	return dateFormat;
}

export function getUsagePercentage(usage: number, limit: number) {
	const currentUsage = Math.ceil((usage / limit) * 100);

	if (Number.isNaN(currentUsage)) return 0;

	return currentUsage;
}

export const convertNumToKMB = (value: number) => {
	if (value < 1000) {
		return String(value);
	}

	if (value < 1000000) {
		const valueDivideK = value / 1000;

		return Number.isInteger(valueDivideK) ? `${valueDivideK}K` : `${valueDivideK.toFixed(1)}K`;
	}
	if (value < 1000000000) {
		const valueDivideM = value / 1000000;

		return Number.isInteger(valueDivideM) ? `${valueDivideM}M` : `${valueDivideM.toFixed(1)}M`;
	}
	const valueDivideB = value / 1000000000;

	return Number.isInteger(valueDivideB) ? `${valueDivideB}B` : `${valueDivideB.toFixed(1)}B`;
};

export function cardNumberFormat(value: string) {
	const number = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
	const matches = number.match(/\d{4,16}/g);
	const match = (matches && matches[0]) || '';
	const parts = [];

	for (let i = 0; i < match.length; i += 4) {
		parts.push(match.substring(i, i + 4));
	}

	if (parts.length) {
		return parts.join(' ');
	}
	return String(value);
}

export function companyNumberFormat(value: string, type: number) {
	let formatNum = '';

	try {
		if (value.length === 10) {
			if (type === 0) {
				formatNum = value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
			} else {
				formatNum = value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
			}
		}
	} catch (e) {
		formatNum = value;
	}
	return formatNum;
}

export function cardExpDateFormat(date: string) {
	const newDate = date.trim().replace(/-/g, '');

	if (newDate.length < 5) {
		return date;
	}
	if (newDate.length < 7) {
		return `${newDate.substring(0, 4)}-${newDate.substring(4, 6)}`;
	}

	return `${newDate.substring(0, 4)}-${newDate.substring(4, 6)}-${newDate.substring(6, 8)}`;
}

export function numberFormat(value: string) {
	return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
}

export function last4CardNumberFormat(value: number) {
	if (value > 999) {
		return value.toString();
	}

	if (value < 1000 && value > 99) {
		return `0${value}`;
	}

	if (value < 100 && value > 9) {
		return `00${value}`;
	}

	return `000${value}`;
}

export function priceUnitFormat(value: string): string {
	if (value === 'USD') {
		return '$';
	}

	if (value === 'KRW') {
		return '\u{20A9}';
	}

	return '';
}

export function paymentFlagFormat(value: paymentFlagType) {
	if (value === 'YES') {
		return 'planSubscription.billingStsPaid';
	}

	if (value === 'NO') {
		return 'planSubscription.billingStsUnpaid';
	}

	if (value === 'NOW') {
		return 'planSubscription.now';
	}

	return '';
}

export const refreshTimeFormat = (time: number) => {
	if (time === 0) {
		return 'planSubscription.justUpdated';
	}
	if (time === 1) {
		return 'planSubscription.updateTimeMin';
	}
	if (time > 1 && time < 60) {
		return 'planSubscription.updateTimeMin1';
	}
	if (time === 60) {
		return 'planSubscription.updateTimeHour';
	}

	return 'planSubscription.updateTimeHour1';
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from 'common/redux/store';
import getProjectUsageDetailApi, { period } from 'features/plan/api/getProjectUsageDetail.v2.api';

import { convertGetProjectUsageDetailResponse } from '../utils/usageChartFunc';

interface GetProjectUsageDetailThunkResult {
	daily: {
		basicMonitoring: {
			activeUser: number[];
			waitingUser: number[];
			connectingUser: number[];
			maxActiveUser: number;
			maxWaitingUser: number;
			maxConnectingUser: number;
		};
		pathControl: {
			activeUser: number[];
			waitingUser: number[];
			connectingUser: number[];
			maxActiveUser: number;
			maxWaitingUser: number;
			maxConnectingUser: number;
		};
	};
	monthly: {
		basicMonitoring: {
			activeUser: number[];
			waitingUser: number[];
			connectingUser: number[];
			maxActiveUser: number;
			maxWaitingUser: number;
			maxConnectingUser: number;
		};
		pathControl: {
			activeUser: number[];
			waitingUser: number[];
			connectingUser: number[];
			maxActiveUser: number;
			maxWaitingUser: number;
			maxConnectingUser: number;
		};
	};
	yearly: {
		startYear: number;
		endYear: number;
		basicMonitoring: {
			activeUser: number[];
			waitingUser: number[];
			connectingUser: number[];
			maxActiveUser: number;
			maxWaitingUser: number;
			maxConnectingUser: number;
		};
		pathControl: {
			activeUser: number[];
			waitingUser: number[];
			connectingUser: number[];
			maxActiveUser: number;
			maxWaitingUser: number;
			maxConnectingUser: number;
		};
	};
}

export const getProjectUsageDetailThunk = createAsyncThunk<
	GetProjectUsageDetailThunkResult,
	void,
	// eslint-disable-next-line
	{ state: RootState; rejectValue: { error: any; interval: period } }
>('projectUsage/get/v2', async (args, thunkAPI) => {
	const {
		account: { organizationId, tenantId },
		usage: { initialMomentValue, selectedProjectId },
	} = thunkAPI.getState();

	try {
		const [dailyResponse, monthlyResponse, yearlyResponse] = await Promise.all([
			getProjectUsageDetailApi({
				organizationId,
				interval: 'daily',
				date: Number(moment(initialMomentValue).format('X')),
				tenantId: tenantId as string,
				projectId: selectedProjectId,
			}),
			getProjectUsageDetailApi({
				organizationId,
				interval: 'monthly',
				date: Number(moment(initialMomentValue).format('X')),
				tenantId: tenantId as string,
				projectId: selectedProjectId,
			}),
			getProjectUsageDetailApi({
				organizationId,
				interval: 'yearly',
				date: Number(moment(initialMomentValue).format('X')),
				tenantId: tenantId as string,
				projectId: selectedProjectId,
			}),
		]);

		return convertGetProjectUsageDetailResponse(dailyResponse.data, monthlyResponse.data, yearlyResponse.data);
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

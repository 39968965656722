/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { colorPalette, paletteSDS, themeCss } from 'surf-design-system';

import { changeIsOveragesModalVisible } from 'common/context/modalSlice';

import PlanOptionName from './PlanOptionName';
import PlanOptionValue from './PlanOptionValue';
import { PlanInfo } from '../types/getPlanList.type';

/**
 * author: Jay
 * createdAt: 2022-08-08
 * updatedAt: 2022-08-08
 * description: plan 선택 항목
 * todo:
 */

interface PlanOptionProps {
	plans?: PlanInfo[];
}

export default function PlanOptionList({ plans }: PlanOptionProps) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const overagesClickHandler = () => {
		dispatch(changeIsOveragesModalVisible());
	};

	return (
		<div css={optionListContainerSt}>
			<div css={[overagesWrapSt, themeCss.fontSize[14], themeCss.fontWeight.medium, colorPalette.gray[900]]}>
				<span css={colorPalette.blue[700]}>*</span>
				<span css={[colorPalette.blue[700], underline]} onClick={overagesClickHandler} aria-hidden>
					{t('planSubscription.overages')}
				</span>
				&nbsp;{t('planSubscription.mayApply')}
			</div>
			<div css={optionContainerCss}>
				<PlanOptionName />
				<div css={optionValueCss}>
					{plans?.map((plan: PlanInfo) => (
						<PlanOptionValue key={plan.planId} plan={plan} />
					))}
				</div>
			</div>
		</div>
	);
}

const optionContainerCss = css`
	display: flex;
	gap: 0px;

	height: fit-content;

	border: 1px solid ${paletteSDS.gray[300]};

	border-radius: 8px;

	margin-bottom: 1.5rem;
`;

const optionValueCss = css`
	display: flex;
	gap: 0px;

	width: 100%;
`;

const optionListContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const overagesWrapSt = css`
	text-align: right;
`;

const underline = css`
	text-decoration: underline;
	cursor: pointer;
`;

import { createSlice } from '@reduxjs/toolkit';
import { FunnelData } from 'features/nfBasic/types/funnelLib.type';

import type { SegmentStatDataValues } from 'features/nfInterval/types/postIntervalSegmentStatData.type';

import { X_AXIS_NUM, X_AXIS_VALUE_INTERVAL } from '../data/constants';

import { SegmentMetaData } from '../types/getIntervalSegmentList.type';
import { putIntervalSegmentSettingThunk } from '../thunk/putIntervalSegmentSetting.thunk';
import { postIntervalSegmentThunk } from '../thunk/postIntervalSegment.thunk';
import { deleteIntervalSegmentGraphYnThunk } from '../thunk/deleteIntervalSegmentGraphYn.thunk';
import { postIntervalSegmentGraphYnThunk } from '../thunk/postIntervalSegmentGraphYn.thunk';
import { deleteIntervalSegmentThunk } from '../thunk/deleteIntervalSegment.thunk';

export type segmentConnection = 'disconnected' | 'connected' | 'hasConnected';

export interface ChartData {
	x: number;
	y: number | null;
}

export interface SegmentData {
	prevSegCardValue: SegmentStatDataValues;
	segCardValue: SegmentStatDataValues;
	waitTicketY: ChartData[];
	processTimeY: ChartData[];
	outflowRateY: ChartData[];
	maxWaitTicket: number;
	maxProcessTime: number;
	segmentGraphYn: boolean;
	segmentColor: string;
	id: number;
	segmentName: string;
	accessYn: boolean;
	segmentKey: string;
	classic: FunnelData;
}

export interface IntervalProjectData {
	classic: FunnelData;
}

export interface IntervalMonitoringSliceData {
	project: IntervalProjectData;
	segment: {
		meta: SegmentMetaData[];
		value: SegmentData[];
		isAnimate: boolean;
		isMetaLoaded: boolean;
		isValueLoaded: boolean;
		// eslint-disable-next-line
		error: any;
		isLoaded: boolean;
		connection: segmentConnection;
		isLoading: boolean;
	};
	lastTimeStamp: number;
}

const initialState: IntervalMonitoringSliceData = {
	project: {
		classic: {
			id: 'projectFunnel',
			type: 1,
			new_key_chk_enter: 0,
			wait_user: 0,
			block: 0,
			wait_tm: 0,
			chk_enter_succ: 0,
			limit: 0,
			complete_succ: 0,
			xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			used_size: 0,
			// 미사용
			bypass: 0,
			access_mode: 0,
			ic_access_mode: 0,
			wait_max: 10,
			limit_max: 100,
			limitmax: 500,
		},
	},
	segment: {
		meta: [],
		value: [],
		isAnimate: true,
		isLoaded: false,
		error: null,
		connection: 'connected',
		isMetaLoaded: false,
		isValueLoaded: false,
		isLoading: false,
	},
	lastTimeStamp: 0,
};

const intervalMonitoringSlice = createSlice({
	name: 'interval',
	initialState,
	reducers: {
		setInitialIntervalMonitoringData: () => {
			return { ...initialState };
		},
		cleanupSegmentRawData: state => {
			return {
				...state,
				segment: {
					...state.segment,
					value: state.segment.value.map(seg => ({
						...seg,
						waitTicketY: seg.waitTicketY.slice(seg.waitTicketY.length - X_AXIS_NUM - 5),
						outflowRateY: seg.outflowRateY.slice(seg.outflowRateY.length - X_AXIS_NUM - 5),
						processTimeY: seg.processTimeY.slice(seg.processTimeY.length - X_AXIS_NUM - 5),
					})),
					isAnimate: false,
				},
			};
		},
		resetSegmentError: state => {
			return { ...state, segment: { ...state.segment, error: null } };
		},
	},
	extraReducers(builder) {
		builder.addCase(putIntervalSegmentSettingThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(putIntervalSegmentSettingThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					error: null,
					meta: state.segment.meta.map(el =>
						el.id === action.payload.segmentId
							? {
									...el,
									segmentName: action.payload.segmentName,
									accessYn: action.payload.accessYn,
									maxInflow: action.payload.maxInflow,
									endUri: action.payload.endUri,
									startUri: action.payload.startUri,
							  }
							: el
					),
					value: state.segment.value.map(el => {
						if (el.id === action.payload.segmentId) {
							return {
								...el,
								segmentName: action.payload.segmentName,
								accessYn: action.payload.accessYn,
								segCardValue: { ...el.segCardValue, maxInflow: action.payload.maxInflow },
								classic: { ...el.classic, limit: action.payload.maxInflow },
							};
						}
						return el;
					}),
				},
			};
		});

		builder.addCase(putIntervalSegmentSettingThunk.rejected, (state, action) => {
			return { ...state, segment: { ...state.segment, isLoading: false, error: { ...(action.payload as object) } } };
		});

		builder.addCase(postIntervalSegmentThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(postIntervalSegmentThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					error: null,
					meta: state.segment.meta.concat({
						segmentColor: action.payload.segmentColor,
						id: action.payload.segmentId,
						segmentGraphYn: false,
						segmentKey: action.payload.segmentKey,
						segmentName: action.payload.segmentName,
						segmentOrder: state.segment.meta.length + 1,
						startUri: action.payload.startUri,
						endUri: action.payload.endUri,
						maxInflow: action.payload.maxInflow,
						accessYn: action.payload.accessYn,
					}),
					value: state.segment.value.concat({
						prevSegCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: action.payload.maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						segCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: action.payload.maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						segmentGraphYn: false,
						segmentColor: action.payload.segmentColor,
						segmentKey: action.payload.segmentKey,
						accessYn: action.payload.accessYn,
						segmentName: action.payload.segmentName,
						maxProcessTime: 0,
						maxWaitTicket: 0,
						id: action.payload.segmentId,
						classic: {
							id: action.payload.segmentKey,
							type: 1,
							new_key_chk_enter: 0,
							wait_user: 0,
							block: 0,
							wait_tm: 0,
							chk_enter_succ: 0,
							limit: action.payload.maxInflow,
							complete_succ: 0,
							xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							used_size: 0,
							// 미사용
							bypass: 0,
							access_mode: 0,
							ic_access_mode: 0,
							wait_max: 10,
							limit_max: 100,
							limitmax: 500,
						},
					}),
				},
			};
		});

		builder.addCase(postIntervalSegmentThunk.rejected, (state, action) => {
			return { ...state, segment: { ...state.segment, isLoading: false, error: { ...(action.payload as object) } } };
		});

		builder.addCase(deleteIntervalSegmentGraphYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					meta: state.segment.meta.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: false };
						}
						return el;
					}),
					value: state.segment.value.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: false };
						}
						return el;
					}),
					error: null,
				},
			};
		});

		builder.addCase(deleteIntervalSegmentGraphYnThunk.rejected, (state, action) => {
			if (action.payload?.error && action.payload.error?.errorCode === 'S1002') {
				return {
					...state,
					segment: {
						...state.segment,
						meta: state.segment.meta.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: false };
							}
							return el;
						}),
						value: state.segment.value.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: false };
							}
							return el;
						}),
						error: null,
					},
				};
			}
			return { ...state };
		});

		builder.addCase(postIntervalSegmentGraphYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					meta: state.segment.meta.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: true };
						}
						return el;
					}),
					value: state.segment.value.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: true };
						}
						return el;
					}),
					error: null,
				},
			};
		});

		builder.addCase(postIntervalSegmentGraphYnThunk.rejected, (state, action) => {
			if (action.payload?.error && action.payload.error?.errorCode === 'S1003') {
				return {
					...state,
					segment: {
						...state.segment,
						meta: state.segment.meta.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: true };
							}
							return el;
						}),
						value: state.segment.value.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: true };
							}
							return el;
						}),
						error: null,
					},
				};
			}

			return { ...state };
		});

		builder.addCase(deleteIntervalSegmentThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(deleteIntervalSegmentThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					meta: state.segment.meta
						.filter(el => el.id !== action.payload.id)
						.map(item => {
							if (item.segmentOrder < action.payload.segmentOrder) {
								return item;
							}
							return { ...item, segmentOrder: item.segmentOrder - 1 };
						}),
					value: state.segment.value.filter(el => el.id !== action.payload.id),
				},
			};
		});

		builder.addCase(deleteIntervalSegmentThunk.rejected, state => {
			return { ...state, segment: { ...state.segment, isLoading: false } };
		});
	},
});

export const { setInitialIntervalMonitoringData, cleanupSegmentRawData, resetSegmentError } =
	intervalMonitoringSlice.actions;
export default intervalMonitoringSlice.reducer;

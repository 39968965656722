import { ONPREM_AGENTS_PATH, ONPREM_ASSETS_PATH } from 'common/data/constants';
import { getOnPremBaseUrl, getOnPremAgentNFServer } from 'common/utils/onPremBaseURLFunc';

export const webAgentCodeBlock = (tenantId: string | null) => {
	return `<script>
    (function(w,d,s,uri,fn){
      w[fn] = w[fn] || function(){ var c = {}; c.tenantApiUrl = arguments[0]; c.tenantNFUrl = arguments[1]; c.tenantLoaderPort = arguments[2]; (w[fn].l = w[fn].l || []).push(c); };
      var o = d.createElement(s); var p = d.getElementsByTagName(s)[0]; o.async = 1; o.charset = 'utf-8'; o.src = uri; p.parentNode.insertBefore(o,p);
      })(window,document,'script','${getOnPremAgentNFServer()}/${ONPREM_AGENTS_PATH}/web/surffy-agent_latest.js','nfTag');
    nfTag('${getOnPremAgentNFServer()}/${ONPREM_ASSETS_PATH}/nf-setting/${tenantId}/nf-setting.json', '${getOnPremAgentNFServer()}', '443');
</script>`;
};

export const webAgentExampleBlock = () => {
	return `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>List</title>
    
      <!-- NetFUNNEL TagCode -->
      <script>
          (function(w,d,s,uri,fn){
            w[fn] = w[fn] || function(){ var c = {}; c.tenantApiUrl = arguments[0]; c.tenantNFUrl = arguments[1]; c.tenantLoaderPort = arguments[2]; (w[fn].l = w[fn].l || []).push(c); };
            var o = d.createElement(s); var p = d.getElementsByTagName(s)[0]; o.async = 1; o.charset = 'utf-8'; o.src = uri; p.parentNode.insertBefore(o,p);
            })(window,document,'script','https://example.netfunnel.co.kr/agents/web/surffy-agent_latest.js','nfTag');
          nfTag('https://example.netfunnel.co.kr/assets/nf-setting/1/nf-setting.json', 'https://example.netfunnel.co.kr', '443');
      </script>
        
      <!-- Project Code -->
      </head>
      <body>
      ...`;
};

export const aosAgentCodeBlock = (customerDomain: string | null, tenantId: string | null) => ({
	java: `    /* setting netfunnel config */ 
    public void nfInit() {
        Netfunnel netfunnel = Netfunnel.INSTANCE;
        /*
         * 1. AppName, 2. tenant API Server URL, 3. tenant Netfunnel Server URL, 4. netfunnel setting server URL,
         * 5. activity(waiting page on this activity), 6. context
         */
        netfunnel.Init("MyAppName", "${getOnPremBaseUrl()}", "${getOnPremBaseUrl()}", "${getOnPremBaseUrl(
		ONPREM_ASSETS_PATH
	)}/nf-setting/${tenantId}/nf-setting.json", this, this.getBaseContext());
    }

    public void useHttpURLConnection() {
        Netfunnel netfunnel = Netfunnel.INSTANCE;
        /*
         * Addmission check Action
         */
        AgentInterface myInterface = new AgentInterface() {
            @Override
            public void onSuccess() { // This function is where the operation is implemented after the wait time check is completed and the entry is allowed.
                new Thread() {
                    @Override
                    public void run() {
                        /**
                         * to do what you want to after the waiting (or non waiting)
                         * example, connect to another URL
                         */
                        java.net.URL url = null;
                        try {
                            url = new URL("https://${customerDomain}/login");
                            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
                            if (conn != null) {
                                conn.setConnectTimeout(10000);
                                conn.setReadTimeout(10000);
                                conn.setRequestMethod("GET");
                                conn.setDoInput(true);
                                InputStream inputStream = conn.getInputStream();
                                BufferedReader br = new BufferedReader(new InputStreamReader(inputStream, "UTF-8"));
                                conn.disconnect();
                            }
                        } catch (IOException e) {
                            e.printStackTrace();
                        }
                        super.run();
                        /*
                         * example end
                         */
                    }
                }.start();
            }
        };
       /*
        * the URL which you want to enter after Addmission check
        */
        netfunnel.nfStart("https://${customerDomain}/login", myInterface);/* netfunnel logic start! */
    }`,
	kotlin: `  /* setting netfunnel config */
    fun nfInit() {
        var netfunnel = NetFunnel.INSTANCE //object class - singleton
  
        /*
          * 1. AppName, 2. tenant API Server URL, 3. tenant Netfunnel Server URL, 4. netfunnel setting server URL,
          * 5. activity(waiting page on this activity), 6. context
          */
        netfunnel.Init(
            "MyAppName",
            "${getOnPremBaseUrl()}",
            "${getOnPremBaseUrl()}",
            "${getOnPremBaseUrl(ONPREM_ASSETS_PATH)}/nf-setting/${tenantId}/nf-setting.json",
            this,
            this.baseContext
        )
    }
  
    /*
      Netfunnel Logic Start
    */
    fun netfunnelStart() {
        /*
          * Action
          */
        val myInterface = object : AgentInterface() {
            override fun onSuccess() {  // call when no more waiting.
                object : Thread() {
                    override fun run() {
                        /**
                          * to do what you want to after no more waiting
                          * example, connect to another URL
                          */
                        try {
                            val url = URL("https://${customerDomain}/login")
                            val conn = url.openConnection() as HttpURLConnection
                            if (conn != null) {
                                conn.connectTimeout = 10000
                                conn.requestMethod = "GET"
                                conn.readTimeout = 10000
                                conn.doInput = true
                                val inputStream = conn.inputStream
                                val br = BufferedReader(InputStreamReader(inputStream, "UTF-8"))
                                conn.disconnect()
                            }
                        } catch (e: IOException) {
                            e.printStackTrace()
                        }
                        /*
                          * example end
                          */
                    }
                }.start()
            }
        }
        /*
         * the URL which you want to enter after Addmission check
         */
        Netfunnel.nfStart("https://${customerDomain}/login", myInterface)/* netfunnel logic start! */
    }`,
});

export const iosAgentCodeBlock = (tenantId: string | null) => {
	return `let agent = SurffyAgent.shared 
agent.setConfig(tenantURL: "${getOnPremBaseUrl()}",
                projectDetailURL: "${getOnPremBaseUrl(ONPREM_ASSETS_PATH)}/nf-setting/${tenantId}/nf-setting.json")
                
URLProtocol.registerClass(STCNFProtocol.self)`;
};

import { createSlice } from '@reduxjs/toolkit';
import type { OrganizationPlanData } from 'features/plan/types/getOrganizationPlan.type';

import { getOrganizationPlanInfoThunk } from 'features/plan/thunk/getOrganizationPlanInfo.thunk';

export interface PlanInfoData extends OrganizationPlanData {
	// eslint-disable-next-line
	error: any;
	isLoaded: boolean;
}

const initialState: PlanInfoData = {
	createdAt: '',
	period: 0,
	periodUnit: '',
	planId: 0,
	planOptions: [],
	planOverages: [],
	price: 0,
	priceUnit: '',
	productType: '',
	updatedAt: '',
	version: 0,
	using: false,
	expiredAt: null,
	isPaymentFailExists: false,
	error: null,
	isLoaded: false,
	priceType: 'FREE',
	usage: {
		activeUsers: 0,
		sectionActiveUsers: 0,
		waitingUsers: 0,
		sectionWaitingUsers: 0,
		peakConcurrentConnectingUsers: 0,
		sectionPeakConcurrentConnectingUsers: 0,
		peakConcurrentConnectingUserOverTotalCount: 0,
		peakConcurrentConnectingUserOverDays: 0,
		vwrCount: 0,
		sectionVwrCount: 0,
		admin: 0,
		operator: 0,
		monitor: 0,
	},
	planLangs: [],
};

const planInfoSlice = createSlice({
	name: 'planInfo',
	initialState,
	reducers: {
		setInitialPlanInfo: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getOrganizationPlanInfoThunk.fulfilled, (_, action) => {
			return { ...action.payload, error: null, isLoaded: true };
		});

		builder.addCase(getOrganizationPlanInfoThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoaded: true };
		});
	},
});

export const { setInitialPlanInfo } = planInfoSlice.actions;

export default planInfoSlice.reducer;

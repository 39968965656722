/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS } from 'surf-design-system';
import { RootState } from 'common/redux/store';
import useEUMChartOptions from 'features/nfEUM/hooks/useEUMChartOptions';
import URLChartBox from 'features/nfEUM/components/URLChartBox';

const { fontSize, fontWeight } = themeSDS;

export default function URLTotalProcessTimeChart() {
	const { t } = useTranslation();

	const { timeZone } = useSelector((state: RootState) => state.account);

	const {
		networkStatus: { isAnimate, maxProcessTime, processTimeY },
		hasError,
		isIntervalActivated,
	} = useSelector((state: RootState) => state.eum);

	const processTimeSeries: ApexAxisChartSeries = [
		{
			data: processTimeY.slice(),
			color: '#0D1F66',
			type: 'area',
		},
	];

	const { apexLineOption } = useEUMChartOptions({
		maxY: Math.max(9, maxProcessTime),
		unit: 's',
		decimalsInFloat: 1,
		timeZone,
		label: t('eum.totalProcessTime'),
		isAnimate,
		isConvertYValue: true,
		isIntervalActivated,
	});

	return (
		<URLChartBox>
			<div css={chartHeaderCss}>
				<span css={titleCss}>{t('eum.totalProcessTime')}</span>
				<span css={unitSt}>{t('eum.sec')}</span>
			</div>
			{processTimeSeries.length === 0 || hasError ? null : (
				<ReactApexChart series={processTimeSeries} options={apexLineOption} width="756px" height="346px" />
			)}
		</URLChartBox>
	);
}

const chartHeaderCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const titleCss = css`
	font-weight: 500;
	font-size: ${fontSize[16]};
	line-height: 160%;
`;

const unitSt = css`
	font-weight: ${fontWeight.medium};
	font-size: ${fontSize[14]};
	line-height: 100%;

	color: ${paletteSDS.gray['700']};
`;

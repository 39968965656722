import axios, { AxiosError } from 'axios';

import type { GetNotificationListApiResponse } from 'common/types/getNotificationList.type';
import type { languageType } from 'common/types/getUserProfile.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import { COGNITO_TOKEN, USER_KEY, LANG_HEADER, ONPREM_CENTER_API_PATH } from 'common/data/constants';

export interface GetNotificationListApiProps {
	pageNum: number;
	pageSize: number;
	organizationId: number;
	language: languageType;
}

export default async function getNotificationListApi({
	pageNum,
	pageSize,
	organizationId,
	language,
}: GetNotificationListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetNotificationListApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/user/notification-list`,
			{
				params: {
					pageNum,
					pageSize,
				},
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					lang: LANG_HEADER[language],
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putPathControlSegmentGraphInfoApi from 'features/nfControl/api/putPathControlSegmentGraphInfo.v2.api';

export interface PutPathControlSegmentGraphInfoThunkResult {
	id: number;
	segmentGraphYn: boolean;
}

export interface PostPathControlSegmentGraphInfoThunkProps {
	segmentId: number;
	segmentGraphYn: boolean;
}

const putPathControlSegmentGraphInfoThunk = createAsyncThunk<
	PutPathControlSegmentGraphInfoThunkResult,
	PostPathControlSegmentGraphInfoThunkProps,
	{ state: RootState }
>('put/path/segment/graph/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId, segmentGraphYn } = args;

	try {
		await putPathControlSegmentGraphInfoApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			segmentId,
			segmentGraphYn,
		});

		return { id: segmentId, segmentGraphYn };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ id: segmentId, error: error.response.data });
	}
});

export default putPathControlSegmentGraphInfoThunk;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css, SerializedStyles } from '@emotion/react';

import { paletteSDS } from 'surf-design-system';

export interface ColorCircleProps {
	size?: number;
	color?: string;
	emotionCss?: SerializedStyles | SerializedStyles[];
}

export default function ColorCircle({ size = 8, color = paletteSDS.red['500'], emotionCss }: ColorCircleProps) {
	return <div css={[colorCircleSt(size, color), emotionCss]} />;
}

const colorCircleSt = (size: number, color: string) => css`
	width: ${size}px;
	height: ${size}px;

	border-radius: 50%;

	background: ${color};
`;

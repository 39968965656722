/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, paletteSDS, Portal } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import { toggleIsPlanChangeConfirmModalVisible } from 'common/context/modalSlice';

interface PlanChangeConfirmModalProps {
	primaryButtonClickHandler: () => void;
}

/**
 * author: Jay
 * createdAt: 2022-08-03
 * updatedAt: 2022-08-03
 * description: 플랜 취소 모달 컴포넌트
 * todo: 다국어 적용, 결제 페이지 연결
 */
export default function PlanChangeConfirmModal({ primaryButtonClickHandler }: PlanChangeConfirmModalProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPlanChangeConfirmModalVisible } = useSelector((state: RootState) => state.modal);

	const { isLoading } = useSelector((state: RootState) => state.address);

	const secondaryButtonClickHandler = () => {
		dispatch(toggleIsPlanChangeConfirmModalVisible());
	};

	return (
		<Portal>
			<Modal
				visible={isPlanChangeConfirmModalVisible}
				isCloseIconVisible={false}
				title={t('modal.finalize')}
				actionType="double"
				primaryBtnLabel={t('modal.changePlan')}
				secondaryBtnLabel={t('modal.keepPlan')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				primaryBtnFunction={primaryButtonClickHandler}
				primaryBtnDisable={isLoading}
			>
				<p css={notiSt}>{t('modal.changePlanConfirmMsg')}</p>
			</Modal>
		</Portal>
	);
}

const notiSt = css`
	height: 3.25rem;
	line-height: 160%;
	color: ${paletteSDS.gray[900]};
`;

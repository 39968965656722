import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { GetVWRDataResponse } from '../types/getVWRData.type';

export interface GetVWRDataApiProps {
	tenantId: string;
	tenantUrl: string;
	projectId: number;
	organizationId: number;
}

export const getVWRDataApi = async ({ tenantId, tenantUrl, projectId, organizationId }: GetVWRDataApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken) {
		const { data } = await axios.get<GetVWRDataResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}/vwr-page`,
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
					'Cache-Control': 'no-store',
					Pragma: 'no-store',
					Expires: '0',
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

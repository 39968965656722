import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutProjectMacroInfoApiResponse } from 'features/nfSetting/types/putProjectMacroInfo.v2.type';

export interface PostProjectMacroInfoApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
	blockYn: boolean;
	blockType?: string;
	checkCycle?: number;
	limitNumPerSec?: number;
	limitNumPerCycle?: number;
	blockTime?: number;
}

export default async function putProjectMacroInfoApi({
	tenantId,
	organizationId,
	projectId,
	blockType,
	blockYn,
	checkCycle,
	limitNumPerCycle,
	limitNumPerSec,
	blockTime,
}: PostProjectMacroInfoApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutProjectMacroInfoApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/project/${projectId}/macro`,
			{ blockYn, blockType, checkCycle, limitNumPerCycle, limitNumPerSec, blockTime },
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import axios, { AxiosError } from 'axios';
import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PutSubscriptionApiResponse } from '../types/putSubscription.type';

export interface PutSubscriptionApiProps {
	organizationId: number;
}

export default async function putSubscriptionApi({ organizationId }: PutSubscriptionApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutSubscriptionApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/payment/unsubscribe`,
			{},
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProjectListApi } from 'features/nfSetting/api/getProjectList.v2.api';
import getProjectMacroInfoApi from 'features/nfSetting/api/getProjectMacroInfo.v1.api';
import type { ProjectInformation } from 'features/nfSetting/types/getProjectList.v2.type';
import type { RootState } from 'common/redux/store';

interface GetProjectListThunkProps {
	initialProjectId?: number;
	isKeepSelectedProjectId?: boolean;
}

export interface GetProjectListThunkResult {
	projectMetaData: ProjectInformation[];
	initialProjectId?: number;
	blockYn: boolean;
}

export const getProjectListThunk = createAsyncThunk<
	GetProjectListThunkResult,
	GetProjectListThunkProps,
	{ state: RootState }
>('prj/get/list/v2', async (arg, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();

	const { initialProjectId } = arg;

	try {
		const { data } = await getProjectListApi({ organizationId, tenantId: tenantId as string });

		const { data: macroData } = await getProjectMacroInfoApi({
			organizationId,
			tenantId: tenantId as string,
			projectId: data.length > 0 ? data[0].id : 1,
		});

		return { projectMetaData: data, initialProjectId, blockYn: macroData.blockYn };
		// eslint-disable-next-line
	} catch (error: any) {
		if (error.response?.data) {
			return thunkAPI.rejectWithValue(error.response.data);
		}

		return thunkAPI.rejectWithValue(error.message);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Textinput } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsUpdateMainDomainConfirmModalVisible } from 'common/context/modalSlice';
import type { ProjectInformation } from 'common/types/getProjectList.type';

import UpdateMainDomainConfirmModal from 'features/nfBasic/components/UpdateMainDomainConfirmModal';
import type { ProjectInformation as ProjectInformationV2 } from 'features/nfSetting/types/getProjectList.v2.type';
import { putServiceDomainThunk as putServiceDomainThunkV2 } from 'features/nfSetting/thunk/putServiceDomain.v1.thunk';
import {
	serviceDomainSettingFormReducer,
	UPDATE_CUSTOMER_DOMAIN,
	VALIDATE_CUSTOMER_DOMAIN,
	initialServiceDomainSettingForm,
	UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
} from '../context/serviceDomainForm.reducer';

import { putServiceDomainThunk } from '../thunk/putServiceDomain.thunk';

export default function ServiceDomainSettingForm() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const customerDomain = useSelector((state: RootState) =>
		state.plan.priceType === 'BYOL'
			? (
					state.projectInfo.projectList.find(
						el => el.id === state.projectInfo.selectedProjectId
					) as ProjectInformationV2
			  ).customerServiceDomain
			: (state.prjMeta.projectMetaData.find(el => el.id === state.prjMeta.selectedProjectId) as ProjectInformation)
					.customerServiceDomain
	);

	const error = useSelector((state: RootState) =>
		state.plan.priceType === 'BYOL' ? state.projectInfo.error : state.prjMeta.error
	);

	const { priceType } = useSelector((state: RootState) => state.plan);

	const [serviceDomainSettingFormData, setServiceDomainSettingFormData] = useReducer(serviceDomainSettingFormReducer, {
		...initialServiceDomainSettingForm,
		customerDomain: customerDomain || '',
	});

	const {
		customerDomain: enteredCustomerDomain,
		isCustomerDomainChanged,
		isCustomerDomainValid,
		customerDomainHelpText,
		customerDomainValidation,
	} = serviceDomainSettingFormData;

	const customerDomainChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setServiceDomainSettingFormData({ type: UPDATE_CUSTOMER_DOMAIN, payload: e.target.value });
	};

	const customerDomainBlurHandler = () => {
		setServiceDomainSettingFormData({ type: VALIDATE_CUSTOMER_DOMAIN });
	};

	const mainDomainUpdateConfirmHandler = () => {
		if (priceType === 'BYOL') {
			dispatch(putServiceDomainThunkV2({ enteredCustomerDomain }));
			return;
		}
		dispatch(putServiceDomainThunk({ enteredCustomerDomain }));
	};

	const serviceDomainFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();
		dispatch(toggleIsUpdateMainDomainConfirmModalVisible());
	};

	useEffect(() => {
		if (error && error?.errorCode === 'P1002') {
			setServiceDomainSettingFormData({
				type: UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
				payload: 'ServiceDomainSetting.errorServiceDomainMsg2',
			});
		}

		if (error && error?.errorCode === 'P1010') {
			setServiceDomainSettingFormData({
				type: UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
				payload: 'ServiceDomainSetting.errorServiceDomainMsg1',
			});
		}
	}, [error]);

	return (
		<React.Fragment>
			<form css={textBtnWrap} onSubmit={serviceDomainFormSubmitHandler}>
				<Textinput
					label={t('ServiceDomainSetting.serviceDomain')}
					width="100%"
					value={enteredCustomerDomain}
					onChange={customerDomainChangeHandler}
					onBlur={customerDomainBlurHandler}
					validation={isCustomerDomainChanged ? customerDomainValidation : 'none'}
					helpText={isCustomerDomainChanged && !isCustomerDomainValid && t(customerDomainHelpText)}
					showIcon
					maxLength={253}
				/>
				<Button size="large" mould="primary" width="100%" disabled={!isCustomerDomainValid}>
					{t('ServiceDomainSetting.serviceDomainDone')}
				</Button>
			</form>
			<UpdateMainDomainConfirmModal onConfirm={mainDomainUpdateConfirmHandler} />
		</React.Fragment>
	);
}

const textBtnWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	max-width: 25rem;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { dracula, CodeBlock } from 'react-code-blocks';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { paletteSDS, themeSDS, Button, FileDownloadIcon, Link } from 'surf-design-system';

import DividerWithTxt from 'common/components/DividerWithTxt';
import { aosAgentCodeBlock } from 'common/utils/codeBlockFunc';
import { fileDownloadFromUrl } from 'common/utils/fileFunc';
import { getI18nLanguage } from 'common/utils/languageFunc';
import { RootState } from 'common/redux/store';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import { getProductInfoApi } from 'common/api/getProductInfo.api';
import { AOS_PRODUCT_CODE } from 'common/data/constants';

interface InstallAOSAgentProps {
	titleFontSize?: string;
}

export default function InstallAOSAgent({ titleFontSize = '20px' }: InstallAOSAgentProps) {
	const { t } = useTranslation();

	const { response, sendRequest } = useApiRequestWithArg(getProductInfoApi);

	const [isCopied, setIsCopied] = useState(false);

	const { tenantUrl, customerDomain } = useSelector((state: RootState) => state.account);

	const [lang, setLang] = useState<'java' | 'kotlin'>('java');

	const javaClickHandler = () => {
		setIsCopied(false);
		setLang('java');
	};

	const kotlinClickHandler = () => {
		setIsCopied(false);
		setLang('kotlin');
	};

	const agentCode = aosAgentCodeBlock(tenantUrl, customerDomain)[lang];

	const linkClickHandler = () => {
		const i18nLanguage = getI18nLanguage();

		if (i18nLanguage === 'KOREAN') {
			window.open('https://app.archbee.com/public/PREVIEW-1S-PNQ3_nKKf6X3NAl_nd/PREVIEW-MlFfaSwWmW0eDGF6zEFyt ');
			return;
		}
		window.open('https://app.archbee.com/public/PREVIEW-Bz-n9CZknDmJxjwMgHzRC/PREVIEW-Xz1B5n__wECYuBiUCX8F6');
	};

	useEffect(() => {
		sendRequest({ productCode: AOS_PRODUCT_CODE });
	}, [sendRequest]);

	if (response === null) {
		return <div style={{ flex: 'auto' }} />;
	}

	const fileDownloadButtonClickHandler = async () => {
		await fileDownloadFromUrl(
			`${process.env.REACT_APP_AGENT_URL}/surffy-agent_${response.data.productVersion.replace('v', '')}.aar`,
			`surffy-agent_${response.data.productVersion.replace('v', '')}.aar`
		);
	};

	return (
		<div css={gap48Column}>
			<div css={gap8Column}>
				<div css={titleSt(titleFontSize)}>{t('agent.requirement')}</div>
				<div css={guideSt}>{t('agent.aosRequirementDescription')}</div>
				<div css={requirementBox}>{t('agent.aosRequirement')}</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={gap24Column}>
				<div css={gap8Column}>
					<div css={titleSt(titleFontSize)}>{t('agent.firstAOSTitle')}</div>
					<div css={guideSt}>{t('agent.firstAOSGuide')}</div>
				</div>
				<CodeBlock
					text={`implementation files('libs/surffy-agent_${response.data.productVersion.replace('v', '')}.aar')`}
					theme={dracula}
					language="java"
					codeBlock
					wrapLines
					customStyle={{
						backgroundColor: '#1E1F29',
						borderRadius: '8px',
						padding: '24px 16px',
					}}
				/>
				<Button
					mould="secondary"
					icon={<FileDownloadIcon fill={paletteSDS.blue['700']} />}
					iconPosition="right"
					onClick={fileDownloadButtonClickHandler}
					size="medium"
				>
					{t('agent.downloadInstallFile')}
				</Button>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={gap24Column}>
				<div css={gap8Column}>
					<div css={titleSt(titleFontSize)}>{t('agent.secondAOSTitle')}</div>
					<div css={guideSt}>
						<Trans
							components={{ bold: <Link inline size="small" onClick={linkClickHandler} /> }}
							i18nKey="agent.secondAOSGuide"
						/>
					</div>
					<div css={guideSt}>{t('agent.secondAOSGuideExample')}</div>
				</div>
				<div css={codeBlockContainer}>
					<div css={langBox}>
						<div css={lang === 'java' ? selectedLangSt : unSelectedLangSt} aria-hidden onClick={javaClickHandler}>
							Java
						</div>
						<div css={lang === 'kotlin' ? selectedLangSt : unSelectedLangSt} aria-hidden onClick={kotlinClickHandler}>
							Kotlin
						</div>
					</div>
					<div css={codeBlockBox}>
						{isCopied ? <div css={copiedSt}>Copied!</div> : null}
						<CopyToClipboard text={agentCode} onCopy={() => setIsCopied(true)}>
							<div css={copyButtonSt}>Copy</div>
						</CopyToClipboard>
						<CodeBlock
							text={agentCode}
							theme={dracula}
							language={lang}
							codeBlock
							wrapLongLines
							customStyle={{
								height: '428px',
								overflowY: 'scroll',
								backgroundColor: '#1E1F29',
								borderRadius: '8px',
								padding: '24px 16px',
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

const gap48Column = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap24Column = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const titleSt = (titleFontSize: string) => css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${titleFontSize};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	white-space: pre-line;
	word-break: break-all;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const requirementBox = css`
	display: flex;
	gap: 10px;

	height: 68px;

	padding: 12px 16px;

	background: ${paletteSDS.gray['50']};
	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray['200']};

	white-space: pre-line;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};
`;

const langBox = css`
	display: flex;
	gap: 16px;
	align-items: center;

	padding: 10.5px 24px 11.5px 24px;

	border-bottom: 1px solid ${paletteSDS.gray['800']};
`;

const selectedLangSt = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.white};

	cursor: pointer;
`;

const unSelectedLangSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.white};

	opacity: 0.24;

	cursor: pointer;
`;

const codeBlockContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	height: fit-content;

	background: #1e1f29;
	border-radius: 8px;
`;

const codeBlockBox = css`
	position: relative;
`;

const copyButtonSt = css`
	position: absolute;

	top: 0;
	right: 0;

	width: 61px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-left-radius: 8px;

	cursor: pointer;
`;

const copiedSt = css`
	position: absolute;

	top: 0;
	right: 61px;

	width: 75px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.blue['500']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

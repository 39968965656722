/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, backgroundPalette } from 'surf-design-system';

interface LicenseStatusLabelProps {
	licenseExpiredYn: boolean;
}
export default function LicenseStatusLabel({ licenseExpiredYn }: LicenseStatusLabelProps) {
	const { t } = useTranslation();

	let textKey = t('onPrem.license.active');
	let backgroundColor = backgroundPalette.categoryColor.mint;

	if (licenseExpiredYn) {
		textKey = t('onPrem.license.expired');
		backgroundColor = backgroundPalette.gray['500'];
	}

	return <div css={[labelContainerCss, backgroundColor]}>{textKey}</div>;
}

const labelContainerCss = css`
	padding: 4px 8px;
	color: ${paletteSDS.white};
	font-size: ${themeSDS.fontSize[13]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	letter-spacing: -0.008em;
	line-height: 128%;
	border-radius: 4px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

export default function FindPasswordTitle() {
	const { t } = useTranslation();

	return (
		<div css={authUpperContentsWrapSt}>
			<div css={authHeaderBtnWrapSt}>
				<div css={authUpperHeaderWrapSt}>
					<div css={titleSt}>{t('signIn.forgotYourPwd')}</div>
					<div css={labelWrapSt}>
						<div>{t('forgotPassword.pwdMsg')}</div>
					</div>
					<div css={mediumSt}>{t('modal.noForgotPwdMsg')}</div>
				</div>
			</div>
		</div>
	);
}

const authUpperContentsWrapSt = css`
	display: flex;
	flex-direction: column;
`;

const authHeaderBtnWrapSt = css`
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 2rem;
`;
const authUpperHeaderWrapSt = css`
	display: flex;
	flex-direction: column;

	gap: 1rem;
`;

const titleSt = css`
	text-align: center;
	letter-spacing: -0.008em;
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.gray[900]};
`;

const labelWrapSt = css`
	display: flex;
	gap: 0.25rem;

	line-height: 160%;
`;

const mediumSt = css`
	color: ${paletteSDS.gray['700']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	SideModal,
	Textinput,
	Button,
	Radio,
	Portal,
	paletteSDS,
	Helptext,
	CloseIcon,
	themeSDS,
	Label,
	SearchIcon,
	Dropdown,
	Loader,
	Modal,
} from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Astrix from 'common/components/Astrix';
import TooltipPortal from 'common/components/TootipPortal';
import DividerWithTxt from 'common/components/DividerWithTxt';
import ColorCircle from 'common/components/ColorCircle';
import Avatar from 'common/components/Avatar';
import { DEFAULT_IMG_URL, USER_KEY } from 'common/data/constants';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import { resetBasicControlModal } from 'common/context/modalSlice';

import { MAX_SEG_TITLE_LENGTH } from 'features/nfBasic/data/constants';
import {
	initialSegmentForm,
	segmentCreateDynamicFormOnPremReducer,
	UPDATE_NAME,
	VALIDATE_NAME,
	UPDATE_MAX_INFLOW,
	VALIDATE_MAX_INFLOW,
	UPDATE_URI,
	VALIDATE_URI,
	INITIALIZE_FORM,
	UPDATE_SEGMENT_TYPE,
	UPDATE_MIN_LIMITED_INFLOW,
	VALIDATE_MIN_LIMITED_INFLOW,
	UPDATE_MAX_LIMITED_INFLOW,
	VALIDATE_MAX_LIMITED_INFLOW,
	UPDATE_NORMAL_RANGE_START_VALUE,
	VALIDATE_NORMAL_RANGE_START_VALUE,
	UPDATE_NORMAL_RANGE_END_VALUE,
	VALIDATE_NORMAL_RANGE_END_VALUE,
	UPDATE_URI_HELP_TEXT,
	UPDATE_NAME_HELP_TEXT,
	UPDATE_ACCESS_YN,
	UPDATE_MEMBER_SEARCH_VALUE,
	UPDATE_SEARCHED_MEMBER_LIST,
	DELETE_MEMBER,
	ADD_MEMBER,
	UPDATE_VWR_ID,
	UPDATE_SELECTED_MEMBER_LIST,
} from 'features/nfControl/context/segmentCreateDynamicForm.onPrem.reducer';
import SegmentProcessTimeRangeGauge from 'features/nfBasic/components/SegmentProcessTimeRangeGauge';

import putBasicControlSegmentOnPremThunk from 'features/nfControl/thunk/putBasicControlSegment.v2.onPrem.thunk';
import { resetBasicControlError } from 'features/nfControl/context/nfControlSlice';
import getOperatorListOnPremApi from 'features/nfControl/api/getOperatorList.v2.onPrem.api';
import getSelectedOperatorListOnPremApi from 'features/nfControl/api/getSelectedOperatorList.v2.onPrem.api';
import postSegmentDuplicateApi from 'features/nfBasic/api/postSegmentDuplicate.api';
import { PostSegmentDuplicateApiResponseData } from 'features/nfBasic/types/postSegmentDuplicate.type';

export interface SegmentUpdateDynamicModalProps {
	isClassic?: boolean;
}

export default function SegmentUpdateDynamicModal({ isClassic = false }: SegmentUpdateDynamicModalProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isLoading, error } = useSelector((state: RootState) => state.nfControl.segment.basic);

	const { response: getOperatorListResponse, sendRequest: getOperatorList } =
		useApiRequestWithArg(getOperatorListOnPremApi);

	const { response: getSelectedOperatorListResponse, sendRequest: getSelectedOperatorListRequest } =
		useApiRequestWithArg(getSelectedOperatorListOnPremApi);

	const { tenantId, organizationId, userRole, language } = useSelector((state: RootState) => state.account);

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const mainDomain = useSelector(
		(state: RootState) =>
			state.projectInfo.projectList.find(el => el.id === state.projectInfo.selectedProjectId)?.customerServiceDomain
	);

	const {
		isUpdateModalVisible,
		initialData: {
			uris,
			segmentName: initialSegmentName,
			segmentType: initialSegmentType,
			dynamicSectionMaxSec,
			dynamicMinInflow,
			dynamicMaxInflow,
			dynamicSectionMinSec,
			vwrPageId,
			vwrPage,
			accessYn: initialAccessYn,
			id,
			maxInflow: initialMaxInflow,
			segmentKey,
		},
	} = useSelector((state: RootState) => state.modal.basicControl);

	const [isMemberSearchInputFocus, setIsMemberSearchInputFocus] = useState(false);
	const [isSelectedOperatorListIncludeUser, setIsSelectedOperatorListIncludeUser] = useState(false);

	const [segmentFormData, setSegmentFormData] = useReducer(segmentCreateDynamicFormOnPremReducer, {
		...initialSegmentForm,
	});

	const {
		segmentName,
		isSegmentNameValid,
		isSegmentNameChanged,
		segmentNameValidation,
		segmentNameHelpText,
		maxInflow,
		maxInflowHelpText,
		maxInflowValidation,
		isMaxInflowChanged,
		isMaxInflowValid,
		uri,
		isUriValid,
		isUriChanged,
		uriHelpText,
		uriValidation,
		accessYn,
		segmentType,
		isMaxLimitedInflowValid,
		isMaxLimitedInflowChanged,
		maxLimitedInflowValidation,
		maxLimitedInflowHelpText,
		maxLimitedInflow,
		isMinLimitedInflowValid,
		isMinLimitedInflowChanged,
		minLimitedInflowValidation,
		minLimitedInflowHelpText,
		minLimitedInflow,
		isNormalRangeStartValueValid,
		isNormalRangeStartValueChanged,
		normalRangeStartValueValidation,
		normalRangeStartValueHelpText,
		normalRangeStartValue,
		isNormalRangeEndValueValid,
		isNormalRangeEndValueChanged,
		normalRangeEndValueValidation,
		normalRangeEndValueHelpText,
		normalRangeEndValue,
		searchedMemberList,
		selectedMemberList,
		memberSearchValue,
		vwrId,
		disabledMemberList,
	} = segmentFormData;

	const closeIconClickHandler = () => {
		dispatch(resetBasicControlModal());
		dispatch(resetBasicControlError());
	};

	const uriChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSegmentFormData({ type: UPDATE_URI, payload: value });
	};

	const uriBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_URI });
	};

	const segmentNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NAME, payload: e.target.value });
	};

	const segmentNameBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NAME });
	};

	const maxInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MAX_INFLOW, payload: e.target.value });
	};

	const maxInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MAX_INFLOW });
	};

	const segmentTypeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_SEGMENT_TYPE, payload: e.target.value });
	};

	const minLimitedInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MIN_LIMITED_INFLOW, payload: e.target.value });
	};

	const minLimitedInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MIN_LIMITED_INFLOW });
	};

	const maxLimitedInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MAX_LIMITED_INFLOW, payload: e.target.value });
	};

	const maxLimitedInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MAX_LIMITED_INFLOW });
	};

	const rangeStartValueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NORMAL_RANGE_START_VALUE, payload: e.target.value });
	};

	const rangeStartValueBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NORMAL_RANGE_START_VALUE });
	};

	const rangeEndValueChaneHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NORMAL_RANGE_END_VALUE, payload: e.target.value });
	};

	const rangeEndValueBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NORMAL_RANGE_END_VALUE });
	};

	const accessYnChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_ACCESS_YN, payload: e.target.value });
	};

	const memberSearchValueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MEMBER_SEARCH_VALUE, payload: e.target.value });
	};

	const memberSearchInputFocusHandler = () => {
		setIsMemberSearchInputFocus(true);
	};

	const memberSearchInputBlurHandler = () => {
		setIsMemberSearchInputFocus(false);
	};

	const vwrChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setSegmentFormData({ type: UPDATE_VWR_ID, payload: e.target.value });
	};

	const isButtonDisabled =
		segmentType === 'STATIC'
			? (isMaxInflowChanged && !isMaxInflowValid) ||
			  (isSegmentNameChanged && !isSegmentNameValid) ||
			  (isUriChanged && !isUriValid) ||
			  isLoading ||
			  (userRole === 'OPERATOR' && !isSelectedOperatorListIncludeUser)
			: (isSegmentNameChanged && !isSegmentNameValid) ||
			  (isUriChanged && !isUriValid) ||
			  isLoading ||
			  !isNormalRangeEndValueValid ||
			  !isNormalRangeStartValueValid ||
			  !isMaxLimitedInflowValid ||
			  !isMinLimitedInflowValid ||
			  (userRole === 'OPERATOR' && !isSelectedOperatorListIncludeUser);

	const createSegmentFormSubmitHandler = async () => {
		// e.preventDefault();

		dispatch(
			putBasicControlSegmentOnPremThunk({
				segmentId: id,
				segmentType,
				segmentName,
				vwrPageId: vwrId,
				dynamicMaxInflow: Number(maxLimitedInflow),
				dynamicMinInflow: Number(minLimitedInflow),
				dynamicSectionMaxSec: Number(normalRangeEndValue),
				dynamicSectionMinSec: Number(normalRangeStartValue),
				deleteUriId: isUriChanged ? [{ id: uris[0].id }] : [],
				addUriPath: isUriChanged ? [uri] : [],
				accessYn,
				maxInflow,
				members: selectedMemberList.concat(disabledMemberList),
			})
		);
	};

	useEffect(() => {
		if (error && error?.errorCode === 'S1007') {
			setSegmentFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'modal.segTitleErrorMsg1' });
		}

		if (error && error?.errorCode === 'S1008') {
			setSegmentFormData({ type: UPDATE_URI_HELP_TEXT, payload: 'modal.addUrlErrorMsg3' });
		}

		if (error && error?.errorCode === 'S1016') {
			setSegmentFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'modal.segTitleErrorMsg1' });
			setSegmentFormData({ type: UPDATE_URI_HELP_TEXT, payload: 'modal.addUrlErrorMsg3' });
		}
	}, [error]);

	useEffect(() => {
		const searchTimeout = setTimeout(() => {
			getOperatorList({ tenantId: tenantId as string, organizationId, searchValue: memberSearchValue });
		}, 200);

		return () => {
			clearTimeout(searchTimeout);
		};
	}, [memberSearchValue, tenantId, organizationId, getOperatorList, isUpdateModalVisible, isMemberSearchInputFocus]);

	useEffect(() => {
		if (getOperatorListResponse) {
			const memberList = getOperatorListResponse.data.map(el => {
				return {
					...el,
					imageUrl: el.imageUrl,
				};
			});
			setSegmentFormData({
				type: UPDATE_SEARCHED_MEMBER_LIST,
				memberList,
				payload: memberSearchValue,
			});
		}
	}, [getOperatorListResponse, memberSearchValue]);

	useEffect(() => {
		setSegmentFormData({
			initial: {
				...initialSegmentForm,
				segmentName: initialSegmentName,
				maxInflow: initialMaxInflow.toString(),
				uri: uris.length > 0 ? uris[0].path : '',
				accessYn: initialAccessYn,
				segmentType: initialSegmentType,
				maxLimitedInflow: dynamicMaxInflow !== null ? dynamicMaxInflow.toString() : '',
				minLimitedInflow: dynamicMinInflow !== null ? dynamicMinInflow.toString() : '',
				normalRangeStartValue: (dynamicSectionMinSec || 2).toFixed(1),
				normalRangeEndValue: (dynamicSectionMaxSec || 5).toFixed(1),
				vwrId: vwrPageId,
				mainDomain: mainDomain || '',
				isMinLimitedInflowValid: true,
				isMaxLimitedInflowValid: (dynamicMinInflow || 0) < (dynamicMaxInflow || 0),
			},
			type: INITIALIZE_FORM,
		});
	}, [
		uris,
		initialAccessYn,
		initialSegmentType,
		initialSegmentName,
		initialMaxInflow,
		dynamicMaxInflow,
		dynamicMinInflow,
		dynamicSectionMaxSec,
		dynamicSectionMinSec,
		vwrPageId,
		mainDomain,
	]);

	useEffect(() => {
		if (isUpdateModalVisible) {
			getSelectedOperatorListRequest({ segmentId: id, tenantId: tenantId as string, organizationId });
		}
	}, [isUpdateModalVisible, getSelectedOperatorListRequest, id, tenantId, organizationId]);

	useEffect(() => {
		if (getSelectedOperatorListResponse) {
			const isSelectedOperatorListEmpty = getSelectedOperatorListResponse.data.length < 1;

			const isUserSelectedOperator =
				!isSelectedOperatorListEmpty &&
				getSelectedOperatorListResponse.data.map(el => el.userKey).includes(sessionStorage.getItem(USER_KEY) || '');

			setIsSelectedOperatorListIncludeUser(isUserSelectedOperator);

			setSegmentFormData({
				type: UPDATE_SELECTED_MEMBER_LIST,
				memberList: getSelectedOperatorListResponse.data
					.filter(el => el.state === 'ACTIVE')
					.map(el => {
						return {
							...el,
							imageUrl: el.imageUrl,
						};
					}),
			});
		}
	}, [getSelectedOperatorListResponse]);

	// URL 중복 검사
	const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(false);
	const [duplList, setDuplList] = useState<PostSegmentDuplicateApiResponseData[]>([]);

	const clickSegmentConfirmBtn = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await postSegmentDuplicateApi({
			tenantId: tenantId ?? '',
			organizationId,
			prjId: selectedProjectId,
			body: { url: uri, segmentId: id },
		})
			.then(res => {
				if (res.data.length > 0) {
					setDuplList(res.data);
					setIsShowDuplicateModal(true);
				} else {
					createSegmentFormSubmitHandler();
				}
			})
			.catch(err => console.error(err));
	};

	const clickUrlDuplModalConfirmBtn = () => {
		createSegmentFormSubmitHandler();
		setIsShowDuplicateModal(false);
	};

	const clickUrlDuplModalCancelBtn = () => {
		setIsShowDuplicateModal(false);
	};

	return (
		<Portal id="side-modal">
			<SideModal
				visible={isUpdateModalVisible}
				onClickOverlay={isLoading ? undefined : closeIconClickHandler}
				containerCss={css`
					display: flex !important;
					flex-direction: column;
				`}
				speed={0.2}
				// onSubmit={createSegmentFormSubmitHandler}
				onSubmit={clickSegmentConfirmBtn}
			>
				<div css={titleContainer}>
					<div css={titleSt}>{t('modal.segSettingModal')}</div>
					<CloseIcon onClick={isLoading ? undefined : closeIconClickHandler} />
				</div>
				<div css={inputContainer}>
					<Textinput
						name={t('commonWords.segTitle')}
						size="small"
						width="100%"
						label={
							<span>
								{t('commonWords.segTitle')}
								<Astrix />
							</span>
						}
						placeholder={t('commonWords.segTitle')}
						value={segmentName}
						maxLength={MAX_SEG_TITLE_LENGTH}
						validation={isSegmentNameChanged ? segmentNameValidation : 'none'}
						helpText={isSegmentNameChanged && !isSegmentNameValid && t(segmentNameHelpText)}
						onChange={segmentNameChangeHandler}
						onBlur={segmentNameBlurHandler}
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<Textinput
						readOnly
						name={t('commonWords.segKey')}
						size="small"
						width="100%"
						label={<span>{t('commonWords.segKey')}</span>}
						placeholder={t('commonWords.segKey')}
						value={segmentKey}
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<Radio
						groupName={t('projectMgmt.access')}
						groupLabel={
							<div css={accessModeCss}>
								{t('projectMgmt.access')}
								<TooltipPortal
									width="16px"
									height="16px"
									position="top"
									message={t('modal.blockPrjDescr')}
									top={224}
									right={language === 'ENGLISH' ? 115 : 122}
								/>
							</div>
						}
						radioList={[
							{
								id: `${isClassic ? 'classic' : 'default'}-${id}-access`,
								name: t('modal.segmentUnblock'),
								value: 'true',
								tabIndex: isUpdateModalVisible ? 0 : -1,
							},
							{
								id: `${isClassic ? 'classic' : 'default'}-${id}-block`,
								name: t('modal.segmentBlock'),
								value: 'false',
								tabIndex: isUpdateModalVisible ? 0 : -1,
							},
						]}
						direction="horizontal"
						selectedValue={accessYn.toString()}
						onChange={accessYnChangeHandler}
						labelStackDirection="horizontal"
					/>
					<Textinput
						name="URL"
						size="small"
						width="100%"
						label={
							<span>
								URL
								<Astrix />
							</span>
						}
						maxLength={253}
						value={uri}
						validation={isUriChanged ? uriValidation : 'none'}
						helpText={isUriChanged && !isUriValid && t(uriHelpText)}
						onChange={uriChangeHandler}
						onBlur={uriBlurHandler}
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<div
						css={[
							gap16px,
							css`
								position: relative;
							`,
						]}
					>
						<Textinput
							name="member"
							label={t('modal.segmentMember')}
							placeholder={t('modal.memberInputPlaceholder')}
							value={memberSearchValue}
							icon={
								isMemberSearchInputFocus ? (
									<CloseIcon
										fill={paletteSDS.gray['700']}
										size="xSmall"
										onMouseDown={e => e.preventDefault()}
										onClick={() => {
											setSegmentFormData({ type: UPDATE_MEMBER_SEARCH_VALUE, payload: '' });
										}}
									/>
								) : (
									<SearchIcon fill={paletteSDS.gray['700']} size="xSmall" cursor="default" />
								)
							}
							showIcon
							size="small"
							onChange={memberSearchValueChangeHandler}
							onFocus={memberSearchInputFocusHandler}
							onBlur={memberSearchInputBlurHandler}
							iconPosition={isMemberSearchInputFocus ? 'right' : 'left'}
							hideIconWhenFocus={false}
							disabled={userRole !== 'OWNER' && userRole !== 'ADMINISTRATOR'}
							tabIndex={isUpdateModalVisible ? 0 : -1}
						/>
						{selectedMemberList.length > 0 ? (
							<div css={selectedMemberContainer}>
								{selectedMemberList.map(el => (
									<div key={el.userKey} css={selectedMemberItemBox}>
										<div css={selectedMemberItem}>
											<Avatar
												imgUrl={el.imageUrl === DEFAULT_IMG_URL ? undefined : el.imageUrl}
												placeholder={el.id.charAt(0).toUpperCase()}
												width="16px"
												height="16px"
											/>
											<div>{el.name}</div>
										</div>
										{userRole === 'ADMINISTRATOR' || userRole === 'OWNER' ? (
											<CloseIcon
												size="xSmall"
												onClick={() => setSegmentFormData({ type: DELETE_MEMBER, member: { ...el } })}
												fill={paletteSDS.gray['700']}
											/>
										) : null}
									</div>
								))}
							</div>
						) : null}
						{isMemberSearchInputFocus ? (
							<div css={searchedMemberContainer} onMouseDown={e => e.preventDefault()} aria-hidden>
								{searchedMemberList.filter(el => el.state === 'ACTIVE').length > 0 ? (
									searchedMemberList
										.filter(el => el.state === 'ACTIVE')
										.map((item, index) => (
											<div
												key={item.userKey}
												css={searchedMemberItemBox(index, searchedMemberList.length)}
												aria-hidden
												onClick={() => {
													setSegmentFormData({ type: ADD_MEMBER, member: { ...item } });
												}}
											>
												<Avatar
													width="16px"
													height="16px"
													imgUrl={item.imageUrl === DEFAULT_IMG_URL ? undefined : item.imageUrl}
													placeholder={item.id.charAt(0).toUpperCase()}
												/>
												<div>{item.name}</div>
											</div>
										))
								) : (
									<div css={noResultSt}>{t('modal.noResult')}</div>
								)}
							</div>
						) : null}
					</div>
					<Dropdown
						menu={vwrPage.map(el =>
							el.defaultYn ? { name: t('vwr.default'), value: el.id } : { name: el.name, value: el.id }
						)}
						bindedValue={vwrId}
						onChange={vwrChangeHandler}
						label={t('modal.vwrDropdownLabel')}
						placeholder={t('modal.vwrDropdownPlaceholder')}
						size="small"
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
					<Radio
						groupName={t('modal.segmentType')}
						groupLabel={t('modal.segmentType')}
						radioList={[
							{
								id: `${isClassic ? 'classic' : 'default'}-${id}-static`,
								name: t('modal.staticSegment'),
								value: 'STATIC',
								tabIndex: isUpdateModalVisible ? 0 : -1,
							},
							{
								id: `${isClassic ? 'classic' : 'default'}-${id}-dynamic`,
								name: t('modal.dynamicSegment'),
								value: 'DYNAMIC',
								tabIndex: isUpdateModalVisible ? 0 : -1,
							},
						]}
						direction="horizontal"
						selectedValue={segmentType}
						onChange={segmentTypeChangeHandler}
						labelStackDirection="horizontal"
					/>
					{segmentType === 'DYNAMIC' ? (
						<div css={gap8px}>
							<div css={gap8px}>
								<Label>
									{t('projectMonitoring.limitedInflow')}
									<Astrix />
								</Label>
								<div css={gap4px}>
									<div css={limitedInflowInputBox}>
										<Label size="small">{t('modal.minLimitedInflow')}</Label>
										<Textinput
											width="100px"
											size="small"
											value={minLimitedInflow}
											valueType="number"
											onChange={minLimitedInflowChangeHandler}
											onBlur={minLimitedInflowBlurHandler}
											validation={isMinLimitedInflowChanged ? minLimitedInflowValidation : 'none'}
											showIcon
											max={99999999}
											min={0}
											step={1}
											tabIndex={isUpdateModalVisible ? 0 : -1}
										/>
									</div>
									{isMinLimitedInflowChanged && !isMinLimitedInflowValid && (
										<Helptext validation="failed">{t(minLimitedInflowHelpText)}</Helptext>
									)}
									<div css={limitedInflowInputBox}>
										<Label size="small">{t('modal.maxLimitedInflow')}</Label>
										<Textinput
											width="100px"
											size="small"
											value={maxLimitedInflow}
											valueType="number"
											onChange={maxLimitedInflowChangeHandler}
											onBlur={maxLimitedInflowBlurHandler}
											validation={isMaxLimitedInflowChanged ? maxLimitedInflowValidation : 'none'}
											showIcon
											max={99999999}
											min={0}
											step={1}
											tabIndex={isUpdateModalVisible ? 0 : -1}
										/>
									</div>
									{isMaxLimitedInflowChanged && !isMaxLimitedInflowValid && (
										<Helptext validation="failed">{t(maxLimitedInflowHelpText)}</Helptext>
									)}
								</div>
							</div>
							<div css={gap8px}>
								<Label>
									{t('modal.rangeProcessTimeTitle')}
									<Astrix />
								</Label>
								<div css={gap20px}>
									<SegmentProcessTimeRangeGauge
										normalRangeStartValue={normalRangeStartValue}
										normalRangeEndValue={normalRangeEndValue}
									/>
									<div css={gap6px}>
										<div css={rangeInputContainer}>
											<div css={rangeLabelSt(paletteSDS.categoryColor.mentis)}>{t('modal.goodRange')}</div>
											<div css={labelSt}>{'<'}</div>
											<Textinput
												width="68px"
												size="medium"
												valueType="number"
												step={0.1}
												value={normalRangeStartValue}
												onChange={rangeStartValueChangeHandler}
												onBlur={rangeStartValueBlurHandler}
												validation={isNormalRangeStartValueChanged ? normalRangeStartValueValidation : 'none'}
												max={100}
												min={0}
												tabIndex={isUpdateModalVisible ? 0 : -1}
											/>
											<div css={labelSt}>≤</div>
											<div css={rangeLabelSt(paletteSDS.categoryColor.yellow)}>{t('modal.normalRange')}</div>
											<div css={labelSt}>{'<'}</div>
											<Textinput
												width="68px"
												size="medium"
												step={0.1}
												valueType="number"
												value={normalRangeEndValue}
												onChange={rangeEndValueChaneHandler}
												onBlur={rangeEndValueBlurHandler}
												validation={isNormalRangeEndValueChanged ? normalRangeEndValueValidation : 'none'}
												max={100}
												min={0}
												tabIndex={isUpdateModalVisible ? 0 : -1}
											/>
											<div css={labelSt}>≤</div>
											<div css={rangeLabelSt(paletteSDS.categoryColor.redOrange)}>{t('modal.badRange')}</div>
										</div>
										{isNormalRangeStartValueChanged && !isNormalRangeStartValueValid && (
											<Helptext validation="failed">{t(normalRangeStartValueHelpText)}</Helptext>
										)}
										{isNormalRangeEndValueChanged && !isNormalRangeEndValueValid && (
											<Helptext validation="failed">{t(normalRangeEndValueHelpText)}</Helptext>
										)}
									</div>
									<Helptext validation="none">{t('modal.rangeValueDescription')}</Helptext>
									<div css={gap4px}>
										<div css={rangeDescriptionContainer}>
											<ColorCircle color={paletteSDS.categoryColor.mentis} size={7.5} />
											<div css={rangeDescriptionSt}>{t('modal.goodRangeDescription')}</div>
										</div>
										<div css={rangeDescriptionContainer}>
											<ColorCircle color={paletteSDS.categoryColor.yellow} size={7.5} />
											<div css={rangeDescriptionSt}>{t('modal.normalRangeDescription')}</div>
										</div>
										<div css={rangeDescriptionContainer}>
											<ColorCircle color={paletteSDS.categoryColor.redOrange} size={7.5} />
											<div css={rangeDescriptionSt}>{t('modal.badRangeDescription')}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<Textinput
							name={t('projectMonitoring.limitedInflow')}
							size="small"
							width="100%"
							label={
								<span>
									{t('projectMonitoring.limitedInflow')}
									<Astrix />
								</span>
							}
							value={maxInflow}
							max={99999999}
							min={0}
							step={1}
							validation={isMaxInflowChanged ? maxInflowValidation : 'none'}
							helpText={isMaxInflowChanged && !isMaxInflowValid && t(maxInflowHelpText)}
							valueType="number"
							onChange={maxInflowChangeHandler}
							onBlur={maxInflowBlurHandler}
							tabIndex={isUpdateModalVisible ? 0 : -1}
						/>
					)}
				</div>
				<div css={buttonContainer}>
					<Button
						width="100%"
						disabled={isButtonDisabled}
						buttonDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
						buttonColor={paletteSDS.blue['700']}
						buttonHoverColor={paletteSDS.blue['800']}
						labelColor={paletteSDS.gray['50']}
						mould="custom"
						tabIndex={isUpdateModalVisible ? 0 : -1}
					>
						{isLoading ? <Loader height="16px" /> : t('modal.segAddSave')}
					</Button>
				</div>
				<Portal id="modal">
					{isShowDuplicateModal && (
						<Modal
							visible
							isCloseIconVisible={false}
							title={t('modal.urlDuplicationTitle')}
							actionType="double"
							primaryBtnLabel={t('ServiceDomainSetting.confirm')}
							primaryBtnFunction={clickUrlDuplModalConfirmBtn}
							secondaryBtnLabel={t('ServiceDomainSetting.cancel')}
							secondaryBtnFunction={clickUrlDuplModalCancelBtn}
						>
							<div css={urlDuplContent}>
								<div css={urlDuplDesc}>{t('modal.urlDuplicationDescUpdate')}</div>
								<div css={urlDuplInfo}>
									<div css={urlDuplInfoTitle}>{t('modal.urlDuplicationInfo')}</div>
									<div css={urlDuplInfoList}>
										{duplList.map(el => {
											return (
												<div key={el.projectName + el.segmentName} css={urlDuplInfoRow}>
													<div css={urlDuplInfoItem}>
														<div css={urlDuplInfoItemKey}>{t('commonWords.prjTitle')}</div>
														<div css={urlDuplInfoItemValue}>{el.projectName}</div>
													</div>
													<div css={urlDuplInfoItem}>
														<div css={urlDuplInfoItemKey}>{t('commonWords.segTitle')}</div>
														<div css={urlDuplInfoItemValue}>{el.segmentName}</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</Modal>
					)}
				</Portal>
			</SideModal>
		</Portal>
	);
}

const titleContainer = css`
	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 64px;

	border-bottom: 1px solid ${paletteSDS.gray['300']};

	padding: 24px 16px 24px 16px;
`;

const titleSt = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['16']};
	color: ${paletteSDS.gray['900']};
`;

const inputContainer = css`
	flex: auto;

	display: flex;
	flex-direction: column;
	gap: 24px;

	padding: 24px;

	overflow: auto;
`;

const accessModeCss = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: ${paletteSDS.gray[600]};
`;

const buttonContainer = css`
	flex: none;
	display: flex;
	align-items: center;

	padding: 16px;

	border-top: 1px solid ${paletteSDS.gray['300']};
`;

const gap8px = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap4px = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const gap20px = css`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const gap6px = css`
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

const limitedInflowInputBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const rangeInputContainer = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const rangeLabelSt = (color: string) => css`
	display: flex;
	align-items: center;

	height: 25px;

	padding: 4px 8px;

	border-radius: 4px;

	background: ${color};

	color: ${paletteSDS.white};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['12']};
`;

const rangeDescriptionContainer = css`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const rangeDescriptionSt = css`
	font-size: ${themeSDS.fontSize['12']};
	color: ${paletteSDS.gray['700']};

	line-height: 140%;

	letter-spacing: 0.32px;
`;

const labelSt = css`
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['600']};
`;

const selectedMemberItem = css`
	display: flex;
	gap: 10px;
	align-items: center;

	color: ${paletteSDS.gray['900']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
`;

const selectedMemberContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	width: 100%;
	max-height: 72px;

	overflow: auto;
`;

const selectedMemberItemBox = css`
	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 24px;
`;

const searchedMemberContainer = css`
	position: absolute;

	top: 66px;

	display: flex;
	flex-direction: column;
	gap: 0;

	width: 100%;

	max-height: 202px;

	overflow: auto;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};

	border-radius: 8px;

	z-index: 2000;
`;

const searchedMemberItemBox = (index: number, length: number) => css`
	flex: none;

	display: flex;
	align-items: center;
	gap: 6px;

	width: 100%;
	height: 40px;

	padding: 11px 10px 11px 12px;

	border-bottom: ${index !== length - 1 && `1px solid ${paletteSDS.gray['400']}`};

	color: ${paletteSDS.gray['900']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};

	:hover {
		background: ${paletteSDS.gray['100']};
	}

	cursor: pointer;
`;

const noResultSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 40px;

	color: ${paletteSDS.gray['700']};

	font-size: ${themeSDS.fontSize['12']};
	font-weight: ${themeSDS.fontWeight.medium};

	background: ${paletteSDS.gray['50']};

	border-radius: 8px;
`;

const gap16px = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const urlDuplContent = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
	color: ${paletteSDS.gray[900]};
	width: 336px;
`;

const urlDuplDesc = css`
	font-size: 16px;
	font-weight: 400;
	line-height: 160%;
`;

const urlDuplInfo = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const urlDuplInfoTitle = css`
	font-size: 16px;
	font-weight: 500;
	line-height: 100%;
`;

const urlDuplInfoList = css`
	border-radius: 4px;
	border: 1px solid ${paletteSDS.gray[300]};
	width: 100%;
	max-height: 150px;
	background-color: ${paletteSDS.gray[75]};
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 18px;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		border: 6px solid transparent;
		border-radius: 100px;
		background-clip: padding-box;
		background-color: hsla(0, 0%, 0%, 0.48);
	}
`;

const urlDuplInfoRow = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 12px;
	:not(:last-of-type) {
		border-bottom: 1px solid ${paletteSDS.gray[200]};
	}
`;

const urlDuplInfoItem = css`
	display: flex;
`;

const urlDuplInfoItemKey = css`
	width: 110px;
	color: ${paletteSDS.gray[600]};
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: 0.16px;
`;

const urlDuplInfoItemValue = css`
	width: calc(100% - 110px);
	color: ${paletteSDS.gray[900]};
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: 0.16px;
	white-space: pre-wrap;
`;

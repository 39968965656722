/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { DeleteIcon, ShowChartIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { setIsSegmentGraphModalVisible } from 'common/context/modalSlice';

import ChangedAmount from 'features/nfBasic/components/ChangedAmount';
import ProcessStatusBar from 'features/nfBasic/components/ProcessStatusBar';
import FunnelText from 'features/nfBasic/components/FunnelText';
import { getSpeedStatus } from 'features/nfBasic/utils/funnelFunc';
import type { SegmentData } from 'features/nfControl/context/nfControlSlice';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';
import { calcOutflowRate } from 'features/nfInterval/utils/monitoringDataFunc';
import putPathControlSegmentGraphInfoThunk from 'features/nfControl/thunk/putPathControlSegmentGraphInfo.v2.thunk';
import getPathControlSegmentDetailThunk from 'features/nfControl/thunk/getPathControlSegmentDetail.v2.thunk';
import NetFunnelSegmentClassic from 'features/nfControl/components/NetFunnelSegmentClassic';
import OnPremPathSegmentDeleteModal from 'features/nfControl/components/OnPremPathSegmentDeleteModal';
import TextCopy from 'common/components/TextCopy';

interface PathControlSegmentCardProps {
	data: SegmentData;
	onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
	onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
	onDrop?: (e: React.DragEvent<HTMLDivElement>) => Promise<void>;
	meta: SegmentMetaData;
}

function OnPremPathControlSegmentCardClassic({
	data,
	onDragOver,
	onDragStart,
	onDrop,
	meta,
}: PathControlSegmentCardProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { projectList, selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { userRole } = useSelector((state: RootState) => state.account);

	const { activatedYn } = projectList[selectedProjectIndex];

	const showGraphSegmentNumber = useSelector((state: RootState) =>
		state.nfControl.segment.path.meta
			.map<number>(el => (el.segmentGraphYn ? 1 : 0))
			.reduce((prev, curr) => prev + curr, 0)
	);

	const [isSegmentDeleteModalVisible, setIsSegmentDeleteModalVisible] = useState(false);

	const speedStatus = getSpeedStatus(data.classic.wait_tm);
	let processTime = Math.ceil(data.segCardValue.processTime).toString();
	const changedAmount = Math.ceil(data.segCardValue.processTime) - Math.ceil(data.prevSegCardValue.processTime);

	if (!activatedYn) {
		processTime = '';
	}

	const debouncedThunk = useMemo(
		() =>
			debounce(() => {
				if (data.segmentGraphYn || (showGraphSegmentNumber < 5 && !data.segmentGraphYn)) {
					dispatch(putPathControlSegmentGraphInfoThunk({ segmentId: data.id, segmentGraphYn: !data.segmentGraphYn }));
					return;
				}

				dispatch(setIsSegmentGraphModalVisible(true));
			}, 300),
		[dispatch, data.segmentGraphYn, data.id, showGraphSegmentNumber]
	);

	const inflowIconClickHandler = useCallback(() => {
		debouncedThunk();
	}, [debouncedThunk]);

	const settingButtonClickHandler = () => {
		if (userRole !== 'MONITOR' && userRole !== 'NONE') {
			dispatch(getPathControlSegmentDetailThunk({ segmentId: data.id }));
		}
	};

	const deleteIconClickHandler = () => {
		if (userRole !== 'MONITOR' && userRole !== 'NONE' && userRole !== 'OPERATOR') {
			setIsSegmentDeleteModalVisible(prevDelete => !prevDelete);
		}
	};

	return (
		<div css={SegmentCardCss} draggable onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop}>
			<div css={SegmentCardCss}>
				<div css={headerCss}>
					<div css={nameCss}>
						<div css={dotCss(data.segmentColor)} />
						<div css={segmentNameSt}>{data.segmentName}</div>
						<button css={graphIconCss} onClick={inflowIconClickHandler}>
							<ShowChartIcon size="small" fill={!data.segmentGraphYn ? paletteSDS.gray[300] : paletteSDS.blue[700]} />
						</button>
					</div>
					<TextCopy width="208px" text={data.segmentKey} />
				</div>
				<div>
					<ProcessStatusBar
						value={data.classic.wait_tm === 0 ? 0 : Number(data.classic.wait_tm.toFixed(1))}
						isBlocked={!data.accessYn}
						disabled={!activatedYn}
					/>
				</div>

				<div css={cardBodyCss}>
					<div css={cardContentCss}>
						<NetFunnelSegmentClassic funnelData={data.classic} speedStatus={speedStatus} disabled={!activatedYn} />

						<React.Fragment>
							<FunnelText
								name={t('projectMonitoring.waitTickets')}
								value={activatedYn ? data.classic.new_key_chk_enter : data.classic.bypass}
								top={16}
								right={0}
							/>
							<FunnelText
								name={t('projectMonitoring.queue')}
								value={data.classic.wait_user}
								top={42}
								right={0}
								textColor={paletteSDS.blue[400]}
								disabled={!activatedYn}
							/>
							<FunnelText
								name={t('projectMonitoring.waitTime')}
								value={data.classic.wait_tm}
								top={90}
								left={0}
								unit="s"
								decimal={1}
								disabled={!activatedYn}
							/>
							<FunnelText
								name={t('projectMonitoring.inflow')}
								value={data.classic.chk_enter_succ}
								top={142}
								right={0}
								disabled={!activatedYn}
							/>
							<FunnelText name={t('projectMonitoring.limitedInflow')} value={data.classic.limit} top={174} right={0} />
							<FunnelText
								name={t('projectMonitoring.outflow')}
								value={data.classic.complete_succ}
								top={284}
								right={0}
								disabled={!activatedYn}
							/>
							<FunnelText
								name={t('projectMonitoring.outflowRate')}
								value={calcOutflowRate(data.segCardValue.totalOutflow, data.segCardValue.totalInflow)}
								top={348}
								left={0}
								unit="%"
								disabled={!activatedYn}
							/>
							<FunnelText
								name={t('projectMonitoring.prcsTime')}
								value={Number(processTime)}
								top={348}
								right={0}
								unit="s"
								disabled={!activatedYn}
							/>
						</React.Fragment>

						{activatedYn && (
							<div css={changeAmountCss}>
								<ChangedAmount value={changedAmount} disabled={!activatedYn} />
							</div>
						)}
					</div>
				</div>

				<div css={graphCheckboxCss}>
					<button css={settingSt} onClick={settingButtonClickHandler} aria-hidden>
						{t('projectMonitoring.settings')}
					</button>
					<button
						css={deleteIconCss(userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR')}
						onClick={deleteIconClickHandler}
					>
						<DeleteIcon
							fill={
								userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR'
									? paletteSDS.gray[300]
									: paletteSDS.blue[700]
							}
							size="small"
							cursor={
								userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR' ? 'not-allowed' : 'pointer'
							}
						/>
					</button>
				</div>
				<OnPremPathSegmentDeleteModal
					visible={isSegmentDeleteModalVisible}
					id={meta.id}
					segmentOrder={meta.segmentOrder}
					segmentName={meta.segmentName}
					onClose={deleteIconClickHandler}
				/>
			</div>
		</div>
	);
}

export default React.memo(OnPremPathControlSegmentCardClassic);

const { fontSize, fontWeight } = themeSDS;
const { gray, systemColor } = paletteSDS;

const SegmentCardCss = css`
	display: flex;
	flex-direction: column;
	width: 222px;
	height: 518px;
	background-color: ${systemColor.white};
	border-right: 1px solid ${gray[300]};
	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;
`;

const headerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	height: 72px;
	padding: 0 8px;

	& > svg {
		cursor: pointer;

		&:hover {
			fill: ${paletteSDS.blue[600]};
		}
	}
`;

const nameCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	height: 40px;
`;

const segmentNameSt = css`
	width: 155px;
	height: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const dotCss = (color: string) => css`
	width: 10px;
	height: 10px;
	background-color: ${color};
	border-radius: 50%;
`;

const cardBodyCss = css`
	padding: 0px 12px;
`;

const cardContentCss = css`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 370px;
`;

const changeAmountCss = css`
	position: absolute;
	top: 332px;
	right: -7px;
	z-index: 1005;
`;

const graphCheckboxCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 48px;
	padding: 8px 0px;
	background-color: ${gray[75]};
	color: ${gray[500]};
`;

const settingSt = css`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 12px;

	height: 36px;

	color: ${paletteSDS.blue[700]};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 100%;
	cursor: pointer;
	text-align: center;
	border: none;
	background-color: ${gray[75]};
	&:focus-visible {
		outline: 2px solid ${paletteSDS.blue[500]};
		outline-offset: -3px;
		border-radius: 6px;
	}
	&:hover {
		color: ${paletteSDS.blue[600]};
	}
	&:active {
		color: ${paletteSDS.blue[800]};
	}
`;

const graphIconCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 4px;
	border: none;
	background-color: ${systemColor.white};

	width: 32px;
	height: 32px;
	&:focus-visible {
		outline: 2px solid ${paletteSDS.blue[500]};
		outline-offset: -3px;
		border-radius: 6px;
	}

	& > svg {
		&:hover {
			fill: ${paletteSDS.blue[600]};
		}

		&:active {
			fill: ${paletteSDS.blue[800]};
		}
	}
`;

const deleteIconCss = (disabled: boolean) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 12px;
	border: none;
	background-color: ${gray[75]};

	height: 40px;
	&:focus-visible {
		outline: 2px solid ${paletteSDS.blue[500]};
		outline-offset: -3px;
		border-radius: 6px;
	}

	& > svg {
		&:hover {
			fill: ${!disabled && paletteSDS.blue[600]};
		}

		&:active {
			fill: ${!disabled && paletteSDS.blue[800]};
		}
	}
`;

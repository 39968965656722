/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { addNumberComma } from 'features/plan/utils/formatFunc';

const { gray, blue } = paletteSDS;
const { fontWeight, fontSize } = themeSDS;

interface InfoLineProps {
	label: string;
	value: number;
	disabled?: boolean;
}

export default function ProjectSummaryInfoLine({ label, value, disabled = false }: InfoLineProps) {
	return (
		<li css={infoLineCss}>
			<div css={labelCss(disabled)}>{label}</div>
			{!disabled && <div css={valueCss}>{addNumberComma(Math.ceil(value).toString())}</div>}
		</li>
	);
}

const infoLineCss = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	gap: 15px;

	width: 100%;
	height: 49px;
`;

const labelCss = (disabled: boolean) => css`
	width: 144px;

	color: ${disabled ? gray[300] : gray[700]};
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.medium};
	white-space: nowrap;

	align-self: flex-start;
`;

const valueCss = css`
	color: ${blue[600]};
	font-size: ${fontSize[20]};
	font-weight: ${fontWeight.bold};
	line-height: 100%;

	align-self: flex-end;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import type { RootState } from 'common/redux/store';
import getEUMMetricsApi from 'features/nfEUM/api/getEUMMetric.api';
import { GetEUMMetricsApiData } from 'features/nfEUM/types/getEUMMetrics.type';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface GetTXNMetricsThunkResult {
	metricsData: GetEUMMetricsApiData[];
}

export const getTXNMetricsThunk = createAsyncThunk<GetTXNMetricsThunkResult, void, { state: RootState }>(
	'eum/txn/metrics/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantUrl, organizationId, customerDomain },
			eumTXNMetrics: { beginTime, endTime, sortColumn, sortType },
			projectInfo: { projectList, selectedProjectId },
			plan: { priceType },
		} = thunkAPI.getState();

		const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
		const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

		try {
			const apiResponse = await getEUMMetricsApi({
				tenantUrl,
				organizationId,
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				beginTime,
				endTime,
				sortColumn,
				sortType: sortType[sortColumn],
				projectKey: selectedProjectKey,
			});
			thunkAPI.dispatch(setInitialToast());
			return {
				metricsData: apiResponse.data,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DeleteIcon, EditIcon, paletteSDS } from 'surf-design-system';

import Table from 'common/components/Table';
import type { TableColumn, TableRow } from 'common/components/Table';
import { RootState, useAppDispatch } from 'common/redux/store';
import { setIsDeleteVWRModalVisible } from 'common/context/modalSlice';

export default function VWRTable() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { vwrList } = useSelector((state: RootState) => state.vwr2);

	const { userRole } = useSelector((state: RootState) => state.account);

	const vwrColumns: TableColumn[] = useMemo(
		() => [
			{
				id: 1,
				label: t('vwr.vwrName'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '490px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 2,
				label: t('vwr.createdAt'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '120px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 3,
				label: t('vwr.updatedAt'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '120px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 4,
				label: '',
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '90px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 5,
				label: '',
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '90px',
				elementCss: css`
					flex: none;
				`,
			},
		],
		[t]
	);

	const vwrRows: TableRow[] = vwrList.map(el => ({
		id: el.id,
		items: [
			{
				elementId: 1,
				element: el.defaultYn ? t('vwr.default') : <span>{el.name}</span>,
				padding: '0px 16px',
				width: '490px',
				elementCss: css`
					flex: none;
					background: ${paletteSDS.gray['50']};

					& > div > span {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				`,
			},
			{
				elementId: 2,
				element: moment.utc(el.createdAt).format('yyyy-MM-DD'),
				padding: '0px 16px',
				width: '120px',
				elementCss: css`
					flex: none;
					background: ${paletteSDS.gray['50']};
				`,
			},
			{
				elementId: 3,
				element: moment.utc(el.updatedAt).format('yyyy-MM-DD'),
				padding: '0px 16px',
				width: '120px',
				elementCss: css`
					flex: none;
					background: ${paletteSDS.gray['50']};
				`,
			},
			{
				elementId: 4,
				element: (() => {
					const editIconClickHandler = async () => {
						// todo: vwr list 갱신 후 이동

						// navigate(`/${LANG_PATH[language]}/saas/console/product/nf/${selectedProjectId}/settings/vwr/edit/${el.id}`);
						navigate(`edit/${el.id}`);
					};

					return <EditIcon size="small" fill={paletteSDS.blue['700']} onClick={editIconClickHandler} />;
				})(),
				width: '90px',
				elementCss: css`
					flex: none;
					background: ${paletteSDS.gray['50']};
					padding: 0;
					& > div {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				`,
			},
			{
				elementId: 5,
				element: (() => {
					if (el.defaultYn || (userRole !== 'OWNER' && userRole !== 'ADMINISTRATOR')) {
						return '-';
					}

					const deleteIconClickHandler = async () => {
						dispatch(setIsDeleteVWRModalVisible({ name: el.name, id: el.id }));
					};

					return <DeleteIcon size="small" fill={paletteSDS.blue['700']} onClick={deleteIconClickHandler} />;
				})(),
				width: '90px',
				elementCss: css`
					flex: none;
					background: ${paletteSDS.gray['50']};
					padding: 0;
					& > div {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				`,
			},
		],
		rowCss: css`
			background: ${paletteSDS.gray['50']};
		`,
	}));

	return <Table columns={vwrColumns} rows={vwrRows} />;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { setIsSegmentMaxNumModalVisible } from 'common/context/modalSlice';
import { PLAN_FREE_BETA_ID, PLAN_FREE_TRIAL_ID, PLAN_MOIS_2022_ID } from 'common/data/constants';
import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';

export default function SegmentMaxNumModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isSegmentMaxNumModalVisible } = useSelector((state: RootState) => state.modal);

	const { planOptions, isLoaded, planId, planLangs } = useSelector((state: RootState) => state.plan);

	const { language } = useSelector((state: RootState) => state.account);

	const planLang = planLangs.find(el => el.langType === language) as PlanLangs;

	const primaryButtonClickHandler = () => {
		dispatch(setIsSegmentMaxNumModalVisible(false));
	};

	let textKey = 'modal.segNumPlan';

	if (planId === PLAN_FREE_TRIAL_ID) {
		textKey = 'modal.segNumFreeTrial';
	}

	if (planId === PLAN_MOIS_2022_ID) {
		textKey = 'mois.segmentMaxNum';
	}

	if (planId === PLAN_FREE_BETA_ID) {
		textKey = 'freeBeta.maxSegmentNumber';
	}

	return (
		<Portal>
			<Modal
				visible={isSegmentMaxNumModalVisible}
				actionType="single"
				primaryBtnFunction={primaryButtonClickHandler}
				primaryBtnLabel={t('modal.segNumConfirm')}
				isCloseIconVisible={false}
			>
				<div css={modalTxtSt}>
					{t(textKey, {
						segNum: isLoaded ? planOptions[3].value : 1,
						plan: planLang.name,
					})}
				</div>
			</Modal>
		</Portal>
	);
}

const modalTxtSt = css`
	margin-top: 16px;

	text-align: center;
	white-space: break-spaces;
	line-height: 160%;
`;

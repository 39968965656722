import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import { closeVWRModal, toggleIsDeleteVWRCompleteModalVisible } from 'common/context/modalSlice';

import { deleteVWRApi } from 'features/vwr/api/deleteVWR.v2.api';

interface DeleteVWRNameThunkResult {
	id: number;
}

export const deleteVWRThunk = createAsyncThunk<DeleteVWRNameThunkResult, void, { state: RootState }>(
	'vwr/delete/v2',
	async (args, thunkAPI) => {
		const { id } = thunkAPI.getState().modal.vwr;

		try {
			const {
				account: { tenantId, organizationId },
				projectInfo: { selectedProjectId },
			} = thunkAPI.getState();

			await deleteVWRApi({
				tenantId: tenantId as string,
				projectId: selectedProjectId,
				organizationId,
				id,
			});

			thunkAPI.dispatch(closeVWRModal());
			thunkAPI.dispatch(toggleIsDeleteVWRCompleteModalVisible());

			return { id };
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { RootState, useAppDispatch } from 'common/redux/store';
import { useSelector } from 'react-redux';
import { paletteSDS } from 'surf-design-system';
import { setShareTab, ShareTab } from 'features/nfEUM/context/eumTXNShareSlice';
import { useTranslation } from 'react-i18next';
import TXNBrowserChart from 'features/nfEUM/components/TXNBrowserChart';
import TXNWebOsChart from 'features/nfEUM/components/TXNWebOsChart';
import TXNAppChart from 'features/nfEUM/components/TXNAppChart';
import TXNNativeOsChart from 'features/nfEUM/components/TXNNativeOsChart';

export default function TXNShareChart() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const { shareTab } = useSelector((state: RootState) => state.eumTXNShare);

	return (
		<div css={container}>
			<div css={header}>
				<span>{t('eum.share')}</span>
				<div css={tabs}>
					<button type="button" css={[tab, webTab(shareTab)]} onClick={() => dispatch(setShareTab('WEB'))}>
						Web
					</button>
					<button type="button" css={[tab, nativeTab(shareTab)]} onClick={() => dispatch(setShareTab('NATIVE'))}>
						Native
					</button>
				</div>
			</div>
			<div css={border} />
			{shareTab === 'WEB' ? (
				<div css={chartWrapper}>
					<TXNBrowserChart />
					<TXNWebOsChart />
				</div>
			) : (
				<div css={chartWrapper}>
					<TXNAppChart />
					<TXNNativeOsChart />
				</div>
			)}
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
`;

const header = css`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	color: ${paletteSDS.gray[900]};
	font-weight: 600;
	font-size: 20px;
	line-height: 140%;
`;

const tabs = css`
	display: flex;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 6px;
	width: 128px;
	height: 22px;
	overflow: hidden;
	font-size: 14px;
	line-height: 100%;
`;

const tab = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	width: 50%;
	cursor: pointer;
`;

const webTab = (shareTab: ShareTab) => css`
	border-right: 1px solid ${paletteSDS.gray[300]};
	background-color: ${shareTab === 'WEB' ? paletteSDS.gray[50] : paletteSDS.gray[75]};
	color: ${shareTab === 'WEB' ? paletteSDS.blue[700] : paletteSDS.gray[700]};
	font-weight: ${shareTab === 'WEB' ? 500 : 400};
`;

const nativeTab = (shareTab: ShareTab) => css`
	background-color: ${shareTab === 'NATIVE' ? paletteSDS.gray[50] : paletteSDS.gray[75]};
	color: ${shareTab === 'NATIVE' ? paletteSDS.blue[700] : paletteSDS.gray[700]};
	font-weight: ${shareTab === 'NATIVE' ? 500 : 400};
`;

const border = css`
	width: 100%;
	height: 1px;
	background-color: ${paletteSDS.gray[300]};
`;

const chartWrapper = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutOrganizationNameApiResponse } from 'features/setting/types/putOrganizationName.type';

export interface PutOrganizationNameApiProps {
	name: string;
	organizationId: number;
}

export default async function putOrganizationNameApi({ name, organizationId }: PutOrganizationNameApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken) {
		const { data } = await axios.put<PutOrganizationNameApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/organization/name`,
			{ name },
			{
				headers: {
					Authorization: cognitoToken,
					organizationId,
					userKey,
				},
			}
		);
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PaginationBasic, PaginationBasicClickHandler, paletteSDS, themeSDS } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';

import PlanTitle from 'features/plan/components/PlanTitle';
import { getBillingHistoryThunk } from 'features/plan/thunk/getBillingHistory.thunk';
import { getRealtimeChargeDetailThunk } from 'features/plan/thunk/getRealtimeChargeDetail.thunk';
import { getRealtimeChargeThunk } from 'features/plan/thunk/getRealtimeCharge.thunk';
import BillingHistoryRealtimeCharge from 'features/plan/components/BillingHistoryRealtimeCharge';
import { BILLING_HISTORY_PAGE_UNIT } from 'features/plan/data/contants';
import PaymentCharge from 'features/plan/components/PaymentCharge';

export default function BillingHistory() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const { isBillingHistoryLoaded, isRealtimeChargeDetailLoaded, isRealtimeChargeLoaded, billingHistory } = useSelector(
		(state: RootState) => state.billing
	);

	const totalPages = Math.ceil(billingHistory.length / BILLING_HISTORY_PAGE_UNIT);

	const [selectedPage, setSelectedPage] = useState(1);

	const prevClickHandler = () => {
		setSelectedPage(prev => prev - 1);
	};

	const nextClickHandler = () => {
		setSelectedPage(prev => prev + 1);
	};

	const paginationButtonClickHandler: PaginationBasicClickHandler = (_, page) => {
		setSelectedPage(page);
	};

	const selectedBillingHistory = billingHistory.slice(
		(selectedPage - 1) * BILLING_HISTORY_PAGE_UNIT,
		selectedPage * BILLING_HISTORY_PAGE_UNIT
	);

	useEffect(() => {
		dispatch(getBillingHistoryThunk());
		dispatch(getRealtimeChargeThunk());
		dispatch(getRealtimeChargeDetailThunk());
	}, [dispatch]);

	if (isBillingHistoryLoaded && billingHistory.length === 0) {
		return <div css={noItemsSt}>{t('planSubscription.noBillingHistory')}</div>;
	}

	return (
		<div>
			<PlanTitle title={t('planSubscription.billingHistory')} />
			{isRealtimeChargeLoaded && isRealtimeChargeDetailLoaded ? <BillingHistoryRealtimeCharge /> : null}
			{isBillingHistoryLoaded
				? selectedBillingHistory.map(billing => <PaymentCharge key={billing.id} bill={billing} />)
				: null}
			<PaginationBasic
				totalPages={totalPages}
				selectedPage={selectedPage}
				onClickNext={prevClickHandler}
				onClickPrev={nextClickHandler}
				onClickSelectPage={paginationButtonClickHandler}
			/>
		</div>
	);
}

const noItemsSt = css`
	display: flex;
	justify-content: center;
	margin: 5rem 0;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import useEUMChartOptions from 'features/nfEUM/hooks/useEUMChartOptions';
import URLChartBox from 'features/nfEUM/components/URLChartBox';

const { fontSize } = themeSDS;

export default function URLTotalCountChart() {
	const { t } = useTranslation();

	const { timeZone } = useSelector((state: RootState) => state.account);

	const {
		networkStatus: { isAnimate, countY, maxCount },
		hasError,
		isIntervalActivated,
	} = useSelector((state: RootState) => state.eum);

	const countSeries: ApexAxisChartSeries = [
		{
			data: countY.slice(),
			color: '#0D1F66',
			type: 'area',
		},
	];

	const { apexLineOption } = useEUMChartOptions({
		maxY: Math.max(50, maxCount),
		unit: '',
		decimalsInFloat: 0,
		timeZone,
		label: t('eum.count'),
		isAnimate,
		isConvertYValue: true,
		isIntervalActivated,
	});

	return (
		<URLChartBox>
			<div css={chartHeaderCss}>
				<span css={titleCss}>{t('eum.totalCount')}</span>
			</div>
			{countSeries.length === 0 || hasError ? null : (
				<ReactApexChart series={countSeries} options={apexLineOption} width="100%" height="95%" />
			)}
		</URLChartBox>
	);
}

const chartHeaderCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const titleCss = css`
	font-weight: 500;
	font-size: ${fontSize[16]};
	line-height: 160%;
`;

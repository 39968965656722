import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putBasicControlSegmentGraphInfoApi from 'features/nfControl/api/putBasicControlSegmentGraphInfo.v2.api';

export interface PutBasicControlSegmentGraphInfoThunkResult {
	id: number;
	segmentGraphYn: boolean;
}

export interface PostBasicControlSegmentGraphInfoThunkProps {
	segmentId: number;
	segmentGraphYn: boolean;
}

const putBasicControlSegmentGraphInfoThunk = createAsyncThunk<
	PutBasicControlSegmentGraphInfoThunkResult,
	PostBasicControlSegmentGraphInfoThunkProps,
	{ state: RootState }
>('put/basic/segment/graph/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId, segmentGraphYn } = args;

	try {
		await putBasicControlSegmentGraphInfoApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			segmentId,
			segmentGraphYn,
		});

		return { id: segmentId, segmentGraphYn };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ id: segmentId, error: error.response.data });
	}
});

export default putBasicControlSegmentGraphInfoThunk;

/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useLayoutEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button, paletteSDS, themeSDS, Link } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { LANG_PATH, PLAN_FREE_TRIAL_ID, PLAN_FREE_BETA_ID, NETFUNNEL } from 'common/data/constants';

import type { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';
import PlanTitle from 'features/plan/components/PlanTitle';
import { addNumberComma, getDateFormatLanguage } from 'features/plan/utils/formatFunc';

import { getSubscriptionListThunk } from 'features/plan/thunk/getSubscriptionList.v2.thunk';
import { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';

export default function SubscriptionSummary() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { tenantStatus, language } = useSelector((state: RootState) => state.account);
	const { planLangs, planId, price, priceUnit, expiredAt } = useSelector((state: RootState) => state.plan);
	const { products, isLoaded } = useSelector((state: RootState) => state.subscription);
	const { id: paymentId } = useSelector((state: RootState) => state.payment);

	const planLang = planLangs.find(el => el.langType === language) as PlanLangs;

	const cancelButtonClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/cancel_subscription`);
	};

	const startSubscriptionClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/choose_plan`);
	};

	useLayoutEffect(() => {
		dispatch(getSubscriptionListThunk());
	}, [dispatch]);

	if (!isLoaded) {
		return <div />;
	}

	const netFunnelSubscriptionInfo = products.find(el => el.productType === NETFUNNEL) as SubscriptionData;

	const payDate = moment(netFunnelSubscriptionInfo.chargeDateTime).utcOffset(0).format('YYYY-MM-DD');

	const billedDate = getDateFormatLanguage(payDate, language);

	if (planId === PLAN_FREE_TRIAL_ID || tenantStatus === 'PENDING') {
		return (
			<div css={containerSt}>
				<div css={upperBoxSt}>
					<PlanTitle title={t('planSubscription.subscrSum')} />
					<div>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.plan')}</p>
							<p>{t('planSubscription.planFree')}</p>
						</div>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.expDate')}</p>
							<p>{getDateFormatLanguage(expiredAt, language)}</p>
						</div>
					</div>
				</div>
				<Button onClick={() => navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/choose_plan`)}>
					{t('modal.upgradePlan')}
				</Button>
			</div>
		);
	}

	if (planId === PLAN_FREE_BETA_ID && paymentId === '') {
		return (
			<div css={containerSt}>
				<div css={upperBoxSt}>
					<PlanTitle title={t('planSubscription.subscrSum')} />
					<div css={gap8Column}>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.plan')}</p>
							<p>{t('freeBeta.freeBeta')}</p>
						</div>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.expDate')}</p>
							<p>{getDateFormatLanguage('2023-03-31', language)}</p>
						</div>
					</div>
				</div>
				<Link onClick={() => navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/choose_plan`)}>
					{t('freeBeta.viewPlan')}
				</Link>
			</div>
		);
	}

	if (planId === PLAN_FREE_BETA_ID && paymentId !== '') {
		return (
			<div css={containerSt}>
				<div css={upperBoxSt}>
					<PlanTitle title={t('planSubscription.subscrSum')} />
					<div css={gap8Column}>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.plan')}</p>
							<p>{t('freeBeta.freeBeta')}</p>
						</div>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.expDate')}</p>
							<p>{getDateFormatLanguage('2023-03-31', language)}</p>
						</div>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.price')}</p>
							<div css={infoPaySt}>
								<p>{t('planSubscription.freePrice')}</p>
								<p>({t('planSubscription.payAsYouGoMsg2')})</p>
							</div>
						</div>
						<div css={infoBoxSt}>
							<p>{t('planSubscription.billingDate')}</p>
							<p>{billedDate}</p>
						</div>
					</div>
				</div>
				<Link onClick={() => navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/choose_plan`)}>
					{t('freeBeta.viewPlan')}
				</Link>
			</div>
		);
	}

	return (
		<div css={containerSt}>
			<div css={upperBoxSt}>
				<PlanTitle title={t('planSubscription.subscrSum')} />
				<div css={infoBoxSt}>
					<p>{t('planSubscription.plan')}</p>
					{netFunnelSubscriptionInfo.status === 'SUBSCRIBING' ? (
						<p>{planLang.name}</p>
					) : (
						<div css={cancelSt}>
							<p>{planLang.name}</p>
							<div css={statusSt}>{t('planSubscription.cancelled')}</div>
						</div>
					)}
				</div>
				<div css={infoBoxSt}>
					<p>{t('planSubscription.price')}</p>
					<div css={infoPaySt}>
						<p>
							{priceUnit === 'KRW' ? '₩' : '$'}
							{addNumberComma(String(price))}/{t('planSubscription.billingCycle')}
						</p>
						<p>({t('planSubscription.payAsYouGoMsg2')})</p>
					</div>
				</div>
				<div css={infoBoxSt}>
					<p>{t('planSubscription.billingDate')}</p>
					<p>{billedDate}</p>
				</div>
				{netFunnelSubscriptionInfo.status !== 'SUBSCRIBING' && (
					<div css={infoBoxSt}>
						<p>{t('planSubscription.dataExp')}</p>
						<div css={cancelSt}>
							<p>{getDateFormatLanguage(expiredAt, language)}</p>
							<div css={statusSt}>{t('planSubscription.deleted')}</div>
						</div>
					</div>
				)}
			</div>
			<div css={buttonContainerSt}>
				{netFunnelSubscriptionInfo.status === 'SUBSCRIBING' ? (
					<React.Fragment>
						<Link onClick={cancelButtonClickHandler}>{t('modal.cancelSubscr')}</Link>
						<Button size="medium" onClick={() => navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/choose_plan`)}>
							{t('planSubscription.changeSubscr')}
						</Button>
					</React.Fragment>
				) : (
					<Button size="medium" onClick={startSubscriptionClickHandler}>
						{t('planSubscription.startSubscr')}
					</Button>
				)}
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 36.625rem;
	height: 21.1875rem;
`;

const infoBoxSt = css`
	display: flex;

	color: ${paletteSDS.blue[900]};

	> p {
		display: flex;
		align-items: center;

		height: 1.625rem;
		line-height: 1.625rem;
	}

	> p:nth-of-type(1) {
		width: 8.75rem;
		font-weight: ${themeSDS.fontWeight.bold};
	}

	:last-child {
		margin-bottom: 0;
	}
`;

const cancelSt = css`
	display: flex;
	align-items: center;

	color: ${paletteSDS.gray[600]};

	> p {
		margin-right: 0.5rem;
	}
`;

const statusSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0 0.5rem;
	height: 1.375rem;

	border: 1px solid ${paletteSDS.gray[600]};
	border-radius: 4px;

	font-size: ${themeSDS.fontSize[14]};
`;

const infoPaySt = css`
	display: flex;
	flex-direction: column;

	width: calc(100% - 8.75rem);
	height: 3.25rem;

	& > p {
		display: flex;
		align-items: center;
		height: 1.625rem;
	}
`;

const buttonContainerSt = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 2.5rem;

	> div {
		display: flex;
		align-items: flex-end;
	}
`;

const upperBoxSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

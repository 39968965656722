/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Modal, Portal, paletteSDS } from 'surf-design-system';

type LinkAgentPromptModalProps = {
	visible: boolean;
	onClickPrimaryButton: () => void;
};

/**
 * author: ian
 * createdAt: 2022-10-27
 * updatedAt: 2022-10-27
 * description: agent 세팅 페이지 이탈 시 경고 모달
 */
export default function LinkAgentPromptModal({ visible, onClickPrimaryButton }: LinkAgentPromptModalProps) {
	const { t } = useTranslation();

	return (
		<Portal>
			<Modal
				visible={visible}
				top="30%"
				actionType="single"
				primaryBtnLabel={t('modal.tenantConfirm')}
				primaryBtnFunction={onClickPrimaryButton}
				title={t('modal.notAccessibleModal')}
			>
				<div css={textSt}>{t('modal.noReturnMsg')}</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 1rem;

	color: ${paletteSDS.gray[900]};
`;

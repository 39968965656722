export const calcProcessTimeRange = (start: number, end: number) => {
	let max = 100;

	if (end < 50) {
		max = Math.floor(end / 10) * 20;
	}

	if (end < 10) {
		max = 10;
	}

	const good = Math.floor((start / max) * 100);
	const normal = Math.floor(((end - start) / max) * 100);
	const bad = 100 - (good + normal);

	return { good, normal, bad };
};

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';

import { GetProjectLicenseApiResponse } from 'features/license/types/getProjectLicense.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface GetProjectLicenseApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
}

export const getProjectLicenseApi = async ({ tenantId, organizationId, projectId }: GetProjectLicenseApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken) {
		const { data } = await axios.get<GetProjectLicenseApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/project/${projectId}/license`,
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

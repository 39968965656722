/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { useEffect } from 'react';

import { setInitialToast } from 'common/context/toastSlice';
import { useAppDispatch } from 'common/redux/store';

import OnPremMemberSearchBar from 'features/setting/components/OnPremMemberSearchBar';
import OnPremMemberTable from 'features/setting/components/OnPremMemberTable';

export default function Members() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(setInitialToast());
		};
	}, [dispatch]);

	return (
		<div>
			<OnPremMemberSearchBar />
			<OnPremMemberTable />
		</div>
	);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProjectUsageSummaryThunk as getProjectUsageSummaryThunkV2 } from 'features/plan/thunk/getProjectUsageSummary.v2.thunk';
import { getProjectUsageDetailThunk as getProjectUsageDetailThunkV2 } from 'features/plan/thunk/getProjectUsageDetail.v2.thunk';
import { getProjectUsageDetailThunk } from 'features/plan/thunk/getProjectUsageDetail.thunk';
import { getProjectUsageSummaryThunk } from 'features/plan/thunk/getProjectUsageSummary.thunk';
import { getProjectListThunk } from 'features/nfSetting/thunk/getProjectList.v2.thunk';

export interface UsageSliceData {
	// eslint-disable-next-line
	usageSummaryError: any;
	isUsageSummaryLoaded: boolean;
	lastUpdate: number;
	initialMomentValue: number;
	usageSummary: {
		basicMonitoring: {
			totalActiveUsers: number;
			totalWaitingUsers: number;
			peakConcurrentConnectionUsers: number;
			vwr: number;
		};
		pathControl: {
			totalActiveUsers: number;
			totalWaitingUsers: number;
			peakConcurrentConnectionUsers: number;
			vwr: number;
		};
		administrator: number;
		operator: number;
		monitor: number;
		waitingRoom: number;
		peakCcu: number;
	};
	chart: {
		daily: {
			basicMonitoring: {
				activeUser: number[];
				waitingUser: number[];
				connectingUser: number[];
				maxActiveUser: number;
				maxWaitingUser: number;
				maxConnectingUser: number;
			};
			pathControl: {
				activeUser: number[];
				waitingUser: number[];
				connectingUser: number[];
				maxActiveUser: number;
				maxWaitingUser: number;
				maxConnectingUser: number;
			};
			isLoaded: boolean;
			// eslint-disable-next-line
			error: any;
		};
		monthly: {
			basicMonitoring: {
				activeUser: number[];
				waitingUser: number[];
				connectingUser: number[];
				maxActiveUser: number;
				maxWaitingUser: number;
				maxConnectingUser: number;
			};
			pathControl: {
				activeUser: number[];
				waitingUser: number[];
				connectingUser: number[];
				maxActiveUser: number;
				maxWaitingUser: number;
				maxConnectingUser: number;
			};
			isLoaded: boolean;
			// eslint-disable-next-line
			error: any;
		};
		yearly: {
			startYear: number;
			endYear: number;
			basicMonitoring: {
				activeUser: number[];
				waitingUser: number[];
				connectingUser: number[];
				maxActiveUser: number;
				maxWaitingUser: number;
				maxConnectingUser: number;
			};
			pathControl: {
				activeUser: number[];
				waitingUser: number[];
				connectingUser: number[];
				maxActiveUser: number;
				maxWaitingUser: number;
				maxConnectingUser: number;
			};
			isLoaded: boolean;
			// eslint-disable-next-line
			error: any;
		};
	};
	selectedProjectId: number;
}

const initialState: UsageSliceData = {
	isUsageSummaryLoaded: false,
	usageSummaryError: null,
	lastUpdate: 0,
	initialMomentValue: 0,
	usageSummary: {
		basicMonitoring: {
			totalActiveUsers: 0,
			totalWaitingUsers: 0,
			peakConcurrentConnectionUsers: 0,
			vwr: 0,
		},
		pathControl: {
			totalActiveUsers: 0,
			totalWaitingUsers: 0,
			peakConcurrentConnectionUsers: 0,
			vwr: 0,
		},
		administrator: 0,
		operator: 0,
		monitor: 0,
		waitingRoom: 0,
		peakCcu: 0,
	},
	chart: {
		daily: {
			basicMonitoring: {
				activeUser: [],
				waitingUser: [],
				connectingUser: [],
				maxActiveUser: 0,
				maxConnectingUser: 0,
				maxWaitingUser: 0,
			},
			pathControl: {
				activeUser: [],
				waitingUser: [],
				connectingUser: [],
				maxActiveUser: 0,
				maxConnectingUser: 0,
				maxWaitingUser: 0,
			},
			isLoaded: false,
			error: null,
		},
		monthly: {
			basicMonitoring: {
				activeUser: [],
				waitingUser: [],
				connectingUser: [],
				maxActiveUser: 0,
				maxConnectingUser: 0,
				maxWaitingUser: 0,
			},
			pathControl: {
				activeUser: [],
				waitingUser: [],
				connectingUser: [],
				maxActiveUser: 0,
				maxConnectingUser: 0,
				maxWaitingUser: 0,
			},
			isLoaded: false,
			error: null,
		},
		yearly: {
			startYear: 0,
			endYear: 0,
			basicMonitoring: {
				activeUser: [],
				waitingUser: [],
				connectingUser: [],
				maxActiveUser: 0,
				maxConnectingUser: 0,
				maxWaitingUser: 0,
			},
			pathControl: {
				activeUser: [],
				waitingUser: [],
				connectingUser: [],
				maxActiveUser: 0,
				maxConnectingUser: 0,
				maxWaitingUser: 0,
			},
			isLoaded: false,
			error: null,
		},
	},
	selectedProjectId: 1,
};

const usageSlice = createSlice({
	name: 'usage',
	initialState,
	reducers: {
		setInitialUsageData: () => {
			return { ...initialState };
		},
		setInitialTimestamp: (state, action: PayloadAction<number>) => {
			return { ...state, lastUpdate: 0, initialMomentValue: action.payload };
		},
		increaseLastUpdate: state => {
			return { ...state, lastUpdate: state.lastUpdate + 1 };
		},
		setSelectedProjectId: (state, action: PayloadAction<number>) => {
			return { ...state, selectedProjectId: action.payload };
		},
	},
	extraReducers(builder) {
		builder.addCase(getProjectUsageDetailThunk.fulfilled, (state, action) => {
			return {
				...state,
				chart: {
					daily: { isLoaded: true, error: null, ...action.payload.daily },
					monthly: { isLoaded: true, error: null, ...action.payload.monthly },
					yearly: { isLoaded: true, error: null, ...action.payload.yearly },
				},
			};
		});

		builder.addCase(getProjectUsageDetailThunk.rejected, (state, action) => {
			return {
				...state,
				chart: {
					daily: { ...state.chart.daily, error: action.payload, isLoaded: true },
					monthly: { ...state.chart.monthly, error: action.payload, isLoaded: true },
					yearly: { ...state.chart.yearly, error: action.payload, isLoaded: true },
				},
			};
		});

		builder.addCase(getProjectUsageDetailThunkV2.fulfilled, (state, action) => {
			return {
				...state,
				chart: {
					daily: { isLoaded: true, error: null, ...action.payload.daily },
					monthly: { isLoaded: true, error: null, ...action.payload.monthly },
					yearly: { isLoaded: true, error: null, ...action.payload.yearly },
				},
			};
		});

		builder.addCase(getProjectUsageDetailThunkV2.rejected, (state, action) => {
			return {
				...state,
				chart: {
					daily: { ...state.chart.daily, error: action.payload, isLoaded: true },
					monthly: { ...state.chart.monthly, error: action.payload, isLoaded: true },
					yearly: { ...state.chart.yearly, error: action.payload, isLoaded: true },
				},
			};
		});

		builder.addCase(getProjectUsageSummaryThunk.fulfilled, (state, action) => {
			return {
				...state,
				isUsageSummaryLoaded: true,
				usageSummaryError: null,
				usageSummary: {
					basicMonitoring: {
						totalActiveUsers: action.payload.data.basicMonitoring.totalActiveUsers,
						totalWaitingUsers: action.payload.data.basicMonitoring.totalWaitingUsers,
						peakConcurrentConnectionUsers: action.payload.data.basicMonitoring.peakConcurrentConnectionUsers,
						vwr: action.payload.data.basicMonitoring.segment,
					},
					pathControl: {
						totalActiveUsers: action.payload.data.pathControl.totalActiveUsers,
						totalWaitingUsers: action.payload.data.pathControl.totalWaitingUsers,
						peakConcurrentConnectionUsers: action.payload.data.pathControl.peakConcurrentConnectionUsers,
						vwr: action.payload.data.pathControl.segment,
					},
					administrator: action.payload.data.member.administrator,
					operator: action.payload.data.member.operator,
					monitor: action.payload.monitor,
					waitingRoom: action.payload.data.vwr,
					peakCcu: 0,
				},
			};
		});

		builder.addCase(getProjectUsageSummaryThunk.rejected, (state, action) => {
			return { ...state, isUsageSummaryLoaded: true, usageSummaryError: action.payload };
		});

		builder.addCase(getProjectUsageSummaryThunkV2.fulfilled, (state, action) => {
			return {
				...state,
				isUsageSummaryLoaded: true,
				usageSummaryError: null,
				usageSummary: {
					basicMonitoring: {
						totalActiveUsers: action.payload.basicMonitoring.totalActiveUsers,
						totalWaitingUsers: action.payload.basicMonitoring.totalWaitingUsers,
						peakConcurrentConnectionUsers: action.payload.basicMonitoring.peakConcurrentConnectionUsers,
						vwr: action.payload.basicMonitoring.segment,
					},
					pathControl: {
						totalActiveUsers: action.payload.pathControl.totalActiveUsers,
						totalWaitingUsers: action.payload.pathControl.totalWaitingUsers,
						peakConcurrentConnectionUsers: action.payload.pathControl.peakConcurrentConnectionUsers,
						vwr: action.payload.pathControl.segment,
					},
					administrator: action.payload.member.administrator,
					operator: action.payload.member.operator,
					monitor: 0,
					waitingRoom: action.payload.vwr,
					peakCcu: action.payload.peakCcu,
				},
			};
		});

		builder.addCase(getProjectUsageSummaryThunkV2.rejected, (state, action) => {
			return { ...state, isUsageSummaryLoaded: true, usageSummaryError: action.payload };
		});

		builder.addCase(getProjectListThunk.fulfilled, (state, action) => {
			return { ...state, selectedProjectId: action.payload.projectMetaData[0].id };
		});
	},
});

export const { setInitialUsageData, setInitialTimestamp, increaseLastUpdate, setSelectedProjectId } =
	usageSlice.actions;

export default usageSlice.reducer;

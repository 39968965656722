import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';

import type { GetLicenseDetailApiResponse } from 'features/license/types/getLicenseDetail.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface GetLicenseDetailApiProps {
	organizationId: number;
	tenantId: string;
	projectId: number;
}

export default async function getLicenseDetailApi({ tenantId, organizationId, projectId }: GetLicenseDetailApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetLicenseDetailApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/license/project/${projectId}`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProjectListThunk } from 'common/thunk/getProjectList.thunk';

import { putProjectAccessYnThunk } from 'features/nfBasic/thunk/putProjectAccessYn.thunk';
import { putProjectActivatedYnThunk } from 'features/nfBasic/thunk/putProjectActivatedYn.thunk';
import { putProjectNameThunk } from 'features/nfBasic/thunk/putProjectName.thunk';
import { putServiceDomainThunk } from 'features/nfBasic/thunk/putServiceDomain.thunk';
import { putProjectMacroInfoThunk } from 'features/nfBasic/thunk/putProjectMacroInfo.thunk';

import { ProjectInformation } from '../types/getProjectList.type';

export interface ProjectSliceData {
	projectMetaData: ProjectInformation[];
	isLoaded: boolean;
	selectedProjectId: number;
	// eslint-disable-next-line
	error: any;
	isLoading: boolean;
}

const initialState: ProjectSliceData = {
	projectMetaData: [
		{
			id: 0,
			projectName: '',
			accessYn: true,
			activatedYn: true,
			projectKey: '',
			updatedAt: '',
			createdAt: '',
			customerServiceDomain: '',
			blockYn: false,
		},
	],
	isLoaded: false,
	error: null,
	selectedProjectId: 0,
	isLoading: false,
};

const projectMetaSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setPrjMetaData: (state, action: PayloadAction<ProjectInformation[]>) => {
			return { ...state, projectMetaData: action.payload.slice() };
		},
		setSelectedProjectId: (state, action: PayloadAction<number>) => {
			return { ...state, selectedProjectId: action.payload };
		},
		setInitialPrjMetaData: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getProjectListThunk.fulfilled, (state, action) => {
			const { initialProjectId } = action.payload;

			const isInitialProjectIdExist = state.projectMetaData.findIndex(el => el.id === initialProjectId) !== -1;

			const isProjectMetaDataExist = state.projectMetaData.length > 0;

			let selectedProjectIdPayload = 0;

			if (isProjectMetaDataExist && isInitialProjectIdExist && initialProjectId) {
				selectedProjectIdPayload = initialProjectId;
			}

			if (isProjectMetaDataExist && !isInitialProjectIdExist) {
				selectedProjectIdPayload = action.payload.projectMetaData[0].id;
			}

			return {
				...state,
				isLoaded: true,
				projectMetaData: action.payload.projectMetaData.slice(),
				error: null,
				selectedProjectId: selectedProjectIdPayload,
			};
		});

		builder.addCase(getProjectListThunk.rejected, (state, action) => {
			return { ...state, isLoaded: true, error: action.payload };
		});

		builder.addCase(putProjectAccessYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectMetaData: state.projectMetaData.map(prev => {
					if (prev.id === action.payload.projectId) {
						return { ...prev, accessYn: action.payload.accessYn, updatedAt: action.payload.updatedAt };
					}
					return prev;
				}),
				isLoading: false,
			};
		});

		builder.addCase(putProjectAccessYnThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putProjectAccessYnThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(putProjectActivatedYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectMetaData: state.projectMetaData.map(prev => {
					if (prev.id === action.payload.projectId) {
						return { ...prev, activatedYn: action.payload.activatedYn, updatedAt: action.payload.updatedAt };
					}
					return prev;
				}),
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(putProjectActivatedYnThunk.pending, state => {
			return { ...state, isLoading: true, error: null };
		});

		builder.addCase(putProjectActivatedYnThunk.rejected, (state, action) => {
			return { ...state, isLoaded: true, error: action.payload, isLoading: false };
		});

		builder.addCase(putProjectNameThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putProjectNameThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectMetaData: state.projectMetaData.map(prev => {
					if (prev.id === action.payload.projectId) {
						return { ...prev, projectName: action.payload.projectName, updatedAt: action.payload.updatedAt };
					}
					return prev;
				}),
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(putProjectNameThunk.rejected, (state, action) => {
			return { ...state, isLoaded: true, error: { ...(action.payload as object) }, isLoading: false };
		});

		builder.addCase(putServiceDomainThunk.pending, state => {
			return { ...state, isLoading: true, error: null };
		});

		builder.addCase(putServiceDomainThunk.fulfilled, (state, action) => {
			const newProjectMetaData = state.projectMetaData.map(prj => {
				if (prj.id === action.payload.projectId) {
					return { ...prj, customerServiceDomain: action.payload.customerDomain };
				}
				return prj;
			});

			return { ...state, projectMetaData: newProjectMetaData, error: null, isLoading: false };
		});

		builder.addCase(putServiceDomainThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(putProjectMacroInfoThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putProjectMacroInfoThunk.rejected, state => {
			return { ...state, isLoading: false };
		});

		builder.addCase(putProjectMacroInfoThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectMetaData: state.projectMetaData.map(meta => {
					if (meta.id === action.payload.projectId) {
						return { ...meta, blockYn: action.payload.blockYn, updatedAt: action.payload.updatedAt };
					}

					return meta;
				}),
				isLoading: false,
			};
		});
	},
});

export const { setInitialPrjMetaData, setSelectedProjectId } = projectMetaSlice.actions;
export default projectMetaSlice.reducer;

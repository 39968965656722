import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import { toggleIsVWRPutConfirmModalVisible } from 'common/context/modalSlice';
import { toggleIsVWRUpdateFailToastVisible } from 'common/context/toastSlice';

import { postVWRDataApi } from 'features/vwr/api/postVWR.v2.api';
import { convertUrlToImgFile } from 'features/nfBasic/utils/fileFunc';
import { getVWRListThunk } from 'features/vwr/thunk/getVWRList.v2.thunk';

interface PostVWRThunkResult {
	id: number;
}

interface PostVWRThunkProps {
	onSuccess: () => void;
}

export const postVWRThunk = createAsyncThunk<PostVWRThunkResult, PostVWRThunkProps, { state: RootState }>(
	'vwr/post/v2',
	async (args, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
			projectInfo: { selectedProjectId },
			modal: {
				vwr: { name },
			},
		} = thunkAPI.getState();

		const { onSuccess } = args;

		const {
			isFollowingWaitYnChanged,
			isGuideTextChanged,
			isImagePathChanged,
			isLogoImagePathChanged,
			isTitleChanged,
			isWaitCancelYnChanged,
			logoImagePathName,
			logoImagePathSrc,
			title,
			followingWaitYn,
			guideText,
			imagePathName,
			imagePathSrc,
			waitCancelYn,
			waitTimeDisplayYn,
			isWaitTimeDisplayYnChanged,
		} = thunkAPI.getState().vwr2.form;

		const followingWaitYnData = isFollowingWaitYnChanged ? followingWaitYn : undefined;
		const guideTextData = isGuideTextChanged ? guideText : undefined;
		const imagePathData = isImagePathChanged ? await convertUrlToImgFile(imagePathSrc, imagePathName) : undefined;
		const logoImagePathData = isLogoImagePathChanged
			? await convertUrlToImgFile(logoImagePathSrc, logoImagePathName)
			: undefined;
		const titleData = isTitleChanged ? title : undefined;
		const waitCancelYnData = isWaitCancelYnChanged ? waitCancelYn : undefined;
		const waitTimeDisplayYnData = isWaitTimeDisplayYnChanged ? waitTimeDisplayYn : undefined;

		try {
			const {
				data: { id },
			} = await postVWRDataApi({
				logoImageFile: logoImagePathData,
				followingWaitYn: followingWaitYnData,
				guideText: guideTextData,
				waitCancelYn: waitCancelYnData,
				tenantId: tenantId as string,
				projectId: selectedProjectId,
				coverImageFile: imagePathData,
				title: titleData,
				organizationId,
				waitTimeDisplayYn: waitTimeDisplayYnData,
				name,
			});

			onSuccess();
			thunkAPI.dispatch(toggleIsVWRPutConfirmModalVisible());
			thunkAPI.dispatch(getVWRListThunk());

			return { id };

			// eslint-disable-next-line
		} catch (error: any) {
			thunkAPI.dispatch(toggleIsVWRPutConfirmModalVisible());
			thunkAPI.dispatch(toggleIsVWRUpdateFailToastVisible());

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

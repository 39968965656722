/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, paletteSDS, Portal } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import { toggleIsPaymentConfirmModalVisible } from 'common/context/modalSlice';

interface PaymentConfirmModalProps {
	onClickPrimaryButton: () => void;
}

/**
 * author: Jay
 * createdAt: 2022-08-03
 * updatedAt: 2022-08-03
 * description: 플랜 취소 모달 컴포넌트
 * todo: 다국어 적용, 결제 페이지 연결
 */
export default function PaymentConfirmModal({ onClickPrimaryButton }: PaymentConfirmModalProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPaymentConfirmModalVisible } = useSelector((state: RootState) => state.modal);

	const { isLoading } = useSelector((state: RootState) => state.payment);

	const secondaryButtonClickHandler = () => {
		dispatch(toggleIsPaymentConfirmModalVisible());
	};

	return (
		<Portal>
			<Modal
				visible={isPaymentConfirmModalVisible}
				isCloseIconVisible={false}
				title={t('modal.editPymt')}
				actionType="double"
				primaryBtnLabel={t('modal.editPymtConfirm')}
				secondaryBtnLabel={t('modal.no')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				primaryBtnFunction={onClickPrimaryButton}
				overlayColor="transparent"
				primaryBtnDisable={isLoading}
			>
				<div css={containerSt}>
					<p>{t('modal.editPymtMsg')}</p>
					<p>
						<b>{t('planSubscription.changePymtMethodMsg')}</b>
					</p>
				</div>
			</Modal>
		</Portal>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 8.125rem;
	line-height: 160%;
	color: ${paletteSDS.gray[900]};
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProjectListThunk } from 'features/nfSetting/thunk/getProjectList.v2.thunk';
import { putProjectAccessYnThunk } from 'features/nfSetting/thunk/putProjectAccessYn.v1.thunk';
import { putProjectActivatedYnThunk } from 'features/nfSetting/thunk/putProjectActivatedYn.v1.thunk';
import { putProjectNameThunk } from 'features/nfSetting/thunk/putProjectName.v1.thunk';
import { putServiceDomainThunk } from 'features/nfSetting/thunk/putServiceDomain.v1.thunk';
import postProjectStatDataThunk from 'features/nfControl/thunk/postProjectStatData.v2.thunk';
import { putProjectMacroInfoThunk } from 'features/nfSetting/thunk/putProjectMacroInfo.v1.thunk';
import { postProjectThunk } from 'features/nfSetting/thunk/postProject.v2.thunk';
import type { ProjectInformation } from 'features/nfSetting/types/getProjectList.v2.type';

export interface ProjectInfoSliceData {
	projectList: ProjectInformation[];
	isLoaded: boolean;
	selectedProjectId: number;
	blockYn: boolean;
	// eslint-disable-next-line
	error: any;
	isLoading: boolean;
}

const initialState: ProjectInfoSliceData = {
	projectList: [
		{
			id: 0,
			projectName: '',
			accessYn: true,
			activatedYn: true,
			projectKey: '',
			updatedAt: '',
			createdAt: '',
			customerServiceDomain: '',
			sectionSegmentCount: 0,
			segmentCount: 0,
		},
	],
	isLoaded: false,
	error: null,
	selectedProjectId: 0,
	isLoading: false,
	blockYn: false,
};

const projectMetaSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setPrjMetaData: (state, action: PayloadAction<ProjectInformation[]>) => {
			return { ...state, projectList: action.payload.slice() };
		},
		setSelectedProjectId: (state, action: PayloadAction<number>) => {
			return {
				...state,
				selectedProjectId: action.payload,
			};
		},
		setInitialPrjMetaData: () => {
			return { ...initialState };
		},
		resetProjectError: state => {
			return { ...state, error: null };
		},
	},
	extraReducers(builder) {
		builder.addCase(getProjectListThunk.fulfilled, (state, action) => {
			const { initialProjectId, isKeepSelectedProjectId } = action.meta.arg;

			const isInitialProjectIdExist = action.payload.projectMetaData.findIndex(el => el.id === initialProjectId) !== -1;

			const isProjectMetaDataExist = action.payload.projectMetaData.length > 0;

			let selectedProjectIdPayload = 0;

			if (isProjectMetaDataExist && isInitialProjectIdExist && initialProjectId) {
				selectedProjectIdPayload = initialProjectId;
			}

			if (isProjectMetaDataExist && !isInitialProjectIdExist) {
				selectedProjectIdPayload = action.payload.projectMetaData[0].id;
			}

			if (isKeepSelectedProjectId) {
				selectedProjectIdPayload = state.selectedProjectId;
			}

			return {
				...state,
				isLoaded: true,
				projectList: action.payload.projectMetaData.slice(),
				error: null,
				selectedProjectId: selectedProjectIdPayload,
				blockYn: action.payload.blockYn,
			};
		});

		builder.addCase(getProjectListThunk.rejected, (state, action) => {
			return { ...state, isLoaded: true, error: action.payload };
		});

		builder.addCase(putProjectAccessYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectList: state.projectList.map(prev => {
					if (prev.id === action.payload.projectId) {
						return { ...prev, accessYn: action.payload.accessYn, updatedAt: action.payload.updatedAt };
					}
					return prev;
				}),
				isLoading: false,
				error: null,
			};
		});

		builder.addCase(putProjectAccessYnThunk.pending, state => {
			return { ...state, isLoading: true, error: null };
		});

		builder.addCase(putProjectAccessYnThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(putProjectActivatedYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectList: state.projectList.map(prev => {
					if (prev.id === action.payload.projectId) {
						return { ...prev, activatedYn: action.payload.activatedYn, updatedAt: action.payload.updatedAt };
					}
					return prev;
				}),
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(putProjectActivatedYnThunk.pending, state => {
			return { ...state, isLoading: true, error: null };
		});

		builder.addCase(putProjectActivatedYnThunk.rejected, (state, action) => {
			return { ...state, isLoaded: true, error: action.payload, isLoading: false };
		});

		builder.addCase(putProjectNameThunk.pending, state => {
			return { ...state, isLoading: true, error: null };
		});

		builder.addCase(putProjectNameThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectList: state.projectList.map(prev => {
					if (prev.id === action.payload.projectId) {
						return { ...prev, projectName: action.payload.projectName, updatedAt: action.payload.updatedAt };
					}
					return prev;
				}),
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(putProjectNameThunk.rejected, (state, action) => {
			return { ...state, isLoaded: true, error: { ...(action.payload as object) }, isLoading: false };
		});

		builder.addCase(putServiceDomainThunk.pending, state => {
			return { ...state, isLoading: true, error: null };
		});

		builder.addCase(putServiceDomainThunk.fulfilled, (state, action) => {
			const newProjectMetaData = state.projectList.map(prj => {
				if (prj.id === action.payload.projectId) {
					return { ...prj, customerServiceDomain: action.payload.customerDomain };
				}
				return prj;
			});

			return {
				...state,
				projectList: newProjectMetaData,
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(putServiceDomainThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(putProjectMacroInfoThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putProjectMacroInfoThunk.rejected, state => {
			return { ...state, isLoading: false };
		});

		builder.addCase(putProjectMacroInfoThunk.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				blockYn: action.payload.blockYn,
			};
		});

		builder.addCase(postProjectStatDataThunk.fulfilled, (state, action) => {
			return {
				...state,
				projectList: state.projectList.map(meta => {
					if (meta.id === action.payload.id) {
						return { ...meta, accessYn: action.payload.accessYn, activatedYn: action.payload.activatedYn };
					}
					return meta;
				}),
			};
		});

		builder.addCase(postProjectThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(postProjectThunk.rejected, (state, action) => {
			return { ...state, error: { ...(action.payload as object) }, isLoading: false };
		});

		builder.addCase(postProjectThunk.fulfilled, state => {
			return { ...state, error: null, isLoading: false };
		});
	},
});

export const { setInitialPrjMetaData, setSelectedProjectId, resetProjectError } = projectMetaSlice.actions;
export default projectMetaSlice.reducer;

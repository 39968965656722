import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getBasicControlSegmentDetailApi from 'features/nfControl/api/getBasicControlSegmentDetail.v2.api';
import type { BasicControlSegmentDetailData } from 'features/nfControl/type/getBasicControlSegmentDetail.v2.type';

interface GetBasicControlSegmentDetailThunkProps {
	segmentId: number;
}

const getBasicControlSegmentDetailThunk = createAsyncThunk<
	BasicControlSegmentDetailData,
	GetBasicControlSegmentDetailThunkProps,
	{ state: RootState }
>('get/basic/segment/detail/v2', async (args, thunkAPI) => {
	const { segmentId } = args;

	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const { data } = await getBasicControlSegmentDetailApi({
			tenantId: tenantId as string,
			organizationId,
			projectId: selectedProjectId,
			segmentId,
		});

		return { ...data };

		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default getBasicControlSegmentDetailThunk;

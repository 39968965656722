/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { paletteSDS } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import useSurffyAccountSetting from 'common/hooks/useSurffyAccountSetting';
import SaasGNB from 'common/components/SaasGNB';
import Footer from 'common/components/Footer';
import AskModal from 'common/components/AskModal';
import { NOTI_START_PAGE } from 'common/data/constants';

import { getNotificationListThunk } from 'common/thunk/getNotificationList.thunk';
import PendingModal from 'common/components/PendingModal';
import TenantFailModal from 'common/components/TenantFailModal';
import SaasToasts from 'common/components/SaasToasts';

import { getProjectListThunk } from 'features/nfSetting/thunk/getProjectList.v2.thunk';

export default function NfLayout() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { id } = useParams();

	const { pathname } = location;

	const { isLoaded: isAccountLoaded, language } = useSelector((state: RootState) => state.account);

	const { isLoaded: isPlanLoaded } = useSelector((state: RootState) => state.plan);

	const { isAskModalVisible } = useSelector((state: RootState) => state.modal);

	const { isLoaded: isProjectMetaLoaded } = useSelector((state: RootState) => state.projectInfo);

	useSurffyAccountSetting({});

	useEffect(() => {
		if (isAccountLoaded) {
			dispatch(getNotificationListThunk({ pageNum: NOTI_START_PAGE }));
			dispatch(getProjectListThunk({ initialProjectId: id ? Number(id) : undefined }));
		}
	}, [dispatch, id, isAccountLoaded, isProjectMetaLoaded, language, pathname]);

	return (
		<div css={container}>
			<SaasGNB />
			<SaasToasts />
			<main css={outletBox}>{!isAccountLoaded || !isPlanLoaded || !isProjectMetaLoaded ? <div /> : <Outlet />}</main>
			{pathname.includes('nf/home') && <Footer />}
			{isAskModalVisible ? <AskModal /> : null}
			<PendingModal />
			<TenantFailModal />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const outletBox = css`
	width: 100%;
	height: 100%;
	overflow: auto;
	background: ${paletteSDS.gray['75']};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import MainDomainDescription from 'common/components/MainDomainDescription';

export default function ServiceDomainSettingTitle() {
	const { t } = useTranslation();

	return (
		<div css={containerSt}>
			<div css={domainSettingTitleSt}>{t('ServiceDomainSetting.serviceDomainSetting')}</div>
			<MainDomainDescription />
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const domainSettingTitleSt = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize[24]};
	line-height: 128%;
	letter-spacing: -0.008em;
	color: ${paletteSDS.gray[900]};
`;

import { createSlice } from '@reduxjs/toolkit';

import { postMoisSegmentThunk } from 'features/nfBasic/thunk/postMoisSegment.thunk';

import type { SegmentStatDataValues } from 'features/nfBasic/types/postSegmentStatData.type';

import { X_AXIS_NUM, X_AXIS_VALUE_INTERVAL } from '../data/constants';

import { deleteSegmentThunk } from '../thunk/deleteSegment.thunk';
import { deleteSegmentGraphYnThunk } from '../thunk/deleteSegmentGraphYn.thunk';
import { postSegmentGraphYnThunk } from '../thunk/postSegmentGraphYn.thunk';
import { putSegmentOrderThunk } from '../thunk/putSegmentOrder.thunk';
import { putSegmentSettingThunk } from '../thunk/putSegmentSetting.thunk';

import type { SegmentMetaData } from '../types/getSegmentList.type';
import { postSegmentThunk } from '../thunk/postSegment.thunk';
import { FunnelData } from '../types/funnelLib.type';

export type segmentConnection = 'disconnected' | 'connected' | 'hasConnected';

export interface ChartData {
	x: number;
	y: number | null;
}

export interface SegmentData {
	prevSegCardValue: SegmentStatDataValues;
	segCardValue: SegmentStatDataValues;
	waitTicketY: ChartData[];
	processTimeY: ChartData[];
	outflowRateY: ChartData[];
	maxWaitTicket: number;
	maxProcessTime: number;
	segmentGraphYn: boolean;
	segmentColor: string;
	id: number;
	segmentName: string;
	accessYn: boolean;
	segmentKey: string;
	classic: FunnelData;
}

export interface MonitoringSliceData {
	project: {
		isLoaded: boolean;
		time: number;
		totalTickets: number;
		maxWaitTime: number;
		maxNumbersWaiting: number;
		maxProcessTime: number;
		classic: FunnelData;
		// eslint-disable-next-line
		error: any;
	};
	segment: {
		isLoading: boolean;
		meta: SegmentMetaData[];
		value: SegmentData[];
		isAnimate: boolean;
		isMetaLoaded: boolean;
		isValueLoaded: boolean;
		// eslint-disable-next-line
		error: any;
		isLoaded: boolean;
		connection: segmentConnection;
	};
	lastTimeStamp: number;
}

const initialState: MonitoringSliceData = {
	project: {
		time: 0,
		totalTickets: 0,
		maxWaitTime: 0,
		maxNumbersWaiting: 0,
		maxProcessTime: 0,
		classic: {
			id: 'projectFunnel',
			type: 1,
			new_key_chk_enter: 0,
			wait_user: 0,
			block: 0,
			wait_tm: 0,
			chk_enter_succ: 0,
			limit: 0,
			complete_succ: 0,
			xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			used_size: 0,
			// 미사용
			bypass: 0,
			access_mode: 0,
			ic_access_mode: 0,
			wait_max: 10,
			limit_max: 100,
			limitmax: 500,
		},
		isLoaded: false,
		error: null,
	},
	segment: {
		isLoading: false,
		meta: [],
		value: [],
		isAnimate: true,
		isLoaded: false,
		error: null,
		connection: 'connected',
		isMetaLoaded: false,
		isValueLoaded: false,
	},
	lastTimeStamp: 0,
};

const monitoringSlice = createSlice({
	name: 'monitoring',
	initialState,
	reducers: {
		setInitialMonitoringData: () => {
			return { ...initialState };
		},
		cleanupSegmentRawData: state => {
			return {
				...state,
				segment: {
					...state.segment,
					value: state.segment.value.map(seg => ({
						...seg,
						waitTicketY: seg.waitTicketY.slice(seg.waitTicketY.length - X_AXIS_NUM - 5),
						outflowRateY: seg.outflowRateY.slice(seg.outflowRateY.length - X_AXIS_NUM - 5),
						processTimeY: seg.processTimeY.slice(seg.processTimeY.length - X_AXIS_NUM - 5),
					})),
					isAnimate: false,
				},
			};
		},
		resetMonitoringError: state => {
			return { ...state, segment: { ...state.segment, error: null } };
		},
	},
	extraReducers(builder) {
		builder.addCase(putSegmentOrderThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					meta: state.segment.meta.map((el, idx) => {
						if (idx === action.payload.droppedCardIndex) {
							return {
								...state.segment.meta[action.payload.pickedCardIndex],
								segmentOrder: action.payload.droppedCardIndex + 1,
							};
						}
						if (idx === action.payload.pickedCardIndex) {
							return {
								...state.segment.meta[action.payload.droppedCardIndex],
								segmentOrder: action.payload.pickedCardIndex + 1,
							};
						}
						return el;
					}),
					value: state.segment.value.map((el, idx) => {
						if (idx === action.payload.droppedCardIndex) {
							return state.segment.value[action.payload.pickedCardIndex];
						}
						if (idx === action.payload.pickedCardIndex) {
							return state.segment.value[action.payload.droppedCardIndex];
						}
						return el;
					}),
				},
			};
		});

		builder.addCase(putSegmentSettingThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(putSegmentSettingThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					error: null,
					meta: state.segment.meta.map(el =>
						el.id === action.payload.segmentId
							? {
									...el,
									segmentName: action.payload.segmentName,
									accessYn: action.payload.accessYn,
									maxInflow: action.payload.maxInflow,
									uris: [...action.payload.uris],
							  }
							: el
					),
					value: state.segment.value.map(el => {
						if (el.id === action.payload.segmentId) {
							return {
								...el,
								segmentName: action.payload.segmentName,
								accessYn: action.payload.accessYn,
								segCardValue: { ...el.segCardValue, maxInflow: action.payload.maxInflow },
								classic: { ...el.classic, limit: action.payload.maxInflow },
							};
						}
						return el;
					}),
				},
			};
		});

		builder.addCase(putSegmentSettingThunk.rejected, (state, action) => {
			return { ...state, segment: { ...state.segment, isLoading: false, error: { ...(action.payload as object) } } };
		});

		builder.addCase(postSegmentThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(postSegmentThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					error: null,
					meta: state.segment.meta.concat({
						segmentColor: action.payload.segmentColor,
						id: action.payload.segmentId,
						segmentGraphYn: false,
						segmentKey: action.payload.segmentKey,
						segmentName: action.payload.segmentName,
						segmentOrder: state.segment.meta.length + 1,
						uris: [...action.payload.uris],
						maxInflow: action.payload.maxInflow,
						accessYn: action.payload.accessYn,
					}),
					value: state.segment.value.concat({
						prevSegCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: action.payload.maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						segCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: action.payload.maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						segmentGraphYn: false,
						segmentColor: action.payload.segmentColor,
						segmentKey: action.payload.segmentKey,
						accessYn: action.payload.accessYn,
						segmentName: action.payload.segmentName,
						maxProcessTime: 0,
						maxWaitTicket: 0,
						id: action.payload.segmentId,
						classic: {
							id: action.payload.segmentKey,
							type: 1,
							new_key_chk_enter: 0,
							wait_user: 0,
							block: 0,
							wait_tm: 0,
							chk_enter_succ: 0,
							limit: action.payload.maxInflow,
							complete_succ: 0,
							xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							used_size: 0,
							// 미사용
							bypass: 0,
							access_mode: 0,
							ic_access_mode: 0,
							wait_max: 10,
							limit_max: 100,
							limitmax: 500,
						},
					}),
				},
			};
		});

		builder.addCase(postSegmentThunk.rejected, (state, action) => {
			return { ...state, segment: { ...state.segment, isLoading: false, error: { ...(action.payload as object) } } };
		});

		builder.addCase(postMoisSegmentThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(postMoisSegmentThunk.rejected, (state, action) => {
			return { ...state, segment: { ...state.segment, isLoading: false, error: { ...(action.payload as object) } } };
		});

		builder.addCase(postMoisSegmentThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					error: null,
					meta: state.segment.meta.concat({
						segmentColor: action.payload.segmentColor,
						id: action.payload.segmentId,
						segmentGraphYn: false,
						segmentKey: action.payload.segmentKey,
						segmentName: action.payload.segmentName,
						segmentOrder: state.segment.meta.length + 1,
						uris: [...action.payload.uris],
						maxInflow: action.payload.maxInflow,
						accessYn: action.payload.accessYn,
					}),
					value: state.segment.value.concat({
						prevSegCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: action.payload.maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						segCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: action.payload.maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
							x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
							y: null,
						})),
						segmentGraphYn: false,
						segmentColor: action.payload.segmentColor,
						segmentKey: action.payload.segmentKey,
						accessYn: action.payload.accessYn,
						segmentName: action.payload.segmentName,
						maxProcessTime: 0,
						maxWaitTicket: 0,
						id: action.payload.segmentId,
						classic: {
							id: action.payload.segmentKey,
							type: 1,
							new_key_chk_enter: 0,
							wait_user: 0,
							block: 0,
							wait_tm: 0,
							chk_enter_succ: 0,
							limit: action.payload.maxInflow,
							complete_succ: 0,
							xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							used_size: 0,
							// 미사용
							bypass: 0,
							access_mode: 0,
							ic_access_mode: 0,
							wait_max: 10,
							limit_max: 100,
							limitmax: 500,
						},
					}),
				},
			};
		});

		builder.addCase(deleteSegmentGraphYnThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(deleteSegmentGraphYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					meta: state.segment.meta.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: false };
						}
						return el;
					}),
					value: state.segment.value.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: false };
						}
						return el;
					}),
				},
			};
		});

		builder.addCase(deleteSegmentGraphYnThunk.rejected, (state, action) => {
			if (action.payload?.error && action.payload.error?.errorCode === 'S1002') {
				return {
					...state,
					segment: {
						...state.segment,
						isLoading: false,
						meta: state.segment.meta.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: false };
							}
							return el;
						}),
						value: state.segment.value.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: false };
							}
							return el;
						}),
					},
				};
			}

			return { ...state, segment: { ...state.segment, isLoading: false } };
		});

		builder.addCase(postSegmentGraphYnThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(postSegmentGraphYnThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					meta: state.segment.meta.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: true };
						}
						return el;
					}),
					value: state.segment.value.map(el => {
						if (el.id === action.payload.id) {
							return { ...el, segmentGraphYn: true };
						}
						return el;
					}),
				},
			};
		});

		builder.addCase(postSegmentGraphYnThunk.rejected, (state, action) => {
			if (action.payload?.error && action.payload.error?.errorCode === 'S1003') {
				return {
					...state,
					segment: {
						...state.segment,
						isLoading: false,
						meta: state.segment.meta.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: true };
							}
							return el;
						}),
						value: state.segment.value.map(el => {
							if (el.id === action.payload?.id) {
								return { ...el, segmentGraphYn: true };
							}
							return el;
						}),
					},
				};
			}

			return { ...state, segment: { ...state.segment, isLoading: false } };
		});

		builder.addCase(deleteSegmentThunk.pending, state => {
			return { ...state, segment: { ...state.segment, isLoading: true } };
		});

		builder.addCase(deleteSegmentThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					isLoading: false,
					meta: state.segment.meta
						.filter(el => el.id !== action.payload.id)
						.map(item => {
							if (item.segmentOrder < action.payload.segmentOrder) {
								return item;
							}
							return { ...item, segmentOrder: item.segmentOrder - 1 };
						}),
					value: state.segment.value.filter(el => el.id !== action.payload.id),
				},
			};
		});

		builder.addCase(deleteSegmentThunk.rejected, state => {
			return { ...state, segment: { ...state.segment, isLoading: false } };
		});
	},
});

export const { setInitialMonitoringData, cleanupSegmentRawData, resetMonitoringError } = monitoringSlice.actions;
export default monitoringSlice.reducer;

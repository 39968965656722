/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { RootState } from 'common/redux/store';
import { useSelector } from 'react-redux';
import { ArrowDownIcon, ArrowUpIcon, paletteSDS } from 'surf-design-system';
import { MetricsSortColumn } from 'features/nfEUM/context/eumTXNMetricsSlice';

interface MetricsSortIconProps {
	column: MetricsSortColumn;
	onClick: () => void;
}

export default function MetricsSortIcon({ column, onClick }: MetricsSortIconProps) {
	const { sortType, sortColumn } = useSelector((state: RootState) => state.eumTXNMetrics);

	return sortType[column] === 'desc' ? (
		<ArrowDownIcon
			size="xSmall"
			fill={sortColumn === column ? paletteSDS.gray[900] : paletteSDS.gray[500]}
			onClick={onClick}
		/>
	) : (
		<ArrowUpIcon
			size="xSmall"
			fill={sortColumn === column ? paletteSDS.gray[900] : paletteSDS.gray[500]}
			onClick={onClick}
		/>
	);
}

/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useReducer } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Textinput } from 'surf-design-system';

import Astrix from 'common/components/Astrix';
import { useAppDispatch, RootState } from 'common/redux/store';

import { postOrganizationAddressThunk } from 'features/plan/thunk/postOrganizationAddress.thunk';

import {
	initialBillingInfoForm,
	UPDATE_VAT,
	UPDATE_REGION,
	UPDATE_CITY,
	UPDATE_POSTAL_CODE,
	UPDATE_COUNTRY,
	UPDATE_STREET_ADDRESS_DETAIL,
	UPDATE_STREET_ADDRESS,
	VALIDATE_POSTAL_CODE,
	VALIDATE_CITY,
	VALIDATE_STREET_ADDRESS,
	VALIDATE_COUNTRY,
	UPDATE_CHANGED_STATE,
	billingInfoFormReducer,
} from 'features/plan/context/billingInfoForm.reducer';

import BillingInfoPromptModal from 'features/plan/components/BillingInfoPromptModal';

import { COUNTRY_LIST, MAX_PAYMENT_CHAR_NUM, MAX_PAYMENT_VAT_CHAR_NUM } from '../data/contants';

import PlanTitle from './PlanTitle';
import PaymentMethod from './PaymentMethod';

export default function KoreanBillingInfoForm() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { country, streetAddress, streetAddressDetail, city, region, postalCode, vat } = useSelector(
		(state: RootState) => state.address
	);

	const [billingInfoFormData, setBillingInfoFormData] = useReducer(billingInfoFormReducer, {
		...initialBillingInfoForm,
		country,
		streetAddress,
		streetAddressDetail,
		city,
		region,
		postalCode,
		vat,
	});

	const {
		countryHelpText,
		countryValidation,
		country: enteredCountry,
		isCountryChanged,
		streetAddress: enteredStreetAddress,
		streetAddressHelpText,
		streetAddressValidation,
		isStreetAddressChanged,
		isStreetAddressValid,
		streetAddressDetail: enteredStreetAddressDetail,
		isStreetAddressDetailChanged,
		city: enteredCity,
		cityHelpText,
		cityValidation,
		isCityChanged,
		isCityValid,
		postalCode: enteredPostalCode,
		postalCodeHelptext,
		postalCodeValidation,
		isPostalCodeChanged,
		isPostalCodeValid,
		vat: enteredVat,
		isVatChanged,
		region: enteredRegion,
		isRegionChanged,
		isCountryValid,
	} = billingInfoFormData;

	const countryChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setBillingInfoFormData({ type: UPDATE_COUNTRY, payload: e.target.value });
	};

	const countryBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_COUNTRY });
	};

	const streetAddressChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_STREET_ADDRESS, payload: e.target.value });
	};

	const streetAddressBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_STREET_ADDRESS });
	};

	const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_CITY, payload: e.target.value });
	};

	const cityBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_CITY });
	};

	const postalCodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_POSTAL_CODE, payload: e.target.value });
	};

	const postalBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_POSTAL_CODE });
	};

	const streetAddressDetailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_STREET_ADDRESS_DETAIL, payload: e.target.value });
	};

	const regionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_REGION, payload: e.target.value });
	};

	const vatChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_VAT, payload: e.target.value });
	};

	const paymentFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();

		await dispatch(
			postOrganizationAddressThunk({
				country: enteredCountry,
				streetAddress: enteredStreetAddress,
				streetAddressDetail: enteredStreetAddressDetail,
				region: enteredRegion,
				city: enteredCity,
				postalCode: enteredPostalCode,
				vat: enteredVat,
				isFreebeta: true,
			})
		);

		setBillingInfoFormData({ type: UPDATE_CHANGED_STATE });
	};

	return (
		<form css={formSt} onSubmit={paymentFormSubmitHandler}>
			<div css={titleWrap}>
				<PlanTitle title={t('freeBeta.billingInfo')} />
			</div>
			<div css={lowerBox}>
				<div css={inputContainerSt}>
					<h5>{t('planSubscription.companyAddress')}</h5>
					<Dropdown
						label={
							<span>
								{t('planSubscription.country')}
								<Astrix />
							</span>
						}
						menu={COUNTRY_LIST}
						placeholder={t('planSubscription.selectCountry')}
						width="100%"
						helpText={isCountryChanged && countryValidation === 'failed' && t(countryHelpText)}
						validation={isCountryChanged ? countryValidation : 'none'}
						onChange={countryChangeHandler}
						bindedValue={enteredCountry}
						onBlur={countryBlurHandler}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.streetAddress')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						helpText={isStreetAddressChanged && !isStreetAddressValid && t(streetAddressHelpText)}
						validation={isStreetAddressChanged ? streetAddressValidation : 'none'}
						onChange={streetAddressChangeHandler}
						value={enteredStreetAddress}
						onBlur={streetAddressBlurHandler}
					/>
					<Textinput
						label={t('planSubscription.suietUnit')}
						placeholder="Optional"
						maxLength={MAX_PAYMENT_CHAR_NUM}
						onChange={streetAddressDetailChangeHandler}
						value={enteredStreetAddressDetail}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.city')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						helpText={isCityChanged && !isCityValid && t(cityHelpText)}
						validation={isCityChanged ? cityValidation : 'none'}
						onChange={cityChangeHandler}
						value={enteredCity}
						onBlur={cityBlurHandler}
					/>
					<Textinput
						label={t('planSubscription.stateProvReg')}
						placeholder={t('planSubscription.optional')}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						value={enteredRegion}
						onChange={regionChangeHandler}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.zipCode')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						helpText={isPostalCodeChanged && !isPostalCodeValid && t(postalCodeHelptext)}
						validation={isPostalCodeChanged ? postalCodeValidation : 'none'}
						onChange={postalCodeChangeHandler}
						onBlur={postalBlurHandler}
						value={enteredPostalCode}
					/>
					<Textinput
						label={t('planSubscription.vat')}
						placeholder="Optional"
						maxLength={MAX_PAYMENT_VAT_CHAR_NUM}
						value={enteredVat}
						onChange={vatChangeHandler}
					/>
					<Button
						size="medium"
						width="100%"
						disabled={
							(isPostalCodeChanged && !isPostalCodeValid) ||
							(isStreetAddressChanged && !isStreetAddressValid) ||
							(isCityChanged && !isCityValid) ||
							(isCountryChanged && !isCountryValid)
						}
					>
						{t('planSubscription.saveChanges')}
					</Button>
				</div>
				<PaymentMethod footer="single" />
			</div>
			<BillingInfoPromptModal
				when={
					isPostalCodeChanged ||
					isCityChanged ||
					isCountryChanged ||
					isStreetAddressChanged ||
					isStreetAddressDetailChanged ||
					isVatChanged ||
					isRegionChanged
				}
			/>
		</form>
	);
}

const inputContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 25rem;
`;

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 3rem;
`;

const titleWrap = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const lowerBox = css`
	display: flex;
	justify-content: space-between;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { setIsIntervalSegmentDeleteConfirmModalVisible } from 'common/context/modalSlice';

import deletePathControlSegmentApi from 'features/nfControl/api/deletePathControlSegment.v2.api';

export interface DeletePathControlSegmentThunkResult {
	id: number;
	segmentOrder: number;
}

export interface DeletePathControlSegmentThunkProps {
	segmentId: number;
	segmentOrder: number;
	onClose: () => void;
}

const deletePathControlSegmentThunk = createAsyncThunk<
	DeletePathControlSegmentThunkResult,
	DeletePathControlSegmentThunkProps,
	{ state: RootState }
>('path/Segment/delete/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId, segmentOrder, onClose } = args;

	try {
		const { data } = await deletePathControlSegmentApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			segmentId,
		});

		onClose();

		thunkAPI.dispatch(setIsIntervalSegmentDeleteConfirmModalVisible(true));

		return { ...data, segmentOrder };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default deletePathControlSegmentThunk;

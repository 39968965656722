/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useRef, ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { checkFileValidation } from 'common/utils/fileObjectFunc';
import Astrix from 'common/components/Astrix';

export interface FileUploadFormProps {
	id: string;
	name?: string;
	label?: React.ReactNode;
	helpText?: React.ReactNode;
	isRequire?: boolean;
	onSuccess: (file: File) => void;
	onFailByType?: () => void;
	onFailBySize?: () => void;
	onDelete: () => void;
}

/**
 * author: Finn
 * createdAt: 2022-07-11
 * updatedAt: 2022-07-29
 * description: 파일 업로드 컴포넌트
 * todo:
 */
export default function FileUploadForm({
	id,
	name,
	label,
	helpText,
	isRequire = false,
	onSuccess,
	onFailBySize = () => {},
	onFailByType = () => {},
	onDelete,
}: FileUploadFormProps) {
	const { t } = useTranslation();

	const [fileName, setFileName] = useState('');

	const selectFileRef = useRef<HTMLInputElement>(null);

	/**
	 * description: 커스텀 한 input file 클릭 제어 함수
	 */
	const addFileClickHandler = (e: MouseEvent) => {
		e.preventDefault();
		selectFileRef?.current?.click();
	};

	/**
	 * description: 이미지 변경 이벤트 함수
	 */
	const fileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;

		const uploadedFile = e.target.files[0];

		const { isFileSizeValid, isFileTypeValid } = checkFileValidation({
			fileName: uploadedFile.name,
			fileSize: uploadedFile.size,
			maxFileSize: 512000,
		});

		if (selectFileRef.current) {
			selectFileRef.current.value = '';
		}

		if (isFileSizeValid && isFileTypeValid) {
			onSuccess(uploadedFile);
			setFileName(uploadedFile.name);
			return;
		}

		if (!isFileTypeValid) {
			onFailByType();
			return;
		}

		if (!isFileSizeValid) {
			onFailBySize();
		}
	};

	/**
	 * description: 이미지 삭제 이벤트 함수
	 */
	const closeIconClickHandler = () => {
		if (selectFileRef && selectFileRef.current) {
			selectFileRef.current.value = '';
		}
		setFileName('');
		onDelete();
	};

	return (
		<React.Fragment>
			<div css={containerSt}>
				<div css={textBoxSt}>
					<div>
						{label}
						{isRequire && <Astrix />}
					</div>
					<div>{helpText}</div>
				</div>
				<div css={buttonSt}>
					<input
						id={id}
						name={name}
						type="file"
						ref={selectFileRef}
						onChange={fileChangeHandler}
						accept=".jpg, .png, .jpeg"
					/>
					<button onClick={addFileClickHandler}>{t('projectMgmt.addFiles')}</button>
				</div>
			</div>
			{fileName !== '' ? (
				<div css={fileInfoSt}>
					<div />
					<div>{fileName}</div>
					<div onClick={closeIconClickHandler} aria-hidden>
						<CloseIcon size="xSmall" fill={paletteSDS.blue[700]} />
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
}

const containerSt = css`
	display: flex;
`;

const textBoxSt = css`
	width: 100%;
	color: ${paletteSDS.gray[700]};
	margin-right: 1rem;
	div:nth-of-type(1) {
		height: 1.375rem;
		line-height: 1.375rem;
		margin-bottom: 0.5rem;
		font-size: ${themeSDS.fontSize[14]};
	}
	div:nth-of-type(2) {
		line-height: 1.1875rem;
		font-size: ${themeSDS.fontSize[12]};
		white-space: pre;
	}
`;

const buttonSt = css`
	position: relative;
	top: 17px;
	width: 92px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	> input {
		display: none;
	}
	> button {
		border: 0;
		background: transparent;
		outline: 0;
		font-size: ${themeSDS.fontSize[14]};
		font-weight: ${themeSDS.fontWeight.medium};
		color: ${paletteSDS.blue[700]};
		cursor: pointer;
	}
`;

const fileInfoSt = css`
	display: flex;
	height: 1.5rem;
	line-height: 1.5rem;
	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
	div:nth-of-type(1) {
		width: 0.25rem;
		height: 1.5rem;
		margin-right: 1rem;
		border-radius: 4px;
		background-color: ${paletteSDS.blue[100]};
	}
	div:nth-of-type(2) {
		width: 29.25rem;
		margin-right: 0.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	div:nth-of-type(3) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Modal, Portal, paletteSDS, Loader } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import { putProjectAccessYnThunk } from 'features/nfSetting/thunk/putProjectAccessYn.v1.thunk';
import { resetProjectError } from 'features/nfSetting/context/projectInfoSlice';

export default function PutAccessYnModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isLoading } = useSelector((state: RootState) => state.projectInfo);

	const { projectName, selectedProjectId, accessYn } = useSelector(
		(state: RootState) => state.modal.projectManagementModal
	);

	const cancelButtonClickHandler = () => {
		dispatch(initializeProjectManagementModal());
	};

	/**
	 * description: 접근제어 버튼 클릭 함수
	 */
	const primaryButtonClickHandler = async () => {
		await dispatch(putProjectAccessYnThunk({ accessYn: !accessYn, selectedProjectId }));
	};

	useEffect(() => {
		return () => {
			dispatch(resetProjectError());
		};
	}, [dispatch]);

	return (
		<Portal>
			<Modal
				visible
				title={t(`${!accessYn ? 'modal.unblckPrj' : 'modal.prjBlockAccess'}`)}
				actionType="double"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t(`${!accessYn ? 'modal.unblck' : 'modal.blck'}`)}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={cancelButtonClickHandler}
				secondaryBtnDisable={isLoading}
				isCloseIconVisible={false}
				primaryBtnDisable={isLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<div css={accessYn ? contentSt.disable : contentSt.access}>
					<span>
						<Trans
							i18nKey={`${!accessYn ? 'modal.unblckPrjMsg' : 'modal.blckbPrjMsg'}`}
							values={{ title: projectName }}
							components={{ bold: <b /> }}
						/>
					</span>
					{accessYn ? <div>{t('projectMgmt.accessDescr')}</div> : null}
				</div>
			</Modal>
		</Portal>
	);
}

const contentSt = {
	access: css`
		color: ${paletteSDS.gray[900]};
	`,

	disable: css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		height: 6.5rem;
		line-height: 1.625rem;

		color: ${paletteSDS.gray[900]};
	`,
};

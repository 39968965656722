/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { RootState, useAppDispatch } from 'common/redux/store';
import { useSelector } from 'react-redux';
import { paletteSDS, ChevronRightIcon } from 'surf-design-system';
import { MapPinIcon } from 'features/nfEUM/assets';
import { mapJsFormToTitle } from 'features/nfEUM/utils/mapFunc';
import { getTXNMapCountryThunk } from 'features/nfEUM/thunk/getTXNMapCountry.thunk';
import { setHomeBtnClicked } from '../context/eumTXNMapSlice';

export default function TXNMapButton() {
	const dispatch = useAppDispatch();
	const { dataProvider } = useSelector((state: RootState) => state.eumTXNMap);

	const checkIsMapNameUsa = (map: string) => {
		let result = mapJsFormToTitle(map);
		if (result === 'Usa') result = 'United States';
		return result;
	};

	return (
		<div css={containerCss}>
			<MapPinIcon fill={paletteSDS.blue[700]} />
			<div
				css={worldBtnCss(dataProvider.map)}
				onClick={() => {
					dispatch(getTXNMapCountryThunk());
					dispatch(setHomeBtnClicked(true));
				}}
				aria-hidden
			>
				World Wide
			</div>
			{dataProvider.map !== 'worldLow' && (
				<div css={breadCrumbCss(dataProvider.map)}>
					<ChevronRightIcon fill={paletteSDS.gray[700]} size="xSmall" />
					<div>{checkIsMapNameUsa(dataProvider.map)}</div>
				</div>
			)}
		</div>
	);
}

const containerCss = css`
	position: absolute;
	top: 8px;
	left: 16px;
	display: flex;
	align-items: center;
	gap: 6px;
	height: 28px;
	z-index: 10;
	color: ${paletteSDS.gray[700]};
	font-weight: 400;
	font-size: 12px;
	line-height: 128%;
	letter-spacing: 0.16px;
`;

const worldBtnCss = (selectedMap: string) => css`
	cursor: pointer;
	color: ${selectedMap === 'worldLow' ? paletteSDS.blue[700] : paletteSDS.gray[700]};
`;

const breadCrumbCss = (selectedMap: string) => css`
	display: flex;
	align-items: center;
	color: ${selectedMap !== 'world' ? paletteSDS.blue[700] : paletteSDS.gray[700]};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

export default function RegisterDomainTitle() {
	const { t } = useTranslation();

	return <div css={domainSettingTitleSt}>{t('AddDomain.addDomain')}</div>;
}

const domainSettingTitleSt = css`
	text-align: center;

	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize[24]};
	line-height: 128%;
	letter-spacing: -0.008em;
	color: ${paletteSDS.gray[900]};
`;

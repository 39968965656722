/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';

import { period } from '../api/getProjectUsageDetail.api';

import WaitingUsersDailyChart from './WaitingUsersDailyChart';
import WaitingUsersMonthlyChart from './WaitingUsersMonthlyChart';
import WaitingUsersYearlyChart from './WaitingUsersYearlyChart';

export interface WaitingUsersChartProps {
	chartType: period;
}

// todo : data 개수에 따른 category 및 데이터 값 설정
export default function WaitingUsersChart({ chartType }: WaitingUsersChartProps) {
	if (chartType === 'daily') {
		return <WaitingUsersDailyChart />;
	}

	if (chartType === 'monthly') {
		return <WaitingUsersMonthlyChart />;
	}

	return <WaitingUsersYearlyChart />;
}

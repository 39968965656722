/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Button, themeSDS, paletteSDS, DoneIcon } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { LANG_PATH, NETFUNNEL } from 'common/data/constants';

import { getDateFormatLanguage } from 'features/plan/utils/formatFunc';
import type { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';
import type { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';

export default function FinishSubscriptionPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { language } = useSelector((state: RootState) => state.account);

	const { planLangs } = useSelector((state: RootState) => state.plan);

	const { products } = useSelector((state: RootState) => state.subscription);

	const planLang = planLangs.find(el => el.langType === language) as PlanLangs;

	const confirmButtonClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan`);
	};

	const netFunnelSubscriptionInfo = products.find(el => el.productType === NETFUNNEL) as SubscriptionData;

	const payDate = moment(netFunnelSubscriptionInfo.chargeDateTime).utcOffset(0).format('YYYY-MM-DD');

	const billedDate = getDateFormatLanguage(payDate, language);

	return (
		<div css={containerSt}>
			<div css={topTextSt}>
				<DoneIcon size="large" fill={paletteSDS.green['500']} />
				<h3>{t('planSubscription.planChangedMsg')}</h3>
				<ul>
					<li>
						{t('planSubscription.yourPlan')}
						<span>{planLang.name}</span>
					</li>
					<li>
						{t('planSubscription.NextPymtDue')}
						<span>{billedDate}</span>
					</li>
				</ul>
				<Button width="160px" onClick={confirmButtonClickHandler}>
					확인
				</Button>
			</div>
			<div css={bottomTextSt}>
				<p>{t('planSubscription.planPymtDetail')}</p>
				<div css={textBoxSt}>
					<div>
						<p>{t('planSubscription.planPymtDetailMsg1')}</p>
						<ul css={listSt}>
							<li>{t('planSubscription.planPymtDetailMsg2')}</li>
							<li>{t('planSubscription.planPymtDetailMsg3')}</li>
						</ul>
					</div>
					<div>
						<p>{t('planSubscription.planCancelDetailMsg')}</p>
						<p>{t('planSubscription.usageRequestDetailMsg')}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

const containerSt = css`
	margin-top: 1.25rem;
`;

const topTextSt = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	align-items: center;

	margin: 2rem auto 4.5rem;

	> h2 {
		width: 100%;
		margin-bottom: 2rem;
		text-align: center;
	}

	> ul {
		margin-bottom: 2.5rem;
		font-size: ${themeSDS.fontSize[18]};

		> li {
			list-style: inside;

			> span {
				color: ${paletteSDS.blue[700]};
				font-weight: ${themeSDS.fontWeight.medium};
			}

			:nth-of-type(1) {
				margin-bottom: 10px;
			}
		}
	}
`;

const bottomTextSt = css`
	padding: 1.25rem 1.625rem;

	color: ${paletteSDS.gray[900]};
	background-color: ${paletteSDS.gray[50]};

	border: 1px solid ${paletteSDS.gray[200]};

	border-radius: 8px;

	> p:nth-of-type(1) {
		margin-bottom: 0.5rem;
		color: ${paletteSDS.black};
		font-size: ${themeSDS.fontSize[16]};
		font-weight: 600;
	}
`;

const textBoxSt = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	line-height: 160%;
	font-size: ${themeSDS.fontSize[14]};
`;

const listSt = css`
	margin-bottom: 2rem;

	> li {
		list-style: inside;
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'common/redux/store';

import { PlanInfo } from '../types/getPlanList.type';
import PlanItem from './PlanItem';
/**
 * author: Jay
 * createdAt: 2022-08-08
 * updatedAt: 2022-08-08
 * description: plan 선택 항목
 * todo:
 */

interface PlanListProps {
	plans: PlanInfo[];
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	selectedPlanId: number;
	planSubscription: boolean;
}

export default function PlanList({ plans, onChange, selectedPlanId, planSubscription }: PlanListProps) {
	const { planId } = useSelector((state: RootState) => state.plan);

	return (
		<div css={planListCss}>
			{plans.map(plan => (
				<PlanItem
					plan={plan}
					selectedPlanId={selectedPlanId}
					onChange={onChange}
					key={plan.planId}
					disabled={!planSubscription && planId !== plan.planId}
				/>
			))}
		</div>
	);
}

const planListCss = css`
	display: flex;
	flex-direction: column;
	max-width: 100rem;
	gap: 0.5rem;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useEffect, createRef, useReducer, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { Button, ChevronLeftIcon, Dropdown, Textinput, paletteSDS } from 'surf-design-system';

import Astrix from 'common/components/Astrix';
import { useAppDispatch, RootState } from 'common/redux/store';
import { toggleIsPlanChangeConfirmModalVisible } from 'common/context/modalSlice';

import { setInitialAddressInfo } from 'features/plan/context/addressSlice';
import { LANG_PATH } from 'common/data/constants';

import { COUNTRY_LIST, MAX_PAYMENT_CHAR_NUM, MAX_PAYMENT_VAT_CHAR_NUM } from '../data/contants';
import {
	companyAddressFormReducer,
	UPDATE_COUNTRY_VALIDATION,
	UPDATE_STREET_ADDRESS_VALIDATION,
	UPDATE_CITY_VALIDATION,
	UPDATE_POSTAL_CODE_VALIDATION,
	initialCompanyAddressForm,
} from '../context/addressForm.reducer';
import PlanTitle from './PlanTitle';
import PaymentMethod from './PaymentMethod';
import PlanChangeConfirmModal from './PlanChangeConfirmModal';
import { postSubscriptionThunk } from '../thunk/postSubscription.thunk';

export default function SubscriptionForm() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { search } = useLocation();

	const { country, streetAddress, streetAddressDetail, city, region, postalCode, vat, isLoaded } = useSelector(
		(state: RootState) => state.address
	);

	const { language } = useSelector((state: RootState) => state.account);

	const [companyAddressFormData, setCompanyAddressFormData] = useReducer(
		companyAddressFormReducer,
		initialCompanyAddressForm
	);

	const {
		countryHelpText,
		countryValidation,
		streetAddressHelpText,
		streetAddressValidation,
		cityHelpText,
		cityValidation,
		postalCodeHelptext,
		postalCodeValidation,
	} = companyAddressFormData;

	const countryRef = useMemo(() => createRef<HTMLOptionElement>(), []);
	const streetAddressRef = useMemo(() => createRef<HTMLInputElement>(), []);
	const streetAddressDetailRef = useMemo(() => createRef<HTMLInputElement>(), []);
	const cityRef = useMemo(() => createRef<HTMLInputElement>(), []);
	const regionRef = useMemo(() => createRef<HTMLInputElement>(), []);
	const postalCodeRef = useMemo(() => createRef<HTMLInputElement>(), []);
	const vatRef = useMemo(() => createRef<HTMLInputElement>(), []);

	useEffect(() => {
		if (streetAddressRef.current) {
			streetAddressRef.current.value = streetAddress;
		}

		if (streetAddressDetailRef.current) {
			streetAddressDetailRef.current.value = streetAddressDetail;
		}

		if (cityRef.current) {
			cityRef.current.value = city;
		}

		if (regionRef.current) {
			regionRef.current.value = region;
		}

		if (postalCodeRef.current) {
			postalCodeRef.current.value = postalCode;
		}

		if (vatRef.current) {
			vatRef.current.value = vat;
		}
	}, [
		streetAddress,
		streetAddressRef,
		streetAddressDetail,
		streetAddressDetailRef,
		city,
		cityRef,
		regionRef,
		region,
		postalCode,
		postalCodeRef,
		vatRef,
		vat,
	]);

	useEffect(() => {
		return () => {
			dispatch(setInitialAddressInfo());
		};
	}, [dispatch]);

	if (!isLoaded) return <div />;

	const countryChangeHandler = () => {
		setCompanyAddressFormData({ type: UPDATE_COUNTRY_VALIDATION, payload: 'none' });
	};
	const streetAddressChangeHandler = () => {
		setCompanyAddressFormData({ type: UPDATE_STREET_ADDRESS_VALIDATION, payload: 'none' });
	};
	const cityChangeHandler = () => {
		setCompanyAddressFormData({ type: UPDATE_CITY_VALIDATION, payload: 'none' });
	};
	const postalCodeChangeHandler = () => {
		setCompanyAddressFormData({ type: UPDATE_POSTAL_CODE_VALIDATION, payload: 'none' });
	};

	const paymentFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault();

		let isValid = true;

		if (countryRef.current?.value.trim() === '') {
			setCompanyAddressFormData({ type: UPDATE_COUNTRY_VALIDATION, payload: 'failed' });
			isValid = false;
		}

		if (streetAddressRef.current?.value.trim() === '') {
			setCompanyAddressFormData({ type: UPDATE_STREET_ADDRESS_VALIDATION, payload: 'failed' });
			isValid = false;
		}

		if (cityRef.current?.value.trim() === '') {
			setCompanyAddressFormData({ type: UPDATE_CITY_VALIDATION, payload: 'failed' });
			isValid = false;
		}

		if (postalCodeRef.current?.value.trim() === '') {
			setCompanyAddressFormData({ type: UPDATE_POSTAL_CODE_VALIDATION, payload: 'failed' });
			isValid = false;
		}

		if (!isValid) return;

		dispatch(toggleIsPlanChangeConfirmModalVisible());
	};

	const planChangeConfirmModalPrimaryButtonClickHandler = async () => {
		if (
			countryRef.current &&
			streetAddressRef.current &&
			streetAddressDetailRef.current &&
			regionRef.current &&
			cityRef.current &&
			postalCodeRef.current &&
			vatRef.current
		) {
			const { id, version } = qs.parse(search, { ignoreQueryPrefix: true });

			await dispatch(
				postSubscriptionThunk({
					country: countryRef.current.value,
					streetAddress: streetAddressRef.current.value,
					streetAddressDetail: streetAddressDetailRef.current.value,
					region: regionRef.current.value,
					city: cityRef.current.value,
					postalCode: postalCodeRef.current.value,
					vat: vatRef.current.value,
					planId: Number(id),
					version: Number(version),
				})
			);

			navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/finish_subscription`);
		}
	};

	return (
		<form css={formSt} onSubmit={paymentFormSubmitHandler}>
			<div css={titleWrap}>
				<PlanTitle title={t('planSubscription.pymt')} />
				<div css={buttonWrap}>
					<Button
						mould="secondary"
						icon={<ChevronLeftIcon fill={paletteSDS.blue[700]} />}
						size="medium"
						iconPosition="left"
						onClick={() => {
							navigate(`/${LANG_PATH[language]}/console/plan_usage/plan/choose_plan`);
						}}
						type="button"
					>
						{t('planSubscription.back')}
					</Button>
					<Button size="medium">{t('modal.finalize')}</Button>
				</div>
			</div>
			<div css={lowerBox}>
				<div css={inputContainerSt}>
					<h5>{t('planSubscription.companyAddress')}</h5>
					<Dropdown
						label={
							<span>
								{t('planSubscription.country')}
								<Astrix />
							</span>
						}
						menu={COUNTRY_LIST}
						placeholder={t('planSubscription.selectCountry')}
						width="100%"
						ref={countryRef}
						defaultValue={COUNTRY_LIST.find(el => el.value === country)?.value || ''}
						helpText={countryValidation === 'failed' ? t(countryHelpText) : ''}
						validation={countryValidation}
						onChange={countryChangeHandler}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.streetAddress')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						ref={streetAddressRef}
						helpText={streetAddressValidation === 'failed' ? t(streetAddressHelpText) : ''}
						validation={streetAddressValidation}
						onChange={streetAddressChangeHandler}
					/>
					<Textinput
						label={t('planSubscription.suietUnit')}
						placeholder="Optional"
						maxLength={MAX_PAYMENT_CHAR_NUM}
						ref={streetAddressDetailRef}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.city')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						ref={cityRef}
						helpText={cityValidation === 'failed' ? t(cityHelpText) : ''}
						validation={cityValidation}
						onChange={cityChangeHandler}
					/>
					<Textinput
						label={t('planSubscription.stateProvReg')}
						placeholder="Optional"
						maxLength={MAX_PAYMENT_CHAR_NUM}
						ref={regionRef}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.zipCode')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						ref={postalCodeRef}
						helpText={postalCodeValidation === 'failed' ? t(postalCodeHelptext) : ''}
						validation={postalCodeValidation}
						onChange={postalCodeChangeHandler}
					/>
					<Textinput
						label={t('planSubscription.vat')}
						placeholder={t('planSubscription.optional')}
						maxLength={MAX_PAYMENT_VAT_CHAR_NUM}
						ref={vatRef}
					/>
				</div>
				<PaymentMethod footer="single" />
			</div>
			<PlanChangeConfirmModal primaryButtonClickHandler={planChangeConfirmModalPrimaryButtonClickHandler} />
		</form>
	);
}

const inputContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 25rem;
`;

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 3rem;
`;

const titleWrap = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const buttonWrap = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const lowerBox = css`
	display: flex;
	justify-content: space-between;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { colorPalette, Modal, themeCss, paletteSDS, Portal } from 'surf-design-system';

import { changeIsOveragesModalVisible } from 'common/context/modalSlice';
import { RootState, useAppDispatch } from 'common/redux/store';
import {
	PLAN_ENTERPRISE_ID,
	PLAN_FREE_BETA_ID,
	PLAN_LARGE_ID,
	PLAN_MEDIUM_ID,
	PLAN_SMALL_ID,
} from 'common/data/constants';

import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';

import { PlanInfo } from '../types/getPlanList.type';
import OverageValue from './OverageValue';
import OveragesName from './OverageName';

export interface OveragesInfoModalProps {
	plans: PlanInfo[];
}

export default function OveragesInfoModal({ plans }: OveragesInfoModalProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isOveragesModalVisible } = useSelector((state: RootState) => state.modal);

	const { language } = useSelector((state: RootState) => state.account);

	const { planId, planLangs } = useSelector((state: RootState) => state.plan);

	const planLang = planLangs.find(el => el.langType === language) as PlanLangs;

	const overageModalVisibleChangeHandler = () => {
		dispatch(changeIsOveragesModalVisible());
	};

	let planColor = '#0FB5AE';

	if (planId === PLAN_SMALL_ID) {
		planColor = '#13A1F6';
	}

	if (planId === PLAN_MEDIUM_ID) {
		planColor = '#4A70FF';
	}

	if (planId === PLAN_LARGE_ID) {
		planColor = '#4046CA';
	}

	if (planId === PLAN_ENTERPRISE_ID) {
		planColor = '#0D1F66';
	}

	if (planId === PLAN_FREE_BETA_ID) {
		planColor = paletteSDS.categoryColor.skyBlue;
	}

	const planColorCss = css`
		background: ${planColor};
	`;

	return (
		<Portal>
			<Modal
				visible={isOveragesModalVisible}
				onClickCloseButton={overageModalVisibleChangeHandler}
				title={t('planSubscription.overageModalTitle')}
				width="896px"
				actionType="none"
				onClickOverlay={overageModalVisibleChangeHandler}
			>
				<span css={[themeCss.fontSize[14], themeCss.fontWeight.regular, colorPalette.gray[700], spanSt]}>
					{t('planSubscription.overageModalDescription')}
				</span>
				<div css={[themeCss.fontWeight.medium, currentPlanWrapSt]}>
					<div css={[colorPalette.black, themeCss.fontSize[16]]}>{t('planSubscription.yourCurrentPlan')}</div>
					<div css={[planColorCss, colorPalette.white, themeCss.fontSize[14], currentPlanSt]}>{planLang.name}</div>
				</div>
				<div css={overageContainerSt}>
					<OveragesName />
					<div css={overagesValueSt}>
						{plans.map(plan => (
							<OverageValue plan={plan} key={plan.planId} />
						))}
					</div>
				</div>
			</Modal>
		</Portal>
	);
}

const overageContainerSt = css`
	display: flex;
	gap: 0px;

	width: 832px;
	height: fit-content;

	border: 1px solid ${paletteSDS.gray[300]};

	border-radius: 8px;
`;

const spanSt = css`
	width: 832px;

	margin-bottom: 24px;

	white-space: pre-line;
	line-height: 160%;
	letter-spacing: 0.0128em;
`;

const overagesValueSt = css`
	display: flex;
	gap: 0px;

	width: 100%;
`;

const currentPlanWrapSt = css`
	display: flex;
	gap: 8px;
	align-items: center;

	margin-top: 32px;
	margin-bottom: 16px;
`;

const currentPlanSt = css`
	height: 30px;

	padding: 8px 10px 8px 10px;

	border-radius: 8px;
	line-height: 14px;
`;

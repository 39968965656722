import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { GetMemberListApiResponse } from 'features/setting/types/getMemberList.onPrem.type';

export interface GetMemberListApiProps {
	searchValue?: string;
	memberStatus?: string;
	organizationId: number;
}

export default async function getMemberListApi({ searchValue, memberStatus, organizationId }: GetMemberListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetMemberListApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/organization/user-list`,
			{
				params: { searchValue, memberStatus, pageNum: 0, pageSize: 100 },
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { ProjectStatDataValue } from 'features/nfBasic/types/postSegmentStatData.type';

export const projectInitialStatValue: ProjectStatDataValue = {
	totalTickets: 0,
	numbersWaiting: 0,
	waitTime: 0,
	totalInflow: 0,
	maxInflow: 0,
	totalOutflow: 0,
	usedSize: 0,
	nfBlock: 0,
	byPass: 0,
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';

import { paletteSDS, themeSDS } from 'surf-design-system';
// etc
import type { RootState } from 'common/redux/store';

let clockInterval: NodeJS.Timer | undefined;

export default function ProjectSummaryClock() {
	const {
		project: { isLoaded },
	} = useSelector((state: RootState) => state.nfControl);

	const { timeZone } = useSelector((state: RootState) => state.account);

	const [time, setTime] = useState<Moment>(moment().utcOffset(timeZone.slice(3)));

	useEffect(() => {
		if (isLoaded) {
			clockInterval = setInterval(() => {
				setTime(moment().utcOffset(timeZone.slice(3)));
			}, 1000);
			return () => clearInterval(clockInterval);
		}

		return undefined;
	}, [timeZone, isLoaded]);

	useEffect(() => {
		return () => {
			clearInterval(clockInterval);
		};
	}, []);

	if (!isLoaded) return <div css={containerSt} />;

	return (
		<div css={containerSt}>
			<div css={clockCss}>
				<div css={numberWrap}>
					<div css={numberSt}>{time.format('HH')}</div>
				</div>
				<div css={colonSt}>
					<div css={dotSt} />
					<div css={dotSt} />
				</div>
				<div css={numberWrap}>
					<div css={numberSt}>{time.format('mm')}</div>
				</div>
				<div css={colonSt}>
					<div css={dotSt} />
					<div css={dotSt} />
				</div>
				<div css={numberWrap}>
					<div css={numberSt}>{time.format('ss')}</div>
				</div>
			</div>
			<div css={timezoneSt}>{timeZone}</div>
		</div>
	);
}

const { fontWeight } = themeSDS;
const { gray, blue, systemColor } = paletteSDS;

const containerSt = css`
	position: relative;

	height: 80px;
	width: 265px;

	background-color: ${systemColor.white};
	border: 1px solid ${gray[300]};
	border-radius: 4px;

	font-weight: ${fontWeight.bold};
	font-size: 1.75rem;
	line-height: 100%;
	letter-spacing: -0.008em;
`;

const clockCss = css`
	display: flex;
	align-items: center;

	width: 200px;
	height: 80px;

	margin: auto;

	color: ${blue[800]};
`;

const numberWrap = css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 3.5rem;
	height: 3.5rem;
`;

const numberSt = css`
	width: 2.25rem;
	height: 1.75rem;

	text-align: center;
`;

const colonSt = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	padding-top: 0.2344rem;
	padding-bottom: 0.2344rem;

	width: 0.75rem;
	height: 1.5rem;
`;

const dotSt = css`
	width: 0.2813rem;
	height: 0.2813rem;

	border-radius: 50%;

	background: ${paletteSDS.blue[800]};
`;

const timezoneSt = css`
	position: absolute;

	color: ${paletteSDS.gray[600]};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['13']};

	bottom: 0px;
	left: 98.5px;
`;

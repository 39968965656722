/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button, RefreshIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { increaseLastUpdate, setInitialTimestamp } from 'features/plan/context/usageSlice';
import { getProjectUsageSummaryThunk } from 'features/plan/thunk/getProjectUsageSummary.v2.thunk';
import { getProjectUsageDetailThunk } from 'features/plan/thunk/getProjectUsageDetail.v2.thunk';
import { refreshTimeFormat } from 'features/plan/utils/formatFunc';

export default function OnPremUsageSummaryHeader() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { lastUpdate, initialMomentValue } = useSelector((state: RootState) => state.usage);

	const refreshTimeI18nKey = refreshTimeFormat(lastUpdate);

	let refreshTimeComponent = t(refreshTimeI18nKey);

	if (lastUpdate > 1 && lastUpdate < 60) {
		refreshTimeComponent = t(refreshTimeI18nKey, { minute: lastUpdate });
	}

	if (lastUpdate > 60) {
		refreshTimeComponent = t(refreshTimeI18nKey, { hour: Math.floor(lastUpdate / 60) });
	}

	const refreshButtonClickHandler = () => {
		dispatch(setInitialTimestamp(moment().valueOf()));
		dispatch(getProjectUsageSummaryThunk());
		dispatch(getProjectUsageDetailThunk());
	};

	// 페이지 진입 시 경과 시간 체크 (리프레쉬 버튼 클릭 X)
	useEffect(() => {
		const timer = setInterval(() => {
			dispatch(increaseLastUpdate());
		}, 60000);

		return () => clearInterval(timer);
	}, [dispatch]);

	return (
		<div css={container}>
			<div css={gap8px}>
				<div css={titleSt}>{t('planSubscription.usageSum')}</div>
				<div css={dividerSt} />
				<div css={subTitle}>{t('planSubscription.monthToDateUsage')}</div>
				<div css={rangeSt}>{`${moment(initialMomentValue)
					.add(lastUpdate, 'minutes')
					.utcOffset('+UTC09:00')
					.format('MMM')} 1 - ${moment(initialMomentValue)
					.add(lastUpdate, 'minutes')
					.utcOffset('+UTC09:00')
					.format('MMM')} ${moment(initialMomentValue)
					.add(lastUpdate, 'minutes')
					.utcOffset('+UTC09:00')
					.daysInMonth()}`}</div>
			</div>
			<div css={gap8px}>
				<div css={refreshLabel}>{refreshTimeComponent}</div>
				<Button
					icon={<RefreshIcon fill={paletteSDS.blue[700]} />}
					iconPosition="left"
					size="small"
					mould="secondary"
					onClick={refreshButtonClickHandler}
				>
					{t('planSubscription.refresh')}
				</Button>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 32px;
`;

const gap8px = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const titleSt = css`
	font-size: ${themeSDS.fontSize[20]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
	color: ${paletteSDS.gray[900]};
`;

const dividerSt = css`
	width: 1px;
	height: 15px;

	background: ${paletteSDS.gray['300']};
`;

const subTitle = css`
	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
	color: ${paletteSDS.gray[900]};
`;

const rangeSt = css`
	color: ${paletteSDS.gray[800]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;
`;

const refreshLabel = css`
	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 100%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import { paletteSDS } from 'surf-design-system';

import TXNMetrics from 'features/nfEUM/components/TXNMetrics';
import TXNToday from 'features/nfEUM/components/TXNToday';

export default function TXNLowerContainer() {
	return (
		<div css={container}>
			<TXNToday />
			<div css={metricsContainer}>
				<TXNMetrics />
			</div>
		</div>
	);
}

const container = css`
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
	min-width: 1588px;
`;

const metricsContainer = css`
	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
	width: 920px;
	height: 440px;
	padding: 16px 24px 24px 24px;
	background-color: ${paletteSDS.white};
`;

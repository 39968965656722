import axios from 'axios';

import { PostOauthUserInfoApiResponse } from 'features/signup/types/postOauthUserInfo.type';

export interface PostOauthUserInfoApiProps {
	idToken: string;
	timezone: string;
	company: string;
	region: string;
	language: string;
	country: string;
}

export default async function postOauthUserInfoApi({
	idToken,
	timezone,
	company,
	region,
	language,
	country,
}: PostOauthUserInfoApiProps) {
	const { data } = await axios.post<PostOauthUserInfoApiResponse>(
		`${process.env.REACT_APP_BASE_URL}/v1/user/oauth`,
		{
			timezone,
			company,
			region,
			language,
			country,
		},
		{
			headers: {
				Authorization: idToken,
			},
		}
	);
	return data;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { paletteSDS, themeSDS } from 'surf-design-system';

interface TXNChartCountProps {
	title?: string;
	count?: number | string;
}

export default function TXNChartCount({ title, count }: TXNChartCountProps) {
	return (
		<div css={totalCounts}>
			<div css={totalCountsTtl}>{title}</div>
			<div css={totalCountsValue}>{count !== '0' ? count : '-'}</div>
		</div>
	);
}
const totalCounts = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const totalCountsTtl = css`
	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 140%;
	letter-spacing: 0.32px;
`;

const totalCountsValue = css`
	color: ${paletteSDS.gray[900]};
	font-size: 28px;
	font-weight: 700;
	letter-spacing: -0.02em;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import type { VWRInfo } from 'features/vwr/types/getVWRList.v2.type';

import { getVWRListApi } from 'features/vwr/api/getVWRList.v2.api';

interface GetVWRListThunkResult {
	vwrList: VWRInfo[];
}

export const getVWRListThunk = createAsyncThunk<GetVWRListThunkResult, void, { state: RootState }>(
	'vwr/get/list/v2',
	async (args, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
			projectInfo: { selectedProjectId },
		} = thunkAPI.getState();

		try {
			const { data } = await getVWRListApi({
				tenantId: tenantId as string,
				projectId: selectedProjectId,
				organizationId,
			});

			return { vwrList: data.slice() };

			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { closeVWRModal } from 'common/context/modalSlice';

export default function VWRDeleteCompleteModal() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isDeleteCompleteModalVisible } = useSelector((state: RootState) => state.modal.vwr);

	const primaryButtonClickHandler = () => {
		dispatch(closeVWRModal());
	};

	return (
		<Portal>
			<Modal
				visible={isDeleteCompleteModalVisible}
				actionType="single"
				primaryBtnLabel={t('vwr.confirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				isCloseIconVisible={false}
			>
				<div css={bodySt}>{t('vwr.deleteComplete')}</div>
			</Modal>
		</Portal>
	);
}

const bodySt = css`
	text-align: center;
`;

import { ONPREM_ASSETS_PATH } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export const defaultApplyCodeBlock = (
	tenantId: string | null,
	appName: string,
	api: string,
	server: string,
	file: string,
	eum: string
) => ({
	kotlin: `fun Init() {
   Netfunnel.InitNetfunnel(
       "MyAppName",    // MyAppName: ${appName}
       "${getOnPremBaseUrl()}",    // API Server URL: ${api}
       "${getOnPremBaseUrl()}",    // NetFUNNEL Server URL: ${server}
       "${getOnPremBaseUrl(
					ONPREM_ASSETS_PATH
				)}/nf-setting/${tenantId}/nf-setting.json",    // >NetFUNNEL Setting File URL: ${file}
       this
   )

    Netfunnel.InitEum(
        "${getOnPremBaseUrl()}",    // EUM Server URL: ${eum}
        this
  )
}`,
	java: `public void Init() {
    Netfunnel.InitNetfunnel(
        "MyAppName",    // MyAppName: ${appName}
        "${getOnPremBaseUrl()}",    // API Server URL: ${api}
        "${getOnPremBaseUrl()}",    // NetFUNNEL Server URL: ${server}
        "${getOnPremBaseUrl(
					ONPREM_ASSETS_PATH
				)}/nf-setting/${tenantId}/nf-setting.json",    // >NetFUNNEL Setting File URL: ${file}
        this
    );

    Netfunnel.InitEum(
        "${getOnPremBaseUrl()}",    // EUM Server URL: ${eum}
        this   
    );
}`,
});

export const agentInterfaceCodeBlock = () => ({
	kotlin: `var myInterface = object : AgentInterface() {
    override fun onSuccess() { 
         // call when user allow access or netfunnel server response error
        object : Thread() {
            override fun run() {
             /*
              * to do what you want to after using netfunnel
              */
            }
        }.start()
    }

    override fun onCancel(_cancelURL: String) {
        // call when user cancel         
        object : Thread() {
            override fun run() {

            }
        }.start()
    }
    
    override fun onFail() {
        /*
         * activity is null, internet not connected
         */
    }

    override fun nfLog(_message: String) {
        /*
         * this function get the process log about library.
         * if you want to collect or check process, use this
         * like DLog.d(_message)
         */
    }
}`,
	java: `AgentInterface myInterface = new AgentInterface() {
    @Override
    public void onSuccess() { // This function is where the operation is implemented after the wait time check is completed and the entry is allowed.
        new Thread() {
            @Override
            public void run() {
             /*
              * to do what you want to after using netfunnel
              */    
            }
        }.start();
    }

    @Override
    public void onCancel(String _cancelURL) {
        if( _cancelURL == "" ) {
            return;
        }
    }

    @Override
    public void onFail() {
        /*
         * activity is null, internet not connected
         */
    }
    @Override
    public void nfLog(String _message) {
        /*
         * this function get the process log about library.
         * if you want to collect or check process, use this
         * like DLog.d(_message)
         */
    }
};`,
});

export const nfStartCodeBlock = () => ({
	kotlin: `fun BasicAction() {
    Netfunnel.NFStart("{project Key}", "{segment Key}", myInterface)
}

fun SectionStartAction() {
    Netfunnel.NFStartSection("{project Key}", "{segment Key}", myInterface)
}`,
	java: `public void BasicAction() {
    Netfunnel.NFStart("{project Key}", "{segment Key}", myInterface);
}

public void SectionStartAction() {
    Netfunnel.NFStartSection("{project Key}", "{segment Key}", myInterface);
}`,
});

export const nfEndCodeBlock = () => ({
	kotlin: `var myInterface = object : AgentInterface() {
    override fun onSuccess() { 
            // call when user allow access or netfunnel server response error
        object : Thread() {
            override fun run() {
                /*
                * to do what you want to after using netfunnel
                */
                
                // basic nf stop
                // Netfunnel.NFStop("{project Key}", "{segment Key}")

                // section nf stop
                // Netfunnel.NFStopSection("{project Key}", "{segment Key}")
            }
        }.start()
    }
}`,
	java: `AgentInterface myInterface = new AgentInterface() {
    @Override
    public void onSuccess() { // This function is where the operation is implemented after the wait time check is completed and the entry is allowed.
        new Thread() {
            @Override
            public void run() {
             /*
              * to do what you want to after using netfunnel
              */    
              
              // basic nf stop
              // Netfunnel.NFStop("{project Key}", "{segment Key}");

              // section nf stop
              // Netfunnel.NFStopSection("{project Key}", "{segment Key}");
            }
        }.start();
    }
};`,
});

export const nfEUMCodeBlock = () => ({
	kotlin: `Netfunnel.EUMBegin("http://example.com")
. . .
. . .
. . .
Netfunnel.EUMEnd("http://example.com")`,
	java: `Netfunnel.EUMBegin("http://example.com");
. . .
. . .
. . .
Netfunnel.EUMEnd("http://example.com");`,
});

export const nfOkHttpCodeBlock = () => ({
	kotlin: `fun networkAction() {
    val client = OkHttpClient.Builder()
        .addInterceptor(EUMInterceptClass())
        .build()
    val request = Request.Builder().url("http://example.com/happy").build()
    try {
        val response = client.newCall(request).execute()
    } catch (e: Exception) {
        e.printStackTrace()
    }
}`,
	java: `public void networkAction() {
    OkHttpClient client = new OkHttpClient.Builder()
        .addInterceptor(new EUMInterceptClass())
        .build();
    Request request = new Request.Builder().url("http://example.com/happy").build();
    try {
        Response response = client.newCall(request).execute();
    } catch (IOException e) {
        e.printStackTrace();
    }
}`,
});

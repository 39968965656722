import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import type { MemberData } from '../types/getMemberList.type';
import getMemberListApi from '../api/getMemberList.api';

export interface GetAllMemberListThunkResult {
	members: MemberData[];
}

export const getAllMemberListThunk = createAsyncThunk<GetAllMemberListThunkResult, void, { state: RootState }>(
	'memberList/get/all',
	async (args, thunkAPI) => {
		const {
			account: { organizationId },
		} = thunkAPI.getState();

		try {
			const {
				data: { content },
			} = await getMemberListApi({
				organizationId,
			});

			return { members: content };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

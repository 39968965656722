/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import DividerWithTxt from 'common/components/DividerWithTxt';
import { RootState, useAppDispatch } from 'common/redux/store';

import { setInitialTimestamp, setInitialUsageData, setSelectedProjectId } from 'features/plan/context/usageSlice';

import SaasUsageSummary from 'features/plan/components/SaasUsageSummary';
import SaasOverallUsage from 'features/plan/components/SaasOverallUsage';
import SaasUsageHeader from 'features/plan/components/SaasUsageHeader';
import { getProjectListThunk } from 'features/nfSetting/thunk/getProjectList.v2.thunk';

export default function Usage() {
	const dispatch = useAppDispatch();

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	useLayoutEffect(() => {
		if (selectedProjectId === 0) {
			dispatch(getProjectListThunk({}));
		} else {
			dispatch(setSelectedProjectId(selectedProjectId));
			dispatch(setInitialTimestamp(moment().valueOf()));
		}

		return () => {
			dispatch(setInitialUsageData());
		};
	}, [dispatch, selectedProjectId]);

	if (selectedProjectId === 0) {
		return <div />;
	}

	return (
		<div css={container}>
			<SaasUsageHeader />
			<SaasUsageSummary />
			<DividerWithTxt />
			<SaasOverallUsage />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

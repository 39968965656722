/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';

export default function ForgotPWLink() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div css={linkWrapSt}>
			<Link
				onClick={() => {
					navigate(`/${getI18nPath()}/signin/find_password`);
					window.location.reload();
				}}
			>
				{t('signIn.forgotYourPwd')}
			</Link>
		</div>
	);
}

const linkWrapSt = css`
	display: flex;
	justify-content: center;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from 'common/redux/store';

import { Button, paletteSDS, themeSDS, CorporateIcon } from 'surf-design-system';

import { toggleIsOrganizationNameModalVisible } from 'common/context/modalSlice';

import OrganizationNameModal from 'features/setting/components/OrganizationNameModal';

export default function Organization() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { organizationName, userRole } = useSelector((state: RootState) => state.account);
	const { isOrganizationNameModalVisible } = useSelector((state: RootState) => state.modal);

	const editButtonClickHandler = () => {
		dispatch(toggleIsOrganizationNameModalVisible());
	};

	return (
		<React.Fragment>
			<div css={organizationCss}>
				<div css={wrapperCss}>
					<h3 css={titleCss}>{t('personalSettings.org')}</h3>
					<div css={iconWithNameCss}>
						<CorporateIcon />
						<div css={nameCss}>{organizationName}</div>
					</div>
					{userRole === 'OWNER' && (
						<Button mould="secondary" onClick={editButtonClickHandler}>
							{t('organizationSettings.editOrgName')}
						</Button>
					)}
				</div>
			</div>
			{isOrganizationNameModalVisible ? <OrganizationNameModal /> : null}
		</React.Fragment>
	);
}

const organizationCss = css`
	display: flex;
	flex-direction: column;
`;

const titleCss = css`
	margin: 0;
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 128%;
	letter-spacing: -0.008em;
`;

const wrapperCss = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const iconWithNameCss = css`
	display: grid;
	grid-template-columns: 1.25rem auto;
	grid-column-gap: 0.625rem;
`;

const nameCss = css`
	color: ${paletteSDS.black};
	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.medium};
`;

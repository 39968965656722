/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Textinput, validationSDS } from 'surf-design-system';

import MainDomainDescription from 'common/components/MainDomainDescription';

export interface CustomerDomainInputProps {
	customerDomainChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
	customerDomainBlurHandler: () => void;
	customerDomain: string;
	isCustomerDomainChanged: boolean;
	isCustomerDomainValid: boolean;
	customerDomainValidation: validationSDS;
	customerDomainHelpText: string;
}

export default function CustomerDomainInput({
	customerDomainBlurHandler,
	customerDomainChangeHandler,
	customerDomain,
	isCustomerDomainChanged,
	isCustomerDomainValid,
	customerDomainValidation,
	customerDomainHelpText,
}: CustomerDomainInputProps) {
	const { t } = useTranslation();

	return (
		<div css={descContainer}>
			<MainDomainDescription gap={16} />
			<Textinput
				label={t('AddDomain.domain')}
				width="100%"
				value={customerDomain}
				onChange={customerDomainChangeHandler}
				onBlur={customerDomainBlurHandler}
				validation={isCustomerDomainChanged ? customerDomainValidation : 'none'}
				helpText={isCustomerDomainChanged && !isCustomerDomainValid && t(customerDomainHelpText)}
				showIcon
				maxLength={253}
			/>
		</div>
	);
}

const descContainer = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

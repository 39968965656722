import { createAsyncThunk } from '@reduxjs/toolkit';

import getOrganizationDataApi from 'common/api/getOrganizationDetail.api';
import getOrganizationListApi from 'common/api/getOrganizationList.api';

import { NETFUNNEL, DATASURFER } from 'common/data/constants';

export const getOrganizationDetailThunk = createAsyncThunk('organizationDetail/get', async (_, thunkAPI) => {
	try {
		const { data: organizationList } = await getOrganizationListApi();
		const organizationData = organizationList[0];
		const { id: organizationId, name: organizationName, status: userStatus, role: userRole } = organizationData;

		const isNetFUNNEL = organizationList.every(item => item.role[0].productType === NETFUNNEL);
		const isDataSurfer = organizationList.every(item => item.role[0].productType === DATASURFER);

		const { data: organizationDetail } = await getOrganizationDataApi(organizationId);

		const {
			defaultCustomerDomain: customerDomain,
			domainUrl: tenantUrl,
			tenantId,
			region,
			freeTrialYn: isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			status: tenantStatus,
			port: tenantPort,
			reason,
			code,
		} = organizationDetail;

		return {
			organizationId,
			organizationName,
			userStatus,
			userRole: userRole[0].role,
			isNetFUNNEL,
			isDataSurfer,
			customerDomain,
			tenantUrl,
			tenantId,
			region,
			isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			tenantStatus,
			tenantPort,
			organizationList,
			reason,
			code,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (error.response?.data) {
			return thunkAPI.rejectWithValue(error.response.data);
		}

		return thunkAPI.rejectWithValue(error.message);
	}
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { postMemberListThunk } from 'features/setting/thunk/postMemberList.thunk';
import { getAllMemberListThunk } from 'features/setting/thunk/getAllMemberList.thunk';

import type { MemberData } from '../types/getMemberList.type';

import { getMemberListThunk } from '../thunk/getMemberList.thunk';
import { putMemberPermissionThunk } from '../thunk/putMemberPermission.thunk';
// todo: 성공 시 멤버리스트 갱신
// import { postMemberListThunk } from '../thunk/postMemberList.thunk';

interface MemberSliceData {
	searchValue?: string;
	memberStatus: string;
	members: MemberData[];
	allMembers: MemberData[];
	isMembersLoaded: boolean;
	isLoading: boolean;
	// eslint-disable-next-line
	error: any;
}

const initialState: MemberSliceData = {
	searchValue: undefined,
	memberStatus: 'all',
	members: [],
	allMembers: [],
	isMembersLoaded: false,
	error: null,
	isLoading: false,
};

const memberSlice = createSlice({
	name: 'member',
	initialState,
	reducers: {
		setSearchValue: (state, action: PayloadAction<string>) => {
			return { ...state, searchValue: action.payload };
		},
		resetSearchValue: state => {
			return { ...state, searchValue: undefined };
		},
		setMemberStatus: (state, action: PayloadAction<string>) => {
			return { ...state, memberStatus: action.payload };
		},
	},
	extraReducers(builder) {
		builder.addCase(getMemberListThunk.fulfilled, (state, action) => {
			return {
				...state,
				members: action.payload.members.slice(),
				isMembersLoaded: true,
				error: null,
				allMembers:
					action.payload.memberStatus === 'all' &&
					(action.payload.searchValue === undefined || action.payload.searchValue === '')
						? action.payload.members.slice()
						: state.allMembers.slice(),
			};
		});

		builder.addCase(getMemberListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(getAllMemberListThunk.fulfilled, (state, action) => {
			return {
				...state,
				members: action.payload.members.slice(),
				isMembersLoaded: true,
				error: null,
				allMembers: action.payload.members.slice(),
				memberStatus: 'all',
				searchValue: undefined,
			};
		});

		builder.addCase(getAllMemberListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(putMemberPermissionThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putMemberPermissionThunk.fulfilled, (state, action) => {
			const newMembers = state.allMembers.map(member => {
				if (member.key === action.payload.key) {
					return { ...action.payload };
				}
				return member;
			});

			return {
				...state,
				isLoading: false,
				members:
					state.memberStatus === 'all'
						? newMembers
						: newMembers.filter(newMember => newMember.state === state.memberStatus),
				allMembers: newMembers,
			};
		});

		builder.addCase(putMemberPermissionThunk.rejected, state => {
			return { ...state, isLoading: false };
		});

		builder.addCase(postMemberListThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		// todo: response 로 멤버 리스트 갱신
		builder.addCase(postMemberListThunk.fulfilled, state => {
			return { ...state, isLoading: false, error: null };
		});

		builder.addCase(postMemberListThunk.rejected, state => {
			return { ...state, isLoading: false };
		});
	},
});

export const { setMemberStatus, setSearchValue, resetSearchValue } = memberSlice.actions;

export default memberSlice.reducer;

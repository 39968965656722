import { useMemo } from 'react';

import type { ApexOptions } from 'apexcharts';

import { paletteSDS } from 'surf-design-system';

import { convertNumToKMB, barColumnWidth, usageChartCategories } from '../utils/usageChartFunc';

import { period } from '../api/getProjectUsageDetail.api';

/**
 * author: ian
 * createdAt: 2022-09-19
 * updatedAt: 2022-09-19
 * @returns: chart option
 */

export interface OptionsParameter {
	maxY: number;
	chartType: period;
	date: number;
	isTooltipTotalVisible?: boolean;
}

export interface CustomTooltipProps {
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			seriesNames: string;
			colors: string;
		};
	};
	series: Array<(number | null)[]>;
}

export default function useUsageStackChartOptions({
	maxY,
	chartType,
	date,
	isTooltipTotalVisible = true,
}: OptionsParameter) {
	const options: ApexOptions = useMemo(
		() => ({
			chart: {
				id: 'realtime',
				type: 'line',
				stacked: true,
				animations: {
					enabled: true,
					easing: 'easeinout',
					dynamicAnimation: {
						speed: 500,
					},
				},
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			xaxis: {
				type: 'category',
				axisTicks: { show: false },
				axisBorder: { show: false },
				categories: usageChartCategories(chartType, date),
				labels: {
					show: true,
					rotate: 0,
					trim: true,
					formatter: value => {
						if (chartType === 'daily') {
							return value;
						}

						if (chartType === 'monthly') {
							if (Number(value) === 32) {
								return 'Jan';
							}
							if (Number(value) === 33) {
								return 'Feb';
							}
							if (Number(value) === 34) {
								return 'Mar';
							}
							if (Number(value) === 35) {
								return 'Apr';
							}
							if (Number(value) === 36) {
								return 'May';
							}
							if (Number(value) === 37) {
								return 'Jun';
							}
							if (Number(value) === 38) {
								return 'Jul';
							}
							if (Number(value) === 39) {
								return 'Aug';
							}
							if (Number(value) === 40) {
								return 'Sep';
							}
							if (Number(value) === 41) {
								return 'Oct';
							}
							if (Number(value) === 42) {
								return 'Nov';
							}
							return 'Dec';
						}

						return value || '';
					},
					style: {
						colors: `${paletteSDS.gray[600]}`,
						fontWeight: '400',
						fontSize: '10',
					},
				},
				tooltip: { enabled: false },
				tickPlacement: 'center',
			},
			yaxis: {
				forceNiceScale: true,
				labels: {
					style: {
						colors: `${paletteSDS.gray[600]}`,
						fontWeight: '400',
						fontSize: '10',
					},
					offsetX: -15,
					minWidth: 30,
					formatter: v => {
						return convertNumToKMB(v);
					},
				},
				max: maxY,
				min: 0,
			},
			grid: {
				show: true,
				borderColor: `${paletteSDS.gray[100]}`,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
				padding: {
					right: 20,
					left: -10,
				},
			},
			tooltip: {
				shared: false,
				followCursor: true,
				custom({ series, seriesIndex, dataPointIndex, w }: CustomTooltipProps) {
					const {
						globals: { colors },
					} = w;

					const tooltip = `
        <div class="container">
          <div class="curr">
            <div class="color" style="background-color:${colors[seriesIndex]};"></div>
            <div class="value">${convertNumToKMB(series[seriesIndex][dataPointIndex] || 0)}</div>
          </div>
          ${
						isTooltipTotalVisible
							? `<div class="total">
								<div class="total_label">Total</div>
								<div class="value">${convertNumToKMB((series[0][dataPointIndex] || 0) + (series[1][dataPointIndex] || 0) || 0)}</div>
							</div>`
							: ``
					}
        </div>
        `;
					return tooltip;
				},
			},
			legend: {
				show: false,
			},
			plotOptions: {
				bar: {
					columnWidth: barColumnWidth(chartType),
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				active: {
					filter: {
						type: 'none',
					},
				},
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxY, chartType, date, isTooltipTotalVisible]
	);

	return {
		options,
	};
}

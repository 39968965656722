/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Modal, Portal, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsInitialConsoleAccessModalVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';

export default function PlanSubscriptionReminderModal() {
	const { t } = useTranslation('home');
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { isPlanSubscriptionReminderModalVisible } = useSelector((state: RootState) => state.modal);
	const { userRole, language } = useSelector((state: RootState) => state.account);

	const upgradePlanClickHandler = () => {
		dispatch(toggleIsInitialConsoleAccessModalVisible());
		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan/choose_plan`);
	};

	const closeClickHandler = () => {
		dispatch(toggleIsInitialConsoleAccessModalVisible());
	};

	return (
		<Portal>
			<Modal
				visible={isPlanSubscriptionReminderModalVisible}
				title={t('planSubscriptionReminderModal.title', { days: '3' })}
				actionType="single"
				primaryBtnLabel={t(
					userRole !== 'OWNER' ? 'planSubscriptionReminderModal.ok' : 'planSubscriptionReminderModal.upgradePlan'
				)}
				primaryBtnFunction={userRole !== 'OWNER' ? closeClickHandler : upgradePlanClickHandler}
				isCloseIconVisible={userRole !== 'OWNER'}
				onClickCloseButton={closeClickHandler}
			>
				<div css={textSt}>
					{t(
						userRole !== 'OWNER'
							? 'planSubscriptionReminderModal.memberBody'
							: 'planSubscriptionReminderModal.ownerBody',
						{ days: 3 }
					)}
				</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	line-height: 160%;
	font-size: ${themeSDS.fontSize[14]};

	white-space: pre-line;
`;

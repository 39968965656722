/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState, useAppDispatch } from 'common/redux/store';
import { ProjectInformation } from 'features/nfSetting/types/getProjectList.v2.type';
import {
	setInitialToast,
	setIsProjectInactiveToastVisible,
	toggleIsWindowRefreshedToastVisible,
} from 'common/context/toastSlice';
import BasicControlOutflowRateChart from 'features/nfControl/components/BasicControlOutflowRateChart';
import BasicControlProcessTimeChart from 'features/nfControl/components/BasicControlProcessTimeChart';
import BasicControlTotalEntryRequestChart from 'features/nfControl/components/BasicControlTotalEntryRequestChart';
import ProjectAccessMode from 'features/nfControl/components/ProjectAccessMode';
import { FETCH_INTERVAL, IS_RELOAD_KEY, IS_RELOAD_TRUE } from 'features/nfBasic/data/constants';
import BasicControlProjectClassic from 'features/nfControl/components/BasicControlProjectClassic';
import BasicControlSegmentBoardClassic from 'features/nfControl/components/BasicControlSegmentBoardClassic';
import getBasicControlSegmentListThunk from 'features/nfControl/thunk/getBasicControlSegmentList.v2.thunk';
import { cleanupBasicControlSegmentRawData, setInitialNfControlData } from 'features/nfControl/context/nfControlSlice';
import postSegmentStatDataInitialThunk from 'features/nfControl/thunk/postSegmentStatDataInitial.v2.thunk';
import postProjectStatDataThunk from 'features/nfControl/thunk/postProjectStatData.v2.thunk';
import postSegmentStatDataUpdateThunk from 'features/nfControl/thunk/postSegmentStatDataUpdate.v2.thunk';
import ProjectSummaryClock from 'features/nfControl/components/ProjectSummaryClock';

let fetchingInterval: NodeJS.Timer | undefined;
let cleanupInterval: NodeJS.Timer | undefined;

export default function NetfunnelBasicControlClassicView() {
	const dispatch = useAppDispatch();

	const activatedYn = useSelector(
		(state: RootState) =>
			(state.projectInfo.projectList.find(el => el.id === state.projectInfo.selectedProjectId) as ProjectInformation)
				.activatedYn,
		shallowEqual
	);

	const { isMetaLoaded, isValueLoaded } = useSelector((state: RootState) => state.nfControl.segment.basic);

	useEffect(() => {
		if (!isMetaLoaded) {
			dispatch(getBasicControlSegmentListThunk());
			return undefined;
		}

		if (isMetaLoaded) {
			if (sessionStorage.getItem(IS_RELOAD_KEY) === IS_RELOAD_TRUE) {
				dispatch(toggleIsWindowRefreshedToastVisible());
				sessionStorage.removeItem(IS_RELOAD_KEY);
			}

			(async () => {
				await dispatch(postSegmentStatDataInitialThunk({ isPathControl: false }));
				await dispatch(postProjectStatDataThunk({ isPathControl: false }));
			})();

			fetchingInterval = setInterval(() => {
				dispatch(postProjectStatDataThunk({ isPathControl: false }));
				dispatch(postSegmentStatDataUpdateThunk({ isPathControl: false }));
			}, FETCH_INTERVAL);

			return () => clearInterval(fetchingInterval);
		}

		return undefined;
	}, [dispatch, isMetaLoaded]);

	useEffect(() => {
		if (isValueLoaded) {
			cleanupInterval = setInterval(() => {
				dispatch(cleanupBasicControlSegmentRawData());
			}, 120000);

			return () => clearInterval(cleanupInterval);
		}

		return undefined;
	}, [dispatch, isValueLoaded]);

	useEffect(() => {
		return () => {
			dispatch(setInitialNfControlData());
			dispatch(setInitialToast());
			clearInterval(fetchingInterval);
			clearInterval(cleanupInterval);
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(setIsProjectInactiveToastVisible(!activatedYn));
	}, [dispatch, activatedYn]);

	if (!isMetaLoaded) return <div />;

	return (
		<div css={containerCss}>
			<div css={leftContainerCss}>
				<ProjectAccessMode />
				<BasicControlProjectClassic />
				<ProjectSummaryClock />
			</div>
			<div css={rightContainerCss}>
				<div css={upperContainerCss}>
					<BasicControlSegmentBoardClassic />
				</div>
				<div css={lowerContainerCss}>
					<div css={chartScrollSt}>
						<div css={chartContainerCss}>
							<BasicControlTotalEntryRequestChart isClassic />
							<BasicControlProcessTimeChart isClassic />
							<BasicControlOutflowRateChart isClassic />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const containerCss = css`
	display: flex;
	flex-direction: row;
	gap: 16px;
	width: 100%;
	height: 100%;
`;

const leftContainerCss = css`
	display: flex;
	flex-direction: column;
	width: 265px;
	gap: 8px;
`;

const rightContainerCss = css`
	display: flex;
	flex-direction: column;
	width: calc(100% - 273px);
	gap: 16px;
`;

const upperContainerCss = css`
	width: 100%;
`;

const lowerContainerCss = css`
	width: 100%;
`;

const chartScrollSt = css`
	width: 100%;
	height: 330px;

	overflow-x: auto;
	overflow-y: hidden;
`;

const chartContainerCss = css`
	display: flex;
	gap: 8px;

	width: 100%;
	min-width: 1516px;
	height: 100%;
`;

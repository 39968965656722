/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { createRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { paletteSDS, NotificationsIcon, HelpIcon } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Avatar from 'common/components/Avatar';
import ColorCircle from 'common/components/ColorCircle';
import SurffyProductTitle from 'common/components/SurffyProductTitle';
import { toggleHeaderNotiOpen } from 'common/context/notificationSlice';
import SaasHeaderNotification from 'common/components/SaasHeaderNotification';
import SaasProfileMenu from 'common/components/SaasProfileMenu';
import { SurffyLogoDark } from 'common/assets/icons';
import {
	toggleIsHelpMenuVisible,
	toggleIsProfileMenuVisible,
	toggleIsProjectMenuVisible,
} from 'common/context/layoutSlice';
import { DEFAULT_IMG_URL, LANG_PATH } from 'common/data/constants';
import useOutboundClick from 'common/hooks/useOutboundClick';
import SaasHelpMenu from 'common/components/SaasHelpMenu';
import SaasGNBNetFunnelHomeTab from 'common/components/SaasGNBNetFunnelHomeTab';
import SaasGNBNetFunnelProjectTab from 'common/components/SaasGNBNetFunnelProjectTab';
import SaasProjectMenu from 'common/components/SaasProjectMenu';

export default function SaasGNB() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const { language, userEmail, imageUrl } = useSelector((state: RootState) => state.account);

	const { isHelpMenuVisible } = useSelector((state: RootState) => state.layout);

	const { isAllRead, isHeaderNotiOpened } = useSelector((state: RootState) => state.noti);

	const notificationsIconRef = createRef<SVGSVGElement>();
	const helpIconRef = createRef<SVGSVGElement>();
	const avatarRef = createRef<HTMLImageElement>();
	const headerNotificationRef = createRef<HTMLDivElement>();
	const profileMenuRef = createRef<HTMLDivElement>();
	const helpMenuRef = createRef<HTMLDivElement>();
	const projectTabRef = createRef<HTMLDivElement>();
	const projectMenuRef = createRef<HTMLDivElement>();

	const surffyLogoClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home`);
	};

	const helpIconClickHandler = () => {
		dispatch(toggleIsHelpMenuVisible());
	};

	const notificationsIconClickHandler = () => {
		dispatch(toggleHeaderNotiOpen());
	};

	const avatarClickHandler = () => {
		dispatch(toggleIsProfileMenuVisible());
	};

	const projectTabClickHandler = () => {
		dispatch(toggleIsProjectMenuVisible());
	};

	useOutboundClick(headerNotificationRef, notificationsIconClickHandler, [notificationsIconRef]);

	useOutboundClick(profileMenuRef, avatarClickHandler, [avatarRef]);

	useOutboundClick(helpMenuRef, helpIconClickHandler, [helpIconRef]);

	useOutboundClick(projectMenuRef, projectTabClickHandler, [projectTabRef]);

	return (
		<React.Fragment>
			<div css={container}>
				<div css={leftBox}>
					<div css={surffyIconBox}>
						<SurffyLogoDark onClick={surffyLogoClickHandler} css={logoSt} />
						<SurffyProductTitle />
					</div>
					{pathname.includes('/product/nf') ? (
						<div css={tabBox}>
							<SaasGNBNetFunnelHomeTab />
							<SaasGNBNetFunnelProjectTab ref={projectTabRef} />
						</div>
					) : null}
				</div>
				<div css={rightBox}>
					<div css={iconBox(isHeaderNotiOpened)}>
						<NotificationsIcon
							fill={!isAllRead ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
							onClick={notificationsIconClickHandler}
							ref={notificationsIconRef}
						/>
						{!isAllRead ? <ColorCircle emotionCss={colorCircleCss} /> : null}
					</div>
					<div css={iconBox(isHelpMenuVisible)}>
						<HelpIcon fill={paletteSDS.blue['700']} onClick={helpIconClickHandler} ref={helpIconRef} />
					</div>
					<div css={avatarBox}>
						<Avatar
							imgUrl={imageUrl === DEFAULT_IMG_URL ? undefined : imageUrl}
							placeholder={userEmail.charAt(0).toUpperCase()}
							onClick={avatarClickHandler}
							emotionCss={css`
								cursor: pointer;
							`}
							ref={avatarRef}
						/>
					</div>
				</div>
			</div>
			<SaasHeaderNotification ref={headerNotificationRef} />
			<SaasProfileMenu ref={profileMenuRef} />
			<SaasHelpMenu ref={helpMenuRef} />
			<SaasProjectMenu ref={projectMenuRef} />
		</React.Fragment>
	);
}

const container = css`
	position: relative;

	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 64px;

	background: ${paletteSDS.gray['50']};

	border-bottom: 1px solid ${paletteSDS.gray['300']};

	overflow: unset;
`;

const surffyIconBox = css`
	display: flex;
	align-items: center;

	gap: 8px;

	width: 244px;
	height: 40px;

	padding: 8px 24px;
`;

const iconBox = (isActivated: boolean) => css`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: 40px;

	border-radius: 50%;

	background: ${isActivated && paletteSDS.blue['100']};

	:hover {
		background: ${paletteSDS.blue['100']};
	}
`;

const avatarBox = css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 64px;
`;

const rightBox = css`
	display: flex;
	align-items: center;
	gap: 0;
`;

const logoSt = css`
	cursor: pointer;
`;

const colorCircleCss = css`
	position: absolute;
	top: 10px;
	left: 20px;
`;

const leftBox = css`
	display: flex;
	align-items: center;
	gap: 0;

	overflow: auto;
`;

const tabBox = css`
	display: flex;
	align-items: center;
	gap: 0;
`;

import { useMemo } from 'react';
import type { ApexOptions } from 'apexcharts';
import { paletteSDS } from 'surf-design-system';
import { formatToPercent, formatLocaleString } from 'features/nfEUM/utils/chartFunc';
import { dountChartColor } from 'features/nfEUM/data/constants';

const { gray } = paletteSDS;

export interface CustomTooltipProps {
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			seriesNames: string;
			colors: string;
			initialSeries: number[];
		};
	};
	series: number[];
}

export interface Annotation {
	label: string;
	data: number;
}

type ChartType = 'noData' | 'browser' | 'os' | 'app';

interface ChartOptionProps {
	chartType: ChartType;
	seriesData: number[];
	labelsData: string[];
	annotation: Annotation;
}

export default function useTXNDonutChartOptions({ chartType, seriesData, labelsData, annotation }: ChartOptionProps) {
	const options: ApexOptions = useMemo(
		() => ({
			chart: {
				id: 'donut',
				height: '218px',
				zoom: {
					enabled: false,
				},
				animations: {
					enabled: false,
				},
			},
			colors: dountChartColor[chartType as keyof typeof dountChartColor],
			series: seriesData,
			labels: labelsData,
			dataLabels: {
				enabled: false,
			},
			stroke: { width: 0 },
			states: { hover: { filter: { type: 'none' } }, active: { filter: { type: 'none' } } },
			plotOptions: {
				pie: {
					customScale: 1,
					offsetX: 0,
					offsetY: 15,
					expandOnClick: true,
					donut: {
						labels: {
							show: true,
							name: {
								show: true,
								color: gray[500],
								fontSize: '14px',
								fontWeight: 400,
								offsetY: -4,
							},
							value: {
								show: true,
								color: gray[900],
								fontSize: '16px',
								fontWeight: 600,
								offsetY: 4,
								formatter: val => {
									const value = formatToPercent(seriesData, Number(val));
									return value === 0 ? '' : `${value}%`;
								},
							},
							total: {
								show: true,
								label: annotation.label,
								color: gray[500],
								fontSize: '14px',
								fontWeight: 400,
								formatter() {
									const value = formatToPercent(seriesData, annotation.data);
									return value === 0 ? '' : `${value}%`;
								},
							},
						},
					},
				},
			},
			legend: {
				show: false,
				position: 'right',
				horizontalAlign: 'center',
				fontSize: '14px',
				markers: {
					width: 7.5,
					height: 7.5,
				},
				formatter(legendName, opts) {
					const label = `
					<span style="
					display: inline-block;
					max-width: 128px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: ${gray[700]};
				  font-weight: 400;
					line-height: 100%" >
					${legendName}</span>
					<span style="
					color: ${gray[700]};
					font-weight: 600;">
					${legendName === 'No Data' ? '' : formatLocaleString(opts.w.globals.series[opts.seriesIndex])}
					</span>`;
					return label;
				},
			},
			tooltip: {
				enabled: true,
				followCursor: false,
				onDatasetHover: {
					highlightDataSeries: false,
				},
				custom({ seriesIndex, w }: CustomTooltipProps) {
					const tooltip = `${
						w.globals.seriesNames[seriesIndex] === 'No Data'
							? ''
							: `
						<div class="box" style="
						box-sizing: border-box;
						padding: 4px 8px; 
						background-color: #fff;
						color: ${gray[900]};
						line-height: 160%;
						font-weight: 400;
						font-size: 12px;">
							<div class="legend">
								<div class="dot" style="
								display: inline-block;
								margin-right: 4px;
								border-radius: 50%;
								width: 7.5px;
								height: 7.5px;
								background-color: ${w.globals.colors[seriesIndex]}
								"></div>
								<span class="data" style="
								font-size: 12px;
								font-weight: 400; ">
								${w.globals.seriesNames[seriesIndex]}: 
								${formatLocaleString(w.globals.initialSeries[seriesIndex])}(${formatToPercent(
									w.globals.initialSeries,
									w.globals.initialSeries[seriesIndex]
							  )}%)
								</span>
							</div>
						</div>
					`
					}`;
					return tooltip;
				},
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[chartType, seriesData]
	);

	return {
		apexChartOption: options,
	};
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import Table, { TableColumn, TableRow } from 'common/components/Table';
import { RootState, useAppDispatch } from 'common/redux/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { paletteSDS } from 'surf-design-system';
import { setMetricsSort } from 'features/nfEUM/context/eumTXNMetricsSlice';
import { getTXNMetricsThunk } from 'features/nfEUM/thunk/getTXNMetrics.thunk';
import BackgroundBar from 'features/nfEUM/components/BackgroundBar';
import MetricsSortIcon from 'features/nfEUM/components/MetricsSortIcon';

export default function TXNMetricTable() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { filteredMetricsData, loadSum, loadTimeAvg, maxLoad, maxLoadTime } = useSelector(
		(state: RootState) => state.eumTXNMetrics
	);

	const columns: TableColumn[] = useMemo(
		() => [
			{
				id: 0,
				label: (
					<div css={th}>
						<span>Host</span>
						<MetricsSortIcon
							column="host"
							onClick={() => {
								dispatch(setMetricsSort('host'));
								dispatch(getTXNMetricsThunk());
							}}
						/>
					</div>
				),
				width: '239px',
				maxWidth: '239px',
				minWidth: '239px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
			{
				id: 1,
				label: (
					<div css={th}>
						<span>Path</span>
						<MetricsSortIcon
							column="uri"
							onClick={() => {
								dispatch(setMetricsSort('uri'));
								dispatch(getTXNMetricsThunk());
							}}
						/>
					</div>
				),
				width: '239px',
				maxWidth: '239px',
				minWidth: '239px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
			{
				id: 2,
				label: (
					<div css={th}>
						<span>{t('eum.load')}</span>
						<MetricsSortIcon
							column="load"
							onClick={() => {
								dispatch(setMetricsSort('load'));
								dispatch(getTXNMetricsThunk());
							}}
						/>
					</div>
				),
				width: '120px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
			{
				id: 3,
				label: (
					<div css={th}>
						<span>{t('eum.loadTime')}(ms)</span>
						<MetricsSortIcon
							column="loadTime"
							onClick={() => {
								dispatch(setMetricsSort('loadTime'));
								dispatch(getTXNMetricsThunk());
							}}
						/>
					</div>
				),
				width: '150px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
				fontSize: '14px',
			},
			{
				id: 4,
				label: (
					<div css={th}>
						<span>{t('eum.category')}</span>
						<MetricsSortIcon
							column="category"
							onClick={() => {
								dispatch(setMetricsSort('category'));
								dispatch(getTXNMetricsThunk());
							}}
						/>
					</div>
				),
				width: '120px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
		],
		[dispatch, t]
	);

	const rows: TableRow[] = filteredMetricsData.map((el, i) => ({
		id: el.host + el.uri + i,
		items: [
			{
				elementId: `host-${el.host}`,
				element: el.host,
				width: '239px',
				maxWidth: '239px',
				minWidth: '239px',
				height: '40px',
				padding: '13px 16px',
				backgroundColor: paletteSDS.white,
				isEllipsis: true,
				showTextBox: el.host.length > 18,
				textBoxMaxWidth: '550px',
			},
			{
				elementId: `url-${el.uri}`,
				element: el.uri,
				width: '239px',
				maxWidth: '239px',
				minWidth: '239px',
				height: '40px',
				padding: '13px 16px',
				backgroundColor: paletteSDS.white,
				isEllipsis: true,
				showTextBox: el.uri.length > 18,
				textBoxMaxWidth: '550px',
			},
			{
				elementId: `load-${el.count}`,
				element: <BackgroundBar data={el.count} bgWidth={Number((el.count / maxLoad).toFixed(2)) * 100} />,
				width: '120px',
				maxWidth: '130px',
				minWidth: '130px',
				height: '40px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.white,
			},
			{
				elementId: `loadTime-${el.pt}`,
				element: <BackgroundBar data={el.pt.toFixed(0)} bgWidth={Number((el.pt / maxLoadTime).toFixed(2)) * 100} />,
				width: '150px',
				maxWidth: '130px',
				minWidth: '130px',
				height: '40px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.white,
			},
			{
				elementId: `category-${el.category}`,
				element: el.category,
				width: '120px',
				maxWidth: '130px',
				minWidth: '130px',
				height: '40px',
				padding: '13px 16px',
				backgroundColor: paletteSDS.white,
			},
		],
	}));

	const footer: TableColumn[] = useMemo(
		() => [
			{
				id: 0,
				label: t('eum.summary'),
				width: '478px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
			{
				id: 1,
				label: loadSum === 0 ? '-' : loadSum,
				width: '120px',
				maxWidth: '130px',
				minWidth: '130px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
			{
				id: 2,
				label: loadTimeAvg !== '0' ? loadTimeAvg : '-',
				width: '150px',
				maxWidth: '130px',
				minWidth: '130px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
			{
				id: 3,
				label: '',
				width: '120px',
				maxWidth: '130px',
				minWidth: '130px',
				height: '32px',
				padding: '8px 16px',
				backgroundColor: paletteSDS.gray[100],
			},
		],
		[loadSum, loadTimeAvg, t]
	);

	return <Table columns={columns} rows={rows} footer={footer} bodyText={t('eum.noData')} bodyHeight="292px" />;
}

const th = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

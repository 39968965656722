/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Avatar from 'common/components/Avatar';
import { DEFAULT_IMG_URL } from 'common/data/constants';
import { toggleIsProfileEditModalVisible } from 'common/context/modalSlice';

import { getDateFormatLanguage } from 'features/plan/utils/formatFunc';

import ProfileEditModal from 'features/setting/components/ProfileEditModal';

export default function UserProfile() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { userName, userEmail, imageUrl, createdAt, updatedAt, language } = useSelector(
		(state: RootState) => state.account
	);

	const { isProfileEditModalVisible } = useSelector((state: RootState) => state.modal);

	const createdDate = getDateFormatLanguage(createdAt, language);
	const updatedDate = getDateFormatLanguage(updatedAt, language);

	const editButtonClickHandler = () => {
		dispatch(toggleIsProfileEditModalVisible());
	};

	return (
		<div css={profileWrapperCss}>
			<div css={pageTitleSt}>{t('personalSettings.profile')}</div>
			<div css={profileWrapSt}>
				<Avatar
					imgUrl={imageUrl !== DEFAULT_IMG_URL ? imageUrl : undefined}
					size="xLarge"
					border={`1px solid ${paletteSDS.gray[50]}`}
					placeholder={userEmail.charAt(0).toUpperCase()}
				/>
				<div css={detailWrapperCss}>
					<div css={userNameCss}>{userName}</div>
					<div>{userEmail}</div>
					<div css={userInfoSt}>
						<div css={detailTitleCss}>{t('personalSettings.authBy')}</div>
						<div>ID/PW</div>
						<div css={detailTitleCss}>{t('personalSettings.createOn')}</div>
						<div>{createdDate}</div>
						<div css={detailTitleCss}>{t('personalSettings.modifiOn')}</div>
						<div>{updatedDate}</div>
					</div>
					<div>
						<Button mould="secondary" size="small" onClick={editButtonClickHandler}>
							{t('modal.editProfile')}
						</Button>
					</div>
				</div>
			</div>
			{isProfileEditModalVisible ? <ProfileEditModal /> : null}
		</div>
	);
}

const profileWrapperCss = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const profileWrapSt = css`
	display: flex;
	gap: 1.5rem;
`;

const detailWrapperCss = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	color: ${paletteSDS.blue[900]};

	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

const userNameCss = css`
	font-size: 1.25rem;
	font-weight: ${themeSDS.fontWeight.bold};
`;

const userInfoSt = css`
	display: grid;
	grid-template-columns: repeat(2, 9.063rem);
	row-gap: 1rem;
`;

const detailTitleCss = css`
	font-weight: ${themeSDS.fontWeight.bold};
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import VersionTable from 'common/components/VersionTable';

export default function SurffyVersion() {
	return (
		<div css={container}>
			<h1>Surffy Version</h1>
			<div css={tableContainer}>
				<VersionTable />
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 100%;
	min-height: 100%;

	padding: 50px;

	overflow: auto;
`;

const tableContainer = css`
	max-width: 1050px;
	min-width: 730px;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from 'common/redux/store';

import postProjectStatDataApi from 'features/nfBasic/api/postProjectStatData.v2.api';

export interface PostProjectStatDataThunkProps {
	isPathControl: boolean;
}

export interface PostProjectStatDataThunkResult {
	time: number;
	totalTickets: number;
	maxWaitTime: number;
	maxNumbersWaiting: number;
	maxProcessTime: number;
	accessYn: boolean;
	activatedYn: boolean;
	id: number;
}

const postProjectStatDataThunk = createAsyncThunk<
	PostProjectStatDataThunkResult,
	PostProjectStatDataThunkProps,
	{ state: RootState }
>('project/statData/post/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId, timeZone },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { isPathControl } = args;

	try {
		const { data } = await postProjectStatDataApi({
			tenantId: tenantId || '',
			tenantUrl,
			organizationId,
			projectId: selectedProjectId,
			begin: Number(moment().utcOffset(timeZone.slice(3)).startOf('day').format('X')),
			end: Number(moment().format('X')),
			isPathControl,
		});

		const {
			value: { time, totalTickets, maxWaitTime, maxNumbersWaiting, maxProcessTime },
			projectId,
			accessYn,
			activatedYn,
		} = data;

		return {
			time,
			totalTickets,
			maxWaitTime,
			maxNumbersWaiting,
			maxProcessTime,
			id: projectId,
			accessYn,
			activatedYn,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default postProjectStatDataThunk;

/** @jsxRuntime classic */
/** @jsx jsx */

import { forwardRef } from 'react';
import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, paletteSDS, themeSDS, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { putNotificationReadYnListThunk } from 'common/thunk/putNotificationReadYnList.thunk';
import { toggleHeaderNotiOpen } from 'common/context/notificationSlice';
import { LANG_PATH } from 'common/data/constants';
import HeaderNotificationContent from 'common/components/HeaderNotificationContent';

const OnPremHeaderNotification = forwardRef<HTMLDivElement>((_, ref) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { isFreetrialNotiVisible, language } = useSelector((state: RootState) => state.account);
	const { isHeaderNotiOpened, isLoaded, isAllRead, headerNotifications } = useSelector(
		(state: RootState) => state.noti
	);

	/**
	 * description: 알림 전체 리스트 페이지 이동 함수
	 */
	const viewAllClickHandler = () => {
		dispatch(toggleHeaderNotiOpen());

		navigate(`/${LANG_PATH[language]}/on_prem/home/notification`);
	};

	/**
	 * description: 전체 읽음 버튼 클릭 함수
	 */
	const readAllClickHandler = () => {
		dispatch(
			putNotificationReadYnListThunk({
				notificationIds: headerNotifications.map(el => el.id),
			})
		);
	};

	let notificationsContent = null;

	if (isLoaded && headerNotifications.length > 0) {
		notificationsContent = (
			<ul css={listBoxSt}>
				{headerNotifications.map(notification => (
					<HeaderNotificationContent key={notification.id} notification={notification} />
				))}
			</ul>
		);
	}

	if (isLoaded && headerNotifications.length === 0) {
		notificationsContent = <div css={noListSt}>{t('console.headerNoti.alertMsg')}</div>;
	}

	if (!isHeaderNotiOpened) return null;

	return (
		<Portal id="header-noti">
			<div css={alertBoxSt(isFreetrialNotiVisible)} ref={ref}>
				<div css={alertTitleSt}>
					<div>{t('console.headerNoti.notification')}</div>
					<Button size="small" mould="tertiary" onClick={readAllClickHandler} disabled={isAllRead}>
						{t('console.headerNoti.markRead')}
					</Button>
				</div>
				{notificationsContent}
				<div css={alertBottomSt}>
					<Button size="medium" mould="tertiary" onClick={viewAllClickHandler}>
						{t('console.headerNoti.viewAll')}
					</Button>
				</div>
			</div>
		</Portal>
	);
});

export default OnPremHeaderNotification;

const alertBoxSt = (isFreetrialNotiVisible: boolean) => css`
	position: absolute;
	top: ${isFreetrialNotiVisible ? '120px' : '56px'};
	right: 0.5rem;

	width: 20rem;
	height: 28.5rem;
	margin-top: -0.0625rem;

	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	background-color: ${paletteSDS.white};

	z-index: 1000;
`;

const alertTitleSt = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: ${paletteSDS.gray[50]};
	border-bottom: 1px solid ${paletteSDS.gray[300]};
	padding: 1.1875rem 1rem;

	border-top-right-radius: 4px;
	border-top-left-radius: 4px;

	> div:nth-of-type(1) {
		display: flex;
		align-items: center;

		width: 9rem;
		height: 1.625rem;

		font-weight: ${themeSDS.fontWeight.medium};
	}
`;

const listBoxSt = css`
	height: 20.4375rem;
	background-color: rgba(0, 0, 0, 0);
	overflow-x: hidden;
	overflow-y: overlay;

	background-clip: text;
	-webkit-background-clip: text;

	&::-webkit-scrollbar {
		width: 1rem;
	}

	&::-webkit-scrollbar-thumb {
		border: 4px solid transparent;
		border-radius: 8px;
		background-clip: padding-box;
		background-color: rgba(12, 12, 12, 0.48);
	}
`;

const noListSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 21rem;
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
`;

const alertBottomSt = css`
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: 0;

	width: 100%;
	padding: 0.5rem 1rem;

	border-top: 1px solid ${paletteSDS.gray[300]};
	background-color: ${paletteSDS.gray[50]};

	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;

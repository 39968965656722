/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { PLAN_FREE_BETA_ID, PLAN_FREE_TRIAL_ID, PLAN_MOIS_2022_ID } from 'common/data/constants';
import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsIntervalSegmentMaxNumModalVisible } from 'common/context/modalSlice';

import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';

export default function IntervalSegmentMaxNumModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { planId, planOptions, planLangs } = useSelector((state: RootState) => state.plan);

	const { isIntervalSegmentMaxNumModalVisible } = useSelector((state: RootState) => state.modal);

	const { language } = useSelector((state: RootState) => state.account);

	const planLang = planLangs.find(el => el.langType === language) as PlanLangs;

	const primaryButtonClickHandler = () => {
		dispatch(toggleIsIntervalSegmentMaxNumModalVisible());
	};

	let textKey = 'modal.segNumPlan';

	if (planId === PLAN_FREE_TRIAL_ID) {
		textKey = 'modal.segNumFreeTrial';
	}

	if (planId === PLAN_MOIS_2022_ID) {
		textKey = 'mois.intervalMaxNum';
	}

	if (planId === PLAN_FREE_BETA_ID) {
		textKey = 'freeBeta.maxPathControlSegmentNumber';
	}

	return (
		<Portal>
			<Modal
				visible={isIntervalSegmentMaxNumModalVisible}
				actionType="single"
				primaryBtnFunction={primaryButtonClickHandler}
				primaryBtnLabel={t('modal.segNumConfirm')}
				isCloseIconVisible={false}
			>
				<div css={modalTxtSt}>
					{t(textKey, {
						segNum: planOptions[7].value,
						plan: planLang.name,
					})}
				</div>
			</Modal>
		</Portal>
	);
}

const modalTxtSt = css`
	margin-top: 16px;

	text-align: center;
	white-space: break-spaces;
	line-height: 160%;
`;

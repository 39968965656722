/** @jsxRuntime classic */
/** @jsx jsx */

import React, { createRef, useEffect, useReducer } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Textinput } from 'surf-design-system';

import Astrix from 'common/components/Astrix';
import { useAppDispatch, RootState } from 'common/redux/store';

import {
	initialBillingInfoForm,
	UPDATE_VAT,
	UPDATE_REGION,
	UPDATE_CITY,
	UPDATE_POSTAL_CODE,
	UPDATE_COUNTRY,
	UPDATE_STREET_ADDRESS_DETAIL,
	UPDATE_STREET_ADDRESS,
	VALIDATE_POSTAL_CODE,
	VALIDATE_CITY,
	VALIDATE_STREET_ADDRESS,
	VALIDATE_COUNTRY,
	UPDATE_CVC,
	VALIDATE_CVC,
	UPDATE_CARD_NUM,
	UPDATE_EXP_DATE,
	VALIDATE_CARD_NUM,
	VALIDATE_EXP_DATE,
	nonKoreanBillingInfoFormReducer,
} from 'features/plan/context/nonKoreanBillingInfo.reducer';

import BillingInfoPromptModal from 'features/plan/components/BillingInfoPromptModal';
import { cardExpDateFormat, cardNumberFormat } from 'features/plan/utils/formatFunc';
import { postOrganizationPaymentInfoNonKoreanThunk } from 'features/plan/thunk/postOrganizationPaymentInfoNonKorean.thunk';

import { COUNTRY_LIST, MAX_PAYMENT_CHAR_NUM, MAX_PAYMENT_VAT_CHAR_NUM } from '../data/contants';

import PlanTitle from './PlanTitle';

let cvcTimeout: NodeJS.Timeout | undefined;

interface FreebetaNonKoreanBillingInfoFormProps {
	onChangeViewMode: () => void;
}

export default function NonKoreanBillingInfoForm({ onChangeViewMode }: FreebetaNonKoreanBillingInfoFormProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { country, streetAddress, streetAddressDetail, city, region, postalCode, vat } = useSelector(
		(state: RootState) => state.address
	);

	const cvcInputRef = createRef<HTMLInputElement>();

	const [billingInfoFormData, setBillingInfoFormData] = useReducer(nonKoreanBillingInfoFormReducer, {
		...initialBillingInfoForm,
		country,
		streetAddress,
		streetAddressDetail,
		city,
		region,
		postalCode,
		vat,
	});

	const {
		countryHelpText,
		countryValidation,
		country: enteredCountry,
		isCountryChanged,
		streetAddress: enteredStreetAddress,
		streetAddressHelpText,
		streetAddressValidation,
		isStreetAddressChanged,
		isStreetAddressValid,
		streetAddressDetail: enteredStreetAddressDetail,
		isStreetAddressDetailChanged,
		city: enteredCity,
		cityHelpText,
		cityValidation,
		isCityChanged,
		isCityValid,
		postalCode: enteredPostalCode,
		postalCodeHelptext,
		postalCodeValidation,
		isPostalCodeChanged,
		isPostalCodeValid,
		vat: enteredVat,
		isVatChanged,
		region: enteredRegion,
		isRegionChanged,
		isCountryValid,
		cardNumber,
		isCardNumberValid,
		isCardNumberChanged,
		cardNumberHelpText,
		cardNumberValidation,
		cardExpDate,
		cardExpDateHelpText,
		cardExpDateValidation,
		isCardExpDateChanged,
		isCardExpDateValid,
		cvc,
		cvcHelpText,
		cvcValidation,
		isCvcChanged,
		isCvcValid,
	} = billingInfoFormData;

	const countryChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setBillingInfoFormData({ type: UPDATE_COUNTRY, payload: e.target.value });
	};

	const countryBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_COUNTRY });
	};

	const streetAddressChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_STREET_ADDRESS, payload: e.target.value });
	};

	const streetAddressBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_STREET_ADDRESS });
	};

	const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_CITY, payload: e.target.value });
	};

	const cityBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_CITY });
	};

	const postalCodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_POSTAL_CODE, payload: e.target.value });
	};

	const postalBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_POSTAL_CODE });
	};

	const streetAddressDetailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_STREET_ADDRESS_DETAIL, payload: e.target.value });
	};

	const regionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_REGION, payload: e.target.value });
	};

	const vatChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_VAT, payload: e.target.value });
	};

	const cardNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_CARD_NUM, payload: cardNumberFormat(e.target.value) });
	};

	const cardNumberBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_CARD_NUM });
	};

	const cardExpDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_EXP_DATE, payload: cardExpDateFormat(e.target.value) });
	};

	const cardExpDateBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_EXP_DATE });
	};

	const cvcChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setBillingInfoFormData({ type: UPDATE_CVC, payload: e.target.value });
	};

	const cvcBlurHandler = () => {
		setBillingInfoFormData({ type: VALIDATE_CVC });
		cvcInputRef.current?.setAttribute('readonly', '');
	};

	const paymentFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();

		dispatch(
			postOrganizationPaymentInfoNonKoreanThunk({
				cardNumber,
				expirateDate: cardExpDate,
				cvc,
				address: {
					country: enteredCountry,
					streetAddress: enteredStreetAddress,
					streetAddressDetail: enteredStreetAddressDetail,
					postalCode: enteredPostalCode,
					city: enteredCity,
					region: enteredRegion,
					vat: enteredVat,
				},
				onSuccess: onChangeViewMode,
			})
		);
	};

	useEffect(() => {
		return () => {
			clearTimeout(cvcTimeout);
		};
	}, []);

	return (
		<form css={formSt} onSubmit={paymentFormSubmitHandler}>
			<div css={titleWrap}>
				<PlanTitle title={t('freeBeta.billingInfo')} />
			</div>
			<div css={lowerBox}>
				<div css={inputContainerSt}>
					<h5>{t('planSubscription.companyAddress')}</h5>
					<Dropdown
						label={
							<span>
								{t('planSubscription.country')}
								<Astrix />
							</span>
						}
						menu={COUNTRY_LIST}
						placeholder={t('planSubscription.selectCountry')}
						width="100%"
						helpText={isCountryChanged && countryValidation === 'failed' && t(countryHelpText)}
						validation={isCountryChanged ? countryValidation : 'none'}
						onChange={countryChangeHandler}
						bindedValue={enteredCountry}
						onBlur={countryBlurHandler}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.streetAddress')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						helpText={isStreetAddressChanged && !isStreetAddressValid && t(streetAddressHelpText)}
						validation={isStreetAddressChanged ? streetAddressValidation : 'none'}
						onChange={streetAddressChangeHandler}
						value={enteredStreetAddress}
						onBlur={streetAddressBlurHandler}
					/>
					<Textinput
						label={t('planSubscription.suietUnit')}
						placeholder="Optional"
						maxLength={MAX_PAYMENT_CHAR_NUM}
						onChange={streetAddressDetailChangeHandler}
						value={enteredStreetAddressDetail}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.city')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						helpText={isCityChanged && !isCityValid && t(cityHelpText)}
						validation={isCityChanged ? cityValidation : 'none'}
						onChange={cityChangeHandler}
						value={enteredCity}
						onBlur={cityBlurHandler}
					/>
					<Textinput
						label={t('planSubscription.stateProvReg')}
						placeholder={t('planSubscription.optional')}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						value={enteredRegion}
						onChange={regionChangeHandler}
					/>
					<Textinput
						label={
							<span>
								{t('planSubscription.zipCode')}
								<Astrix />
							</span>
						}
						maxLength={MAX_PAYMENT_CHAR_NUM}
						showIcon
						helpText={isPostalCodeChanged && !isPostalCodeValid && t(postalCodeHelptext)}
						validation={isPostalCodeChanged ? postalCodeValidation : 'none'}
						onChange={postalCodeChangeHandler}
						onBlur={postalBlurHandler}
						value={enteredPostalCode}
					/>
					<Textinput
						label={t('planSubscription.vat')}
						placeholder="Optional"
						maxLength={MAX_PAYMENT_VAT_CHAR_NUM}
						value={enteredVat}
						onChange={vatChangeHandler}
					/>
				</div>
				<div css={paymentContainer}>
					<h5>{t('planSubscription.pymtMethod')}</h5>
					<div css={paymentInputContainer}>
						<Textinput
							label={
								<div css={labelSt}>
									<p>{t('modal.cardNum')}</p>
									<Astrix />
								</div>
							}
							value={cardNumberFormat(cardNumber)}
							validation={isCardNumberChanged ? cardNumberValidation : 'none'}
							helpText={isCardNumberChanged && !isCardNumberValid && t(cardNumberHelpText)}
							onChange={cardNumberChangeHandler}
							onBlur={cardNumberBlurHandler}
							maxLength={20}
							autoComplete="new-password"
						/>
						<Textinput
							label={
								<div css={labelSt}>
									<p>{t('modal.cardExpDate')}</p>
									<Astrix />
								</div>
							}
							value={cardExpDateFormat(cardExpDate)}
							validation={isCardExpDateChanged ? cardExpDateValidation : 'none'}
							helpText={isCardExpDateChanged && !isCardExpDateValid && t(cardExpDateHelpText)}
							onChange={cardExpDateChangeHandler}
							onBlur={cardExpDateBlurHandler}
							placeholder="YYYY-MM"
							maxLength={7}
							autoComplete="new-password"
						/>
						<Textinput
							label={
								<div css={labelSt}>
									<p>cvc</p>
									<Astrix />
								</div>
							}
							value={cvc}
							validation={isCvcChanged ? cvcValidation : 'none'}
							helpText={isCvcChanged && !isCvcValid && t(cvcHelpText)}
							valueType="password"
							onChange={cvcChangeHandler}
							onBlur={cvcBlurHandler}
							maxLength={3}
							ref={cvcInputRef}
							onFocus={() => {
								cvcTimeout = setTimeout(() => {
									cvcInputRef.current?.removeAttribute('readonly');
								}, 100);
							}}
							autoComplete="one-time-code"
							isReadOnlyAttribute
						/>
					</div>
					<ul css={guideSt}>
						<li>{t('planSubscription.changePymtMethodMsg')}</li>
						<li>{t('modal.addPymtMethodMsg1')}</li>
						<li>{t('modal.addPymtMethodMsg2')}</li>
					</ul>
					<Button
						width="100%"
						disabled={
							(isPostalCodeChanged && !isPostalCodeValid) ||
							(isStreetAddressChanged && !isStreetAddressValid) ||
							(isCityChanged && !isCityValid) ||
							(isCountryChanged && !isCountryValid) ||
							!isCardNumberValid ||
							!isCardExpDateValid ||
							!isCvcValid
						}
					>
						{t('planSubscription.saveChanges')}
					</Button>
				</div>
			</div>
			<BillingInfoPromptModal
				when={
					isPostalCodeChanged ||
					isCityChanged ||
					isCountryChanged ||
					isStreetAddressChanged ||
					isStreetAddressDetailChanged ||
					isVatChanged ||
					isRegionChanged ||
					isCvcChanged ||
					isCardNumberChanged ||
					isCardExpDateChanged
				}
			/>
		</form>
	);
}

const inputContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 25rem;
`;

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 3rem;

	width: 60rem;
`;

const titleWrap = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const lowerBox = css`
	display: flex;
	justify-content: space-between;
`;

const labelSt = css`
	display: flex;
`;

const paymentContainer = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 400px;
`;

const paymentInputContainer = css`
	display: flex;
	flex-direction: column;
	gap: 16px;

	width: 100%;
`;

const guideSt = css`
	line-height: 160%;
	font-size: 13px;

	> li {
		list-style: inside;
		white-space: pre-line;
	}
`;

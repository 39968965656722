/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState } from 'common/redux/store';

import OnPremSurffyHomeRunning from 'features/home/components/OnPremSurffyHomeRunning';
import OnPremSurffyHomePending from 'features/home/components/OnPremSurffyHomePending';
import OnPremSurffyHomeTenantFail from 'features/home/components/OnPremSurffyHomeTenantFail';

export default function SurffyHomePage() {
	const { tenantStatus } = useSelector((state: RootState) => state.account);

	if (tenantStatus === 'FAILED') {
		return (
			<div css={container}>
				<OnPremSurffyHomeTenantFail />
			</div>
		);
	}

	if (tenantStatus === 'PENDING') {
		return (
			<div css={container}>
				<OnPremSurffyHomePending />
			</div>
		);
	}

	return (
		<div css={container}>
			<OnPremSurffyHomeRunning />
		</div>
	);
}

const container = css`
	width: 960px;

	padding: 32px 24px 80px;

	margin: 0 auto;
`;

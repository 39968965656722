/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import { paletteSDS, themeSDS, Button, AddIcon } from 'surf-design-system';

import Tooltip from 'common/components/Tooltip';

// etc
import type { RootState } from 'common/redux/store';
import {
	toggleIsIntervalSegmentCreateModalVisible,
	toggleIsIntervalSegmentMaxNumModalVisible,
} from 'common/context/modalSlice';

import IntervalSegmentMaxNumModal from 'features/nfInterval/components/IntervalSegmentMaxNumModal';
import SegmentGraphModal from 'features/nfBasic/components/SegmentMaxGraphModal';

const { fontWeight, fontSize } = themeSDS;
const { gray, systemColor } = paletteSDS;

export default function PathControlSegmentLegend() {
	const dispatch = useDispatch();

	const { userRole } = useSelector((state: RootState) => state.account);

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const { planOptions } = useSelector((state: RootState) => state.plan);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { activatedYn } = projectList[selectedProjectIndex];

	const currSegNum: number = useSelector((state: RootState) => state.nfControl.segment.path.meta.length);

	const { t } = useTranslation();

	const legendHeader = {
		segmentTitle: `${t('commonWords.segTitle')}`,
		segmentStatus: `${t('projectMonitoring.segSts')}`,
	};

	const segmentLegendList = [
		{
			id: 1,
			name: `${t('projectMonitoring.waitTickets')}`,
			tooltip: `${t('projectMonitoring.ttlWaitTicketsTooltip')}`,
		},
		{ id: 2, name: `${t('projectMonitoring.queue')}` },
		{
			id: 3,
			name: `${t('projectMonitoring.waitTime')}(${t('commonUnit.sec')})`,
		},
		{
			id: 4,
			name: `${t('projectMonitoring.inflow')}`,
			tooltip: `${t('projectMonitoring.ttlInflowTooltip')}`,
		},
		{ id: 5, name: `${t('projectMonitoring.limitedInflow')}` },
		{ id: 6, name: `${t('projectMonitoring.resourceUsageRate')}` },
		{ id: 7, name: `${t('projectMonitoring.outflowRate')}` },
		{
			id: 8,
			name: `${t('projectMonitoring.prcsTime')}(${t('commonUnit.sec')})`,
		},
	];

	const addButtonClickHandler = () => {
		if (currSegNum < planOptions[7].value) {
			dispatch(toggleIsIntervalSegmentCreateModalVisible());
		} else {
			dispatch(toggleIsIntervalSegmentMaxNumModalVisible());
		}
	};

	return (
		<div css={SegmentLegendCss}>
			<div css={segmentTitleCss}>{legendHeader.segmentTitle}</div>
			<div css={segmentStatusCss}>{legendHeader.segmentStatus}</div>
			<ul css={nameListCss}>
				{segmentLegendList.map((legend, index) => (
					<li css={legendCss} key={legend.id}>
						<div css={nameCss(legend.tooltip, index !== 0 && index !== 4 && !activatedYn)}>{legend.name}</div>
						{legend.tooltip && <Tooltip message={legend.tooltip} disabled={!activatedYn} position="top" />}
					</li>
				))}
			</ul>
			<div css={buttonWrapperCss}>
				<Button
					size="small"
					icon={<AddIcon fill={gray[50]} />}
					iconPosition="left"
					width="140px"
					onClick={addButtonClickHandler}
					disabled={userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR'}
				>
					{t('projectMonitoring.add')}
				</Button>
			</div>
			<IntervalSegmentMaxNumModal />
			<SegmentGraphModal />
		</div>
	);
}

const SegmentLegendCss = css`
	height: 100%;
	width: 188px;
	min-width: 188px;

	padding: 0px 16px 12px 16px;

	border: 1px solid ${gray[300]};

	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;

	background-color: ${systemColor.white};

	color: ${gray[700]};
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;
`;

const segmentTitleCss = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 2.5rem;
`;

const segmentStatusCss = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 2rem;
`;

const nameListCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 320px;
	//height: 17.5rem;
`;

const legendCss = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const nameCss = (hasTooltip: string | undefined, disabled: boolean) => css`
	margin-right: ${hasTooltip ? '0.3125rem' : ''};

	white-space: nowrap;

	color: ${disabled && gray['300']};
`;

const buttonWrapperCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	margin-top: 12px;
`;

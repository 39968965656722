import axios from 'axios';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
// import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? getOnPremBaseUrl() : process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(config => {
	// const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	// const userKey = window.sessionStorage.getItem(USER_KEY);
	//
	// if (!cognitoToken || !userKey) {
	// 	return Promise.reject(AxiosError.ERR_BAD_REQUEST);
	// }

	return config;
});

axiosInstance.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error?.response?.data?.errorCode === 'L1002') {
			window.location.replace('/');
		}

		if (error?.response?.data?.errorCode === 'L1003') {
			window.location.replace('/');
		}

		return Promise.reject(error);
	}
);

export default axiosInstance;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import { putProjectNameApi } from 'features/nfSetting/api/putProjectName.v1.api';

export interface PutProjectNameThunkProps {
	projectName: string;
	selectedProjectId: number;
}

export interface PutProjectNameThunkResult {
	projectId: number;
	projectName: string;
	updatedAt: string;
}

export const putProjectNameThunk = createAsyncThunk<
	PutProjectNameThunkResult,
	PutProjectNameThunkProps,
	{ state: RootState }
>('nfPrj/put/name/v2', async (args: PutProjectNameThunkProps, thunkAPI) => {
	const { projectName, selectedProjectId } = args;

	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();

	try {
		const {
			data: { id, updatedAt },
		} = await putProjectNameApi({
			organizationId,
			tenantId: tenantId as string,
			projectId: selectedProjectId,
			projectName,
		});

		thunkAPI.dispatch(initializeProjectManagementModal());

		return { projectId: id, projectName, updatedAt };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

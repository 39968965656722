import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import { PostProjectStatDataApiResponse } from '../types/postProjectStatData.type';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 프로젝트 리스트들의 data NF(DS)에 요청 api
 * todo: tenant 별 db가 생기면 주석된 주소로 변경 필요
 */

export interface PostProjectStatDataApiProps {
	tenantUrl: string;
	tenantId: string;
	organizationId: number;
	projectId: number;
	begin: number;
	end: number;
	isPathControl: boolean;
}

export default async function postProjectStatDataApi({
	tenantUrl,
	tenantId,
	organizationId,
	projectId,
	begin,
	end,
	isPathControl,
}: PostProjectStatDataApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	const body = {
		data: {
			projectIds: [projectId],
			fields: ['total_tickets', 'max_wait_time', 'max_numbers_waiting', 'max_process_time'],
			interval_control_yn: isPathControl,
		},
		range: {
			type: 'realtime',
			value: {
				begin,
				end,
			},
		},
	};

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.post<PostProjectStatDataApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/statData/select/project`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

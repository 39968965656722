import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import type { MemberData } from 'features/setting/types/getMemberList.onPrem.type';
import getMemberListApi from 'features/setting/api/getMemberList.v2.onPrem.api';

export interface GetMemberListThunkResult {
	members: MemberData[];
	searchValue?: string;
	memberStatus: string;
}

export const getMemberListThunk = createAsyncThunk<GetMemberListThunkResult, void, { state: RootState }>(
	'memberList/onPrem/get',
	async (args, thunkAPI) => {
		const {
			account: { organizationId },
			onPremMember: { searchValue, memberStatus },
		} = thunkAPI.getState();

		try {
			const {
				data: { content },
			} = await getMemberListApi({
				searchValue,
				organizationId,
				memberStatus: memberStatus === 'all' ? undefined : memberStatus,
			});

			return { members: content, searchValue, memberStatus };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

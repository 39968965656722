/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css, SerializedStyles } from '@emotion/react';
import React, { useEffect } from 'react';

import { CloseIcon, paletteSDS } from 'surf-design-system';

interface ToastProps {
	width?: string;
	height?: string;
	visible?: boolean;
	children?: React.ReactNode;
	duration?: number;
	backgroundColor?: string;
	fontColor?: string;
	isToastClose?: boolean;
	onClose?: () => void;
	isCloseIconVisible?: boolean;
	closeIconCss?: SerializedStyles;
}

/**
 * author: Jay
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: toast 알람 컴포넌트
 * todo:
 */
export default function Toast({
	width = '100%',
	height = '2.5rem',
	visible,
	children,
	duration = 3500,
	backgroundColor,
	fontColor,
	isToastClose = true,
	onClose,
	isCloseIconVisible = false,
	closeIconCss = css``,
}: ToastProps) {
	useEffect(() => {
		if (isToastClose && visible && onClose) {
			const timer = setTimeout(() => {
				onClose();
			}, duration);

			return () => {
				clearTimeout(timer);
			};
		}
		return undefined;
	}, [visible, duration, isToastClose, onClose]);

	return (
		<div css={toastCss(width, height, backgroundColor, fontColor, visible)}>
			{children}
			{isCloseIconVisible && visible ? (
				<CloseIcon size="small" onClick={onClose} fill={paletteSDS.white} emotionCss={[closeIconSt, closeIconCss]} />
			) : null}
		</div>
	);
}

const toastCss = (
	width: string | undefined,
	height: string | undefined,
	backgroundColor: string | undefined,
	fontColor: string | undefined,
	visible: boolean | undefined
) => css`
	position: relative;

	flex: none;

	display: flex;
	justify-content: center;
	align-items: center;

	width: ${width};
	height: ${visible ? height : 0};
	overflow: hidden;

	background-color: ${backgroundColor};
	color: ${visible ? fontColor : 'transparent'};

	transition: height 0.5s ease-in-out, color 0.4s ease-in-out;
`;

const closeIconSt = css`
	position: absolute;
	top: 10px;
	right: 10px;
`;

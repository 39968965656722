import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import {
	toggleIsServiceDomainSettingModalVisible,
	toggleIsUpdateMainDomainConfirmModalVisible,
} from 'common/context/modalSlice';
import { toggleIsPutServiceDomainFailToastVisible } from 'common/context/toastSlice';

import { putServiceDomainBeApi, putServiceDomainTenantApi } from '../api/putServiceDomain.api';

export interface PutServiceDomainThunkProps {
	enteredCustomerDomain: string;
}

export interface PutServiceDomainThunkResult {
	projectId: number;
	customerDomain: string;
}

export const putServiceDomainThunk = createAsyncThunk<
	PutServiceDomainThunkResult,
	PutServiceDomainThunkProps,
	{ state: RootState }
>('account/put/customerDomain', async (args: PutServiceDomainThunkProps, thunkAPI) => {
	const { enteredCustomerDomain } = args;

	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		await putServiceDomainBeApi({ organizationId, defaultCustomerUrl: enteredCustomerDomain });

		const result = await putServiceDomainTenantApi({
			organizationId,
			customerServiceDomain: enteredCustomerDomain,
			tenantId: tenantId as string,
			tenantUrl,
			projectId: selectedProjectId,
		});

		const { data: tenantData } = result;

		thunkAPI.dispatch(toggleIsUpdateMainDomainConfirmModalVisible());

		thunkAPI.dispatch(toggleIsServiceDomainSettingModalVisible());

		return { projectId: tenantData.id, customerDomain: enteredCustomerDomain };
		// eslint-disable-next-line
	} catch (error: any) {
		thunkAPI.dispatch(toggleIsUpdateMainDomainConfirmModalVisible());

		thunkAPI.dispatch(toggleIsPutServiceDomainFailToastVisible());

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

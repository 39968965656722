import _ from 'lodash';

import { loadTimeColor } from 'features/nfEUM/data/constants';
import { latlong } from 'features/nfEUM/data/latlong';
import { GetEUMMapApiData, MapImage } from 'features/nfEUM/types/getEUMMap.type';

export function mapTitleToJsForm(data: string) {
	const tmpCode = data.split(' ');
	tmpCode[0] = tmpCode[0].toLowerCase();
	const mapName = `${tmpCode.join('')}Low`;

	return mapName;
}

export function mapJsFormToTitle(data: string) {
	const dataToArray = _.snakeCase(data)
		.split('_')
		.map(el => el.charAt(0).toUpperCase() + el.slice(1));
	dataToArray.pop();
	const result = dataToArray.join(' ');

	return result;
}

export const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const calcCircleSize = (count: number) => {
	const str = count.toString();
	if (str.length < 3) return 20;
	if (str.length === 3) return 30;
	if (str.length === 4) return 40;
	if (str.length === 5) return 50;
	if (str.length === 6) return 60;
	return 70;
};

export function generateCountryImage(mapData: GetEUMMapApiData[]): MapImage[] {
	let latitude = 0;
	let longitude = 0;
	let title = '';
	let color = '';
	let pt = 0;

	const result: MapImage[] = mapData.map(el => {
		title = regionNames.of(el.region) || '';
		latitude = latlong[!el.region ? 'unknown' : (el.region as keyof typeof latlong)].latitude;
		longitude = latlong[!el.region ? 'unknown' : (el.region as keyof typeof latlong)].longitude;
		color = loadTimeColor[Math.floor(el.pt) >= 9 ? 9 : (Math.floor(el.pt) as keyof typeof loadTimeColor)];
		pt = Number(el.pt.toFixed(2));

		return {
			type: 'circle',
			width: calcCircleSize(el.count),
			height: calcCircleSize(el.count),
			color,
			zoomLevel: 5,
			scale: 0.5,
			title,
			latitude,
			longitude,
			line: [el.count, pt],
		};
	});

	return result;
}

export function generateCityImage(mapData: GetEUMMapApiData[]): MapImage[] {
	let latitude = 0;
	let longitude = 0;
	let title = '';
	let color = '';
	let pt = 0;

	const result: MapImage[] = mapData.map(el => {
		// regionCodes[0]: countryCode("KR"), [1]: cityName("Seoul"), [2]: regionCode("11"), [3]: latitude("37.53"), [4]: longitude("127.02")
		const regionCodes: string[] = el.region.split('/');

		title = regionCodes[1] === '' ? regionNames.of(regionCodes[0]) || '' : regionCodes[1];

		if (regionCodes[3] === '' || regionCodes[4] === '') {
			latitude = latlong[regionCodes[0] as keyof typeof latlong].latitude;
			longitude = latlong[regionCodes[0] as keyof typeof latlong].longitude;
		} else {
			latitude = Number(regionCodes[3]);
			longitude = Number(regionCodes[4]);
		}

		color = loadTimeColor[Math.floor(el.pt) >= 9 ? 9 : (Math.floor(el.pt) as keyof typeof loadTimeColor)];
		pt = Number(el.pt.toFixed(2));

		return {
			type: 'circle',
			width: el.count,
			height: el.count,
			color,
			zoomLevel: 5,
			scale: 0.5,
			title,
			latitude,
			longitude,
			line: [el.count, pt],
		};
	});

	return result;
}

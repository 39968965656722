/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import DividerWithTxt from 'common/components/DividerWithTxt';
import { RootState, useAppDispatch } from 'common/redux/store';

import { setInitialTimestamp, setInitialUsageData, setSelectedProjectId } from 'features/plan/context/usageSlice';

import OnPremUsageSummary from 'features/plan/components/OnPremUsageSummary';
import OnPremOverallUsage from 'features/plan/components/OnPremOverallUsage';
import SaasUsageHeader from 'features/plan/components/SaasUsageHeader';

export default function Usage() {
	const dispatch = useAppDispatch();

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	useLayoutEffect(() => {
		dispatch(setSelectedProjectId(selectedProjectId));
		dispatch(setInitialTimestamp(moment().valueOf()));

		return () => {
			dispatch(setInitialUsageData());
		};
	}, [dispatch, selectedProjectId]);

	return (
		<div css={container}>
			<SaasUsageHeader />
			<OnPremUsageSummary />
			<DividerWithTxt />
			<OnPremOverallUsage />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 912px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import type { userRoleType } from 'common/types/getOrganizationList.type';

interface PermissionDescriptionTextBoxProps {
	role: userRoleType;
}

export default function PermissionDescriptionTextBox({ role }: PermissionDescriptionTextBoxProps) {
	const { t } = useTranslation();

	if (role === 'NONE') {
		return null;
	}

	return (
		<div css={container}>
			{t(
				role === 'OWNER' || role === 'ADMINISTRATOR'
					? 'modal.ownerPermissionDescription'
					: 'modal.memberPermissionDescription'
			)}
		</div>
	);
}

const container = css`
	width: 100%;

	padding: 12px 16px;

	background: ${paletteSDS.blue['50']};

	border: 1px solid ${paletteSDS.blue['200']};
	border-radius: 8px;

	color: ${paletteSDS.black};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;

	word-break: keep-all;
`;

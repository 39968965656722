/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import OrganizationTable from 'features/setting/components/OrganizationTable';
import OwnerTransferModal from 'features/setting/components/OwnerTransferModal';
import { useSelector } from 'react-redux';
import { RootState } from 'common/redux/store';

export default function SwitchOrganization() {
	const { t } = useTranslation();

	const { isOwnerTransferModalVisible } = useSelector((state: RootState) => state.modal);

	return (
		<div css={containerSt}>
			<div css={pageTitleSt}>{t('personalSettings.org')}</div>
			<OrganizationTable />
			{isOwnerTransferModalVisible ? <OwnerTransferModal /> : null}
		</div>
	);
}
const containerSt = css`
	display: flex;
	flex-direction: column;

	gap: 24px;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;
	margin-bottom: 1.5rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

import { LineChartData } from 'features/nfEUM/hooks/useTXNLineChartOptions';

export function formatToPercent(series: number[], value: number) {
	const sum = series.reduce((a, b) => a + b);
	const result = Number(((value / sum) * 100).toFixed(1));
	return result;
}

export function lineChartAnnotationFn(chartData: LineChartData[]): LineChartData {
	const result = { x: 0, y: 0 };

	chartData.forEach(el => {
		if (el.y && el.y >= result.y) {
			result.x = el.x;
			result.y = el.y;
		}
	});

	return result;
}

interface DountChartData {
	browser?: string;
	os?: string;
	appVersion?: string;
	count: number;
}

export function formatCompactNumber(number: number) {
	const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
	return formatter.format(number);
}

export function formatLocaleString(number: number) {
	return number.toLocaleString('ko-KR');
}

export function donutChartAnnotationFn(chartData: DountChartData[]) {
	const result = { label: '', data: 0 };

	chartData.forEach((el, i) => {
		if (el.count > result.data) {
			result.label = chartData[i].browser || chartData[i].os || chartData[i].appVersion || '';
			result.data = el.count;
		}
	});

	return result;
}

interface ColumnChartData {
	x: string;
	y: number;
}

export function columnChartAnnotationFn(webData: ColumnChartData[], nativeData: ColumnChartData[]): ColumnChartData {
	const sum = webData.map((el, i) => {
		return { x: el.x, y: el.y + nativeData[i].y };
	});

	let result: ColumnChartData = { x: '', y: 0 };
	sum.forEach(el => {
		if (el.y >= result.y) result = el;
	});

	return result;
}

export function columnChartTotalCountFn(webData: ColumnChartData[], nativeData: ColumnChartData[]): number {
	let result = 0;

	webData.forEach(el => {
		result += el.y;
	});

	nativeData.forEach(el => {
		result += el.y;
	});

	return result;
}

export function calcMaxIntFn(a: number | null, b: number | null) {
	const bigger = (a || 0) > (b || 0) ? a || 0 : b || 0;
	const cipher = 10 ** (bigger.toString().length - 1);
	return Math.ceil(bigger / cipher) * cipher;
}

export function calcMaxFloatFn(a: number | null, b: number | null) {
	const bigger = (a || 0) > (b || 0) ? a || 0 : b || 0;
	return Math.ceil(bigger);
}

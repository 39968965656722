import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import { GetEUMShareWebApiDataBrowser, GetEUMShareWebApiDataOS } from 'features/nfEUM/types/getEUMShareWeb.type';
import { GetEUMShareNativeApiDataOS, GetEUMShareNativeApiDataApp } from 'features/nfEUM/types/getEUMShareNative.type';
import getEUMShareWebApi from 'features/nfEUM/api/getEUMShareWeb.api';
import getEUMShareNativeApi from 'features/nfEUM/api/getEUMShareNative.api';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface GetTXNShareThunkResult {
	webBrowserData: GetEUMShareWebApiDataBrowser[];
	webOSData: GetEUMShareWebApiDataOS[];
	nativeAppData: GetEUMShareNativeApiDataApp[];
	nativeOSData: GetEUMShareNativeApiDataOS[];
}

export const getTXNShareThunk = createAsyncThunk<GetTXNShareThunkResult, void, { state: RootState }>(
	'eum/txn/share/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantUrl, organizationId, customerDomain },
			eumTXNShare: { beginTime, endTime },
			projectInfo: { projectList, selectedProjectId },
			plan: { priceType },
		} = thunkAPI.getState();

		const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
		const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

		try {
			const apiResponse = await Promise.all([
				getEUMShareWebApi({
					tenantUrl,
					organizationId,
					mainDomain:
						priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
							? getDomainWithoutSubdomain(selectedDomain)
							: customerDomain,
					beginTime,
					endTime,
					projectKey: selectedProjectKey,
				}),
				getEUMShareNativeApi({
					tenantUrl,
					organizationId,
					mainDomain:
						priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
							? getDomainWithoutSubdomain(selectedDomain)
							: customerDomain,
					beginTime,
					endTime,
					projectKey: selectedProjectKey,
				}),
			]);

			let webBrowserData: GetEUMShareWebApiDataBrowser[] = [];
			let webOSData: GetEUMShareWebApiDataOS[] = [];
			let nativeAppData: GetEUMShareNativeApiDataApp[] = [];
			let nativeOSData: GetEUMShareNativeApiDataOS[] = [];

			if (apiResponse[0].data) {
				webBrowserData = apiResponse[0].data.browserDataList;
				webOSData = apiResponse[0].data.osDataList;
			}

			if (apiResponse[1].data) {
				nativeAppData = apiResponse[1].data.versionDataList;
				nativeOSData = apiResponse[1].data.osDataList;
			}
			thunkAPI.dispatch(setInitialToast());
			return {
				webBrowserData,
				webOSData,
				nativeAppData,
				nativeOSData,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

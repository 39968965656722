/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { paletteSDS } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';
import AuthHeader from 'common/components/AuthHeader';
import Footer from 'common/components/Footer';
import { putI18nLanguage } from 'common/utils/languageFunc';
import { resetAccountInfos } from 'common/context/accountInfoSlice';

export default function AuthLayout() {
	const { locale } = useParams();
	const dispatch = useAppDispatch();

	useLayoutEffect(() => {
		(async () => {
			await putI18nLanguage(locale || 'en');
		})();

		dispatch(resetAccountInfos());
	}, [locale, dispatch]);

	return (
		<div css={containerSt}>
			<AuthHeader />
			<main css={outletFooterBoxSt}>
				<div css={outletBoxSt}>
					<Outlet />
				</div>
				<Footer />
			</main>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;

	overflow: auto;

	background: ${paletteSDS.gray[75]};
`;

const outletFooterBoxSt = css`
	height: 100%;
	overflow: auto;
`;

const outletBoxSt = css`
	min-height: calc(100% - 147px);
`;

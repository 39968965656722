import getUsageCSVApi from 'features/plan/api/getUsageCSV.api';
import getUsageCSVApiV2 from 'features/plan/api/getUsageCSV.v2.api';
import type { period } from 'features/plan/api/getProjectUsageDetail.api';
import moment from 'moment';

export const downloadCSV = async (organizationId: number, interval: period, date: number) => {
	try {
		const data = await getUsageCSVApi({ interval, organizationId, date });

		const csvFile = new Blob([`\ufeff${data}`], { type: 'text/csv; charset=utf-8' });

		const urlFromCSV = URL.createObjectURL(csvFile);
		const aTag = document.createElement('a');

		aTag.href = urlFromCSV;
		aTag.download = `surffy_netfunnel_${moment(date * 1000)
			.utcOffset(0)
			.format('YYYYMMDD')}_${interval}.csv`;

		document.body.appendChild(aTag);

		aTag.click();

		setTimeout(() => {
			URL.revokeObjectURL(urlFromCSV);
		}, 60000);

		aTag.remove();
	} catch (e) {
		// eslint-disable-next-line
		alert('fail download file');
	}
};

interface DownloadCSVV2Props {
	organizationId: number;
	interval: period;
	date: number;
	tenantId: string;
	projectId: number;
}

export const downloadCSVV2 = async ({ organizationId, date, interval, tenantId, projectId }: DownloadCSVV2Props) => {
	try {
		const data = await getUsageCSVApiV2({ interval, organizationId, date, tenantId, projectId });

		const csvFile = new Blob([`\ufeff${data}`], { type: 'text/csv; charset=utf-8' });

		const urlFromCSV = URL.createObjectURL(csvFile);
		const aTag = document.createElement('a');

		aTag.href = urlFromCSV;
		aTag.download = `surffy_netfunnel_${moment(date * 1000)
			.utcOffset(0)
			.format('YYYYMMDD')}_${interval}.csv`;

		document.body.appendChild(aTag);

		aTag.click();

		setTimeout(() => {
			URL.revokeObjectURL(urlFromCSV);
		}, 60000);

		aTag.remove();
	} catch (e) {
		// eslint-disable-next-line
		alert('fail download file');
	}
};

export const onPremDownloadCSVV2 = async ({
	organizationId,
	date,
	interval,
	tenantId,
	projectId,
}: DownloadCSVV2Props) => {
	try {
		const data = await getUsageCSVApiV2({ interval, organizationId, date, tenantId, projectId });

		const csvFile = new Blob([`\ufeff${data}`], { type: 'text/csv; charset=utf-8' });

		const urlFromCSV = URL.createObjectURL(csvFile);
		const aTag = document.createElement('a');

		aTag.href = urlFromCSV;
		aTag.download = `surffy_netfunnel_${moment(date * 1000)
			.utcOffset('+UTC09:00')
			.format('YYYYMMDD')}_${interval}.csv`;

		document.body.appendChild(aTag);

		aTag.click();

		setTimeout(() => {
			URL.revokeObjectURL(urlFromCSV);
		}, 60000);

		aTag.remove();
	} catch (e) {
		// eslint-disable-next-line
		alert('fail download file');
	}
};

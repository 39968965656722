/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import SigninForm from 'features/signin/components/SigninForm';
import SigninOauth from 'features/signin/components/SigninOauth';
import SigninTitle from 'features/signin/components/SigninTitle';
import ForgotPWLink from 'features/signin/components/ForgotPWLink';

export default function SigninPage() {
	return (
		<div css={signinContainer}>
			<div css={signinWrapSt}>
				<SigninTitle />
				<SigninOauth />
				<SigninForm />
				<ForgotPWLink />
			</div>
		</div>
	);
}

const signinContainer = css`
	display: flex;
	justify-content: center;

	width: 100%;
	height: fit-content;

	padding: 5rem;
`;

const signinWrapSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	width: 25rem;
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import { GetProjectSegmentCheckApiResponse } from 'features/nfControl/type/getProjectSegmentCheck.v2.onPrem.type';

export interface GetProjectSegmentCheckApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
}

export default async function getProjectSegmentCheckApi({
	tenantId,
	organizationId,
	projectId,
}: GetProjectSegmentCheckApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetProjectSegmentCheckApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/project/${projectId}/segment-check`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Dropdown, paletteSDS, themeSDS } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';

import { putUserLanguageThunk } from 'features/setting/thunk/putUserLanguage.thunk';

const langMenus = [
	{ value: 'ENGLISH', name: 'English (United States)' },
	{ value: 'KOREAN', name: '한국어' },
];

export default function LanguageForm() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { language } = useSelector((state: RootState) => state.account);

	const languageChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = async e => {
		const selectedLanguage = e.target.value;

		const putUserLanguageResult = await dispatch(putUserLanguageThunk({ language: selectedLanguage }));

		const userLanguage = unwrapResult(putUserLanguageResult);

		if (process.env.REACT_APP_PRODUCT_MODE === 'ONPREM') {
			navigate(`/${LANG_PATH[userLanguage.language]}/on_prem/home/personal_setting/preference`);
			return;
		}

		navigate(`/${LANG_PATH[userLanguage.language]}/home/personal_setting/preference`);
	};

	return (
		<div css={profileWrapperCss}>
			<div css={pageTitleSt}>{t('personalSettings.lang')}</div>
			<Dropdown menu={langMenus} width="18rem" onChange={languageChangeHandler} bindedValue={language} />
		</div>
	);
}

const profileWrapperCss = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import TXNMap from 'features/nfEUM/components/TXNMap';
import TXNMapButton from 'features/nfEUM/components/TXNMapButton';
import TXNMapLegends from 'features/nfEUM/components/TXNMapLegends';
import TXNMapTimezone from 'features/nfEUM/components/TXNMapTimezone';
import TXNRealtime from 'features/nfEUM/components/TXNRealtime';
import TXNShare from 'features/nfEUM/components/TXNShare';

export default function TXNUpperContainer() {
	return (
		<div css={container}>
			<TXNRealtime />
			<div css={mapContainer}>
				<TXNMapButton />
				<TXNMap />
				<TXNMapLegends />
				<TXNMapTimezone />
			</div>
			<TXNShare />
		</div>
	);
}

const container = css`
	display: flex;
	align-items: center;
	gap: 8px;
	min-width: 1616px;
	height: 662px;
`;

const mapContainer = css`
	position: relative;
	width: 668px;
	height: 662px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';
// etcS
import { paletteSDS } from 'surf-design-system';

import { DEFAULT_TRACK_PADDING, DEFAULT_TRACK_STROKE } from '../data/constants';

const { gray, systemColor } = paletteSDS;

interface GaugeBarProps {
	min: number;
	max: number;
	value: number;
	width: number;
	trackStroke?: number;
	trackPadding?: number;
	trackColor?: string;
	gaugeColor?: string;
}

export default function GaugeBar({
	min,
	max,
	value,
	width,
	trackStroke = DEFAULT_TRACK_STROKE,
	trackPadding = DEFAULT_TRACK_PADDING,
	trackColor = systemColor.white,
	gaugeColor = gray[700],
}: GaugeBarProps) {
	const [percent, setPercent] = useState(value / (max - min));

	useEffect(() => {
		setPercent((value - min) / (max - min));
	}, [min, max, value]);

	return (
		<div css={gaugeBarCss(width, trackStroke, trackColor, trackPadding)}>
			<div css={gaugeCss(percent * width, trackStroke, trackPadding, gaugeColor)} />
		</div>
	);
}

const gaugeBarCss = (width: number, height: number, trackColor: string, trackPadding: number) => css`
	display: flex;
	align-items: center;
	width: ${width}px;
	height: ${height}px;
	padding: ${trackPadding}px;
	background-color: ${trackColor};
	overflow: hidden;
`;

const gaugeCss = (gaugePoint: number, trackStroke: number, trackPadding: number, gaugeColor: string) => css`
	width: ${gaugePoint}px;
	height: ${trackStroke - trackPadding}px;
	background-color: ${gaugeColor};
	transition: width ease 0.5s;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import ProjectManagementTable from 'features/nfSetting/components/ProjectManagementTable';
import ManagementGuide from 'features/nfBasic/components/ManagementGuide';

export default function ProjectManagement() {
	const { t } = useTranslation();

	return (
		<div css={container}>
			<div css={pageTitleSt}>{t('projectMgmt.mgmt')}</div>
			<ProjectManagementTable />
			<ManagementGuide />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

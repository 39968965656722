/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import type { paymentFlagType, GetPaymentChargeApiData } from '../types/getPaymentCharge.type';

import { addNumberComma, getDateFormatLanguage, priceUnitFormat, paymentFlagFormat } from '../utils/formatFunc';

/**
 * author: Finn
 * createdAt: 2022-09-22
 * updatedAt: 2022-09-22
 * description: 인보이스 전체 정보 조회
 * todo: 다국어 적용 필요, 결제일 데이터 확인, VAT 추가
 */
interface InvoiceInfoProps {
	paymentCharge: GetPaymentChargeApiData;
	paymentMethod: string;
}
export default function InvoiceInfo({ paymentCharge, paymentMethod }: InvoiceInfoProps) {
	const { organizationName, language } = useSelector((state: RootState) => state.account);

	const { t } = useTranslation();

	const today = moment().toLocaleString();
	const billedDate = getDateFormatLanguage(today, language);

	return (
		<div css={containerSt}>
			<div css={leftBoxSt}>
				<p>{t('planSubscription.invoice')}</p>
				<div css={leftBottomBoxSt}>
					<p>{t('planSubscription.billdTo')}</p>
					<p>{organizationName}</p>
				</div>
			</div>
			<div css={rightBoxSt}>
				<div css={dateSt}>
					<p>
						{t('planSubscription.billedDate')} : {billedDate}
					</p>
					<p>
						{t('planSubscription.pymtMethod')} : {paymentMethod}
					</p>
				</div>
				<div css={priceSt}>
					<p css={paymentFlagSt(paymentCharge.paymentFlag)}>{paymentFlagFormat(paymentCharge.paymentFlag)}</p>
					<p css={totalChargeSt}>
						{priceUnitFormat(paymentCharge.chargeDetailModels[0].priceUnit)}
						{addNumberComma(String(paymentCharge.totalCharge + paymentCharge.surtax || 0))}
					</p>
				</div>
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;

	padding: 1.75rem 1.5rem 1.5rem;
	width: 100%;
	height: 10rem;

	border: 1px solid ${paletteSDS.gray[500]};
	border-radius: 4px;

	background-color: ${paletteSDS.white};
`;

const leftBoxSt = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 6.75rem;

	> p {
		height: 1.875rem;
		line-height: 100%;

		font-size: 30px;
		font-weight: 600;
	}
`;

const leftBottomBoxSt = css`
	> p:nth-of-type(1) {
		margin-bottom: 0.25rem;
		color: ${paletteSDS.gray[800]};
		font-size: ${themeSDS.fontSize[14]};
	}

	> p:nth-of-type(2) {
		font-size: ${themeSDS.fontSize[18]};
		font-weight: 600;
	}
`;

const rightBoxSt = css`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	width: 100%;

	text-align: right;
`;

const dateSt = css`
	margin-bottom: 1rem;
	height: 2.75rem;
	line-height: 160%;

	color: ${paletteSDS.gray[800]};
	font-size: ${themeSDS.fontSize[14]};
`;

const priceSt = css`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	gap: 0.5rem;
`;

const paymentFlagSt = (paymentFlag: paymentFlagType) => css`
	padding: 0.25rem 0.5rem;
	height: 1.5625rem;
	line-height: 128%;

	border-radius: 4px;

	${paymentFlag === 'YES' && `background-color: ${paletteSDS.blue[700]}`};
	${paymentFlag === 'NO' && `background-color: ${paletteSDS.gray[500]}`};
	${paymentFlag === 'NOW' && `background-color: ${paletteSDS.categoryColor.mint}`};
	color: ${paletteSDS.systemColor.white};

	font-size: 13px;
	font-weight: 600;
	letter-spacing: -0.008em;
`;

const totalChargeSt = css`
	height: 1.75rem;
	line-height: 100%;

	font-size: 28px;
	font-weight: ${themeSDS.fontWeight.bold};
`;

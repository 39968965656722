/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	Textinput,
	Button,
	Radio,
	Portal,
	paletteSDS,
	themeSDS,
	InfoIcon,
	SideModal,
	CloseIcon,
	Label,
	SearchIcon,
	Dropdown,
	Loader,
	Modal,
} from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Astrix from 'common/components/Astrix';
import TooltipPortal from 'common/components/TootipPortal';
import DividerWithTxt from 'common/components/DividerWithTxt';
import Avatar from 'common/components/Avatar';
import { DEFAULT_IMG_URL, USER_KEY } from 'common/data/constants';
import { resetPathControlModal } from 'common/context/modalSlice';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';

import {
	UPDATE_NAME,
	VALIDATE_NAME,
	UPDATE_MAX_INFLOW,
	VALIDATE_MAX_INFLOW,
	initialSegmentForm,
	segmentCreateFormOnPremReducer,
	UPDATE_END_URI,
	UPDATE_START_URI,
	VALIDATE_END_URI,
	VALIDATE_START_URI,
	UPDATE_END_URI_HELP_TEXT,
	UPDATE_START_URI_HELP_TEXT,
	UPDATE_NAME_HELP_TEXT,
	INITIALIZE_FORM,
	UPDATE_ACCESS_YN,
	UPDATE_MEMBER_SEARCH_VALUE,
	UPDATE_SEARCHED_MEMBER_LIST,
	DELETE_MEMBER,
	ADD_MEMBER,
	UPDATE_VWR_ID,
	UPDATE_SELECTED_MEMBER_LIST,
} from 'features/nfControl/context/pathControlSegmentCreateForm.onPrem.reducer';
import putPathControlSegmentOnPremThunk from 'features/nfControl/thunk/putPathControlSegment.v2.onPrem.thunk';
import { resetPathControlError } from 'features/nfControl/context/nfControlSlice';
import { MAX_SEG_TITLE_LENGTH } from 'features/nfBasic/data/constants';
import getOperatorListOnPremApi from 'features/nfControl/api/getOperatorList.v2.onPrem.api';
import getSelectedOperatorListOnPremApi from 'features/nfControl/api/getSelectedOperatorList.v2.onPrem.api';
import { PostSegmentDuplicateApiResponseData } from 'features/nfBasic/types/postSegmentDuplicate.type';
import postSegmentDuplicateApi from 'features/nfBasic/api/postSegmentDuplicate.api';

export interface OnPremPathControlSegmentUpdateSideModalProps {
	isClassic?: boolean;
}

export default function OnPremPathControlSegmentUpdateSideModal({
	isClassic = false,
}: OnPremPathControlSegmentUpdateSideModalProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isLoading, error } = useSelector((state: RootState) => state.nfControl.segment.path);

	const { response: getOperatorListResponse, sendRequest: getOperatorList } =
		useApiRequestWithArg(getOperatorListOnPremApi);
	const { response: getSelectedOperatorListResponse, sendRequest: getSelectedOperatorListRequest } =
		useApiRequestWithArg(getSelectedOperatorListOnPremApi);

	const { tenantId, organizationId, userRole, language } = useSelector((state: RootState) => state.account);

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const mainDomain = useSelector(
		(state: RootState) =>
			state.projectInfo.projectList.find(el => el.id === state.projectInfo.selectedProjectId)?.customerServiceDomain
	);

	const {
		isUpdateModalVisible,
		initialData: {
			segmentName: initialSegmentName,
			vwrPageId,
			vwrPage,
			accessYn: initialAccessYn,
			id,
			maxInflow: initialMaxInflow,
			endUri: initialEndUri,
			startUri: initialStartUri,
			segmentKey,
		},
	} = useSelector((state: RootState) => state.modal.pathControl);

	const [segmentFormData, setSegmentFormData] = useReducer(segmentCreateFormOnPremReducer, {
		...initialSegmentForm,
	});

	const [isMemberSearchInputFocus, setIsMemberSearchInputFocus] = useState(false);
	const [isSelectedOperatorListIncludeUser, setIsSelectedOperatorListIncludeUser] = useState(false);

	const {
		segmentName,
		isSegmentNameValid,
		isSegmentNameChanged,
		segmentNameValidation,
		segmentNameHelpText,
		maxInflow,
		maxInflowHelpText,
		maxInflowValidation,
		isMaxInflowChanged,
		isMaxInflowValid,
		startUri,
		startUriValidation,
		startUriHelpText,
		isStartUriValid,
		isStartUriChanged,
		endUri,
		endUriHelpText,
		endUriValidation,
		isEndUriValid,
		isEndUriChanged,
		accessYn,
		searchedMemberList,
		selectedMemberList,
		memberSearchValue,
		vwrId,
		disabledMemberList,
	} = segmentFormData;

	// description: events
	const closeIconClickHandler = () => {
		dispatch(resetPathControlError());
		dispatch(resetPathControlModal());
	};

	const startUriChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSegmentFormData({ type: UPDATE_START_URI, payload: value });
	};

	const startUriBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_START_URI });
	};

	const endUriChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSegmentFormData({ type: UPDATE_END_URI, payload: value });
	};

	const endUriBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_END_URI });
	};

	const segmentNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NAME, payload: e.target.value });
	};

	const segmentNameBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NAME });
	};

	const maxInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MAX_INFLOW, payload: e.target.value });
	};

	const maxInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MAX_INFLOW });
	};

	const accessYnChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_ACCESS_YN, payload: e.target.value });
	};

	const memberSearchValueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MEMBER_SEARCH_VALUE, payload: e.target.value });
	};

	const memberSearchInputFocusHandler = () => {
		setIsMemberSearchInputFocus(true);
	};

	const memberSearchInputBlurHandler = () => {
		setIsMemberSearchInputFocus(false);
	};

	const vwrChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setSegmentFormData({ type: UPDATE_VWR_ID, payload: e.target.value });
	};

	// description: API
	const updateSegmentFormSubmitHandler = async () => {
		// e.preventDefault();

		dispatch(
			putPathControlSegmentOnPremThunk({
				segmentId: id,
				segmentName,
				vwrPageId: vwrId,
				accessYn,
				maxInflow,
				endUri,
				startUri,
				members: selectedMemberList.concat(disabledMemberList),
			})
		);
	};

	useEffect(() => {
		if (error && error?.errorCode === 'S1007') {
			setSegmentFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'modal.segTitleErrorMsg1' });
		}

		if (error && error?.errorCode === 'S1008') {
			setSegmentFormData({ type: UPDATE_START_URI_HELP_TEXT, payload: 'modal.addUrlErrorMsg3' });
		}

		if (error && error?.errorCode === 'S1016') {
			setSegmentFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'modal.segTitleErrorMsg1' });
			setSegmentFormData({ type: UPDATE_START_URI_HELP_TEXT, payload: 'modal.addUrlErrorMsg3' });
		}

		if (error && error?.errorCode === 'S1012') {
			setSegmentFormData({ type: UPDATE_END_URI_HELP_TEXT, payload: 'modal.duplicatedURL' });
			setSegmentFormData({ type: UPDATE_START_URI_HELP_TEXT, payload: 'modal.duplicatedURL' });
		}
	}, [error]);

	useEffect(() => {
		setSegmentFormData({
			type: INITIALIZE_FORM,
			initial: {
				...initialSegmentForm,
				segmentName: initialSegmentName,
				maxInflow: initialMaxInflow.toString(),
				startUri: initialStartUri,
				endUri: initialEndUri,
				accessYn: initialAccessYn,
				vwrId: vwrPageId,
				mainDomain: mainDomain || '',
			},
		});
	}, [initialSegmentName, initialMaxInflow, initialStartUri, initialEndUri, initialAccessYn, vwrPageId, mainDomain]);

	useEffect(() => {
		const searchTimeout = setTimeout(() => {
			getOperatorList({ tenantId: tenantId as string, organizationId, searchValue: memberSearchValue });
		}, 200);

		return () => {
			clearTimeout(searchTimeout);
		};
	}, [memberSearchValue, tenantId, organizationId, getOperatorList, isUpdateModalVisible, isMemberSearchInputFocus]);

	useEffect(() => {
		if (getOperatorListResponse) {
			const memberList = getOperatorListResponse.data.map(el => {
				return {
					...el,
					imageUrl: el.imageUrl,
				};
			});
			setSegmentFormData({
				type: UPDATE_SEARCHED_MEMBER_LIST,
				memberList,
				payload: memberSearchValue,
			});
		}
	}, [getOperatorListResponse, memberSearchValue]);

	useEffect(() => {
		if (isUpdateModalVisible) {
			getSelectedOperatorListRequest({ segmentId: id, tenantId: tenantId as string, organizationId });
		}
	}, [isUpdateModalVisible, getSelectedOperatorListRequest, id, tenantId, organizationId]);

	useEffect(() => {
		if (getSelectedOperatorListResponse) {
			const isSelectedOperatorListEmpty = getSelectedOperatorListResponse.data.length < 1;

			const isUserSelectedOperator =
				!isSelectedOperatorListEmpty &&
				getSelectedOperatorListResponse.data.map(el => el.userKey).includes(sessionStorage.getItem(USER_KEY) || '');

			setIsSelectedOperatorListIncludeUser(isUserSelectedOperator);

			setSegmentFormData({
				type: UPDATE_SELECTED_MEMBER_LIST,
				memberList: getSelectedOperatorListResponse.data
					.filter(el => el.state === 'ACTIVE')
					.map(el => {
						return {
							...el,
							imageUrl: el.imageUrl,
						};
					}),
			});
		}
	}, [getSelectedOperatorListResponse]);

	// URL 중복 검사
	const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(false);
	const [duplList, setDuplList] = useState<PostSegmentDuplicateApiResponseData[]>([]);

	const clickSegmentConfirmBtn = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await postSegmentDuplicateApi({
			tenantId: tenantId ?? '',
			organizationId,
			prjId: selectedProjectId,
			body: { url: startUri, segmentId: id },
		})
			.then(res => {
				if (res.data.length > 0) {
					setDuplList(res.data);
					setIsShowDuplicateModal(true);
				} else {
					updateSegmentFormSubmitHandler();
				}
			})
			.catch(err => console.error(err));
	};

	const clickUrlDuplModalConfirmBtn = () => {
		updateSegmentFormSubmitHandler();
		setIsShowDuplicateModal(false);
	};

	const clickUrlDuplModalCancelBtn = () => {
		setIsShowDuplicateModal(false);
	};

	return (
		<Portal id="side-modal">
			<SideModal
				visible={isUpdateModalVisible}
				onClickOverlay={isLoading ? undefined : closeIconClickHandler}
				containerCss={css`
					display: flex !important;
					flex-direction: column;
				`}
				speed={0.2}
				// onSubmit={updateSegmentFormSubmitHandler}
				onSubmit={clickSegmentConfirmBtn}
			>
				<div css={titleContainer}>
					<div css={titleSt}>{t('modal.intervalSegmentUpdateModalTitle')}</div>
					<CloseIcon onClick={isLoading ? undefined : closeIconClickHandler} />
				</div>
				<div css={inputContainer}>
					<Textinput
						name={t('commonWords.segTitle')}
						size="small"
						width="100%"
						label={
							<span>
								{t('commonWords.segTitle')}
								<Astrix />
							</span>
						}
						placeholder={t('commonWords.segTitle')}
						value={segmentName}
						maxLength={MAX_SEG_TITLE_LENGTH}
						validation={isSegmentNameChanged ? segmentNameValidation : 'none'}
						helpText={isSegmentNameChanged && !isSegmentNameValid && t(segmentNameHelpText)}
						onChange={segmentNameChangeHandler}
						onBlur={segmentNameBlurHandler}
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<Textinput
						readOnly
						name={t('commonWords.segKey')}
						size="small"
						width="100%"
						label={<span>{t('commonWords.segKey')}</span>}
						placeholder={t('commonWords.segKey')}
						value={segmentKey}
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<Radio
						groupName={t('projectMgmt.access')}
						groupLabel={
							<div css={accessModeCss}>
								{t('projectMgmt.access')}
								<TooltipPortal
									width="16px"
									height="16px"
									position="top"
									message={t('modal.blockPrjDescr')}
									top={224}
									right={language === 'ENGLISH' ? 115 : 122}
								/>
							</div>
						}
						radioList={[
							{
								id: `${isClassic ? 'classic' : 'default'}-${id}-access-interval`,
								name: t('modal.segmentUnblock'),
								value: 'true',
								tabIndex: isUpdateModalVisible ? 0 : -1,
							},
							{
								id: `${isClassic ? 'classic' : 'default'}-${id}-block-interval`,
								name: t('modal.segmentBlock'),
								value: 'false',
								tabIndex: isUpdateModalVisible ? 0 : -1,
							},
						]}
						direction="horizontal"
						selectedValue={accessYn.toString()}
						onChange={accessYnChangeHandler}
						labelStackDirection="horizontal"
					/>
					<Textinput
						name={t('projectMonitoring.limitedInflow')}
						size="small"
						width="100%"
						label={
							<span>
								{t('projectMonitoring.limitedInflow')}
								<Astrix />
							</span>
						}
						value={maxInflow}
						max={99999999}
						min={0}
						step={1}
						validation={isMaxInflowChanged ? maxInflowValidation : 'none'}
						helpText={isMaxInflowChanged && !isMaxInflowValid && t(maxInflowHelpText)}
						valueType="number"
						onChange={maxInflowChangeHandler}
						onBlur={maxInflowBlurHandler}
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<div
						css={[
							gap16px,
							css`
								position: relative;
							`,
						]}
					>
						<Textinput
							name="member"
							label={t('modal.segmentMember')}
							placeholder={t('modal.memberInputPlaceholder')}
							value={memberSearchValue}
							icon={
								isMemberSearchInputFocus ? (
									<CloseIcon
										fill={paletteSDS.gray['700']}
										size="xSmall"
										onMouseDown={e => e.preventDefault()}
										onClick={() => {
											setSegmentFormData({ type: UPDATE_MEMBER_SEARCH_VALUE, payload: '' });
										}}
									/>
								) : (
									<SearchIcon fill={paletteSDS.gray['700']} size="xSmall" cursor="default" />
								)
							}
							showIcon
							size="small"
							onChange={memberSearchValueChangeHandler}
							onFocus={memberSearchInputFocusHandler}
							onBlur={memberSearchInputBlurHandler}
							iconPosition={isMemberSearchInputFocus ? 'right' : 'left'}
							hideIconWhenFocus={false}
							disabled={userRole !== 'OWNER' && userRole !== 'ADMINISTRATOR'}
							tabIndex={isUpdateModalVisible ? 0 : -1}
						/>
						{selectedMemberList.length > 0 ? (
							<div css={selectedMemberContainer}>
								{selectedMemberList.map(el => (
									<div key={el.userKey} css={selectedMemberItemBox}>
										<div css={selectedMemberItem}>
											<Avatar
												imgUrl={el.imageUrl === DEFAULT_IMG_URL ? undefined : el.imageUrl}
												placeholder={el.id.charAt(0).toUpperCase()}
												width="16px"
												height="16px"
											/>
											<div>{el.name}</div>
										</div>
										{userRole === 'ADMINISTRATOR' || userRole === 'OWNER' ? (
											<CloseIcon
												size="xSmall"
												onClick={() => setSegmentFormData({ type: DELETE_MEMBER, member: { ...el } })}
												fill={paletteSDS.gray['700']}
											/>
										) : null}
									</div>
								))}
							</div>
						) : null}
						{isMemberSearchInputFocus ? (
							<div css={searchedMemberContainer} onMouseDown={e => e.preventDefault()} aria-hidden>
								{searchedMemberList.filter(el => el.state === 'ACTIVE').length > 0 ? (
									searchedMemberList
										.filter(el => el.state === 'ACTIVE')
										.map((item, index) => (
											<div
												key={item.userKey}
												css={searchedMemberItemBox(index, searchedMemberList.length)}
												aria-hidden
												onClick={() => {
													setSegmentFormData({ type: ADD_MEMBER, member: { ...item } });
												}}
											>
												<Avatar
													width="16px"
													height="16px"
													imgUrl={item.imageUrl === DEFAULT_IMG_URL ? undefined : item.imageUrl}
													placeholder={item.id.charAt(0).toUpperCase()}
												/>
												<div>{item.name}</div>
											</div>
										))
								) : (
									<div css={noResultSt}>{t('modal.noResult')}</div>
								)}
							</div>
						) : null}
					</div>
					<Dropdown
						menu={vwrPage.map(el =>
							el.defaultYn ? { name: t('vwr.default'), value: el.id } : { name: el.name, value: el.id }
						)}
						bindedValue={vwrId}
						onChange={vwrChangeHandler}
						label={t('modal.vwrDropdownLabel')}
						placeholder={t('modal.vwrDropdownPlaceholder')}
						size="small"
						tabIndex={isUpdateModalVisible ? 0 : -1}
					/>
					<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
					<div css={gap20px}>
						<div css={uriBox}>
							<Label>{t('modal.urlSubTitle')}</Label>
							<div css={uriInputBox}>
								<div css={tooltipBox}>
									<span css={uriInputLabel}>
										{t('modal.startURL')}
										<Astrix />
									</span>
									<TooltipPortal
										position="right"
										message={t('modal.startURLTooltip')}
										top={622}
										right={language === 'ENGLISH' ? 20 : 110}
									/>
								</div>
								<Textinput
									size="small"
									width="240px"
									maxLength={253}
									value={startUri}
									validation={isStartUriChanged ? startUriValidation : 'none'}
									helpText={isStartUriChanged && !isStartUriValid && t(startUriHelpText)}
									onChange={startUriChangeHandler}
									onBlur={startUriBlurHandler}
									tabIndex={isUpdateModalVisible ? 0 : -1}
								/>
							</div>
							<div css={uriInputBox}>
								<div css={tooltipBox}>
									<span css={uriInputLabel}>
										{t('modal.endURL')}
										<Astrix />
									</span>
									<TooltipPortal
										position="right"
										message={t('modal.endURLTooltip')}
										top={661}
										right={language === 'ENGLISH' ? 30 : 110}
									/>
								</div>
								<Textinput
									size="small"
									width="240px"
									maxLength={253}
									value={endUri}
									validation={isEndUriChanged ? endUriValidation : 'none'}
									helpText={isEndUriChanged && !isEndUriValid && t(endUriHelpText)}
									onChange={endUriChangeHandler}
									onBlur={endUriBlurHandler}
									tabIndex={isUpdateModalVisible ? 0 : -1}
								/>
							</div>
						</div>
						<div css={cautionWrap}>
							<InfoIcon fill={`${paletteSDS.blue[500]}`} />
							<div css={guideSt}>{t('modal.intervalMonitoringCautionURL')}</div>
						</div>
					</div>
				</div>
				<div css={buttonContainer}>
					<Button
						width="100%"
						disabled={
							(isMaxInflowChanged && !isMaxInflowValid) ||
							(isSegmentNameChanged && !isSegmentNameValid) ||
							(isStartUriChanged && !isStartUriValid) ||
							(isEndUriChanged && !isEndUriValid) ||
							isLoading ||
							(userRole === 'OPERATOR' && !isSelectedOperatorListIncludeUser)
						}
						buttonDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
						buttonColor={paletteSDS.blue['700']}
						buttonHoverColor={paletteSDS.blue['800']}
						labelColor={paletteSDS.gray['50']}
						mould="custom"
						tabIndex={isUpdateModalVisible ? 0 : -1}
					>
						{isLoading ? <Loader height="16px" /> : t('modal.segAddSave')}
					</Button>
				</div>
				<Portal id="modal">
					{isShowDuplicateModal && (
						<Modal
							visible
							isCloseIconVisible={false}
							title={t('modal.urlDuplicationTitle')}
							actionType="double"
							primaryBtnLabel={t('ServiceDomainSetting.confirm')}
							primaryBtnFunction={clickUrlDuplModalConfirmBtn}
							secondaryBtnLabel={t('ServiceDomainSetting.cancel')}
							secondaryBtnFunction={clickUrlDuplModalCancelBtn}
						>
							<div css={urlDuplContent}>
								<div css={urlDuplDesc}>{t('modal.urlDuplicationDescUpdate')}</div>
								<div css={urlDuplInfo}>
									<div css={urlDuplInfoTitle}>{t('modal.urlDuplicationInfo')}</div>
									<div css={urlDuplInfoList}>
										{duplList.map(el => {
											return (
												<div key={el.projectName + el.segmentName} css={urlDuplInfoRow}>
													<div css={urlDuplInfoItem}>
														<div css={urlDuplInfoItemKey}>{t('commonWords.prjTitle')}</div>
														<div css={urlDuplInfoItemValue}>{el.projectName}</div>
													</div>
													<div css={urlDuplInfoItem}>
														<div css={urlDuplInfoItemKey}>{t('commonWords.segTitle')}</div>
														<div css={urlDuplInfoItemValue}>{el.segmentName}</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</Modal>
					)}
				</Portal>
			</SideModal>
		</Portal>
	);
}

const titleContainer = css`
	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 64px;

	border-bottom: 1px solid ${paletteSDS.gray['300']};

	padding: 24px 16px 24px 16px;
`;

const titleSt = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['16']};
	color: ${paletteSDS.gray['900']};
`;

const inputContainer = css`
	flex: auto;

	display: flex;
	flex-direction: column;
	gap: 24px;

	padding: 24px;

	overflow: auto;
`;

const accessModeCss = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: ${paletteSDS.gray[600]};
`;

const buttonContainer = css`
	flex: none;
	display: flex;
	align-items: center;

	padding: 16px;

	border-top: 1px solid ${paletteSDS.gray['300']};
`;

const gap20px = css`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const uriBox = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const uriInputBox = css`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const uriInputLabel = css`
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray[700]};
`;

const tooltipBox = css`
	display: flex;
	gap: 6px;
	align-items: center;

	height: 2rem;
`;

const cautionWrap = css`
	display: flex;
	gap: 0.5rem;

	background: ${paletteSDS.blue[50]};

	border: 1px solid ${paletteSDS.blue[200]};
	border-radius: 8px;

	padding: 1rem;
`;

const guideSt = css`
	width: calc(100% - 2rem);

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray[900]};

	word-break: keep-all;
`;

const selectedMemberItem = css`
	display: flex;
	gap: 10px;
	align-items: center;

	color: ${paletteSDS.gray['900']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
`;

const selectedMemberContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	width: 100%;
	max-height: 72px;

	overflow: auto;
`;

const selectedMemberItemBox = css`
	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 24px;
`;

const searchedMemberContainer = css`
	position: absolute;

	top: 66px;

	display: flex;
	flex-direction: column;
	gap: 0;

	width: 100%;

	max-height: 202px;

	overflow: auto;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};

	border-radius: 8px;

	z-index: 2000;
`;

const searchedMemberItemBox = (index: number, length: number) => css`
	flex: none;

	display: flex;
	align-items: center;
	gap: 6px;

	width: 100%;
	height: 40px;

	padding: 11px 10px 11px 12px;

	border-bottom: ${index !== length - 1 && `1px solid ${paletteSDS.gray['400']}`};

	color: ${paletteSDS.gray['900']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};

	:hover {
		background: ${paletteSDS.gray['100']};
	}

	cursor: pointer;
`;

const noResultSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 40px;

	color: ${paletteSDS.gray['700']};

	font-size: ${themeSDS.fontSize['12']};
	font-weight: ${themeSDS.fontWeight.medium};

	background: ${paletteSDS.gray['50']};

	border-radius: 8px;
`;

const gap16px = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const urlDuplContent = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
	color: ${paletteSDS.gray[900]};
	width: 336px;
`;

const urlDuplDesc = css`
	font-size: 16px;
	font-weight: 400;
	line-height: 160%;
`;

const urlDuplInfo = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const urlDuplInfoTitle = css`
	font-size: 16px;
	font-weight: 500;
	line-height: 100%;
`;

const urlDuplInfoList = css`
	border-radius: 4px;
	border: 1px solid ${paletteSDS.gray[300]};
	width: 100%;
	max-height: 150px;
	background-color: ${paletteSDS.gray[75]};
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 18px;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		border: 6px solid transparent;
		border-radius: 100px;
		background-clip: padding-box;
		background-color: hsla(0, 0%, 0%, 0.48);
	}
`;

const urlDuplInfoRow = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 12px;
	:not(:last-of-type) {
		border-bottom: 1px solid ${paletteSDS.gray[200]};
	}
`;

const urlDuplInfoItem = css`
	display: flex;
`;

const urlDuplInfoItemKey = css`
	width: 110px;
	color: ${paletteSDS.gray[600]};
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: 0.16px;
`;

const urlDuplInfoItemValue = css`
	width: calc(100% - 110px);
	color: ${paletteSDS.gray[900]};
	font-size: 14px;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: 0.16px;
	white-space: pre-wrap;
`;

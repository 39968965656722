/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { paletteSDS, themeSDS } from 'surf-design-system';

interface PlanTitleProps {
	title: string;
}

/**
 * author: Finn
 * createdAt: 2022-07-11
 * updatedAt: 2022-08-02
 * description: 플랜 페이지 서브 타이틀 컴포넌트
 * todo:
 */
export default function PlanTitle({ title }: PlanTitleProps) {
	return <div css={titleSt}>{title}</div>;
}

const titleSt = css`
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	line-height: 128%;
	letter-spacing: -0.008em;
`;

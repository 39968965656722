import { createSlice } from '@reduxjs/toolkit';

import { postOrganizationPaymentInfoNonKoreanThunk } from 'features/plan/thunk/postOrganizationPaymentInfoNonKorean.thunk';
import { getOrganizationAddressThunk } from '../thunk/getOrganizationAddress.thunk';
import { postSubscriptionThunk } from '../thunk/postSubscription.thunk';
import { postOrganizationAddressThunk } from '../thunk/postOrganizationAddress.thunk';

export interface AddressInfoData {
	// eslint-disable-next-line
	error: any;
	isLoaded: boolean;
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
	isLoading: boolean;
}

const initialState: AddressInfoData = {
	country: '',
	streetAddress: '',
	streetAddressDetail: '',
	city: '',
	region: '',
	postalCode: '',
	vat: '',
	error: null,
	isLoaded: false,
	isLoading: false,
};

const addressInfoSlice = createSlice({
	name: 'addressInfo',
	initialState,
	reducers: {
		setInitialAddressInfo: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getOrganizationAddressThunk.fulfilled, (_, action) => {
			return {
				error: null,
				isLoaded: true,
				country: action.payload.country,
				streetAddress: action.payload.streetAddress,
				streetAddressDetail: action.payload.streetAddressDetail,
				city: action.payload.city,
				region: action.payload.region,
				postalCode: action.payload.postalCode,
				vat: action.payload.vat,
				isLoading: false,
			};
		});

		builder.addCase(getOrganizationAddressThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(postSubscriptionThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(postSubscriptionThunk.fulfilled, (state, action) => {
			return {
				...state,
				error: null,
				country: action.payload.country,
				streetAddress: action.payload.streetAddress,
				streetAddressDetail: action.payload.streetAddressDetail,
				city: action.payload.city,
				region: action.payload.region,
				postalCode: action.payload.postalCode,
				vat: action.payload.vat,
				isLoading: false,
			};
		});

		builder.addCase(postSubscriptionThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(postOrganizationAddressThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(postOrganizationAddressThunk.fulfilled, (state, action) => {
			return {
				...state,
				error: null,
				country: action.payload.country,
				streetAddress: action.payload.streetAddress,
				streetAddressDetail: action.payload.streetAddressDetail,
				city: action.payload.city,
				region: action.payload.region,
				postalCode: action.payload.postalCode,
				vat: action.payload.vat,
				isLoading: false,
			};
		});

		builder.addCase(postOrganizationAddressThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(postOrganizationPaymentInfoNonKoreanThunk.fulfilled, (state, action) => {
			return { ...state, error: null, isLoading: false, ...action.payload.address };
		});
	},
});

export const { setInitialAddressInfo } = addressInfoSlice.actions;

export default addressInfoSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putIntervalSegmentSettingApi from '../api/putIntervalSegmentSetting.api';

export interface PutSegmentSettingThunkResult {
	segmentName: string;
	accessYn: boolean;
	maxInflow: number;
	startUri: string;
	endUri: string;
	segmentId: number;
}

export interface PutSegmentSettingThunkProps {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: string;
	startUri: string;
	endUri: string;
	segmentId: number;
	onClose: () => void;
}

export const putIntervalSegmentSettingThunk = createAsyncThunk<
	PutSegmentSettingThunkResult,
	PutSegmentSettingThunkProps,
	{ state: RootState }
>('sectionSegment/setting/put', async (args, thnkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thnkAPI.getState();

	const { segmentColor, segmentName, accessYn, maxInflow, segmentId, endUri, startUri, onClose } = args;

	const putSegmentSettingApiBody = {
		segmentColor,
		segmentName,
		accessYn,
		maxInflow: Number(maxInflow),
		endUri,
		startUri,
	};

	try {
		await putIntervalSegmentSettingApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			body: putSegmentSettingApiBody,
			segId: segmentId,
		});

		onClose();
		return { segmentName, accessYn, maxInflow: Number(maxInflow), segmentId, endUri, startUri };

		// eslint-disable-next-line
	} catch (error: any) {
		return thnkAPI.rejectWithValue(error.response.data);
	}
});

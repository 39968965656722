/* eslint-disable no-nested-ternary */
/** @jsxRuntime classic */
/** @jsx jsx */

import React, { ReactNode, useState } from 'react';
import { jsx, css, SerializedStyles } from '@emotion/react';
import { Dropdown, DropdownElement, paletteSDS, themeSDS } from 'surf-design-system';

import TableElement, { TableElementData, TableElementStyle } from './TableElement';

export interface TableColumn extends TableElementStyle {
	id: number | string;
	label: ReactNode;
}

export interface TableRow {
	id: number | string;
	items: TableElementData[];
	rowCss?: SerializedStyles | SerializedStyles[];
}

export interface TableProps {
	columns: TableColumn[];
	rows: TableRow[];
	listScrollNumDropdownMenus?: DropdownElement[];
	defaultScrollNum?: number;
	onChangeScrollNum?: (value: number) => void;
	title?: string;
	columnCss?: SerializedStyles | SerializedStyles[];
	bodyText?: string;
	bodyHeight?: string;
	footer?: TableColumn[];
}

export default function Table({
	columns,
	rows,
	listScrollNumDropdownMenus,
	defaultScrollNum,
	onChangeScrollNum,
	title,
	columnCss = css``,
	bodyText,
	bodyHeight,
	footer,
}: TableProps) {
	const [selectedValue, setSelectedValue] = useState(defaultScrollNum);

	const dropdownChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = event => {
		setSelectedValue(Number(event.target.value));
		if (onChangeScrollNum) {
			onChangeScrollNum(Number(event.target.value));
		}
	};

	return (
		<div css={tableContainerSt}>
			{title || listScrollNumDropdownMenus ? (
				<div css={optionSt}>
					{listScrollNumDropdownMenus && (
						<Dropdown menu={listScrollNumDropdownMenus} bindedValue={selectedValue} onChange={dropdownChangeHandler} />
					)}
					{title && <div>{title}</div>}
				</div>
			) : null}
			<div css={tableSt}>
				<ul css={[theadSt(rows.length === 0, bodyHeight), columnCss]}>
					{columns.map((column, columnIndex) => {
						const {
							id,
							label,
							width,
							padding,
							fontWeight,
							fontSize,
							textAlign,
							color,
							height,
							backgroundColor,
							maxWidth,
							minWidth,
							elementCss,
						} = column;
						return (
							<li
								key={`column-${id}`}
								css={[
									columnSt(
										{
											width,
											padding,
											fontSize,
											fontWeight,
											color,
											textAlign,
											height,
											backgroundColor,
											maxWidth,
											minWidth,
											isStart: columnIndex === 0,
											isEnd: columns.length - 1 === columnIndex,
										},
										rows.length === 0,
										bodyHeight
									),
									elementCss,
								]}
							>
								{label}
							</li>
						);
					})}
				</ul>
				<div css={tbodySt(bodyHeight, rows.length === 0, bodyText)}>
					{rows.length === 0 && bodyHeight !== undefined
						? bodyText
						: rows.map((row, idx) => (
								<TableElement
									key={`row-${row.id}`}
									id={row.id}
									element={row.items}
									index={idx}
									rowsLength={rows.length}
									rowCss={row.rowCss}
								/>
						  ))}
				</div>
				{footer && (
					<div css={footerSt}>
						{footer.map((column, columnIndex) => {
							const {
								id,
								label,
								width,
								padding,
								fontWeight,
								fontSize,
								textAlign,
								color,
								height,
								backgroundColor,
								maxWidth,
								minWidth,
								elementCss,
							} = column;
							return (
								<li
									key={`column-${id}`}
									css={[
										footColumnSt({
											width,
											padding,
											fontSize,
											fontWeight,
											color,
											textAlign,
											height,
											backgroundColor,
											maxWidth,
											minWidth,
											isStart: columnIndex === 0,
											isEnd: columns.length - 1 === columnIndex,
										}),
										elementCss,
									]}
								>
									{label}
								</li>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}

const tableContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const optionSt = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const tableSt = css`
	/* width: fit-content; */

	border: 1px solid ${paletteSDS.gray[500]};
	border-radius: 4px;
	/* overflow: hidden; */
`;

const theadSt = (isRowsEmpty: boolean, bodyHeight: string | undefined) => css`
	display: flex;
	align-items: center;

	width: 100%;
	//height: 3rem;

	border-bottom: ${isRowsEmpty
		? bodyHeight
			? `1px solid ${paletteSDS.gray[500]}`
			: 'none'
		: `1px solid ${paletteSDS.gray[500]}`};
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-left-radius: ${isRowsEmpty ? (bodyHeight ? '' : '4px') : ''};
	border-bottom-right-radius: ${isRowsEmpty ? (bodyHeight ? '' : '4px') : ''};

	background-color: ${paletteSDS.gray[300]};
`;

const footerSt = css`
	display: flex;
	align-items: center;
	border-top: 1px solid ${paletteSDS.gray[500]};
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	width: 100%;
	background-color: ${paletteSDS.gray[300]};
	overflow: hidden;
`;

const columnSt = (
	{
		width,
		padding,
		textAlign,
		fontWeight,
		fontSize,
		color,
		height,
		backgroundColor,
		maxWidth,
		minWidth,
		isEnd,
		isStart,
	}: TableElementStyle & { isStart: boolean; isEnd: boolean },
	isRowEmpty: boolean,
	bodyHeight: string | undefined
) => css`
	display: flex;
	align-items: center;

	flex: auto;

	width: ${width};
	max-width: ${maxWidth};
	min-width: ${minWidth};
	height: ${height || '3rem'};
	padding: ${padding || '1rem'};

	color: ${color || paletteSDS.gray[900]};
	font-size: ${fontSize || themeSDS.fontSize[14]};
	font-weight: ${fontWeight || themeSDS.fontWeight.medium};
	text-align: ${textAlign || 'start'};
	background-color: ${backgroundColor || paletteSDS.gray[300]};

	border-top-left-radius: ${isStart && '4px'};
	border-top-right-radius: ${isEnd && '4px'};
	border-bottom-left-radius: ${isStart && isRowEmpty && !bodyHeight && '4px'};
	border-bottom-right-radius: ${isEnd && isRowEmpty && !bodyHeight && '4px'};
`;

const tbodySt = (bodyHeight: string | undefined, isRowsEmpty: boolean, bodyText: string | undefined) => css`
	display: flex;
	justify-content: ${bodyText !== undefined && isRowsEmpty ? 'center' : 'flex-start'};
	flex-direction: column;
	align-items: center;
	background-color: ${paletteSDS.white};

	width: 100%;
	height: ${bodyHeight !== undefined && bodyHeight};
	overflow-y: ${bodyHeight ? 'overlay' : 'visible'};

	::-webkit-scrollbar {
		width: 18px;
	}

	::-webkit-scrollbar-thumb {
		border: 6px solid transparent;
		border-radius: 100px;
		background-clip: padding-box;
		background-color: hsla(0, 0%, 0%, 0.48);
	}

	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
`;

const footColumnSt = ({
	width,
	padding,
	textAlign,
	fontWeight,
	fontSize,
	color,
	height,
	backgroundColor,
	maxWidth,
	minWidth,
	isEnd,
	isStart,
}: TableElementStyle & { isStart: boolean; isEnd: boolean }) => css`
	display: flex;
	align-items: center;

	flex: auto;

	width: ${width};
	max-width: ${maxWidth};
	min-width: ${minWidth};
	height: ${height || '3rem'};
	padding: ${padding || '1rem'};

	color: ${color || paletteSDS.gray[900]};
	font-size: ${fontSize || themeSDS.fontSize[14]};
	font-weight: ${fontWeight || themeSDS.fontWeight.medium};
	text-align: ${textAlign || 'start'};
	background-color: ${backgroundColor || paletteSDS.gray[300]};

	border-bottom-left-radius: ${isStart && '4px'};
	border-bottom-right-radius: ${isEnd && '4px'};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Button, Textinput } from 'surf-design-system';

import postResetPasswordApi from 'features/signin/api/postResetPassword.api';

import {
	initialFindPasswordForm,
	findPasswordFormReducer,
	UPDATE_EMAIL,
	VALIDATE_EMAIL,
	UPDATE_EMAIL_HELP_TEXT,
	UPDATE_RECAPTCHA,
} from '../context/findPassword.reducer';

import { MAX_EMAIL_CHAR_NUM } from '../data/constants';
import getReCaptChaResultApi from '../api/getReCaptChaResult.api';

export default function FindPasswordForm() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const [findPasswordFormData, setFindPasswordFormData] = useReducer(findPasswordFormReducer, initialFindPasswordForm);
	const [isLoading, setIsLoading] = useState(false);

	const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setFindPasswordFormData({ type: UPDATE_EMAIL, payload: e.target.value });
	};

	const emailBlurHandler = () => {
		setFindPasswordFormData({ type: VALIDATE_EMAIL });
	};

	const reCaptchaVerifyHandler = useCallback(async () => {
		if (executeRecaptcha) {
			const captchaToken = await executeRecaptcha();

			const result = await getReCaptChaResultApi({ captchaToken });

			if (result.success) {
				setFindPasswordFormData({ type: UPDATE_RECAPTCHA, payload: 'valid' });
				return;
			}
			setFindPasswordFormData({ type: UPDATE_RECAPTCHA });
		}
	}, [executeRecaptcha]);

	const findPasswordFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();

		postResetPasswordApi({
			email: findPasswordFormData.email,
			onFail: err => {
				setFindPasswordFormData({ type: UPDATE_EMAIL_HELP_TEXT, payload: err });
				setIsLoading(false);
			},
			onSuccess: () => {
				const encodedEmail = encodeURIComponent(findPasswordFormData.email);
				setIsLoading(false);
				navigate(`check_email/${encodedEmail}`);
			},
			onFailFindEmail: () => {
				setFindPasswordFormData({ type: UPDATE_EMAIL_HELP_TEXT, payload: 'forgotPassword.error.notFoundMsg' });
				setIsLoading(false);
			},
			onFailOauthAccount: () => {
				setFindPasswordFormData({ type: UPDATE_EMAIL_HELP_TEXT, payload: 'modal.noForgotPwdMsg' });
				setIsLoading(false);
			},
		});
	};

	useEffect(() => {
		reCaptchaVerifyHandler();
	}, [reCaptchaVerifyHandler]);

	return (
		<form css={formSt} onSubmit={findPasswordFormSubmitHandler}>
			<div css={buttonWrap}>
				<Textinput
					width="100%"
					label={t('commonWords.email')}
					name="email"
					value={findPasswordFormData.email}
					onChange={emailChangeHandler}
					validation={findPasswordFormData.isEmailChanged ? findPasswordFormData.emailValidation : 'none'}
					helpText={
						findPasswordFormData.isEmailChanged &&
						findPasswordFormData.emailValidation === 'failed' &&
						t(findPasswordFormData.emailHelpText)
					}
					onBlur={emailBlurHandler}
					maxLength={MAX_EMAIL_CHAR_NUM}
				/>
			</div>
			<Button
				width="100%"
				type="submit"
				disabled={!findPasswordFormData.isEmailValid || !findPasswordFormData.isReCaptchaValid || isLoading}
			>
				{t('forgotPassword.sendEmail')}
			</Button>
		</form>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

const buttonWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

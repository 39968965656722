/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dracula, CodeBlock } from 'react-code-blocks';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { paletteSDS, themeSDS, InfoIcon, colorPalette } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { webAgentCodeBlock, webAgentExampleBlock } from 'common/utils/onPremCodeBlockFunc';
import DividerWithTxt from './DividerWithTxt';
import OnpremAgentGuideEnd from './OnpremAgentGuideEnd';

interface InstallWebAgentProps {
	titleFontSize?: string;
}

export default function OnPremInstallWebAgent({ titleFontSize = '18px' }: InstallWebAgentProps) {
	const { t } = useTranslation();

	const { tenantId } = useSelector((state: RootState) => state.account);

	const [isCopied, setIsCopied] = useState(false);

	const copyCode = webAgentCodeBlock(tenantId);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		}
	}, [isCopied]);

	return (
		<div css={gapColumn('24px')}>
			<div css={[titleSt(titleFontSize), { fontSize: '22px' }]}>{t('agent.applyTitle')}</div>
			<div css={gapColumn('16px')}>
				<div css={titleSt(titleFontSize)}>{t('agent.firstWebTitle')}</div>
				<div css={guideSt}>{t('agent.firstWebDesc')}</div>
				<div css={codeBlockBox}>
					<CopyToClipboard text={copyCode} onCopy={() => setIsCopied(true)}>
						<div css={copyButtonSt}>{t('agent.copy')}</div>
					</CopyToClipboard>
					{isCopied ? <div css={copiedSt}>{t('agent.copied')}</div> : null}
					<CodeBlock
						text={copyCode}
						theme={dracula}
						language="html"
						codeBlock
						wrapLines
						customStyle={{
							backgroundColor: '#1E1F29',
							borderRadius: '8px',
							padding: '24px 16px',
						}}
					/>
				</div>
			</div>
			<DividerWithTxt />
			<div css={gapColumn('16px')}>
				<div css={titleSt(titleFontSize)}>{t('agent.secondWebTitle')}</div>
				<div css={guideSt}>{t('agent.secondWebGuide')}</div>
				<div css={exampleCode}>
					{t('agent.codeExample')}
					<CodeBlock
						text={webAgentExampleBlock()}
						theme={dracula}
						language="html"
						codeBlock
						wrapLines
						customStyle={{
							backgroundColor: '#1E1F29',
							borderRadius: '8px',
							padding: '24px 16px',
						}}
					/>
				</div>
				<div css={noticeBox}>
					<InfoIcon
						fill={paletteSDS.blue['500']}
						emotionCss={css`
							flex: none;
						`}
					/>
					<div>
						<div css={noticeRow}>
							<div css={dot}>•</div>
							<div css={[guideSt, colorPalette.black]}>
								<p>{t('agent.webNoti1Title')}</p>
								<p>{t('agent.webNoti1Example')}</p>
							</div>
						</div>
						<br />
						<div css={noticeRow}>
							<div css={dot}>•</div>
							<div css={[guideSt, colorPalette.black]}>
								<span>{t('agent.webNoti2Desc')}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DividerWithTxt />
			<OnpremAgentGuideEnd />
		</div>
	);
}

const gapColumn = (gap: string) => css`
	display: flex;
	flex-direction: column;
	gap: ${gap};
`;

const titleSt = (titleFontSize: string) => css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${titleFontSize};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

const dot = css`
	width: 8px;
	text-align: center;
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};
	white-space: pre-line;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const noticeBox = css`
	display: flex;
	gap: 10px;
	width: 100%;
	padding: 12px 16px;
	white-space: pre;
	background: ${paletteSDS.blue[50]};
	border-radius: 8px;
	border: 1px solid ${paletteSDS.blue['200']};
`;

const noticeRow = css`
	display: flex;
	gap: 8px;
`;

const codeBlockBox = css`
	position: relative;
	height: fit-content;
	border-radius: 8px;
`;

const copyButtonSt = css`
	position: absolute;
	top: 0;
	right: 0;
	width: 82px;
	height: 26px;
	padding: 7px 16px;
	background: ${paletteSDS.gray['800']};
	text-align: center;
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};
	border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;
	cursor: pointer;
`;

const copiedSt = css`
	position: absolute;
	top: 0;
	right: 0;
	width: 82px;
	height: 26px;
	padding: 7px 16px;
	background: ${paletteSDS.blue['500']};
	text-align: center;
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};
	border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

const exampleCode = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: 1px solid ${paletteSDS.gray[400]};
	border-radius: 8px;
	width: 912px;
	padding: 16px 10px 10px 10px;
	background: ${paletteSDS.gray[100]};
	color: ${paletteSDS.gray[700]};
	font-size: 14px;
	font-weight: 700;
	line-height: 140%; /* 19.6px */
`;

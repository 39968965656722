import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { OrganizationData, userRoleType, userStatusType } from 'common/types/getOrganizationList.type';
import { tenantStatusType } from 'common/types/getOrganizationDetail.type';
import { languageType } from 'common/types/getUserProfile.type';
import { checkFreetrialEnd } from 'common/utils/dateFunc';
import { getOrganizationDetailThunk } from 'common/thunk/getOrganizationDetail.thunk';
import { PLAN_FREE_TRIAL_ID } from 'common/data/constants';
import { getAccountInfoThunk } from 'common/thunk/getAccountInfo.thunk';
import { getAccountInfoOnPremThunk } from 'common/thunk/getAccountInfo.onPrem.thunk';

import { putServiceDomainThunk } from 'features/nfBasic/thunk/putServiceDomain.thunk';
import { putUserNameThunk } from 'features/setting/thunk/putUserName.thunk';
import { postUserProfileImageThunk } from 'features/setting/thunk/postUserProfileImage.thunk';
import { putTimezoneThunk } from 'features/setting/thunk/putTimezone.thunk';
import { putOrganizationNameThunk } from 'features/setting/thunk/putOrganizationName.thunk';
import { putUserLanguageThunk } from 'features/setting/thunk/putUserLanguage.thunk';

import { getAccountInfoSwitchOrganizationThunk } from 'features/setting/thunk/getAccountInfoSwitchOrganization.thunk';

export interface AccountInfoData {
	isLoaded: boolean;
	isLoading: boolean;
	isPlanLoaded: boolean;
	// eslint-disable-next-line
	error: any;
	isLogin: boolean;
	isFreeTrial: boolean | null;
	organizationId: number;
	organizationName: string;
	userStatus: userStatusType;
	userRole: userRoleType;
	userName: string;
	userEmail: string;
	imageUrl: string;
	timeZone: string;
	language: languageType;
	createdAt: string;
	updatedAt: string;
	customerDomain: string | null;
	tenantUrl: string;
	region: string;
	freeTrialStartAt: string | null;
	freeTrialEndAt: string | null;
	tenantId: string | null;
	tenantStatus: tenantStatusType | null;
	tenantPort: number | null;
	remainDate: number;
	isFreetrialNotiVisible: boolean;
	isFreetrialEnd: boolean;
	expiredAt: string | null;
	organizationList: OrganizationData[];
	code: string;
	reason: string;
	country: string;
	userId: string;
}

const initialState: AccountInfoData = {
	isLogin: false,
	isLoading: false,
	isPlanLoaded: false,
	isFreeTrial: null,
	organizationId: 0,
	organizationName: '',
	userStatus: 'NONE',
	userRole: 'MONITOR',
	userName: '',
	userEmail: '',
	imageUrl: '',
	timeZone: '',
	language: 'ENGLISH',
	customerDomain: '',
	tenantUrl: '',
	region: '',
	freeTrialStartAt: null,
	freeTrialEndAt: null,
	tenantId: '',
	tenantStatus: 'PENDING',
	createdAt: '',
	updatedAt: '',
	tenantPort: 0,
	remainDate: 0,
	error: null,
	isLoaded: false,
	isFreetrialNotiVisible: false,
	isFreetrialEnd: false,
	expiredAt: null,
	organizationList: [],
	code: '',
	reason: '',
	country: '',
	userId: '',
};

const AccountInfoSlice = createSlice({
	name: 'accountInfo',
	initialState,
	reducers: {
		resetAccountInfos: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getAccountInfoThunk.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				isLogin: true,
				...action.payload,
				error: null,
				remainDate: moment(action.payload.freeTrialEndAt).diff(moment(), 'day') + 1,
				isFreetrialEnd: checkFreetrialEnd(action.payload.freeTrialEndAt || ''),
				reason: action.payload.reason,
				code: action.payload.code,
			};
		});

		builder.addCase(getAccountInfoOnPremThunk.fulfilled, (state, action) => {
			return { ...state, isLoaded: true, isLogin: true, error: null, ...action.payload };
		});

		builder.addCase(getAccountInfoOnPremThunk.rejected, (state, action) => {
			return { ...state, isLogin: false, error: action.payload, isLoaded: true };
		});

		builder.addCase(getOrganizationDetailThunk.fulfilled, (state, action) => {
			return { ...state, ...action.payload };
		});

		builder.addCase(getOrganizationDetailThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoaded: true };
		});

		builder.addCase(getAccountInfoSwitchOrganizationThunk.fulfilled, (state, action) => {
			return {
				isLoaded: true,
				isLogin: true,
				isPlanLoaded: true,
				...action.payload,
				error: null,
				remainDate: moment(action.payload.freeTrialEndAt).diff(moment(), 'day') + 1,
				isFreetrialNotiVisible: action.payload.freeTrialEndAt
					? !checkFreetrialEnd(action.payload.freeTrialEndAt) && action.payload.planId === PLAN_FREE_TRIAL_ID
					: false,
				isFreetrialEnd: checkFreetrialEnd(action.payload.freeTrialEndAt || ''),
				organizationList: [...state.organizationList],
				isLoading: false,
				country: 'korea',
				userId: '',
			};
		});

		builder.addCase(getAccountInfoThunk.rejected, (state, action) => {
			return { ...state, isLogin: false, error: action.payload, isLoaded: true };
		});

		builder.addCase(putServiceDomainThunk.fulfilled, (state, action) => {
			return { ...state, customerDomain: action.payload.customerDomain, error: null };
		});

		builder.addCase(putServiceDomainThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(putUserNameThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putUserNameThunk.fulfilled, (state, action) => {
			return { ...state, userName: action.payload.name, updatedAt: moment().format('YYYY-MM-DD'), isLoading: false };
		});

		builder.addCase(putUserNameThunk.rejected, state => {
			return { ...state, isLoading: false };
		});

		builder.addCase(postUserProfileImageThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(postUserProfileImageThunk.fulfilled, (state, action) => {
			return { ...state, imageUrl: action.payload.imageUrl, updatedAt: moment().format('YYYY-MM-DD') };
		});

		builder.addCase(postUserProfileImageThunk.rejected, state => {
			return { ...state, isLoading: false };
		});

		builder.addCase(putTimezoneThunk.fulfilled, (state, action) => {
			return { ...state, timeZone: action.payload.timezone };
		});

		builder.addCase(putOrganizationNameThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putOrganizationNameThunk.fulfilled, (state, action) => {
			return { ...state, organizationName: action.payload.organizationName, isLoading: false };
		});

		builder.addCase(putOrganizationNameThunk.rejected, state => {
			return { ...state, isLoading: false };
		});

		builder.addCase(putUserLanguageThunk.fulfilled, (state, action) => {
			return { ...state, language: action.payload.language };
		});
	},
});

export const { resetAccountInfos } = AccountInfoSlice.actions;

export default AccountInfoSlice.reducer;

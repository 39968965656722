/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { css, jsx } from '@emotion/react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, PaginationBasic, PaginationBasicClickHandler, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { NOTI_START_PAGE, NOTI_PAGE_QUERY } from 'common/data/constants';
import { getNotificationListThunk } from 'common/thunk/getNotificationList.thunk';
import NotificationContent from 'common/components/NotificationContent';
import { resetExpandedNotificationId } from 'common/context/notificationSlice';
import { putNotificationReadYnListThunk } from 'common/thunk/putNotificationReadYnList.thunk';

export default function Notification() {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	const { notifications, selectedPage, totalPages, isLoaded } = useSelector((state: RootState) => state.noti);

	const paginationChevronClickHandler = (increase: boolean) => {
		if (increase) {
			searchParams.set(NOTI_PAGE_QUERY, String(selectedPage + 1));
			setSearchParams(searchParams);
			return;
		}

		searchParams.set(NOTI_PAGE_QUERY, String(selectedPage - 1));
		setSearchParams(searchParams);
	};

	const paginationButtonClickHandler: PaginationBasicClickHandler = (e: React.MouseEvent, page: number) => {
		searchParams.set(NOTI_PAGE_QUERY, String(page));
		setSearchParams(searchParams);
	};

	const readAllButtonClickHandler = () => {
		dispatch(putNotificationReadYnListThunk({ notificationIds: notifications.map(el => el.id) }));
	};

	useEffect(() => {
		const page = searchParams.get(NOTI_PAGE_QUERY);
		if (page) {
			dispatch(getNotificationListThunk({ pageNum: Number(page) }));
			if (Number(page) === NOTI_START_PAGE) {
				dispatch(getNotificationListThunk({ pageNum: NOTI_START_PAGE }));
			}
		}
	}, [dispatch, searchParams, setSearchParams]);

	useEffect(() => {
		return () => {
			dispatch(resetExpandedNotificationId());
		};
	}, [dispatch]);

	if (!isLoaded) return <div css={containerSt} />;

	if (isLoaded && notifications.length === 0)
		return (
			<div css={containerSt}>
				<div css={titleSt}>{t('console.headerNoti.notification')}</div>
				<div css={noNotiSt}>{t('console.headerNoti.alertMsg')}</div>
			</div>
		);

	return (
		<div css={containerSt}>
			<div css={headerBox}>
				<div css={titleSt}>{t('console.headerNoti.notification')}</div>
				<Button mould="tertiary" onClick={readAllButtonClickHandler} size="small">
					{t('console.headerNoti.markRead')}
				</Button>
			</div>
			<div css={notificationContainer}>
				<div>
					{notifications.map((notification, idx, arr) => (
						<NotificationContent
							key={notification.id}
							notification={notification}
							notificationIdx={idx}
							notificationLength={arr.length}
						/>
					))}
				</div>
				{notifications.length !== 0 ? (
					<PaginationBasic
						totalPages={totalPages}
						selectedPage={selectedPage}
						onClickNext={() => paginationChevronClickHandler(true)}
						onClickPrev={() => paginationChevronClickHandler(false)}
						onClickSelectPage={paginationButtonClickHandler}
						backgroundColor="inherit"
					/>
				) : null}
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 960px;

	margin: 0 auto;

	padding: 32px 24px 80px;
`;

const noNotiSt = css`
	margin: 12.8125rem auto;

	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

const titleSt = css`
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 128%;

	height: 32px;
`;

const headerBox = css`
	display: flex;
	justify-content: space-between;
`;

const notificationContainer = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { paletteSDS, themeSDS } from 'surf-design-system';

import type { RootState } from 'common/redux/store';
import { useTranslation } from 'react-i18next';

export default function ProjectAccessMode() {
	const { t } = useTranslation();

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { accessYn } = projectList[selectedProjectIndex];

	return (
		<div css={container(accessYn)}>
			<div css={textSt(accessYn)}>{t('projectMonitoring.accessMode')}</div>
			<div css={textSt(accessYn)}>{t(accessYn ? 'projectMonitoring.unblock' : 'projectMonitoring.block')}</div>
		</div>
	);
}

const container = (accessYn: boolean) => css`
	display: flex;
	justify-content: space-between;

	width: 100%;
	height: 36px;
	padding: 8px 20px;

	background: ${accessYn ? paletteSDS.blue['50'] : paletteSDS.gray['600']};

	border: 1px solid ${accessYn ? paletteSDS.blue['200'] : paletteSDS.gray['700']};

	border-radius: 4px;
`;

const textSt = (accessYn: boolean) => css`
	color: ${accessYn ? paletteSDS.gray['900'] : paletteSDS.white};

	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};
	line-height: 140%;
`;

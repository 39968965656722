import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putSegmentSettingApi from '../api/putSegmentSetting.api';

import type { URIId, URI } from '../types/getSegmentList.type';

export interface PutSegmentSettingThunkResult {
	segmentName: string;
	accessYn: boolean;
	maxInflow: number;
	uris: URI[];
	segmentId: number;
}

export interface PutSegmentSettingThunkProps {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: string;
	deleteUriId: URIId[];
	addUriPath: string[];
	segmentId: number;
	onClose: () => void;
}

export const putSegmentSettingThunk = createAsyncThunk<
	PutSegmentSettingThunkResult,
	PutSegmentSettingThunkProps,
	{ state: RootState }
>('segment/setting/put', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentColor, segmentName, accessYn, maxInflow, deleteUriId, addUriPath, segmentId, onClose } = args;

	const putSegmentSettingApiBody = {
		segmentColor,
		segmentName,
		accessYn,
		maxInflow: Number(maxInflow),
		deleteUriId,
		addUriPath: addUriPath.map(el => ({ path: el })),
	};

	try {
		const {
			data: { uris },
		} = await putSegmentSettingApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			body: putSegmentSettingApiBody,
			segId: segmentId,
		});

		onClose();

		return { segmentName, accessYn, maxInflow: Number(maxInflow), uris, segmentId };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

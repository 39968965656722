/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import InvoiceItem from './InvoiceItem';

import { addNumberComma, priceUnitFormat } from '../utils/formatFunc';
import { GetPaymentChargeApiData } from '../types/getPaymentCharge.type';
/**
 * author: Finn
 * createdAt: 2022-09-22
 * updatedAt: 2022-09-22
 * description: 인보이스 리스트 컴포넌트
 * todo: 다국어 처리 및 조회 API 연결, map에 index 사용, 부가세 관련 데이터
 */

interface InvoiceListProps {
	paymentCharge: GetPaymentChargeApiData;
}
export default function InvoiceList({ paymentCharge }: InvoiceListProps) {
	const { t } = useTranslation();

	const priceUnit = priceUnitFormat(paymentCharge.chargeDetailModels[0].priceUnit);

	const isInvoiceItemExpand = paymentCharge.chargeDetailModels.length < 3;

	// 데이터 길이가 두 개 이하라면 자동으로 인보이스 상세리스트 펼치기

	return (
		<div css={containerSt}>
			<ul css={theadSt}>
				<li>{t('planSubscription.period')}</li>
				<li>{t('planSubscription.billDescr')}</li>
				<li>{t('planSubscription.quantity')}</li>
				<li>
					{t('planSubscription.price1', {
						unit: paymentCharge.chargeDetailModels[0].priceUnit,
					})}
				</li>
				<li>
					{t('planSubscription.amount', {
						unit: paymentCharge.chargeDetailModels[0].priceUnit,
					})}
				</li>
			</ul>
			{paymentCharge.chargeDetailModels.map(detail => (
				<InvoiceItem key={detail.startAt} invoiceDetail={detail} defaultIsItemDetailExpand={isInvoiceItemExpand} />
			))}
			<div css={listBottomSt}>
				<p>
					{t('planSubscription.subtotal')}
					{priceUnit}
					{addNumberComma(String(paymentCharge.totalCharge))}
				</p>
				<p>
					{t('planSubscription.vat1')}
					{priceUnit}
					{addNumberComma(String(paymentCharge.surtax))}
				</p>
				<p>
					{t('planSubscription.total')}
					{priceUnit}
					{addNumberComma(String(paymentCharge.totalCharge + paymentCharge.surtax))}
				</p>
			</div>
		</div>
	);
}

const containerSt = css`
	border: 1px solid ${paletteSDS.gray[500]};
	border-radius: 4px;
`;

const theadSt = css`
	display: flex;
	align-items: center;

	width: 100%;
	height: 2rem;

	border-bottom: 1px solid ${paletteSDS.gray[500]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.medium};

	color: ${paletteSDS.gray[900]};
	background-color: ${paletteSDS.gray[300]};

	> li {
		padding: 0.5rem 1rem;
	}

	> li:nth-of-type(1) {
		width: 10rem;
	}

	> li:nth-of-type(2) {
		width: 25rem;
	}

	> li:nth-of-type(3) {
		width: 7.5rem;
		text-align: right;
	}

	> li:nth-of-type(4) {
		width: 7.5rem;
		text-align: right;
	}

	> li:nth-of-type(5) {
		width: 10rem;
		text-align: right;
	}
`;

const listBottomSt = css`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	height: 7rem;

	text-align: right;
	font-size: 14px;
	background-color: ${paletteSDS.white};

	gap: 0.125rem;

	> p {
		height: 1.375rem;
		line-height: 160%;

		&:nth-of-type(3) {
			height: 2rem;

			font-size: 20px;
			font-weight: ${themeSDS.fontWeight.bold};
		}
	}
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { GetUsageSummaryApiResponse } from '../types/getUsageSummary.type';

export interface GetUsageSummaryApiProps {
	organizationId: number;
}

export default async function getUsageSummaryApi({ organizationId }: GetUsageSummaryApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.get<GetUsageSummaryApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/usage/invite-members`,
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { setInitialNotification } from 'common/context/notificationSlice';
import { LANG_PATH } from 'common/data/constants';
import { setInitialLayout, toggleIsProfileMenuVisible } from 'common/context/layoutSlice';
import {
	setInitialModalVisible,
	toggleIsPendingModalVisible,
	toggleIsTenantFailModalVisible,
} from 'common/context/modalSlice';
import { resetAccountInfos } from 'common/context/accountInfoSlice';
import { setInitialPrjMetaData } from 'common/context/projectMetaSlice';

const SaasProfileMenu = forwardRef<HTMLDivElement>((_, ref) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { isFreetrialNotiVisible, language, userRole, userName, organizationName, tenantStatus } = useSelector(
		(state: RootState) => state.account
	);

	const { isProfileMenuVisible } = useSelector((state: RootState) => state.layout);

	const { priceType } = useSelector((state: RootState) => state.plan);

	const personalSettingClickHandler = () => {
		dispatch(toggleIsProfileMenuVisible());
		navigate(`/${LANG_PATH[language]}/home/personal_setting/profile`);
	};

	const organizationSettingClickHandler = () => {
		dispatch(toggleIsProfileMenuVisible());

		if (tenantStatus === 'PENDING') {
			dispatch(toggleIsPendingModalVisible());
			return;
		}

		if (tenantStatus === 'FAILED') {
			dispatch(toggleIsTenantFailModalVisible());
			return;
		}

		navigate(`/${LANG_PATH[language]}/home/organization_setting/member`);
	};

	const usageClickHandler = () => {
		dispatch(toggleIsProfileMenuVisible());

		if (tenantStatus === 'PENDING') {
			dispatch(toggleIsPendingModalVisible());
			return;
		}

		if (tenantStatus === 'FAILED') {
			dispatch(toggleIsTenantFailModalVisible());
			return;
		}

		navigate(`/${LANG_PATH[language]}/home/usage`);
	};

	const planUsageClickHandler = () => {
		dispatch(toggleIsProfileMenuVisible());

		if (tenantStatus === 'PENDING') {
			dispatch(toggleIsPendingModalVisible());
			return;
		}

		if (tenantStatus === 'FAILED') {
			dispatch(toggleIsTenantFailModalVisible());
			return;
		}

		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan`);
	};

	const signoutClickHandler = () => {
		sessionStorage.clear();
		dispatch(setInitialLayout());
		dispatch(setInitialModalVisible());
		dispatch(resetAccountInfos());
		dispatch(setInitialNotification());
		dispatch(setInitialPrjMetaData());
		navigate(`/${LANG_PATH[language]}/signin`, { replace: true });
	};

	if (!isProfileMenuVisible) return null;

	return (
		<Portal id="profile-menu">
			<div css={container(isFreetrialNotiVisible)} ref={ref}>
				<div css={nameIncBox}>
					<div css={nameSt}>{userName}</div>
					<div css={incSt}>{organizationName} inc.</div>
				</div>
				<div css={menuBox}>
					<div css={menuSt} aria-hidden onClick={personalSettingClickHandler}>
						{t('console.sidebar.profile.personalSettings')}
					</div>
					{userRole === 'OWNER' && priceType === 'BYOL' ? (
						<React.Fragment>
							<div css={menuSt} aria-hidden onClick={organizationSettingClickHandler}>
								{t('console.sidebar.profile.organizationSettings')}
							</div>
							<div css={menuSt} aria-hidden onClick={usageClickHandler}>
								{t('breadCrumb.usage')}
							</div>
						</React.Fragment>
					) : null}
					{userRole === 'OWNER' && priceType !== 'BYOL' ? (
						<React.Fragment>
							<div css={menuSt} aria-hidden onClick={organizationSettingClickHandler}>
								{t('console.sidebar.profile.organizationSettings')}
							</div>
							<div css={menuSt} aria-hidden onClick={planUsageClickHandler}>
								{t('console.sidebar.profile.planAndUsages')}
							</div>
						</React.Fragment>
					) : null}
				</div>
				<div css={menuBox}>
					<div css={menuSt} aria-hidden onClick={signoutClickHandler}>
						{t('console.sidebar.profile.signOut')}
					</div>
				</div>
			</div>
		</Portal>
	);
});

export default SaasProfileMenu;

const container = (isFreetrialNotiVisible: boolean) => css`
	position: absolute;

	top: ${isFreetrialNotiVisible ? '120px' : '56px'};
	right: 8px;

	width: 192px;

	padding: 8px 0;

	border: 1px solid ${paletteSDS.gray['200']};

	border-radius: 4px;
	background-color: ${paletteSDS.gray['50']};

	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);

	z-index: 1000;
`;

const nameSt = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['900']};

	line-height: 140%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const incSt = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['12']};
	color: ${paletteSDS.gray['900']};

	line-height: 140%;
`;

const nameIncBox = css`
	width: 100%;
	height: fit-content;

	padding: 16px;
`;

const menuBox = css`
	width: 100%;
	height: fit-content;

	border-top: 1px solid ${paletteSDS.gray['200']};
`;

const menuSt = css`
	display: flex;
	align-items: center;

	height: 40px;

	padding: 16px;

	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['700']};

	cursor: pointer;

	:hover {
		background: ${paletteSDS.gray['100']};
	}
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { postProjectApi } from 'features/nfSetting/api/postProject.v2.api';
import { getProjectListThunk } from 'features/nfSetting/thunk/getProjectList.v2.thunk';

export interface PostProjectThunkProps {
	customerServiceDomain: string;
	projectName: string;
}

export interface PostProjectThunkResponse {
	projectId: number;
}

export const postProjectThunk = createAsyncThunk<PostProjectThunkResponse, PostProjectThunkProps, { state: RootState }>(
	'post/project/v2',
	async (args: PostProjectThunkProps, thunkAPI) => {
		const { projectName, customerServiceDomain } = args;

		const {
			account: { tenantId, organizationId },
		} = thunkAPI.getState();

		try {
			const { data } = await postProjectApi({
				customerServiceDomain,
				projectName,
				tenantId: tenantId as string,
				organizationId,
			});

			thunkAPI.dispatch(getProjectListThunk({}));

			return { projectId: data.id };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import { paletteSDS } from 'surf-design-system';

export interface ProgressbarProps {
	max?: string; // 프로그레스바의 최대값
	value?: string; // 현재 데이터 값
}

/**
 * author: Finn
 * createdAt: 2022-07-11
 * updatedAt: 2022-07-11
 * description: VWR Setting 미리보기 템플릿에 보여지는 프로그레스 바 컴포넌트
 * todo:
 */
export default function Progressbar({ max, value }: ProgressbarProps) {
	return <progress max={max} value={value} css={progressBarSt} />;
}

const progressBarSt = css`
	width: 21rem;
	height: 0.875rem;
	appearance: none;

	&::-webkit-progress-bar {
		border-radius: 10px;
		background-color: ${paletteSDS.gray[200]};
	}

	&::-webkit-progress-value {
		border-radius: 10px;
		background-color: ${paletteSDS.blue[500]};
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { paletteSDS } from 'surf-design-system';

import { SurffyLogoDark } from 'common/assets/icons';

export default function OnPremAuthHeader() {
	return (
		<div css={containerSt}>
			<SurffyLogoDark />
		</div>
	);
}

const containerSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	min-height: 4rem;

	border-bottom: 1px solid ${paletteSDS.gray[300]};
	background-color: ${paletteSDS.gray[50]};
`;

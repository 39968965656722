import { paletteSDS } from 'surf-design-system';
import { BOUNDARY_FAST, BOUNDARY_MED } from 'features/nfBasic/data/constants';
import { SpeedStatus } from 'features/nfBasic/types/funnelLib.type';

// func
export const getSpeedStatus = (waitTime: number): SpeedStatus => {
	if (waitTime < BOUNDARY_FAST) return 'FAST';
	if (waitTime < BOUNDARY_MED) return 'MEDIUM';
	return 'SLOW';
};

export const getWaveHeightSegment = (speedStatus: SpeedStatus, inflow: number): number => {
	if (inflow === 0) return 0;
	if (speedStatus === 'FAST') return 30;
	if (speedStatus === 'MEDIUM') return 55;
	return 80;
};

export const getWaveHeightProject = (speedStatus: SpeedStatus, inflow: number): number => {
	if (inflow === 0) return 0;
	if (speedStatus === 'FAST') return 20;
	if (speedStatus === 'MEDIUM') return 35;
	return 55;
};

export const getWaveColor = (speedStatus: SpeedStatus) => {
	if (speedStatus === 'FAST')
		return `linear-gradient(180deg, rgba(74, 112, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);`;
	if (speedStatus === 'MEDIUM')
		return 'linear-gradient(180deg, rgba(15, 181, 174, 1) 0%, rgba(255, 255, 255, 0.5) 100%);';
	return 'linear-gradient(180deg, rgba(246, 133, 17, 1) 0%, rgba(246, 133, 17, 0.6) 50%, rgba(255, 255, 255, 0.5) 100%);';
};

export const restrictTextPosSegment = (percent: number) => {
	if (percent >= 95) return 95;
	if (percent >= 60) return percent - 5;
	if (percent >= 5) return percent + 18;
	return 20;
};

export const restrictTextPosProject = (percent: number) => {
	if (percent >= 95) return 95;
	if (percent >= 60) return percent - 3;
	if (percent >= 5) return percent + 13;
	return 15;
};

export function createRandomNumber(min: number, max: number): number {
	if (min >= 0) return Math.floor(Math.random() * (max + 1 - min) + min);
	return Math.floor(Math.random() * (-min + max + 1) + min);
}

export const getPercentTextColor = (isHover: boolean, percent: number) => {
	if (isHover) return paletteSDS.systemColor.white;
	return percent < 60 ? 'black' : paletteSDS.systemColor.white;
};

export const getResourceUsageRate = (usedSize: number, limit: number) => {
	if (usedSize === 0) return 0;
	if (limit === 0) return 0;

	const percent = Number(((usedSize / limit) * 100).toFixed(0));

	if (Number.isNaN(percent)) return 0;
	return percent === 0 ? 1 : percent;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import type { OrganizationPlanData } from 'features/plan/types/getOrganizationPlan.type';
import getOrganizationPlanApi from 'features/plan/api/getOrganizationPlan.api';

export const getOrganizationPlanInfoThunk = createAsyncThunk<OrganizationPlanData, void, { state: RootState }>(
	'plan/get',
	async (_, thunkAPI) => {
		const {
			account: { organizationId },
		} = thunkAPI.getState();

		try {
			const { data } = await getOrganizationPlanApi({ organizationId });

			return { ...data };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

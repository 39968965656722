/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import React, { useReducer } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal, Textinput, Portal, paletteSDS, Loader } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { closeVWRModal, setVWRName } from 'common/context/modalSlice';

import {
	vwrNameFormReducer,
	UPDATE_NAME,
	VALIDATE_NAME,
	initialNameForm,
	UPDATE_NAME_HELP_TEXT,
} from 'features/vwr/context/vwrNameForm.reducer';
import { MAX_NAME_CHAR_NUM } from 'features/vwr/data/constants';
import { getVWRNameValidateThunk } from 'features/vwr/thunk/getVWRNameValidate.v2.thunk';

interface VWRNameModalProps {
	mode: 'create' | 'edit';
}

export default function VWRNameModal({ mode }: VWRNameModalProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { name } = useSelector((state: RootState) => state.modal.vwr);

	const { isLoading } = useSelector((state: RootState) => state.vwr);

	const [vwrNameFormData, setVWRNameFormData] = useReducer(vwrNameFormReducer, {
		...initialNameForm,
		enteredName: name,
	});

	const { enteredName, enteredNameHelpText, enteredNameValidation, isEnteredNameChanged, isEnteredNameValid } =
		vwrNameFormData;

	const nameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVWRNameFormData({ type: UPDATE_NAME, payload: e.target.value });
	};

	const nameBlurHandler = () => {
		setVWRNameFormData({ type: VALIDATE_NAME });
	};

	const secondaryButtonClickHandler = () => {
		dispatch(closeVWRModal());
	};

	const primaryButtonClickHandler = async () => {
		const validateAction = await dispatch(getVWRNameValidateThunk({ enteredName, mode }));

		const { isValidate, name: responseName } = unwrapResult(validateAction);

		if (isValidate) {
			dispatch(setVWRName({ name: responseName }));
			dispatch(closeVWRModal());
			return;
		}

		setVWRNameFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'vwr.duplicatedRoomName' });
	};

	return (
		<Portal>
			<Modal
				visible
				title={t('vwr.updateNameModalTitle')}
				isCloseIconVisible={false}
				actionType="double"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t('vwr.confirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('vwr.cancel')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				secondaryBtnDisable={isLoading}
				primaryBtnDisable={!isEnteredNameValid || isLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<Textinput
					label={t('vwr.vwrName')}
					helpText={isEnteredNameChanged && !isEnteredNameValid && t(enteredNameHelpText)}
					validation={isEnteredNameChanged ? enteredNameValidation : 'none'}
					showIcon
					maxLength={MAX_NAME_CHAR_NUM}
					value={enteredName}
					onChange={nameChangeHandler}
					onBlur={nameBlurHandler}
				/>
			</Modal>
		</Portal>
	);
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putBasicControlSegmentOrderApi from 'features/nfControl/api/putBasicControlSegmentOrder.v2.api';

export interface PutBasicControlSegmentOrderThunkResult {
	pickedCardIndex: number;
	droppedCardIndex: number;
}

export interface PutBasicControlSegmentOrderThunkProps {
	pickedCardIndex: number;
	droppedCardIndex: number;
}

const putBasicControlSegmentOrderThunk = createAsyncThunk<
	PutBasicControlSegmentOrderThunkResult,
	PutBasicControlSegmentOrderThunkProps,
	{ state: RootState }
>('basic/segment/order/put/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
		nfControl: {
			segment: {
				basic: { meta },
			},
		},
	} = thunkAPI.getState();

	const { pickedCardIndex, droppedCardIndex } = args;

	const putSegmentOrderApiBody = meta.map(el => {
		if (el.segmentOrder === pickedCardIndex + 1) {
			return {
				id: el.id,
				segmentKey: el.segmentKey,
				segmentOrder: droppedCardIndex + 1,
			};
		}

		if (el.segmentOrder === droppedCardIndex + 1) {
			return {
				id: el.id,
				segmentKey: el.segmentKey,
				segmentOrder: pickedCardIndex + 1,
			};
		}

		return {
			id: el.id,
			segmentKey: el.segmentKey,
			segmentOrder: el.segmentOrder,
		};
	});

	try {
		await putBasicControlSegmentOrderApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			order: putSegmentOrderApiBody,
		});

		return { pickedCardIndex, droppedCardIndex };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default putBasicControlSegmentOrderThunk;

import moment from 'moment';
import type { period } from 'features/plan/api/getProjectUsageDetail.api';
import type { UsageValue, GetProjectUsageDetailApiData } from 'features/plan/types/getProjectUsageDetail.type';

export const convertDataToChartSeries = (chartType: period, data: UsageValue[]) => {
	const today = moment()
		.utcOffset(process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? '+UTC09:00' : 0)
		.format('YYYY-MM-DD');
	const usageData = data.map(item => {
		return {
			date: moment(item.date)
				.utcOffset(process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? '+UTC09:00' : 0)
				.format('YYYY-MM-DD'),
			value: item.value,
		};
	});

	if (chartType === 'daily') {
		const daysInMonth = moment()
			.utcOffset(process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? '+UTC09:00' : 0)
			.daysInMonth();
		const results: number[] = Array(daysInMonth).fill(0, 0);

		usageData.forEach(item => {
			if (item.date.split('-')[0] === today.split('-')[0] && item.date.split('-')[1] === today.split('-')[1]) {
				results.splice(Number(item.date.split('-')[2]) - 1, 1, item.value);
			}
		});
		return results;
	}

	if (chartType === 'monthly') {
		const results: number[] = Array(12).fill(0, 0);

		usageData.forEach(item => {
			if (item.date.split('-')[0] === today.split('-')[0]) {
				results.splice(Number(item.date.split('-')[1]) - 1, 1, item.value);
			}
		});

		return results;
	}

	const yearlyData = usageData.map(item => item.value);
	const results: number[] = Array(7).fill(null, 0);

	if (yearlyData.length === 1) {
		results.splice(3, 1, ...yearlyData);
	} else if (yearlyData.length === 2) {
		results.splice(2, 2, ...yearlyData);
	} else if (yearlyData.length === 3) {
		results.splice(2, 3, ...yearlyData);
	} else if (yearlyData.length === 4) {
		results.splice(2, 4, ...yearlyData);
	} else {
		results.splice(1, 5, ...yearlyData.slice(-5));
	}

	return results;
};

export const convertNumToKMB = (value: number) => {
	if (value < 1000) {
		return String(value);
	}

	if (value < 1000000) {
		const valueDivideK = value / 1000;

		return Number.isInteger(valueDivideK) ? `${valueDivideK}K` : `${valueDivideK.toFixed(1)}K`;
	}

	if (value < 1000000000) {
		const valueDivideM = value / 1000000;

		return Number.isInteger(valueDivideM) ? `${valueDivideM}M` : `${valueDivideM.toFixed(1)}M`;
	}

	const valueDivideB = value / 1000000000;

	return Number.isInteger(valueDivideB) ? `${valueDivideB}B` : `${valueDivideB.toFixed(1)}B`;
};

export const barColumnWidth = (chartType: period) => {
	if (chartType === 'daily') {
		return '25%';
	}
	if (chartType === 'monthly') {
		return '15%';
	}
	return '8%';
};

export const usageChartCategories = (chartType: period, date: number) => {
	const currYear = moment()
		.utcOffset(process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? '+UTC09:00' : 0)
		.year();

	if (chartType === 'daily') {
		return Array.from({ length: date }, (_, idx) => idx + 1);
	}

	if (chartType === 'monthly') {
		return Array.from({ length: date }, (_, idx) => idx + 32);
	}
	const year: number[] = Array(7).fill(null, 0);

	if (date === 1) {
		year.splice(3, 1, currYear);
	} else if (date === 2) {
		year.splice(2, 2, currYear - 1, currYear);
	} else if (date === 3) {
		year.splice(2, 3, currYear - 2, currYear - 1, currYear);
	} else if (date === 4) {
		year.splice(2, 4, currYear - 3, currYear - 2, currYear - 1, currYear);
	} else {
		year.splice(1, 5, currYear - 4, currYear - 3, currYear - 2, currYear - 1, currYear);
	}
	return year;
};

export const convertGetProjectUsageDetailResponse = (
	dailyData: GetProjectUsageDetailApiData,
	monthlyData: GetProjectUsageDetailApiData,
	yearlyData: GetProjectUsageDetailApiData
) => {
	const convertedDailyBasicMonitoringActiveUsers = convertDataToChartSeries(
		'daily',
		dailyData.basicMonitoring.activeUsers
	);

	const convertedDailyBasicMonitoringWaitingUsers = convertDataToChartSeries(
		'daily',
		dailyData.basicMonitoring.waitingUsers
	);

	const convertedDailyBasicMonitoringConcurrentConnectionUsers = convertDataToChartSeries(
		'daily',
		dailyData.basicMonitoring.concurrentConnectionUsers
	);

	const convertedMonthlyBasicMonitoringActiveUsers = convertDataToChartSeries(
		'monthly',
		monthlyData.basicMonitoring.activeUsers
	);

	const convertedMonthlyBasicMonitoringWaitingUsers = convertDataToChartSeries(
		'monthly',
		monthlyData.basicMonitoring.waitingUsers
	);

	const convertedMonthlyBasicMonitoringConcurrentConnectionUsers = convertDataToChartSeries(
		'monthly',
		monthlyData.basicMonitoring.concurrentConnectionUsers
	);

	const convertedYearlyBasicMonitoringActiveUsers = convertDataToChartSeries(
		'yearly',
		yearlyData.basicMonitoring.activeUsers
	);

	const convertedYearlyBasicMonitoringWaitingUsers = convertDataToChartSeries(
		'yearly',
		yearlyData.basicMonitoring.waitingUsers
	);

	const convertedYearlyBasicMonitoringConcurrentConnectionUsers = convertDataToChartSeries(
		'yearly',
		yearlyData.basicMonitoring.concurrentConnectionUsers
	);

	const convertedDailyPathControlMonitoringActiveUsers = convertDataToChartSeries(
		'daily',
		dailyData.pathControl.activeUsers
	);

	const convertedDailyPathControlMonitoringWaitingUsers = convertDataToChartSeries(
		'daily',
		dailyData.pathControl.waitingUsers
	);

	const convertedDailyPathControlMonitoringConcurrentConnectionUsers = convertDataToChartSeries(
		'daily',
		dailyData.pathControl.concurrentConnectionUsers
	);

	const convertedMonthlyPathControlMonitoringActiveUsers = convertDataToChartSeries(
		'monthly',
		monthlyData.pathControl.activeUsers
	);

	const convertedMonthlyPathControlMonitoringWaitingUsers = convertDataToChartSeries(
		'monthly',
		monthlyData.pathControl.waitingUsers
	);

	const convertedMonthlyPathControlMonitoringConcurrentConnectionUsers = convertDataToChartSeries(
		'monthly',
		monthlyData.pathControl.concurrentConnectionUsers
	);

	const convertedYearlyPathControlMonitoringActiveUsers = convertDataToChartSeries(
		'yearly',
		yearlyData.pathControl.activeUsers
	);

	const convertedYearlyPathControlMonitoringWaitingUsers = convertDataToChartSeries(
		'yearly',
		yearlyData.pathControl.waitingUsers
	);

	const convertedYearlyPathControlMonitoringConcurrentConnectionUsers = convertDataToChartSeries(
		'yearly',
		yearlyData.pathControl.concurrentConnectionUsers
	);

	return {
		daily: {
			basicMonitoring: {
				activeUser: convertedDailyBasicMonitoringActiveUsers,
				waitingUser: convertedDailyBasicMonitoringWaitingUsers,
				connectingUser: convertedDailyBasicMonitoringConcurrentConnectionUsers,
				maxActiveUser: Math.max(Math.max(...convertedDailyBasicMonitoringActiveUsers), 50),
				maxWaitingUser: Math.max(Math.max(...convertedDailyBasicMonitoringWaitingUsers), 50),
				maxConnectingUser: Math.max(Math.max(...convertedDailyBasicMonitoringConcurrentConnectionUsers), 50),
			},
			pathControl: {
				activeUser: convertedDailyPathControlMonitoringActiveUsers,
				waitingUser: convertedDailyPathControlMonitoringWaitingUsers,
				connectingUser: convertedDailyPathControlMonitoringConcurrentConnectionUsers,
				maxActiveUser: Math.max(Math.max(...convertedDailyPathControlMonitoringActiveUsers), 50),
				maxWaitingUser: Math.max(Math.max(...convertedDailyPathControlMonitoringWaitingUsers), 50),
				maxConnectingUser: Math.max(Math.max(...convertedDailyPathControlMonitoringConcurrentConnectionUsers), 50),
			},
		},
		monthly: {
			basicMonitoring: {
				activeUser: convertedMonthlyBasicMonitoringActiveUsers,
				waitingUser: convertedMonthlyBasicMonitoringWaitingUsers,
				connectingUser: convertedMonthlyBasicMonitoringConcurrentConnectionUsers,
				maxActiveUser: Math.max(Math.max(...convertedMonthlyBasicMonitoringActiveUsers), 50),
				maxWaitingUser: Math.max(Math.max(...convertedMonthlyBasicMonitoringWaitingUsers), 50),
				maxConnectingUser: Math.max(Math.max(...convertedMonthlyBasicMonitoringConcurrentConnectionUsers), 50),
			},
			pathControl: {
				activeUser: convertedMonthlyPathControlMonitoringActiveUsers,
				waitingUser: convertedMonthlyPathControlMonitoringWaitingUsers,
				connectingUser: convertedMonthlyPathControlMonitoringConcurrentConnectionUsers,
				maxActiveUser: Math.max(Math.max(...convertedMonthlyPathControlMonitoringActiveUsers), 50),
				maxWaitingUser: Math.max(Math.max(...convertedMonthlyPathControlMonitoringWaitingUsers), 50),
				maxConnectingUser: Math.max(Math.max(...convertedMonthlyPathControlMonitoringConcurrentConnectionUsers), 50),
			},
		},
		yearly: {
			startYear: moment(yearlyData.basicMonitoring.activeUsers[0].date)
				.utcOffset(process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? '+UTC09:00' : 0)
				.year(),
			endYear: moment(yearlyData.basicMonitoring.activeUsers[yearlyData.basicMonitoring.activeUsers.length - 1].date)
				.utcOffset(process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? '+UTC09:00' : 0)
				.year(),
			basicMonitoring: {
				activeUser: convertedYearlyBasicMonitoringActiveUsers,
				waitingUser: convertedYearlyBasicMonitoringWaitingUsers,
				connectingUser: convertedYearlyBasicMonitoringConcurrentConnectionUsers,
				maxActiveUser: Math.max(Math.max(...convertedYearlyBasicMonitoringActiveUsers), 50),
				maxWaitingUser: Math.max(Math.max(...convertedYearlyBasicMonitoringWaitingUsers), 50),
				maxConnectingUser: Math.max(Math.max(...convertedYearlyBasicMonitoringConcurrentConnectionUsers), 50),
			},
			pathControl: {
				activeUser: convertedYearlyPathControlMonitoringActiveUsers,
				waitingUser: convertedYearlyPathControlMonitoringWaitingUsers,
				connectingUser: convertedYearlyPathControlMonitoringConcurrentConnectionUsers,
				maxActiveUser: Math.max(Math.max(...convertedYearlyPathControlMonitoringActiveUsers), 50),
				maxWaitingUser: Math.max(Math.max(...convertedYearlyPathControlMonitoringWaitingUsers), 50),
				maxConnectingUser: Math.max(Math.max(...convertedYearlyPathControlMonitoringConcurrentConnectionUsers), 50),
			},
		},
	};
};

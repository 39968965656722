import axios, { AxiosError } from 'axios';

import type { GetSubscriptionListApiResponse } from 'features/plan/types/getSubscriptionList.v2.type';
import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

interface GetSubscriptionListApiProps {
	organizationId: number;
}

export default async function getSubscriptionListApi({ organizationId }: GetSubscriptionListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetSubscriptionListApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v2/subscription/list`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import getOrganizationDataApi from 'common/api/getOrganizationDetail.api';
import getOrganizationListApi from 'common/api/getOrganizationList.api';
import getUserProfileApi from 'common/api/getUserProfile.v2.onPrem.api';

import { DEFAULT_IMG_URL } from 'common/data/constants';
import axiosInstance from 'common/utils/axiosInstance';
// import type { OrganizationData } from 'common/types/getOrganizationList.type';

export const getAccountInfoOnPremThunk = createAsyncThunk('account/onPrem/get', async (_, thunkAPI) => {
	try {
		const { data: organizationList } = await getOrganizationListApi();
		const initialOrganizationData = organizationList[0];
		const { id: initialOrganizationId } = initialOrganizationData;

		const { data: userProfileData } = await getUserProfileApi(initialOrganizationId);

		const {
			name: userName,
			id: userId,
			imageUrl,
			language,
			timezone: timeZone,
			createdAt,
			updatedAt,
		} = userProfileData;

		const { role: userRole, status: userStatus, name: organizationName } = organizationList[0];

		const { data: organizationDetail } = await getOrganizationDataApi(initialOrganizationId);

		const {
			defaultCustomerDomain: customerDomain,
			domainUrl: tenantUrl,
			tenantId,
			region,
			freeTrialYn: isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			status: tenantStatus,
			port: tenantPort,
			reason,
			code,
			country,
		} = organizationDetail;

		axiosInstance.defaults.headers.tenantId = tenantId;
		axiosInstance.defaults.headers.organizationId = initialOrganizationId;

		await i18next.changeLanguage(language === 'KOREAN' ? 'ko' : 'en');

		return {
			organizationId: initialOrganizationId,
			organizationName,
			userStatus,
			userRole: userRole[0].role,
			customerDomain,
			tenantUrl,
			tenantId,
			region,
			isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			tenantStatus,
			tenantPort,
			userName,
			imageUrl: imageUrl === DEFAULT_IMG_URL ? imageUrl : `${imageUrl}?${Date.now()}`,
			language,
			timeZone,
			createdAt,
			updatedAt,
			organizationList,
			reason,
			code,
			country: country || 'KR',
			userId,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (error.response?.data) {
			return thunkAPI.rejectWithValue(error.response.data);
		}

		return thunkAPI.rejectWithValue(error.message);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import moment from 'moment';

import { RootState, useAppDispatch } from 'common/redux/store';

import {
	paletteSDS,
	themeSDS,
	Button,
	ChevronLeftIcon,
	CalendarIcon,
	WaitTimeIcon,
	ChevronRightIcon,
	colorPalette,
	DatePicker,
	TimePicker,
} from 'surf-design-system';

import useOutboundClick from 'common/hooks/useOutboundClick';

import { postEUMStepDateThunk } from 'features/nfEUM/thunk/postEUMStepDate.thunk';
import { postEUMSelectDateThunk } from 'features/nfEUM/thunk/postEUMSelectDate.thunk';
import { postEUMIntervalStartThunk } from 'features/nfEUM/thunk/postEUMIntervalStart.thunk';
import { initializeState } from 'features/nfEUM/context/eumSlice';

export default function URLToolBar() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { selectedDate, isIntervalActivated, initialDate } = useSelector((state: RootState) => state.eum);

	const { timeZone } = useSelector((state: RootState) => state.account);

	const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
	const [isTimePickerVisible, setIsTimePickerVisible] = useState(false);

	const datePickerRef = useRef<HTMLDivElement>(null);
	const timePickerRef = useRef<HTMLDivElement>(null);
	const calendarIconRef = useRef<SVGSVGElement>(null);
	const waitTimeIconRef = useRef<SVGSVGElement>(null);
	const dateRef = useRef<HTMLDivElement>(null);
	const timeRef = useRef<HTMLDivElement>(null);

	const datePickerVisibleChangeHandler = () => {
		setIsDatePickerVisible(prevState => !prevState);
	};

	const timePickerVisibleChangeHandler = () => {
		setIsTimePickerVisible(prevState => !prevState);
	};

	const throttleDateIncrease = useMemo(
		() =>
			throttle(() => {
				dispatch(postEUMStepDateThunk({ isIncrease: true, isStepDay: true }));
			}, 300),
		[dispatch]
	);

	const dateIncreaseHandler = useCallback(() => {
		const nextDate = Math.floor(moment(selectedDate).utcOffset(timeZone.slice(3)).add(1, 'day').valueOf() / 100000);
		const initialMinuteDate = Math.floor(initialDate / 100000);

		if (nextDate < initialMinuteDate) {
			throttleDateIncrease();
			return;
		}

		if (nextDate === initialMinuteDate) {
			dispatch(initializeState(initialDate));
			dispatch(postEUMIntervalStartThunk());
		}
	}, [throttleDateIncrease, selectedDate, timeZone, initialDate, dispatch]);

	const throttleMinuteIncrease = useMemo(
		() =>
			throttle(() => {
				dispatch(postEUMStepDateThunk({ isIncrease: true, isStepDay: false }));
			}, 300),
		[dispatch]
	);

	const throttleMinuteDecrease = useMemo(
		() =>
			throttle(() => {
				dispatch(postEUMStepDateThunk({ isIncrease: false, isStepDay: false }));
			}, 300),
		[dispatch]
	);

	const minuteIncreaseHandler = useCallback(() => {
		const nextDate = Math.floor(moment(selectedDate).utcOffset(timeZone.slice(3)).add(1, 'minute').valueOf() / 100000);
		const initialMinuteDate = Math.floor(initialDate / 100000);

		if (nextDate < initialMinuteDate) {
			throttleMinuteIncrease();
			return;
		}

		if (!isIntervalActivated) {
			dispatch(initializeState(initialDate));
			dispatch(postEUMIntervalStartThunk());
		}
	}, [throttleMinuteIncrease, selectedDate, timeZone, initialDate, dispatch, isIntervalActivated]);

	const minuteDecreaseHandler = useCallback(() => {
		throttleMinuteDecrease();
	}, [throttleMinuteDecrease]);

	const throttleDateDecrease = useMemo(
		() =>
			throttle(() => {
				dispatch(postEUMStepDateThunk({ isStepDay: true, isIncrease: false }));
			}, 300),
		[dispatch]
	);

	const dateDecreaseHandler = useCallback(() => {
		throttleDateDecrease();
	}, [throttleDateDecrease]);

	const throttleChangeDate = useMemo(
		() =>
			throttle((date: number) => {
				dispatch(postEUMSelectDateThunk({ selectedDate: moment(date).startOf('minutes').valueOf() }));
			}),
		[dispatch]
	);

	const dateChangeHandler = useCallback(
		(dateValue: number) => {
			throttleChangeDate(dateValue);
		},
		[throttleChangeDate]
	);

	const todayButtonClickHandler = () => {
		dispatch(initializeState(moment().valueOf()));
		dispatch(postEUMIntervalStartThunk());
	};

	useOutboundClick(
		datePickerRef,
		() => {
			setIsDatePickerVisible(false);
		},
		[dateRef, calendarIconRef, timeRef, waitTimeIconRef]
	);

	useOutboundClick(
		timePickerRef,
		() => {
			setIsTimePickerVisible(false);
		},
		[timeRef, waitTimeIconRef, dateRef, calendarIconRef]
	);

	return (
		<div css={container}>
			<div css={titleSt}>{t('eum.urlStatus')}</div>
			<div css={datePickerBox}>
				<Button height="40px" mould="secondary" onClick={todayButtonClickHandler} disabled={isIntervalActivated}>
					{t('eum.now')}
				</Button>
				<div css={relativeSt}>
					<div css={customPickerBox}>
						<div css={iconBox}>
							<ChevronLeftIcon size="medium" fill={paletteSDS.blue['700']} onClick={dateDecreaseHandler} />
						</div>
						<div css={dateBoxSt}>
							<CalendarIcon
								size="small"
								fill={paletteSDS.gray['600']}
								onClick={datePickerVisibleChangeHandler}
								ref={calendarIconRef}
							/>
							<div onClick={datePickerVisibleChangeHandler} aria-hidden css={dateSt} ref={dateRef}>
								{moment(selectedDate).utcOffset(timeZone.slice(3)).format('yyyy.MM.DD')}
							</div>
						</div>
						<div css={iconBox}>
							<ChevronRightIcon
								size="medium"
								fill={
									Math.floor(moment(selectedDate).utcOffset(timeZone.slice(3)).add(1, 'day').valueOf() / 100000) <=
									Math.floor(initialDate / 100000)
										? paletteSDS.blue['700']
										: paletteSDS.gray['500']
								}
								cursor={
									Math.floor(moment(selectedDate).utcOffset(timeZone.slice(3)).add(1, 'day').valueOf() / 100000) <=
									Math.floor(initialDate / 100000)
										? 'pointer'
										: 'not-allowed'
								}
								onClick={dateIncreaseHandler}
							/>
						</div>
					</div>
					<DatePicker
						isDatePickerVisible={isDatePickerVisible}
						selectedDate={selectedDate}
						onChange={dateChangeHandler}
						ref={datePickerRef}
						width="220px"
						maxDate={initialDate}
						timeZone={timeZone}
						datePickerTop="50px"
						datePickerLeft="-3px"
					/>
				</div>
				<div css={relativeSt}>
					<div css={customTimepickerBox}>
						<div css={iconBox}>
							<ChevronLeftIcon size="medium" fill={paletteSDS.blue['700']} onClick={minuteDecreaseHandler} />
						</div>
						<WaitTimeIcon
							size="small"
							fill={!isIntervalActivated ? paletteSDS.gray['600'] : paletteSDS.blue['700']}
							onClick={timePickerVisibleChangeHandler}
							ref={waitTimeIconRef}
						/>
						<div
							onClick={timePickerVisibleChangeHandler}
							aria-hidden
							css={[timeSt, isIntervalActivated ? colorPalette.blue['700'] : colorPalette.gray['900']]}
							ref={timeRef}
						>
							{moment(selectedDate).utcOffset(timeZone.slice(3)).format('HH:mm')}
						</div>
						<div css={iconBox}>
							<ChevronRightIcon
								size="medium"
								fill={selectedDate !== initialDate ? paletteSDS.blue['700'] : paletteSDS.gray['500']}
								cursor={selectedDate !== initialDate ? 'pointer' : 'not-allowed'}
								onClick={minuteIncreaseHandler}
							/>
						</div>
						<div css={timezoneSt}>{timeZone}</div>
					</div>
					<TimePicker
						isTimePickerVisible={isTimePickerVisible}
						selectedDate={selectedDate}
						onChange={dateChangeHandler}
						is24HFormat
						ref={timePickerRef}
						maxDate={initialDate}
						timeZone={timeZone}
						timePickerTop="50px"
						timePickerLeft="24px"
					/>
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 40px;
`;

const titleSt = css`
	color: ${paletteSDS.black};
	font-size: ${themeSDS.fontSize['20']};
	font-weight: ${themeSDS.fontWeight.bold};
`;

const datePickerBox = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const dateSt = css`
	width: 86px;

	color: ${paletteSDS.gray['900']};
	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.bold};

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none;
`;

const timeSt = css`
	width: 45px;

	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.bold};

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none;
`;

const dateBoxSt = css`
	display: flex;
	align-items: center;
	gap: 8px;

	background: transparent;

	cursor: pointer;
`;

const iconBox = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;
`;

const customPickerBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 212px;
	height: 40px;

	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
`;

const customTimepickerBox = css`
	display: flex;
	align-items: center;

	align-items: center;
	justify-content: space-between;

	width: 165px;
	height: 40px;

	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
`;

const relativeSt = css`
	position: relative;
	z-index: 100;
`;

const timezoneSt = css`
	position: absolute;

	padding: 0 4px;

	bottom: 32px;
	left: 42px;

	color: ${paletteSDS.gray['600']};
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};

	background-color: ${paletteSDS.white};
`;

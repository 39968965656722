/** @jsxRuntime classic */
/** @jsx jsx */

import { useEffect } from 'react';
import { css, jsx } from '@emotion/react';

import { paletteSDS, themeSDS } from 'surf-design-system';

import maintenance from 'common/assets/images/maintenance.png';
import AuthHeader from 'common/components/AuthHeader';

export default function Maintenance() {
	useEffect(() => {
		const refreshInterval = setInterval(() => {
			window.location.reload();
		}, 20 * 60 * 1000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, []);

	return (
		<div css={container}>
			<AuthHeader />
			<div css={imageContentBox}>
				<img src={maintenance} alt="maintenance" css={imgSt} />
				<div css={commentButtonBox}>
					<div css={boldSt}>Maintenance Notice</div>
					<div css={commentBox}>
						<div css={mediumSt}>Due to maintenance, you will not be able to browse during the following time.</div>
						<div css={boldSmallSt}>Date: Wednesday, 4th January 2023</div>
						<div css={boldSmallSt}>Start Time: 20:00 (UTC+09:00)</div>
						<div css={boldSmallSt}>End Time: 22:30 (UTC+09:00)</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 102px;
	align-items: center;

	height: 100%;
	background-color: ${paletteSDS.gray['75']};
`;

const imgSt = css`
	width: fit-content;
	height: fit-content;
`;

const boldSt = css`
	font-size: ${themeSDS.fontSize[24]};
	color: ${paletteSDS.gray[900]};
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 120%;

	white-space: pre-line;
`;

const mediumSt = css`
	font-size: ${themeSDS.fontSize[16]};
	color: ${paletteSDS.gray[900]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;

	white-space: pre;
`;

const commentBox = css`
	display: flex;
	flex-direction: column;
	gap: 8px;

	text-align: center;

	white-space: pre-line;
`;

const commentButtonBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;

	align-items: center;
`;

const imageContentBox = css`
	display: flex;
	flex-direction: column;
	gap: 56px;
	align-items: center;
`;

const boldSmallSt = css`
	font-size: ${themeSDS.fontSize[16]};
	color: ${paletteSDS.gray[900]};
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 120%;

	white-space: pre-line;
`;

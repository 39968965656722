/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Textinput, Checkbox, Textarea, Button } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import FileUploadForm from 'features/vwr/components/FileUploadForm';
import Astrix from 'common/components/Astrix';
import { toggleIsVWRPutConfirmModalVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';
import { getProjectModeUrl } from 'common/utils/projectFunc';

import VWRPromptModal from 'features/vwr/components/VWRPromptModal';
import VWRConfirmModal from 'features/vwr/components/VWRConfirmModal';

import {
	changeFollowingWaitYn,
	changeGuideText,
	changeLogoImagePath,
	changeTitle,
	changeWaitCancelYn,
	setPreviewData,
	changeImagePath,
	ChangeFileProps,
	changeWaitTimeDisplayYn,
	setInitialCreateTitle,
} from 'features/vwr/context/vwrSlice.v2';

export default function VWRCreateForm() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isConfirmNavigate, setIsConfirmNavigate] = useState(false);

	const {
		form: {
			logoImagePathSrc,
			logoImagePathName,
			title,
			followingWaitYn,
			guideText,
			imagePathSrc,
			imagePathName,
			waitCancelYn,
			isFollowingWaitYnChanged,
			isGuideTextChanged,
			isImagePathChanged,
			isLogoImagePathChanged,
			isTitleChanged,
			isWaitCancelYnChanged,
			waitTimeDisplayYn,
			isWaitTimeDisplayYnChanged,
		},
	} = useSelector((state: RootState) => state.vwr2);

	const { language } = useSelector((state: RootState) => state.account);

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const formSubmitHandler: React.FormEventHandler = e => {
		e.preventDefault();

		dispatch(toggleIsVWRPutConfirmModalVisible());
	};

	const logoImageUploadHandler = ({ src, name }: ChangeFileProps) => {
		dispatch(changeLogoImagePath({ src, name }));
	};

	const imageUploadHandler = ({ src, name }: ChangeFileProps) => {
		dispatch(changeImagePath({ src, name }));
	};

	const titleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		dispatch(changeTitle(e.target.value));
	};

	const guideTextChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
		dispatch(changeGuideText(e.target.value));
	};

	const followingWaitYnChangeHandler = () => {
		dispatch(changeFollowingWaitYn(!followingWaitYn));
	};

	const waitCancelYnChangeHandler = () => {
		dispatch(changeWaitCancelYn(!waitCancelYn));
	};

	const waitTimeDisplayYnChangeHandler = () => {
		dispatch(changeWaitTimeDisplayYn(!waitTimeDisplayYn));
	};

	const previewButtonClickHandler = () => {
		let titleData = title;

		if (title === '' && language === 'ENGLISH') {
			titleData = 'Waiting Needed for Online Access';
		}

		if (title === '' && language === 'KOREAN') {
			titleData = '온라인 접속 대기 안내';
		}

		dispatch(
			setPreviewData({
				logoImagePathSrc,
				logoImagePathName,
				title: titleData,
				followingWaitYn,
				guideText,
				imagePathSrc,
				imagePathName,
				waitCancelYn,
				waitTimeDisplayYn,
			})
		);
	};

	const logoImageDeleteHandler = () => {
		dispatch(changeLogoImagePath({ src: '', name: '' }));
	};

	const imageDeleteHandler = () => {
		dispatch(changeImagePath({ src: '', name: '' }));
	};

	const fileUploadFailBySizeHandler = () => {
		// eslint-disable-next-line
		alert('Only under 500kb file can be uploaded.');
	};

	const fileUploadFailByTypeHandler = () => {
		// eslint-disable-next-line
		alert('Only jpg and png file can be uploaded.');
	};

	const postVWRSuccessHandler = () => {
		setIsConfirmNavigate(true);
		navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${selectedProjectId}/settings/vwr`);
	};

	useEffect(() => {
		dispatch(setInitialCreateTitle(t('projectMgmt.vwrPreviewDefaultTitle')));
	}, [dispatch, t]);

	return (
		<React.Fragment>
			<form css={previewFormBoxSt} onSubmit={formSubmitHandler}>
				<FileUploadForm
					id="logo"
					name="logo_img"
					label={t('projectMgmt.serviceLogo')}
					helpText={
						<React.Fragment>
							<p>{t('projectMgmt.serviceLogoMsg1')}</p>
							<p>{t('projectMgmt.serviceLogoMsg2')}</p>
						</React.Fragment>
					}
					fileName={logoImagePathName}
					isRequire
					onSuccess={logoImageUploadHandler}
					onDelete={logoImageDeleteHandler}
					onFailBySize={fileUploadFailBySizeHandler}
					onFailByType={fileUploadFailByTypeHandler}
				/>
				<div css={boxSt}>
					<Textinput
						label={
							<span>
								{t('projectMgmt.serviceTitle')}
								<Astrix />
							</span>
						}
						valueCount
						name="title"
						width="100%"
						maxLength={50}
						onChange={titleChangeHandler}
						value={title}
					/>
				</div>
				<div css={boxSt}>
					<Checkbox
						label={t('projectMgmt.vwrOption')}
						id="followingYn"
						name="followingYn"
						onChange={followingWaitYnChangeHandler}
						checked={followingWaitYn}
					/>
					<Checkbox
						label={t('projectMgmt.waitingTimeOption')}
						id="waitingTimeOption"
						name="waitingTimeOption"
						onChange={waitTimeDisplayYnChangeHandler}
						checked={waitTimeDisplayYn}
					/>
				</div>
				<div css={boxSt}>
					<Textarea
						label={t('projectMgmt.vwrDescription')}
						placeholder={t('projectMgmt.vwrDescrMsg')}
						onChange={guideTextChangeHandler}
						value={guideText.replaceAll('<br>', '\n') || ''}
						currentLength={guideText.trim().length}
						maxLength={250}
						lengthCheck
					/>
				</div>
				<FileUploadForm
					id="cover"
					name="cover_img"
					label={t('projectMgmt.coverImage')}
					helpText={t('projectMgmt.coverImageMsg')}
					fileName={imagePathName}
					onSuccess={imageUploadHandler}
					onDelete={imageDeleteHandler}
					onFailBySize={fileUploadFailBySizeHandler}
					onFailByType={fileUploadFailByTypeHandler}
				/>
				<div css={boxSt}>
					<Checkbox
						label={t('projectMgmt.exitqueueMsg')}
						id="waitCancelYn"
						name="waitCancelYn"
						onChange={waitCancelYnChangeHandler}
						checked={waitCancelYn}
					/>
				</div>
				<div css={buttonBoxSt}>
					<Button mould="secondary" onClick={previewButtonClickHandler} type="button" height="48px">
						{t('projectMgmt.preview')}
					</Button>
					<Button height="48px">{t('projectMgmt.submit')}</Button>
				</div>
			</form>
			<VWRConfirmModal isCreate onSuccessPost={postVWRSuccessHandler} />
			<VWRPromptModal
				when={
					isFollowingWaitYnChanged ||
					isGuideTextChanged ||
					isImagePathChanged ||
					isLogoImagePathChanged ||
					isTitleChanged ||
					isWaitCancelYnChanged ||
					isWaitTimeDisplayYnChanged
				}
				isConfirmNavigate={isConfirmNavigate}
			/>
		</React.Fragment>
	);
}

const previewFormBoxSt = css`
	flex: auto;
	max-width: 520px;
`;

const boxSt = css`
	display: flex;
	flex-direction: column;
	gap: 8px;

	margin-bottom: 1.5rem;
`;

const buttonBoxSt = css`
	display: flex;
	justify-content: flex-end;
	> button:nth-of-type(1) {
		margin-right: 0.5rem;
	}
`;

import { validationSDS } from 'surf-design-system';
import { rProjectName } from 'features/home/utils/regex';

export interface ProjectNameForm {
	projectName: string;
	isProjectNameValid: boolean;
	projectNameValidation: validationSDS;
	projectNameHelpText: string;
	isProjectNameChanged: boolean;
}

export interface ProjectNameFormAction {
	type?: string;
	payload?: string;
}

export const initialProjectNameForm: ProjectNameForm = {
	projectName: '',
	isProjectNameValid: false,
	projectNameValidation: 'failed',
	projectNameHelpText: '',
	isProjectNameChanged: false,
};

export const UPDATE_PROJECT_NAME = 'UPDATE_PROJECT_NAME';
export const VALIDATE_PROJECT_NAME = 'VALIDATE_PROJECT_NAME';
export const UPDATE_PROJECT_NAME_HELP_TEXT = 'UPDATE_PROJECT_NAME_HELP_TEXT';

export const projectNameFormReducer = (state: ProjectNameForm, action: ProjectNameFormAction): ProjectNameForm => {
	switch (action.type) {
		case UPDATE_PROJECT_NAME: {
			const isProjectNamePayloadNotEmpty = (action.payload as string).trim().length > 0;

			const isProjectNamePayloadFormatValid = rProjectName.test(action.payload as string);

			let helpText = '';

			if (!isProjectNamePayloadFormatValid) {
				helpText = 'console.nfHome.projectNameNotValid';
			}

			if (!isProjectNamePayloadNotEmpty) {
				helpText = 'console.nfHome.emptyProjectName';
			}

			return {
				...state,
				projectName: action.payload ? action.payload : '',
				isProjectNameValid: isProjectNamePayloadNotEmpty && isProjectNamePayloadFormatValid,
				projectNameValidation: isProjectNamePayloadNotEmpty && isProjectNamePayloadFormatValid ? 'none' : 'failed',
				isProjectNameChanged: true,
				projectNameHelpText: helpText,
			};
		}

		case VALIDATE_PROJECT_NAME: {
			const isProjectNameStateNotEmpty = state.projectName.trim().length > 0;

			const isProjectNameStateFormatValid = rProjectName.test(state.projectName);

			let helpText = '';

			if (!isProjectNameStateFormatValid) {
				helpText = 'console.nfHome.projectNameNotValid';
			}

			if (!isProjectNameStateNotEmpty) {
				helpText = 'console.nfHome.emptyProjectName';
			}

			const isProjectNameStateValid = isProjectNameStateNotEmpty && isProjectNameStateFormatValid;

			return {
				...state,
				isProjectNameValid: state.isProjectNameChanged ? isProjectNameStateValid : false,
				projectNameValidation: state.isProjectNameChanged && isProjectNameStateValid ? 'none' : 'failed',
				projectNameHelpText: helpText,
			};
		}

		case UPDATE_PROJECT_NAME_HELP_TEXT: {
			return {
				...state,
				projectNameValidation: 'failed',
				projectNameHelpText: action.payload ? action.payload : '',
				isProjectNameValid: false,
				isProjectNameChanged: true,
			};
		}

		default:
			return initialProjectNameForm;
	}
};

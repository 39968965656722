/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { RootState } from 'common/redux/store';
import { useSelector } from 'react-redux';
import { paletteSDS } from 'surf-design-system';

export default function TXNMapTimezone() {
	const { timeZone } = useSelector((state: RootState) => state.account);
	return <div css={container}>{timeZone}</div>;
}

const container = css`
	position: absolute;
	bottom: 8px;
	right: 8px;
	color: ${paletteSDS.gray[600]};
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.16px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';

import InvoiceFooter from 'features/plan/components/InvoiceFooter';
import PlanTitle from 'features/plan/components/PlanTitle';
import { makePdf } from 'features/plan/utils/pdfFunc';

import InvoiceInfo from 'features/plan/components/InvoiceInfo';
import InvoiceList from 'features/plan/components/InvoiceList';

export default function RealtimeChargeDetailPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { realtimeCharge, realtimeChargeDetail } = useSelector((state: RootState) => state.billing);

	const { language } = useSelector((state: RootState) => state.account);

	const downloadPdfClickHandler = () => {
		makePdf();
	};

	return (
		<div css={containerSt}>
			<header css={headerSt}>
				<PlanTitle title={t('planSubscription.billingHistory')} />
				<div css={buttonGroupSt}>
					<Button
						mould="secondary"
						size="medium"
						onClick={() => navigate(`/${LANG_PATH[language]}/console/plan_usage/billing_history`)}
					>
						{t('planSubscription.back')}
					</Button>
					<Button size="medium" onClick={downloadPdfClickHandler}>
						{t('planSubscription.downloadPdf')}
					</Button>
				</div>
			</header>
			<div id="printArea">
				<section css={sectionSt}>
					<InvoiceInfo paymentCharge={realtimeChargeDetail} paymentMethod={realtimeCharge.paymentMethod} />
					<InvoiceList paymentCharge={realtimeChargeDetail} />
				</section>
				<footer>
					<InvoiceFooter />
				</footer>
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;

	padding: 0 2rem;
	margin-bottom: 2rem;

	width: 60rem;
	height: 100%;
`;

const headerSt = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;

	> div:nth-of-type(1) {
		margin: auto 0;
	}
`;

const buttonGroupSt = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const sectionSt = css`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5rem;

	gap: 0.5rem;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import getTenantHealthCheckApi from 'common/api/getTenantHealthCheckList.api';
import type { RootState } from 'common/redux/store';
import { TenantHealthData } from 'common/types/getTenantHealthCheckList.type';

export interface GetTenantHealthCheckListThunkResult {
	tenantHealthList: TenantHealthData[];
}

export const getTenantHealthCheckListThunk = createAsyncThunk<
	GetTenantHealthCheckListThunkResult,
	void,
	{ state: RootState }
>('tenant/health-check/get', async (_, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();
	try {
		const { data } = await getTenantHealthCheckApi({
			tenantId: tenantId as string,
			organizationId,
		});

		return {
			tenantHealthList: data,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';

import SurffyStatus from 'features/home/components/SurffyStatus';

export default function OnPremSurffyHomeTenantFail() {
	const { t } = useTranslation();

	const { userName } = useSelector((state: RootState) => state.account);

	return (
		<div css={container}>
			<h3>{t('console.main.mainMsg', { name: userName })}</h3>
			<SurffyStatus />
			<DividerWithTxt />
			<div css={gap8pxColumn}>
				<h4 css={subTitle}>{t('console.main.failedDetail')}</h4>
				<div css={descriptionSt}>{t('onPrem.surffyHome.failGuide')}</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	padding: 32px 24px 80px;
`;

const descriptionSt = css`
	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize['14']};
	line-height: 140%;
`;

const gap8pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const subTitle = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['20']};
	line-height: 140%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { setIsNetfunnelControlsInitialToastVisible } from 'common/context/toastSlice';

import NetFunnelControlViewSwitch from 'features/nfControl/components/NetFunnelControlViewSwitch';
import { NF_CONTROL_INITIAL_TOAST } from 'features/nfControl/data/constants';
import { DEFAULT_PROJECT_NAME } from 'features/nfSetting/data/constants';

export default function NetfunnelBasicControlLayout() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const projectName = useSelector(
		(state: RootState) =>
			state.projectInfo.projectList.find(el => el.id === state.projectInfo.selectedProjectId)?.projectName
	);

	useEffect(() => {
		if (!localStorage.getItem(NF_CONTROL_INITIAL_TOAST) && projectName === DEFAULT_PROJECT_NAME) {
			dispatch(setIsNetfunnelControlsInitialToastVisible(true));
			localStorage.setItem(NF_CONTROL_INITIAL_TOAST, NF_CONTROL_INITIAL_TOAST);
		}
	}, [dispatch, projectName]);

	return (
		<div css={container}>
			<div css={headerBox}>
				<div css={titleSt}>{t('projectMonitoring.basicMonitoring')}</div>
				<NetFunnelControlViewSwitch />
			</div>
			<div css={outletBox}>
				<Outlet />
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	padding: 24px;
	height: fit-content;
`;

const headerBox = css`
	flex: none;

	display: flex;
	justify-content: space-between;

	height: 34px;
`;

const titleSt = css`
	height: 100%;

	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize[24]};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

const outletBox = css`
	flex: none;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import AltText from 'common/components/AltText';
import { ElementXY } from 'common/components/TableElement';
import { useState } from 'react';
import { paletteSDS } from 'surf-design-system';
import { dountChartColor } from 'features/nfEUM/data/constants';

interface DonutChartLegend {
	chartName: string;
	labels: string[];
	series: number[];
}

export default function DounutChartLegend({ chartName, labels, series }: DonutChartLegend) {
	const [hoverTextId, setHoverTextId] = useState(-1);
	const [hoverTextXY, setHoverTextXY] = useState<ElementXY>({ x: 0, y: 0 });

	const textMouseOverHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		const { x, y } = e.currentTarget.getBoundingClientRect();
		setHoverTextXY({ x, y });
	};

	return (
		<div css={container}>
			{labels.map((label, i) => {
				return (
					<div
						css={row}
						key={`${label}: ${series[i]}`}
						onFocus={() => {}}
						onBlur={() => {}}
						onMouseOver={e => {
							textMouseOverHandler(e);
							setHoverTextId(i);
						}}
						onMouseOut={() => {
							setHoverTextId(-1);
						}}
					>
						<div css={dot(dountChartColor[chartName as keyof typeof dountChartColor][i])} />
						<div css={labelCss}>{label}</div>
						<div css={seriesCss}>{series[i]}</div>
						{i === hoverTextId && label.length > 14 && (
							<AltText
								key={`${label}: ${series[i]}`}
								text={
									<div css={row} key={`${label}: ${series[i]}`}>
										<div css={dot(dountChartColor[chartName as keyof typeof dountChartColor][i])} />
										<div css={labelCss}>{label}</div>
										<div css={labelCss}>{series[i]}</div>
									</div>
								}
								textBoxMaxWidth="240px"
								x={hoverTextXY.x}
								y={hoverTextXY.y - 4}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 6px;
	height: 210px;
	color: ${paletteSDS.gray[700]};
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
`;

const row = css`
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: default;
`;

const dot = (color: string) => css`
	border-radius: 50%;
	width: 7.5px;
	height: 7.5px;
	background-color: ${color};
`;

const labelCss = css`
	font-weight: 400;
`;

const seriesCss = css`
	font-weight: 600;
`;

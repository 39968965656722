import moment from 'moment/moment';

import getLicenseUsageCSVApi, { GetLicenseUsageCSVApiProps } from 'features/license/api/getLicenseUsageCSV.api';

export const downloadCSVV2 = async ({
	organizationId,
	searchStartDate,
	searchEndDate,
	tenantId,
	projectId,
	pageNum,
}: GetLicenseUsageCSVApiProps) => {
	try {
		const data = await getLicenseUsageCSVApi({
			organizationId,
			tenantId,
			projectId,
			searchStartDate: Number(moment(searchStartDate).utcOffset('+UTC09:00').startOf('day').format('X')),
			searchEndDate: Number(moment(searchEndDate).utcOffset('+UTC09:00').endOf('day').format('X')),
			pageNum,
		});

		const csvFile = new Blob([`\ufeff${data}`], { type: 'text/csv; charset=utf-8' });

		const urlFromCSV = URL.createObjectURL(csvFile);
		const aTag = document.createElement('a');

		aTag.href = urlFromCSV;
		aTag.download = `netfunnel_license_usage_${moment(searchStartDate)
			.utcOffset('+UTC:0900')
			.format('YYYYMMDD')}_${moment(searchEndDate).utcOffset('+UTC:0900').format('YYYYMMDD')}.csv`;

		document.body.appendChild(aTag);

		aTag.click();

		setTimeout(() => {
			URL.revokeObjectURL(urlFromCSV);
		}, 60000);

		aTag.remove();
	} catch (e) {
		// eslint-disable-next-line
		alert('fail download file');
	}
};

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

interface PasswordTypeProps {
	passwordType: 'password' | 'text';
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function PasswordType({ passwordType, onClick }: PasswordTypeProps) {
	const { t } = useTranslation();

	return (
		<div css={pwValidationLabelCss}>
			<div>{t('commonWords.password')}</div>
			<button css={toggleButtonCss} tabIndex={-1} type="button" onClick={onClick}>
				{passwordType === 'password' ? t('signIn.showPwd') : t('signIn.hidePwd')}
			</button>
		</div>
	);
}

const pwValidationLabelCss = css`
	display: flex;
	width: 25rem;
	justify-content: space-between;
`;

const toggleButtonCss = css`
	justify-content: center;
	align-items: center;
	border: none;
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.blue[700]};
	background-color: transparent;
	cursor: pointer;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabElement, Tabs } from 'surf-design-system';

export default function OrganizationSettingLayout() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();

	const { pathname } = location;

	const [selectedTabId, setSelectedTabId] = useState<number | null>(null);

	const personalTabList: TabElement[] = [
		{
			id: 0,
			onClick: () => {
				navigate('member');
			},
			tabLabel: t('organizationSettings.mem'),
		},
		{
			id: 1,
			onClick: () => {
				navigate('organization');
			},
			tabLabel: t('breadCrumb.organizationSettings'),
		},
	];

	useEffect(() => {
		if (pathname.includes('member')) {
			setSelectedTabId(0);
		} else {
			setSelectedTabId(1);
		}
	}, [pathname]);

	return (
		<div css={containerSt}>
			<Tabs tabList={personalTabList} selectedTabId={selectedTabId} />
			<Outlet />
		</div>
	);
}

const containerSt = css`
	width: 960px;

	padding: 32px 24px 80px 24px;

	margin: 0 auto;
`;

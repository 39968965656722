/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

export default function ChangePasswordTitle() {
	const { t } = useTranslation();

	return <div css={titleSt}>{t('resetPassword.chooseNewPwd')}</div>;
}

const titleSt = css`
	text-align: center;
	letter-spacing: -0.008em;
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.gray[900]};
`;

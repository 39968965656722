import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import type { RootState } from 'common/redux/store';

import getProjectUsageSummaryApi from 'features/plan/api/getProjectUsageSummary.v2.api';

import type { GetProjectUsageSummaryData } from 'features/plan/types/getProjectUsageSummary.v2.type';

export const getProjectUsageSummaryThunk = createAsyncThunk<GetProjectUsageSummaryData, void, { state: RootState }>(
	'projectUsageSummary/get/v2',
	async (args, thunkAPI) => {
		const {
			account: { organizationId, tenantId },
			usage: { initialMomentValue, selectedProjectId },
		} = thunkAPI.getState();

		try {
			const { data } = await getProjectUsageSummaryApi({
				organizationId,
				date: Number(moment(initialMomentValue).format('X')),
				tenantId: tenantId as string,
				projectId: selectedProjectId,
			});

			return {
				...data,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { createRef, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Portal, Textinput, Radio } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Astrix from 'common/components/Astrix';
import { toggleIsPaymentFormModalVisible, toggleIsPaymentConfirmModalVisible } from 'common/context/modalSlice';

import { cardNumberFormat, cardExpDateFormat } from '../utils/formatFunc';

import {
	initialPaymentForm,
	paymentFormReducer,
	UPDATE_CARD_NUM,
	UPDATE_BIRTH_DATE,
	UPDATE_BUSINESS_NUMBER,
	UPDATE_EXP_DATE,
	UPDATE_PASSWORD,
	UPDATE_CARD_TYPE,
	VALIDATE_BIRTH_DATE,
	VALIDATE_BUSINESS_NUMBER,
	VALIDATE_CARD_NUM,
	VALIDATE_EXP_DATE,
	VALIDATE_PASSWORD,
} from '../context/paymentForm.reducer';

import { postOrganizationPaymentInfoThunk } from '../thunk/postOrganizationPaymentInfo.thunk';

import PaymentConfirmModal from './PaymentConfirmModal';
import PaymentErrorModal from './PaymentErrorModal';

let cardPasswordTimeout: NodeJS.Timeout | undefined;
let birthDateTimeout: NodeJS.Timeout | undefined;

export default function PaymentFormModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPaymentFormModalVisible } = useSelector((state: RootState) => state.modal);

	const { name } = useSelector((state: RootState) => state.payment);

	const [paymentFormData, setPaymentFormData] = useReducer(paymentFormReducer, initialPaymentForm);

	const birthDateInputRef = createRef<HTMLInputElement>();
	const passwordInputRef = createRef<HTMLInputElement>();

	const {
		cardExpDate,
		cardExpDateHelpText,
		cardExpDateValidation,
		cardNumber,
		cardNumberHelpText,
		cardNumberValidation,
		cardPassword,
		cardPasswordHelpText,
		cardPasswordValidation,
		cardType,
		birthDate,
		birthDateHelpText,
		birthDateValidation,
		businessNumber,
		businessNumberHelpText,
		businessNumberValidation,
		isBirthDateChanged,
		isBirthDateValid,
		isBusinessNumberChanged,
		isBusinessNumberValid,
		isCardExpDateChanged,
		isCardExpDateValid,
		isCardNumberChanged,
		isCardNumberValid,
		isCardPasswordChanged,
		isCardPasswordValid,
	} = paymentFormData;

	const closeIconClickHandler = () => {
		dispatch(toggleIsPaymentFormModalVisible());
		setPaymentFormData({ type: 'reset' });
	};

	const cardTypeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPaymentFormData({ type: UPDATE_CARD_TYPE, payload: e.target.value });
	};

	const cardNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPaymentFormData({ type: UPDATE_CARD_NUM, payload: cardNumberFormat(e.target.value) });
	};

	const cardNumberBlurHandler = () => {
		setPaymentFormData({ type: VALIDATE_CARD_NUM });
	};

	const cardExpDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPaymentFormData({ type: UPDATE_EXP_DATE, payload: cardExpDateFormat(e.target.value) });
	};

	const cardExpDateBlurHandler = () => {
		setPaymentFormData({ type: VALIDATE_EXP_DATE });
	};

	const birthDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPaymentFormData({ type: UPDATE_BIRTH_DATE, payload: e.target.value });
	};

	const birthDateBlurHandler = () => {
		setPaymentFormData({ type: VALIDATE_BIRTH_DATE });

		birthDateInputRef.current?.setAttribute('readonly', '');
	};

	const businessNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPaymentFormData({ type: UPDATE_BUSINESS_NUMBER, payload: e.target.value });
	};

	const businessNumberBlurHandler = () => {
		setPaymentFormData({ type: VALIDATE_BUSINESS_NUMBER });
	};

	const cardPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setPaymentFormData({ type: UPDATE_PASSWORD, payload: e.target.value });
	};

	const cardPasswordBlurHandler = () => {
		setPaymentFormData({ type: VALIDATE_PASSWORD });
		passwordInputRef.current?.setAttribute('readonly', '');
	};

	const paymentFormSubmitHandler: React.FormEventHandler = async e => {
		e.preventDefault();

		dispatch(toggleIsPaymentFormModalVisible());

		if (name === '') {
			dispatch(
				postOrganizationPaymentInfoThunk({
					cardNumber,
					cardPassword,
					cardType,
					birthDate,
					businessNumber,
					expirateDate: cardExpDate,
				})
			);
			setPaymentFormData({ type: 'reset' });
			return;
		}

		dispatch(toggleIsPaymentConfirmModalVisible());
	};

	const paymentConfirmModalPrimaryButtonClickHandler = () => {
		dispatch(
			postOrganizationPaymentInfoThunk({
				cardNumber,
				cardPassword,
				cardType,
				birthDate,
				businessNumber,
				expirateDate: cardExpDate,
			})
		);

		dispatch(toggleIsPaymentConfirmModalVisible());
		setPaymentFormData({ type: 'reset' });
	};

	useEffect(() => {
		return () => {
			clearTimeout(cardPasswordTimeout);
			clearTimeout(birthDateTimeout);
		};
	}, []);

	return (
		<React.Fragment>
			<Portal>
				<Modal
					visible={isPaymentFormModalVisible}
					actionType="none"
					title={t('planSubscription.addpymtMethod')}
					isCloseIconVisible
					onClickCloseButton={closeIconClickHandler}
					top="10%"
					width="414px"
				>
					<form css={formSt} onSubmit={paymentFormSubmitHandler} autoComplete="new-password">
						<div className="inputbox" css={inputBoxSt}>
							<Radio
								groupName="cardType"
								radioList={[
									{
										id: 'private',
										name: t('modal.personalCc'),
										value: 'private',
									},
									{
										id: 'corp',
										name: t('modal.corporationCc'),
										value: 'corp',
									},
								]}
								direction="horizontal"
								defaultValue="private"
								onChange={cardTypeChangeHandler}
							/>
							<Textinput
								label={
									<div css={labelSt}>
										<p>{t('modal.cardNum')}</p>
										<Astrix />
									</div>
								}
								size="small"
								value={cardNumberFormat(cardNumber)}
								validation={isCardNumberChanged ? cardNumberValidation : 'none'}
								helpText={isCardNumberChanged && !isCardNumberValid && t(cardNumberHelpText)}
								onChange={cardNumberChangeHandler}
								onBlur={cardNumberBlurHandler}
								maxLength={20}
								autoComplete="new-password"
							/>
							<Textinput
								label={
									<div css={labelSt}>
										<p>{t('modal.cardExpDate')}</p>
										<Astrix />
									</div>
								}
								size="small"
								value={cardExpDateFormat(cardExpDate)}
								validation={isCardExpDateChanged ? cardExpDateValidation : 'none'}
								helpText={isCardExpDateChanged && !isCardExpDateValid && t(cardExpDateHelpText)}
								onChange={cardExpDateChangeHandler}
								onBlur={cardExpDateBlurHandler}
								placeholder="YYYY-MM"
								maxLength={7}
								autoComplete="new-password"
							/>
							<Textinput
								label={
									<div css={labelSt}>
										<p>{t('modal.cardHolderDoB')}</p>
										<Astrix />
									</div>
								}
								size="small"
								value={birthDate}
								validation={isBirthDateChanged ? birthDateValidation : 'none'}
								helpText={cardType === 'private' && isBirthDateChanged && !isBirthDateValid && t(birthDateHelpText)}
								onChange={birthDateChangeHandler}
								onBlur={birthDateBlurHandler}
								maxLength={6}
								placeholder="YYMMDD"
								disabled={cardType === 'corp'}
								autoComplete="one-time-code"
								ref={birthDateInputRef}
								onFocus={() => {
									birthDateTimeout = setTimeout(() => {
										birthDateInputRef.current?.removeAttribute('readonly');
									}, 100);
								}}
								isReadOnlyAttribute
							/>
							<Textinput
								label={
									<div css={labelSt}>
										<p>{t('modal.BusinessRegNum')}</p>
										<Astrix />
									</div>
								}
								size="small"
								value={businessNumber}
								validation={isBusinessNumberChanged ? businessNumberValidation : 'none'}
								helpText={
									cardType === 'corp' && isBusinessNumberChanged && !isBusinessNumberValid && t(businessNumberHelpText)
								}
								onChange={businessNumberChangeHandler}
								onBlur={businessNumberBlurHandler}
								disabled={cardType === 'private'}
								maxLength={10}
								autoComplete="new-password"
							/>
							<Textinput
								label={
									<div css={labelSt}>
										<p>{t('modal.twoDigitPin')}</p>
										<Astrix />
									</div>
								}
								size="small"
								value={cardPassword}
								validation={isCardPasswordChanged ? cardPasswordValidation : 'none'}
								helpText={isCardPasswordChanged && !isCardPasswordValid && t(cardPasswordHelpText)}
								valueType="password"
								onChange={cardPasswordChangeHandler}
								onBlur={cardPasswordBlurHandler}
								maxLength={2}
								ref={passwordInputRef}
								onFocus={() => {
									cardPasswordTimeout = setTimeout(() => {
										passwordInputRef.current?.removeAttribute('readonly');
									}, 100);
								}}
								autoComplete="one-time-code"
								isReadOnlyAttribute
							/>
						</div>
						<div css={footerSt}>
							<Button
								width="100%"
								disabled={
									(cardType === 'private' && !isBirthDateValid) ||
									(cardType === 'corp' && !isBusinessNumberValid) ||
									!isCardExpDateValid ||
									!isCardNumberValid ||
									!isCardPasswordValid
								}
							>
								<li>{t('modal.addPymtSave')}</li>
							</Button>
							<ul css={guideSt}>
								<li>{t('modal.addPymtMethodMsg5')}</li>
								<li>{`${t('modal.addPymtMethodMsg3')}\n     ${t('modal.addPymtMethodMsg4')}`}</li>
								<li>{t('planSubscription.changePymtMethodMsg')}</li>
								<li>{t('modal.addPymtMethodMsg1')}</li>
								<li>{t('modal.addPymtMethodMsg2')}</li>
							</ul>
						</div>
					</form>
				</Modal>
			</Portal>
			<PaymentConfirmModal onClickPrimaryButton={paymentConfirmModalPrimaryButtonClickHandler} />
			<PaymentErrorModal />
		</React.Fragment>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const inputBoxSt = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const labelSt = css`
	display: flex;
`;

const footerSt = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const guideSt = css`
	line-height: 160%;
	font-size: 13px;

	> li {
		list-style: inside;
		white-space: pre-line;
	}
`;

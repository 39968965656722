/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

interface NetfunnelProjectName {
	projectName: string;
	activatedYn: boolean;
}

export default function NetfunnelProjectName({ projectName, activatedYn }: NetfunnelProjectName) {
	const { t } = useTranslation();

	return (
		<div css={container}>
			<div css={nameSt}>{projectName}</div>
			<div css={activateSt(activatedYn)}>{t(activatedYn ? 'console.nfHome.active' : 'console.nfHome.inactive')}</div>
		</div>
	);
}

const container = css`
	width: 100%;

	display: flex;
	align-items: center;
	gap: 8px;

	cursor: pointer;
`;

const nameSt = css`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	color: ${paletteSDS.blue['900']};
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize['14']};

	line-height: 128%;
`;

const activateSt = (activatedYn: boolean) => css`
	flex: none;

	display: flex;
	align-items: center;

	height: 20px;

	padding: 4px 6px;

	background: ${paletteSDS.gray['50']};
	border: 1px solid ${activatedYn ? paletteSDS.green['500'] : paletteSDS.gray['500']};
	border-radius: 4px;

	color: ${activatedYn ? paletteSDS.green['500'] : paletteSDS.gray['500']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['12']};
`;

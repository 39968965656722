/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'common/redux/store';

import LicenseDetailCard from 'features/license/components/LicenseDetailCard';
import { getLicenseDetailThunk } from 'features/license/thunk/getLicenseDetail.thunk';

export default function LicenseDetail() {
	const dispatch = useAppDispatch();

	const { licenseId } = useParams();

	useEffect(() => {
		dispatch(getLicenseDetailThunk({ licenseId: Number(licenseId) }));
	}, [dispatch, licenseId]);

	return <LicenseDetailCard />;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';

import { TooltipPointerIcon } from 'common/assets/icons';

import { HelpIcon, themeSDS, paletteSDS } from 'surf-design-system';

export interface TooltipProps {
	width?: string;
	height?: string;
	children?: React.ReactNode;
	disabled?: boolean;
	message?: React.ReactNode;
	position?: 'left' | 'right' | 'top' | 'bottom';
	helpIconFillColor?: string;
	isHelpIconVisible?: boolean;
	tooltipBoxGap?: number;
}

export default function Tooltip({
	width = '1rem',
	height = '1rem',
	disabled = false,
	message = 'Tip Message',
	helpIconFillColor = paletteSDS.blue[700],
	position = 'right',
	children,
	isHelpIconVisible = true,
	tooltipBoxGap = 0,
}: TooltipProps) {
	const [isHovered, setIsHovered] = useState(false);
	const handleHover = () => {
		if (!disabled) setIsHovered(prev => !prev);
	};

	return (
		<div css={containerCss({ isHovered, width, height })} onMouseEnter={handleHover} onMouseLeave={handleHover}>
			{children}
			{isHelpIconVisible ? <HelpIcon size="xSmall" fill={disabled ? paletteSDS.gray[300] : helpIconFillColor} /> : null}
			{!disabled && isHovered && (
				<div css={[tooltipBoxCss, positions({ position, tooltipBoxGap })]}>
					<div css={tooltipPointerCss({ position })}>
						<TooltipPointerIcon />
					</div>
					<div css={tooltipMsgCss}>{message}</div>
				</div>
			)}
		</div>
	);
}

export interface TooltipStProps {
	isHovered?: boolean;
	width?: string;
	height?: string;
	position?: 'left' | 'right' | 'top' | 'bottom';
	tooltipBoxGap?: number;
}

const containerCss = ({ isHovered, width, height }: TooltipStProps) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: ${width};
	height: ${height};

	svg {
		fill: ${isHovered && paletteSDS.blue[800]};
	}
`;

const tooltipBoxCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 100%;
	z-index: 10;
`;

const positions = ({ position, tooltipBoxGap }: TooltipStProps) => {
	switch (position) {
		case 'left':
			return css`
				position: absolute;
				right: calc(100% + ${tooltipBoxGap || 0}px);
				flex-direction: row-reverse;
			`;

		case 'right':
			return css`
				position: absolute;
				left: calc(100% + ${tooltipBoxGap || 0}px);
				flex-direction: row;
			`;

		case 'top':
			return css`
				position: absolute;
				bottom: calc(100% + ${tooltipBoxGap || 0}px);
				flex-direction: column-reverse;
			`;

		case 'bottom':
			return css`
				position: absolute;
				top: calc(100% + ${tooltipBoxGap || 0}px);
				flex-direction: column;
			`;
		default:
			return css``;
	}
};

const tooltipPointerCss = ({ position }: TooltipStProps) => {
	switch (position) {
		case 'left':
			return css`
				transform: rotate(90deg);
				margin: -2px;
			`;

		case 'right':
			return css`
				transform: rotate(-90deg);
				margin: -2px;
			`;

		case 'top':
			return css`
				transform: rotate(180deg);
				margin: -4px;
			`;

		case 'bottom':
			return css`
				margin: -4px;
			`;

		default:
			return css``;
	}
};

const tooltipMsgCss = css`
	border: none;
	border-radius: 2px;
	padding: 8px;
	background-color: ${paletteSDS.gray[900]};
	color: ${paletteSDS.gray[50]};
	line-height: 100%;
	white-space: nowrap;
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

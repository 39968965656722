import axios, { AxiosError } from 'axios';
import { GetOrganizationDetailApiResponse } from 'common/types/getOrganizationDetail.type';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export default async function getOrganizationDetailApi(organizationId: number) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetOrganizationDetailApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/organization`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
				},
			}
		);
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { setIsSegmentMaxNumModalVisible, toggleIsMoisSegmentCreateModalVisible } from 'common/context/modalSlice';

import { isAxiosError } from 'common/api/isAxiosError';

import type { PostSegmentApiError } from 'features/nfBasic/types/postSegment.type';

import postSegmentApi from '../api/postSegment.api';

import type { URI } from '../types/getSegmentList.type';

export interface PostMoisSegmentThunkResult {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: number;
	uris: URI[];
	segmentId: number;
	segmentKey: string;
}

export interface PostMoisSegmentThunkProps {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: string;
	uriList: string[];
	onSuccess?: () => void;
}

export const postMoisSegmentThunk = createAsyncThunk<
	PostMoisSegmentThunkResult,
	PostMoisSegmentThunkProps,
	{ state: RootState }
>('segment/mois/post', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentColor, segmentName, accessYn, maxInflow, uriList, onSuccess } = args;

	const PostSegmentApiBody = {
		segmentColor,
		segmentName,
		accessYn,
		maxInflow: Number(maxInflow),
		uris: uriList.map(el => ({ path: el })),
	};

	try {
		const {
			data: { uris, id, segmentKey, segmentColor: segmentColorResponse },
		} = await postSegmentApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			body: PostSegmentApiBody,
		});

		if (onSuccess) {
			onSuccess();
		} else {
			thunkAPI.dispatch(toggleIsMoisSegmentCreateModalVisible());
		}

		return {
			segmentColor: segmentColorResponse,
			segmentName,
			accessYn,
			maxInflow: Number(maxInflow),
			uris,
			segmentKey,
			segmentId: id,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (isAxiosError<PostSegmentApiError>(error)) {
			if (error.response?.data.errorCode === 'S1013') {
				thunkAPI.dispatch(toggleIsMoisSegmentCreateModalVisible());
				thunkAPI.dispatch(setIsSegmentMaxNumModalVisible(true));
			}
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

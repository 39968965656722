import { SegmentMetaData } from 'features/nfBasic/types/getSegmentList.type';

export const calcOutflowRate = (outflow: number, inflow: number) => {
	if (outflow === 0) return 0;

	if (inflow === 0) return 0;

	const ratio = (outflow / inflow) * 100;

	if (ratio > 100) return 100;

	const result = Math.ceil(ratio);
	return Number.isNaN(result) ? 0 : result;
};

export const compareSegIds = (before: number[], after: number[]) => {
	if (before.length !== after.length) {
		return false;
	}

	let compareCheck = true;

	before.forEach(el => {
		if (!after.includes(el)) compareCheck = false;
	});

	return compareCheck;
};

export const convertNumToKMB = (value: number) => {
	if (value < 1000) {
		return String(value);
	}

	if (value < 1000000) {
		const valueDivideK = value / 1000;

		return Number.isInteger(valueDivideK) ? `${valueDivideK}K` : `${valueDivideK.toFixed(1)}K`;
	}
	if (value < 1000000000) {
		const valueDivideM = value / 1000000;

		return Number.isInteger(valueDivideM) ? `${valueDivideM}M` : `${valueDivideM.toFixed(1)}M`;
	}
	const valueDivideB = value / 1000000000;

	return Number.isInteger(valueDivideB) ? `${valueDivideB}B` : `${valueDivideB.toFixed(1)}B`;
};

export const convertTimezoneToTimeStamp = (timezone: string) => {
	// eslint-disable-next-line
	const [integer, hour10, hour1, _, minute10, minute] = timezone.split('');

	if (integer === '+') {
		return Number(hour10) * 36000 + Number(hour1) * 3600 + Number(minute10) * 600 + Number(minute) * 60;
	}

	return (Number(hour10) * 36000 + Number(hour1) * 3600 + Number(minute10) * 600 + Number(minute) * 60) * -1;
};

export const calcResourceUsageRate = (usage: number, limitedInflow: number) => {
	if (limitedInflow === 0) {
		return `${usage}(0%)`;
	}

	const usageRate = Math.ceil((usage / limitedInflow) * 100);

	if (usageRate > 100) {
		return `${usage}(100%)`;
	}

	return `${usage}(${usageRate}%)`;
};

export const getShowTooltip = (meta: SegmentMetaData[]) => {
	let show = false;

	for (let i = 0; i < meta.length; i++)
		if (meta[i].segmentGraphYn) {
			show = true;
			break;
		}

	return show;
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { paletteSDS, themeSDS, Button, InfoIcon, colorPalette } from 'surf-design-system';

import LinkAgentPromptModal from 'features/signup/components/LinkAgentPromptModal';

import { AOSIcon, IOSIcon, WebIcon } from 'common/assets/icons';
import usePrompt from 'common/hooks/usePrompt';
import DividerWithTxt from 'common/components/DividerWithTxt';

export default function SelectAgent() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(true);

	const [selectedAgent, setSelectedAgent] = useState<'none' | 'ios' | 'web' | 'aos'>('none');

	const webClickHandler = () => {
		setSelectedAgent('web');
	};

	const iosClickHandler = () => {
		setSelectedAgent('ios');
	};

	const aosClickHandler = () => {
		setSelectedAgent('aos');
	};

	const doneButtonClickHandler = () => {
		navigate(`install/${selectedAgent}`);
		confirmNavigation();
	};

	const primaryButtonClickHandler = () => {
		cancelNavigation();
	};

	return (
		<div css={container}>
			<div css={gap8Column}>
				<div css={title}>{t('agent.installAgent')}</div>
				<div css={[regularSt]}>{t('agent.installAgentGuide')}</div>
				<div css={blueGuideBox}>
					<InfoIcon
						fill={paletteSDS.blue['500']}
						emotionCss={css`
							flex: none;
						`}
					/>
					<div css={[regularSt, colorPalette.black]}>{t('agent.installAgentNotification')}</div>
				</div>
			</div>
			<DividerWithTxt />
			<div css={gap16Column}>
				<div css={selectTitleSt}>{t('agent.selectEnvironment')}</div>
				<div css={selectContainer}>
					<div
						css={[basicSelectBox, selectedAgent === 'web' ? selectedBox : unSelectedBox]}
						aria-hidden
						onClick={webClickHandler}
					>
						<WebIcon fill={selectedAgent === 'web' ? paletteSDS.blue['400'] : paletteSDS.gray['500']} />
						<div>Web</div>
					</div>
					<div
						css={[basicSelectBox, selectedAgent === 'aos' ? selectedBox : unSelectedBox]}
						aria-hidden
						onClick={aosClickHandler}
					>
						<AOSIcon fill={selectedAgent === 'aos' ? paletteSDS.blue['400'] : paletteSDS.gray['500']} />
						<div>AOS</div>
					</div>
					<div
						css={[basicSelectBox, selectedAgent === 'ios' ? selectedBox : unSelectedBox]}
						aria-hidden
						onClick={iosClickHandler}
					>
						<IOSIcon fill={selectedAgent === 'ios' ? paletteSDS.blue['400'] : paletteSDS.gray['500']} />
						<div>iOS</div>
					</div>
				</div>
				<div css={buttonBox}>
					<Button width="100%" disabled={selectedAgent === 'none'} type="button" onClick={doneButtonClickHandler}>
						{t('agent.next')}
					</Button>
				</div>
			</div>
			<LinkAgentPromptModal visible={showPrompt} onClickPrimaryButton={primaryButtonClickHandler} />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 414px;

	padding: 32px 24px 80px;

	margin: 0 auto;
`;

const gap16Column = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const buttonBox = css`
	padding-top: 24px;
`;

const blueGuideBox = css`
	display: flex;
	gap: 10px;

	padding: 12px 16px;

	background: ${paletteSDS.blue['50']};
	border-radius: 8px;
	border: 1px solid ${paletteSDS.blue['200']};
`;

const basicSelectBox = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background: ${paletteSDS.gray['50']};
	border-radius: 8px;

	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.medium};

	width: 128px;
	height: 200px;

	cursor: pointer;
`;

const selectedBox = css`
	color: ${paletteSDS.blue['700']};
	border: 1px solid ${paletteSDS.blue['700']};
`;

const unSelectedBox = css`
	color: ${paletteSDS.gray['800']};
	border: 1px solid ${paletteSDS.gray['500']};
`;

const selectContainer = css`
	display: flex;
	gap: 8px;
`;

const title = css`
	width: 100%;

	color: ${paletteSDS.gray['900']};
	font-size: ${themeSDS.fontSize['24']};
	font-weight: ${themeSDS.fontWeight.bold};
`;

const regularSt = css`
	color: ${paletteSDS.gray['900']};
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;
`;

const selectTitleSt = css`
	color: ${paletteSDS.blue['700']};
	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useRef, ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { checkFileValidation } from 'common/utils/fileObjectFunc';
import Astrix from 'common/components/Astrix';

import { ChangeFileProps } from 'features/nfBasic/context/vwrSlice';
import { convertFileToUrl } from 'features/nfBasic/utils/fileFunc';

export interface FileUploadFormProps {
	id: string;
	name?: string;
	label?: React.ReactNode;
	helpText?: React.ReactNode;
	isRequire?: boolean;
	fileName: string;
	onSuccess: ({ src, name }: ChangeFileProps) => void;
	onFailByType?: () => void;
	onFailBySize?: () => void;
	onDelete: () => void;
}

export default function FileUploadForm({
	id,
	name,
	label,
	helpText,
	isRequire = false,
	fileName,
	onSuccess,
	onFailBySize = () => {},
	onFailByType = () => {},
	onDelete,
}: FileUploadFormProps) {
	const { t } = useTranslation();

	const selectFileRef = useRef<HTMLInputElement>(null);

	/**
	 * description: 커스텀 한 input file 클릭 제어 함수
	 */
	const addFileClickHandler = (e: MouseEvent) => {
		e.preventDefault();
		selectFileRef?.current?.click();
	};

	/**
	 * description: 이미지 변경 이벤트 함수
	 */
	const fileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;

		const uploadedFile = e.target.files[0];

		const { isFileSizeValid, isFileTypeValid } = checkFileValidation({
			fileName: uploadedFile.name,
			fileSize: uploadedFile.size,
			maxFileSize: 512000,
		});

		if (selectFileRef.current) {
			selectFileRef.current.value = '';
		}

		if (isFileSizeValid && isFileTypeValid) {
			convertFileToUrl(uploadedFile, onSuccess);
			return;
		}

		if (!isFileTypeValid) {
			onFailByType();
			return;
		}

		if (!isFileSizeValid) {
			onFailBySize();
		}
	};

	/**
	 * description: 이미지 삭제 이벤트 함수
	 */
	const closeIconClickHandler = () => {
		if (selectFileRef && selectFileRef.current) {
			selectFileRef.current.value = '';
		}
		onDelete();
	};

	return (
		<React.Fragment>
			<div css={containerSt}>
				<div css={textBoxSt}>
					<div>
						{label}
						{isRequire && <Astrix />}
					</div>
					<div>{helpText}</div>
				</div>
				<div css={buttonSt}>
					<input
						id={id}
						name={name}
						type="file"
						ref={selectFileRef}
						onChange={fileChangeHandler}
						accept=".jpg, .png, .jpeg"
					/>
					<button onClick={addFileClickHandler}>{t('projectMgmt.addFiles')}</button>
				</div>
			</div>
			{fileName !== '' ? (
				<div css={fileInfoSt}>
					<div css={guidanceSt} />
					<div css={listBox}>
						<div css={labelSt}>{fileName}</div>
						<div onClick={closeIconClickHandler} aria-hidden css={iconBox}>
							<CloseIcon size="xSmall" fill={paletteSDS.gray[700]} />
						</div>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
}

const containerSt = css`
	display: flex;
	margin-bottom: 1.5rem;
`;

const guidanceSt = css`
	width: 0.25rem;
	height: 1.5rem;
	border-radius: 4px;
	background-color: ${paletteSDS.blue[100]};
`;

const listBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
`;

const labelSt = css`
	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 100%;
`;

const iconBox = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
`;

const textBoxSt = css`
	width: 100%;
	color: ${paletteSDS.gray[700]};
	margin-right: 1rem;
	div:nth-of-type(1) {
		height: 1.375rem;
		line-height: 1.375rem;
		margin-bottom: 0.5rem;
		font-size: ${themeSDS.fontSize[14]};
		white-space: pre;
	}
	div:nth-of-type(2) {
		line-height: 1.1875rem;
		font-size: ${themeSDS.fontSize[12]};
		white-space: pre;
	}
`;

const buttonSt = css`
	position: relative;
	top: 17px;
	width: 92px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	> input {
		display: none;
	}
	> button {
		border: 0;
		background: transparent;
		outline: 0;
		font-size: ${themeSDS.fontSize[14]};
		font-weight: ${themeSDS.fontWeight.medium};
		color: ${paletteSDS.blue[700]};
		cursor: pointer;
	}
`;

const fileInfoSt = css`
	display: flex;
	align-items: center;
	gap: 8px;

	margin-bottom: 24px;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import {
	toggleIsIntervalSegmentCreateModalVisible,
	toggleIsIntervalSegmentMaxNumModalVisible,
} from 'common/context/modalSlice';

import { isAxiosError } from 'common/api/isAxiosError';

import type { PostSegmentApiError } from 'features/nfInterval/types/postIntervalSegment.type';

import postIntervalSegmentApi from '../api/postIntervalSegment.api';

export interface PostSegmentThunkResult {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: number;
	endUri: string;
	startUri: string;
	segmentId: number;
	segmentKey: string;
}

export interface PostSegmentThunkProps {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: string;
	endUri: string;
	startUri: string;
	onSuccess?: () => void;
}

export const postIntervalSegmentThunk = createAsyncThunk<
	PostSegmentThunkResult,
	PostSegmentThunkProps,
	{ state: RootState }
>('sectionSegment/post', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	const {
		segmentColor,
		segmentName,
		accessYn,
		maxInflow,
		endUri: enteredEndUri,
		startUri: enteredStartUri,
		onSuccess,
	} = args;

	const PostSegmentApiBody = {
		segmentColor,
		segmentName,
		accessYn,
		maxInflow: Number(maxInflow),
		endUri: enteredEndUri,
		startUri: enteredStartUri,
	};

	try {
		const {
			data: { id, segmentKey, endUri, startUri, segmentColor: segmentColorResponse },
		} = await postIntervalSegmentApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			body: PostSegmentApiBody,
		});

		if (onSuccess) {
			onSuccess();
		} else {
			thunkAPI.dispatch(toggleIsIntervalSegmentCreateModalVisible());
		}

		return {
			segmentColor: segmentColorResponse,
			segmentName,
			accessYn,
			maxInflow: Number(maxInflow),
			segmentKey,
			segmentId: id,
			endUri,
			startUri,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (isAxiosError<PostSegmentApiError>(error)) {
			if (error.response?.data.errorCode === 'S1013') {
				thunkAPI.dispatch(toggleIsIntervalSegmentCreateModalVisible());
				thunkAPI.dispatch(toggleIsIntervalSegmentMaxNumModalVisible());
			}
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

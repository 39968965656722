import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import { PutVWRDataResponse } from 'features/vwr/types/putVWRData.v2.type';

export interface PutVWRDataApiProps {
	logoImageFile?: File;
	title?: string;
	followingWaitYn?: boolean;
	guideText?: string;
	coverImageFile?: File;
	waitCancelYn?: boolean;
	tenantId: string;
	projectId: number;
	organizationId: number;
	waitTimeDisplayYn?: boolean;
	id: number;
	name: string;
}

export async function putVWRDataApi({
	logoImageFile,
	title,
	followingWaitYn,
	guideText,
	coverImageFile,
	waitCancelYn,
	tenantId,
	projectId,
	organizationId,
	waitTimeDisplayYn,
	id,
	name,
}: PutVWRDataApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutVWRDataResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/vwr-page/${id}`,
			{
				logoImageFile,
				title,
				followingWaitYn,
				guideText,
				coverImageFile,
				waitCancelYn,
				waitTimeDisplayYn,
				name,
			},
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
					'Content-Type': 'multipart/form-data',
					'Cache-Control': 'no-store',
					Pragma: 'no-store',
					Expires: '0',
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState, useRef } from 'react';

import { paletteSDS } from 'surf-design-system';

import FunnelSegmentTooltip from 'features/nfBasic/components/FunnelSegmentTooltip';

interface FunnelTextProps {
	value: number;
	decimal?: number;
	name?: string;
	textColor?: string;
	unit?: string;
	top?: number;
	left?: number;
	right?: number;
	disabled?: boolean;
}

function FunnelText({
	value,
	decimal = 0,
	name = '',
	textColor = paletteSDS.systemColor.black,
	unit = '',
	top = 0,
	left,
	right,
	disabled = false,
}: FunnelTextProps) {
	const [isHover, setIsHover] = useState<boolean>(false);
	const divRef = useRef<HTMLDivElement>(null);

	const textMouseEnterHandler = () => {
		setIsHover(() => true);
	};

	const textMouseLeaveHandler = () => {
		setIsHover(() => false);
	};

	return !disabled ? (
		<div
			css={FunnelTextCss(top, left, right, isHover, textColor)}
			onMouseEnter={textMouseEnterHandler}
			onMouseLeave={textMouseLeaveHandler}
			ref={divRef}
		>
			<span css={valueCss(textColor)}>{value === 0 ? 0 : value.toFixed(decimal)}</span>
			<span css={unitCss}>{unit}</span>
			<FunnelSegmentTooltip
				isShow={isHover}
				value={`${value.toFixed(decimal)}${unit}`}
				name={name}
				parentWidth={divRef.current?.clientWidth}
			/>
		</div>
	) : null;
}

export default React.memo(FunnelText);

const FunnelTextCss = (
	top: number,
	left: number | undefined,
	right: number | undefined,
	isHover: boolean,
	textColor: string
) => css`
	position: absolute;
	top: ${top}px;
	left: ${left}px;
	right: ${right}px;
	display: flex;
	align-items: flex-end;
	padding: 0 4px;
	background-color: ${isHover ? paletteSDS.gray[900] : 'transparent'};
	border-radius: 4px;

	& > span:first-of-type {
		color: ${isHover ? paletteSDS.systemColor.white : textColor};
	}
`;

const valueCss = (textColor: string) => css`
	color: ${textColor};
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.16px;
`;

const unitCss = css`
	color: ${paletteSDS.gray[600]};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.16px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';

import { paletteSDS } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';
import { setInitialToast } from 'common/context/toastSlice';
import DividerWithTxt from 'common/components/DividerWithTxt';

import TimezoneForm from 'features/setting/components/TimezoneForm';
import LanguageForm from 'features/setting/components/LanguageForm';

export default function Preference() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(setInitialToast());
		};
	}, [dispatch]);

	return (
		<div css={container}>
			<TimezoneForm />
			<DividerWithTxt dividerColor={paletteSDS.gray[300]} />
			<LanguageForm />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import { PostSegmentStatDataApiResponse } from 'features/nfBasic/types/postSegmentStatData.type';

export interface PostSegmentStatDataApiProps {
	isPathControl: boolean;
	tenantId: string;
	organizationId: number;
	projectId: number;
	segmentIds: number[];
	begin: number;
	end: number;
}

export default async function postSegmentStatDataApi({
	tenantId,
	organizationId,
	projectId,
	segmentIds,
	begin,
	end,
	isPathControl,
}: PostSegmentStatDataApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	const body = {
		data: {
			segmentIds,
			fields: [
				'total_tickets',
				'numbers_waiting',
				'wait_time',
				'total_inflow',
				'max_inflow',
				'total_outflow',
				'process_time',
				'used_size',
			],
			interval_control_yn: isPathControl,
		},
		range: {
			type: 'realtime',
			value: {
				begin,
				end,
			},
		},
	};

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.post<PostSegmentStatDataApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/statData/project/${projectId}/select/segment`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

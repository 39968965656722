import { createSlice } from '@reduxjs/toolkit';

import { BillingHistoryData } from '../types/getBillingHistory.type';
import { GetRealtimeChargeApiData } from '../types/getRealtimeCharge.type';

import { getBillingHistoryThunk } from '../thunk/getBillingHistory.thunk';
import { getRealtimeChargeThunk } from '../thunk/getRealtimeCharge.thunk';
import { getRealtimeChargeDetailThunk } from '../thunk/getRealtimeChargeDetail.thunk';

import type { GetRealtimeChargeDetailApiData } from '../types/getRealtimeChargeDetail.type';

export interface BillingHistorySliceData {
	billingHistory: BillingHistoryData[];
	realtimeCharge: GetRealtimeChargeApiData;
	realtimeChargeDetail: GetRealtimeChargeDetailApiData;
	// eslint-disable-next-line
	billingHistoryError: any;
	// eslint-disable-next-line
	realtimeChargeError: any;
	// eslint-disable-next-line
	realtimeChargeDetailError: any;
	isBillingHistoryLoaded: boolean;
	isRealtimeChargeLoaded: boolean;
	isRealtimeChargeDetailLoaded: boolean;
}

const initialState: BillingHistorySliceData = {
	billingHistory: [],
	realtimeCharge: {
		charge: 0,
		paymentMethod: '',
		planNames: [],
	},
	billingHistoryError: null,
	realtimeChargeError: null,
	isBillingHistoryLoaded: false,
	isRealtimeChargeLoaded: false,
	realtimeChargeDetail: {
		paymentFlag: 'FAIL',
		totalCharge: 0,
		surtax: 0,
		chargeDetailModels: [],
	},
	isRealtimeChargeDetailLoaded: false,
	realtimeChargeDetailError: null,
};

const billingHistorySlice = createSlice({
	name: 'billingHistory',
	initialState,
	reducers: {
		setInitialBillingHistory: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getBillingHistoryThunk.fulfilled, (state, action) => {
			return { ...state, billingHistoryError: null, isBillingHistoryLoaded: true, billingHistory: action.payload };
		});

		builder.addCase(getBillingHistoryThunk.rejected, (state, action) => {
			return { ...state, billingHistoryError: action.payload, isBillingHistoryLoaded: true };
		});

		builder.addCase(getRealtimeChargeThunk.fulfilled, (state, action) => {
			return {
				...state,
				realtimeCharge: { ...action.payload },
				realtimeChargeError: null,
				isRealtimeChargeLoaded: true,
			};
		});

		builder.addCase(getRealtimeChargeThunk.rejected, (state, action) => {
			return { ...state, realtimeChargeError: action.payload, isRealtimeChargeLoaded: true };
		});

		builder.addCase(getRealtimeChargeDetailThunk.fulfilled, (state, action) => {
			return {
				...state,
				realtimeChargeDetail: { ...action.payload },
				realtimeChargeDetailError: null,
				isRealtimeChargeDetailLoaded: true,
			};
		});

		builder.addCase(getRealtimeChargeDetailThunk.rejected, (state, action) => {
			return { ...state, isRealtimeChargeDetailLoaded: true, realtimeChargeDetailError: action.payload };
		});
	},
});

export const { setInitialBillingHistory } = billingHistorySlice.actions;

export default billingHistorySlice.reducer;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { paletteSDS } from 'surf-design-system';

import { ServiceFunnel } from 'features/nfBasic/utils/funnelLib';
import projectFrameImg from 'features/nfBasic/assets/projectImg/shape.png';
import waveFast from 'features/nfBasic/assets/projectImg/waveFast.gif';
import waveMedium from 'features/nfBasic/assets/projectImg/waveMedium.gif';
import waveSlow from 'features/nfBasic/assets/projectImg/waveSlow.gif';
import FunnelSegmentTooltip from 'features/nfBasic/components/FunnelSegmentTooltip';
import type { FunnelData, SpeedStatus } from 'features/nfBasic/types/funnelLib.type';
import {
	getPercentTextColor,
	getResourceUsageRate,
	getWaveColor,
	getWaveHeightProject,
	restrictTextPosProject,
} from 'features/nfBasic/utils/funnelFunc';

interface FunnelProjectProps {
	funnelData: FunnelData;
	speedStatus: SpeedStatus;
	disabled: boolean;
}

function NetFunnelProjectClassic({ funnelData, speedStatus = 'FAST', disabled = false }: FunnelProjectProps) {
	const { t } = useTranslation();

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isHover, setIsHover] = useState<boolean>(false);

	const divRef = useRef<HTMLDivElement>(null);
	// TODO: 라이브러리 타입 정의하기
	const dataRef = useRef<any>(null);

	const waveImg = getWaveImg(speedStatus);
	const resourceUsageRate = getResourceUsageRate(funnelData.used_size, funnelData.limit);
	const percentTextColor = getPercentTextColor(isHover, resourceUsageRate);

	useEffect(() => {
		if (isFirstRender) {
			dataRef.current = new ServiceFunnel(funnelData.id, 'service_1', { speedStatus }, () => {});
			dataRef.current.setData(funnelData);
			setIsFirstRender(false);
		}
	}, [funnelData.id, funnelData, speedStatus, isFirstRender]);

	useEffect(() => {
		if (!dataRef.current) return;
		const isVisible = document.visibilityState === 'visible';
		if (isVisible) {
			dataRef.current.setData(funnelData);
		}
	}, [funnelData, isFirstRender]);

	useEffect(() => {
		return () => {
			setIsFirstRender(() => true);
		};
	}, []);

	const textMouseEnterHandler = () => {
		setIsHover(() => true);
	};

	const textMouseLeaveHandler = () => {
		setIsHover(() => false);
	};

	return (
		<div css={FunnelCss(disabled)}>
			<div id={funnelData.id} css={particleCss} />
			{!disabled && (
				<div css={inflowWrapperCss}>
					<div css={emptyCss} />
					<div css={inflowCss(speedStatus, funnelData.wait_tm)}>{waveImg}</div>
				</div>
			)}
			<div css={filterWrapperCss}>
				<div css={blurFilterCss(disabled ? 0 : resourceUsageRate)} />
				<div css={gagueCss} />
			</div>
			<img css={funnelFrameImgCss} src={projectFrameImg} alt="funnelFrame" />
			{!disabled && (
				<div css={emptyContainerCss}>
					<div
						css={waitPercentTextCss(resourceUsageRate, isHover, percentTextColor)}
						onMouseEnter={textMouseEnterHandler}
						onMouseLeave={textMouseLeaveHandler}
						ref={divRef}
					>
						<span>
							{funnelData.used_size}({resourceUsageRate}%)
						</span>
						<FunnelSegmentTooltip
							isShow={isHover}
							value={`${funnelData.used_size}(${resourceUsageRate}%)`}
							name={t('projectMonitoring.resourceUsageRate')}
							parentWidth={divRef.current?.clientWidth}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default React.memo(NetFunnelProjectClassic);

const FunnelCss = (disabled: boolean) => css`
	position: relative;
	width: 106px;
	height: 447px;
	background-color: ${disabled ? paletteSDS.gray[75] : '#d9edff'};
`;

const particleCss = css`
	position: absolute;
	width: 106px;
	height: 447px;
`;

const inflowWrapperCss = css`
	position: absolute;
	top: -5px;
	width: 106px;
	height: 120px;
	overflow: hidden;
	transition: all 0.5s;
`;

const emptyCss = css`
	width: 1px;
	height: 1px;
`;

const inflowCss = (speedStatus: SpeedStatus, waitTime: number) => css`
	display: ${waitTime === 0 ? 'none' : 'block'};
	position: absolute;
	bottom: 0px;
	width: 106px;
	height: ${getWaveHeightProject(speedStatus, waitTime)}%;
	background: ${getWaveColor(speedStatus)};
	transition: all 1s;
	z-index: 1000;

	& > img {
		display: ${waitTime === 0 ? 'none' : 'block'};
	}
`;

const waveFastCss = css`
	position: absolute;
	top: -3px;
	width: 106px;
`;

const waveMediumCss = css`
	position: absolute;
	top: -2px;
	width: 106px;
`;

const waveSlowCss = css`
	position: absolute;
	top: -5px;
	width: 106px;
`;

const emptyContainerCss = css`
	position: absolute;
	top: 202px;
	left: 0px;
	width: 106px;
	height: 170px;
`;

const waitPercentTextCss = (percent: number, isHover: boolean, color: string) => css`
	position: absolute;
	left: 50%;
	top: ${100 - restrictTextPosProject(percent)}%;
	transform: translate3d(-50%, 0, 0);

	padding: 0 4px;
	background-color: ${isHover ? paletteSDS.gray[900] : ''};
	border-radius: 4px;

	color: ${color};
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.16px;
	white-space: nowrap;

	z-index: 1001;
	transition: top 0.5s;
`;

const filterWrapperCss = css`
	position: absolute;
	top: 202px;
	left: 0px;
	width: 106px;
	height: 170px;
	border-radius: 10px;
	overflow: hidden;
	z-index: 1000;
`;

const blurFilterCss = (height: number) => css`
	width: 100%;
	height: ${100 - height}%;
	background: rgba(90, 90, 90, 0.15);
	backdrop-filter: blur(3px);
	transition: all 0.5s;
`;

const gagueCss = css`
	width: 100%;
	height: 100%;
	background-color: ${paletteSDS.blue[600]};
`;

const funnelFrameImgCss = css`
	position: absolute;
	top: 0;
	width: 106px;
	height: 447px;
	z-index: 1001;
`;

const getWaveImg = (speedStatus: SpeedStatus) => {
	if (speedStatus === 'FAST') return <img css={waveFastCss} src={waveFast} alt="waveFast" />;
	if (speedStatus === 'MEDIUM') return <img css={waveMediumCss} src={waveMedium} alt="waveMedium" />;
	return <img css={waveSlowCss} src={waveSlow} alt="waveSlow" />;
};

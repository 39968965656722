import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { getVWRDataApi } from '../api/getVWRData.api';
import type { VWRElement } from '../context/vwrSlice';

import { defaultLogoImageBase64 } from '../data/defaultImageBase64';

export const getVWRDataThunk = createAsyncThunk<VWRElement, void, { state: RootState }>(
	'vwr/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantId, tenantUrl, organizationId, language },
			prjMeta: { selectedProjectId },
		} = thunkAPI.getState();

		const { preview } = thunkAPI.getState().vwr;

		try {
			if (tenantId) {
				const {
					data: {
						imagePath,
						logoImagePath,
						title,
						guideText,
						waitCancelYn,
						followingWaitYn,
						imageFileName,
						logoImageFileName,
						waitTimeDisplayYn,
					},
				} = await getVWRDataApi({ tenantId, tenantUrl, projectId: selectedProjectId, organizationId });

				let titleData = title;

				if (title === '' && language === 'ENGLISH') {
					titleData = 'Waiting Needed for Online Access';
				}

				if (title === '' && language === 'KOREAN') {
					titleData = '온라인 접속 대기 안내';
				}

				return {
					imagePathSrc: imagePath !== '' ? `${imagePath}?${Date.now()}` : '',
					imagePathName: imageFileName,
					logoImagePathSrc: logoImagePath !== '' ? `${logoImagePath}?${Date.now()}` : defaultLogoImageBase64,
					logoImagePathName: logoImageFileName,
					title: titleData,
					guideText,
					waitCancelYn,
					followingWaitYn,
					waitTimeDisplayYn,
				};
			}
			return {
				...preview,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

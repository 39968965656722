/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Table from 'common/components/Table';
import type { TableColumn, TableRow } from 'common/components/Table';
import { LANG_PATH } from 'common/data/constants';
import { getProjectModeUrl } from 'common/utils/projectFunc';

import NetfunnelProjectTableIcon from 'features/home/components/NetfunnelProjectTableIcon';
import NetfunnelProjectName from 'features/home/components/NetfunnelProjectName';
import { setSelectedProjectId } from 'features/nfSetting/context/projectInfoSlice';

export default function NetfunnelProjectListTable() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { language, userRole } = useSelector((state: RootState) => state.account);

	const projectColumns: TableColumn[] = useMemo(
		() => [
			{
				id: 1,
				label: t('console.nfHome.projectName'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '195px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 2,
				label: t('console.nfHome.projectKey'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '135px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 3,
				label: t('console.nfHome.serviceDomain'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '195px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 4,
				label: t('console.nfHome.basic'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '120px',
				elementCss: css`
					flex: none;
					word-break: keep-all;
				`,
			},
			{
				id: 5,
				label: t('console.nfHome.pathControl'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '120px',
				elementCss: css`
					flex: none;
					word-break: keep-all;
				`,
			},
			{
				id: 6,
				label: t('console.nfHome.accessMode'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '97px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 7,
				label: '',
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '48px',
				elementCss: css`
					flex: none;
				`,
			},
		],
		[t]
	);

	const projectRows: TableRow[] = useSelector((state: RootState) =>
		state.projectInfo.projectList.map(el => ({
			id: el.id,
			items: [
				{
					elementId: 1,
					element: <NetfunnelProjectName projectName={el.projectName} activatedYn={el.activatedYn} />,
					padding: '0px 16px',
					width: '195px',
					elementCss: css`
						flex: none;
						background: none;
					`,
					onClick: () => {
						dispatch(setSelectedProjectId(el.id));
						navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
					},
				},
				{
					elementId: 2,
					element: <span>{el.projectKey}</span>,
					padding: '0px 16px',
					width: '135px',
					elementCss: css`
						flex: none;
						background: none;

						& > div > span {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					`,
					onClick: () => {
						dispatch(setSelectedProjectId(el.id));
						navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
					},
				},
				{
					elementId: 3,
					element: <span>{el.customerServiceDomain}</span>,
					padding: '0px 16px',
					width: '195px',
					elementCss: css`
						flex: none;
						background: none;

						& > div > span {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					`,
					onClick: () => {
						dispatch(setSelectedProjectId(el.id));
						navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
					},
				},
				{
					elementId: 4,
					element: el.segmentCount,
					padding: '0px 16px',
					width: '120px',
					elementCss: css`
						flex: none;
						background: none;
					`,
					onClick: () => {
						dispatch(setSelectedProjectId(el.id));
						navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
					},
				},
				{
					elementId: 5,
					element: el.sectionSegmentCount,
					padding: '0px 16px',
					width: '120px',
					elementCss: css`
						flex: none;
						background: none;
					`,
					onClick: () => {
						dispatch(setSelectedProjectId(el.id));
						navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
					},
				},
				{
					elementId: 6,
					element: t(el.accessYn ? 'console.nfHome.allowed' : 'console.nfHome.blocked'),
					padding: '0px 16px',
					width: '97px',
					elementCss: css`
						flex: none;
						background: none;
					`,
					onClick: () => {
						dispatch(setSelectedProjectId(el.id));
						navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
					},
				},
				{
					elementId: 7,
					element:
						userRole === 'OWNER' || userRole === 'ADMINISTRATOR' ? <NetfunnelProjectTableIcon projectId={el.id} /> : '',
					padding: '0px 8px',
					width: '48px',
					elementCss: css`
						flex: none;
						background: none;
						& > div {
							overflow: unset;
						}
					`,
					onClick:
						userRole === 'OWNER' || userRole === 'ADMINISTRATOR'
							? undefined
							: () => {
									dispatch(setSelectedProjectId(el.id));
									navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${el.id}/main`);
							  },
				},
			],
			rowCss: rowSt,
		}))
	);

	return <Table columns={projectColumns} rows={projectRows} />;
}

const rowSt = css`
	background: ${paletteSDS.gray['50']};

	cursor: pointer;

	:hover {
		background: ${paletteSDS.gray['100']};
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { LANG_PATH, NF_CONTROLS_VIEW_MODE_KEY } from 'common/data/constants';
import { toggleIsSaasSNBExpand } from 'common/context/layoutSlice';

export default function SaasSNB() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const { language, userRole } = useSelector((state: RootState) => state.account);

	const { isSaasSNBExpand } = useSelector((state: RootState) => state.layout);

	const { selectedProjectId, projectList, isLoaded } = useSelector((state: RootState) => state.projectInfo);

	const viewMode = window.localStorage.getItem(NF_CONTROLS_VIEW_MODE_KEY);

	const chevronBoxRef = createRef<HTMLDivElement>();

	const projectNameClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/main`);
	};

	const basicControlClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/controls/basic/${viewMode || 'default'}`);
	};

	const sectionControlClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/controls/path/${viewMode || 'default'}`);
	};

	const uriClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/eum/url`);
	};

	const transactionClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/eum/transaction`);
	};

	const projectManagementClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/settings/management/project`);
	};

	const vwrClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/settings/vwr`);
	};

	const agentClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/${selectedProjectId}/settings/agent/web`);
	};

	useEffect(() => {
		const chevronBoxTimeout = setTimeout(() => {
			if (chevronBoxRef.current) {
				chevronBoxRef.current.style.visibility = 'visible';
			}
		}, 401);

		return () => {
			clearTimeout(chevronBoxTimeout);
		};
	}, [chevronBoxRef]);

	if (!isLoaded) return null;

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { projectName } = projectList[selectedProjectIndex];

	return (
		<div css={container(isSaasSNBExpand)}>
			<div css={chevronBox(isSaasSNBExpand)} ref={chevronBoxRef}>
				<ChevronRightIcon
					fill={paletteSDS.blue['700']}
					onClick={() => dispatch(toggleIsSaasSNBExpand())}
					size="small"
					emotionCss={iconFlipSt(isSaasSNBExpand)}
				/>
			</div>
			<div css={subContainer(isSaasSNBExpand)}>
				{isSaasSNBExpand ? (
					<React.Fragment>
						<div css={projectNameSt} aria-hidden onClick={projectNameClickHandler}>
							{projectName}
						</div>
						<div css={navigateBox}>
							<div css={navigateLabel}>Controls</div>
							<div
								css={navigateContent(pathname.includes('/controls/basic'))}
								onClick={basicControlClickHandler}
								aria-hidden
							>
								{t('breadCrumb.basicMonitoring')}
							</div>
							<div
								css={navigateContent(pathname.includes('/controls/path'))}
								aria-hidden
								onClick={sectionControlClickHandler}
							>
								{t('breadCrumb.intervalMonitoring')}
							</div>
						</div>
						<div css={navigateBox}>
							<div css={navigateLabel}>End User Monitoring</div>
							<div
								css={navigateContent(pathname.includes('/eum/transaction'))}
								aria-hidden
								onClick={transactionClickHandler}
							>
								{t('eum.transaction')}
							</div>
							<div css={navigateContent(pathname.includes('/eum/url'))} aria-hidden onClick={uriClickHandler}>
								URL
							</div>
						</div>
						<div css={navigateBox}>
							<div css={navigateLabel}>Settings</div>
							{userRole === 'OWNER' || userRole === 'ADMINISTRATOR' ? (
								<div
									css={navigateContent(pathname.includes('/settings/management'))}
									aria-hidden
									onClick={projectManagementClickHandler}
								>
									{t('userPermission.projectManagement')}
								</div>
							) : null}
							<div css={navigateContent(pathname.includes('/settings/vwr'))} aria-hidden onClick={vwrClickHandler}>
								{t('breadCrumb.vwrManagement')}
							</div>
							<div css={navigateContent(pathname.includes('/settings/agent'))} aria-hidden onClick={agentClickHandler}>
								{t('breadCrumb.agent')}
							</div>
						</div>
					</React.Fragment>
				) : null}
			</div>
		</div>
	);
}

const container = (isSNBExpand: boolean) => css`
	position: relative;
	flex: none;
	width: ${isSNBExpand ? '244px' : '32px'};
	transition: width 0.4s ease-in-out;
	white-space: nowrap;
	overflow: unset;
`;

const subContainer = (isSNBExpand: boolean) => css`
	width: ${isSNBExpand ? '244px' : '20px'};
	height: 100%;
	background: ${paletteSDS.gray['50']};
	border-right: 1px solid ${paletteSDS.gray['300']};
	box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.04);
	transition: width 0.4s ease-in-out;
	overflow: auto;
`;

const projectNameSt = css`
	width: 100%;
	height: 86px;
	padding: 33px 24px;
	font-size: ${themeSDS.fontSize['16']};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
`;

const navigateBox = css`
	border-top: 1px solid ${paletteSDS.gray['300']};
`;

const navigateLabel = css`
	height: 31px;
	padding: 8px 24px;
	color: ${paletteSDS.gray['500']};
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['11']};
	line-height: 140%;
	letter-spacing: 0.32px;
`;

const navigateContent = (isActive: boolean) => css`
	height: 48px;
	padding: 14px 24px;
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['14']};
	line-height: 140%;
	letter-spacing: 0.16px;
	background: ${isActive && paletteSDS.blue['50']};

	cursor: pointer;

	${!isActive &&
	css`
		:hover {
			background: ${paletteSDS.gray['100']};
		}
	`}
`;

const chevronBox = (isSNBExpand: boolean) => css`
	visibility: hidden;
	position: absolute;
	top: 31px;
	left: ${isSNBExpand ? '232px' : '8px'};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid ${paletteSDS.gray['300']};
	background: ${paletteSDS.gray['50']};

	@keyframes move-left {
		from {
			left: 232px;
		}

		to {
			left: 8px;
		}
	}

	@keyframes move-right {
		from {
			left: 8px;
		}

		to {
			left: 232px;
		}
	}

	animation: ${isSNBExpand ? 'move-right 0.4s ease-in-out' : 'move-left 0.4s ease-in-out'};
	animation-fill-mode: both;
`;

const iconFlipSt = (isSNBExpand: boolean) => css`
	transform: ${isSNBExpand && 'rotateY(180deg)'};
	transition: rotate 0.3s linear;
`;

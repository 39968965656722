/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';

import useApiRequestNoArg from 'common/hooks/useApiRequestNoArg';
import { getI18nLanguage } from 'common/utils/languageFunc';

import SignupTitle from 'features/signup/components/SignupTitle';
import SignupOauthForm from 'features/signup/components/SignupOauthForm';
import getCountryListApi from 'features/signup/api/getCountryList.api';
import getRegionListApi from 'features/signup/api/getRegionList.api';

export default function SignupOauthPage() {
	const { response: regionList, sendRequest: getRegionList } = useApiRequestNoArg(getRegionListApi);
	const { response: countryList, sendRequest: getCountryList } = useApiRequestNoArg(getCountryListApi);

	useEffect(() => {
		getRegionList();
		getCountryList();
	}, [getRegionList, getCountryList]);

	if (regionList === null || countryList === null) return <div />;

	const availRegionList = regionList.data
		.filter(region => region.availability === 'YES')
		.map(item => ({ value: item.code, name: item.name }));

	const availCountryList = countryList.data
		.filter(country => country.availability)
		.map(item => ({ value: item.code, name: getI18nLanguage() === 'KOREAN' ? item.koreanName : item.englishName }));

	return (
		<div css={signinContainer}>
			<div css={signinWrapSt}>
				<SignupTitle />
				<SignupOauthForm availRegionList={availRegionList} availCountryList={availCountryList} />
			</div>
		</div>
	);
}

export const loader = () => {
	return getRegionListApi();
};

const signinContainer = css`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 100%;
	height: fit-content;

	padding: 5rem;
`;

const signinWrapSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	width: 25rem;

	height: fit-content;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS } from 'surf-design-system';

import type { RootState } from 'common/redux/store';

import ProjectStatData from 'features/nfControl/components/ProjectStatData';

const { gray, systemColor } = paletteSDS;

export default function ProjectStatDataSummary() {
	const { t } = useTranslation();

	const { totalTickets, maxNumbersWaiting, maxProcessTime, maxWaitTime } = useSelector(
		(state: RootState) => state.nfControl.project
	);

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { activatedYn } = projectList[selectedProjectIndex];

	return (
		<ul css={infoBoxCss}>
			<ProjectStatData label={`${t('projectMonitoring.totalWaitTickets')}`} value={totalTickets} disabled={false} />
			<ProjectStatData
				label={`${t('projectMonitoring.maxWaitTime')}(${t('commonUnit.sec')})`}
				value={maxWaitTime}
				disabled={!activatedYn}
			/>
			<ProjectStatData label={t('projectMonitoring.maxQueue')} value={maxNumbersWaiting} disabled={!activatedYn} />
			<ProjectStatData
				label={`${t('projectMonitoring.maxPrcsTime')}(${t('commonUnit.sec')})`}
				value={maxProcessTime}
				disabled={!activatedYn}
			/>
		</ul>
	);
}

const infoBoxCss = css`
	display: flex;
	flex-direction: column;

	gap: 20px;

	width: 100%;
	height: 268px;

	padding: 24px;

	background-color: ${systemColor.white};
	border: 1px solid ${gray[300]};
	border-radius: 4px;
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutUserPasswordApiResponse } from 'features/setting/types/putUserPassowrd.v2.onPrem.type';

export interface PutUserPasswordApiProps {
	prevPassword: string;
	newPassword: string;
}

export default async function putUserPasswordApi({ prevPassword, newPassword }: PutUserPasswordApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutUserPasswordApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/user/password`,
			{
				prevPassword,
				newPassword,
			},
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'common/redux/store';
import {
	toggleIsWindowRefreshedToastVisible,
	setInitialToast,
	setIsProjectInactiveToastVisible,
} from 'common/context/toastSlice';
import type { ProjectInformation } from 'features/nfSetting/types/getProjectList.v2.type';

import { FETCH_INTERVAL, IS_RELOAD_KEY, IS_RELOAD_TRUE } from 'features/nfBasic/data/constants';

import BasicControlTotalEntryRequestChart from 'features/nfControl/components/BasicControlTotalEntryRequestChart';
import BasicControlProcessTimeChart from 'features/nfControl/components/BasicControlProcessTimeChart';
import BasicControlOutflowRateChart from 'features/nfControl/components/BasicControlOutflowRateChart';
import ProjectAccessMode from 'features/nfControl/components/ProjectAccessMode';
import ProjectStatDataSummary from 'features/nfControl/components/ProjectStatDataSummary';
import getBasicControlSegmentListThunk from 'features/nfControl/thunk/getBasicControlSegmentList.v2.thunk';
import postProjectStatDataThunk from 'features/nfControl/thunk/postProjectStatData.v2.thunk';
import postSegmentStatDataInitialThunk from 'features/nfControl/thunk/postSegmentStatDataInitial.v2.thunk';
import postSegmentStatDataUpdateThunk from 'features/nfControl/thunk/postSegmentStatDataUpdate.v2.thunk';
import { cleanupBasicControlSegmentRawData, setInitialNfControlData } from 'features/nfControl/context/nfControlSlice';
import ProjectSummaryClock from 'features/nfControl/components/ProjectSummaryClock';
import OnPremBasicControlSegmentLegend from 'features/nfControl/components/OnPremBasicControlSegmentLegend';
import OnPremBasicControlSegmentBoard from 'features/nfControl/components/OnPremBasicControlSegmentBoard';

let fetchingInterval: NodeJS.Timer | undefined;
let cleanupInterval: NodeJS.Timer | undefined;

export default function OnPremNetfunnelBasicControlDefaultView() {
	const dispatch = useAppDispatch();

	const activatedYn = useSelector(
		(state: RootState) =>
			(state.projectInfo.projectList.find(el => el.id === state.projectInfo.selectedProjectId) as ProjectInformation)
				.activatedYn,
		shallowEqual
	);

	const { isMetaLoaded, isValueLoaded } = useSelector((state: RootState) => state.nfControl.segment.basic);

	useEffect(() => {
		if (!isMetaLoaded) {
			dispatch(getBasicControlSegmentListThunk());
			return undefined;
		}

		if (isMetaLoaded) {
			if (sessionStorage.getItem(IS_RELOAD_KEY) === IS_RELOAD_TRUE) {
				dispatch(toggleIsWindowRefreshedToastVisible());
				sessionStorage.removeItem(IS_RELOAD_KEY);
			}

			(async () => {
				await dispatch(postSegmentStatDataInitialThunk({ isPathControl: false }));
				await dispatch(postProjectStatDataThunk({ isPathControl: false }));
			})();

			fetchingInterval = setInterval(() => {
				dispatch(postProjectStatDataThunk({ isPathControl: false }));
				dispatch(postSegmentStatDataUpdateThunk({ isPathControl: false }));
			}, FETCH_INTERVAL);

			return () => clearInterval(fetchingInterval);
		}

		return undefined;
	}, [dispatch, isMetaLoaded]);

	useEffect(() => {
		if (isValueLoaded) {
			cleanupInterval = setInterval(() => {
				dispatch(cleanupBasicControlSegmentRawData());
			}, 120000);

			return () => clearInterval(cleanupInterval);
		}

		return undefined;
	}, [dispatch, isValueLoaded]);

	useEffect(() => {
		return () => {
			dispatch(setInitialNfControlData());
			dispatch(setInitialToast());
			clearInterval(fetchingInterval);
			clearInterval(cleanupInterval);
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(setIsProjectInactiveToastVisible(!activatedYn));
	}, [dispatch, activatedYn]);

	if (!isMetaLoaded) return <div />;

	return (
		<div css={containerCss}>
			<div css={upperContainerCss}>
				<div css={projectSummaryWrapperCss}>
					<ProjectAccessMode />
					<ProjectStatDataSummary />
					<ProjectSummaryClock />
				</div>
				<div css={chartScrollSt}>
					<div css={chartContainerCss}>
						<BasicControlTotalEntryRequestChart />
						<BasicControlProcessTimeChart />
						<BasicControlOutflowRateChart />
					</div>
				</div>
			</div>
			<div css={lowerContainerCss}>
				<OnPremBasicControlSegmentLegend />
				<OnPremBasicControlSegmentBoard />
			</div>
		</div>
	);
}

const containerCss = css`
	display: flex;
	flex-direction: column;
	gap: 8px;

	width: 100%;
`;

const upperContainerCss = css`
	flex: none;

	display: flex;
	gap: 8px;
	height: 400px;
`;

const projectSummaryWrapperCss = css`
	display: flex;
	flex-direction: column;
	width: 265px;

	height: 400px;

	gap: 8px;
`;

const chartScrollSt = css`
	width: 100%;
	height: 400px;

	overflow-x: auto;
	overflow-y: hidden;
`;

const chartContainerCss = css`
	display: flex;
	gap: 8px;

	width: 100%;
	min-width: 1516px;
	height: 100%;
`;

const lowerContainerCss = css`
	flex: none;

	display: flex;
	position: relative;
	width: 100%;
	height: 448px;
	//height: 408px;

	margin-bottom: 40px;

	z-index: 0;
`;

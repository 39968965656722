/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useAppDispatch } from 'common/redux/store';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { paletteSDS } from 'surf-design-system';
import {
	cleanupRealtimeData,
	resetRealtimeData,
	setRealtimeIntervalTime,
} from 'features/nfEUM/context/eumTXNRealtimeSlice';
import { getTXNRealtimeThunk } from 'features/nfEUM/thunk/getTXNRealtime.thunk';
import TXNErrorChart from 'features/nfEUM/components/TXNErrorChart';
import TXNLoadChart from 'features/nfEUM/components/TXNLoadChart';
import TXNLoadTimeChart from 'features/nfEUM/components/TXNLoadTimeChart';

let intervalTimer: NodeJS.Timer | undefined;

export default function TXNRealtime() {
	const dispatch = useAppDispatch();
	const [currIntervalSec, setCurrIntervalSec] = useState(false);

	useEffect(() => {
		clearInterval(intervalTimer);

		intervalTimer = setInterval(async () => {
			if (moment().seconds() % 3 === 0 && !currIntervalSec) {
				setCurrIntervalSec(true);
				dispatch(
					setRealtimeIntervalTime({
						beginTime: moment().subtract(9, 's').unix(),
						endTime: moment().subtract(7, 's').unix(),
					})
				);
				dispatch(getTXNRealtimeThunk());
			} else if (moment().seconds() % 3 !== 0) setCurrIntervalSec(false);
		}, 300);
	}, [currIntervalSec, dispatch]);

	useLayoutEffect(() => {
		dispatch(
			setRealtimeIntervalTime({
				beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
				endTime: moment().subtract(7, 's').unix(),
			})
		);
		dispatch(getTXNRealtimeThunk());

		const cleanupTimeout = setInterval(() => {
			dispatch(cleanupRealtimeData());
		}, 30000000);

		return () => {
			clearInterval(intervalTimer);
			clearInterval(cleanupTimeout);
			dispatch(resetRealtimeData());
		};
	}, [dispatch]);

	return (
		<div css={chartContainer}>
			<div css={chartSt}>
				<TXNLoadChart />
			</div>
			<div css={chartSt}>
				<TXNLoadTimeChart />
			</div>
			<div css={chartSt}>
				<TXNErrorChart />
			</div>
		</div>
	);
}

const chartContainer = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 466px;
	height: 662px;
`;

const chartSt = css`
	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
	width: 466px;
	/* height: 226px; */
	padding: 16px 28px 0 16px;
	background-color: ${paletteSDS.white};
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastData {
	isCompanyAddressToastVisible: boolean;
	isSubscriptionCancelToastVisible: boolean;
	isSegmentUpdatedToastVisible: boolean;
	isVWRUpdatedToastVisible: boolean;
	isWindowRefreshedToastVisible: boolean;
	isPreferenceChangeToastVisible: boolean;
	isOwnershipTransferToastVisible: boolean;
	isInviteAllSuccessToastVisible: boolean;
	isInviteAllFailToastVisible: boolean;
	isInviteNotAllSuccessToastVisible: boolean;
	isEUMNetworkErrorToastVisible: boolean;
	isBillingInfoChangeToastVisible: boolean;
	isMemberPermissionChangeSuccessVisible: boolean;
	isMemberPermissionChangeFailVisible: boolean;
	isOwnershipTransferFailToastVisible: boolean;
	isPutServiceDomainFailToastVisible: boolean;
	isProjectInactiveToastVisible: boolean;
	isNetfunnelControlsInitialToastVisible: boolean;
	isVWRUpdateFailToastVisible: boolean;
	isProjectLicenseByPassToastVisible: boolean;
}

const initialState: ToastData = {
	isCompanyAddressToastVisible: false,
	isSubscriptionCancelToastVisible: false,
	isSegmentUpdatedToastVisible: false,
	isVWRUpdatedToastVisible: false,
	isWindowRefreshedToastVisible: false,
	isPreferenceChangeToastVisible: false,
	isOwnershipTransferToastVisible: false,
	isInviteAllFailToastVisible: false,
	isInviteAllSuccessToastVisible: false,
	isInviteNotAllSuccessToastVisible: false,
	isEUMNetworkErrorToastVisible: false,
	isBillingInfoChangeToastVisible: false,
	isMemberPermissionChangeFailVisible: false,
	isMemberPermissionChangeSuccessVisible: false,
	isOwnershipTransferFailToastVisible: false,
	isPutServiceDomainFailToastVisible: false,
	isProjectInactiveToastVisible: false,
	isNetfunnelControlsInitialToastVisible: false,
	isVWRUpdateFailToastVisible: false,
	isProjectLicenseByPassToastVisible: false,
};

const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		toggleIsCompanyAddressToastVisible: state => {
			return { ...state, isCompanyAddressToastVisible: !state.isCompanyAddressToastVisible };
		},
		toggleIsSubscriptionCancelToastVisible: state => {
			return { ...state, isSubscriptionCancelToastVisible: !state.isSubscriptionCancelToastVisible };
		},
		toggleIsSegmentUpdatedToastVisible: state => {
			return { ...state, isSegmentUpdatedToastVisible: !state.isSegmentUpdatedToastVisible };
		},
		toggleIsWindowRefreshedToastVisible: state => {
			return { ...state, isWindowRefreshedToastVisible: !state.isWindowRefreshedToastVisible };
		},
		toggleIsVWRUpdatedToastVisible: state => {
			return { ...state, isVWRUpdatedToastVisible: !state.isVWRUpdatedToastVisible };
		},
		toggleIsPreferenceChangeToastVisible: state => {
			return { ...state, isPreferenceChangeToastVisible: !state.isPreferenceChangeToastVisible };
		},
		toggleIsOwnershipTransferToastVisible: state => {
			return { ...state, isOwnershipTransferToastVisible: !state.isOwnershipTransferToastVisible };
		},
		toggleIsInviteAllSuccessToastVisible: state => {
			return { ...state, isInviteAllSuccessToastVisible: !state.isInviteAllSuccessToastVisible };
		},
		toggleIsInviteAllFailToastVisible: state => {
			return { ...state, isInviteAllFailToastVisible: !state.isInviteAllFailToastVisible };
		},
		toggleIsInviteNotAllSuccessToastVisible: state => {
			return { ...state, isInviteNotAllSuccessToastVisible: !state.isInviteNotAllSuccessToastVisible };
		},
		toggleIsBillingInfoChangeToastVisible: state => {
			return { ...state, isBillingInfoChangeToastVisible: !state.isBillingInfoChangeToastVisible };
		},
		toggleIsMemberPermissionChangeSuccessToastVisible: state => {
			return { ...state, isMemberPermissionChangeSuccessVisible: !state.isMemberPermissionChangeSuccessVisible };
		},
		toggleIsMemberPermissionChangeFailToastVisible: state => {
			return { ...state, isMemberPermissionChangeFailVisible: !state.isMemberPermissionChangeFailVisible };
		},
		toggleIsOwnershipTransferFailToastVisible: state => {
			return { ...state, isOwnershipTransferFailToastVisible: !state.isOwnershipTransferFailToastVisible };
		},
		toggleIsPutServiceDomainFailToastVisible: state => {
			return { ...state, isPutServiceDomainFailToastVisible: !state.isPutServiceDomainFailToastVisible };
		},
		setIsEUMNetworkErrorToastVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isEUMNetworkErrorToastVisible: action.payload };
		},
		setInitialToast: () => {
			return { ...initialState };
		},
		setIsNetfunnelControlsInitialToastVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isNetfunnelControlsInitialToastVisible: action.payload };
		},
		setIsProjectInactiveToastVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isProjectInactiveToastVisible: action.payload };
		},
		setIsProjectLicenseByPassToastVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isProjectLicenseByPassToastVisible: action.payload };
		},
		toggleIsVWRUpdateFailToastVisible: state => {
			return { ...state, isVWRUpdateFailToastVisible: !state.isVWRUpdateFailToastVisible };
		},
	},
});

export const {
	setInitialToast,
	toggleIsCompanyAddressToastVisible,
	toggleIsSubscriptionCancelToastVisible,
	toggleIsSegmentUpdatedToastVisible,
	toggleIsVWRUpdatedToastVisible,
	toggleIsWindowRefreshedToastVisible,
	toggleIsPreferenceChangeToastVisible,
	toggleIsOwnershipTransferToastVisible,
	toggleIsInviteAllFailToastVisible,
	toggleIsInviteAllSuccessToastVisible,
	toggleIsInviteNotAllSuccessToastVisible,
	setIsEUMNetworkErrorToastVisible,
	toggleIsBillingInfoChangeToastVisible,
	toggleIsMemberPermissionChangeSuccessToastVisible,
	toggleIsMemberPermissionChangeFailToastVisible,
	toggleIsOwnershipTransferFailToastVisible,
	toggleIsPutServiceDomainFailToastVisible,
	setIsNetfunnelControlsInitialToastVisible,
	setIsProjectInactiveToastVisible,
	toggleIsVWRUpdateFailToastVisible,
	setIsProjectLicenseByPassToastVisible,
} = toastSlice.actions;

export default toastSlice.reducer;

import { useMemo } from 'react';
import moment from 'moment';
import type { ApexOptions } from 'apexcharts';

import { paletteSDS } from 'surf-design-system';
import { convertNumToKMB } from 'features/nfBasic/utils/monitoringDataFunc';

/**
 * author: root
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-08
 * @returns: chart option
 */

interface ChartData {
	x: number;
	y: number | null;
}

const { gray } = paletteSDS;

export interface OptionsParameter {
	maxY: number;
	unit: string;
	timeZone: string;
	decimalsInFloat: number;
	label?: string;
	isAnimate: boolean;
	isConvertYValue: boolean;
	isIntervalActivated: boolean;
}

export interface ApexChartSeries {
	data: ChartData[];
}

export interface CustomTooltipProps {
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			seriesNames: string;
			colors: string;
			initialSeries: Array<ApexChartSeries>;
		};
	};
	series: Array<ChartData[]>;
}

export default function useEUMChartOptions({
	maxY,
	unit,
	timeZone,
	decimalsInFloat,
	label,
	isAnimate,
	isConvertYValue,
	isIntervalActivated,
}: OptionsParameter) {
	const options: ApexOptions = useMemo(
		() => ({
			chart: {
				id: 'area',
				width: '100%',
				height: '100%',
				type: 'area',
				animations: {
					easing: 'linear',
					dynamicAnimation: {
						speed: 200,
					},
					enabled: isAnimate,
				},
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			colors: ['#0D1F66'],
			stroke: {
				width: 1,
				curve: 'straight',
			},
			xaxis: {
				type: 'datetime',
				axisTicks: { show: false },
				axisBorder: { show: false },
				tickAmount: isIntervalActivated ? 5 : 6,
				range: isIntervalActivated ? 300 : 1440 * 60,
				labels: {
					show: true,
					rotate: 0,
					rotateAlways: false,
					trim: false,
					// eslint-disable-next-line
					formatter(value, timestamp?, opts?) {
						const time = moment((Number(value) + (isIntervalActivated ? 60 : 4 * 60 * 60)) * 1000)
							.utcOffset(timeZone.slice(3))
							.format('HH:mm');

						if (time === '00:00') {
							return '24:00';
						}
						return time;
					},
					style: {
						colors: `${gray[600]}`,
						fontWeight: '300',
						fontSize: '10',
						cssClass: 'apexcharts-xaxis-label',
					},
					offsetX: isIntervalActivated ? 45 : 36,
					hideOverlappingLabels: true,
				},
				tooltip: { enabled: false },
			},
			yaxis: {
				decimalsInFloat,
				labels: {
					style: { colors: `${gray[600]}`, fontWeight: '400', fontSize: '10' },
					offsetX: -20,
					minWidth: 48,
					formatter(val: number): string {
						if (isConvertYValue) {
							return convertNumToKMB(val);
						}
						if (val !== null) {
							return val.toString();
						}
						return '';
					},
				},
				max: maxY,
				min: 0,
				forceNiceScale: true,
			},
			grid: {
				show: true,
				borderColor: `${gray[100]}`,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
				padding: {
					right: 20,
					left: -10,
				},
			},
			tooltip: {
				enabled: true,
				followCursor: true,
				custom({ series, seriesIndex, dataPointIndex, w }: CustomTooltipProps) {
					const {
						globals: { colors },
					} = w;

					const tooltip = `
          <div class="curr">
            <div class="color" style="background-color:${colors[seriesIndex]};"></div>
            <div>${label}</div>
            <div class="value">${
							series[seriesIndex][dataPointIndex] === null ? '' : series[seriesIndex][dataPointIndex]
						}${unit}</div>
          </div>
        `;

					return tooltip;
				},
			},
			legend: {
				show: false,
			},
			fill: {
				opacity: 0.1,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxY, unit, isAnimate, isConvertYValue, isIntervalActivated, timeZone]
	);

	return {
		apexLineOption: options,
	};
}

import { LANG_KEY } from 'common/data/constants';
import i18next from 'i18next';

export const getBrowserLanguage = () => {
	const { language, userLanguage, systemLanguage } = navigator;
	const i18nLanguage = localStorage.getItem(LANG_KEY);

	if (i18nLanguage === 'ko') {
		return 'ko-KR';
	}

	if (i18nLanguage === 'en') {
		return 'en-US';
	}

	if (language) {
		return language;
	}

	if (userLanguage) {
		return userLanguage;
	}

	return systemLanguage || 'en-US';
};

export const getI18nLanguage = () => {
	const i18nLanguage = localStorage.getItem(LANG_KEY);

	if (i18nLanguage === 'ko') {
		return 'KOREAN';
	}

	return 'ENGLISH';
};

export const getI18nPath = () => {
	const i18nLanguage = localStorage.getItem(LANG_KEY);

	if (i18nLanguage === 'ko') {
		return 'ko';
	}

	return 'en';
};

export const putI18nLanguage = async (locale: string) => {
	localStorage.setItem(LANG_KEY, locale);
	await i18next.changeLanguage(locale);
};

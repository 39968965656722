import { createSlice } from '@reduxjs/toolkit';

import { postOrganizationPaymentInfoNonKoreanThunk } from 'features/plan/thunk/postOrganizationPaymentInfoNonKorean.thunk';
import { getOrganizationPaymentInfoThunk } from '../thunk/getOrganizationPaymentInfo.thunk';
import { postOrganizationPaymentInfoThunk } from '../thunk/postOrganizationPaymentInfo.thunk';

export interface PaymentInfoData {
	// eslint-disable-next-line
	error: any;
	isLoaded: boolean;
	id: string;
	name: string;
	number: number;
	isLoading: boolean;
}

const initialState: PaymentInfoData = {
	id: '',
	name: '',
	number: 0,
	error: null,
	isLoaded: false,
	isLoading: false,
};

const paymentInfoSlice = createSlice({
	name: 'paymentInfo',
	initialState,
	reducers: {
		setInitialPaymentInfo: () => {
			return { ...initialState };
		},
	},
	extraReducers(builder) {
		builder.addCase(getOrganizationPaymentInfoThunk.fulfilled, (_, action) => {
			return {
				id: action.payload.id,
				name: action.payload.name,
				number: action.payload.number,
				error: null,
				isLoaded: true,
				isLoading: false,
			};
		});

		builder.addCase(getOrganizationPaymentInfoThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(postOrganizationPaymentInfoThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(postOrganizationPaymentInfoThunk.fulfilled, (state, action) => {
			return {
				...state,
				...action.payload,
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(postOrganizationPaymentInfoThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});

		builder.addCase(postOrganizationPaymentInfoNonKoreanThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(postOrganizationPaymentInfoNonKoreanThunk.fulfilled, (state, action) => {
			return {
				...state,
				error: null,
				isLoading: false,
				id: action.payload.id,
				name: action.payload.name,
				number: action.payload.number,
			};
		});

		builder.addCase(postOrganizationPaymentInfoNonKoreanThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoading: false };
		});
	},
});

export const { setInitialPaymentInfo } = paymentInfoSlice.actions;

export default paymentInfoSlice.reducer;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Portal, Modal } from 'surf-design-system';

import postOrganizationDomainApi from 'features/signup/api/postOrganizationDomain.api';
import CustomerDomainInput from 'features/signup/components/CustomerDomainInput';
import RegisterDomainTitle from 'features/signup/components/RegisterDomainTitle';
import {
	initialRegisterDomainForm,
	registerDomainFormReducer,
	UPDATE_CUSTOMER_DOMAIN,
	UPDATE_SUB_DUP,
	VALIDATE_CUSTOMER_DOMAIN,
} from 'features/signup/context/registerDomain.reducer';

import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import useApiRequestNoArg from 'common/hooks/useApiRequestNoArg';
import getOrganizationListApi from 'common/api/getOrganizationList.api';
import { useAppDispatch, RootState } from 'common/redux/store';
import { changeIsBlockRegisterDomainModalVisible } from 'common/context/modalSlice';
import { getI18nPath } from 'common/utils/languageFunc';

export default function RegisterDomainPage() {
	const { response: getOrganizationListResponse, sendRequest: getOrganizationList } =
		useApiRequestNoArg(getOrganizationListApi);

	const {
		response: postOrganizationDomainResponse,
		sendRequest: postOrganizationDomain,
		error,
		isLoading,
	} = useApiRequestWithArg(postOrganizationDomainApi);

	const organizationId = useMemo(() => {
		if (getOrganizationListResponse === null) {
			return 0;
		}
		return getOrganizationListResponse.data[0].id;
	}, [getOrganizationListResponse]);

	const [registerDomainFormData, setRegisterDomainFormData] = useReducer(
		registerDomainFormReducer,
		initialRegisterDomainForm
	);

	const {
		customerDomain,
		isCustomerDomainChanged,
		isCustomerDomainValid,
		customerDomainValidation,
		customerDomainHelpText,
	} = registerDomainFormData;

	const { isBlockRegisterDomainModalVisible } = useSelector((state: RootState) => state.modal);

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const customerDomainChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setRegisterDomainFormData({ type: UPDATE_CUSTOMER_DOMAIN, payload: e.target.value });
	};

	const customerDomainBlurHandler = () => {
		setRegisterDomainFormData({ type: VALIDATE_CUSTOMER_DOMAIN });
	};

	const registerDomainSubmitHandler: React.FormEventHandler = async e => {
		e.preventDefault();
		await postOrganizationDomain({ organizationId, defaultCustomerUrl: customerDomain });
	};

	const blockModalVisibleChangeHandler = () => {
		dispatch(changeIsBlockRegisterDomainModalVisible());
	};

	useEffect(() => {
		if (postOrganizationDomainResponse && postOrganizationDomainResponse.statusCode === 201) {
			navigate({
				pathname: `/${getI18nPath()}/signup/agent/select`,
			});
		}
	}, [postOrganizationDomainResponse, navigate, dispatch, organizationId]);

	useEffect(() => {
		if (error && error?.errorCode === 'O1003') {
			dispatch(changeIsBlockRegisterDomainModalVisible());
		}

		if (error && error?.errorCode === 'TN1001') {
			setRegisterDomainFormData({ type: UPDATE_SUB_DUP, payload: 'duplicated' });
		}
	}, [error, dispatch]);

	useEffect(() => {
		getOrganizationList();
	}, [getOrganizationList]);

	if (getOrganizationListResponse === null) return <div />;

	return (
		<React.Fragment>
			<div css={container}>
				<form css={wrapSt} onSubmit={registerDomainSubmitHandler}>
					<RegisterDomainTitle />
					<CustomerDomainInput
						customerDomainChangeHandler={customerDomainChangeHandler}
						customerDomainBlurHandler={customerDomainBlurHandler}
						customerDomain={customerDomain}
						isCustomerDomainChanged={isCustomerDomainChanged}
						isCustomerDomainValid={isCustomerDomainValid}
						customerDomainValidation={customerDomainValidation}
						customerDomainHelpText={customerDomainHelpText}
					/>
					<Button
						size="medium"
						mould="primary"
						width="100%"
						type="submit"
						disabled={!isCustomerDomainValid || isLoading}
					>
						{t('AddDomain.done')}
					</Button>
				</form>
			</div>
			<Portal>
				<Modal
					visible={isBlockRegisterDomainModalVisible}
					title={t('modal.notAccessibleModal')}
					actionType="single"
					primaryBtnLabel={t('modal.tenantConfirm')}
					primaryBtnFunction={blockModalVisibleChangeHandler}
					isCloseIconVisible={false}
					onClickOverlay={blockModalVisibleChangeHandler}
				>
					{t('modal.tenantAlreadyCreated')}
				</Modal>
			</Portal>
		</React.Fragment>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 5rem;

	width: 100%;
`;

const wrapSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	width: 25rem;
`;

export const X_AXIS_NUM = 21;
export const X_AXIS_INTERVAL_TIME = 3;
export const X_AXIS_VALUE_INTERVAL = 1;

export const maxWaitTicketY = 50;
export const intervalWaitTicketY = 5;
export const tickamountWaitTicketY = 11;
export const waitTicketDecimalInFloat = 1;
export const waitTicketMaxYratio = 1.5;

export const maxOutflowRateY = 100;
export const intervalOutflowRateY = 20;
export const tickamountOutflowRateY = 5;
export const outflowRateDecimalInFloat = 0;

export const maxProcessTimeY = 3;
export const intervalProcessTimeY = 0.3;
export const tickamountProcessTimeY = 11;
export const processTimeDecimalInFloat = 1;
export const processTimeMaxYratio = 1.5;

export const SEG_TIME_RANGE = 60;
export const PRJ_TIME_RANGE = 3;

export const MAX_INFLOW_LENGTH = 50;
export const MAX_SEG_TITLE_LENGTH = 50;

export const TRACK_STROKE = 4;

export const MAX_GAUGE = 120;
export const MIN_GAUGE = 0;

export const BOUNDARY_FAST = 3;
export const BOUNDARY_MED = 6;

export const DEFAULT_TRACK_STROKE = 25;
export const DEFAULT_TRACK_PADDING = 0;

export const FETCH_INTERVAL = 3000;

export const INTERVAL_BEGIN = 1;
export const INTERVAL_END = 4;

export const IS_RELOAD_KEY = 'isReload';
export const IS_RELOAD_TRUE = 'true';

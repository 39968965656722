import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutBasicControlSegmentOrderApiResponse } from 'features/nfControl/type/putBasicControlSegmentOrder.v2.type';

export interface PutSegmentOrderApiBody {
	id: number;
	segmentKey: string;
	segmentOrder: number;
}

interface PutBasicControlSegmentOrderApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
	order: PutSegmentOrderApiBody[];
}

export default async function putBasicControlSegmentOrderApi({
	tenantId,
	organizationId,
	projectId,
	order,
}: PutBasicControlSegmentOrderApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.put<PutBasicControlSegmentOrderApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/segment/order`,
			{ data: order.slice() },
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { paletteSDS, themeSDS, backgroundPalette, colorPalette, themeCss } from 'surf-design-system';

import useApiRequestNoArg from 'common/hooks/useApiRequestNoArg';
import { RootState } from 'common/redux/store';
import { LANG_PATH, NETFUNNEL } from 'common/data/constants';
import DividerWithTxt from 'common/components/DividerWithTxt';

import PlanTitle from 'features/plan/components/PlanTitle';
import PlanList from 'features/plan/components/PlanList';
import PlanOptionList from 'features/plan/components/PlanOptionList';
import PlanFeatureCard from 'features/plan/components/PlanFeatureCard';
import OveragesInfoModal from 'features/plan/components/OveragesInfoModal';
import getPlanListApi from 'features/plan/api/getPlanList.api';
import type { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';

export default function ChoosePlan() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { language } = useSelector((state: RootState) => state.account);

	const { response, sendRequest } = useApiRequestNoArg(getPlanListApi);

	const { products, isLoaded: isSubscriptionLoaded } = useSelector((state: RootState) => state.subscription);
	const { planId, isLoaded: isPlanLoaded } = useSelector((state: RootState) => state.plan);

	const [selectedPlanId, setSelectedPlanId] = useState<number>(planId);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	useEffect(() => {
		if (isPlanLoaded) {
			setSelectedPlanId(planId);
		}
	}, [isPlanLoaded, planId]);

	if (response === null || !isPlanLoaded || !isSubscriptionLoaded) return <div css={containerSt} />;

	const netFunnelSubscriptionInfo = products.find(el => el.productType === NETFUNNEL) as SubscriptionData;

	const planList = response.concat({
		planId: -1,
		priceUnit: '',
		price: 0,
		productType: '',
		period: 1,
		version: 1,
		periodUnit: '',
		planOptions: [],
		planOverages: [],
		createdAt: '',
		updatedAt: '',
		planLangs: [
			{
				langType: 'KOREAN',
				name: '엔터프라이즈',
				description: '문의를 통해 당신의 비즈니스에 맞는 플랜을 찾아보세요.',
			},
			{ langType: 'ENGLISH', name: 'Enterprise', description: 'Contact us to find the right plan for your business.' },
		],
	});

	const planSubmitHandler: React.FormEventHandler<HTMLFormElement> = event => {
		event.preventDefault();

		navigate({
			pathname: `/${LANG_PATH[language]}/home/plan_usage/plan/subscription`,
			search: qs.stringify(
				{
					id: selectedPlanId,
					version: planList[selectedPlanId].version,
				},
				{ encode: true }
			),
		});
	};

	const planChangeHandler: React.ChangeEventHandler<HTMLInputElement> = event => {
		setSelectedPlanId(Number(event.target.value));
	};

	const cancelPlanCss = [
		cancelPlanSt,
		colorPalette.black,
		backgroundPalette.blue[50],
		themeCss.fontSize[14],
		themeCss.fontWeight.regular,
	];

	return (
		<React.Fragment>
			<div css={containerSt}>
				<PlanTitle title={t('breadCrumb.plan')} />
				<div css={wrapperSt}>
					{netFunnelSubscriptionInfo.status !== 'SUBSCRIBING' && (
						<div css={cancelPlanCss}>
							<div>{t('planSubscription.restartNotification')}</div>
						</div>
					)}
					<form css={formSt} onSubmit={planSubmitHandler}>
						{selectedPlanId !== 0 ? (
							<PlanList
								plans={planList}
								onChange={planChangeHandler}
								selectedPlanId={selectedPlanId}
								planSubscription={netFunnelSubscriptionInfo.status === 'SUBSCRIBING'}
							/>
						) : null}
						{selectedPlanId !== 0 ? (
							<PlanFeatureCard selectedPlan={planList.find(el => el.planId === selectedPlanId) || planList[0]} />
						) : null}
					</form>
					<DividerWithTxt />
					<PlanTitle title={t('planSubscription.features')} />
					<PlanOptionList plans={planList} />
					<ol css={planRuleSt}>
						<li>{t('planSubscription.planCancelDetailMsg')}</li>
						<li>{t('planSubscription.usageRequestDetailMsg')}</li>
					</ol>
				</div>
			</div>
			<OveragesInfoModal plans={planList} />
		</React.Fragment>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const wrapperSt = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const planRuleSt = css`
	line-height: 160%;

	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.medium};
	color: ${paletteSDS.gray[700]};
	letter-spacing: 0.0128em;

	> li {
		list-style: outside;
		//text-indent: -10px;
		margin-left: 15px;
	}
`;

const formSt = css`
	display: flex;
	gap: 1.5rem;
`;

const cancelPlanSt = css`
	display: flex;
	align-items: center;

	height: 46px;

	padding: 12px 16px;

	border: 1px solid ${paletteSDS.blue[200]};
	border-radius: 8px;
	line-height: 160%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { RootState, useAppDispatch } from 'common/redux/store';
import { getAccountInfoOnPremThunk } from 'common/thunk/getAccountInfo.onPrem.thunk';
import { COGNITO_TOKEN, LANG_PATH, USER_KEY } from 'common/data/constants';
import { getI18nPath } from 'common/utils/languageFunc';
import axiosInstance from 'common/utils/axiosInstance';

export default function AccountSetting() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { error } = useSelector((state: RootState) => state.account);

	useEffect(() => {
		const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
		const userKey = window.sessionStorage.getItem(USER_KEY);

		if (cognitoToken === null) {
			window.location.replace(`/${getI18nPath()}/on_prem/signin`);
			return;
		}

		axiosInstance.defaults.headers.Authorization = cognitoToken;
		axiosInstance.defaults.headers.userKey = userKey;

		(async () => {
			if (error) {
				// eslint-disable-next-line
				console.log(error.error);
			}

			const accountInfoAction = await dispatch(getAccountInfoOnPremThunk());

			const accountInfo = unwrapResult(accountInfoAction);

			if (accountInfo.userStatus === 'DISABLED') {
				navigate(`/${getI18nPath()}/on_prem/signin`, { replace: true });
				return;
			}

			navigate(`/${LANG_PATH[accountInfo.language]}/on_prem/home`, { replace: true });
		})();

		// eslint-disable-next-line
	}, [dispatch, error]);

	return (
		<div css={container}>
			<svg width="48px" height="48px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" css={spinnerSt}>
				<path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
			</svg>
		</div>
	);
}

const spinnerSt = css`
	transform-origin: center;

	@keyframes spinner_svv2 {
		to {
			transform: rotate(360deg);
		}
	}
	animation: spinner_svv2 0.75s infinite linear;
`;

const container = css`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { setIsSegmentAddModalVisible, setIsSegmentMaxNumModalVisible } from 'common/context/modalSlice';

import { isAxiosError } from 'common/api/isAxiosError';

import type { PostSegmentApiError } from 'features/nfBasic/types/postSegment.type';

import postSegmentApi from '../api/postSegment.api';

import type { URI } from '../types/getSegmentList.type';

export interface PostSegmentThunkResult {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: number;
	uris: URI[];
	segmentId: number;
	segmentKey: string;
}

export interface PostSegmentThunkProps {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: string;
	enteredUri: string;
	onSuccess?: () => void;
}

export const postSegmentThunk = createAsyncThunk<PostSegmentThunkResult, PostSegmentThunkProps, { state: RootState }>(
	'segment/post',
	async (args, thunkAPI) => {
		const {
			account: { tenantId, tenantUrl, organizationId },
			prjMeta: { selectedProjectId },
		} = thunkAPI.getState();

		const { segmentColor, segmentName, accessYn, maxInflow, enteredUri, onSuccess } = args;

		const PostSegmentApiBody = {
			segmentColor,
			segmentName,
			accessYn,
			maxInflow: Number(maxInflow),
			uris: [{ path: enteredUri }],
		};

		try {
			const {
				data: { uris, id, segmentKey, segmentColor: segmentColorResponse },
			} = await postSegmentApi({
				tenantId: tenantId || '',
				tenantUrl,
				prjId: selectedProjectId,
				organizationId,
				body: PostSegmentApiBody,
			});

			if (onSuccess) {
				onSuccess();
			} else {
				thunkAPI.dispatch(setIsSegmentAddModalVisible(false));
			}

			return {
				segmentColor: segmentColorResponse,
				segmentName,
				accessYn,
				maxInflow: Number(maxInflow),
				uris,
				segmentKey,
				segmentId: id,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			if (isAxiosError<PostSegmentApiError>(error)) {
				if (error.response?.data.errorCode === 'S1013') {
					thunkAPI.dispatch(setIsSegmentAddModalVisible(false));
					thunkAPI.dispatch(setIsSegmentMaxNumModalVisible(true));
				}
			}

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

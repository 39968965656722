/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsChangePasswordModalVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';

/**
 * author: ian
 * createdAt: 2022-10-27
 * updatedAt: 2022-10-27
 * description: 비밀번호 변경 후 동작 안내 모달
 */
export default function OnPremChangePasswordModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isChangePasswordModalVisible } = useSelector((state: RootState) => state.modal);
	const { language } = useSelector((state: RootState) => state.account);

	const primaryBtnClickHandler = () => {
		dispatch(toggleIsChangePasswordModalVisible());
		sessionStorage.clear();
		window.location.replace(`/${LANG_PATH[language]}/on_prem/signin`);
	};

	return (
		<Portal>
			<Modal
				visible={isChangePasswordModalVisible}
				actionType="single"
				primaryBtnLabel={t('resetPassword.confirm')}
				primaryBtnFunction={primaryBtnClickHandler}
				onClickOverlay={primaryBtnClickHandler}
				isCloseIconVisible={false}
			>
				<div>{t('resetPassword.resetPwdMsg')}</div>
			</Modal>
		</Portal>
	);
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';
import { paletteSDS } from 'surf-design-system';
import useTXNLineChartOptions from 'features/nfEUM/hooks/useTXNLineChartOptions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'common/redux/store';
import TXNChartTitle from 'features/nfEUM/components/TXNChartTitle';
import TXNChartCount from 'features/nfEUM/components/TXNChartCount';
import { calcMaxIntFn } from 'features/nfEUM/utils/chartFunc';

export default function TXNErrorChart() {
	const { t } = useTranslation();
	const { error } = useSelector((state: RootState) => state.eumTXNRealtime);
	const { timeZone } = useSelector((state: RootState) => state.account);
	const maxY = Math.max(calcMaxIntFn(error.annotation.web.y, error.annotation.native.y), 4);

	const { apexLineOption } = useTXNLineChartOptions({
		maxY,
		timeZone,
		isChartTypeArea: true,
		isErrorChart: true,
		annotation: error.annotation,
	});

	return (
		<div>
			<TXNChartTitle
				title={t('eum.error')}
				labels={[
					{ color: paletteSDS.categoryColor.purple, label: 'Web', id: 0 },
					{ color: paletteSDS.red[300], label: 'Native', id: 1 },
				]}
			/>
			<TXNChartCount title={t('eum.last5MinutesTotal')} count={error.totalCount} />
			<div css={chartCss}>
				<ReactApexChart
					type="area"
					width="426px"
					height="144px"
					series={[
						{
							name: 'web',
							data: error.data.web,
							color: paletteSDS.categoryColor.purple,
						},
						{
							name: 'native',
							data: error.data.native,
							color: paletteSDS.red[300],
						},
					]}
					options={apexLineOption}
				/>
			</div>
		</div>
	);
}

const chartCss = css`
	margin-top: -10px;
`;

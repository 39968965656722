/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { ReactNode } from 'react';
import { paletteSDS, Portal } from 'surf-design-system';

interface AltTextStyle {
	textBoxMaxWidth?: string;
	x: number;
	y: number;
}

export interface AltTextProps extends AltTextStyle {
	text: ReactNode;
}

export default function AltText({ text, textBoxMaxWidth, x, y }: AltTextProps) {
	return (
		<Portal>
			<div css={textBox({ textBoxMaxWidth, x, y })}>{text}</div>
		</Portal>
	);
}

const textBox = ({ textBoxMaxWidth, x, y }: AltTextStyle) => css`
	z-index: 10;
	position: absolute;
	top: ${22 + (y || 0)}px;
	left: ${0 + (x || 0)}px;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	padding: 4px 8px;
	background-color: #fff;
	width: max-content;
	max-width: ${textBoxMaxWidth};
	word-break: break-all;
	color: ${paletteSDS.gray[900]};
	font-weight: 600;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: 0.32px;
`;

import { validationSDS } from 'surf-design-system';
import { rID } from 'features/signin/utils/regex';

export interface SigninForm {
	id: string;
	isIdValid: boolean;
	idValidation: validationSDS;
	password: string;
	passwordValidation: validationSDS;
	isPasswordValid: boolean;
	isPasswordChange: boolean;
	isIdChanged: boolean;
	idHelpText: string;
	passwordHelpText: string;
}

export interface SigninFormAction {
	type?: string;
	payload?: string;
}

export const initialSigninForm: SigninForm = {
	id: '',
	isIdValid: false,
	idValidation: 'failed',
	password: '',
	passwordValidation: 'failed',
	isIdChanged: false,
	idHelpText: 'onPrem.signin.idInvalid',
	passwordHelpText: '',
	isPasswordValid: false,
	isPasswordChange: false,
};

export const UPDATE_ID = 'UPDATE_ID';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const VALIDATE_ID = 'VALIDATE_ID';
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const UPDATE_PW_HELP_TEXT = 'UPDATE_PW_HELP_TEXT';
export const AUTO_FILL = 'AUTO_FILL';

export const signinFormReducer = (state: SigninForm, action: SigninFormAction): SigninForm => {
	switch (action.type) {
		case UPDATE_ID: {
			const idPayload = action.payload as string;

			const isIDPayloadLengthValid = idPayload.length > 3;

			const isIDPayloadFormValid = rID.test(idPayload);

			const isIDPayloadValid = isIDPayloadFormValid && isIDPayloadLengthValid;

			return {
				...state,
				id: idPayload,
				isIdValid: isIDPayloadValid,
				idValidation: isIDPayloadValid ? 'none' : 'failed',
				isIdChanged: true,
				passwordValidation: 'none',
				passwordHelpText: '',
			};
		}

		case UPDATE_PASSWORD: {
			const isPasswordPayloadValid = action.payload ? action.payload.trim().length > 0 : false;

			return {
				...state,
				password: action.payload ? action.payload : '',
				passwordValidation: isPasswordPayloadValid ? 'none' : 'failed',
				passwordHelpText: '',
				isPasswordValid: isPasswordPayloadValid,
				isPasswordChange: true,
			};
		}

		case VALIDATE_ID: {
			const isEmailStateValid = rID.test(state.id) && state.id.length > 3;

			return {
				...state,
				isIdValid: state.isIdChanged ? isEmailStateValid : false,
				idValidation: state.isIdChanged && isEmailStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_PW_HELP_TEXT: {
			return {
				...state,
				passwordValidation: 'failed',
				passwordHelpText: action.payload ? action.payload : '',
				isPasswordChange: true,
			};
		}

		case AUTO_FILL: {
			return { ...state, isIdValid: true, isPasswordValid: true };
		}

		default:
			return initialSigninForm;
	}
};

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import cardSlot from 'features/plan/assets/cardSlot.png';

import { last4CardNumberFormat } from '../utils/formatFunc';

/**
 * author: Finn
 * createdAt: 2022-09-15
 * updatedAt: 2022-09-15
 * description: 플랜 페이지에서 보여지는 카드 컴포넌트
 * todo: 실제 데이터 연결
 */
export default function CreditCard() {
	const { name, number } = useSelector((state: RootState) => state.payment);

	return (
		<div css={creditCardSt}>
			<p>{name}</p>
			<p>**** **** **** {last4CardNumberFormat(number)}</p>
		</div>
	);
}

const creditCardSt = css`
	position: relative;
	width: 334px;
	height: 210px;

	color: ${paletteSDS.gray[50]};

	background-image: url(${cardSlot});
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;

	> p:nth-of-type(1) {
		position: absolute;
		top: 4.5rem;
		left: 4.25rem;

		font-weight: ${themeSDS.fontWeight.bold};
		line-height: 1.1875rem;
	}

	> p:nth-of-type(2) {
		position: absolute;
		top: 8.125rem;
		left: 2.4244rem;

		font-size: ${themeSDS.fontSize[18]};
		font-weight: ${themeSDS.fontWeight.regular};
		line-height: 1.8125rem;
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Checkbox, Dropdown, DropdownElement, Link, Textinput } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';

import {
	initialSignupOauthForm,
	signupOauthFormReducer,
	UPDATE_COMPANY_NAME,
	UPDATE_REGION,
	UPDATE_TERM_CHECKED,
	VALIDATE_COMPANY_NAME,
	UPDATE_COUNTRY,
} from '../context/signupOauthForm.reducer';

import { MAX_COMPANY_NAME_CHAR_NUM } from '../data/constants';

export interface SignupFormProps {
	availRegionList: DropdownElement[];
	availCountryList: DropdownElement[];
}

export default function SignupOauthForm({ availRegionList, availCountryList }: SignupFormProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [signupOauthFormData, setSignupOauthFormData] = useReducer(signupOauthFormReducer, initialSignupOauthForm);
	const [isLoading, setIsLoading] = useState(false);

	const {
		region,
		isRegionValid,
		companyName,
		isCompanyNameValid,
		companyNameValidation,
		companyNameHelpText,
		isCompanyNameChanged,
		isTermChecked,
		country,
		isCountryValid,
	} = signupOauthFormData;

	const companyNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSignupOauthFormData({ type: UPDATE_COMPANY_NAME, payload: e.target.value });
	};

	const isTermCheckedChangeHandler = () => {
		setSignupOauthFormData({ type: UPDATE_TERM_CHECKED });
	};

	const regionChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setSignupOauthFormData({ type: UPDATE_REGION, payload: e.target.value });
	};

	const companyNameBlurHandler = () => {
		setSignupOauthFormData({ type: VALIDATE_COMPANY_NAME });
	};

	const countryChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setSignupOauthFormData({ type: UPDATE_COUNTRY, payload: e.target.value });
	};

	const signupOathFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault();
		setIsLoading(true);

		const callback = `${window.location.protocol}//${window.location.host}/${getI18nPath()}/callback`;
		const scope = 'aws.cognito.signin.user.admin email openid profile';
		const type = 'code';
		const state = encodeURIComponent(`signup;${region};${companyName};${country}`);

		window.location.href = `https://${process.env.REACT_APP_AWS_OAUTH_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${callback}&response_type=${type}&client_id=${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}&scope=${scope}&state=${state}`;
	};

	const linkClickHandler = () => {
		navigate(`/${getI18nPath()}/signup`);
	};

	return (
		<form css={formSt} onSubmit={signupOathFormSubmitHandler}>
			<div css={buttonWrap}>
				<Dropdown
					label={t('commonWords.country')}
					menu={availCountryList}
					placeholder={t('singnUp.countryPlaceholder')}
					width="100%"
					onChange={countryChangeHandler}
					bindedValue={country}
				/>
				<Dropdown
					label={t('commonWords.region')}
					menu={availRegionList}
					placeholder={t('singnUp.regionMsg')}
					width="100%"
					onChange={regionChangeHandler}
					bindedValue={region}
				/>
				<Textinput
					label={t('commonWords.companyName')}
					value={companyName}
					validation={isCompanyNameChanged ? companyNameValidation : 'none'}
					helpText={isCompanyNameChanged && !isCompanyNameValid && t(companyNameHelpText)}
					onChange={companyNameChangeHandler}
					onBlur={companyNameBlurHandler}
					maxLength={MAX_COMPANY_NAME_CHAR_NUM}
					showIcon
				/>
			</div>
			<Checkbox
				id="terms"
				label={
					<Trans
						i18nKey="singnUp.agreeComment"
						components={{
							terms: (
								<Link
									size="small"
									onClick={e => {
										e.preventDefault();
										window.open(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}/terms`, '_blank');
									}}
									inline
								/>
							),
							privacy: (
								<Link
									size="small"
									onClick={e => {
										e.preventDefault();
										window.open(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}/privacy`, '_blank');
									}}
									inline
								/>
							),
							cookie: (
								<Link
									size="small"
									onClick={e => {
										e.preventDefault();
										window.open(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}/cookies`, '_blank');
									}}
									inline
								/>
							),
						}}
					/>
				}
				checked={isTermChecked}
				onChange={isTermCheckedChangeHandler}
				isLabelColorToggleAvailable={false}
			/>
			<Button
				width="100%"
				type="submit"
				disabled={!isCompanyNameValid || !isRegionValid || !isTermChecked || !isCountryValid || isLoading}
			>
				{t('signIn.signUp')}
			</Button>
			<div css={linkBox}>
				<Link size="medium" onClick={linkClickHandler}>
					{t('singnUp.signUpWthEmail')}
				</Link>
			</div>
		</form>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	height: fit-content;
`;

const buttonWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const linkBox = css`
	display: flex;
	align-items: center;
	justify-content: center;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import { Button, ChevronRightIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';
import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';

interface NetFunnelSubscribingProps {
	planNames: PlanLangs[];
	chargeDateTime: string | null;
}

export default function NetFunnelSubscribing({ planNames, chargeDateTime }: NetFunnelSubscribingProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { language } = useSelector((state: RootState) => state.account);

	const planLangs = planNames.find(el => el.langType === language) as PlanLangs;

	const netFunnelClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/console/product/nf/home`);
	};

	return (
		<div css={productBox} onClick={netFunnelClickHandler} aria-hidden>
			<div css={gap8pxColumn}>
				<div css={netfunnelSt}>NetFUNNEL</div>
				<div css={gap4pxColumn}>
					<div css={gap8px}>
						<div css={productInfoLabelSt}>{t('console.main.planInUse')}</div>
						<div css={productInfoSt}>{planLangs.name}</div>
					</div>
					<div css={gap8px}>
						<div css={productInfoLabelSt}>{t('console.main.billingDate')}</div>
						<div css={productInfoSt}>
							{moment(chargeDateTime || '')
								.utcOffset(0)
								.format('MMM. DD. YYYY.')}
						</div>
					</div>
				</div>
			</div>
			<Button mould="icon" icon={<ChevronRightIcon />} size="large" />
		</div>
	);
}

const productBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 140px;

	padding: 24px;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};
	border-radius: 8px;

	cursor: pointer;

	:hover {
		outline: 2px solid ${paletteSDS.blue['700']};
		border: 1px solid transparent;
	}
`;

const netfunnelSt = css`
	color: ${paletteSDS.blue['800']};
	font-weight: 800;
	font-size: ${themeSDS.fontSize['20']};
	line-height: 140%;
`;

const gap8pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap4pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const gap8px = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const productInfoLabelSt = css`
	width: 160px;

	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['16']};

	line-height: 160%;
`;

const productInfoSt = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['16']};

	line-height: 140%;
`;

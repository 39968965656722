import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutData {
	isSNBExpand: boolean;
	isSettingMenuExpand: boolean;
	isNFSubMenuExpand: boolean;
	isProfileMenuVisible: boolean;
	isHelpMenuVisible: boolean;
	isProjectMenuVisible: boolean;
	isSaasSNBExpand: boolean;
	isOnPremSNBExpand: boolean;
	isOnPremProjectMenuVisible: boolean;
}

const initialState: LayoutData = {
	isSNBExpand: true,
	isSettingMenuExpand: false,
	isNFSubMenuExpand: true,
	isHelpMenuVisible: false,
	isProfileMenuVisible: false,
	isProjectMenuVisible: false,
	isSaasSNBExpand: true,
	isOnPremSNBExpand: true,
	isOnPremProjectMenuVisible: false,
};

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		toggleExpandSNB: state => {
			return {
				...state,
				isSNBExpand: !state.isSNBExpand,
				isNFSubMenuExpand: !state.isSNBExpand,
				isSettingMenuExpand: state.isSNBExpand ? false : state.isSettingMenuExpand,
			};
		},
		setIsSNBExpand: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSNBExpand: action.payload };
		},
		toggleExpandNFSubMenu: state => {
			return { ...state, isNFSubMenuExpand: !state.isNFSubMenuExpand };
		},
		setIsNFSubMenuExpand: (state, action: PayloadAction<boolean>) => {
			return { ...state, isNFSubMenuExpand: action.payload };
		},
		setIsTopNotiBar: (state, action: PayloadAction<boolean>) => {
			return { ...state, isTopNotiBar: action.payload };
		},
		toggleIsSettingMenuOpen: state => {
			return { ...state, isSettingMenuExpand: !state.isSettingMenuExpand };
		},
		setInitialLayout: () => {
			return { ...initialState };
		},
		toggleIsProfileMenuVisible: state => {
			return { ...state, isProfileMenuVisible: !state.isProfileMenuVisible };
		},
		toggleIsHelpMenuVisible: state => {
			return { ...state, isHelpMenuVisible: !state.isHelpMenuVisible };
		},
		toggleIsProjectMenuVisible: state => {
			return { ...state, isProjectMenuVisible: !state.isProjectMenuVisible };
		},
		toggleIsOnPremProjectMenuVisible: state => {
			return { ...state, isOnPremProjectMenuVisible: !state.isOnPremProjectMenuVisible };
		},
		toggleIsSaasSNBExpand: state => {
			return { ...state, isSaasSNBExpand: !state.isSaasSNBExpand };
		},
		toggleIsOnPremSNBExpand: state => {
			return { ...state, isOnPremSNBExpand: !state.isOnPremSNBExpand };
		},
	},
});

export const {
	toggleExpandSNB,
	toggleIsSettingMenuOpen,
	setInitialLayout,
	toggleExpandNFSubMenu,
	setIsSNBExpand,
	toggleIsProfileMenuVisible,
	toggleIsHelpMenuVisible,
	toggleIsProjectMenuVisible,
	toggleIsSaasSNBExpand,
	toggleIsOnPremSNBExpand,
	toggleIsOnPremProjectMenuVisible,
} = layoutSlice.actions;

export default layoutSlice.reducer;

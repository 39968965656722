import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import getOrganizationDataApi from 'common/api/getOrganizationDetail.api';
import getOrganizationListApi from 'common/api/getOrganizationList.api';
import getUserProfileApi from 'common/api/getUserProfile.api';
import axiosInstance from 'common/utils/axiosInstance';

import { DEFAULT_IMG_URL } from 'common/data/constants';

export const getAccountInfoThunk = createAsyncThunk('accountInfo/get', async (_, thunkAPI) => {
	try {
		const { data: organizationList } = await getOrganizationListApi();
		const initialOrganizationData = organizationList[0];
		const { id: initialOrganizationId } = initialOrganizationData;

		axiosInstance.defaults.headers.organizationId = initialOrganizationId;

		const { data: userProfileData } = await getUserProfileApi();

		const {
			name: userName,
			email: userEmail,
			imageUrl,
			language,
			timezone: timeZone,
			createdAt,
			updatedAt,
			selectedOrganizationId,
		} = userProfileData;

		const {
			role: userRole,
			status: userStatus,
			name: organizationName,
		} = organizationList.find(el => el.id === selectedOrganizationId) || organizationList[0];

		const { data: organizationDetail } = await getOrganizationDataApi(selectedOrganizationId || initialOrganizationId);

		const {
			defaultCustomerDomain: customerDomain,
			domainUrl: tenantUrl,
			tenantId,
			region,
			freeTrialYn: isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			status: tenantStatus,
			port: tenantPort,
			reason,
			code,
			country,
		} = organizationDetail;

		await i18next.changeLanguage(language === 'KOREAN' ? 'ko' : 'en');

		axiosInstance.defaults.headers.organizationId = selectedOrganizationId || initialOrganizationId;
		axiosInstance.defaults.headers.tenantId = tenantId;

		return {
			organizationId: selectedOrganizationId || initialOrganizationId,
			organizationName,
			userStatus,
			userRole: userRole[0].role,
			customerDomain,
			tenantUrl,
			tenantId,
			region,
			isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			tenantStatus,
			tenantPort,
			userName,
			userEmail,
			imageUrl: imageUrl === DEFAULT_IMG_URL ? imageUrl : `${imageUrl}?${Date.now()}`,
			language,
			timeZone,
			createdAt,
			updatedAt,
			organizationList,
			reason,
			code,
			country: country || 'KR',
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (error.response?.data) {
			return thunkAPI.rejectWithValue(error.response.data);
		}

		return thunkAPI.rejectWithValue(error.message);
	}
});

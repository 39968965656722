/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button, CalendarIcon, DatePicker, paletteSDS, themeSDS } from 'surf-design-system';
import { RootState, useAppDispatch } from 'common/redux/store';
import useOutboundClick from 'common/hooks/useOutboundClick';

import { setSearchEndDate, setSearchStartDate } from 'features/license/context/licenseSlice';
import { downloadCSVV2 } from 'features/license/utils/csvFunc';

export default function LicenseUsageToolBar() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const searchStartDatePickerRef = useRef<HTMLDivElement>(null);
	const searchEndDatePickerRef = useRef<HTMLDivElement>(null);
	const searchStartButtonRef = useRef<HTMLDivElement>(null);
	const searchEndButtonRef = useRef<HTMLDivElement>(null);

	const { tenantId, organizationId, language } = useSelector((state: RootState) => state.account);

	const { searchStartDate, searchEndDate, selectedId, selectedPage } = useSelector(
		(state: RootState) => state.license.usage
	);

	const { createdAt } = useSelector((state: RootState) => state.license.detail);

	const [isSearchStartDatePickerVisible, setIsSearchStartDatePickerVisible] = useState(false);
	const [isSearchEndDatePickerVisible, setIsSearchEndDatePickerVisible] = useState(false);

	const searchStartDateChangeHandler = (dateValue: number) => {
		if (dateValue >= searchEndDate) {
			dispatch(setSearchStartDate(dateValue));
			dispatch(setSearchEndDate(dateValue));
			return;
		}

		dispatch(setSearchStartDate(dateValue));
	};

	const searchEndDateChangeHandler = (dateValue: number) => {
		if (dateValue <= searchStartDate) {
			dispatch(setSearchStartDate(dateValue));
			dispatch(setSearchEndDate(dateValue));
			return;
		}

		dispatch(setSearchEndDate(dateValue));
	};

	const csvBtnClickHandler = () => {
		downloadCSVV2({
			organizationId,
			searchStartDate,
			searchEndDate,
			tenantId: tenantId || '',
			projectId: selectedId,
			pageNum: selectedPage,
		});
	};

	const searchStartDatePickerVisibleChangeHandler = () => {
		setIsSearchStartDatePickerVisible(prevState => !prevState);
	};

	const searchEndDatePickerVisibleChangeHandler = () => {
		setIsSearchEndDatePickerVisible(prevState => !prevState);
	};

	useOutboundClick(
		searchStartDatePickerRef,
		() => {
			setIsSearchStartDatePickerVisible(false);
		},
		[searchStartButtonRef]
	);

	useOutboundClick(
		searchEndDatePickerRef,
		() => {
			setIsSearchEndDatePickerVisible(false);
		},
		[searchEndButtonRef]
	);

	return (
		<div css={headerCss}>
			<p css={titleCss}>{t('onPrem.license.usagePeriod')}</p>
			<div css={toolBarCss}>
				<div>
					<div css={datePickerWrap}>
						<div
							css={dateBox(isSearchStartDatePickerVisible)}
							aria-hidden
							onClick={searchStartDatePickerVisibleChangeHandler}
							ref={searchStartButtonRef}
						>
							<CalendarIcon
								fill={isSearchStartDatePickerVisible ? paletteSDS.blue['700'] : paletteSDS.gray[600]}
								width="15px"
							/>
							<div css={dateSt(isSearchStartDatePickerVisible)}>
								{moment(searchStartDate).utcOffset('+UTC09:00').format('YYYY-MM-DD')}
							</div>
						</div>
						<div css={dateRangeSt}>~</div>
						<div
							css={dateBox(isSearchEndDatePickerVisible)}
							aria-hidden
							onClick={searchEndDatePickerVisibleChangeHandler}
							ref={searchEndButtonRef}
						>
							<CalendarIcon
								fill={isSearchEndDatePickerVisible ? paletteSDS.blue['700'] : paletteSDS.gray[600]}
								width="15px"
							/>
							<div css={dateSt(isSearchEndDatePickerVisible)}>
								{moment(searchEndDate).utcOffset('+UTC09:00').format('YYYY-MM-DD')}
							</div>
						</div>
						<DatePicker
							isDatePickerVisible={isSearchStartDatePickerVisible}
							selectedDate={searchStartDate}
							onChange={searchStartDateChangeHandler}
							ref={searchStartDatePickerRef}
							timeZone="+UTC09:00"
							datePickerTop="40px"
							datePickerLeft="-51px"
							locale={language}
							maxDate={searchEndDate}
							minDate={moment(createdAt).utcOffset('+UTC09:00').startOf('day').valueOf()}
							containerEmotionCss={css`
								z-index: 100;
							`}
						/>
						<DatePicker
							isDatePickerVisible={isSearchEndDatePickerVisible}
							selectedDate={searchEndDate}
							onChange={searchEndDateChangeHandler}
							ref={searchEndDatePickerRef}
							timeZone="+UTC09:00"
							datePickerTop="40px"
							datePickerRight="-51px"
							minDate={searchStartDate}
							maxDate={moment().utcOffset('+UTC09:00').endOf('day').valueOf()}
							locale={language}
							containerEmotionCss={css`
								z-index: 100;
							`}
						/>
					</div>
				</div>
				<Button size="small" mould="secondary" onClick={csvBtnClickHandler}>
					{t('planSubscription.downloadCsv')}
				</Button>
			</div>
		</div>
	);
}

const headerCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const titleCss = css`
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[20]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
`;

const toolBarCss = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const datePickerWrap = css`
	position: relative;

	display: flex;
	align-items: center;
	gap: 4px;

	height: 2rem;
`;

const dateBox = (visible: boolean) => css`
	display: flex;
	align-items: center;

	width: 126px;
	height: 32px;

	padding: 0 8px;

	border: 1px solid ${visible ? paletteSDS.blue['700'] : paletteSDS.gray[300]};
	border-radius: 6px;
	background-color: ${paletteSDS.white};
	outline: ${visible && `1px solid ${paletteSDS.blue['700']}`};

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 160%;

	cursor: pointer;
`;

const dateRangeSt = css`
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 160%;
`;

const dateSt = (visible: boolean) => css`
	padding: 0 4px;

	color: ${visible ? paletteSDS.blue['700'] : paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 160%;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getBasicControlSegmentListApi from 'features/nfControl/api/getBasicControlSegmentList.v2.api';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';

interface GetBasicControlSegmentDetailThunkResult {
	meta: SegmentMetaData[];
}

const getBasicControlSegmentListThunk = createAsyncThunk<
	GetBasicControlSegmentDetailThunkResult,
	void,
	{ state: RootState }
>('get/basic/segment/list/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const { data } = await getBasicControlSegmentListApi({
			tenantId: tenantId as string,
			organizationId,
			projectId: selectedProjectId,
		});

		return { meta: data.slice() };

		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default getBasicControlSegmentListThunk;

import type { userRoleType } from 'common/types/getOrganizationList.type';

export const MAX_USER_NAME_CHAR_NUM = 100;
export const MAX_ORGANIZATION_CHAR_NUM = 50;

export const organizationRoleTextKey = {
	OWNER: 'userPermission.owner',
	ADMINISTRATOR: 'userPermission.member',
	MONITOR: 'userPermission.member',
	OPERATOR: 'userPermission.member',
	NONE: '',
};

export const productRoleTextKey = {
	OWNER: 'userPermission.owner',
	ADMINISTRATOR: 'userPermission.admin',
	MONITOR: 'userPermission.monitor',
	OPERATOR: 'userPermission.operator',
	NONE: '',
};

export const roleTextkey = (role: userRoleType) => {
	if (role === 'ADMINISTRATOR') {
		return 'commonWords.roleAdmin';
	}

	if (role === 'OWNER') {
		return 'commonWords.roleOwner';
	}

	if (role === 'OPERATOR') {
		return 'commonWords.roleOperator';
	}

	if (role === 'MONITOR') {
		return 'commonWords.roleMonitor';
	}

	return '';
};

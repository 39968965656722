import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';
import type { productType, userRoleType } from 'common/types/getOrganizationList.type';

import type { PostMemberListApiResponse } from '../types/postMemberList.type';

export interface PostMemberListApiProps {
	email: string[];
	role: userRoleType;
	selectedProductType: productType;
	organizationId: number;
}

export default async function postMemberListApi({
	email,
	role,
	selectedProductType,
	organizationId,
}: PostMemberListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.post<PostMemberListApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/organization/invitation`,
			{
				email,
				role: [{ productType: selectedProductType, role }],
			},
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

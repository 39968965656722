import { useState, useCallback } from 'react';

export default function useApiRequestNoArg<R>(requestFunction: () => Promise<R>) {
	const [response, setResponse] = useState<R | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	// eslint-disable-next-line
	const [error, setError] = useState<any>(null);

	const sendRequest = useCallback(async () => {
		setIsLoading(true);
		try {
			const responseData = await requestFunction();
			setResponse(responseData);
			setIsLoading(false);
			// eslint-disable-next-line
		} catch (err: any) {
			setError(err);
			setIsLoading(false);
		}
	}, [requestFunction]);

	return {
		sendRequest,
		response,
		isLoading,
		error,
	};
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import postOauthTokenApi from 'common/api/postOauthToken.api';
import getOauthUserInfoApi from 'features/signin/api/getOauthUserInfo.api';
import { COGNITO_REFRESH_TOKEN, COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import type { PostOauthTokenApiResponse } from 'common/types/postOauthToken.type';
import {
	toggleIsOauthBadRequestModalVisible,
	toggleIsOauthRegisterCognitoRequiredModalVisible,
	// toggleIsSigninBlockModalVisible,
} from 'common/context/modalSlice';
import axiosInstance from 'common/utils/axiosInstance';
// import { isAxiosError } from 'common/api/isAxiosError';
// import { PostOauthTokenApiError } from 'common/types/postOauthToken.type';

export interface GoogleOauthSigninThunkProps {
	code: string;
}

export interface GoogleOauthSigninThunkResult {
	isSuccess: boolean;
}

export const googleOauthSigninThunk = createAsyncThunk<
	GoogleOauthSigninThunkResult,
	GoogleOauthSigninThunkProps,
	{ state: RootState }
>('google/signin', async (args, thunkAPI) => {
	const { code } = args;

	let oauthToken: PostOauthTokenApiResponse = {
		access_token: '',
		id_token: '',
		expires_in: 0,
		refresh_token: '',
		token_type: '',
	};

	try {
		oauthToken = await postOauthTokenApi({ code });

		const { data: oauthUserInfo } = await getOauthUserInfoApi({ accessToken: oauthToken.id_token });

		sessionStorage.setItem(COGNITO_TOKEN, oauthToken.id_token);
		sessionStorage.setItem(COGNITO_REFRESH_TOKEN, oauthToken.refresh_token);
		sessionStorage.setItem(USER_KEY, oauthUserInfo.userKey);

		axiosInstance.defaults.headers.Authorization = oauthToken.id_token;
		axiosInstance.defaults.headers.userKey = oauthUserInfo.userKey;

		if (oauthUserInfo.isRegistered) {
			return { isSuccess: true };
		}

		thunkAPI.dispatch(toggleIsOauthRegisterCognitoRequiredModalVisible());

		return { isSuccess: false };

		// eslint-disable-next-line
	} catch (error: any) {
		// if (isAxiosError<PostOauthTokenApiError>(error) && error.response?.data.error.includes('LD1000')) {
		// 	thunkAPI.dispatch(toggleIsSigninBlockModalVisible());
		// 	return { isSuccess: false };
		// }

		thunkAPI.dispatch(toggleIsOauthBadRequestModalVisible());

		return { isSuccess: false };
	}
});

import axios, { AxiosError } from 'axios';
// etc

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { DeleteBasicControlSegmentApiResponse } from 'features/nfControl/type/deleteBasicControlSegment.v2.type';

export interface DeleteBasicControlSegmentApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
	segId: number;
}

export default async function deleteBasicControlSegmentApi({
	tenantId,
	organizationId,
	projectId,
	segId,
}: DeleteBasicControlSegmentApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.delete<DeleteBasicControlSegmentApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/segment/${segId}`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

export const columnChartInitData = [
	{ x: '00', y: 0 },
	{ x: '01', y: 0 },
	{ x: '02', y: 0 },
	{ x: '03', y: 0 },
	{ x: '04', y: 0 },
	{ x: '05', y: 0 },
	{ x: '06', y: 0 },
	{ x: '07', y: 0 },
	{ x: '08', y: 0 },
	{ x: '09', y: 0 },
	{ x: '10', y: 0 },
	{ x: '11', y: 0 },
	{ x: '12', y: 0 },
	{ x: '13', y: 0 },
	{ x: '14', y: 0 },
	{ x: '15', y: 0 },
	{ x: '16', y: 0 },
	{ x: '17', y: 0 },
	{ x: '18', y: 0 },
	{ x: '19', y: 0 },
	{ x: '20', y: 0 },
	{ x: '21', y: 0 },
	{ x: '22', y: 0 },
	{ x: '23', y: 0 },
];

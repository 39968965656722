import { createAsyncThunk } from '@reduxjs/toolkit';

import putUserNameApi from '../api/putUserName.api';

export interface PutUserNameThunkProps {
	name: string;
}

export interface PutUserNameThunkResult {
	name: string;
}

export const putUserNameThunk = createAsyncThunk<PutUserNameThunkResult, PutUserNameThunkProps>(
	'userName/put',
	async (args: PutUserNameThunkProps, thnkAPI) => {
		const { name: eneteredName } = args;

		try {
			const {
				data: { name },
			} = await putUserNameApi({ name: eneteredName });

			return { name };
			// eslint-disable-next-line
		} catch (error: any) {
			return thnkAPI.rejectWithValue(error.response.data);
		}
	}
);

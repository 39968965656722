import { validationSDS } from 'surf-design-system';

import type { SegmentType } from 'features/nfControl/type/getBasicControlSegmentDetail.v2.type';
import { rMaxInflow, rSegmentNameIncludeSpecialSymbol } from 'features/nfBasic/utils/regex';
import { isValidUrl } from '../utils/urlValidation';

export interface MemberInfo {
	imageUrl: string;
	name: string;
	id: string;
	userKey: string;
	state: 'ACTIVE' | 'DISABLED';
}

export interface SegmentForm {
	segmentName: string;
	isSegmentNameValid: boolean;
	isSegmentNameChanged: boolean;
	segmentNameValidation: validationSDS;
	segmentNameHelpText: string;
	maxInflow: string;
	isMaxInflowValid: boolean;
	isMaxInflowChanged: boolean;
	maxInflowValidation: validationSDS;
	maxInflowHelpText: string;
	uri: string;
	isUriValid: boolean;
	isUriChanged: boolean;
	uriValidation: validationSDS;
	uriHelpText: string;
	accessYn: boolean;
	isAccessYnChanged: boolean;
	segmentType: SegmentType;
	isMaxLimitedInflowValid: boolean;
	isMaxLimitedInflowChanged: boolean;
	maxLimitedInflowValidation: validationSDS;
	maxLimitedInflowHelpText: string;
	maxLimitedInflow: string;
	isMinLimitedInflowValid: boolean;
	isMinLimitedInflowChanged: boolean;
	minLimitedInflowValidation: validationSDS;
	minLimitedInflowHelpText: string;
	minLimitedInflow: string;
	isNormalRangeStartValueValid: boolean;
	isNormalRangeStartValueChanged: boolean;
	normalRangeStartValueValidation: validationSDS;
	normalRangeStartValueHelpText: string;
	normalRangeStartValue: string;
	isNormalRangeEndValueValid: boolean;
	isNormalRangeEndValueChanged: boolean;
	normalRangeEndValueValidation: validationSDS;
	normalRangeEndValueHelpText: string;
	normalRangeEndValue: string;
	vwrId: number;
	memberSearchValue: string;
	isMemberSearchValueChanged: boolean;
	searchedMemberList: MemberInfo[];
	selectedMemberList: MemberInfo[];
	disabledMemberList: MemberInfo[];
	mainDomain: string;
}

export interface SegmentFormAction {
	type?: string;
	payload?: string;
	initial?: SegmentForm;
	member?: MemberInfo;
	memberList?: MemberInfo[];
}

export const initialSegmentForm: SegmentForm = {
	segmentName: '',
	isSegmentNameValid: false,
	isSegmentNameChanged: false,
	segmentNameValidation: 'failed',
	segmentNameHelpText: '',
	maxInflow: '',
	isMaxInflowValid: false,
	isMaxInflowChanged: false,
	maxInflowValidation: 'failed',
	maxInflowHelpText: 'modal.limitInflowErrorMsg',
	uri: '',
	isUriValid: false,
	isUriChanged: false,
	uriValidation: 'failed',
	uriHelpText: '',
	accessYn: true,
	isAccessYnChanged: false,
	segmentType: 'STATIC',
	isMaxLimitedInflowValid: false,
	isMaxLimitedInflowChanged: false,
	maxLimitedInflowValidation: 'failed',
	maxLimitedInflowHelpText: '',
	maxLimitedInflow: '',
	isMinLimitedInflowValid: false,
	isMinLimitedInflowChanged: false,
	minLimitedInflowValidation: 'failed',
	minLimitedInflowHelpText: '',
	minLimitedInflow: '',
	isNormalRangeStartValueValid: true,
	isNormalRangeStartValueChanged: false,
	normalRangeStartValueValidation: 'failed',
	normalRangeStartValueHelpText: '',
	normalRangeStartValue: '2.0',
	isNormalRangeEndValueValid: true,
	isNormalRangeEndValueChanged: false,
	normalRangeEndValueValidation: 'failed',
	normalRangeEndValueHelpText: '',
	normalRangeEndValue: '5.0',
	vwrId: 1,
	memberSearchValue: '',
	isMemberSearchValueChanged: false,
	searchedMemberList: [],
	selectedMemberList: [],
	disabledMemberList: [],
	mainDomain: '',
};

export const UPDATE_NAME = 'UPDATE_NAME';
export const VALIDATE_NAME = 'VALIDATE_NAME';
export const UPDATE_NAME_HELP_TEXT = 'UPDATE_NAME_HELP_TEXT';

export const UPDATE_MAX_INFLOW = 'UPDATE_MAX_INFLOW';
export const VALIDATE_MAX_INFLOW = 'VALIDATE_MAX_INFLOW';
export const UPDATE_MAX_INFLOW_HELP_TEXT = 'UPDATE_MAX_INFLOW_HELP_TEXT';

export const UPDATE_URI = 'UPDATE_URI';
export const VALIDATE_URI = 'VALIDATE_URI';
export const UPDATE_URI_HELP_TEXT = 'UPDATE_URI_HELP_TEXT';
export const RESET_URI = 'RESET_URI';

export const UPDATE_ACCESS_YN = 'UPDATE_ACCESS_YN';
export const VALIDATE_ACCESS_YN = 'VALIDATE_ACCESS_YN';
export const UPDATE_ACCESS_YN_HELP_TEXT = 'UPDATE_ACCESS_YN_HELP_TEXT';

export const UPDATE_SEGMENT_TYPE = 'UPDATE_SEGMENT_TYPE';

export const UPDATE_MAX_LIMITED_INFLOW = 'UPDATE_MAX_LIMITED_INFLOW';
export const UPDATE_MAX_LIMITED_INFLOW_HELP_TEXT = 'UPDATE_MAX_LIMITED_INFLOW_HELP_TEXT';
export const VALIDATE_MAX_LIMITED_INFLOW = 'VALIDATE_MAX_LIMITED_INFLOW';

export const UPDATE_MIN_LIMITED_INFLOW = 'UPDATE_MIN_LIMITED_INFLOW';
export const UPDATE_MIN_LIMITED_INFLOW_HELP_TEXT = 'UPDATE_MIN_LIMITED_INFLOW_HELP_TEXT';
export const VALIDATE_MIN_LIMITED_INFLOW = 'VALIDATE_MIN_LIMITED_INFLOW';

export const UPDATE_NORMAL_RANGE_START_VALUE = 'UPDATE_NORMAL_RANGE_START_VALUE';
export const UPDATE_NORMAL_RANGE_START_VALUE_HELP_TEXT = 'UPDATE_NORMAL_RANGE_START_VALUE_HELP_TEXT';
export const VALIDATE_NORMAL_RANGE_START_VALUE = 'VALIDATE_NORMAL_RANGE_START_VALUE';

export const UPDATE_NORMAL_RANGE_END_VALUE = 'UPDATE_NORMAL_RANGE_END_VALUE';
export const UPDATE_NORMAL_RANGE_END_VALUE_HELP_TEXT = 'UPDATE_NORMAL_RANGE_END_VALUE_HELP_TEXT';
export const VALIDATE_NORMAL_RANGE_END_VALUE = 'VALIDATE_NORMAL_RANGE_END_VALUE';

export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const UPDATE_VWR_ID = 'UPDATE_VWR_ID';

export const UPDATE_MEMBER_SEARCH_VALUE = 'UPDATE_MEMBER_SEARCH_VALUE';
export const UPDATE_SEARCHED_MEMBER_LIST = 'UPDATE_SEARCHED_MEMBER_LIST';
export const UPDATE_SELECTED_MEMBER_LIST = 'UPDATE_SELECTED_MEMBER_LIST';
export const ADD_MEMBER = 'ADD_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';

export const UPDATE_MAIN_DOMAIN = 'UPDATE_MAIN_DOMAIN';

export const segmentCreateDynamicFormOnPremReducer = (state: SegmentForm, action: SegmentFormAction): SegmentForm => {
	switch (action.type) {
		case UPDATE_NAME: {
			const isSegmentNamePayloadValid = (action.payload || '').trim().length > 0 && (action.payload || '').length < 51;

			const isSegmentNamePayloadIncludeSpecialSymbol = rSegmentNameIncludeSpecialSymbol.test(action.payload || '');

			let segmentNamePayloadHelpText = '';

			if (isSegmentNamePayloadIncludeSpecialSymbol) {
				segmentNamePayloadHelpText = 'modal.segTitleErrorMsg2';
			}

			if (!isSegmentNamePayloadValid) {
				segmentNamePayloadHelpText = 'modal.segTitleErrorMsg';
			}

			return {
				...state,
				segmentName: action.payload || '',
				isSegmentNameChanged: true,
				isSegmentNameValid: isSegmentNamePayloadValid && !isSegmentNamePayloadIncludeSpecialSymbol,
				segmentNameHelpText: segmentNamePayloadHelpText,
				segmentNameValidation:
					isSegmentNamePayloadValid && !isSegmentNamePayloadIncludeSpecialSymbol ? 'none' : 'failed',
			};
		}

		case VALIDATE_NAME: {
			const isSegmentNameStateValid = state.segmentName.trim().length > 0 && state.segmentName.length < 51;

			const isSegmentNameStateIncludeSpecialSymbol = rSegmentNameIncludeSpecialSymbol.test(state.segmentName);

			let segmentNameStateHelpText = '';

			if (isSegmentNameStateIncludeSpecialSymbol) {
				segmentNameStateHelpText = 'modal.segTitleErrorMsg2';
			}

			if (!isSegmentNameStateValid) {
				segmentNameStateHelpText = 'modal.segTitleErrorMsg';
			}

			return {
				...state,
				isSegmentNameValid: state.isSegmentNameChanged
					? isSegmentNameStateValid && !isSegmentNameStateIncludeSpecialSymbol
					: false,
				segmentNameHelpText: segmentNameStateHelpText,
				segmentNameValidation:
					state.isSegmentNameChanged && isSegmentNameStateValid && !isSegmentNameStateIncludeSpecialSymbol
						? 'none'
						: 'failed',
			};
		}

		case UPDATE_MAX_INFLOW: {
			const isMaxInflowPayloadValid = rMaxInflow.test((action.payload || '0').trim());

			let maxInflowPayloadNumber = Number(action.payload || '0');

			const isMaxInflowNumberSmallerThanZero = maxInflowPayloadNumber < 0;

			const isMaxInflowNumberBiggerThanMax = maxInflowPayloadNumber > 99999999;

			if (isMaxInflowNumberBiggerThanMax) {
				maxInflowPayloadNumber = 99999999;
			}

			if (isMaxInflowNumberSmallerThanZero) {
				maxInflowPayloadNumber = 0;
			}

			return {
				...state,
				maxInflow: maxInflowPayloadNumber.toString(),
				isMaxInflowChanged: true,
				isMaxInflowValid: isMaxInflowPayloadValid,
				maxInflowValidation: isMaxInflowPayloadValid ? 'none' : 'failed',
			};
		}

		case VALIDATE_MAX_INFLOW: {
			const isMaxInflowStateValid = rMaxInflow.test(state.maxInflow.trim());

			return {
				...state,
				isMaxInflowChanged: true,
				isMaxInflowValid: isMaxInflowStateValid,
				maxInflowValidation: isMaxInflowStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_URI: {
			const uriPayload = (action.payload || '').trim();

			const isEmptyUri = uriPayload === '';

			const isUriPayloadFormValid = !uriPayload.includes('/*');

			const isUrlIncludeDomain = isValidUrl(uriPayload);

			const isUriPayloadValid = isUriPayloadFormValid && !isEmptyUri && isUrlIncludeDomain;

			let uriPayloadHelpText = '';

			if (!isUriPayloadFormValid) {
				uriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			if (!isUrlIncludeDomain) {
				uriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			if (isEmptyUri) {
				uriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			return {
				...state,
				uri: uriPayload,
				isUriChanged: true,
				isUriValid: isUriPayloadValid,
				uriHelpText: uriPayloadHelpText,
				uriValidation: isUriPayloadValid ? 'none' : 'failed',
			};
		}

		case VALIDATE_URI: {
			const isEmptyUri = state.uri === '';

			const isUriStateFormValid = !state.uri.includes('/*');

			const isUrlIncludeDomain = isValidUrl(state.uri);

			const isUriStateValid = isUriStateFormValid && !isEmptyUri && isUrlIncludeDomain;

			let uriStateHelpText = '';

			if (!isUriStateFormValid) {
				uriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			if (!isUrlIncludeDomain) {
				uriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			if (isEmptyUri) {
				uriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			return {
				...state,
				isUriValid: state.isUriChanged ? isUriStateValid : false,
				uriHelpText: uriStateHelpText,
				uriValidation: isUriStateValid && state.isUriChanged ? 'none' : 'failed',
			};
		}

		case RESET_URI: {
			return { ...state, uri: '', isUriChanged: false, isUriValid: false, uriHelpText: '', uriValidation: 'failed' };
		}

		case UPDATE_ACCESS_YN: {
			return { ...state, accessYn: action.payload === 'true', isAccessYnChanged: true };
		}

		case UPDATE_NAME_HELP_TEXT: {
			return {
				...state,
				segmentNameValidation: 'failed',
				segmentNameHelpText: action.payload || '',
				isSegmentNameChanged: true,
				isSegmentNameValid: false,
			};
		}

		case UPDATE_URI_HELP_TEXT: {
			return {
				...state,
				uriValidation: 'failed',
				uriHelpText: action.payload || '',
				isUriChanged: true,
				isUriValid: false,
			};
		}

		case UPDATE_MAX_LIMITED_INFLOW: {
			const isMaxLimitedInflowPayloadValid = rMaxInflow.test((action.payload || '0').trim());

			let maxLimitedInflowPayloadNumber = Number(action.payload || '0');

			let helptext = '';

			const isMaxLimitedInflowNumberSmallerThanZero = maxLimitedInflowPayloadNumber < 0;

			const isMaxLimitedInflowNumberBiggerThanMax = maxLimitedInflowPayloadNumber > 99999999;

			const isMaxLimitedInflowNumberBiggerThanMinLimitedInflow =
				state.minLimitedInflow !== '' ? maxLimitedInflowPayloadNumber > Number(state.minLimitedInflow) : true;

			if (isMaxLimitedInflowNumberBiggerThanMax) {
				maxLimitedInflowPayloadNumber = 99999999;
			}

			if (isMaxLimitedInflowNumberSmallerThanZero) {
				maxLimitedInflowPayloadNumber = 0;
			}

			if (!isMaxLimitedInflowNumberBiggerThanMinLimitedInflow) {
				helptext = 'modal.maxLimitedInflowSmall';
			}

			return {
				...state,
				maxLimitedInflow: maxLimitedInflowPayloadNumber.toString(),
				isMaxLimitedInflowChanged: true,
				isMaxLimitedInflowValid: isMaxLimitedInflowPayloadValid && isMaxLimitedInflowNumberBiggerThanMinLimitedInflow,
				maxLimitedInflowHelpText: helptext,
				maxLimitedInflowValidation:
					isMaxLimitedInflowPayloadValid && isMaxLimitedInflowNumberBiggerThanMinLimitedInflow ? 'none' : 'failed',
				isMinLimitedInflowValid:
					state.isMinLimitedInflowChanged && !state.isMinLimitedInflowValid
						? isMaxLimitedInflowNumberBiggerThanMinLimitedInflow
						: state.isMinLimitedInflowValid,
				minLimitedInflowValidation:
					state.isMinLimitedInflowChanged &&
					!state.isMinLimitedInflowValid &&
					isMaxLimitedInflowNumberBiggerThanMinLimitedInflow
						? 'none'
						: state.minLimitedInflowValidation,
			};
		}

		case VALIDATE_MAX_LIMITED_INFLOW: {
			const isMaxLimitedInflowStateValid = rMaxInflow.test(state.maxLimitedInflow);

			const isMaxLimitedInflowStateBiggerThanMin = state.isMinLimitedInflowChanged
				? Number(state.maxLimitedInflow) > Number(state.minLimitedInflow)
				: true;

			let helpText = '';

			if (!isMaxLimitedInflowStateBiggerThanMin) {
				helpText = 'modal.maxLimitedInflowSmall';
			}

			if (!isMaxLimitedInflowStateValid) {
				helpText = 'modal.maxLimitedInflowNoValue';
			}

			return {
				...state,
				isMaxLimitedInflowValid: isMaxLimitedInflowStateValid && isMaxLimitedInflowStateBiggerThanMin,
				maxLimitedInflowValidation:
					state.isMaxLimitedInflowChanged && isMaxLimitedInflowStateValid && isMaxLimitedInflowStateBiggerThanMin
						? 'none'
						: 'failed',
				maxLimitedInflowHelpText: helpText,
			};
		}

		case UPDATE_MAX_LIMITED_INFLOW_HELP_TEXT: {
			return {
				...state,
				maxLimitedInflowValidation: 'failed',
				maxLimitedInflowHelpText: action.payload || '',
				isMaxLimitedInflowChanged: true,
				isMaxLimitedInflowValid: false,
			};
		}

		case UPDATE_MIN_LIMITED_INFLOW: {
			const isMinLimitedInflowPayloadValid = rMaxInflow.test((action.payload || '0').trim());

			let minLimitedInflowPayloadNumber = Number(action.payload || '0');

			let helptext = '';

			const isMinLimitedInflowNumberSmallerThanZero = minLimitedInflowPayloadNumber < 0;

			const isMinLimitedInflowNumberBiggerThanMax = minLimitedInflowPayloadNumber > 99999999;

			const isMinLimitedInflowNumberSmallerThanMaxLimitedInflow =
				state.maxLimitedInflow !== '' ? minLimitedInflowPayloadNumber < Number(state.maxLimitedInflow) : true;

			if (isMinLimitedInflowNumberBiggerThanMax) {
				minLimitedInflowPayloadNumber = 99999999;
			}

			if (isMinLimitedInflowNumberSmallerThanZero) {
				minLimitedInflowPayloadNumber = 0;
			}

			if (!isMinLimitedInflowNumberSmallerThanMaxLimitedInflow) {
				helptext = 'modal.minLimitedInflowBig';
			}

			return {
				...state,
				minLimitedInflow: minLimitedInflowPayloadNumber.toString(),
				isMinLimitedInflowChanged: true,
				isMinLimitedInflowValid: isMinLimitedInflowPayloadValid && isMinLimitedInflowNumberSmallerThanMaxLimitedInflow,
				minLimitedInflowHelpText: helptext,
				minLimitedInflowValidation:
					isMinLimitedInflowPayloadValid && isMinLimitedInflowNumberSmallerThanMaxLimitedInflow ? 'none' : 'failed',
				isMaxLimitedInflowValid:
					state.isMaxLimitedInflowChanged && !state.isMaxLimitedInflowValid
						? isMinLimitedInflowNumberSmallerThanMaxLimitedInflow
						: state.isMaxLimitedInflowValid,
				maxLimitedInflowValidation:
					state.isMaxLimitedInflowChanged &&
					!state.isMaxLimitedInflowValid &&
					isMinLimitedInflowNumberSmallerThanMaxLimitedInflow
						? 'none'
						: state.maxLimitedInflowValidation,
			};
		}

		case VALIDATE_MIN_LIMITED_INFLOW: {
			const isMinLimitedInflowStateValid = rMaxInflow.test(state.minLimitedInflow);

			const isMinLimitedInflowStateSmallerThanMax = state.isMaxLimitedInflowChanged
				? Number(state.maxLimitedInflow) > Number(state.minLimitedInflow)
				: true;

			let helpText = '';

			if (!isMinLimitedInflowStateSmallerThanMax) {
				helpText = 'modal.minLimitedInflowBig';
			}

			if (!isMinLimitedInflowStateValid) {
				helpText = 'modal.minLimitedInflowNoValue';
			}

			return {
				...state,
				isMinLimitedInflowValid: isMinLimitedInflowStateValid && isMinLimitedInflowStateSmallerThanMax,
				minLimitedInflowValidation:
					state.isMinLimitedInflowChanged && isMinLimitedInflowStateValid && isMinLimitedInflowStateSmallerThanMax
						? 'none'
						: 'failed',
				minLimitedInflowHelpText: helpText,
			};
		}

		case UPDATE_MIN_LIMITED_INFLOW_HELP_TEXT: {
			return {
				...state,
				minLimitedInflowValidation: 'failed',
				minLimitedInflowHelpText: action.payload || '',
				isMinLimitedInflowChanged: true,
				isMinLimitedInflowValid: false,
			};
		}

		case UPDATE_NORMAL_RANGE_START_VALUE: {
			let normalRangeStartValuePayloadNumber = Number(action.payload || '');

			let helptext = '';

			const isNormalRangeStartValueSmallerThanZero = normalRangeStartValuePayloadNumber < 0;

			const isNormalRangeStartValueBiggerThanMax = normalRangeStartValuePayloadNumber > 100;

			const isNormalRangeStartValueSmallerThanEndValue =
				normalRangeStartValuePayloadNumber < Number(state.normalRangeEndValue);

			if (isNormalRangeStartValueBiggerThanMax) {
				normalRangeStartValuePayloadNumber = 100;
			}

			if (isNormalRangeStartValueSmallerThanZero) {
				normalRangeStartValuePayloadNumber = 0;
			}

			if (!isNormalRangeStartValueSmallerThanEndValue) {
				helptext = 'modal.rangeStartBiggerThanEnd';
			}

			return {
				...state,
				normalRangeStartValue: isNormalRangeStartValueBiggerThanMax ? '100' : action.payload || '',
				isNormalRangeStartValueChanged: true,
				isNormalRangeStartValueValid: isNormalRangeStartValueSmallerThanEndValue,
				normalRangeStartValueHelpText: helptext,
				normalRangeStartValueValidation: isNormalRangeStartValueSmallerThanEndValue ? 'none' : 'failed',
				isNormalRangeEndValueValid:
					state.isNormalRangeEndValueChanged && !state.isNormalRangeEndValueValid
						? isNormalRangeStartValueSmallerThanEndValue
						: state.isNormalRangeEndValueValid,
				normalRangeEndValueValidation:
					!state.isNormalRangeEndValueValid &&
					state.isNormalRangeEndValueChanged &&
					isNormalRangeStartValueSmallerThanEndValue
						? 'none'
						: state.normalRangeEndValueValidation,
			};
		}

		case VALIDATE_NORMAL_RANGE_START_VALUE: {
			const isNormalRangeStartValueSmallerThanEnd =
				Number(state.normalRangeStartValue) < Number(state.normalRangeEndValue);

			let helpText = '';

			if (!isNormalRangeStartValueSmallerThanEnd) {
				helpText = 'modal.rangeStartBiggerThanEnd';
			}

			return {
				...state,
				normalRangeStartValue: (Math.floor(Number(state.normalRangeStartValue) * 10) / 10).toString(),
				isNormalRangeStartValueValid: isNormalRangeStartValueSmallerThanEnd,
				normalRangeStartValueValidation:
					state.isNormalRangeStartValueChanged && isNormalRangeStartValueSmallerThanEnd ? 'none' : 'failed',
				normalRangeStartValueHelpText: helpText,
			};
		}

		case UPDATE_NORMAL_RANGE_START_VALUE_HELP_TEXT: {
			return {
				...state,
				normalRangeStartValueValidation: 'failed',
				normalRangeStartValueHelpText: action.payload || '',
				isNormalRangeStartValueChanged: true,
				isNormalRangeStartValueValid: false,
			};
		}

		case UPDATE_NORMAL_RANGE_END_VALUE: {
			let normalRangeEndValuePayloadNumber = Number(action.payload || '');

			let helptext = '';

			const isNormalRangeEndValueSmallerThanZero = normalRangeEndValuePayloadNumber < 0;

			const isNormalRangeEndValueBiggerThanMax = normalRangeEndValuePayloadNumber > 100;

			const isNormalRangeEndValueBiggerThanStartValue =
				normalRangeEndValuePayloadNumber > Number(state.normalRangeStartValue);

			if (isNormalRangeEndValueBiggerThanMax) {
				normalRangeEndValuePayloadNumber = 100;
			}

			if (isNormalRangeEndValueSmallerThanZero) {
				normalRangeEndValuePayloadNumber = 0;
			}

			if (!isNormalRangeEndValueBiggerThanStartValue) {
				helptext = 'modal.rangeEndSmallerThanStart';
			}

			return {
				...state,
				normalRangeEndValue: isNormalRangeEndValueBiggerThanMax ? '100' : action.payload || '',
				isNormalRangeEndValueChanged: true,
				isNormalRangeEndValueValid: isNormalRangeEndValueBiggerThanStartValue,
				normalRangeEndValueHelpText: helptext,
				normalRangeEndValueValidation: isNormalRangeEndValueBiggerThanStartValue ? 'none' : 'failed',
				isNormalRangeStartValueValid:
					state.isNormalRangeStartValueChanged && !state.isNormalRangeStartValueValid
						? isNormalRangeEndValueBiggerThanStartValue
						: state.isNormalRangeStartValueValid,
				normalRangeStartValueValidation:
					!state.isNormalRangeStartValueValid &&
					state.isNormalRangeStartValueChanged &&
					isNormalRangeEndValueBiggerThanStartValue
						? 'none'
						: state.normalRangeStartValueValidation,
			};
		}

		case VALIDATE_NORMAL_RANGE_END_VALUE: {
			const isNormalRangeEndValueBiggerThanStart =
				Number(state.normalRangeStartValue) < Number(state.normalRangeEndValue);

			let helpText = '';

			if (!isNormalRangeEndValueBiggerThanStart) {
				helpText = 'modal.rangeEndSmallerThanStart';
			}

			return {
				...state,
				normalRangeEndValue: (Math.floor(Number(state.normalRangeEndValue) * 10) / 10).toString(),
				isNormalRangeEndValueValid: isNormalRangeEndValueBiggerThanStart,
				normalRangeEndValueValidation:
					state.isNormalRangeEndValueChanged && isNormalRangeEndValueBiggerThanStart ? 'none' : 'failed',
				normalRangeEndValueHelpText: helpText,
			};
		}

		case UPDATE_NORMAL_RANGE_END_VALUE_HELP_TEXT: {
			return {
				...state,
				normalRangeEndValueValidation: 'failed',
				normalRangeEndValueHelpText: action.payload || '',
				isNormalRangeEndValueChanged: true,
				isNormalRangeEndValueValid: false,
			};
		}

		case UPDATE_SEGMENT_TYPE: {
			return {
				...state,
				segmentType: action.payload as SegmentType,
			};
		}

		case INITIALIZE_FORM: {
			return { ...(action.initial as SegmentForm) };
		}

		case UPDATE_VWR_ID: {
			return { ...state, vwrId: Number(action.payload) };
		}

		case UPDATE_MEMBER_SEARCH_VALUE: {
			return { ...state, isMemberSearchValueChanged: true, memberSearchValue: action.payload as string };
		}

		case UPDATE_SEARCHED_MEMBER_LIST: {
			const selectedMemberStateUserKeyArray = state.selectedMemberList.map(el => el.userKey);

			return {
				...state,
				searchedMemberList: [
					...(action.memberList as MemberInfo[]).filter(el => !selectedMemberStateUserKeyArray.includes(el.userKey)),
				],
				disabledMemberList:
					action.payload === ''
						? [...(action.memberList as MemberInfo[]).filter(el => el.state === 'DISABLED')]
						: [...state.disabledMemberList],
			};
		}

		case UPDATE_SELECTED_MEMBER_LIST: {
			return { ...state, selectedMemberList: [...(action.memberList as MemberInfo[])] };
		}

		case ADD_MEMBER: {
			const isSelectedMemberAlreadyExist =
				state.selectedMemberList.findIndex(el => el.userKey === (action.member as MemberInfo).userKey) !== -1;

			return {
				...state,
				selectedMemberList: isSelectedMemberAlreadyExist
					? [...state.selectedMemberList]
					: state.selectedMemberList.concat(action.member as MemberInfo),
				searchedMemberList: state.searchedMemberList.filter(el => el.userKey !== (action.member as MemberInfo).userKey),
			};
		}

		case DELETE_MEMBER: {
			return {
				...state,
				selectedMemberList: state.selectedMemberList.filter(el => el.userKey !== (action.member as MemberInfo).userKey),
			};
		}

		case UPDATE_MAIN_DOMAIN: {
			return { ...state, mainDomain: action.payload as string };
		}

		default:
			return initialSegmentForm;
	}
};

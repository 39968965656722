/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';
import SurffyStatus from 'features/home/components/SurffyStatus';
import { changeEnterpriseAskModalVisible } from 'common/context/modalSlice';
import { TENANT_FAILED_ASK_TITLE } from 'common/data/constants';

export default function SurffyHomeTenantFail() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { userName, language } = useSelector((state: RootState) => state.account);

	const talkToSalesButtonClickHandler = () => {
		dispatch(changeEnterpriseAskModalVisible(TENANT_FAILED_ASK_TITLE[language]));
	};

	return (
		<div css={container}>
			<h3>{t('console.main.mainMsg', { name: userName })}</h3>
			<SurffyStatus />
			<DividerWithTxt />
			<div css={gap16pxColumn}>
				<div css={gap8pxColumn}>
					<h4>{t('console.main.failedDetail')}</h4>
					<div css={descriptionSt}>{t('console.main.failedGuide')}</div>
				</div>
				<Button size="medium" onClick={talkToSalesButtonClickHandler}>
					{t('planSubscription.talkToSales')}
				</Button>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	padding: 32px 24px 80px;
`;

const descriptionSt = css`
	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['14']};
	line-height: 140%;
`;

const gap8pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap16pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

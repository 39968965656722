/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';
import { paletteSDS } from 'surf-design-system';
import useTXNColumnChartOptions from 'features/nfEUM/hooks/useTXNColumnChartOptions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'common/redux/store';
import TXNChartCount from 'features/nfEUM/components/TXNChartCount';
import TXNChartTitle from 'features/nfEUM/components/TXNChartTitle';
import { calcMaxIntFn, formatLocaleString } from 'features/nfEUM/utils/chartFunc';

export default function TXNTodayLoadChart() {
	const { t } = useTranslation();
	const { load } = useSelector((state: RootState) => state.eumTXNToday);

	const { apexLineOption } = useTXNColumnChartOptions({
		maxY: calcMaxIntFn(load.annotation.y, 0),
		annotation: load.annotation,
	});

	return (
		<div>
			<TXNChartTitle
				title={t('eum.todayLoad')}
				labels={[
					{ color: paletteSDS.categoryColor.blue, label: 'Web', id: 0 },
					{ color: paletteSDS.categoryColor.mint, label: 'Native', id: 1 },
				]}
			/>
			<TXNChartCount count={formatLocaleString(load.totalCount)} />
			<div css={chartCss}>
				<ReactApexChart
					type="bar"
					width="640px"
					height="136px"
					series={[
						{
							name: 'web',
							data: load.data.web,
							color: paletteSDS.categoryColor.blue,
						},
						{
							name: 'native',
							data: load.data.native,
							color: paletteSDS.categoryColor.mint,
						},
					]}
					options={apexLineOption}
				/>
			</div>
		</div>
	);
}

const chartCss = css`
	margin-top: -10px;
`;

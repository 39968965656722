import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import getOrganizationDataApi from 'common/api/getOrganizationDetail.api';
import getUserProfileApi from 'common/api/getUserProfile.api';
import type { userRoleType, userStatusType, Role } from 'common/types/getOrganizationList.type';
import type { tenantStatusType } from 'common/types/getOrganizationDetail.type';
import type { languageType } from 'common/types/getUserProfile.type';
import { DEFAULT_IMG_URL } from 'common/data/constants';

import getOrganizationPlanApi from 'features/plan/api/getOrganizationPlan.api';
import putSelectedOrganizationIdApi from 'features/setting/api/putSelectedOrganizationId.api';
import axiosInstance from 'common/utils/axiosInstance';

export interface GetAccountInfoSwitchOrganizationThunkProps {
	organizationId: number;
	organizationName: string;
	userStatus: userStatusType;
	userRole: Role[];
}

export interface GetAccountInfoSwitchOrganizationThunkResult {
	organizationId: number;
	organizationName: string;
	userStatus: userStatusType;
	userRole: userRoleType;
	customerDomain: string | null;
	tenantUrl: string;
	tenantId: string | null;
	region: string;
	isFreeTrial: boolean | null;
	freeTrialStartAt: string | null;
	freeTrialEndAt: string | null;
	tenantStatus: tenantStatusType | null;
	tenantPort: number | null;
	userName: string;
	userEmail: string;
	imageUrl: string;
	language: languageType;
	timeZone: string;
	createdAt: string;
	updatedAt: string;
	planSubscription: boolean;
	expiredAt: string | null;
	code: string;
	reason: string;
	country: string;
	planId: number;
}

export const getAccountInfoSwitchOrganizationThunk = createAsyncThunk<
	GetAccountInfoSwitchOrganizationThunkResult,
	GetAccountInfoSwitchOrganizationThunkProps,
	{ state: RootState }
>('accountInfo/switchOrganization/get', async (args, thunkAPI) => {
	const { organizationId, organizationName, userStatus, userRole } = args;

	axiosInstance.defaults.headers.organizationId = organizationId;

	try {
		await putSelectedOrganizationIdApi({ organizationId });

		const organizationUserData = await Promise.all([getOrganizationDataApi(organizationId), getUserProfileApi()]);

		const organizationDetail = organizationUserData[0].data;
		const userProfileData = organizationUserData[1].data;

		const {
			defaultCustomerDomain: customerDomain,
			domainUrl: tenantUrl,
			tenantId,
			region,
			freeTrialYn: isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			status: tenantStatus,
			port: tenantPort,
			code,
			reason,
			country,
		} = organizationDetail;

		const {
			name: userName,
			email: userEmail,
			imageUrl,
			language,
			timezone: timeZone,
			createdAt,
			updatedAt,
		} = userProfileData;

		axiosInstance.defaults.headers.tenantId = tenantId;

		if (organizationDetail.status && organizationDetail.status === 'PENDING') {
			return {
				organizationId,
				organizationName,
				userStatus,
				userRole: userRole[0].role,
				customerDomain,
				tenantUrl,
				tenantId,
				region,
				isFreeTrial,
				freeTrialStartAt,
				freeTrialEndAt,
				tenantStatus,
				tenantPort,
				userName,
				userEmail,
				imageUrl: imageUrl === DEFAULT_IMG_URL ? imageUrl : `${imageUrl}?${Date.now()}`,
				language,
				timeZone,
				createdAt,
				updatedAt,
				planSubscription: false,
				expiredAt: null,
				code,
				reason,
				country: country || 'KR',
				planId: 0,
			};
		}

		const { data: organizationPlan } = await getOrganizationPlanApi({ organizationId });

		const { using: planSubscription, expiredAt, planId } = organizationPlan;

		return {
			organizationId,
			organizationName,
			userStatus,
			userRole: userRole[0].role,
			customerDomain,
			tenantUrl,
			tenantId,
			region,
			isFreeTrial,
			freeTrialStartAt,
			freeTrialEndAt,
			tenantStatus,
			tenantPort,
			userName,
			userEmail,
			imageUrl: imageUrl === DEFAULT_IMG_URL ? imageUrl : `${imageUrl}?${Date.now()}`,
			language,
			timeZone,
			createdAt,
			updatedAt,
			planSubscription,
			expiredAt,
			code,
			reason,
			country: country || 'KR',
			planId,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

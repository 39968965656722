/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, Button, SettingsIcon } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import NetfunnelProjectListTable from 'features/home/components/NetfunnelProjectListTable';
import MacroSettingModal from 'features/nfSetting/components/MacroSettingModal';
import { setIsBotBlockingSettingModalVisible } from 'common/context/modalSlice';

export default function NetfunnelHome() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { blockYn, selectedProjectId } = useSelector((state: RootState) => state.projectInfo);
	const { isBotBlockingSettingModalVisible } = useSelector((state: RootState) => state.modal.projectManagementModal);
	const { userRole } = useSelector((state: RootState) => state.account);

	const createProjectButtonClickHandler = () => {
		navigate(`create_project`);
	};

	const settingIconClickHandler = () => {
		dispatch(dispatch(setIsBotBlockingSettingModalVisible({ selectedProjectId })));
	};

	return (
		<div css={container}>
			<div css={gap16pxColumn}>
				<h3>NetFUNNEL {t('console.nfHome.project')}</h3>
				<div css={subtitleSt}>{t('console.nfHome.selectProjectGuide')}</div>
			</div>
			<div css={gap8pxColumn}>
				<div css={titleSt}>{t('console.nfHome.settingInfo')}</div>
				<div css={macroBox}>
					<div css={gap8px}>
						<div css={labelSt}>{t('console.nfHome.botBlockingSetting')}</div>
						<div css={iconBox}>
							{userRole === 'OWNER' || userRole === 'ADMINISTRATOR' ? (
								<React.Fragment>
									<div css={divider} />
									<SettingsIcon fill={paletteSDS.blue['700']} size="xSmall" onClick={settingIconClickHandler} />
								</React.Fragment>
							) : null}
						</div>
					</div>
					<div css={blockYnSt}>{t(blockYn ? 'console.nfHome.on' : 'console.nfHome.off')}</div>
				</div>
			</div>
			<div css={gap16pxColumn}>
				<div css={titleBox}>
					<div css={titleSt}>{t('console.nfHome.project')}</div>
					{userRole === 'ADMINISTRATOR' || userRole === 'OWNER' ? (
						<Button size="small" onClick={createProjectButtonClickHandler}>
							{t('console.nfHome.createProject')}
						</Button>
					) : null}
				</div>
				<NetfunnelProjectListTable />
			</div>
			{isBotBlockingSettingModalVisible ? <MacroSettingModal /> : null}
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	margin: 0 auto;

	width: 960px;

	padding: 32px 24px 80px;
`;

const gap16pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const gap8pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const titleBox = css`
	display: flex;
	justify-content: space-between;
`;

const titleSt = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['20']};

	line-height: 140%;
`;

const subtitleSt = css`
	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['14']};

	line-height: 128%;
`;

const macroBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 240px;
	height: 48px;

	padding: 16px;

	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 8px;

	background: ${paletteSDS.white};
`;

const divider = css`
	position: absolute;

	left: 0;

	width: 1px;
	height: 15px;

	background: ${paletteSDS.gray['300']};
`;

const labelSt = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};

	line-height: 140%;
`;

const blockYnSt = css`
	color: ${paletteSDS.blue['700']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};

	line-height: 140%;
`;

const iconBox = css`
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0;

	width: 33px;
	height: 32px;
`;

const gap8px = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

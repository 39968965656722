/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, WarningIcon } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import Toast from 'common/components/Toast';

import {
	toggleIsVWRUpdatedToastVisible,
	toggleIsPreferenceChangeToastVisible,
	toggleIsOwnershipTransferToastVisible,
	toggleIsInviteAllFailToastVisible,
	toggleIsInviteAllSuccessToastVisible,
	toggleIsInviteNotAllSuccessToastVisible,
	toggleIsBillingInfoChangeToastVisible,
	toggleIsMemberPermissionChangeFailToastVisible,
	toggleIsMemberPermissionChangeSuccessToastVisible,
	toggleIsOwnershipTransferFailToastVisible,
	toggleIsPutServiceDomainFailToastVisible,
	toggleIsSegmentUpdatedToastVisible,
	toggleIsWindowRefreshedToastVisible,
	setIsNetfunnelControlsInitialToastVisible,
	toggleIsVWRUpdateFailToastVisible,
	toggleIsSubscriptionCancelToastVisible,
	toggleIsCompanyAddressToastVisible,
} from 'common/context/toastSlice';

export default function SaasToasts() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const {
		isVWRUpdatedToastVisible,
		isPreferenceChangeToastVisible,
		isOwnershipTransferToastVisible,
		isInviteAllFailToastVisible,
		isInviteAllSuccessToastVisible,
		isInviteNotAllSuccessToastVisible,
		isEUMNetworkErrorToastVisible,
		isBillingInfoChangeToastVisible,
		isMemberPermissionChangeFailVisible,
		isMemberPermissionChangeSuccessVisible,
		isOwnershipTransferFailToastVisible,
		isPutServiceDomainFailToastVisible,
		isSegmentUpdatedToastVisible,
		isWindowRefreshedToastVisible,
		isNetfunnelControlsInitialToastVisible,
		isProjectInactiveToastVisible,
		isVWRUpdateFailToastVisible,
		isSubscriptionCancelToastVisible,
		isCompanyAddressToastVisible,
	} = useSelector((state: RootState) => state.toast);

	const subscriptionCancelToastCloseHandler = () => {
		dispatch(toggleIsSubscriptionCancelToastVisible());
	};

	const companyAddressToastCloseHandler = () => {
		dispatch(toggleIsCompanyAddressToastVisible());
	};

	return (
		<React.Fragment>
			<Toast
				backgroundColor={paletteSDS.gray['900']}
				fontColor={paletteSDS.white}
				visible={isProjectInactiveToastVisible}
				isToastClose={false}
			>
				{t('projectMonitoring.projectDeactivated')}
			</Toast>
			<Toast
				visible={isNetfunnelControlsInitialToastVisible}
				isToastClose={false}
				onClose={() => dispatch(setIsNetfunnelControlsInitialToastVisible(false))}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
				isCloseIconVisible
			>
				{t('projectMonitoring.initialToastDescription')}
			</Toast>
			<Toast
				backgroundColor={paletteSDS.gray['900']}
				fontColor={paletteSDS.white}
				visible={isEUMNetworkErrorToastVisible}
				isToastClose={false}
			>
				<div css={networkErrorToastSt}>
					<WarningIcon fill={paletteSDS.yellow['400']} />
					<div>Network Error</div>
				</div>
			</Toast>
			<Toast
				backgroundColor={paletteSDS.gray['900']}
				fontColor={paletteSDS.white}
				visible={isVWRUpdateFailToastVisible}
				onClose={() => dispatch(toggleIsVWRUpdateFailToastVisible())}
			>
				<div css={networkErrorToastSt}>
					<WarningIcon fill={paletteSDS.yellow['400']} />
					<div>{t('vwr.updateFail')}</div>
				</div>
			</Toast>
			<Toast
				visible={isInviteAllSuccessToastVisible}
				isToastClose
				onClose={() => dispatch(toggleIsInviteAllSuccessToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('organizationSettings.invitationMsg')}
			</Toast>
			<Toast
				visible={isBillingInfoChangeToastVisible}
				isToastClose
				onClose={() => dispatch(toggleIsBillingInfoChangeToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('freeBeta.billingChanged')}
			</Toast>
			<Toast
				visible={isInviteAllFailToastVisible}
				isToastClose
				onClose={() => dispatch(toggleIsInviteAllFailToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('organizationSettings.errorInvitationMsg1')}
			</Toast>
			<Toast
				visible={isInviteNotAllSuccessToastVisible}
				isToastClose
				onClose={() => dispatch(toggleIsInviteNotAllSuccessToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('organizationSettings.errorInvitationMsg2')}
			</Toast>
			<Toast
				visible={isOwnershipTransferToastVisible}
				isToastClose
				onClose={() => dispatch(toggleIsOwnershipTransferToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('modal.ownerTransSubmitMsg')}
			</Toast>
			<Toast
				visible={isVWRUpdatedToastVisible}
				isToastClose
				onClose={() => dispatch(toggleIsVWRUpdatedToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('projectMgmt.vwrSettingChangeNoti')}
			</Toast>
			<Toast
				isToastClose
				visible={isPreferenceChangeToastVisible}
				onClose={() => dispatch(toggleIsPreferenceChangeToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('personalSettings.preferencesNoti')}
			</Toast>
			<Toast
				backgroundColor={paletteSDS.gray['900']}
				fontColor={paletteSDS.white}
				visible={isMemberPermissionChangeFailVisible}
				onClose={() => dispatch(toggleIsMemberPermissionChangeFailToastVisible())}
			>
				<div css={networkErrorToastSt}>
					<WarningIcon fill={paletteSDS.yellow['400']} />
					<div>{t('organizationSettings.memberPermissionChangeFail')}</div>
				</div>
			</Toast>
			<Toast
				backgroundColor={paletteSDS.gray['900']}
				fontColor={paletteSDS.white}
				visible={isPutServiceDomainFailToastVisible}
				onClose={() => dispatch(toggleIsPutServiceDomainFailToastVisible())}
			>
				<div css={networkErrorToastSt}>
					<WarningIcon fill={paletteSDS.yellow['400']} />
					<div>{t('ServiceDomainSetting.putServiceDomainFailText')}</div>
				</div>
			</Toast>
			<Toast
				isToastClose
				visible={isMemberPermissionChangeSuccessVisible}
				onClose={() => dispatch(toggleIsMemberPermissionChangeSuccessToastVisible())}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
			>
				{t('organizationSettings.memPermissionChange')}
			</Toast>
			<Toast
				backgroundColor={paletteSDS.gray['900']}
				fontColor={paletteSDS.white}
				visible={isOwnershipTransferFailToastVisible}
				onClose={() => dispatch(toggleIsOwnershipTransferFailToastVisible())}
			>
				<div css={networkErrorToastSt}>
					<WarningIcon fill={paletteSDS.yellow['400']} />
					<div>{t('modal.ownerTransferRequestFail')}</div>
				</div>
			</Toast>
			<Toast
				isToastClose
				visible={isSegmentUpdatedToastVisible}
				backgroundColor={paletteSDS.gray[900]}
				fontColor={paletteSDS.white}
				onClose={() => dispatch(toggleIsSegmentUpdatedToastVisible())}
				duration={2000}
			>
				{t('projectMonitoring.prjChangeNoti')}
			</Toast>
			<Toast
				isToastClose
				visible={isWindowRefreshedToastVisible}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
				onClose={() => dispatch(toggleIsWindowRefreshedToastVisible())}
				duration={2000}
			>
				{t('projectMonitoring.prjChangeApplied')}
			</Toast>
			<Toast
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
				visible={isSubscriptionCancelToastVisible}
				onClose={subscriptionCancelToastCloseHandler}
			>
				{t('planSubscription.subscrCancelNoti')}
			</Toast>
			<Toast
				visible={isCompanyAddressToastVisible}
				backgroundColor={paletteSDS.blue[400]}
				fontColor={paletteSDS.white}
				onClose={companyAddressToastCloseHandler}
			>
				{t('planSubscription.savedNoti')}
			</Toast>
		</React.Fragment>
	);
}

const networkErrorToastSt = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

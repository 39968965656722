/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { MonitoringIcon, ProjectIcon, HelpIcon, InfoIcon, WarningIcon, paletteSDS } from 'surf-design-system';

import { notiIconType } from 'common/types/getNotificationList.type';
import { SurffyIcon } from 'common/assets/icons';
import { NOTI_COMMON, NOTI_NOTICE, NOTI_ASK, NOTI_DISCONNECT, NOTI_DS, NOTI_NF } from 'common/data/constants';

export interface NotiTypeIconsProps {
	iconType: notiIconType | 'NULL';
}

const iconBackgroundColor = {
	ASK: paletteSDS.gray[500],
	COMMON: paletteSDS.blue[900],
	NOTICE: paletteSDS.blue[500],
	DISCONNECT: paletteSDS.gray[900],
	DATASURFER: paletteSDS.categoryColor.violet,
	NETFUNNEL: paletteSDS.categoryColor.mentis,
	NULL: paletteSDS.white,
};

export default function NotificationIcon({ iconType }: NotiTypeIconsProps) {
	switch (iconType) {
		case NOTI_COMMON:
			return (
				<div css={iconWrapSt(NOTI_COMMON)}>
					<SurffyIcon />
				</div>
			);
		case NOTI_ASK:
			return (
				<div css={iconWrapSt(NOTI_ASK)}>
					<HelpIcon fill={paletteSDS.gray[50]} size="small" />
				</div>
			);
		case NOTI_DISCONNECT:
			return (
				<div css={iconWrapSt(NOTI_DISCONNECT)}>
					<WarningIcon fill={paletteSDS.yellow[400]} size="small" />
				</div>
			);
		case NOTI_DS:
			return (
				<div css={iconWrapSt(NOTI_DS)}>
					<ProjectIcon fill={paletteSDS.gray[50]} size="small" />
				</div>
			);
		case NOTI_NF:
			return (
				<div css={iconWrapSt(NOTI_NF)}>
					<MonitoringIcon fill={paletteSDS.gray[50]} size="small" />
				</div>
			);
		case NOTI_NOTICE:
			return (
				<div css={iconWrapSt(NOTI_NOTICE)}>
					<InfoIcon fill={paletteSDS.gray[50]} size="small" />
				</div>
			);

		default:
			return <div css={iconWrapSt('NULL')} />;
	}
}

const iconWrapSt = (iconType: notiIconType | 'NULL') => css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	background-color: ${iconBackgroundColor[iconType]};
`;

import { validationSDS } from 'surf-design-system';

export interface ProfileForm {
	enteredName: string;
	isEnteredNameChanged: boolean;
	isEnteredNameValid: boolean;
	enteredNameValidation: validationSDS;
	enteredNameHelpText: string;
	image: File | null;
	isImageChanged: boolean;
}

export interface ProfileFormAction {
	type?: string;
	payload?: string;
	file?: File | null;
}

export const initialProfileForm: ProfileForm = {
	enteredName: '',
	isEnteredNameChanged: false,
	isEnteredNameValid: false,
	enteredNameHelpText: 'modal.userNameMsg',
	enteredNameValidation: 'failed',
	image: null,
	isImageChanged: false,
};

export const UPDATE_NAME = 'UPDATE_NAME';
export const VALIDATE_NAME = 'VALIATE_NAME';
export const UPDATE_NAME_HELP_TEXT = 'UPDATE_NAME_HELP_TEXT';

export const UPDATE_FILE = 'UPDATE_FILE';

export const profileFormReducer = (state: ProfileForm, action: ProfileFormAction): ProfileForm => {
	switch (action.type) {
		case UPDATE_NAME: {
			const isNamePayloadValid = (action.payload || '').trim().length > 0 && (action.payload || '').trim().length < 101;

			return {
				...state,
				enteredName: action.payload || '',
				isEnteredNameValid: isNamePayloadValid,
				enteredNameValidation: isNamePayloadValid ? 'none' : 'failed',
				isEnteredNameChanged: true,
			};
		}

		case VALIDATE_NAME: {
			const isNameStateValid = state.enteredName.trim().length > 0 && state.enteredName.trim().length < 101;

			return {
				...state,
				isEnteredNameValid: state.isEnteredNameChanged ? isNameStateValid : false,
				enteredNameValidation: state.isEnteredNameChanged && isNameStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_FILE: {
			const filePayload = action.file || null;

			return { ...state, image: filePayload, isImageChanged: !!filePayload };
		}

		default:
			return initialProfileForm;
	}
};

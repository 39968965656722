/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from 'common/redux/store';

import SaasUsageSummaryCard, { UsageSummaryCardProps } from 'features/plan/components/SaasUsageSummaryCard';
import { getProjectUsageSummaryThunk } from 'features/plan/thunk/getProjectUsageSummary.v2.thunk';
import SaasUsageSummaryHeader from 'features/plan/components/SaasUsageSummaryHeader';

export default function SaasUsageSummary() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const {
		usageSummary: {
			basicMonitoring: {
				totalActiveUsers: basicMonitoringTotalActiveUsers,
				vwr: basicMonitoringVwr,
				totalWaitingUsers: basicMonitoringTotalWaitingUsers,
			},
			pathControl: {
				totalActiveUsers: pathControlTotalActiveUsers,
				vwr: pathControlVwr,
				totalWaitingUsers: pathControlTotalWaitingUsers,
			},
			administrator,
			operator,
			waitingRoom,
			peakCcu,
		},
		isUsageSummaryLoaded,
		selectedProjectId,
	} = useSelector((state: RootState) => state.usage);

	const usageSummaries: UsageSummaryCardProps[] = [
		{
			title: t('planSubscription.totalAu'),
			data: basicMonitoringTotalActiveUsers + pathControlTotalActiveUsers,
			footer: [
				{ id: 0, value: basicMonitoringTotalActiveUsers, label: t('planSubscription.basic') },
				{ id: 1, value: pathControlTotalActiveUsers, label: t('planSubscription.path') },
			],
		},
		{
			title: t('planSubscription.totalWu'),
			data: basicMonitoringTotalWaitingUsers + pathControlTotalWaitingUsers,
			footer: [
				{ id: 0, value: basicMonitoringTotalWaitingUsers, label: t('planSubscription.basic') },
				{ id: 1, value: pathControlTotalWaitingUsers, label: t('planSubscription.path') },
			],
		},
		{
			title: t('planSubscription.ccu1'),
			data: peakCcu,
		},
		{
			title: t('planSubscription.vwr1'),
			data: basicMonitoringVwr + pathControlVwr,
			footer: [
				{ id: 0, value: basicMonitoringVwr, label: t('planSubscription.basic') },
				{ id: 1, value: pathControlVwr, label: t('planSubscription.path') },
			],
		},
		{
			title: t('planSubscription.waitingRoom'),
			data: waitingRoom,
		},
		{
			title: t('planSubscription.numInvitedMem1'),
			data: administrator + operator,
			footer: [
				{ id: 0, value: administrator, label: t('planSubscription.admin') },
				{ id: 1, value: operator, label: t('planSubscription.operator') },
			],
		},
	];

	const initialUsageSummaries: UsageSummaryCardProps[] = [
		{ title: t('planSubscription.totalAu'), footer: [] },
		{ title: t('planSubscription.totalWu'), footer: [] },
		{ title: t('planSubscription.ccu1'), footer: [] },
		{ title: t('planSubscription.vwr1'), footer: [] },
		{
			title: t('planSubscription.waitingRoom'),
			footer: [],
		},
		{
			title: t('planSubscription.numInvitedMem1'),
			footer: [],
		},
	];

	useEffect(() => {
		dispatch(getProjectUsageSummaryThunk());
	}, [dispatch, selectedProjectId]);

	return (
		<div css={usageSummaryContainerSt}>
			<SaasUsageSummaryHeader />
			<div css={usageSummaryCardContainerSt}>
				{isUsageSummaryLoaded
					? usageSummaries.map(summary => <SaasUsageSummaryCard key={summary.title} {...summary} />)
					: initialUsageSummaries.map(initialSummary => (
							<SaasUsageSummaryCard key={initialSummary.title} {...initialSummary} />
					  ))}
			</div>
		</div>
	);
}

const usageSummaryContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const usageSummaryCardContainerSt = css`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;

	width: 100%;
`;

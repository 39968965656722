/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useAppDispatch } from 'common/redux/store';
import TXNTodayLoadChart from 'features/nfEUM/components/TXNTodayLoadChart';
import TXNTodayUVChart from 'features/nfEUM/components/TXNTodayUVChart';
import moment from 'moment';
import { useEffect, useLayoutEffect } from 'react';
import { paletteSDS } from 'surf-design-system';
import { resetTodayData, setTodayIntervalTime } from 'features/nfEUM/context/eumTXNTodaySlice';
import { getTXNTodayThunk } from 'features/nfEUM/thunk/getTXNToday.thunk';

let intervalTimer: NodeJS.Timer | undefined;

export default function TXNToday() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		clearInterval(intervalTimer);

		intervalTimer = setInterval(async () => {
			dispatch(
				setTodayIntervalTime({ beginTime: moment().startOf('d').unix(), endTime: moment().subtract(7, 's').unix() })
			);
			dispatch(getTXNTodayThunk());
		}, 300000);
	}, [dispatch]);

	useLayoutEffect(() => {
		dispatch(
			setTodayIntervalTime({ beginTime: moment().startOf('d').unix(), endTime: moment().subtract(7, 's').unix() })
		);
		dispatch(getTXNTodayThunk());
		return () => {
			clearInterval(intervalTimer);
			dispatch(resetTodayData());
		};
	}, [dispatch]);

	return (
		<div css={chartContainer}>
			<div css={chartSt}>
				<TXNTodayLoadChart />
			</div>
			<div css={chartSt}>
				<TXNTodayUVChart />
			</div>
		</div>
	);
}

const chartContainer = css`
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

const chartSt = css`
	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
	width: 680px;
	height: 216px;
	padding: 16px 28px 0 16px;
	background-color: ${paletteSDS.white};
`;

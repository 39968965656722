import { useMemo } from 'react';
import moment from 'moment';
import type { ApexOptions } from 'apexcharts';
import { paletteSDS } from 'surf-design-system';

export interface LineChartData {
	x: number;
	y: number | null;
}

const { gray, categoryColor, red } = paletteSDS;

interface LineChartAnnotation {
	web: LineChartData;
	native: LineChartData;
}

export interface OptionsParameter {
	maxY: number;
	timeZone: string;
	isChartTypeArea?: boolean;
	isErrorChart?: boolean;
	annotation: LineChartAnnotation;
}

export interface ApexChartSeries {
	data: LineChartData[];
}

export interface CustomTooltipProps {
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			seriesNames: string;
			colors: string;
			initialSeries: Array<ApexChartSeries>;
		};
	};
	series: Array<LineChartData[]>;
}

const colorSet = [categoryColor.blue, categoryColor.mint, categoryColor.purple, red[300]];

export default function useTXNLineChartOptions({
	maxY,
	timeZone,
	isChartTypeArea = true,
	isErrorChart = false,
	annotation,
}: OptionsParameter) {
	const options: ApexOptions = useMemo(
		() => ({
			chart: {
				id: 'realtime',
				animations: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			stroke: {
				width: 1,
				curve: 'straight',
			},
			dataLabels: {
				enabled: false,
			},
			annotations: {
				points: [
					{
						x: annotation.web.x,
						y: annotation.web.y,
						marker: {
							size: 3,
							strokeWidth: 1,
							strokeColor: isErrorChart ? colorSet[2] : colorSet[0],
							radius: 2,
							cssClass: 'apexcharts-custom-class',
						},
						label: {
							text: `High ${annotation.web.y}`,
							borderColor: 'none',
							style: {
								background: 'none',
								fontWeight: 600,
								fontSize: '10',
								color: '#000',
							},
						},
					},
					{
						x: annotation.native.x,
						y: annotation.native.y,
						marker: {
							size: 5,
							fillColor: '#fff',
							strokeWidth: 1,
							strokeColor: isErrorChart ? colorSet[3] : colorSet[1],
							radius: 2,
							cssClass: 'apexcharts-custom-class',
						},
						label: {
							text: `High ${annotation.native.y}`,
							borderColor: 'none',
							style: {
								fontWeight: 600,
								fontSize: '10',
								color: '#000',
							},
						},
					},
				],
			},
			xaxis: {
				type: 'datetime',
				axisTicks: { show: false },
				axisBorder: { show: false },
				tickAmount: 5,
				tickPlacement: 'on',
				range: 300,
				labels: {
					show: true,
					rotate: 0,
					rotateAlways: false,
					trim: false,
					// eslint-disable-next-line
					formatter(value, timestamp?, opts?) {
						const time = moment
							.unix(Number(value) + 60)
							.utcOffset(timeZone.slice(3))
							.format('HH:mm:ss');
						if (time === '00:00:00') {
							return '24:00:00';
						}
						return time;
					},
					style: {
						colors: `${gray[600]}`,
						fontWeight: '400',
						fontSize: '10',
						cssClass: 'apexcharts-xaxis-label',
					},
					offsetX: 20,
					offsetY: -6,
					maxHeight: 15,
					hideOverlappingLabels: true,
				},
				tooltip: { enabled: false },
			},
			yaxis: {
				decimalsInFloat: 0,
				tickAmount: 3,
				labels: {
					style: { colors: `${gray[700]}`, fontWeight: '400', fontSize: '10' },
					offsetY: 2,
				},
				max: maxY,
				min: 0,
				forceNiceScale: true,
			},
			grid: {
				show: true,
				borderColor: `${gray[100]}`,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
			},
			tooltip: {
				enabled: true,
				shared: false,
				followCursor: false,
				custom({ series, seriesIndex, dataPointIndex, w }) {
					const tooltip = `
						<div class="box" style="
						box-sizing: border-box;
						padding: 4px 8px;
						color: ${gray[900]};
						line-height: 160%;
						font-weight: 400;
						font-size: 12px;">
							<div class="loadWeb">
								${
									series[seriesIndex][dataPointIndex] !== undefined
										? `<div class="dot" style="
								display: inline-block;
								margin-right: 4px;
								border-radius: 50%;
								width: 7.5px;
								height: 7.5px;
								background-color: ${w.globals.seriesColors[seriesIndex]}
								"></div>
								<span class="data" style="
								font-size: 10px;
								font-weight: 600; ">
								${series[seriesIndex][dataPointIndex]}
								</span>
							</div>`
										: ''
								}
							
						</div>
					`;
					return tooltip;
				},
			},
			legend: {
				show: false,
			},
			fill: {
				opacity: isChartTypeArea ? 0.1 : 1,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxY, timeZone, annotation]
	);

	return {
		apexLineOption: options,
	};
}

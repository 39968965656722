import axios from 'axios';

export const fileDownloadFromUrl = async (url: string, name: string) => {
	try {
		const { data } = await axios.get<Blob>(url, { responseType: 'blob' });

		const urlFromBlob = URL.createObjectURL(data);
		const aTag = document.createElement('a');

		aTag.href = urlFromBlob;
		aTag.download = name;

		document.body.appendChild(aTag);

		aTag.click();

		setTimeout(() => {
			URL.revokeObjectURL(urlFromBlob);
		}, 60000);

		aTag.remove();
	} catch (e) {
		// eslint-disable-next-line
		alert('fail download file');
	}
};

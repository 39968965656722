import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { GetOrganizationAddressData } from '../types/getOrganizationAddress.type';
import getOrganizationAddressApi from '../api/getOrganizationAdderss.api';

export const getOrganizationAddressThunk = createAsyncThunk<GetOrganizationAddressData, void, { state: RootState }>(
	'address/get',
	async (_, thunkAPI) => {
		const {
			account: { organizationId },
		} = thunkAPI.getState();

		try {
			const { data } = await getOrganizationAddressApi({ organizationId });

			if (data !== null) {
				return { ...data };
			}

			return {
				id: 0,
				vat: '',
				country: '',
				city: '',
				streetAddress: '',
				streetAddressDetail: '',
				postalCode: '',
				organizationId,
				region: '',
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

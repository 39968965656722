import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from 'common/redux/store';

import postSegmentStatDataApi from 'features/nfControl/api/postSegmentStatData.v2.api';
import type { SegmentData, ChartData } from 'features/nfControl/context/nfControlSlice';

import {
	X_AXIS_NUM,
	waitTicketMaxYratio,
	processTimeMaxYratio,
	maxProcessTimeY,
	maxWaitTicketY,
	SEG_TIME_RANGE,
	X_AXIS_VALUE_INTERVAL,
} from 'features/nfBasic/data/constants';

import { calcOutflowRate } from 'features/nfBasic/utils/monitoringDataFunc';
import type { FunnelData } from 'features/nfBasic/types/funnelLib.type';

import { setSumStatData } from 'features/nfControl/data/statDataFunc';
import { projectInitialStatValue } from 'features/nfControl/data/initialData';

interface PostSegmentStatDataInitialThunkResult {
	value: SegmentData[];
	lastTimeStamp: number;
	classic: FunnelData;
	isPathControl: boolean;
}

interface PostSegmentStatDataInitialThunkProps {
	isPathControl: boolean;
}

interface PostSegmentStatDataInitialThunkError {
	isPathControl: boolean;
	// eslint-disable-next-line
	error: any;
}

const postSegmentStatDataInitialThunk = createAsyncThunk<
	PostSegmentStatDataInitialThunkResult,
	PostSegmentStatDataInitialThunkProps,
	{ state: RootState; rejectValue: PostSegmentStatDataInitialThunkError }
>('segment/statData/post/initial/v2', async (args, thunkAPI) => {
	const { isPathControl } = args;

	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const meta = isPathControl
		? thunkAPI.getState().nfControl.segment.path.meta
		: thunkAPI.getState().nfControl.segment.basic.meta;

	try {
		const { data } = await postSegmentStatDataApi({
			tenantId: tenantId || '',
			isPathControl,
			organizationId,
			projectId: selectedProjectId,
			begin: Number(moment().subtract(SEG_TIME_RANGE, 's').format('X')),
			end: Number(moment().format('X')),
			segmentIds: meta.map(el => el.id),
		});

		const lastTimeStampData =
			data.length > 0 && data[0].segments[0].value.length > 0
				? data[0].segments[0].value[data[0].segments[0].value.length - 1].time
				: Math.floor(Number(moment().format('X')));

		const projectStat = data.length > 0 && data[0].projectStat ? data[0].projectStat : projectInitialStatValue;

		const newValue: SegmentData[] = meta.map((el, idx) => {
			const currRawData = data[0].segments[idx].value;
			const currRawDataLength = currRawData.length;
			const sumStatData = setSumStatData(currRawData);

			if (currRawDataLength === X_AXIS_NUM) {
				return {
					segCardValue: {
						totalTickets: sumStatData.totalTickets,
						numbersWaiting: currRawData[currRawDataLength - 1].numbersWaiting,
						waitTime: currRawData[currRawDataLength - 1].waitTime,
						totalInflow: sumStatData.totalInflow,
						maxInflow: currRawData[currRawDataLength - 1].maxInflow,
						totalOutflow: sumStatData.totalOutflow,
						processTime: currRawData[currRawDataLength - 1].processTime,
						usedSize: currRawData[currRawDataLength - 1].usedSize,
						nfBlock: sumStatData.nfBlock,
						byPass: sumStatData.byPass,
					},
					segmentColor: el.segmentColor,
					segmentGraphYn: el.segmentGraphYn,
					waitTicketY: currRawData.map(waitEl => ({
						x: waitEl.time,
						y: Math.ceil(waitEl.totalTickets),
					})),
					processTimeY: currRawData.map(processTimeEl => ({
						x: processTimeEl.time,
						y: Number(processTimeEl.processTime.toFixed(1)),
					})),
					outflowRateY: currRawData.map(outflowRateEl => ({
						x: outflowRateEl.time,
						y: calcOutflowRate(outflowRateEl.totalOutflow, outflowRateEl.totalInflow),
					})),
					id: el.id,
					segmentName: el.segmentName,
					accessYn: el.accessYn,
					segmentKey: el.segmentKey,
					prevSegCardValue: {
						totalTickets: currRawData[currRawDataLength - 2].totalTickets,
						numbersWaiting: currRawData[currRawDataLength - 2].numbersWaiting,
						waitTime: currRawData[currRawDataLength - 2].waitTime,
						totalInflow: currRawData[currRawDataLength - 2].totalInflow,
						maxInflow: currRawData[currRawDataLength - 2].maxInflow,
						totalOutflow: currRawData[currRawDataLength - 2].totalOutflow,
						processTime: currRawData[currRawDataLength - 2].processTime,
						usedSize: currRawData[currRawDataLength - 2].usedSize,
						nfBlock: currRawData[currRawDataLength - 2].nfBlock,
						byPass: currRawData[currRawDataLength - 2].byPass,
					},
					maxWaitTicket:
						Math.max(...currRawData.map(maxWaitTicketEl => Math.ceil(maxWaitTicketEl.totalTickets))) *
						waitTicketMaxYratio,
					maxProcessTime:
						Math.max(...currRawData.map(maxProcessTimeEl => Number(maxProcessTimeEl.processTime.toFixed(1)))) *
						processTimeMaxYratio,
					classic: {
						id: el.segmentKey,
						type: 1,
						new_key_chk_enter: sumStatData.totalTickets,
						wait_user: currRawData[currRawDataLength - 1].numbersWaiting,
						block: sumStatData.nfBlock,
						wait_tm: currRawData[currRawDataLength - 1].waitTime,
						chk_enter_succ: sumStatData.totalInflow,
						limit: currRawData[currRawDataLength - 1].maxInflow,
						complete_succ: sumStatData.totalOutflow,
						xview: [0, 0, 0, 0, 0, sumStatData.totalOutflow, 0, 0, 0, 0],
						used_size: currRawData[currRawDataLength - 1].usedSize,
						bypass: sumStatData.byPass,
						// 미사용
						access_mode: 0,
						ic_access_mode: 0,
						wait_max: 10,
						limit_max: 100,
						limitmax: 500,
					},
				};
			}

			if (currRawDataLength < X_AXIS_NUM && currRawDataLength > 0) {
				const nullChartData = Array.from<unknown, ChartData>(
					{ length: X_AXIS_NUM - currRawDataLength },
					(nullEl, nullIdx) => ({
						x: lastTimeStampData + (nullIdx + 1) * X_AXIS_VALUE_INTERVAL,
						y: null,
					})
				);

				return {
					segCardValue: {
						totalTickets: sumStatData.totalTickets,
						numbersWaiting: currRawData[currRawDataLength - 1].numbersWaiting,
						waitTime: currRawData[currRawDataLength - 1].waitTime,
						totalInflow: sumStatData.totalInflow,
						maxInflow: currRawData[currRawDataLength - 1].maxInflow,
						totalOutflow: sumStatData.totalOutflow,
						processTime: currRawData[currRawDataLength - 1].processTime,
						usedSize: currRawData[currRawDataLength - 1].usedSize,
						nfBlock: sumStatData.nfBlock,
						byPass: sumStatData.byPass,
					},
					segmentColor: el.segmentColor,
					segmentGraphYn: el.segmentGraphYn,
					waitTicketY: nullChartData.slice().concat(
						currRawData.map<ChartData>(waitTicketEl => ({
							x: waitTicketEl.time,
							y: Math.ceil(waitTicketEl.totalTickets),
						}))
					),
					processTimeY: nullChartData.slice().concat(
						currRawData.map<ChartData>(processTimeEl => ({
							x: processTimeEl.time,
							y: Number(processTimeEl.processTime.toFixed(1)),
						}))
					),
					outflowRateY: nullChartData.slice().concat(
						currRawData.map<ChartData>(outflowEl => ({
							x: outflowEl.time,
							y: calcOutflowRate(outflowEl.totalOutflow, outflowEl.totalInflow),
						}))
					),
					id: el.id,
					segmentName: el.segmentName,
					accessYn: el.accessYn,
					segmentKey: el.segmentKey,
					prevSegCardValue: {
						totalTickets: currRawData[currRawDataLength - 2].totalTickets,
						numbersWaiting: currRawData[currRawDataLength - 2].numbersWaiting,
						waitTime: currRawData[currRawDataLength - 2].waitTime,
						totalInflow: currRawData[currRawDataLength - 2].totalInflow,
						maxInflow: currRawData[currRawDataLength - 2].maxInflow,
						totalOutflow: currRawData[currRawDataLength - 2].totalOutflow,
						processTime: currRawData[currRawDataLength - 2].processTime,
						usedSize: currRawData[currRawDataLength - 2].usedSize,
						nfBlock: currRawData[currRawDataLength - 2].nfBlock,
						byPass: currRawData[currRawDataLength - 2].byPass,
					},
					maxWaitTicket:
						Math.max(...currRawData.map(maxWaitTicketEl => Math.ceil(maxWaitTicketEl.totalTickets))) *
						waitTicketMaxYratio,
					maxProcessTime:
						Math.max(...currRawData.map(maxProcessTimeEl => Number(maxProcessTimeEl.processTime.toFixed(1)))) *
						processTimeMaxYratio,
					classic: {
						id: el.segmentKey,
						type: 1,
						new_key_chk_enter: sumStatData.totalTickets,
						wait_user: currRawData[currRawDataLength - 1].numbersWaiting,
						block: sumStatData.nfBlock,
						wait_tm: currRawData[currRawDataLength - 1].waitTime,
						chk_enter_succ: sumStatData.totalInflow,
						limit: currRawData[currRawDataLength - 1].maxInflow,
						complete_succ: sumStatData.totalOutflow,
						xview: [0, 0, 0, 0, 0, sumStatData.totalOutflow, 0, 0, 0, 0],
						used_size: currRawData[currRawDataLength - 1].usedSize,
						bypass: sumStatData.byPass,
						// 미사용
						access_mode: 0,
						ic_access_mode: 0,
						wait_max: 10,
						limit_max: 100,
						limitmax: 500,
					},
				};
			}

			if (currRawDataLength > X_AXIS_NUM) {
				return {
					segCardValue: {
						totalTickets: sumStatData.totalTickets,
						numbersWaiting: currRawData[currRawDataLength - 1].numbersWaiting,
						waitTime: currRawData[currRawDataLength - 1].waitTime,
						totalInflow: sumStatData.totalInflow,
						maxInflow: currRawData[currRawDataLength - 1].maxInflow,
						totalOutflow: sumStatData.totalOutflow,
						processTime: currRawData[currRawDataLength - 1].processTime,
						usedSize: currRawData[currRawDataLength - 1].usedSize,
						nfBlock: sumStatData.nfBlock,
						byPass: sumStatData.byPass,
					},
					segmentColor: el.segmentColor,
					segmentGraphYn: el.segmentGraphYn,
					waitTicketY: currRawData
						.slice(currRawDataLength - X_AXIS_NUM)
						.map(waitTicketEl => ({ x: waitTicketEl.time, y: Math.ceil(waitTicketEl.totalTickets) })),
					processTimeY: currRawData.slice(currRawDataLength - X_AXIS_NUM).map(processTimeEl => ({
						x: processTimeEl.time,
						y: Number(processTimeEl.processTime.toFixed(1)),
					})),
					outflowRateY: currRawData.slice(currRawDataLength - X_AXIS_NUM).map(outflowRateEl => ({
						x: outflowRateEl.time,
						y: calcOutflowRate(outflowRateEl.totalOutflow, outflowRateEl.totalInflow),
					})),
					id: el.id,
					segmentName: el.segmentName,
					accessYn: el.accessYn,
					segmentKey: el.segmentKey,
					prevSegCardValue: {
						totalTickets: currRawData[currRawDataLength - 2].totalTickets,
						numbersWaiting: currRawData[currRawDataLength - 2].numbersWaiting,
						waitTime: currRawData[currRawDataLength - 2].waitTime,
						totalInflow: currRawData[currRawDataLength - 2].totalInflow,
						maxInflow: currRawData[currRawDataLength - 2].maxInflow,
						totalOutflow: currRawData[currRawDataLength - 2].totalOutflow,
						processTime: currRawData[currRawDataLength - 2].processTime,
						usedSize: currRawData[currRawDataLength - 2].usedSize,
						nfBlock: currRawData[currRawDataLength - 2].nfBlock,
						byPass: currRawData[currRawDataLength - 2].byPass,
					},
					maxWaitTicket:
						Math.max(
							...currRawData
								.slice(currRawDataLength - X_AXIS_NUM)
								.map(maxWaitTicketEl => Math.ceil(maxWaitTicketEl.totalTickets))
						) * waitTicketMaxYratio,
					maxProcessTime:
						Math.max(
							...currRawData
								.slice(currRawDataLength - X_AXIS_NUM)
								.map(maxProcessTimeEl => Number(maxProcessTimeEl.processTime.toFixed(1)))
						) * processTimeMaxYratio,
					classic: {
						id: el.segmentKey,
						type: 1,
						new_key_chk_enter: sumStatData.totalTickets,
						wait_user: currRawData[currRawDataLength - 1].numbersWaiting,
						block: sumStatData.nfBlock,
						wait_tm: currRawData[currRawDataLength - 1].waitTime,
						chk_enter_succ: sumStatData.totalInflow,
						limit: currRawData[currRawDataLength - 1].maxInflow,
						complete_succ: sumStatData.totalOutflow,
						xview: [0, 0, 0, 0, 0, sumStatData.totalOutflow, 0, 0, 0, 0],
						used_size: currRawData[currRawDataLength - 1].usedSize,
						bypass: sumStatData.byPass,
						// 미사용
						access_mode: 0,
						ic_access_mode: 0,
						wait_max: 10,
						limit_max: 100,
						limitmax: 500,
					},
				};
			}

			return {
				prevSegCardValue: {
					totalTickets: 0,
					numbersWaiting: 0,
					waitTime: 0,
					totalInflow: 0,
					maxInflow: meta[idx].maxInflow,
					totalOutflow: 0,
					processTime: 0,
					usedSize: 0,
					nfBlock: 0,
					byPass: 0,
				},
				segCardValue: {
					totalTickets: 0,
					numbersWaiting: 0,
					waitTime: 0,
					totalInflow: 0,
					maxInflow: meta[idx].maxInflow,
					totalOutflow: 0,
					processTime: 0,
					usedSize: 0,
					nfBlock: 0,
					byPass: 0,
				},
				segmentColor: el.segmentColor,
				segmentGraphYn: el.segmentGraphYn,
				waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (b, currIdx) => ({
					x: lastTimeStampData - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
					y: null,
				})),
				processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (c, currIdx) => ({
					x: lastTimeStampData - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
					y: null,
				})),
				outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (d, currIdx) => ({
					x: lastTimeStampData - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
					y: null,
				})),
				id: el.id,
				segmentName: el.segmentName,
				accessYn: el.accessYn,
				segmentKey: el.segmentKey,
				maxWaitTicket: maxWaitTicketY,
				maxProcessTime: maxProcessTimeY,
				classic: {
					id: el.segmentKey,
					type: 1,
					new_key_chk_enter: 0,
					wait_user: 0,
					block: 0,
					wait_tm: 0,
					chk_enter_succ: 0,
					limit: el.maxInflow,
					complete_succ: 0,
					xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					used_size: 0,
					bypass: 0,
					// 미사용
					access_mode: 0,
					ic_access_mode: 0,
					wait_max: 10,
					limit_max: 100,
					limitmax: 500,
				},
			};
		});

		const projectFunnelData: FunnelData = {
			id: 'projectFunnel',
			type: 1,
			new_key_chk_enter: projectStat.totalTickets,
			wait_user: projectStat.numbersWaiting,
			block: projectStat.nfBlock,
			wait_tm: projectStat.waitTime,
			chk_enter_succ: projectStat.totalInflow,
			limit: projectStat.maxInflow,
			complete_succ: projectStat.totalOutflow,
			xview: [0, 0, 0, 0, 0, projectStat.totalOutflow, 0, 0, 0, 0],
			used_size: projectStat.usedSize,
			bypass: projectStat.byPass,
			// 미사용
			access_mode: 0,
			ic_access_mode: 0,
			wait_max: 10,
			limit_max: 100,
			limitmax: 500,
		};

		return {
			classic: projectFunnelData,
			value: newValue,
			lastTimeStamp: lastTimeStampData,
			isPathControl,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.response.data, isPathControl: true });
	}
});

export default postSegmentStatDataInitialThunk;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import type { PutIntervalSegmentSettingApiResponse } from '../types/putIntervalSegmentSetting.type';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 특정 세그먼트 수정 api
 */

interface PutIntervalSegmentSettingApiBody {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: number;
	startUri: string;
	endUri: string;
}

export interface PutSegmentSettingApiProps {
	tenantUrl: string;
	tenantId: string;
	organizationId: number;
	prjId: number;
	segId: number;
	body: PutIntervalSegmentSettingApiBody;
}

export default async function putIntervalSegmentSettingApi({
	tenantUrl,
	tenantId,
	organizationId,
	prjId,
	segId,
	body,
}: PutSegmentSettingApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutIntervalSegmentSettingApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${prjId}/section-segment/${segId}`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from 'common/redux/store';
import { closeAllMonitoringModals } from 'common/context/modalSlice';
import { toggleIsSegmentUpdatedToastVisible } from 'common/context/toastSlice';

import postSegmentStatDataApi from 'features/nfControl/api/postSegmentStatData.v2.api';
import type { SegmentData } from 'features/nfControl/context/nfControlSlice';
import {
	X_AXIS_NUM,
	waitTicketMaxYratio,
	X_AXIS_INTERVAL_TIME,
	processTimeMaxYratio,
	IS_RELOAD_KEY,
	IS_RELOAD_TRUE,
} from 'features/nfBasic/data/constants';
import { calcOutflowRate, compareSegIds } from 'features/nfBasic/utils/monitoringDataFunc';
import { FunnelData } from 'features/nfBasic/types/funnelLib.type';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';
import { projectInitialStatValue } from 'features/nfControl/data/initialData';
import { setSumStatData } from 'features/nfControl/data/statDataFunc';

interface PostSegmentStatDataUpdateThunkResult {
	value: SegmentData[];
	lastTimeStamp: number;
	classic: FunnelData;
	isPathControl: boolean;
	meta: SegmentMetaData[];
}

interface PostSegmentStatDataUpdateThunkProps {
	isPathControl: boolean;
}

interface PostSegmentStatDataUpdateThunkError {
	isPathControl: boolean;
	// eslint-disable-next-line
	error: any;
}

const postSegmentStatDataUpdateThunk = createAsyncThunk<
	PostSegmentStatDataUpdateThunkResult,
	PostSegmentStatDataUpdateThunkProps,
	{ state: RootState; rejectValue: PostSegmentStatDataUpdateThunkError }
>('segment/statData/post/update/v2', async (args, thunkAPI) => {
	const { isPathControl } = args;

	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
		nfControl: { lastTimeStamp, project },
	} = thunkAPI.getState();

	const meta = isPathControl
		? thunkAPI.getState().nfControl.segment.path.meta
		: thunkAPI.getState().nfControl.segment.basic.meta;

	const value = isPathControl
		? thunkAPI.getState().nfControl.segment.path.value
		: thunkAPI.getState().nfControl.segment.basic.value;

	try {
		const { data } = await postSegmentStatDataApi({
			tenantId: tenantId || '',
			organizationId,
			projectId: selectedProjectId,
			begin: lastTimeStamp + 1,
			end: Number(moment().format('X')),
			segmentIds: meta.map(el => el.id),
			isPathControl,
		});

		if (
			!compareSegIds(
				meta.map(el => el.id),
				data[0].segIdList
			)
		) {
			thunkAPI.dispatch(closeAllMonitoringModals());
			thunkAPI.dispatch(toggleIsSegmentUpdatedToastVisible());
			sessionStorage.setItem(IS_RELOAD_KEY, IS_RELOAD_TRUE);

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}

		const lastTimeStampData =
			data.length > 0 && data[0].segments[0].value.length > 0
				? data[0].segments[0].value[data[0].segments[0].value.length - 1].time
				: // : Math.floor(Number(moment().format('X')));
				  lastTimeStamp;

		const projectStat = data.length > 0 && data[0].projectStat ? data[0].projectStat : projectInitialStatValue;

		if (data[0].segments[0].value.length === 0) {
			return {
				value: meta.map((el, idx) => {
					return {
						segCardValue: {
							totalTickets: 0,
							numbersWaiting: 0,
							waitTime: 0,
							totalInflow: 0,
							maxInflow: meta[idx].maxInflow,
							totalOutflow: 0,
							processTime: 0,
							usedSize: 0,
							nfBlock: 0,
							byPass: 0,
						},
						segmentColor: el.segmentColor,
						segmentGraphYn: el.segmentGraphYn,
						waitTicketY: value[idx].waitTicketY.concat([
							{
								x: lastTimeStamp + X_AXIS_INTERVAL_TIME,
								y: null,
							},
						]),
						processTimeY: value[idx].processTimeY.concat([
							{
								x: lastTimeStamp + X_AXIS_INTERVAL_TIME,
								y: null,
							},
						]),
						outflowRateY: value[idx].outflowRateY.concat([
							{
								x: lastTimeStamp + X_AXIS_INTERVAL_TIME,
								y: null,
							},
						]),
						id: el.id,
						segmentName: el.segmentName,
						accessYn: el.accessYn,
						segmentKey: el.segmentKey,
						prevSegCardValue: { ...value[idx].segCardValue },
						maxProcessTime: value[idx].maxProcessTime,
						maxWaitTicket: value[idx].maxWaitTicket,
						classic: { ...value[idx].classic },
					};
				}),
				lastTimeStamp: lastTimeStampData,
				classic: project.classic,
				isPathControl,
				meta: [...meta],
			};
		}

		const newValue: SegmentData[] = meta.map((el, idx) => {
			const currRawData =
				data[0].segments[idx].value.length > 0
					? data[0].segments[idx].value
					: [
							{
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: meta[idx].maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								time: data[0].segments[0].value[0].time,
								nfBlock: 0,
								byPass: 0,
							},
					  ];
			const currRawDataLength = currRawData.length;
			const sumStatData = setSumStatData(currRawData);
			return {
				segCardValue: {
					totalTickets: sumStatData.totalTickets,
					numbersWaiting: currRawData[currRawDataLength - 1].numbersWaiting,
					waitTime: currRawData[currRawDataLength - 1].waitTime,
					totalInflow: sumStatData.totalInflow,
					maxInflow: currRawData[currRawDataLength - 1].maxInflow,
					totalOutflow: sumStatData.totalOutflow,
					processTime: currRawData[currRawDataLength - 1].processTime,
					usedSize: currRawData[currRawDataLength - 1].usedSize,
					nfBlock: sumStatData.nfBlock,
					byPass: sumStatData.byPass,
				},
				segmentColor: el.segmentColor,
				segmentGraphYn: el.segmentGraphYn,
				waitTicketY: value[idx].waitTicketY.concat(
					currRawData.map(waitTicketEl => ({
						x: waitTicketEl.time,
						y: Math.ceil(waitTicketEl.totalTickets),
					}))
				),
				processTimeY: value[idx].processTimeY.concat(
					currRawData.map(processTimeEl => ({
						x: processTimeEl.time,
						y: Number(processTimeEl.processTime.toFixed(1)),
					}))
				),
				outflowRateY: value[idx].outflowRateY.concat(
					currRawData.map(outflowRateEl => ({
						x: outflowRateEl.time,
						y: calcOutflowRate(outflowRateEl.totalOutflow, outflowRateEl.totalInflow),
					}))
				),
				id: el.id,
				segmentName: el.segmentName,
				accessYn: el.accessYn,
				segmentKey: el.segmentKey,
				prevSegCardValue: { ...value[idx].segCardValue },
				maxProcessTime:
					Math.max(
						...value[idx].processTimeY
							.slice(-X_AXIS_NUM + currRawData.length)
							.map(maxProcessTimeEl => maxProcessTimeEl.y || 0),
						Math.max(
							...currRawData
								.filter(maxProcessTimeEl => maxProcessTimeEl.time > lastTimeStamp)
								.map(maxProcessTimeEl => Number(maxProcessTimeEl.processTime.toFixed(1)))
						)
					) * processTimeMaxYratio,
				maxWaitTicket:
					Math.max(
						...value[idx].waitTicketY
							.slice(-X_AXIS_NUM + currRawData.length)
							.map(maxWaitTicketEl => maxWaitTicketEl.y || 0),
						Math.max(
							...currRawData
								.filter(maxWaitTicketEl => maxWaitTicketEl.time > lastTimeStamp)
								.map(maxWaitTicketEl => Math.ceil(maxWaitTicketEl.totalTickets))
						)
					) * waitTicketMaxYratio,
				classic: {
					id: el.segmentKey,
					type: 1,
					new_key_chk_enter: sumStatData.totalTickets,
					wait_user: currRawData[currRawDataLength - 1].numbersWaiting,
					block: sumStatData.nfBlock,
					wait_tm: currRawData[currRawDataLength - 1].waitTime,
					chk_enter_succ: sumStatData.totalInflow,
					limit: currRawData[currRawDataLength - 1].maxInflow,
					complete_succ: sumStatData.totalOutflow,
					xview: [0, 0, 0, 0, 0, sumStatData.totalOutflow, 0, 0, 0, 0],
					used_size: currRawData[currRawDataLength - 1].usedSize,
					bypass: sumStatData.byPass,
					// 미사용
					access_mode: 0,
					ic_access_mode: 0,
					wait_max: 10,
					limit_max: 100,
					limitmax: 500,
				},
			};
		});

		const projectFunnelData: FunnelData = {
			id: 'projectFunnel',
			type: 1,
			new_key_chk_enter: projectStat.totalTickets,
			wait_user: projectStat.numbersWaiting,
			block: projectStat.nfBlock,
			wait_tm: projectStat.waitTime,
			chk_enter_succ: projectStat.totalInflow,
			limit: projectStat.maxInflow,
			complete_succ: projectStat.totalOutflow,
			xview: [0, 0, 0, 0, 0, projectStat.totalOutflow, 0, 0, 0, 0],
			used_size: projectStat.usedSize,
			bypass: projectStat.byPass,
			// 미사용
			access_mode: 0,
			ic_access_mode: 0,
			wait_max: 10,
			limit_max: 100,
			limitmax: 500,
		};

		return {
			classic: projectFunnelData,
			value: newValue,
			lastTimeStamp: lastTimeStampData,
			isPathControl,
			meta: meta.map((el, idx) => ({
				...el,
				accessYn: data[0].segments[idx].accessYn !== undefined ? data[0].segments[idx].accessYn : el.accessYn,
			})),
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.response.data, isPathControl });
	}
});

export default postSegmentStatDataUpdateThunk;

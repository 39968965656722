/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { paletteSDS } from 'surf-design-system';

import { SurffyLogoDark } from 'common/assets/icons';
import { getI18nPath } from 'common/utils/languageFunc';

export default function AuthHeader() {
	return (
		<div css={containerSt}>
			<SurffyLogoDark
				onClick={() => window.location.replace(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}`)}
				css={logoCss}
			/>
		</div>
	);
}

const containerSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	min-height: 4rem;

	border-bottom: 1px solid ${paletteSDS.gray[300]};
	background-color: ${paletteSDS.gray[50]};
`;

const logoCss = css`
	cursor: pointer;
`;

import axios from 'axios';

import { GetProductListApiResponse } from 'common/types/getProductInfoList.type';

export const getProductListApi = async () => {
	const { data } = await axios.get<GetProductListApiResponse>(
		`${process.env.REACT_APP_BASE_URL}/v1/admin/product-info/list`
	);
	return data;
};

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InfoOutlineIcon, Loader, Modal, paletteSDS, Portal, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsUpdateMainDomainConfirmModalVisible } from 'common/context/modalSlice';

interface UpdateMainDomainConfirmModalProps {
	onConfirm: () => void;
}

export default function UpdateMainDomainConfirmModal({ onConfirm }: UpdateMainDomainConfirmModalProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isLoading: isPrjMetaLoading } = useSelector((state: RootState) => state.prjMeta);

	const { isLoading: isProjectInfoLoading } = useSelector((state: RootState) => state.projectInfo);

	const { isUpdateMainDomainConfirmModalVisible } = useSelector((state: RootState) => state.modal);

	const primaryButtonClickHandler = async () => {
		onConfirm();
	};

	const secondaryButtonClickHandler = () => {
		dispatch(toggleIsUpdateMainDomainConfirmModalVisible());
	};

	return (
		<Portal>
			<Modal
				visible={isUpdateMainDomainConfirmModalVisible}
				title={t('ServiceDomainSetting.updateMainDomainConfirmModalTitle')}
				isCloseIconVisible={false}
				actionType="double"
				primaryBtnLabel={
					isPrjMetaLoading || isProjectInfoLoading ? <Loader height="16px" /> : t('ServiceDomainSetting.confirm')
				}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('ServiceDomainSetting.cancel')}
				secondaryBtnDisable={isPrjMetaLoading || isProjectInfoLoading}
				secondaryBtnFunction={secondaryButtonClickHandler}
				primaryBtnDisable={isPrjMetaLoading || isProjectInfoLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<div css={bodyBox}>
					<div css={subTitleSt}>{t('ServiceDomainSetting.updateMainDomainConfirmModalSubTitle')}</div>
					<div css={infoBox}>
						<InfoOutlineIcon
							fill={paletteSDS.blue['500']}
							cursor="default"
							emotionCss={css`
								flex: none;
							`}
						/>
						<div css={infoSt}>{t('ServiceDomainSetting.updateMainDomainCaution')}</div>
					</div>
				</div>
			</Modal>
		</Portal>
	);
}

const bodyBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const subTitleSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.gray[900]};
`;

const infoBox = css`
	display: flex;
	align-items: flex-start;
	gap: 8px;

	padding: 12px 16px;

	background: ${paletteSDS.blue['50']};

	border-radius: 8px;

	border: 1px solid ${paletteSDS.blue['200']};
`;

const infoSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.black};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, ChevronDownIcon } from 'surf-design-system';

import type { RootState } from 'common/redux/store';

import { getDateFormatLanguage, getStartDateFormat, addNumberComma, priceUnitFormat } from '../utils/formatFunc';
import { ChargeDetailData } from '../types/getPaymentCharge.type';

interface InvoiceItemProps {
	invoiceDetail: ChargeDetailData;
	defaultIsItemDetailExpand: boolean;
}

/**
 * author: Finn
 * createdAt: 2022-09-22
 * updatedAt: 2022-09-23
 * description: 인보이스 개별 리스트 컴포넌트
 * todo: 디자인 변경 후 적용 필요 (날짜에서 시간도 같이 표현되어야 함)
 */
export default function InvoiceItem({ invoiceDetail, defaultIsItemDetailExpand }: InvoiceItemProps) {
	const [isItemDetailExpand, setIsItemDetailExpand] = useState(defaultIsItemDetailExpand);

	const { language } = useSelector((state: RootState) => state.account);

	const { t } = useTranslation();

	const hoursToDate = Math.ceil(invoiceDetail.quantityHour / 24);

	const handleShowList = () => {
		setIsItemDetailExpand(prev => !prev);
	};

	const startDate = getStartDateFormat(invoiceDetail.startAt, language);
	const endDate = getDateFormatLanguage(invoiceDetail.endAt, language);

	const priceUnit = priceUnitFormat(invoiceDetail.priceUnit);

	return (
		<ul css={tbodySt}>
			<li css={firstSt}>
				<div css={periodSt}>
					{startDate} - {endDate}
				</div>
				<div css={descriptionSt}>NetFUNNEL Small (Monthly)</div>
				<div css={quantitySt}>{t('planSubscription.quantityDays', { days: hoursToDate })}</div>
				<div css={priceSt}>
					{priceUnit}
					{addNumberComma(String(invoiceDetail.monthPrice))}
					{t('planSubscription.quantityMonth')}
				</div>
				<div css={amountSt}>
					{priceUnit}
					{addNumberComma(String(invoiceDetail.charge))}
				</div>
			</li>
			<li css={secondSt}>
				<div>NetFUNNEL Small (Pay-as-you-go)</div>
				<div css={buttonSt(isItemDetailExpand)}>
					<ChevronDownIcon onClick={handleShowList} size="xSmall" fill={paletteSDS.blue[700]} />
				</div>
			</li>
			{isItemDetailExpand ? (
				<ul css={thirdSt}>
					<li>
						<div>• Active Users</div>
						<div>{invoiceDetail.activeUsers.quantity} Units</div>
						<div>
							{priceUnit}
							{invoiceDetail.activeUsers.price} / Unit
						</div>
						<div>
							{priceUnit}
							{invoiceDetail.activeUsers.charge}
						</div>
					</li>
					<li>
						<div>• Waiting Users</div>
						<div>{invoiceDetail.waitingUsers.quantity} Units</div>
						<div>
							{priceUnit}
							{invoiceDetail.waitingUsers.price} / Unit
						</div>
						<div>
							{priceUnit}
							{invoiceDetail.waitingUsers.charge}
						</div>
					</li>
					<li>
						<div>• Peak Concurrent Connecting Users</div>
						<div>{invoiceDetail.peakConcurrentConnectingUsers.quantity} Units</div>
						<div>
							{priceUnit}
							{invoiceDetail.peakConcurrentConnectingUsers.price} / Unit
						</div>
						<div>
							{priceUnit}
							{invoiceDetail.peakConcurrentConnectingUsers.charge}{' '}
						</div>
					</li>
				</ul>
			) : null}
		</ul>
	);
}

const tbodySt = css`
	padding: 0.5rem 0;
	width: 100%;
	min-height: 3.8125rem;

	border-bottom: 1px solid ${paletteSDS.gray[300]};

	font-size: 13px;
	background-color: ${paletteSDS.white};
`;

const firstSt = css`
	display: flex;
	height: 1.3125rem;
	line-height: 160%;
`;

const secondSt = css`
	display: flex;
	height: 1.3125rem;
	padding-left: 10.3rem;
	line-height: 160%;

	> div:nth-of-type(1) {
		width: 25rem;
		margin-right: 15.5rem;
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const buttonSt = (showInvoiceList: boolean) => css`
	padding-right: 1rem;
	width: 10rem;
	text-align: right;

	> img {
		cursor: pointer;
		transform: ${showInvoiceList && 'rotate(180deg)'};
		transition: all ease 0.1ms;
	}
`;

const thirdSt = css`
	color: ${paletteSDS.gray[900]};

	> li {
		display: flex;
		padding-left: 10.3rem;

		height: 1.3125rem;
		line-height: 160%;

		> div:nth-of-type(1) {
			width: 21.2rem;
		}

		> div:nth-of-type(2) {
			padding-right: 1rem;
			width: 7.5rem;
			text-align: right;
		}

		> div:nth-of-type(3) {
			padding-right: 1rem;
			margin-right: 1rem;
			width: 7.5rem;
			text-align: right;
		}

		> div:nth-of-type(4) {
			width: 7.5rem;
			text-align: right;
		}
	}
`;

const periodSt = css`
	width: 9.375rem;
	padding-left: 1rem;
`;

const descriptionSt = css`
	width: 22.5rem;
	padding-left: 1rem;
	font-weight: ${themeSDS.fontWeight.bold};
`;

const quantitySt = css`
	width: 7.5rem;
	padding-right: 1rem;
	margin-right: 0.5rem;
	text-align: right;
	color: ${paletteSDS.gray[900]};
`;

const priceSt = css`
	width: 7.5rem;
	padding: 0 1rem 0 0.5rem;
	margin-right: 0.5rem;
	color: ${paletteSDS.gray[900]};
`;

const amountSt = css`
	width: 8.5rem;
	padding-right: 1rem;
	text-align: right;
	color: ${paletteSDS.gray[900]};
`;

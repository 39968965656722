import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import { putProjectAccessYnApi } from 'features/nfSetting/api/putProjectAccessYn.v1.api';

export interface PutProjectAccessYnThunkProps {
	accessYn: boolean;
	selectedProjectId: number;
}

export interface PutProjectAccessYnThunkResponse {
	projectId: number;
	accessYn: boolean;
	updatedAt: string;
}

export const putProjectAccessYnThunk = createAsyncThunk<
	PutProjectAccessYnThunkResponse,
	PutProjectAccessYnThunkProps,
	{ state: RootState }
>('nfPrj/put/accessYn/v2', async (args: PutProjectAccessYnThunkProps, thunkAPI) => {
	const { accessYn, selectedProjectId } = args;

	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();

	try {
		const {
			data: { id, updatedAt },
		} = await putProjectAccessYnApi({
			organizationId,
			tenantId: tenantId as string,
			projectId: selectedProjectId,
			accessYn,
		});

		thunkAPI.dispatch(initializeProjectManagementModal());

		return { projectId: id, accessYn, updatedAt };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

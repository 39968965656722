/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { colorPalette, themeCss } from 'surf-design-system';

import { addNumberComma } from '../utils/formatFunc';
import { PlanOption } from '../types/getPlanList.type';

export interface PlanFeatureDetailProps {
	featureData: PlanOption[];
}

export default function PlanFeatureDetail({ featureData }: PlanFeatureDetailProps) {
	const { t } = useTranslation();

	const titleCss = [themeCss.fontSize[14], themeCss.fontWeight.medium, colorPalette.gray[800], titleSt];

	const memberTitleCss = [themeCss.fontSize[14], themeCss.fontWeight.medium, colorPalette.gray[700], titleSt];

	const valueCss = [themeCss.fontSize[14], themeCss.fontWeight.bold, colorPalette.gray[900]];

	return (
		<div css={detailContainerSt}>
			<div css={detailWrapSt}>
				<div css={titleCss}>MAU</div>
				<div css={valueCss}>{addNumberComma(featureData[0].value.toString())}</div>
			</div>
			<div css={detailWrapSt}>
				<div css={titleCss}>MWU</div>
				<div css={valueCss}>{addNumberComma(featureData[1].value.toString())}</div>
			</div>
			<div css={detailWrapSt}>
				<div css={titleCss}>Peak CCU</div>
				<div css={valueCss}>{addNumberComma(featureData[2].value.toString())}</div>
			</div>
			<div css={titleCss}>{t('planSubscription.vwr1')}</div>
			<div css={detailWrapSt}>
				<div css={memberTitleCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.basic')}</div>
				<div css={valueCss}>{addNumberComma(featureData[3].value.toString())}</div>
			</div>
			<div css={detailWrapSt}>
				<div css={memberTitleCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.path')}</div>
				<div css={valueCss}>1</div>
			</div>
			<div css={titleCss}>{t('planSubscription.members')}</div>
			<div css={detailWrapSt}>
				<div css={memberTitleCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.admin')}</div>
				<div css={valueCss}>{addNumberComma(featureData[4].value.toString())}</div>
			</div>
			<div css={detailWrapSt}>
				<div css={memberTitleCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.operator')}</div>
				<div css={valueCss}>{addNumberComma(featureData[5].value.toString())}</div>
			</div>
			<div css={detailWrapSt}>
				<div css={memberTitleCss}>&nbsp;&nbsp;&nbsp;· &nbsp;{t('planSubscription.monitor')}</div>
				<div css={valueCss}>{addNumberComma(featureData[6].value.toString())}</div>
			</div>
			<div css={detailWrapSt}>
				<div css={titleCss}>EUM</div>
				<div css={valueCss}>{t('planSubscription.free')}</div>
			</div>
		</div>
	);
}

const detailContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	margin-top: 0.625rem;
	margin-bottom: 1.5rem;
`;

const titleSt = css`
	line-height: 128%;
	letter-spacing: -0.008em;
	white-space: pre;
`;

const detailWrapSt = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

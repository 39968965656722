/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import FindPasswordTitle from 'features/signin/components/FindPasswordTitle';
import BackToSignin from 'features/signin/components/BackToSignin';

export default function FindPasswordLayout() {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_V3_SITE_KEY as string}>
			<div css={signinContainer}>
				<div css={signinWrapSt}>
					<FindPasswordTitle />
					<Outlet />
					<BackToSignin />
				</div>
			</div>
		</GoogleReCaptchaProvider>
	);
}

const signinContainer = css`
	display: flex;
	justify-content: center;

	width: 100%;
	height: fit-content;

	padding: 5rem;

	.grecaptcha-badge {
		bottom: 5.625rem !important;
	}
`;

const signinWrapSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	width: 25rem;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { setIsIntervalSegmentDeleteConfirmModalVisible } from 'common/context/modalSlice';

import deleteIntervalSegmentApi from '../api/deleteIntervalSegment.api';

export interface DeleteSegmentThunkResult {
	id: number;
	segmentOrder: number;
}

export interface DeleteSegmentThunkProps {
	segmentId: number;
	segmentOrder: number;
	onClose: () => void;
}

export const deleteIntervalSegmentThunk = createAsyncThunk<
	DeleteSegmentThunkResult,
	DeleteSegmentThunkProps,
	{ state: RootState }
>('sectionSegment/delete', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId, segmentOrder, onClose } = args;

	try {
		const { data } = await deleteIntervalSegmentApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			segId: segmentId,
		});

		onClose();

		thunkAPI.dispatch(setIsIntervalSegmentDeleteConfirmModalVisible(true));

		return { ...data, segmentOrder };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

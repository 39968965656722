import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import type { GetMemberListApiResponse } from '../types/getMemberList.type';

export interface GetMemberListApiProps {
	searchValue?: string;
	memberStatus?: string;
	organizationId: number;
}

export default async function getMemberListApi({ searchValue, memberStatus, organizationId }: GetMemberListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetMemberListApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/organization/user-list`,
			{
				params: { searchValue, memberStatus, pageNum: 0, pageSize: 100 },
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PostSubscriptionApiResponse } from '../types/postSubscription.type';

export interface PostSubscriptionApiProps {
	planId: number;
	version: number;
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
	organizationId: number;
}

export default async function postSubscriptionApi({
	planId,
	vat,
	version,
	city,
	country,
	streetAddress,
	streetAddressDetail,
	organizationId,
	region,
	postalCode,
}: PostSubscriptionApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.post<PostSubscriptionApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/payment/subscription`,
			{
				planId,
				version,
				country,
				streetAddress,
				streetAddressDetail,
				city,
				region,
				postalCode,
				vat,
			},
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

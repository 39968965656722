import React from 'react';
import ReactDOM from 'react-dom/client';

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';
import './common/locale/settingi18n';
import 'common/styles/index.css';
import 'common/styles/react-datepicker.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<HistoryRouter history={createBrowserHistory({ window })}>
		<App />
	</HistoryRouter>
);

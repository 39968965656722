/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'common/redux/store';
import { ChevronLeftIcon, ChevronRightIcon, paletteSDS } from 'surf-design-system';

import SegmentDeleteConfirmModal from 'features/nfBasic/components/SegmentDeleteConfirmModal';

import putBasicControlSegmentOrderThunk from 'features/nfControl/thunk/putBasicControlSegmentOrder.v2.thunk';
import OnPremBasicControlSegmentCard from 'features/nfControl/components/OnPremBasicControlSegmentCard';
import OnPremBasicControlSegmentCreateDynamicModal from 'features/nfControl/components/OnPremBasicControlSegmentCreateDynamicModal';
import OnPremBasicControlSegmentUpdateDynamicModal from 'features/nfControl/components/OnPremBasicControlSegmentUpdateDynamicModal';

export default function OnPremBasicControlSegmentBoard() {
	const { meta, value, isValueLoaded } = useSelector((state: RootState) => state.nfControl.segment.basic);

	const { projectList, selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const { activatedYn } = projectList[selectedProjectIndex];

	// description: scrolling
	const [pickedCardIndex, setPickedCardIndex] = useState<number>(0);
	const [segmentScrollPosition, setSegmentScrollPosition] = useState<number>(0);
	const [segmentHiddenWidth, setSegmentHiddenWidth] = useState<number>(0);

	const segmentRef = useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();

	let isScrollBtnActivated = true;

	if (!activatedYn) {
		isScrollBtnActivated = false;
	}

	if ((segmentRef.current?.offsetWidth as number) > meta.length * 160) {
		isScrollBtnActivated = false;
	}

	const handleClickScrollLeft = () => {
		if (activatedYn) {
			if (segmentScrollPosition + 160 > 0) setSegmentScrollPosition(0);
			else setSegmentScrollPosition(prev => prev + 160);
		}
	};

	const handleClickScrollRight = () => {
		if (activatedYn && (segmentRef.current?.offsetWidth as number) < meta.length * 160) {
			if (segmentHiddenWidth + segmentScrollPosition < 160)
				setSegmentScrollPosition(prev => prev - (segmentHiddenWidth + segmentScrollPosition));
			else setSegmentScrollPosition(prev => prev - 160);
		}
	};

	// description: segment 순서 변경
	const segmentCardDragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
		const obj = e.currentTarget;
		// eslint-disable-next-line
		setPickedCardIndex([...(obj.parentNode?.children as any)].indexOf(obj));
	};

	const segmentCardDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const segmentCardDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
		const obj = e.currentTarget;
		// eslint-disable-next-line
		const droppedCardIndex = [...(obj.parentNode?.children as any)].indexOf(obj);

		if (droppedCardIndex !== pickedCardIndex && meta.length > 1) {
			dispatch(putBasicControlSegmentOrderThunk({ pickedCardIndex, droppedCardIndex }));
		}
	};

	// description: 화면상 board 컴포넌트의 width를 측정, scroll 가능한지 판단
	useEffect(() => {
		if (segmentRef.current) {
			setSegmentHiddenWidth(160 * meta.length - segmentRef.current.offsetWidth);
		}
	}, [segmentRef.current?.offsetWidth, meta.length]);

	if (!isValueLoaded) return <div css={segmentWrapperCss(meta.length)} />;

	return (
		<div ref={segmentRef} css={segmentWrapperCss(meta.length)}>
			{meta.length > 0 ? (
				<React.Fragment>
					<ul css={segmentListCss(segmentScrollPosition)}>
						{value.map((data, idx) => (
							<OnPremBasicControlSegmentCard
								key={data.id}
								data={data}
								meta={meta[idx]}
								onDragOver={segmentCardDragOverHandler}
								onDragStart={segmentCardDragStartHandler}
								onDrop={segmentCardDropHandler}
							/>
						))}
					</ul>
					<div css={segmentScrollBtnWrapCss}>
						<button css={segmentScrollBtnCss(isScrollBtnActivated)} onClick={handleClickScrollLeft}>
							<ChevronLeftIcon
								width="20"
								height="20"
								fill={activatedYn ? paletteSDS.blue[700] : paletteSDS.gray[300]}
							/>
						</button>
						<button css={segmentScrollBtnCss(isScrollBtnActivated)} onClick={handleClickScrollRight}>
							<ChevronRightIcon
								width="20"
								height="20"
								fill={activatedYn ? paletteSDS.blue[700] : paletteSDS.gray[300]}
							/>
						</button>
					</div>
				</React.Fragment>
			) : null}
			<OnPremBasicControlSegmentCreateDynamicModal />
			<OnPremBasicControlSegmentUpdateDynamicModal />
			<SegmentDeleteConfirmModal />
		</div>
	);
}

const segmentWrapperCss = (cardsLength: number) => css`
	width: ${cardsLength * 20}rem;
	min-width: calc(100% - 187px);
	height: 448px;
	//height: 408px;

	border-top: 1px solid ${paletteSDS.gray[300]};
	border-bottom: 1px solid ${paletteSDS.gray[300]};
	border-right: 1px solid ${paletteSDS.gray[300]};

	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;

	overflow-x: scroll;

	background: ${paletteSDS.systemColor.white};

	&::-webkit-scrollbar {
		display: none;
	}
`;

const segmentListCss = (segmentScrollPosition: number) => css`
	display: flex;
	width: fit-content;
	height: 100%;
	overflow: visible;
	transform: translateX(${segmentScrollPosition}px);
	transition: 0.5s all;
`;

const segmentScrollBtnWrapCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: -40px;
	right: 16px;
	border: 1px solid ${paletteSDS.gray[500]};
	z-index: 10;
`;

const segmentScrollBtnCss = (activated: boolean) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	width: 2rem;
	height: 2rem;
	background-color: ${paletteSDS.gray[50]};
	cursor: ${activated ? 'pointer' : 'default'};

	svg {
		fill: ${activated ? paletteSDS.blue[700] : paletteSDS.gray[300]};
	}

	:hover {
		svg {
			fill: ${activated ? paletteSDS.blue[800] : paletteSDS.gray[300]};
		}
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS } from 'surf-design-system';
import { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';
import Tooltip from 'common/components/Tooltip';
import URLDashboardSummaryCard from 'features/nfEUM/components/URLDashboardSummaryCard';
import { useRef, useState } from 'react';
import AltText from 'common/components/AltText';

export default function URLDashboardSummary() {
	// const { customerDomain } = useSelector((state: RootState) => state.account);
	const { projectList, selectedProjectId } = useSelector((state: RootState) => state.projectInfo);
	const { t } = useTranslation();

	const [isShowCustomerDomainAltText, setIsShowCustomerDomainAltText] = useState(false);
	const customerDomainRef = useRef<HTMLDivElement>(null);

	const {
		isLoaded,
		hasError,
		summary: {
			seg_uri_data: {
				max_pt: segmentMaxProcessTime,
				max_count: segmentMaxCount,
				avg_pt: segmentAverageProcessTime,
				avg_count: segmentAverageCount,
			},
			overall_uri_data: {
				max_count: overallMaxCount,
				max_pt: overallMaxProcessTime,
				avg_count: overallAverageCount,
				avg_pt: overallProcessTime,
			},
		},
	} = useSelector((state: RootState) => state.eum);

	return (
		<div css={container}>
			<div css={summaryBox}>
				{isLoaded && !hasError ? (
					<URLDashboardSummaryCard
						averageCount={segmentAverageCount}
						maxCount={segmentMaxCount}
						averageProcessTime={segmentAverageProcessTime}
						maxProcessTime={segmentMaxProcessTime}
						title={
							<div css={titleSt}>
								{t('eum.segmentURLDataTitle')}
								<Tooltip message={t('eum.segmentURLDataTooltip')} position="top" />
							</div>
						}
					/>
				) : (
					<URLDashboardSummaryCard
						averageCount={hasError ? '-' : ''}
						maxCount={hasError ? '-' : ''}
						averageProcessTime={hasError ? '-' : ''}
						maxProcessTime={hasError ? '-' : ''}
						title={
							<div css={titleSt}>
								{t('eum.segmentURLDataTitle')}
								<Tooltip message={t('eum.segmentURLDataTooltip')} position="top" />
							</div>
						}
					/>
				)}
				<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
				{isLoaded && !hasError ? (
					<URLDashboardSummaryCard
						averageCount={overallAverageCount}
						maxCount={overallMaxCount}
						averageProcessTime={overallProcessTime}
						maxProcessTime={overallMaxProcessTime}
						title={
							<div css={titleWrapper}>
								<div
									ref={customerDomainRef}
									css={customerDomainSt}
									onMouseEnter={() => setIsShowCustomerDomainAltText(true)}
									onMouseLeave={() => setIsShowCustomerDomainAltText(false)}
								>
									{projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain}
								</div>
								{isShowCustomerDomainAltText && (
									<AltText
										text={projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain}
										textBoxMaxWidth="314px"
										x={customerDomainRef.current?.getBoundingClientRect().x as number}
										y={customerDomainRef.current?.getBoundingClientRect().y as number}
									/>
								)}
								<div css={titleSt}>
									{t('eum.overallURLDataTitle')}
									<Tooltip message={t('eum.overallURLDataTooltip')} position="right" />
								</div>
							</div>
						}
					/>
				) : (
					<URLDashboardSummaryCard
						averageCount={hasError ? '-' : ''}
						maxCount={hasError ? '-' : ''}
						averageProcessTime={hasError ? '-' : ''}
						maxProcessTime={hasError ? '-' : ''}
						title={
							<div css={titleWrapper}>
								<div
									ref={customerDomainRef}
									css={customerDomainSt}
									onMouseEnter={() => setIsShowCustomerDomainAltText(true)}
									onMouseLeave={() => setIsShowCustomerDomainAltText(false)}
								>
									{projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain}
								</div>
								{isShowCustomerDomainAltText &&
									(projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain.length as number) >
										23 && (
										<AltText
											text={projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain}
											textBoxMaxWidth="314px"
											x={customerDomainRef.current?.getBoundingClientRect().x as number}
											y={customerDomainRef.current?.getBoundingClientRect().y as number}
										/>
									)}
								<div css={titleSt}>
									{t('eum.overallURLDataTitle')}
									<Tooltip message={t('eum.overallURLDataTooltip')} position="right" />
								</div>
							</div>
						}
					/>
				)}
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const summaryBox = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const titleSt = css`
	display: flex;
	gap: 6px;
	align-items: center;

	color: ${paletteSDS.gray[900]};
	font-weight: 600;
	font-size: ${themeSDS.fontSize['16']};

	& > strong {
		color: ${paletteSDS.blue['700']};
		font-weight: ${themeSDS.fontWeight.medium};
		font-size: ${themeSDS.fontSize['16']};
	}
`;

const titleWrapper = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const customerDomainSt = css`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	color: ${paletteSDS.gray[700]};
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import { Button, InfoOutlineIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';
import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';
import DividerWithTxt from 'common/components/DividerWithTxt';

interface NetFunnelPaymentFailedProps {
	planNames: PlanLangs[];
	dataRetentionDeadline: string | null;
}

export default function NetFunnelPaymentFailed({ planNames, dataRetentionDeadline }: NetFunnelPaymentFailedProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { language, userRole } = useSelector((state: RootState) => state.account);

	const planLangs = planNames.find(el => el.langType === language) as PlanLangs;

	const seePlansButtonClickHandler = () => {
		// todo: navigate 수정
		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan`);
	};

	return (
		<div css={productBox}>
			<div css={netfunnelSt}>NetFUNNEL</div>
			<div css={gap4pxColumn}>
				<div css={gap8px}>
					<div css={productInfoLabelSt}>{t('console.main.planInUse')}</div>
					<div css={productInfoSt}>
						{planLangs.name}
						<div css={cancelSt}>{t('console.main.paymentFailed')}</div>
					</div>
				</div>
				<div css={gap8px}>
					<div css={productInfoLabelSt}>{t('console.main.dataExpiration')}</div>
					<div css={productInfoSt}>
						{moment(dataRetentionDeadline || '')
							.utcOffset(0)
							.format('MMM. DD. YYYY.')}
					</div>
				</div>
			</div>
			<div css={lowerBox}>
				<DividerWithTxt />
				<h4 css={restartTitle}>
					{t(userRole === 'OWNER' ? 'console.main.paymentFailTitle' : 'console.main.planStop')}
				</h4>
				<div css={restartDescriptionBox}>
					<InfoOutlineIcon cursor="default" fill={paletteSDS.blue['500']} />
					<div css={restartDescriptionSt}>{t('console.main.paymentFailGuide')}</div>
				</div>
				{userRole === 'OWNER' ? (
					<Button size="medium" onClick={seePlansButtonClickHandler}>
						{t('console.main.seePlans')}
					</Button>
				) : null}
			</div>
		</div>
	);
}

const productBox = css`
	display: flex;
	flex-direction: column;
	gap: 8px;

	width: 100%;

	padding: 24px;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};
	border-radius: 8px;
`;

const netfunnelSt = css`
	color: ${paletteSDS.blue['800']};
	font-weight: 800;
	font-size: ${themeSDS.fontSize['20']};
	line-height: 140%;
`;

const gap4pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const gap8px = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const productInfoLabelSt = css`
	width: 160px;

	color: ${paletteSDS.gray['300']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['16']};

	line-height: 160%;
`;

const productInfoSt = css`
	display: flex;
	align-items: center;
	gap: 8px;

	color: ${paletteSDS.gray['500']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['16']};

	line-height: 140%;
`;

const cancelSt = css`
	height: 25px;

	padding: 4px 8px;

	border-radius: 4px;

	background: ${paletteSDS.gray['500']};

	font-size: ${themeSDS.fontSize['13']};
	color: ${paletteSDS.white};
	font-weight: ${themeSDS.fontWeight.regular};

	line-height: 128%;
`;

const lowerBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;

	padding-top: 16px;
`;

const restartDescriptionBox = css`
	display: flex;
	gap: 8px;

	width: 100%;
	height: 48px;

	padding: 12px 16px 12px 16px;

	background: ${paletteSDS.blue['50']};

	border: 1px solid ${paletteSDS.blue['200']};
	border-radius: 8px;
`;

const restartDescriptionSt = css`
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.black};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;
`;

const restartTitle = css`
	font-size: ${themeSDS.fontSize['20']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { deleteVWRThunk } from 'features/vwr/thunk/deleteVWR.thunk.v2';
import { getVWRDataThunk } from 'features/vwr/thunk/getVWRData.v2.thunk';
import { getVWRListThunk } from 'features/vwr/thunk/getVWRList.v2.thunk';
import { putVWRDataThunk } from 'features/vwr/thunk/putVWRData.v2.thunk';
import type { VWRInfo } from 'features/vwr/types/getVWRList.v2.type';
import { getVWRNameValidateThunk } from 'features/vwr/thunk/getVWRNameValidate.v2.thunk';
import { postVWRThunk } from 'features/vwr/thunk/postVWR.v2.thunk';

export interface ChangeFileProps {
	src: string;
	name: string;
}

export interface VWRElement {
	logoImagePathSrc: string;
	logoImagePathName: string;
	title: string;
	followingWaitYn: boolean;
	guideText: string;
	imagePathSrc: string;
	imagePathName: string;
	waitCancelYn: boolean;
	waitTimeDisplayYn: boolean;
}

export interface VWRDataChange {
	isLogoImagePathChanged: boolean;
	isTitleChanged: boolean;
	isFollowingWaitYnChanged: boolean;
	isGuideTextChanged: boolean;
	isImagePathChanged: boolean;
	isWaitCancelYnChanged: boolean;
	isWaitTimeDisplayYnChanged: boolean;
}

export interface VWRData {
	form: VWRElement & VWRDataChange;
	preview: VWRElement;
	isLoaded: boolean;
	isLoading: boolean;
	// eslint-disable-next-line
	error: any;
	vwrList: VWRInfo[];
}

const initialState: VWRData = {
	form: {
		logoImagePathSrc: '',
		logoImagePathName: '',
		title: '',
		followingWaitYn: false,
		guideText: '',
		imagePathSrc: '',
		imagePathName: '',
		waitCancelYn: true,
		isFollowingWaitYnChanged: false,
		isGuideTextChanged: false,
		isImagePathChanged: false,
		isLogoImagePathChanged: false,
		isTitleChanged: false,
		isWaitCancelYnChanged: false,
		isWaitTimeDisplayYnChanged: false,
		waitTimeDisplayYn: true,
	},
	preview: {
		logoImagePathSrc: '',
		logoImagePathName: '',
		title: '',
		followingWaitYn: false,
		guideText: '',
		imagePathSrc: '',
		imagePathName: '',
		waitCancelYn: true,
		waitTimeDisplayYn: true,
	},
	isLoaded: false,
	isLoading: false,
	error: null,
	vwrList: [],
};

const vwrSliceV2 = createSlice({
	name: 'vwrV2',
	initialState,
	reducers: {
		changeLogoImagePath: (state, action: PayloadAction<ChangeFileProps>): VWRData => {
			return {
				...state,
				form: {
					...state.form,
					logoImagePathSrc: action.payload.src,
					isLogoImagePathChanged: true,
					logoImagePathName: action.payload.name,
				},
			};
		},
		changeImagePath: (state, action: PayloadAction<ChangeFileProps>): VWRData => {
			return {
				...state,
				form: {
					...state.form,
					imagePathSrc: action.payload.src,
					isImagePathChanged: true,
					imagePathName: action.payload.name,
				},
			};
		},
		changeTitle: (state, action: PayloadAction<string>): VWRData => {
			return { ...state, form: { ...state.form, title: action.payload, isTitleChanged: true } };
		},
		changeFollowingWaitYn: (state, action: PayloadAction<boolean>): VWRData => {
			return { ...state, form: { ...state.form, followingWaitYn: action.payload, isFollowingWaitYnChanged: true } };
		},
		changeGuideText: (state, action: PayloadAction<string>): VWRData => {
			return { ...state, form: { ...state.form, guideText: action.payload, isGuideTextChanged: true } };
		},
		changeWaitCancelYn: (state, action: PayloadAction<boolean>): VWRData => {
			return { ...state, form: { ...state.form, waitCancelYn: action.payload, isWaitCancelYnChanged: true } };
		},
		changeWaitTimeDisplayYn: (state, action: PayloadAction<boolean>): VWRData => {
			return { ...state, form: { ...state.form, waitTimeDisplayYn: action.payload, isWaitTimeDisplayYnChanged: true } };
		},
		setPreviewData: (state, action: PayloadAction<VWRElement>): VWRData => {
			return { ...state, preview: { ...action.payload }, form: { ...state.form, title: action.payload.title } };
		},
		setInitializeVWRData: state => {
			return { ...initialState, vwrList: [...state.vwrList] };
		},
		setInitialCreateTitle: (state, action: PayloadAction<string>) => {
			return {
				...state,
				form: { ...state.form, title: action.payload },
				preview: { ...state.preview, title: action.payload },
			};
		},
	},
	extraReducers(builder) {
		builder.addCase(getVWRDataThunk.fulfilled, (state, action): VWRData => {
			return {
				...state,
				isLoaded: true,
				isLoading: false,
				form: {
					...action.payload,
					isFollowingWaitYnChanged: false,
					isGuideTextChanged: false,
					isImagePathChanged: false,
					isLogoImagePathChanged: false,
					isTitleChanged: false,
					isWaitCancelYnChanged: false,
					isWaitTimeDisplayYnChanged: false,
				},
				preview: { ...action.payload },
				error: null,
			};
		});

		builder.addCase(getVWRDataThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoaded: true };
		});

		builder.addCase(putVWRDataThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putVWRDataThunk.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				form: {
					isFollowingWaitYnChanged: false,
					isGuideTextChanged: false,
					isImagePathChanged: false,
					isLogoImagePathChanged: false,
					isTitleChanged: false,
					isWaitCancelYnChanged: false,
					isWaitTimeDisplayYnChanged: false,
					...action.payload,
				},
				preview: { ...action.payload },
				error: null,
			};
		});

		builder.addCase(putVWRDataThunk.rejected, (state, action) => {
			return { ...state, error: { ...(action.payload as object) }, isLoaded: true, isLoading: false };
		});

		builder.addCase(deleteVWRThunk.fulfilled, (state, action) => {
			return {
				...state,
				error: null,
				isLoading: false,
				vwrList: state.vwrList.filter(el => el.id !== action.payload.id),
			};
		});

		builder.addCase(deleteVWRThunk.pending, state => {
			return { ...state, error: null, isLoading: true };
		});

		builder.addCase(deleteVWRThunk.rejected, (state, action) => {
			return { ...state, error: { ...(action.payload as object) }, isLoading: false };
		});

		builder.addCase(getVWRListThunk.fulfilled, (state, action) => {
			return { ...state, vwrList: action.payload.vwrList.slice() };
		});

		builder.addCase(getVWRNameValidateThunk.pending, state => {
			return { ...state, error: null, isLoading: true };
		});

		builder.addCase(getVWRNameValidateThunk.fulfilled, state => {
			return {
				...state,
				error: null,
				isLoading: false,
			};
		});

		builder.addCase(getVWRNameValidateThunk.rejected, (state, action) => {
			return { ...state, error: { ...(action.payload as object) }, isLoading: false };
		});

		builder.addCase(postVWRThunk.pending, state => {
			return { ...state, error: null, isLoading: true };
		});

		builder.addCase(postVWRThunk.rejected, (state, action) => {
			return { ...state, error: { ...(action.payload as object) }, isLoading: false };
		});
	},
});

export const {
	changeFollowingWaitYn,
	changeGuideText,
	changeLogoImagePath,
	changeTitle,
	changeWaitCancelYn,
	setPreviewData,
	changeImagePath,
	changeWaitTimeDisplayYn,
	setInitializeVWRData,
	setInitialCreateTitle,
} = vwrSliceV2.actions;

export default vwrSliceV2.reducer;

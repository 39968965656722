/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Modal, Portal, themeSDS } from 'surf-design-system';

import { userRoleType } from 'common/types/getOrganizationList.type';
import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsFreetrialEndModalVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';

/**
 * author: Finn
 * createdAt: 2022-07-11
 * updatedAt: 2022-08-17
 * description: 트라이얼 기간 종료 모달
 * todo:
 */
export default function FreetrialEndModal() {
	const { isFreetrialEndModalVisible } = useSelector((state: RootState) => state.modal);
	const { userRole, language } = useSelector((state: RootState) => state.account);

	const { t } = useTranslation();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	/**
	 * description: 플랜 업그레이드 페이지 이동 함수
	 */
	const onNavigateToPlan = () => {
		dispatch(toggleIsFreetrialEndModalVisible());
		navigate(`/${LANG_PATH[language]}/console/plan_usage/plan`);
	};

	/**
	 * description: 모달 닫기 제어 함수
	 */
	const onCloseModal = () => {
		dispatch(toggleIsFreetrialEndModalVisible());
	};

	let modalBody = <p>{t('modal.freeTrialEndMsg')}</p>;

	const modalTitle = t('modal.freeTrialEndTitle');

	if (userRole !== 'OWNER') {
		modalBody = <p>{t('modal.freeTrialEndMbrMsg')}</p>;
	}

	return (
		<Portal>
			<Modal
				visible={isFreetrialEndModalVisible}
				title={modalTitle}
				actionType="single"
				primaryBtnLabel={userRole !== 'OWNER' ? t('modal.ok') : t('modal.upgradePlan')}
				primaryBtnFunction={userRole !== 'OWNER' ? onCloseModal : onNavigateToPlan}
				onClickOverlay={onCloseModal}
				isCloseIconVisible={userRole === 'OWNER'}
				onClickCloseButton={onCloseModal}
			>
				<div css={textSt(userRole)}>{modalBody}</div>
			</Modal>
		</Portal>
	);
}

const textSt = (userRole: userRoleType) => css`
	margin-bottom: ${userRole === 'OWNER' && '2.5rem'};
	line-height: 1.375rem;
	font-size: ${themeSDS.fontSize[14]};

	white-space: pre-line;
`;

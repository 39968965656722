/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { paletteSDS, themeSDS } from 'surf-design-system';

export interface ButtonGroupElement {
	id: string;
	onClick: () => void;
	disabled?: boolean;
	label?: React.ReactNode;
}

export interface ButtonGroupProps {
	selectedId: string;
	buttonGroup: ButtonGroupElement[];
}

export default function ButtonGroup({ selectedId, buttonGroup }: ButtonGroupProps) {
	return (
		<div css={btnGroupContainer}>
			{buttonGroup.map((el, idx) => {
				let btnStyle = btnSt.middle;

				if (idx === buttonGroup.length - 1) {
					btnStyle = btnSt.end;
				}

				if (idx === 0) {
					btnStyle = btnSt.start;
				}

				return (
					<div
						onClick={el.onClick}
						key={el.id}
						css={[btnSt.basic, btnStyle, el.id === selectedId && btnSt.selected]}
						aria-hidden
					>
						{el.label}
					</div>
				);
			})}
		</div>
	);
}

const btnGroupContainer = css`
	display: flex;
	align-items: center;
	gap: 0px;
`;

const btnSt = {
	basic: css`
		display: flex;
		justify-content: center;
		align-items: center;

		height: 32px;
		padding-left: 18px;
		padding-right: 18px;
		padding-top: 9px;
		padding-bottom: 9px;

		background: ${paletteSDS.gray[50]};
		color: ${paletteSDS.blue[700]};
		font-size: ${themeSDS.fontSize[14]};
		font-weight: ${themeSDS.fontWeight.regular};
		line-height: 100%;

		cursor: pointer;
	`,

	start: css`
		border: 1px solid ${paletteSDS.gray[300]};
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	`,

	middle: css`
		border-top: 1px solid ${paletteSDS.gray[300]};
		border-bottom: 1px solid ${paletteSDS.gray[300]};
		border-right: 1px solid ${paletteSDS.gray[300]};
	`,

	end: css`
		border-top: 1px solid ${paletteSDS.gray[300]};
		border-bottom: 1px solid ${paletteSDS.gray[300]};
		border-right: 1px solid ${paletteSDS.gray[300]};
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	`,

	selected: css`
		background: ${paletteSDS.blue[700]};
		color: ${paletteSDS.gray[50]};
	`,
};

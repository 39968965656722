/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS, themeSDS, WarningIcon } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';

import { GoogleIcon } from 'common/assets/icons';

export default function SigninOauth() {
	const { userStatus } = useSelector((state: RootState) => state.account);
	const { t } = useTranslation();

	const googleIconClickHandler = () => {
		const callback = `${window.location.protocol}//${window.location.host}/callback`;

		const scope = 'aws.cognito.signin.user.admin email openid profile';
		const type = 'code';
		const state = encodeURIComponent('signin;;');
		window.location.href = `https://${process.env.REACT_APP_AWS_OAUTH_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${callback}&response_type=${type}&client_id=${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}&scope=${scope}&state=${state}`;
	};

	return (
		<React.Fragment>
			{userStatus === 'DISABLED' && (
				<div css={notiSt}>
					<WarningIcon fill={`${paletteSDS.red[500]}`} width="20" height="20" />
					{t('signIn.error.signUpMsg3')}
				</div>
			)}
			<Button icon={<GoogleIcon />} iconPosition="left" width="100%" mould="secondary" onClick={googleIconClickHandler}>
				{t('signIn.signWthGoogle')}
			</Button>
			<DividerWithTxt value={t('signIn.or')} dividerColor={paletteSDS.gray[300]} textColor={paletteSDS.gray[500]} />
		</React.Fragment>
	);
}

const notiSt = css`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.625rem;
	padding-left: 1.125rem;
	height: 3rem;

	background-color: ${paletteSDS.red[50]};
	color: ${paletteSDS.systemColor.black};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};

	border: 1px solid ${paletteSDS.red[200]};
	border-radius: 8px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// component
import {
	Textinput,
	Button,
	Radio,
	Portal,
	paletteSDS,
	AddIcon,
	SideModal,
	CloseIcon,
	Label,
	Helptext,
	themeSDS,
	SearchIcon,
	Dropdown,
	Loader,
} from 'surf-design-system';

// etc
import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsMoisSegmentCreateModalVisible } from 'common/context/modalSlice';
import Astrix from 'common/components/Astrix';
import Tooltip from 'common/components/Tooltip';
import DividerWithTxt from 'common/components/DividerWithTxt';
import ColorCircle from 'common/components/ColorCircle';
import Avatar from 'common/components/Avatar';
import { DEFAULT_IMG_URL } from 'common/data/constants';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';

import SegmentUriElement from 'features/nfBasic/components/SegmentUriElement';
import postMoisBasicControlSegmentThunk from 'features/nfControl/thunk/postMoisBasicControlSegment.v2.thunk';

import {
	initialSegmentForm,
	moisSegmentCreateDynamicFormReducer,
	UPDATE_NAME,
	VALIDATE_NAME,
	UPDATE_MAX_INFLOW,
	VALIDATE_MAX_INFLOW,
	UPDATE_URI,
	VALIDATE_URI,
	UPDATE_URI_HELP_TEXT,
	UPDATE_NAME_HELP_TEXT,
	ADD_URI_TO_LIST,
	DELETE_URI_FROM_LIST,
	INITIALIZE_FORM,
	UPDATE_MAX_LIMITED_INFLOW,
	UPDATE_MIN_LIMITED_INFLOW,
	UPDATE_NORMAL_RANGE_END_VALUE,
	UPDATE_NORMAL_RANGE_START_VALUE,
	UPDATE_SEGMENT_TYPE,
	VALIDATE_MAX_LIMITED_INFLOW,
	VALIDATE_MIN_LIMITED_INFLOW,
	VALIDATE_NORMAL_RANGE_END_VALUE,
	VALIDATE_NORMAL_RANGE_START_VALUE,
	UPDATE_MEMBER_SEARCH_VALUE,
	UPDATE_SEARCHED_MEMBER_LIST,
	DELETE_MEMBER,
	ADD_MEMBER,
	UPDATE_VWR_ID,
	UPDATE_MAIN_DOMAIN,
} from 'features/nfBasic/context/moisSegmentCreateDynamicForm.reducer';
import SegmentProcessTimeRangeGauge from 'features/nfBasic/components/SegmentProcessTimeRangeGauge';
import { resetBasicControlError } from 'features/nfControl/context/nfControlSlice';

import { MAX_SEG_TITLE_LENGTH } from 'features/nfBasic/data/constants';
import { getVWRListApi } from 'features/vwr/api/getVWRList.v2.api';
import getOperatorListApi from 'features/nfControl/api/getOperatorList.v2.api';

interface MoisSegmentCreateDynamicModalProps {
	isClassic?: boolean;
}

export default function MoisSegmentCreateDynamicModal({ isClassic = false }: MoisSegmentCreateDynamicModalProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isLoading, error } = useSelector((state: RootState) => state.nfControl.segment.basic);

	const { isMoisSegmentCreateModalVisible } = useSelector((state: RootState) => state.modal);

	const { response: getVWRListResponse, sendRequest: getVWRList } = useApiRequestWithArg(getVWRListApi);

	const { response: getOperatorListResponse, sendRequest: getOperatorList } = useApiRequestWithArg(getOperatorListApi);

	const vwrList =
		getVWRListResponse === null
			? []
			: getVWRListResponse.data.map(el => ({ value: el.id, name: el.defaultYn ? t('vwr.default') : el.name }));

	const { tenantId, organizationId, userRole } = useSelector((state: RootState) => state.account);

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const mainDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain;

	const [segmentFormData, setSegmentFormData] = useReducer(moisSegmentCreateDynamicFormReducer, initialSegmentForm);
	const [isMemberSearchInputFocus, setIsMemberSearchInputFocus] = useState(false);

	const {
		segmentName,
		isSegmentNameValid,
		isSegmentNameChanged,
		segmentNameValidation,
		segmentNameHelpText,
		maxInflow,
		maxInflowHelpText,
		maxInflowValidation,
		isMaxInflowChanged,
		isMaxInflowValid,
		uri,
		isUriValid,
		isUriChanged,
		uriHelpText,
		uriValidation,
		uriList,
		segmentType,
		isMaxLimitedInflowValid,
		isMaxLimitedInflowChanged,
		maxLimitedInflowValidation,
		maxLimitedInflowHelpText,
		maxLimitedInflow,
		isMinLimitedInflowValid,
		isMinLimitedInflowChanged,
		minLimitedInflowValidation,
		minLimitedInflowHelpText,
		minLimitedInflow,
		isNormalRangeStartValueValid,
		isNormalRangeStartValueChanged,
		normalRangeStartValueValidation,
		normalRangeStartValueHelpText,
		normalRangeStartValue,
		isNormalRangeEndValueValid,
		isNormalRangeEndValueChanged,
		normalRangeEndValueValidation,
		normalRangeEndValueHelpText,
		normalRangeEndValue,
		searchedMemberList,
		selectedMemberList,
		memberSearchValue,
		vwrId,
	} = segmentFormData;

	// description: events
	const closeIconClickHandler = () => {
		dispatch(toggleIsMoisSegmentCreateModalVisible());
		setSegmentFormData({ type: INITIALIZE_FORM, initial: { ...initialSegmentForm } });
		dispatch(resetBasicControlError());
	};

	const uriChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSegmentFormData({ type: UPDATE_URI, payload: value });
	};

	const uriBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_URI });
	};

	const segmentNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NAME, payload: e.target.value });
	};

	const segmentNameBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NAME });
	};

	const maxInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MAX_INFLOW, payload: e.target.value });
	};

	const maxInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MAX_INFLOW });
	};

	const uriElementCloseIconClickHandler = (path: string) => {
		setSegmentFormData({ type: DELETE_URI_FROM_LIST, payload: path });
	};

	const addUriButtonClickHandler = () => {
		setSegmentFormData({ type: ADD_URI_TO_LIST });
	};

	const segmentTypeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_SEGMENT_TYPE, payload: e.target.value });
	};

	const minLimitedInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MIN_LIMITED_INFLOW, payload: e.target.value });
	};

	const minLimitedInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MIN_LIMITED_INFLOW });
	};

	const maxLimitedInflowChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MAX_LIMITED_INFLOW, payload: e.target.value });
	};

	const maxLimitedInflowBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_MAX_LIMITED_INFLOW });
	};

	const rangeStartValueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NORMAL_RANGE_START_VALUE, payload: e.target.value });
	};

	const rangeStartValueBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NORMAL_RANGE_START_VALUE });
	};

	const rangeEndValueChaneHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_NORMAL_RANGE_END_VALUE, payload: e.target.value });
	};

	const rangeEndValueBlurHandler = () => {
		setSegmentFormData({ type: VALIDATE_NORMAL_RANGE_END_VALUE });
	};

	const memberSearchValueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSegmentFormData({ type: UPDATE_MEMBER_SEARCH_VALUE, payload: e.target.value });
	};

	const memberSearchInputFocusHandler = () => {
		setIsMemberSearchInputFocus(true);
	};

	const memberSearchInputBlurHandler = () => {
		setIsMemberSearchInputFocus(false);
	};

	const vwrChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setSegmentFormData({ type: UPDATE_VWR_ID, payload: e.target.value });
	};

	const isButtonDisabled =
		segmentType === 'STATIC'
			? !isMaxInflowValid || !isSegmentNameValid || uriList.length === 0 || isLoading
			: !isSegmentNameValid ||
			  uriList.length === 0 ||
			  isLoading ||
			  !isNormalRangeEndValueValid ||
			  !isNormalRangeStartValueValid ||
			  !isMaxLimitedInflowValid ||
			  !isMinLimitedInflowValid;

	// description: API
	const createSegmentFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();

		dispatch(
			postMoisBasicControlSegmentThunk({
				segmentName,
				maxInflow,
				accessYn: true,
				uriList: uriList.slice(),
				onSuccess: closeIconClickHandler,
				segmentType,
				vwrPageId: vwrId,
				dynamicMaxInflow: Number(maxLimitedInflow),
				dynamicMinInflow: Number(minLimitedInflow),
				dynamicSectionMaxSec: Number(normalRangeEndValue),
				dynamicSectionMinSec: Number(normalRangeStartValue),
				members: selectedMemberList.filter(el => el.state === 'ACTIVE'),
			})
		);
	};

	useEffect(() => {
		if (error && error?.errorCode === 'S1007') {
			setSegmentFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'modal.segTitleErrorMsg1' });
		}

		if (error && error?.errorCode === 'S1008') {
			setSegmentFormData({ type: UPDATE_URI_HELP_TEXT, payload: 'modal.addUrlErrorMsg3' });
		}

		if (error && error?.errorCode === 'S1016') {
			setSegmentFormData({ type: UPDATE_NAME_HELP_TEXT, payload: 'modal.segTitleErrorMsg1' });
			setSegmentFormData({ type: UPDATE_URI_HELP_TEXT, payload: 'modal.addUrlErrorMsg3' });
		}

		if (error && error?.errorCode === 'S1020') {
			setSegmentFormData({ type: UPDATE_URI_HELP_TEXT, payload: 'mois.maxUri' });
		}
	}, [error]);

	useEffect(() => {
		const searchTimeout = setTimeout(() => {
			getOperatorList({ tenantId: tenantId as string, organizationId, searchValue: memberSearchValue });
		}, 200);

		return () => {
			clearTimeout(searchTimeout);
		};
	}, [memberSearchValue, tenantId, organizationId, getOperatorList, isMemberSearchInputFocus]);

	useEffect(() => {
		getVWRList({ tenantId: tenantId as string, organizationId, projectId: selectedProjectId });
	}, [getVWRList, tenantId, organizationId, selectedProjectId, isMoisSegmentCreateModalVisible]);

	useEffect(() => {
		if (getVWRListResponse) {
			setSegmentFormData({
				type: UPDATE_VWR_ID,
				payload: getVWRListResponse.data.find(el => el.defaultYn)?.id.toString(),
			});
		}
	}, [getVWRListResponse]);

	useEffect(() => {
		if (getOperatorListResponse) {
			setSegmentFormData({
				type: UPDATE_SEARCHED_MEMBER_LIST,
				memberList: getOperatorListResponse.data.slice(),
			});
		}
	}, [getOperatorListResponse]);

	useEffect(() => {
		setSegmentFormData({ type: UPDATE_MAIN_DOMAIN, payload: mainDomain });
	}, [mainDomain]);

	return (
		<Portal id="side-modal">
			<SideModal
				visible={isMoisSegmentCreateModalVisible}
				onClickOverlay={isLoading ? undefined : closeIconClickHandler}
				containerCss={css`
					display: flex;
					flex-direction: column;
				`}
				speed={0.2}
				onSubmit={createSegmentFormSubmitHandler}
			>
				<div css={titleContainer}>
					<div css={titleSt}>{t('modal.segAddModal')}</div>
					<CloseIcon onClick={isLoading ? undefined : closeIconClickHandler} />
				</div>
				<div css={inputContainer}>
					<Textinput
						name={t('commonWords.segTitle')}
						size="small"
						width="100%"
						label={
							<span>
								{t('commonWords.segTitle')}
								<Astrix />
							</span>
						}
						placeholder={t('commonWords.segTitle')}
						value={segmentName}
						maxLength={MAX_SEG_TITLE_LENGTH}
						validation={isSegmentNameChanged ? segmentNameValidation : 'none'}
						helpText={isSegmentNameChanged && !isSegmentNameValid && t(segmentNameHelpText)}
						onChange={segmentNameChangeHandler}
						onBlur={segmentNameBlurHandler}
						tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
					/>
					<Radio
						groupName={t('projectMgmt.access')}
						groupLabel={
							<div css={accessModeCss}>
								{t('projectMgmt.access')}
								<Tooltip disabled />
							</div>
						}
						radioList={[
							{
								id: `${isClassic ? 'classic' : 'default'}-access`,
								name: t('modal.segmentUnblock'),
								value: 'true',
								tabIndex: isMoisSegmentCreateModalVisible ? 0 : -1,
							},
							{
								id: `${isClassic ? 'classic' : 'default'}-block`,
								name: t('modal.segmentBlock'),
								value: 'false',
								tabIndex: isMoisSegmentCreateModalVisible ? 0 : -1,
							},
						]}
						direction="horizontal"
						defaultValue="true"
						disabled
						labelStackDirection="horizontal"
					/>
					<div css={uriInputContainer(isUriChanged && !isUriValid)}>
						<Textinput
							name="URL"
							size="small"
							width="264px"
							label={
								<span>
									URL
									<Astrix />
								</span>
							}
							maxLength={253}
							value={uri}
							validation={isUriChanged ? uriValidation : 'none'}
							helpText={isUriChanged && !isUriValid && t(uriHelpText)}
							onChange={uriChangeHandler}
							onBlur={uriBlurHandler}
							disabled={uriList.length === 10}
							tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
						/>
						<Button
							size="small"
							disabled={!isUriValid || uriList.length === 10}
							mould="secondary"
							onClick={addUriButtonClickHandler}
							height="32px"
							width="90px"
							icon={<AddIcon size="xSmall" fill={paletteSDS.blue['700']} />}
							iconPosition="left"
							tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
						>
							{t('modal.addUri')}
						</Button>
					</div>
					{uriList.length > 0 ? (
						<div css={uriContainer}>
							{uriList.map(uriElement => (
								<SegmentUriElement
									label={uriElement}
									onClickCloseIcon={() => uriElementCloseIconClickHandler(uriElement)}
									key={uriElement}
								/>
							))}
						</div>
					) : null}
					<div
						css={[
							gap16px,
							css`
								position: relative;
							`,
						]}
					>
						<Textinput
							name="member"
							label={t('modal.segmentMember')}
							placeholder={t('modal.memberInputPlaceholder')}
							value={memberSearchValue}
							icon={
								isMemberSearchInputFocus ? (
									<CloseIcon
										fill={paletteSDS.gray['700']}
										size="xSmall"
										onMouseDown={e => e.preventDefault()}
										onClick={() => {
											setSegmentFormData({ type: UPDATE_MEMBER_SEARCH_VALUE, payload: '' });
										}}
									/>
								) : (
									<SearchIcon fill={paletteSDS.gray['700']} size="xSmall" cursor="default" />
								)
							}
							showIcon
							size="small"
							onChange={memberSearchValueChangeHandler}
							onFocus={memberSearchInputFocusHandler}
							onBlur={memberSearchInputBlurHandler}
							iconPosition={isMemberSearchInputFocus ? 'right' : 'left'}
							hideIconWhenFocus={false}
							disabled={userRole !== 'OWNER' && userRole !== 'ADMINISTRATOR'}
							tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
						/>
						{selectedMemberList.length > 0 ? (
							<div css={selectedMemberContainer}>
								{selectedMemberList.map(el => (
									<div key={el.userKey} css={selectedMemberItemBox}>
										<div css={selectedMemberItem}>
											<Avatar
												imgUrl={el.imageUrl === DEFAULT_IMG_URL ? undefined : el.imageUrl}
												placeholder={el.email.charAt(0).toUpperCase()}
												width="16px"
												height="16px"
											/>
											<div>{el.name}</div>
										</div>
										{userRole === 'ADMINISTRATOR' || userRole === 'OWNER' ? (
											<CloseIcon
												size="xSmall"
												onClick={() => setSegmentFormData({ type: DELETE_MEMBER, member: { ...el } })}
												fill={paletteSDS.gray['700']}
											/>
										) : null}
									</div>
								))}
							</div>
						) : null}
						{isMemberSearchInputFocus ? (
							<div css={searchedMemberContainer} onMouseDown={e => e.preventDefault()} aria-hidden>
								{searchedMemberList.filter(el => el.state === 'ACTIVE').length > 0 ? (
									searchedMemberList
										.filter(el => el.state === 'ACTIVE')
										.map((item, index) => (
											<div
												key={item.userKey}
												css={searchedMemberItemBox(index, searchedMemberList.length)}
												aria-hidden
												onClick={() => {
													setSegmentFormData({ type: ADD_MEMBER, member: { ...item } });
												}}
											>
												<Avatar
													width="16px"
													height="16px"
													imgUrl={item.imageUrl === DEFAULT_IMG_URL ? undefined : item.imageUrl}
													placeholder={item.email.charAt(0).toUpperCase()}
												/>
												<div>{item.name}</div>
											</div>
										))
								) : (
									<div css={noResultSt}>{t('modal.noResult')}</div>
								)}
							</div>
						) : null}
					</div>
					<Dropdown
						menu={vwrList}
						bindedValue={vwrId}
						onChange={vwrChangeHandler}
						label={t('modal.vwrDropdownLabel')}
						placeholder={t('modal.vwrDropdownPlaceholder')}
						size="small"
						tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
					/>
					<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
					<Radio
						groupName={t('modal.segmentType')}
						groupLabel={t('modal.segmentType')}
						radioList={[
							{
								id: `${isClassic ? 'classic' : 'default'}-static-mois`,
								name: t('modal.staticSegment'),
								value: 'STATIC',
								tabIndex: isMoisSegmentCreateModalVisible ? 0 : -1,
							},
							{
								id: `${isClassic ? 'classic' : 'default'}-dynamic-mois`,
								name: t('modal.dynamicSegment'),
								value: 'DYNAMIC',
								tabIndex: isMoisSegmentCreateModalVisible ? 0 : -1,
							},
						]}
						direction="horizontal"
						selectedValue={segmentType}
						onChange={segmentTypeChangeHandler}
						labelStackDirection="horizontal"
						disabled={!isMoisSegmentCreateModalVisible}
					/>
					{segmentType === 'DYNAMIC' ? (
						<div css={gap8px}>
							<div css={gap8px}>
								<Label>
									{t('projectMonitoring.limitedInflow')}
									<Astrix />
								</Label>
								<div css={gap4px}>
									<div css={limitedInflowInputBox}>
										<Label size="small">{t('modal.minLimitedInflow')}</Label>
										<Textinput
											width="100px"
											size="small"
											value={minLimitedInflow}
											valueType="number"
											onChange={minLimitedInflowChangeHandler}
											onBlur={minLimitedInflowBlurHandler}
											validation={isMinLimitedInflowChanged ? minLimitedInflowValidation : 'none'}
											showIcon
											max={99999999}
											min={0}
											step={1}
											tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
										/>
									</div>
									{isMinLimitedInflowChanged && !isMinLimitedInflowValid && (
										<Helptext validation="failed">{t(minLimitedInflowHelpText)}</Helptext>
									)}
									<div css={limitedInflowInputBox}>
										<Label size="small">{t('modal.maxLimitedInflow')}</Label>
										<Textinput
											width="100px"
											size="small"
											value={maxLimitedInflow}
											valueType="number"
											onChange={maxLimitedInflowChangeHandler}
											onBlur={maxLimitedInflowBlurHandler}
											validation={isMaxLimitedInflowChanged ? maxLimitedInflowValidation : 'none'}
											showIcon
											max={99999999}
											min={0}
											step={1}
											tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
										/>
									</div>
									{isMaxLimitedInflowChanged && !isMaxLimitedInflowValid && (
										<Helptext validation="failed">{t(maxLimitedInflowHelpText)}</Helptext>
									)}
								</div>
							</div>
							<div css={gap8px}>
								<Label>
									{t('modal.rangeProcessTimeTitle')}
									<Astrix />
								</Label>
								<div css={gap20px}>
									<SegmentProcessTimeRangeGauge
										normalRangeStartValue={normalRangeStartValue}
										normalRangeEndValue={normalRangeEndValue}
									/>
									<div css={gap6px}>
										<div css={rangeInputContainer}>
											<div css={rangeLabelSt(paletteSDS.categoryColor.mentis)}>{t('modal.goodRange')}</div>
											<div css={labelSt}>{'<'}</div>
											<Textinput
												width="68px"
												size="medium"
												valueType="number"
												step={0.1}
												value={normalRangeStartValue}
												onChange={rangeStartValueChangeHandler}
												onBlur={rangeStartValueBlurHandler}
												validation={isNormalRangeStartValueChanged ? normalRangeStartValueValidation : 'none'}
												max={100}
												min={0}
												tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
											/>
											<div css={labelSt}>≤</div>
											<div css={rangeLabelSt(paletteSDS.categoryColor.yellow)}>{t('modal.normalRange')}</div>
											<div css={labelSt}>{'<'}</div>
											<Textinput
												width="68px"
												size="medium"
												step={0.1}
												valueType="number"
												value={normalRangeEndValue}
												onChange={rangeEndValueChaneHandler}
												onBlur={rangeEndValueBlurHandler}
												validation={isNormalRangeEndValueChanged ? normalRangeEndValueValidation : 'none'}
												max={100}
												min={0}
												tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
											/>
											<div css={labelSt}>≤</div>
											<div css={rangeLabelSt(paletteSDS.categoryColor.redOrange)}>{t('modal.badRange')}</div>
										</div>
										{isNormalRangeStartValueChanged && !isNormalRangeStartValueValid && (
											<Helptext validation="failed">{t(normalRangeStartValueHelpText)}</Helptext>
										)}
										{isNormalRangeEndValueChanged && !isNormalRangeEndValueValid && (
											<Helptext validation="failed">{t(normalRangeEndValueHelpText)}</Helptext>
										)}
									</div>
									<Helptext validation="none">{t('modal.rangeValueDescription')}</Helptext>
									<div css={gap4px}>
										<div css={rangeDescriptionContainer}>
											<ColorCircle color={paletteSDS.categoryColor.mentis} size={7.5} />
											<div css={rangeDescriptionSt}>{t('modal.goodRangeDescription')}</div>
										</div>
										<div css={rangeDescriptionContainer}>
											<ColorCircle color={paletteSDS.categoryColor.yellow} size={7.5} />
											<div css={rangeDescriptionSt}>{t('modal.normalRangeDescription')}</div>
										</div>
										<div css={rangeDescriptionContainer}>
											<ColorCircle color={paletteSDS.categoryColor.redOrange} size={7.5} />
											<div css={rangeDescriptionSt}>{t('modal.badRangeDescription')}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<Textinput
							name={t('projectMonitoring.limitedInflow')}
							size="small"
							width="100%"
							label={
								<span>
									{t('projectMonitoring.limitedInflow')}
									<Astrix />
								</span>
							}
							value={maxInflow}
							max={99999999}
							min={0}
							step={1}
							validation={isMaxInflowChanged ? maxInflowValidation : 'none'}
							helpText={isMaxInflowChanged && !isMaxInflowValid && t(maxInflowHelpText)}
							valueType="number"
							onChange={maxInflowChangeHandler}
							onBlur={maxInflowBlurHandler}
							tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
						/>
					)}
				</div>
				<div css={buttonContainer}>
					<Button
						width="100%"
						disabled={isButtonDisabled}
						buttonDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
						buttonColor={paletteSDS.blue['700']}
						buttonHoverColor={paletteSDS.blue['800']}
						labelColor={paletteSDS.gray['50']}
						mould="custom"
						tabIndex={isMoisSegmentCreateModalVisible ? 0 : -1}
					>
						{isLoading ? <Loader height="16px" /> : t('modal.segAddSave')}
					</Button>
				</div>
			</SideModal>
		</Portal>
	);
}

const accessModeCss = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: ${paletteSDS.gray[300]};
`;

const uriContainer = css`
	min-height: 24px;
	max-height: 72px;
	overflow-y: auto;

	flex: none;
`;

const uriInputContainer = (isUriHelpTextVisible: boolean) => css`
	display: flex;
	gap: 8px;
	align-items: ${isUriHelpTextVisible ? 'center' : 'flex-end'};
`;

const titleContainer = css`
	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 64px;

	border-bottom: 1px solid ${paletteSDS.gray['300']};

	padding: 24px 16px 24px 16px;
`;

const titleSt = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['16']};
	color: ${paletteSDS.gray['900']};
`;

const inputContainer = css`
	flex: auto;

	display: flex;
	flex-direction: column;
	gap: 24px;

	overflow-y: auto;

	padding: 24px;

	overflow: auto;
`;

const buttonContainer = css`
	flex: none;
	display: flex;
	align-items: center;

	padding: 16px;

	border-top: 1px solid ${paletteSDS.gray['300']};
`;

const gap8px = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap4px = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const gap20px = css`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const gap6px = css`
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

const limitedInflowInputBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const rangeInputContainer = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const rangeLabelSt = (color: string) => css`
	display: flex;
	align-items: center;

	height: 25px;

	padding: 4px 8px;

	border-radius: 4px;

	background: ${color};

	color: ${paletteSDS.white};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['12']};
`;

const rangeDescriptionContainer = css`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const rangeDescriptionSt = css`
	font-size: ${themeSDS.fontSize['12']};
	color: ${paletteSDS.gray['700']};

	line-height: 140%;

	letter-spacing: 0.32px;
`;

const labelSt = css`
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['600']};
`;

const selectedMemberItem = css`
	display: flex;
	gap: 10px;
	align-items: center;

	color: ${paletteSDS.gray['900']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
`;

const selectedMemberContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	width: 100%;
	max-height: 72px;

	overflow: auto;
`;

const selectedMemberItemBox = css`
	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 24px;
`;

const searchedMemberContainer = css`
	position: absolute;

	top: 66px;

	display: flex;
	flex-direction: column;
	gap: 0;

	width: 100%;

	max-height: 202px;

	overflow: auto;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};

	border-radius: 8px;

	z-index: 2000;
`;

const searchedMemberItemBox = (index: number, length: number) => css`
	flex: none;

	display: flex;
	align-items: center;
	gap: 6px;

	width: 100%;
	height: 40px;

	padding: 11px 10px 11px 12px;

	border-bottom: ${index !== length - 1 && `1px solid ${paletteSDS.gray['400']}`};

	color: ${paletteSDS.gray['900']};

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};

	:hover {
		background: ${paletteSDS.gray['100']};
	}

	cursor: pointer;
`;

const noResultSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 40px;

	color: ${paletteSDS.gray['700']};

	font-size: ${themeSDS.fontSize['12']};
	font-weight: ${themeSDS.fontWeight.medium};

	background: ${paletteSDS.gray['50']};

	border-radius: 8px;
`;

const gap16px = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

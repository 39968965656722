import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PutSegmentOrderApiResponse } from '../types/putSegmentOrder.type';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 세그먼트 순서 변경 api
 * todo: tenant 별 db가 생기면 주석된 주소로 변경 필요
 */

interface PutSegmentOrderApiBody {
	id: number;
	segmentKey: string;
	segmentOrder: number;
}

export interface PutSegmentOrderApiProps {
	tenantUrl: string;
	tenantId: string;
	organizationId: number;
	prjId: number;
	body: PutSegmentOrderApiBody[];
}

export default async function putSegmentOrderApi({
	tenantUrl,
	tenantId,
	organizationId,
	prjId,
	body,
}: PutSegmentOrderApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.put<PutSegmentOrderApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${prjId}/segment/order`,
			{ data: body },
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

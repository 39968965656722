/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, Button } from 'surf-design-system';

import pageNotfoundImage from 'common/assets/images/page_not_found.png';
import { getI18nPath } from 'common/utils/languageFunc';
import AuthHeader from 'common/components/AuthHeader';
import Footer from 'common/components/Footer';
import { COGNITO_TOKEN } from 'common/data/constants';

export default function PageNotFound() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const backToSurffyButtonClickHandler = () => {
		if (window.sessionStorage.getItem(COGNITO_TOKEN) === null && process.env.REACT_APP_PRODUCT_MODE !== 'ONPREM') {
			window.location.replace(`/${getI18nPath()}/signin`);
			return;
		}

		if (window.sessionStorage.getItem(COGNITO_TOKEN) === null && process.env.REACT_APP_PRODUCT_MODE === 'ONPREM') {
			window.location.replace(`/${getI18nPath()}/on_prem/signin`);
			return;
		}

		if (process.env.REACT_APP_PRODUCT_MODE === 'ONPREM') {
			navigate(`/${getI18nPath()}/on_prem/account_setting`);
			return;
		}

		navigate(`/${getI18nPath()}/account_setting`);
	};

	return (
		<div css={container}>
			<AuthHeader />
			<div css={imageContentBox}>
				<img src={pageNotfoundImage} alt="page not found" css={imgSt} />
				<div css={commentButtonBox}>
					<div css={commentBox}>
						<div css={boldSt}>{t('pageNotFound.title')}</div>
						<div css={mediumSt}>{t('pageNotFound.content')}</div>
					</div>
					<Button onClick={backToSurffyButtonClickHandler}>{t('pageNotFound.backToSurffy')}</Button>
				</div>
			</div>
			{process.env.REACT_APP_PRODUCT_MODE !== 'ONPREM' ? <Footer /> : null}
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 102px;
	align-items: center;

	height: 100%;
	background-color: ${paletteSDS.gray['75']};
`;

const imgSt = css`
	width: fit-content;
	height: fit-content;
`;

const boldSt = css`
	font-size: ${themeSDS.fontSize[24]};
	color: ${paletteSDS.gray[900]};
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 120%;
`;

const mediumSt = css`
	font-size: ${themeSDS.fontSize[16]};
	color: ${paletteSDS.gray[900]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;

	white-space: pre;
`;

const commentBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;

	text-align: center;
`;

const commentButtonBox = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	align-items: center;
`;

const imageContentBox = css`
	flex: auto;

	display: flex;
	flex-direction: column;
	gap: 56px;
	align-items: center;
`;

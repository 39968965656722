/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// etc
import { Loader, Modal, paletteSDS, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { setIsSegmentDeleteConfirmModalVisible } from 'common/context/modalSlice';

/**
 * author: noah, ian
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-30
 * @params: 삭제되는 segment의 data
 */

export default function SegmentDeleteConfirmModal() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isSegmentDeleteConfirmModalVisible } = useSelector((state: RootState) => state.modal);

	const { isLoading } = useSelector((state: RootState) => state.monitoring.segment);

	const primaryButtonClickHandler = async () => {
		dispatch(setIsSegmentDeleteConfirmModalVisible(false));
	};

	useEffect(() => {
		let timeout: NodeJS.Timeout;

		if (isSegmentDeleteConfirmModalVisible) {
			timeout = setTimeout(() => {
				dispatch(setIsSegmentDeleteConfirmModalVisible(false));
			}, 3000);
		}

		return () => clearTimeout(timeout);
	}, [dispatch, isSegmentDeleteConfirmModalVisible]);

	return (
		<Portal>
			<Modal
				visible={isSegmentDeleteConfirmModalVisible}
				isBackgroundActive
				center
				isCloseIconVisible={false}
				actionType="single"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t('modal.segConfirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				primaryBtnDisable={isLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<div css={childrenCss}>{t('modal.segDelConfirmMsg')}</div>
			</Modal>
		</Portal>
	);
}

const childrenCss = css`
	text-align: center;
	line-height: 160%;
`;

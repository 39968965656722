import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import putProjectMacroInfoApi from 'features/nfSetting/api/putProjectMacroInfo.v1.api';

export interface PutProjectMacroInfoThunkResult {
	blockYn: boolean;
	updatedAt: string;
	projectId: number;
}

export interface PutProjectMacroInfoThunkProps {
	blockYn: boolean;
	blockType: string;
	checkCycle: number;
	limitNumPerSec: number;
	limitNumPerCycle: number;
	blockTime: number;
	onClose: () => void;
	selectedProjectId: number;
}

export const putProjectMacroInfoThunk = createAsyncThunk<
	PutProjectMacroInfoThunkResult,
	PutProjectMacroInfoThunkProps,
	{ state: RootState }
>('project/macro/put/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();

	const {
		blockYn: enteredBlockYn,
		blockType,
		checkCycle,
		limitNumPerCycle,
		limitNumPerSec,
		blockTime,
		onClose,
		selectedProjectId,
	} = args;

	try {
		if (enteredBlockYn) {
			const { data } = await putProjectMacroInfoApi({
				tenantId: tenantId || '',
				organizationId,
				projectId: selectedProjectId,
				blockType,
				blockYn: enteredBlockYn,
				checkCycle,
				limitNumPerCycle,
				limitNumPerSec,
				blockTime,
			});

			onClose();

			return {
				blockYn: data.blockYn,
				projectId: data.id,
				updatedAt: data.updatedAt,
			};
		}

		const { data } = await putProjectMacroInfoApi({
			tenantId: tenantId || '',
			organizationId,
			blockYn: enteredBlockYn,
			projectId: selectedProjectId,
			blockType,
		});

		onClose();

		return {
			blockYn: data.blockYn,
			projectId: data.id,
			updatedAt: data.updatedAt,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

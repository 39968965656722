/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { PaginationBasic, PaginationBasicClickHandler, paletteSDS, themeSDS } from 'surf-design-system';

import Table, { TableColumn, TableRow } from 'common/components/Table';
import { RootState, useAppDispatch } from 'common/redux/store';

import { ccuStatus } from 'features/license/types/getLicenseProjectUsage.type';
import { setSelectedPage } from 'features/license/context/licenseSlice';
import { addNumberComma } from 'features/plan/utils/formatFunc';

export default function LicenseUsageTable() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { selectedPage, content, totalPages, isLoaded } = useSelector((state: RootState) => state.license.usage);

	const getCCUStatus = (status: ccuStatus) => {
		switch (status) {
			case 'DANGER':
				return t('onPrem.license.warning');
			case 'EXCEEDED':
				return t('onPrem.license.excess');
			case 'NORMAL':
				return t('onPrem.license.fine');
			default:
				return '';
		}
	};

	const licenseUsageColumns: TableColumn[] = useMemo(
		() => [
			{
				id: 1,
				label: t('onPrem.license.date'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '303px',
				elementCss: columnSt,
			},
			{
				id: 2,
				label: t('onPrem.license.peakCCU'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '303px',
				elementCss: columnSt,
			},
			{
				id: 3,
				label: t('onPrem.license.status'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '304px',
				elementCss: css`
					justify-content: center;
				`,
			},
		],
		[t]
	);

	const licenseUsageRows: TableRow[] = content.map((el, index) => ({
		id: index,
		items: [
			{
				elementId: 1,
				element: (
					<span>
						{moment(el.usageAt * 1000)
							.utcOffset('+UTC09:00')
							.format('YYYY-MM-DD')}
					</span>
				),
				padding: '0px 16px',
				width: '303px',
				height: '48px',
				elementCss: rowElementSt,
			},
			{
				elementId: 2,
				element: <span>{addNumberComma(el.dailyPeakCcu.toString())}</span>,
				padding: '12px 16px',
				width: '303px',
				height: '48px',
				elementCss: rowElementSt,
			},
			{
				elementId: 3,
				element: <span>{getCCUStatus(el.dailyPeakCcuStatus.toUpperCase() as ccuStatus)}</span>,
				padding: '0px 16px',
				width: '304px',
				height: '48px',
				elementCss: [rowElementSt, statusCss[el.dailyPeakCcuStatus]],
			},
		],
		rowCss: rowSt,
	}));

	const prevClickHandler = () => {
		dispatch(setSelectedPage(selectedPage - 1));
	};

	const nextClickHandler = () => {
		dispatch(setSelectedPage(selectedPage + 1));
	};

	const paginationButtonClickHandler: PaginationBasicClickHandler = (_, page) => {
		dispatch(setSelectedPage(page));
	};

	if (isLoaded && licenseUsageRows.length < 1) {
		return (
			<div css={container}>
				<div css={noDataContainer}>
					<Table columns={licenseUsageColumns} rows={licenseUsageRows} />
					<div css={noDataSt}>{t('onPrem.license.noData')}</div>
				</div>
				<PaginationBasic
					totalPages={totalPages}
					selectedPage={selectedPage}
					onClickNext={prevClickHandler}
					onClickPrev={nextClickHandler}
					onClickSelectPage={paginationButtonClickHandler}
				/>
			</div>
		);
	}

	return (
		<div css={container}>
			<Table columns={licenseUsageColumns} rows={licenseUsageRows} />
			<PaginationBasic
				totalPages={totalPages}
				selectedPage={selectedPage}
				onClickNext={prevClickHandler}
				onClickPrev={nextClickHandler}
				onClickSelectPage={paginationButtonClickHandler}
			/>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const columnSt = css`
	justify-content: center;
	flex: none;
	word-break: keep-all;
`;

const rowSt = css`
	background: ${paletteSDS.gray['50']};
`;

const rowElementSt = css`
	flex: none;
	background: none;
	> div {
		justify-content: center;
	}
`;

const statusCss = {
	DANGER: css`
		font-weight: ${themeSDS.fontWeight.semiBold};
		color: ${paletteSDS.categoryColor.orange};
	`,

	EXCEEDED: css`
		font-weight: ${themeSDS.fontWeight.semiBold};
		color: ${paletteSDS.red[500]};
	`,

	NORMAL: css`
		color: ${paletteSDS.gray[900]};
	`,
};

const noDataContainer = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const noDataSt = css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 240px;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize['14']};
	line-height: 140%;
	letter-spacing: 0.16px;
	color: ${paletteSDS.gray['900']};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import { Loader, Modal, paletteSDS, Portal, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { closeVWRModal } from 'common/context/modalSlice';

import { deleteVWRThunk } from 'features/vwr/thunk/deleteVWR.thunk.v2';

export default function VWRDeleteModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { name } = useSelector((state: RootState) => state.modal.vwr);

	const { isLoading } = useSelector((state: RootState) => state.vwr);

	const secondaryButtonClickHandler = () => {
		dispatch(closeVWRModal());
	};

	const primaryButtonClickHandler = () => {
		dispatch(deleteVWRThunk());
	};

	return (
		<Portal>
			<Modal
				visible
				isCloseIconVisible={false}
				actionType="double"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t('vwr.confirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('vwr.cancel')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				secondaryBtnDisable={isLoading}
				primaryBtnDisable={isLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<div css={bodySt}>
					<Trans values={{ title: name }} components={{ bold: <strong /> }} i18nKey="vwr.confirmDelete" />
				</div>
			</Modal>
		</Portal>
	);
}

const bodySt = css`
	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray['900']};

	white-space: pre-line;

	line-height: 160%;

	text-align: center;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

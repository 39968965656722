import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { resetPathControlModal, setIsSegmentMaxNumModalVisible } from 'common/context/modalSlice';

import { isAxiosError } from 'common/api/isAxiosError';

import putPathControlSegmentApi from 'features/nfControl/api/putPathControlSegment.v2.api';
import type { PostBasicControlSegmentApiError } from 'features/nfControl/type/postBasicControlSegment.v2.type';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';
import type { MemberInfo } from 'features/nfBasic/context/segmentCreateDynamicForm.reducer';
import putOperatorApi from 'features/nfControl/api/putOperator.v2.api';

interface PutPathControlSegmentThunkProps {
	segmentName: string;
	accessYn: boolean;
	maxInflow: string;
	endUri: string;
	startUri: string;
	vwrPageId: number;
	segmentId: number;
	members: MemberInfo[];
}

const putPathControlSegmentThunk = createAsyncThunk<
	Omit<SegmentMetaData, 'segmentGraphYn' | 'segmentKey' | 'segmentColor' | 'segmentOrder'>,
	PutPathControlSegmentThunkProps,
	{ state: RootState }
>('path/segment/put/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentName, accessYn, maxInflow, startUri, endUri, vwrPageId, segmentId, members } = args;

	try {
		const {
			data: { id },
		} = await putPathControlSegmentApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			segmentId,
			body: {
				segmentName,
				accessYn,
				maxInflow: Number(maxInflow),
				startUri,
				endUri,
				vwrPageId,
			},
		});

		await putOperatorApi({
			tenantId: tenantId || '',
			organizationId,
			segmentId,
			userList: members.map(member => member.userKey),
		});

		thunkAPI.dispatch(resetPathControlModal());

		return {
			segmentName,
			accessYn,
			maxInflow: Number(maxInflow),
			id,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (isAxiosError<PostBasicControlSegmentApiError>(error) && error.response?.data.errorCode === 'S1013') {
			thunkAPI.dispatch(resetPathControlModal());
			thunkAPI.dispatch(setIsSegmentMaxNumModalVisible(true));
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default putPathControlSegmentThunk;

import { validationSDS } from 'surf-design-system';

export interface FileListElement {
	file: File;
	id: string;
}

export interface OwnershipTransferForm {
	title: string;
	isTitleChanged: boolean;
	isTitleValid: boolean;
	titleValidation: validationSDS;
	titleHelpText: string;
	description: string;
	isDescriptionChanged: boolean;
	isDescriptionValid: boolean;
	descriptionValidation: validationSDS;
	descriptionHelpText: string;
	fileList: FileListElement[];
	isFileUploadFail: boolean;
	fileId: number;
}

export interface OwnershipTransferFormAction {
	type?: string;
	payload?: string;
	files?: File[];
}

export const initialOwnershipTransferForm: OwnershipTransferForm = {
	title: '',
	isTitleChanged: false,
	isTitleValid: false,
	titleValidation: 'failed',
	titleHelpText: 'singnUp.errorSignUpNoInputMsg',
	description: '',
	isDescriptionChanged: false,
	isDescriptionValid: false,
	descriptionValidation: 'failed',
	descriptionHelpText: 'singnUp.errorSignUpNoInputMsg',
	fileList: [],
	isFileUploadFail: false,
	fileId: 0,
};

export const UPDATE_TITLE = 'UPDATE_TITLE';
export const VALIDATE_TITLE = 'VALIATE_TITLE';
export const UPDATE_TITLE_HELP_TEXT = 'UPDATE_TITLE_HELP_TEXT';

export const UPDATE_DESC = 'UPDATE_DESC';
export const VALIDATE_DESC = 'VALIATE_DESC';
export const UPDATE_DESC_HELP_TEXT = 'UPDATE_DESC_HELP_TEXT';

export const UPDATE_FILES = 'UPDATE_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const EMPTY_FILE = 'EMPTY_FILE';

export const ownershipTransferFormReducer = (
	state: OwnershipTransferForm,
	action: OwnershipTransferFormAction
): OwnershipTransferForm => {
	switch (action.type) {
		case UPDATE_TITLE: {
			const isTitlePayloadValid =
				(action.payload || '').trim().length > 0 && (action.payload || '').trim().length < 251;

			return {
				...state,
				title: action.payload || '',
				isTitleValid: isTitlePayloadValid,
				titleValidation: isTitlePayloadValid ? 'none' : 'failed',
				isTitleChanged: true,
			};
		}

		case VALIDATE_TITLE: {
			const isTitleStateValid = state.title.trim().length > 0 && state.title.trim().length < 251;

			return {
				...state,
				isTitleValid: state.isTitleChanged ? isTitleStateValid : false,
				titleValidation: state.isTitleChanged && isTitleStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_DESC: {
			const isDescriptionPayloadValid = (action.payload || '').trim() !== '' && (action.payload || '').length < 5001;

			return {
				...state,
				description: action.payload || '',
				isDescriptionValid: isDescriptionPayloadValid,
				descriptionValidation: isDescriptionPayloadValid ? 'none' : 'failed',
				isDescriptionChanged: true,
			};
		}

		case VALIDATE_DESC: {
			const isDescriptionStateValid = state.description.trim() !== '' && state.description.length < 5001;

			return {
				...state,
				isDescriptionValid: state.isDescriptionChanged ? isDescriptionStateValid : false,
				descriptionValidation: state.isDescriptionChanged && isDescriptionStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_FILES: {
			const filePayload = action.files || [];

			if (filePayload.length + state.fileList.length > 3) {
				return { ...state, isFileUploadFail: true };
			}

			if (!filePayload.every(el => el.size < 5 * 1024 * 1024)) {
				return { ...state, isFileUploadFail: true };
			}

			const newFileList = state.fileList.concat(
				filePayload.map((el, idx) => ({ id: (state.fileId + idx).toString(), file: el }))
			);

			return {
				...state,
				fileList: newFileList,
				fileId: state.fileId + filePayload.length + 1,
				isFileUploadFail: false,
			};
		}

		case DELETE_FILE: {
			const fileIdPayload = action.payload || '';

			return { ...state, fileList: state.fileList.filter(el => el.id !== fileIdPayload) };
		}

		case EMPTY_FILE: {
			return { ...state, isFileUploadFail: true };
		}

		default:
			return initialOwnershipTransferForm;
	}
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dracula, CodeBlock } from 'react-code-blocks';

import { paletteSDS, themeSDS, Button, FileDownloadIcon, Link } from 'surf-design-system';

import DividerWithTxt from 'common/components/DividerWithTxt';
import { fileDownloadFromUrl } from 'common/utils/fileFunc';
import { RootState } from 'common/redux/store';
import { ONPREM_AGENTS_PATH } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import AOSAgentCodeBox from 'features/nfSetting/components/AOSAgentCodeBox';
import { defaultApplyCodeBlock } from 'features/nfSetting/utils/onpremAOSCodeBlockFunc';
import OnpremAgentGuideEnd from './OnpremAgentGuideEnd';

interface InstallAOSAgentProps {
	titleFontSize?: string;
}

export default function OnPremInstallAOSAgent({ titleFontSize = '18px' }: InstallAOSAgentProps) {
	const { t } = useTranslation();

	const { tenantId } = useSelector((state: RootState) => state.account);

	const fileDownloadButtonClickHandler = async () => {
		await fileDownloadFromUrl(
			`${getOnPremBaseUrl(ONPREM_AGENTS_PATH)}/aos/netfunnel-android-agent_latest.zip`,
			`netfunnel-android-agent_latest.zip`
		);
	};

	return (
		<div css={gapColumn('24px')}>
			<div css={[titleSt(titleFontSize), { fontSize: '22px' }]}>{t('agent.applyTitle')}</div>
			<div css={gapColumn('24px')}>
				<div css={titleSt(titleFontSize)}>{t('agent.aosGuide1Title')}</div>
				<div css={gapColumn('16px')}>
					<div css={descSt}>{t('agent.aosGuide1Desc')}</div>
				</div>
				<DividerWithTxt />
				<div css={titleSt(titleFontSize)}>{t('agent.aosGuide2Title')}</div>
				<div css={gapColumn('4px')}>
					<div css={descSt}>
						<strong>{` · ${t('agent.javaVersion')}: `}</strong>
						{t('agent.javaVersionTxt')}
					</div>
					<div css={descSt}>
						<strong>{` · ${t('agent.androidSDK')}: `}</strong>
						{t('agent.androidSDKTxt')}
					</div>
					<div css={descSt}>
						<strong>{` · ${t('agent.dependency')}: `}</strong>
						{t('agent.dependencyTxt')}
					</div>
				</div>
				<DividerWithTxt />
				<div css={titleSt(titleFontSize)}>{t('agent.aosGuide4Title')}</div>
				<div css={gapColumn('16px')}>
					<div css={[gapColumn('16px'), { paddingLeft: '16px' }]}>
						<div css={subTitleSt}>1. {t('agent.aosGuide41Title')}</div>
						<div css={descSt}>{t('agent.aosGuide41Desc')}</div>
						<CodeBlock
							text={`implementation files('libs/netfunnel-android-agent_latest.aar')`}
							theme={dracula}
							language="java"
							codeBlock
							wrapLines
							customStyle={{
								backgroundColor: '#1E1F29',
								borderRadius: '8px',
								padding: '24px 16px',
							}}
						/>
						<Button
							mould="secondary"
							icon={<FileDownloadIcon fill={paletteSDS.blue['700']} />}
							iconPosition="right"
							onClick={fileDownloadButtonClickHandler}
							size="medium"
						>
							{t('agent.downloadInstallFile')}
						</Button>
						<div css={gapColumn('24px')} />
						<div css={gapColumn('16px')}>
							<div css={subTitleSt}>2. {t('agent.aosGuide42Title')}</div>
							<div css={descSt}>{t('agent.aosGuide42Desc')}</div>
							<div css={gapColumn('8px')}>
								<div css={rowSt}>
									<span css={dotSt}>·</span>
									<span css={codeTxt}>MyAppName</span>:<span css={descSt}>{t('agent.myAppTxt')}</span>
								</div>
								<div css={rowSt}>
									<span css={dotSt}>·</span>
									<span css={codeTxt}>API Server URL</span>:<span css={descSt}>{t('agent.APITxt')}</span>
								</div>
								<div css={rowSt}>
									<span css={dotSt}>·</span>
									<span css={codeTxt}>NetFUNNEL Server URL</span>:<span css={descSt}>{t('agent.serverURLTxt')}</span>
								</div>
								<div css={rowSt}>
									<span css={dotSt}>·</span>
									<span css={codeTxt}>NetFUNNEL Setting File URL</span>:
									<span css={descSt}>{t('agent.fileURLTxt')}</span>
								</div>
								<div css={rowSt}>
									<span css={dotSt}>·</span>
									<span css={codeTxt}>EUM Server URL</span>:<span css={descSt}>{t('agent.eumURLTxt')}</span>
								</div>
							</div>
						</div>
						<AOSAgentCodeBox
							agentCode={defaultApplyCodeBlock(
								tenantId,
								t('agent.myAppTxt'),
								t('agent.APITxt'),
								t('agent.serverURLTxt'),
								t('agent.fileURLTxt'),
								t('agent.eumURLTxt')
							)}
							height="367px"
						/>
					</div>
				</div>
			</div>

			<DividerWithTxt />
			<OnpremAgentGuideEnd />
		</div>
	);
}

const gapColumn = (gap: string) => css`
	display: flex;
	flex-direction: column;
	gap: ${gap};
`;

const titleSt = (titleFontSize: string) => css`
	color: var(--gray-800, #4c4c4c);
	font-size: 18px;
	font-weight: 600;
	line-height: 140%; /* 25.2px */
`;

const descSt = css`
	display: flex;
	align-items: center;
	gap: 4px;
	color: var(--gray-700, #666);
	font-size: 14px;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
	letter-spacing: 0.16px;
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	white-space: pre-line;
	word-break: break-all;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const requirementBox = css`
	display: flex;
	flex-direction: column;
	padding: 16px;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;

	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray['200']};
	background: ${paletteSDS.gray['50']};

	white-space: pre-line;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 128%;
	color: ${paletteSDS.gray['900']};
`;

const langBox = css`
	display: flex;
	gap: 16px;
	align-items: center;

	padding: 10.5px 24px 11.5px 24px;

	border-bottom: 1px solid ${paletteSDS.gray['800']};
`;

const selectedLangSt = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.white};

	cursor: pointer;
`;

const unSelectedLangSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.white};

	opacity: 0.24;

	cursor: pointer;
`;

const codeBlockContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	height: fit-content;

	background: #1e1f29;
	border-radius: 8px;
`;

const codeBlockBox = css`
	position: relative;
`;

const copyButtonSt = css`
	position: absolute;

	top: 0;
	right: 0;

	width: 61px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-left-radius: 8px;

	cursor: pointer;
`;

const copiedSt = css`
	position: absolute;

	top: 0;
	right: 61px;

	width: 75px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.blue['500']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

const codeTxt = css`
	display: flex;
	align-items: center;
	border: 1px solid ${paletteSDS.gray[200]};
	border-radius: 4px;
	padding: 6px 8px;
	width: fit-content;
	height: 24px;
	background-color: ${paletteSDS.gray[200]};
	color: ${paletteSDS.gray[700]};
	font-size: 12px;
	font-weight: 400;
	line-height: 100%; /* 12px */
`;

const subTitleSt = css`
	color: var(--gray-800, #4c4c4c);
	font-size: 16px;
	font-weight: 500;
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.16px;
`;

const rowSt = css`
	display: flex;
	gap: 4px;
`;
const dotSt = css`
	width: 8px;
	text-align: center;
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { GetSelectedOperatorListOnPremApiResponse } from 'features/nfControl/type/getSelectedOperatorList.v2.onPrem.type';

export interface GetSelectedOperatorListOnPremApiProps {
	tenantId: string;
	organizationId: number;
	segmentId: number;
}

export default async function getSelectedOperatorListOnPremApi({
	tenantId,
	organizationId,
	segmentId,
}: GetSelectedOperatorListOnPremApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.get<GetSelectedOperatorListOnPremApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/privilege/segment/${segmentId}/user-segment`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';

import { paletteSDS } from 'surf-design-system';

interface FunnelSegmentTooltipProps {
	isShow: boolean;
	value: number | string;
	name: string;
	top?: number;
	left?: number;
	parentWidth: number | undefined;
}

export default function FunnelSegmentTooltip({
	isShow = false,
	value = 0,
	name = '',
	top = 0,
	left = 0,
	parentWidth = 0,
}: FunnelSegmentTooltipProps) {
	return (
		<React.Fragment>
			{isShow && (
				<div css={FunnelSegmentTooltipCss(top, parentWidth, left)}>
					<span css={valueCss}>{value}</span>
					<span css={nameCss}>{name}</span>
				</div>
			)}
		</React.Fragment>
	);
}

const FunnelSegmentTooltipCss = (top: number, parentWidth: number, left: number) => css`
	position: absolute;
	top: ${top}px;
	left: ${left || parentWidth + 8}px;
	display: flex;
	align-items: center;
	width: fit-content;
	height: 20px;
	padding: 4px 8px;
	background-color: ${paletteSDS.systemColor.white};
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	white-space: nowrap;
	z-index: 2000;
`;

const valueCss = css`
	margin-right: 8px;
	color: ${paletteSDS.gray[900]};
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.5px;
`;

const nameCss = css`
	color: ${paletteSDS.gray[600]};
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.32px;
	word-break: keep-all;
`;

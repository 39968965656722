/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';

import { paletteSDS } from 'surf-design-system';

export interface UsageChartBoxProps {
	children?: React.ReactNode;
}

export default function UsageChartBox({ children }: UsageChartBoxProps) {
	return <div css={container}>{children}</div>;
}

const container = css`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 20rem;

	padding: 16px 24px 24px 16px;

	background: ${paletteSDS.gray[50]};

	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 8px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';

import { getDateFormatLanguage, addNumberComma, priceUnitFormat } from 'features/plan/utils/formatFunc';

export default function BillingHistoryRealtimeCharge() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		isRealtimeChargeLoaded,
		realtimeCharge: { charge, paymentMethod, planNames },
		realtimeChargeDetail: { chargeDetailModels },
	} = useSelector((state: RootState) => state.billing);

	const { language } = useSelector((state: RootState) => state.account);

	const payDate = moment().add(1, 'month').format('YYYY-MM') + '-05'.toString();
	const billedDate = getDateFormatLanguage(payDate, language);

	const viewDetailClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home/plan_usage/billing_history/realtime_detail`);
	};

	if (!isRealtimeChargeLoaded) <div />;

	return (
		<div css={currentListSt}>
			<div css={billingTitleSt}>{t('planSubscription.usageofMonth')}</div>
			<div css={billingInfoSt}>
				<div>
					{t('planSubscription.billedDate')} :&nbsp;
					{billedDate}
				</div>
				<div>
					{t('planSubscription.billingPlan')} :{' '}
					{planNames.length > 1 ? `${planNames[0]} 외 ${planNames.length - 1}개` : planNames[0]}
				</div>
				<div>
					{t('planSubscription.pymtMethod')} : {paymentMethod}
				</div>
			</div>
			<div css={billingPriceSt}>
				<div css={btnSt}>{t('planSubscription.now')}</div>
				<div css={priceTextSt}>
					{priceUnitFormat(chargeDetailModels[0].priceUnit)}
					{addNumberComma(String(charge))}
				</div>
			</div>
			<Button mould="tertiary" size="medium" onClick={viewDetailClickHandler}>
				{t('planSubscription.viewDetail')}
			</Button>
		</div>
	);
}

const currentListSt = css`
	display: grid;
	grid-template-columns: 10rem 15rem 21rem 10rem;
	justify-content: start;

	width: 60rem;
	min-width: 60rem;
	height: 8.125rem;
	padding: 2rem 1.5rem;

	border: 1px solid ${paletteSDS.gray[500]};
	border-radius: 4px;
`;

const billingTitleSt = css`
	font-size: ${themeSDS.fontSize[16]};
	font-weight: 600;
	color: ${paletteSDS.systemColor.black};
`;

const billingInfoSt = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	color: ${paletteSDS.gray[800]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

const billingPriceSt = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const btnSt = css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 2.6875rem;
	height: 1.5625rem;

	background-color: ${paletteSDS.categoryColor.mint};
	color: ${paletteSDS.white};

	border-radius: 4px;
`;

const priceTextSt = css`
	color: ${paletteSDS.black};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import type { PutSubscriptionData } from 'features/plan/types/putSubscription.type';
import putSubscriptionApi from 'features/plan/api/putSubscription.api';
import { getSubscriptionListThunk } from 'features/plan/thunk/getSubscriptionList.v2.thunk';

export const putSubscriptionThunk = createAsyncThunk<PutSubscriptionData, void, { state: RootState }>(
	'subscription/put',
	async (_, thunkAPI) => {
		const {
			account: { organizationId },
		} = thunkAPI.getState();

		try {
			const { data } = await putSubscriptionApi({
				organizationId,
			});

			thunkAPI.dispatch(getSubscriptionListThunk());

			return { ...data };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

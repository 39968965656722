/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { paletteSDS } from 'surf-design-system';

interface BackgroundBarProps {
	data: React.ReactNode;
	bgWidth: number;
}

export default function BackgroundBar({ data, bgWidth }: BackgroundBarProps) {
	return <div css={container(bgWidth)}>{data}</div>;
}

const container = (bgWidth: number) => css`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, ${paletteSDS.blue[100]} ${bgWidth}%, transparent ${bgWidth}% ${100 - bgWidth}%);
`;

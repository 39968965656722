/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { paletteSDS, themeSDS } from 'surf-design-system';

export default function SurffyProductTitle() {
	const { pathname } = useLocation();

	if (pathname.includes('/product/nf/')) {
		return (
			<React.Fragment>
				<div css={divider} />
				<div css={titleSt}>NetFUNNEL</div>
			</React.Fragment>
		);
	}

	return null;
}

const titleSt = css`
	font-size: ${themeSDS.fontSize['20']};
	font-weight: 800;
	line-height: 140%;
	color: ${paletteSDS.gray['500']};
`;

const divider = css`
	background: ${paletteSDS.gray['500']};

	width: 0.88px;
	height: 18.12px;
`;

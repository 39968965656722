/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { paletteSDS, themeSDS } from 'surf-design-system';
import { RootState, useAppDispatch } from 'common/redux/store';

import { postEUMIntervalUpdateThunk } from 'features/nfEUM/thunk/postEUMIntervalUpdate.thunk';
import { getProjectListThunk } from 'common/thunk/getProjectList.thunk';
import { cleanupEUMChartData, initializeState, resetEUMData, setInitialDate } from 'features/nfEUM/context/eumSlice';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import { postEUMIntervalStartThunk } from 'features/nfEUM/thunk/postEUMIntervalStart.thunk';
import URLDashboardSummary from 'features/nfEUM/components/URLDashboardSummary';
import URLStatusIndicator from 'features/nfEUM/components/URLStatusIndicator';
import URLTotalCountChart from 'features/nfEUM/components/URLTotalCountChart';
import URLTotalProcessTimeChart from 'features/nfEUM/components/URLTotalProcessTimeChart';

let intervalTimer: NodeJS.Timer | undefined;
let initialDateTimer: NodeJS.Timer | undefined;

export default function EUMUrlPage() {
	const dispatch = useAppDispatch();
	const [currIntervalSec, setCurrIntervalSec] = useState(false);

	const { isIntervalActivated, isLoaded } = useSelector((state: RootState) => state.eum);

	useEffect(() => {
		if (isIntervalActivated && isLoaded) {
			clearInterval(initialDateTimer);

			intervalTimer = setInterval(async () => {
				if (moment().seconds() === 8 && !currIntervalSec) {
					setCurrIntervalSec(true);
					dispatch(postEUMIntervalUpdateThunk());
				} else if (moment().seconds() !== 8) setCurrIntervalSec(false);
			}, 300);
		} else {
			clearInterval(intervalTimer);

			initialDateTimer = setInterval(() => {
				const currMoment = moment();

				if (currMoment.seconds() === 8) {
					dispatch(setInitialDate(currMoment.valueOf()));
				}
			}, 600);
		}

		return () => {
			clearInterval(intervalTimer);
			clearInterval(initialDateTimer);
		};
	}, [isIntervalActivated, dispatch, isLoaded, currIntervalSec]);

	useEffect(() => {
		dispatch(postEUMIntervalStartThunk());

		const cleanupTimeout = setInterval(() => {
			dispatch(cleanupEUMChartData());
		}, 30000000);

		return () => clearInterval(cleanupTimeout);
	}, [dispatch]);

	useLayoutEffect(() => {
		dispatch(getProjectListThunk({}));
		dispatch(initializeState(moment().startOf('minutes').valueOf()));
		return () => {
			clearInterval(intervalTimer);
			dispatch(resetEUMData());
			dispatch(setIsEUMNetworkErrorToastVisible(false));
			dispatch(setInitialToast());
		};
	}, [dispatch]);

	return (
		<div css={container}>
			<div css={boxWrapper}>
				<div css={summaryBox}>
					<URLDashboardSummary />
				</div>
				<div css={tableBox}>
					<URLStatusIndicator />
				</div>
			</div>
			<div css={chartScrollBox}>
				<div css={[chartContainer, toolTipSt]}>
					<URLTotalCountChart />
					<URLTotalProcessTimeChart />
				</div>
			</div>
		</div>
	);
}

const container = css`
	flex: 1 0 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	/* overflow: auto; */
`;

const boxWrapper = css`
	display: flex;
	gap: 8px;
`;

const summaryBox = css`
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	height: 632px;
	padding: 24px;
	background-color: ${paletteSDS.white};
`;

const tableBox = css`
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	height: 632px;
	padding: 24px;
	background-color: ${paletteSDS.white};
`;

const chartScrollBox = css`
	max-width: 1824px;
	min-width: 1617px;
	height: fit-content;
`;

const chartContainer = css`
	display: flex;
	gap: 8px;
`;

export const toolTipSt = css`
	.apexcharts-tooltip {
		height: 28px;
		padding: 6px;
		border: 1px solid ${paletteSDS.gray[400]};
		border-radius: 4px;
		background: ${paletteSDS.systemColor.white};
	}
	.container {
		display: flex;
		gap: 8px;
	}
	.curr {
		display: flex;
		align-items: center;
		gap: 4.25px;
	}
	.color {
		width: 7.5px;
		height: 7.5px;
		border-radius: 50%;
	}
	.value {
		color: ${paletteSDS.gray[900]};
		font-size: ${themeSDS.fontSize[12]};
		font-weight: ${themeSDS.fontWeight.bold};
		line-height: 120%;
		display: flex;
		align-items: center;
	}
	.label {
		color: ${paletteSDS.gray[900]};
		font-size: ${themeSDS.fontSize[12]};
		font-weight: ${themeSDS.fontWeight.medium};
		line-height: 120%;
	}
	.total {
		display: flex;
		gap: 4px;
	}
	.total_label {
		color: ${paletteSDS.gray[900]};
		font-size: ${themeSDS.fontSize[12]};
		font-weight: ${themeSDS.fontWeight.bold};
		line-height: 100%;
	}
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { DecreaseIcon, IncreaseIcon, NoChangeIcon, paletteSDS, themeSDS } from 'surf-design-system';

const { fontSize, fontWeight } = themeSDS;
const { gray, red, green } = paletteSDS;

interface ChangedAmountProps {
	value: number;
	disabled: boolean;
}

export default function ChangedAmount({ value, disabled }: ChangedAmountProps) {
	const verifyStatus = () => {
		if (disabled)
			return (
				<div css={statusCss(gray[500])}>
					<NoChangeIcon />
				</div>
			);
		if (!value)
			return (
				<div css={statusCss(gray[500])}>
					0<NoChangeIcon />
				</div>
			);
		if (value > 0)
			return (
				<div css={statusCss(green[500])}>
					{value}
					<IncreaseIcon />
				</div>
			);
		return (
			<div css={statusCss(red[500])}>
				{-value}
				<DecreaseIcon />
			</div>
		);
	};

	return <div css={changedAmountCss}>{verifyStatus()}</div>;
}
const changedAmountCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 24px;
`;

const statusCss = (color: string) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${color};
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.medium};
	line-height: 120%;

	svg {
		fill: ${color};
	}
`;

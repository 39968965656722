export interface MacroSettingForm {
	enteredIsMacroBlocked: string;
	enteredCheckMacroInterval: string;
	enteredLimitNumPerSec: string;
	enteredLimitNumPerPeriod: string;
	enteredBlockTime: string;
}

export const initialMacroSettingForm = {
	enteredIsMacroBlocked: 'true',
	enteredCheckMacroInterval: '5',
	enteredLimitNumPerSec: '1',
	enteredLimitNumPerPeriod: '',
	enteredBlockTime: '5',
};

export interface MacroSettingFormAction {
	type: string;
	payload?: string;
}

export const UPDATE_IS_MACRO_BLOCKED = 'UPDATE_IS_MACRO_BLOCKED';
export const UPDATE_CHECK_MACRO_INTERVAL = 'UPDATE_CHECK_MACRO_INTERVAL';
export const UPDATE_LIMIT_NUM_PER_SEC = 'UPDATE_LIMIT_NUM_PER_SEC';
export const UPDATE_LIMIT_NUM_PER_PERIOD = 'UPDATE_LIMIT_NUM_PER_PERIOD';
export const UPDATE_BLOCK_TIME = 'UPDATE_BLOCK_TIME';
export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const VALIDATE_LIMIT_NUM_PER_SEC = 'VALIDATE_LIMIT_NUM_PER_SEC';
export const VALIDATE_LIMIT_NUM_PER_PERIOD = 'VALIDATE_LIMIT_NUM_PER_PERIOD';

export const macroSettingFormReducer = (state: MacroSettingForm, action: MacroSettingFormAction): MacroSettingForm => {
	switch (action.type) {
		case UPDATE_IS_MACRO_BLOCKED: {
			return { ...state, enteredIsMacroBlocked: action.payload as string };
		}

		case UPDATE_CHECK_MACRO_INTERVAL: {
			return {
				...state,
				enteredCheckMacroInterval: action.payload as string,
				enteredLimitNumPerPeriod: action.payload === '' ? '' : state.enteredLimitNumPerPeriod,
			};
		}

		case UPDATE_LIMIT_NUM_PER_SEC: {
			let limitNumPerSecPayload = action.payload as string;

			if (Number(limitNumPerSecPayload) < 1) {
				limitNumPerSecPayload = '1';
			}

			if (Number(limitNumPerSecPayload) > 99999) {
				limitNumPerSecPayload = '99999';
			}

			if (action.payload === '') {
				limitNumPerSecPayload = '';
			}

			return { ...state, enteredLimitNumPerSec: limitNumPerSecPayload };
		}

		case VALIDATE_LIMIT_NUM_PER_SEC: {
			let limitNumPerSecState = Number(state.enteredLimitNumPerSec);

			if (limitNumPerSecState < 1) {
				limitNumPerSecState = 1;
			}

			if (limitNumPerSecState > 99999) {
				limitNumPerSecState = 99999;
			}

			return { ...state, enteredLimitNumPerSec: limitNumPerSecState.toFixed(0) };
		}

		case UPDATE_LIMIT_NUM_PER_PERIOD: {
			let limitNumPerPeriodPayload = action.payload as string;

			if (Number(limitNumPerPeriodPayload) > 99999) {
				limitNumPerPeriodPayload = '99999';
			}

			if (Number(limitNumPerPeriodPayload) < 0) {
				limitNumPerPeriodPayload = '0';
			}

			if (action.payload === '') {
				limitNumPerPeriodPayload = '';
			}

			return { ...state, enteredLimitNumPerPeriod: limitNumPerPeriodPayload };
		}

		case VALIDATE_LIMIT_NUM_PER_PERIOD: {
			let limitNumPerPeriodState = Number(state.enteredLimitNumPerPeriod);

			if (limitNumPerPeriodState < 0) {
				limitNumPerPeriodState = 0;
			}

			if (limitNumPerPeriodState > 99999) {
				limitNumPerPeriodState = 99999;
			}

			return { ...state, enteredLimitNumPerPeriod: limitNumPerPeriodState.toFixed(0) };
		}

		case UPDATE_BLOCK_TIME: {
			return { ...state, enteredBlockTime: action.payload as string };
		}

		case INITIALIZE_FORM: {
			const splitedPayload = (action.payload as string).split(',');

			return {
				enteredBlockTime: splitedPayload[0],
				enteredCheckMacroInterval: splitedPayload[1] === '0' ? '' : splitedPayload[1],
				enteredIsMacroBlocked: splitedPayload[2],
				enteredLimitNumPerPeriod: splitedPayload[3] === '0' ? '' : splitedPayload[3],
				enteredLimitNumPerSec: splitedPayload[4] === '0' ? '' : splitedPayload[4],
			};
		}

		default:
			return {
				enteredIsMacroBlocked: '',
				enteredCheckMacroInterval: '',
				enteredLimitNumPerSec: '',
				enteredLimitNumPerPeriod: '',
				enteredBlockTime: '',
			};
	}
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import type { RootState } from 'common/redux/store';
import getEUMTodayApi from 'features/nfEUM/api/getEUMToday.api';
import { GetEUMTodayApiDataCall, GetEUMTodayApiDataVisit } from 'features/nfEUM/types/getEUMToday.type';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface GetTXNTodayThunkResult {
	loadData: GetEUMTodayApiDataCall;
	visitData: GetEUMTodayApiDataVisit;
}

export const getTXNTodayThunk = createAsyncThunk<GetTXNTodayThunkResult, void, { state: RootState }>(
	'eum/txn/today/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantUrl, organizationId, customerDomain },
			eumTXNToday: { beginTime, endTime },
			projectInfo: { projectList, selectedProjectId },
			plan: { priceType },
		} = thunkAPI.getState();

		const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
		const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

		try {
			const apiResponse = await getEUMTodayApi({
				tenantUrl,
				organizationId,
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				beginTime,
				endTime,
				projectKey: selectedProjectKey,
			});

			const loadData = apiResponse.data.call;
			const visitData = apiResponse.data.visit;

			thunkAPI.dispatch(setInitialToast());

			return {
				loadData,
				visitData,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

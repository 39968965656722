/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { RootState, useAppDispatch } from 'common/redux/store';
import { COGNITO_TOKEN, LANG_PATH, USER_KEY } from 'common/data/constants';
import { getI18nPath } from 'common/utils/languageFunc';
import { getAccountInfoThunk } from 'common/thunk/getAccountInfo.thunk';
import axiosInstance from 'common/utils/axiosInstance';

import { getOrganizationPlanInfoThunk } from 'features/plan/thunk/getOrganizationPlanInfo.thunk';
import { getSubscriptionListThunk } from 'features/plan/thunk/getSubscriptionList.v2.thunk';

export default function AccountSetting() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { error, language } = useSelector((state: RootState) => state.account);

	const { error: planError } = useSelector((state: RootState) => state.plan);

	useEffect(() => {
		const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
		const userKey = window.sessionStorage.getItem(USER_KEY);

		if (cognitoToken === null) {
			window.location.replace(`/${getI18nPath()}/signin`);
			return;
		}

		axiosInstance.defaults.headers.Authorization = cognitoToken;
		axiosInstance.defaults.headers.userKey = userKey;

		(async () => {
			if (error) {
				// eslint-disable-next-line
				console.log(error.error);

				// window.location.replace('/signin');
				return;
			}

			const accountInfoAction = await dispatch(getAccountInfoThunk());

			const accountInfo = unwrapResult(accountInfoAction);

			if (accountInfo.tenantId === null || accountInfo.tenantStatus === 'DELETED') {
				// todo: 회원 가입만 하고 도메인 세팅 하지 않은 경우
				navigate(`/${getI18nPath()}/signup/register_domain`, { replace: true });
				return;
			}

			if (accountInfo.userStatus === 'DISABLED') {
				navigate(`/${getI18nPath()}/signin`, { replace: true });
				return;
			}

			dispatch(getOrganizationPlanInfoThunk());

			dispatch(getSubscriptionListThunk());

			navigate(`/${LANG_PATH[accountInfo.language]}/home`, { replace: true });
		})();
		// eslint-disable-next-line
	}, [dispatch, error]);

	useEffect(() => {
		if (planError && planError.errorCode === 'SC1000') {
			navigate(`/${LANG_PATH[language]}/home`, { replace: true });
		}
		// eslint-disable-next-line
	}, [planError, language]);

	return (
		<div css={container}>
			<svg width="48px" height="48px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" css={spinnerSt}>
				<path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
			</svg>
		</div>
	);
}

const spinnerSt = css`
	transform-origin: center;

	@keyframes spinner_svv2 {
		to {
			transform: rotate(360deg);
		}
	}
	animation: spinner_svv2 0.75s infinite linear;
`;

const container = css`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;

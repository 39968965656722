/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css, SerializedStyles } from '@emotion/react';
import React, { useState } from 'react';
import { CopyIcon, paletteSDS, themeSDS } from 'surf-design-system';
import AltText from './AltText';
import Tooltip from './Tooltip';

export interface TableTextBoxStyle {
	isEllipsis?: boolean;
	showTextBox?: boolean;
	textBoxMaxWidth?: string;
}

export interface TableCopyIconStyle {
	showCopyIcon?: boolean;
	copyIconPositionTop?: number;
	copyIconPositionLeft?: number;
}

export interface TableElementStyle extends TableTextBoxStyle, TableCopyIconStyle {
	width?: string;
	padding?: string;
	textAlign?: string;
	color?: string;
	fontSize?: string;
	fontWeight?: string;
	backgroundColor?: string;
	height?: string;
	minWidth?: string;
	maxWidth?: string;
	elementCss?: SerializedStyles | SerializedStyles[];
}

export interface TableElementData extends TableElementStyle {
	elementId: number | string;
	element: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLLIElement>;
}

export interface TableElementProps {
	id: number | string;
	element: TableElementData[];
	index: number;
	rowsLength: number;
	rowCss?: SerializedStyles | SerializedStyles[];
}

export interface ElementXY {
	x: number;
	y: number;
}

export default function TableElement({ id, element, index, rowsLength, rowCss }: TableElementProps) {
	const [hoverCellId, setHoverCellId] = useState(-1);
	const [hoverTextId, setHoverTextId] = useState(-1);
	const [hoverTextXY, setHoverTextXY] = useState<ElementXY>({ x: 0, y: 0 });
	const [copyMsg, setCopyMsg] = useState('Copy');

	const copyIconClickHandler = (url: string | undefined) => {
		navigator.clipboard.writeText(url as string);
		setCopyMsg('URL Copied!');
	};

	const textMouseOverHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		const { x, y } = e.currentTarget.getBoundingClientRect();
		setHoverTextXY({ x, y });
	};

	let lastCss = css``;

	if (rowsLength - 1 === index) {
		lastCss = lastSt;
	}

	return (
		<ul css={[listSt, lastCss, rowCss]}>
			{element.map((item, elementIndex) => {
				const {
					elementId,
					textAlign,
					width,
					fontSize,
					fontWeight,
					color,
					padding,
					backgroundColor,
					height,
					maxWidth,
					minWidth,
					elementCss,
					onClick,
					isEllipsis,
					showTextBox,
					textBoxMaxWidth,
					showCopyIcon,
				} = item;

				return (
					<li
						key={`table-${id}-${elementId}`}
						css={[
							elementSt({
								width,
								textAlign,
								fontWeight,
								fontSize,
								color,
								padding,
								backgroundColor,
								height,
								maxWidth,
								minWidth,
								isEnd: elementIndex === element.length - 1,
								isStart: elementIndex === 0,
								rowIndex: index,
								rowsLength,
							}),
							elementCss,
						]}
						onClick={onClick}
						aria-hidden
						onFocus={() => {}}
						onBlur={() => {}}
						onMouseOver={() => setHoverCellId(elementIndex)}
						onMouseOut={() => {
							setHoverCellId(-1);
							setCopyMsg('Copy');
						}}
					>
						<div
							css={elementDivSt(showCopyIcon)}
							onFocus={() => {}}
							onBlur={() => {}}
							onMouseOver={e => {
								setHoverTextId(elementIndex);
								textMouseOverHandler(e);
							}}
							onMouseOut={() => setHoverTextId(-1)}
						>
							{isEllipsis ? <div css={elementChildSt}>{item.element}</div> : item.element}
						</div>
						{showCopyIcon && elementIndex === hoverCellId && (
							<div css={copyIconWrapper}>
								<Tooltip isHelpIconVisible={false} message={copyMsg} position="bottom">
									<CopyIcon
										fill={paletteSDS.blue[700]}
										size="small"
										onClick={() => copyIconClickHandler(item.element?.toString())}
									/>
								</Tooltip>
							</div>
						)}
						{showTextBox &&
							elementIndex === hoverTextId &&
							typeof item.element === 'string' &&
							item.element.length > 32 && (
								<AltText text={item.element} textBoxMaxWidth={textBoxMaxWidth} x={hoverTextXY.x} y={hoverTextXY.y} />
							)}
					</li>
				);
			})}
		</ul>
	);
}

const listSt = css`
	display: flex;
	width: 100%;

	border-bottom: 1px solid #e3e3e3;
`;

const lastSt = css`
	border-bottom: none;

	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
`;

const elementSt = ({
	width,
	padding,
	textAlign,
	fontWeight,
	fontSize,
	color,
	backgroundColor,
	height,
	isEnd,
	isStart,
	minWidth,
	maxWidth,
	rowsLength,
	rowIndex,
}: TableElementStyle & { isStart: boolean; isEnd: boolean; rowsLength: number; rowIndex: number }) => css`
	position: relative;
	display: flex;
	align-items: center;
	flex: 1;

	width: ${width};
	max-width: ${maxWidth};
	min-width: ${minWidth};
	height: ${height || '47px'};
	padding: ${padding || '1rem'};

	color: ${color || paletteSDS.gray[900]};
	font-size: ${fontSize || themeSDS.fontSize[14]};
	font-weight: ${fontWeight || themeSDS.fontWeight.regular};
	text-align: ${textAlign || 'start'};
	background: ${backgroundColor || paletteSDS.white};

	border-bottom-left-radius: ${isStart && rowsLength - 1 === rowIndex && `3px`};
	border-bottom-right-radius: ${isEnd && rowsLength - 1 === rowIndex && '3px'};
`;

const elementDivSt = (showCopyIcon: boolean | undefined) => css`
	display: flex;
	align-items: center;
	${showCopyIcon ? `width: calc(100% - 32px); ` : `width: 100%`};
	height: 100%;
`;

const elementChildSt = css`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;

const copyIconWrapper = css`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 100%;
`;

import axios, { AxiosError } from 'axios';

import { PutNotificationReadYnApiResponse } from 'common/types/putNotificationReadYn.type';
import { languageType } from 'common/types/getUserProfile.type';
import { COGNITO_TOKEN, USER_KEY, LANG_HEADER, ONPREM_CENTER_API_PATH } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface PutNotificationReadYnApiProps {
	notificationId: number;
	organizationId: number;
	language: languageType;
}

export default async function putNotificationReadYnApi({
	notificationId,
	organizationId,
	language,
}: PutNotificationReadYnApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutNotificationReadYnApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/notification/${notificationId}/read`,
			{},
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					lang: LANG_HEADER[language],
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import putNotificationReadYnApi from 'common/api/putNotificationReadYn.api';
import type { RootState } from 'common/redux/store';

export interface PutNotificationReadYnThunkProps {
	notificationId: number;
}

export interface PutNotificationReadYnThunkResult {
	notificationId: number;
}

export const putNotificationReadYnThunk = createAsyncThunk<
	PutNotificationReadYnThunkResult,
	PutNotificationReadYnThunkProps,
	{ state: RootState }
>('noti/put/readYn', async (args: PutNotificationReadYnThunkProps, thunkAPI) => {
	const { notificationId } = args;

	const {
		account: { organizationId, language },
	} = thunkAPI.getState();
	try {
		await putNotificationReadYnApi({ organizationId, language, notificationId });

		return { notificationId };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getPathControlSegmentDetailApi from 'features/nfControl/api/getPathControlSegmentDetail.v2.api';
import type { PathControlSegmentDetailData } from 'features/nfControl/type/getPathControlSegmentDetail.v2.type';

interface GetPathControlSegmentDetailThunkProps {
	segmentId: number;
}

const getPathControlSegmentDetailThunk = createAsyncThunk<
	PathControlSegmentDetailData,
	GetPathControlSegmentDetailThunkProps,
	{ state: RootState }
>('get/path/segment/detail/v2', async (args, thunkAPI) => {
	const { segmentId } = args;

	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const { data } = await getPathControlSegmentDetailApi({
			tenantId: tenantId as string,
			organizationId,
			projectId: selectedProjectId,
			segmentId,
		});

		return { ...data };

		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default getPathControlSegmentDetailThunk;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';
// etc
import type { ApexOptions } from 'apexcharts';

interface RealTimeLineChartProps {
	options: ApexOptions;
	series: ApexAxisChartSeries;
}

export default function RealTimeLineChart({ options, series }: RealTimeLineChartProps) {
	return (
		<div css={realTimeLineChartCss}>
			<ReactApexChart options={options} series={series} type="line" width="100%" height="100%" />
		</div>
	);
}

const realTimeLineChartCss = css`
	/* display: block; */

	width: 100%;
	height: 100%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import { Button, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import Progressbar from 'common/components/Progressbar';

import uploadCoverFailImg from 'features/nfBasic/assets/upload failed-cover.png';
import uploadLogoFailImg from 'features/nfBasic/assets/upload failed-logo.png';

import { defaultLogoImageBase64 } from 'features/nfBasic/data/defaultImageBase64';

interface VWRPreviewProps {
	isCreate?: boolean;
}

function VWRPreview({ isCreate = false }: VWRPreviewProps) {
	const { t } = useTranslation();

	const { language } = useSelector((state: RootState) => state.account);

	const {
		isLoaded,
		preview: { logoImagePathSrc, title, followingWaitYn, guideText, imagePathSrc, waitCancelYn, waitTimeDisplayYn },
	} = useSelector((state: RootState) => state.vwr2);

	const logoErrorHandler: React.ReactEventHandler<HTMLImageElement> = e => {
		e.currentTarget.src = uploadLogoFailImg;
	};

	const coverErrorHandler: React.ReactEventHandler<HTMLImageElement> = e => {
		e.currentTarget.src = uploadCoverFailImg;
	};

	if (!isLoaded && !isCreate) return <div />;

	return (
		<div css={containerSt}>
			<div css={templateSt}>
				<div>
					<div css={previewLogoSt}>
						<img
							src={logoImagePathSrc === '' ? defaultLogoImageBase64 : logoImagePathSrc}
							alt="logo"
							onError={logoErrorHandler}
						/>
					</div>
					{imagePathSrc !== '' ? (
						<div css={previewCoverSt}>
							<img src={imagePathSrc} alt="cover" onError={coverErrorHandler} />
						</div>
					) : null}
					<div css={infoTitleSt}>{title}</div>
					<div css={progressBarBoxSt}>
						<Progressbar max="100" value="63" />
					</div>
					<div css={infoContentBoxSt}>
						{waitTimeDisplayYn && (
							<div css={infoContentSt}>
								<p>{t('projectMgmt.NumWaitTime')} : </p>
								<p>00:00:19</p>
							</div>
						)}
						<div css={infoContentSt}>
							<p>{t('projectMgmt.queueAhead')} : </p>
							<p>{language === 'KOREAN' ? '320명' : '320'}</p>
						</div>
						{followingWaitYn && (
							<div css={infoContentSt}>
								<p>{t('projectMgmt.queueBehind')} : </p>
								<p>{language === 'KOREAN' ? '680명' : '680'}</p>
							</div>
						)}
					</div>
					<div css={descriptionBoxSt(waitCancelYn)}>
						<p>{t('projectMgmt.vwrSettingMsg1')}</p>
						{guideText.length !== 0 ? <p css={textSt}>{guideText}</p> : <p>{t('projectMgmt.vwrSettingMsg2')}</p>}
						{waitCancelYn && (
							<Button width="100%" mould="secondary">
								{t('projectMgmt.exitqueue')}
							</Button>
						)}
					</div>
				</div>
				<div css={previewFooterSt}>
					<Trans i18nKey="commonWords.surffyInfo" components={{ bold: <b /> }} />
				</div>
			</div>
			<div css={notiSt}>{t('projectMgmt.vwrSettingPrevMsg')}</div>
		</div>
	);
}

export default React.memo(VWRPreview);

const containerSt = css`
	flex: none;

	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const templateSt = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 25rem;
	padding: 1.5rem 2rem;
	background-color: ${paletteSDS.gray[50]};
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 8px;
	> div:nth-of-type(1) {
		margin-bottom: 2rem;
	}
`;

const previewLogoSt = css`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto 1rem;

	> img {
		width: 336px;
		height: 64px;
		object-fit: contain;
	}
`;

const previewCoverSt = css`
	width: 21rem;

	border-radius: 4px;

	> img {
		width: 336px;
		height: 180px;
		object-fit: contain;
	}
`;

const infoTitleSt = css`
	width: 100%;
	line-height: 1.8125rem;
	margin: 1.5rem 0rem 2rem 0rem;
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[18]};
	font-weight: ${themeSDS.fontWeight.medium};
	text-align: center;
	word-wrap: break-word;
`;

const progressBarBoxSt = css`
	margin-bottom: 2rem;
`;

const infoContentBoxSt = css`
	margin-bottom: 2rem;
`;

const infoContentSt = css`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 1rem;
	line-height: 1rem;
	margin-bottom: 0.75rem;
	&:last-of-type {
		margin-bottom: 0;
	}
	p:nth-of-type(1) {
		margin-right: 0.4375rem;
		color: ${paletteSDS.gray[900]};
		font-weight: ${themeSDS.fontWeight.medium};
	}
	p:nth-of-type(2) {
		line-height: 1rem;
		color: ${paletteSDS.blue[600]};
		font-weight: 600;
	}
`;

const descriptionBoxSt = (waitCancelYn: boolean) => css`
	width: 21rem;
	line-height: 1.375rem;
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray[700]};
	white-space: pre-wrap;
	word-break: break-all;

	> p:nth-of-type(1) {
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid ${paletteSDS.gray[300]};
	}

	> p:nth-of-type(2) {
		color: ${paletteSDS.gray[500]};
		margin-bottom: ${waitCancelYn && '1.5rem'};
	}
`;

const textSt = css`
	color: ${paletteSDS.gray[700]} !important;
	white-space: pre-wrap;
`;

const previewFooterSt = css`
	width: 100%;
	height: 0.75rem;
	line-height: 0.625rem;
	font-size: 0.625rem;
	font-weight: ${themeSDS.fontWeight.regular};
	text-align: center;
	color: ${paletteSDS.gray[700]};
`;

const notiSt = css`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 1.1875rem;
	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

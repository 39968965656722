import { createAsyncThunk } from '@reduxjs/toolkit';

import i18next from 'i18next';

import { putUserLanguageApi } from 'features/setting/api/putUserLanguage.api';
import type { languageType } from 'common/types/getUserProfile.type';
import { toggleIsPreferenceChangeToastVisible } from 'common/context/toastSlice';

import { convertStringToLanguageType } from 'features/setting/utils/languageFunc';
import { LANG_KEY } from 'common/data/constants';

export interface PutUserLanguageThunkProps {
	language: string;
}

export interface PutUserLanguageThunkResult {
	language: languageType;
}

export const putUserLanguageThunk = createAsyncThunk<PutUserLanguageThunkResult, PutUserLanguageThunkProps>(
	'language/put',
	async (args: PutUserLanguageThunkProps, thunkAPI) => {
		const { language: enteredLanguage } = args;

		try {
			const {
				data: { languageType: languageResponse },
			} = await putUserLanguageApi({ language: convertStringToLanguageType(enteredLanguage) });

			await i18next.changeLanguage(languageResponse === 'KOREAN' ? 'ko' : 'en');

			localStorage.setItem(LANG_KEY, languageResponse === 'KOREAN' ? 'ko' : 'en');

			thunkAPI.dispatch(toggleIsPreferenceChangeToastVisible());

			return { language: convertStringToLanguageType(languageResponse) };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { donutChartAnnotationFn } from 'features/nfEUM/utils/chartFunc';
import { getTXNShareThunk } from 'features/nfEUM/thunk/getTXNShare.thunk';

export type ShareTab = 'WEB' | 'NATIVE';

export interface DountChartData {
	label: string;
	data: number;
}

export interface TXNShareState {
	labels: string[];
	series: number[];
	annotation: DountChartData;
}

export interface TXNIntervalTime {
	beginTime: number;
	endTime: number;
}

export interface TXNShareSliceData extends TXNIntervalTime {
	hasError: boolean;
	isLoaded: boolean;
	shareTab: ShareTab;
	browser: TXNShareState;
	webOS: TXNShareState;
	app: TXNShareState;
	nativeOS: TXNShareState;
}

const initData = {
	labels: ['No Data'],
	series: [1],
	annotation: { label: '', data: 0 },
};

const initialState: TXNShareSliceData = {
	hasError: false,
	isLoaded: true,
	shareTab: 'WEB',
	beginTime: 0,
	endTime: 0,
	browser: initData,
	webOS: initData,
	app: initData,
	nativeOS: initData,
};

const eumTXNShareSlice = createSlice({
	name: 'eumTXNShare',
	initialState,
	reducers: {
		setShareTab: (state, action: PayloadAction<ShareTab>) => {
			return { ...state, shareTab: action.payload };
		},
		setShareIntervalTime: (state, action: PayloadAction<TXNIntervalTime>) => {
			const { payload } = action;
			return { ...state, beginTime: payload.beginTime, endTime: payload.endTime };
		},
		resetShareData: () => {
			return { ...initialState };
		},
	},
	extraReducers: builder => {
		builder.addCase(getTXNShareThunk.rejected, state => {
			return { ...state, hasError: true, isLoaded: true };
		});

		builder.addCase(getTXNShareThunk.fulfilled, (state, action) => {
			const { payload } = action;

			// browser
			let browserLabels = state.browser.labels;
			let browserSeries = state.browser.series;
			let browserAnnotation = state.browser.annotation;

			if (payload.webBrowserData.length > 0) {
				const sortedData = payload.webBrowserData.sort((a, b) => {
					return b.count - a.count;
				});
				if (payload.webBrowserData.length > 5) {
					const top4Data = sortedData.slice(0, 4).sort((a, b) => {
						if (a.browser > b.browser) return 1;
						if (a.browser < b.browser) return -1;
						return 0;
					});
					const restData = { browser: 'etc', count: 0 };
					sortedData.slice(4).forEach(el => {
						restData.count += el.count;
					});
					browserLabels = [
						...top4Data.map(el => {
							return el.browser;
						}),
						restData.browser,
					];
					browserSeries = [
						...top4Data.map(el => {
							return el.count;
						}),
						restData.count,
					];
				} else {
					browserLabels = payload.webBrowserData.map(el => {
						return el.browser;
					});
					browserSeries = payload.webBrowserData.map(el => {
						return el.count;
					});
				}
				browserAnnotation = donutChartAnnotationFn(payload.webBrowserData);
			} else {
				browserLabels = initData.labels;
				browserSeries = initData.series;
				browserAnnotation = initData.annotation;
			}

			// webOS
			let webOSLabels = state.webOS.labels;
			let webOSSeries = state.webOS.series;
			let webOSAnnotation = state.webOS.annotation;

			if (payload.webOSData.length > 0) {
				const sortedData = payload.webOSData.sort((a, b) => {
					return b.count - a.count;
				});
				if (payload.webOSData.length > 5) {
					const top4Data = sortedData.slice(0, 4).sort((a, b) => {
						if (a.os > b.os) return 1;
						if (a.os < b.os) return -1;
						return 0;
					});
					const restData = { os: 'etc', count: 0 };
					sortedData.slice(4).forEach(el => {
						restData.count += el.count;
					});
					webOSLabels = [
						...top4Data.map(el => {
							return el.os;
						}),
						restData.os,
					];
					webOSSeries = [
						...top4Data.map(el => {
							return el.count;
						}),
						restData.count,
					];
				} else {
					webOSLabels = payload.webOSData.map(el => {
						return el.os;
					});
					webOSSeries = payload.webOSData.map(el => {
						return el.count;
					});
				}
				webOSAnnotation = donutChartAnnotationFn(payload.webOSData);
			} else {
				webOSLabels = initData.labels;
				webOSSeries = initData.series;
				webOSAnnotation = initData.annotation;
			}

			// app
			let appLabels = state.app.labels;
			let appSeries = state.app.series;
			let appAnnotation = state.app.annotation;

			if (payload.nativeAppData.length > 0) {
				const sortedData = payload.nativeAppData.sort((a, b) => {
					return b.count - a.count;
				});
				if (payload.nativeAppData.length > 5) {
					const top4Data = sortedData.slice(0, 4).sort((a, b) => {
						if (a.appVersion > b.appVersion) return 1;
						if (a.appVersion < b.appVersion) return -1;
						return 0;
					});
					const restData = { appVersion: 'etc', count: 0 };
					sortedData.slice(4).forEach(el => {
						restData.count += el.count;
					});
					appLabels = [
						...top4Data.map(el => {
							return el.appVersion;
						}),
						restData.appVersion,
					];
					appSeries = [
						...top4Data.map(el => {
							return el.count;
						}),
						restData.count,
					];
				} else {
					appLabels = payload.nativeAppData.map(el => {
						return el.appVersion;
					});
					appSeries = payload.nativeAppData.map(el => {
						return el.count;
					});
				}
				appAnnotation = donutChartAnnotationFn(payload.nativeAppData);
			} else {
				appLabels = initData.labels;
				appSeries = initData.series;
				appAnnotation = initData.annotation;
			}

			// nativeOS
			let nativeOSLabels = state.nativeOS.labels;
			let nativeOSSeries = state.nativeOS.series;
			let nativeOSAnnotation = state.nativeOS.annotation;

			if (payload.nativeOSData.length > 0) {
				const sortedData = payload.nativeOSData.sort((a, b) => {
					return b.count - a.count;
				});
				if (payload.nativeOSData.length > 5) {
					const top4Data = sortedData.slice(0, 4).sort((a, b) => {
						if (a.os > b.os) return 1;
						if (a.os < b.os) return -1;
						return 0;
					});
					const restData = { os: 'etc', count: 0 };
					sortedData.slice(4).forEach(el => {
						restData.count += el.count;
					});
					nativeOSLabels = [
						...top4Data.map(el => {
							return el.os;
						}),
						restData.os,
					];
					nativeOSSeries = [
						...top4Data.map(el => {
							return el.count;
						}),
						restData.count,
					];
				} else {
					nativeOSLabels = payload.nativeOSData.map(el => {
						return el.os;
					});
					nativeOSSeries = payload.nativeOSData.map(el => {
						return el.count;
					});
				}
				nativeOSAnnotation = donutChartAnnotationFn(payload.nativeOSData);
			} else {
				nativeOSLabels = initData.labels;
				nativeOSSeries = initData.series;
				nativeOSAnnotation = initData.annotation;
			}

			return {
				...state,
				hasError: false,
				isLoaded: true,
				browser: {
					...state.browser,
					labels: browserLabels,
					series: browserSeries,
					annotation: browserAnnotation,
				},
				webOS: {
					...state.webOS,
					labels: webOSLabels,
					series: webOSSeries,
					annotation: webOSAnnotation,
				},
				app: {
					...state.app,
					labels: appLabels,
					series: appSeries,
					annotation: appAnnotation,
				},
				nativeOS: {
					...state.nativeOS,
					labels: nativeOSLabels,
					series: nativeOSSeries,
					annotation: nativeOSAnnotation,
				},
			};
		});
	},
});

export const { setShareTab, setShareIntervalTime, resetShareData } = eumTXNShareSlice.actions;

export default eumTXNShareSlice.reducer;

import { validationSDS } from 'surf-design-system';

import type { MemberInfo } from 'features/nfControl/context/segmentCreateDynamicForm.onPrem.reducer';
import { rMaxInflow, rSegmentNameIncludeSpecialSymbol } from 'features/nfBasic/utils/regex';
import { isValidUrl } from '../utils/urlValidation';

export interface PathControlSegmentForm {
	segmentName: string;
	isSegmentNameValid: boolean;
	isSegmentNameChanged: boolean;
	segmentNameValidation: validationSDS;
	segmentNameHelpText: string;
	maxInflow: string;
	isMaxInflowValid: boolean;
	isMaxInflowChanged: boolean;
	maxInflowValidation: validationSDS;
	maxInflowHelpText: string;
	startUri: string;
	isStartUriValid: boolean;
	isStartUriChanged: boolean;
	startUriValidation: validationSDS;
	startUriHelpText: string;
	endUri: string;
	isEndUriValid: boolean;
	isEndUriChanged: boolean;
	endUriValidation: validationSDS;
	endUriHelpText: string;
	accessYn: boolean;
	isAccessYnChanged: boolean;
	vwrId: number;
	memberSearchValue: string;
	isMemberSearchValueChanged: boolean;
	searchedMemberList: MemberInfo[];
	selectedMemberList: MemberInfo[];
	disabledMemberList: MemberInfo[];
	mainDomain: string;
}

export interface PathControlSegmentFormAction {
	type?: string;
	payload?: string;
	initial?: PathControlSegmentForm;
	member?: MemberInfo;
	memberList?: MemberInfo[];
}

export const initialSegmentForm: PathControlSegmentForm = {
	segmentName: '',
	isSegmentNameValid: false,
	isSegmentNameChanged: false,
	segmentNameValidation: 'failed',
	segmentNameHelpText: '',
	maxInflow: '',
	isMaxInflowValid: false,
	isMaxInflowChanged: false,
	maxInflowValidation: 'failed',
	maxInflowHelpText: 'modal.limitInflowErrorMsg',
	startUri: '',
	startUriHelpText: '',
	startUriValidation: 'failed',
	isStartUriChanged: false,
	isStartUriValid: false,
	endUri: '',
	endUriHelpText: '',
	isEndUriChanged: false,
	isEndUriValid: false,
	endUriValidation: 'failed',
	accessYn: true,
	isAccessYnChanged: false,
	vwrId: 1,
	memberSearchValue: '',
	isMemberSearchValueChanged: false,
	searchedMemberList: [],
	selectedMemberList: [],
	disabledMemberList: [],
	mainDomain: '',
};

export const UPDATE_NAME = 'UPDATE_NAME';
export const VALIDATE_NAME = 'VALIDATE_NAME';
export const UPDATE_NAME_HELP_TEXT = 'UPDATE_NAME_HELP_TEXT';

export const UPDATE_MAX_INFLOW = 'UPDATE_MAX_INFLOW';
export const VALIDATE_MAX_INFLOW = 'VALIDATE_MAX_INFLOW';
export const UPDATE_MAX_INFLOW_HELP_TEXT = 'UPDATE_MAX_INFLOW_HELP_TEXT';

export const UPDATE_START_URI = 'UPDATE_START_URI';
export const VALIDATE_START_URI = 'VALIDATE_START_URI';
export const UPDATE_START_URI_HELP_TEXT = 'UPDATE_START_URI_HELP_TEXT';
export const RESET_START_URI = 'RESET_START_URI';

export const UPDATE_END_URI = 'UPDATE_END_URI';
export const VALIDATE_END_URI = 'VALIDATE_END_URI';
export const UPDATE_END_URI_HELP_TEXT = 'UPDATE_END_URI_HELP_TEXT';
export const RESET_END_URI = 'RESET_END_URI';

export const UPDATE_ACCESS_YN = 'UPDATE_ACCESS_YN';
export const VALIDATE_ACCESS_YN = 'VALIDATE_ACCESS_YN';
export const UPDATE_ACCESS_YN_HELP_TEXT = 'UPDATE_ACCESS_YN_HELP_TEXT';

export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const UPDATE_VWR_ID = 'UPDATE_VWR_ID';

export const UPDATE_MEMBER_SEARCH_VALUE = 'UPDATE_MEMBER_SEARCH_VALUE';
export const UPDATE_SEARCHED_MEMBER_LIST = 'UPDATE_SEARCHED_MEMBER_LIST';
export const ADD_MEMBER = 'ADD_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const UPDATE_SELECTED_MEMBER_LIST = 'UPDATE_SELECTED_MEMBER_LIST';

export const UPDATE_MAIN_DOMAIN = 'UPDATE_MAIN_DOMAIN';

export const segmentCreateFormOnPremReducer = (
	state: PathControlSegmentForm,
	action: PathControlSegmentFormAction
): PathControlSegmentForm => {
	switch (action.type) {
		case UPDATE_NAME: {
			const isSegmentNamePayloadValid = (action.payload || '').trim().length > 0 && (action.payload || '').length < 51;

			const isSegmentNamePayloadIncludeSpecialSymbol = rSegmentNameIncludeSpecialSymbol.test(action.payload || '');

			let segmentNamePayloadHelpText = '';

			if (isSegmentNamePayloadIncludeSpecialSymbol) {
				segmentNamePayloadHelpText = 'modal.segTitleErrorMsg2';
			}

			if (!isSegmentNamePayloadValid) {
				segmentNamePayloadHelpText = 'modal.segTitleErrorMsg';
			}

			return {
				...state,
				segmentName: action.payload || '',
				isSegmentNameChanged: true,
				isSegmentNameValid: isSegmentNamePayloadValid && !isSegmentNamePayloadIncludeSpecialSymbol,
				segmentNameHelpText: segmentNamePayloadHelpText,
				segmentNameValidation:
					isSegmentNamePayloadValid && !isSegmentNamePayloadIncludeSpecialSymbol ? 'none' : 'failed',
			};
		}

		case VALIDATE_NAME: {
			const isSegmentNameStateValid = state.segmentName.trim().length > 0 && state.segmentName.length < 51;

			const isSegmentNameStateIncludeSpecialSymbol = rSegmentNameIncludeSpecialSymbol.test(state.segmentName);

			let segmentNameStateHelpText = '';

			if (isSegmentNameStateIncludeSpecialSymbol) {
				segmentNameStateHelpText = 'modal.segTitleErrorMsg2';
			}

			if (!isSegmentNameStateValid) {
				segmentNameStateHelpText = 'modal.segTitleErrorMsg';
			}

			return {
				...state,
				isSegmentNameValid: state.isSegmentNameChanged
					? isSegmentNameStateValid && !isSegmentNameStateIncludeSpecialSymbol
					: false,
				segmentNameHelpText: segmentNameStateHelpText,
				segmentNameValidation:
					state.isSegmentNameChanged && isSegmentNameStateValid && !isSegmentNameStateIncludeSpecialSymbol
						? 'none'
						: 'failed',
			};
		}

		case UPDATE_MAX_INFLOW: {
			const isMaxInflowPayloadValid = rMaxInflow.test((action.payload || '0').trim());

			let maxInflowPayloadNumber = Number(action.payload || '0');

			const isMaxInflowNumberSmallerThanZero = maxInflowPayloadNumber < 0;

			const isMaxInflowNumberBiggerThanMax = maxInflowPayloadNumber > 99999999;

			if (isMaxInflowNumberBiggerThanMax) {
				maxInflowPayloadNumber = 99999999;
			}

			if (isMaxInflowNumberSmallerThanZero) {
				maxInflowPayloadNumber = 0;
			}

			return {
				...state,
				maxInflow: maxInflowPayloadNumber.toString(),
				isMaxInflowChanged: true,
				isMaxInflowValid: isMaxInflowPayloadValid,
				maxInflowValidation: isMaxInflowPayloadValid ? 'none' : 'failed',
			};
		}

		case VALIDATE_MAX_INFLOW: {
			const isMaxInflowStateValid = rMaxInflow.test(state.maxInflow.trim());

			return {
				...state,
				isMaxInflowChanged: true,
				isMaxInflowValid: isMaxInflowStateValid,
				maxInflowValidation: isMaxInflowStateValid ? 'none' : 'failed',
			};
		}

		case UPDATE_START_URI: {
			const startUriPayload = (action.payload || '').trim();

			const isEmptyStartUri = startUriPayload === '';

			const isEmptyEndUri = state.endUri === '';

			const isStartUriPayloadFormValid = !startUriPayload.includes('/*');

			const isStartUrlIncludeDomain = isValidUrl(startUriPayload);

			const isStartUriPayloadValid = isStartUriPayloadFormValid && !isEmptyStartUri && isStartUrlIncludeDomain;

			const isStartUriPayloadSameWithEndUri = startUriPayload === state.endUri;

			const isEndUriStateValid = !state.endUri.includes('/*') && isValidUrl(state.endUri) && !isEmptyEndUri;

			let startUriPayloadHelpText = '';

			let endUriStateHelpText = '';
			let endUriStateValidation: validationSDS = 'none';

			if (isStartUriPayloadSameWithEndUri) {
				startUriPayloadHelpText = 'modal.duplicatedURL';
				endUriStateHelpText = 'modal.duplicatedURL';
				endUriStateValidation = 'failed';
			}

			if (!isStartUriPayloadFormValid) {
				startUriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			if (!isStartUriPayloadSameWithEndUri && isEndUriStateValid) {
				endUriStateHelpText = '';
				endUriStateValidation = 'none';
			}

			if (!isStartUriPayloadSameWithEndUri && !isEndUriStateValid) {
				endUriStateHelpText = 'modal.addUrlErrorMsg1';
				endUriStateValidation = 'failed';
			}

			if (!isStartUrlIncludeDomain) {
				startUriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			if (isEmptyEndUri) {
				endUriStateHelpText = 'modal.addUrlErrorMsg1';
				endUriStateValidation = 'failed';
			}

			if (isEmptyStartUri) {
				startUriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			return {
				...state,
				startUri: startUriPayload,
				isStartUriChanged: true,
				isStartUriValid: isStartUriPayloadValid && !isStartUriPayloadSameWithEndUri,
				startUriHelpText: startUriPayloadHelpText,
				startUriValidation: !isStartUriPayloadSameWithEndUri && isStartUriPayloadValid ? 'none' : 'failed',
				endUriValidation:
					isStartUriPayloadValid && isStartUriPayloadSameWithEndUri && !isEmptyEndUri
						? 'failed'
						: endUriStateValidation,
				endUriHelpText:
					isStartUriPayloadValid && isStartUriPayloadSameWithEndUri && !isEmptyEndUri
						? startUriPayloadHelpText
						: endUriStateHelpText,
				isEndUriValid:
					isStartUriPayloadValid && isStartUriPayloadSameWithEndUri && !isEmptyEndUri ? false : isEndUriStateValid,
				isEndUriChanged: isStartUriPayloadValid && isStartUriPayloadSameWithEndUri ? true : state.isEndUriChanged,
			};
		}

		case VALIDATE_START_URI: {
			const isEmptyStartUri = state.startUri === '';

			const isEmptyEndUri = state.endUri === '';

			const isStartUrlIncludeDomain = isValidUrl(state.startUri);

			const isStartUriStateFormValid = !state.startUri.includes('/*') && isStartUrlIncludeDomain;

			const isStartUriStateValid = isStartUriStateFormValid && !isEmptyStartUri;

			const isStartUriStateSameWithEndUri = state.startUri === state.endUri;

			let startUriStateHelpText = '';

			if (isStartUriStateSameWithEndUri) {
				startUriStateHelpText = 'modal.duplicatedURL';
			}

			if (!isStartUriStateFormValid) {
				startUriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			if (!isStartUrlIncludeDomain) {
				startUriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			if (isEmptyStartUri) {
				startUriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			return {
				...state,
				isStartUriValid: state.isStartUriChanged
					? isStartUriStateValid && !isStartUriStateSameWithEndUri && !isEmptyStartUri
					: false,
				startUriHelpText: startUriStateHelpText,
				startUriValidation:
					state.isStartUriChanged && isStartUriStateValid && !isStartUriStateSameWithEndUri ? 'none' : 'failed',
				endUriValidation:
					isStartUriStateValid && isStartUriStateSameWithEndUri && !isEmptyEndUri ? 'failed' : state.endUriValidation,
				endUriHelpText:
					isStartUriStateValid && isStartUriStateSameWithEndUri && !isEmptyEndUri
						? startUriStateHelpText
						: state.endUriHelpText,
				isEndUriValid:
					isStartUriStateValid && isStartUriStateSameWithEndUri && !isEmptyEndUri ? false : state.isEndUriValid,
			};
		}

		case UPDATE_END_URI: {
			const endUriPayload = (action.payload || '').trim();

			const isEmptyEndUri = endUriPayload === '';

			const isEmptyStartUri = state.startUri === '';

			const isEndUriPayloadFormValid = !endUriPayload.includes('/*');

			const isEndUrlIncludeDomain = isValidUrl(endUriPayload);

			const isEndUriPayloadValid = isEndUriPayloadFormValid && !isEmptyEndUri && isEndUrlIncludeDomain;

			const isEndUriPayloadSameWithStartUri = endUriPayload === state.startUri;

			const isStartUriStateValid = !state.startUri.includes('/*') && isValidUrl(state.startUri) && !isEmptyStartUri;

			let endUriPayloadHelpText = '';

			let startUriStateHelpText = '';
			let startUriStateValidation: validationSDS = 'none';

			if (isEndUriPayloadSameWithStartUri) {
				endUriPayloadHelpText = 'modal.duplicatedURL';
				startUriStateHelpText = 'modal.duplicatedURL';
				startUriStateValidation = 'failed';
			}

			if (!isEndUriPayloadFormValid) {
				endUriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			if (!isEndUriPayloadSameWithStartUri && isStartUriStateValid) {
				startUriStateHelpText = '';
				startUriStateValidation = 'none';
			}

			if (!isEndUriPayloadSameWithStartUri && !isStartUriStateValid) {
				startUriStateHelpText = 'modal.addUrlErrorMsg1';
				startUriStateValidation = 'failed';
			}

			if (!isEndUrlIncludeDomain) {
				endUriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			if (isEmptyStartUri) {
				startUriStateHelpText = 'modal.addUrlErrorMsg1';
				startUriStateValidation = 'failed';
			}

			if (isEmptyEndUri) {
				endUriPayloadHelpText = 'modal.addUrlErrorMsg1';
			}

			return {
				...state,
				endUri: endUriPayload,
				isEndUriChanged: true,
				isEndUriValid: isEndUriPayloadValid && !isEndUriPayloadSameWithStartUri && !isEmptyEndUri,
				endUriHelpText: endUriPayloadHelpText,
				endUriValidation: !isEndUriPayloadSameWithStartUri && isEndUriPayloadValid ? 'none' : 'failed',
				startUriValidation:
					isEndUriPayloadValid && isEndUriPayloadSameWithStartUri && !isEmptyStartUri
						? 'failed'
						: startUriStateValidation,
				startUriHelpText:
					isEndUriPayloadValid && isEndUriPayloadSameWithStartUri && !isEmptyStartUri
						? endUriPayloadHelpText
						: startUriStateHelpText,
				isStartUriValid:
					isEndUriPayloadValid && isEndUriPayloadSameWithStartUri && !isEmptyStartUri ? false : isStartUriStateValid,
				isStartUriChanged: isEndUriPayloadValid && isEndUriPayloadSameWithStartUri ? true : state.isStartUriChanged,
			};
		}

		case VALIDATE_END_URI: {
			const isEmptyStartUri = state.startUri === '';

			const isEmptyEndUri = state.endUri === '';

			const isEndUrlIncludeDomain = isValidUrl(state.endUri);

			const isEndUriStateFormValid = !state.endUri.includes('/*') && isEndUrlIncludeDomain;

			const isEndUriStateValid = isEndUriStateFormValid && !isEmptyEndUri;

			const isEndUriStateSameWithStartUri = state.startUri === state.endUri;

			let endUriStateHelpText = '';

			if (isEndUriStateSameWithStartUri) {
				endUriStateHelpText = 'modal.duplicatedURL';
			}

			if (!isEndUriStateFormValid) {
				endUriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			if (!isEndUrlIncludeDomain) {
				endUriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			if (isEmptyEndUri) {
				endUriStateHelpText = 'modal.addUrlErrorMsg1';
			}

			return {
				...state,
				isEndUriValid: state.isEndUriChanged
					? isEndUriStateValid && !isEndUriStateSameWithStartUri && !isEmptyEndUri
					: false,
				endUriHelpText: endUriStateHelpText,
				endUriValidation:
					state.isEndUriChanged && isEndUriStateValid && !isEndUriStateSameWithStartUri ? 'none' : 'failed',
				startUriValidation:
					isEndUriStateValid && isEndUriStateSameWithStartUri && !isEmptyStartUri ? 'failed' : state.startUriValidation,
				startUriHelpText:
					isEndUriStateValid && isEndUriStateSameWithStartUri && !isEmptyStartUri
						? endUriStateHelpText
						: state.startUriHelpText,
				isStartUriValid:
					isEndUriStateValid && isEndUriStateSameWithStartUri && !isEmptyStartUri ? false : state.isStartUriValid,
			};
		}

		case UPDATE_ACCESS_YN: {
			return { ...state, accessYn: action.payload === 'true', isAccessYnChanged: true };
		}

		case UPDATE_NAME_HELP_TEXT: {
			return {
				...state,
				segmentNameValidation: 'failed',
				segmentNameHelpText: action.payload || '',
				isSegmentNameChanged: true,
				isSegmentNameValid: false,
			};
		}

		case UPDATE_START_URI_HELP_TEXT: {
			return {
				...state,
				startUriValidation: 'failed',
				startUriHelpText: action.payload || '',
				isStartUriChanged: true,
				isStartUriValid: false,
			};
		}

		case UPDATE_END_URI_HELP_TEXT: {
			return {
				...state,
				endUriValidation: 'failed',
				endUriHelpText: action.payload || '',
				isEndUriChanged: true,
				isEndUriValid: false,
			};
		}

		case INITIALIZE_FORM: {
			return { ...(action.initial as PathControlSegmentForm) };
		}

		case UPDATE_VWR_ID: {
			return { ...state, vwrId: Number(action.payload) };
		}

		case UPDATE_MEMBER_SEARCH_VALUE: {
			return { ...state, isMemberSearchValueChanged: true, memberSearchValue: action.payload as string };
		}

		case UPDATE_SEARCHED_MEMBER_LIST: {
			const selectedMemberStateUserKeyArray = state.selectedMemberList.map(el => el.userKey);

			return {
				...state,
				searchedMemberList: [
					...(action.memberList as MemberInfo[]).filter(el => !selectedMemberStateUserKeyArray.includes(el.userKey)),
				],
				disabledMemberList:
					action.payload === ''
						? [...(action.memberList as MemberInfo[]).filter(el => el.state === 'DISABLED')]
						: [...state.disabledMemberList],
			};
		}

		case ADD_MEMBER: {
			const isSelectedMemberAlreadyExist =
				state.selectedMemberList.findIndex(el => el.userKey === (action.member as MemberInfo).userKey) !== -1;

			return {
				...state,
				selectedMemberList: isSelectedMemberAlreadyExist
					? [...state.selectedMemberList]
					: state.selectedMemberList.concat(action.member as MemberInfo),
				searchedMemberList: state.searchedMemberList.filter(el => el.userKey !== (action.member as MemberInfo).userKey),
			};
		}

		case DELETE_MEMBER: {
			return {
				...state,
				selectedMemberList: state.selectedMemberList.filter(el => el.userKey !== (action.member as MemberInfo).userKey),
			};
		}

		case UPDATE_MAIN_DOMAIN: {
			return { ...state, mainDomain: action.payload as string };
		}

		case UPDATE_SELECTED_MEMBER_LIST: {
			return { ...state, selectedMemberList: [...(action.memberList as MemberInfo[])] };
		}

		default:
			return initialSegmentForm;
	}
};

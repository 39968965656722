import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import type { RootState } from 'common/redux/store';
import postEUMNetworkStatDataApi from 'features/nfEUM/api/postEUMNetworkStatData.api';
import { PostEUMSummaryApiData } from 'features/nfEUM/types/postEUMSummary.type';
import { PostEUMUriStatusApiData } from 'features/nfEUM/types/postEUMUriStatus.type';
import postEUMUriStatusApi from 'features/nfEUM/api/postEUMUriStatus.api';
import postEUMSummaryApi from 'features/nfEUM/api/postEUMSummary.api';
import { X_AXIS_VALUE_INTERVAL_SELECT, X_AXIS_NUM_SELECT } from 'features/nfEUM/data/constants';
import type { ChartData } from 'features/nfBasic/context/monitoringSlice';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface PostEUMStepDateThunkProps {
	isIncrease: boolean;
	isStepDay: boolean;
}

export interface PostEUMTStepDateThunkResult {
	processTimeY: ChartData[];
	countY: ChartData[];
	summaryData: PostEUMSummaryApiData;
	uriStatusData: PostEUMUriStatusApiData[];
	newSelectedDate: number;
}

export const postEUMStepDateThunk = createAsyncThunk<
	PostEUMTStepDateThunkResult,
	PostEUMStepDateThunkProps,
	{ state: RootState }
>('eum/post/step', async (args, thunkAPI) => {
	const {
		account: { tenantUrl, organizationId, timeZone, customerDomain },
		eum: { selectedDate },
		projectInfo: { projectList, selectedProjectId },
		plan: { priceType },
	} = thunkAPI.getState();

	const { isIncrease, isStepDay } = args;

	let newMomentValueOf = moment(selectedDate).add(1, 'day').valueOf();

	if (isStepDay && !isIncrease) {
		newMomentValueOf = moment(selectedDate).subtract(1, 'day').valueOf();
	}

	if (!isStepDay && isIncrease) {
		newMomentValueOf = moment(selectedDate).add(1, 'minutes').valueOf();
	}

	if (!isStepDay && !isIncrease) {
		newMomentValueOf = moment(selectedDate).subtract(1, 'minutes').valueOf();
	}
	const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
	const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

	try {
		const updatedData = await Promise.all([
			postEUMNetworkStatDataApi({
				tenantUrl,
				organizationId,
				beginTime: Number(moment(newMomentValueOf).utcOffset(timeZone.slice(3)).startOf('day').format('X')),
				endTime: Number(moment(newMomentValueOf).utcOffset(timeZone.slice(3)).endOf('day').format('X')) + 1,
				type: 'H',
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				projectId: selectedProjectId,
				projectKey: selectedProjectKey,
			}),
			postEUMUriStatusApi({
				tenantUrl,
				organizationId,
				timestamp: Number(moment(newMomentValueOf).utcOffset(timeZone.slice(3)).format('X')),
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				projectId: selectedProjectId,
				projectKey: selectedProjectKey,
			}),
			postEUMSummaryApi({
				tenantUrl,
				organizationId,
				timestamp: Number(moment(newMomentValueOf).utcOffset(timeZone.slice(3)).format('X')),
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				projectId: selectedProjectId,
				projectKey: selectedProjectKey,
			}),
		]);

		let processTimeY: ChartData[] = [];
		let countY: ChartData[] = [];

		if (updatedData[0].data.length > X_AXIS_NUM_SELECT) {
			processTimeY = updatedData[0].data
				.slice(updatedData[0].data.length - X_AXIS_NUM_SELECT)
				.map(processData => ({ x: processData.ts, y: Number((processData.avg_pt / 1000).toFixed(1)) }));

			countY = updatedData[0].data
				.slice(updatedData[0].data.length - X_AXIS_NUM_SELECT)
				.map(countData => ({ x: countData.ts, y: countData.sum_count }));
		}

		if (updatedData[0].data.length === X_AXIS_NUM_SELECT) {
			processTimeY = updatedData[0].data.map(processData => ({
				x: processData.ts,
				y: Number((processData.avg_pt / 1000).toFixed(1)),
			}));
			countY = updatedData[0].data.map(countData => ({ x: countData.ts, y: countData.sum_count }));
		}

		if (updatedData[0].data.length > 0 && updatedData[0].data.length < X_AXIS_NUM_SELECT) {
			const firstTimestamp = updatedData[0].data[0].ts;

			const nullChartData = Array.from<unknown, ChartData>(
				{ length: X_AXIS_NUM_SELECT - updatedData[0].data.length },
				(nullEl, nullIdx) => ({
					y: null,
					x: firstTimestamp - (X_AXIS_NUM_SELECT - updatedData[0].data.length - nullIdx) * X_AXIS_VALUE_INTERVAL_SELECT,
				})
			);

			processTimeY = nullChartData.slice().concat(
				updatedData[0].data.map(processData => ({
					x: processData.ts,
					y: Number((processData.avg_pt / 1000).toFixed(1)),
				}))
			);

			countY = nullChartData
				.slice()
				.concat(updatedData[0].data.map(countData => ({ x: countData.ts, y: countData.sum_count })));
		}

		if (updatedData[0].data.length === 0) {
			const nullChartData = Array.from<unknown, ChartData>({ length: X_AXIS_NUM_SELECT }, (nullEl, nullIdx) => ({
				y: null,
				x:
					Number(moment(newMomentValueOf).utcOffset(timeZone.slice(3)).startOf('day').format('X')) +
					nullIdx * X_AXIS_VALUE_INTERVAL_SELECT,
			}));

			processTimeY = nullChartData.slice();
			countY = nullChartData.slice();
		}

		thunkAPI.dispatch(setInitialToast());

		return {
			processTimeY,
			countY,
			uriStatusData: updatedData[1].data,
			summaryData: updatedData[2].data,
			newSelectedDate: newMomentValueOf,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

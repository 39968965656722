/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Textinput, Button, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { toggleIsProfileEditModalVisible } from 'common/context/modalSlice';
import { MAX_USER_NAME_CHAR_NUM } from '../data/constants';
import FileUploadForm from './FileUploadForm';
import {
	initialProfileForm,
	profileFormReducer,
	UPDATE_FILE,
	UPDATE_NAME,
	VALIDATE_NAME,
} from '../context/profileForm.reducer';
import { putUserNameThunk } from '../thunk/putUserName.thunk';
import { postUserProfileImageThunk } from '../thunk/postUserProfileImage.thunk';

/**
 * author: Jay
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-19
 * description: 프로필 설정 수정 모달 - 사용자 이름 변경 및 이미지 변경
 * todo:
 */

export default function ProfileEditModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { userName, isLoading } = useSelector((state: RootState) => state.account);
	const { isProfileEditModalVisible } = useSelector((state: RootState) => state.modal);

	const [profileFormData, setProfileFormData] = useReducer(profileFormReducer, {
		...initialProfileForm,
		enteredName: userName,
	});

	const {
		enteredName,
		image,
		isEnteredNameChanged,
		isEnteredNameValid,
		isImageChanged,
		enteredNameHelpText,
		enteredNameValidation,
	} = profileFormData;

	/**
	 * description: 모달 취소 버튼 클릭시 제어
	 */
	const cancelButtonClickHandler = () => {
		dispatch(toggleIsProfileEditModalVisible());
	};

	const nameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setProfileFormData({ type: UPDATE_NAME, payload: e.target.value });
	};

	const nameBlurHandler = () => {
		setProfileFormData({ type: VALIDATE_NAME });
	};

	const fileDeleteHandler = () => {
		setProfileFormData({ type: UPDATE_FILE, file: null });
	};

	const fileUploadHandler = (file: File) => {
		setProfileFormData({ type: UPDATE_FILE, file });
	};

	const fileUploadFailBySizeHandler = () => {
		// eslint-disable-next-line
		alert('Only under 500kb file can be uploaded.');
	};

	const fileUploadFailByTypeHandler = () => {
		// eslint-disable-next-line
		alert('Only jpg and png file can be uploaded.');
	};

	/**
	 * description: 모달 변경 버튼 클릭시 제어
	 */
	const profileFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault();

		if (isEnteredNameChanged) {
			dispatch(putUserNameThunk({ name: enteredName }));
		}

		if (isImageChanged && image) {
			dispatch(postUserProfileImageThunk({ image }));
		}

		dispatch(toggleIsProfileEditModalVisible());
	};

	/**
	 * description: 이름, 이미지 수정 여부에 따라 모달 변경 버튼 활성화
	 */

	return (
		<Portal>
			<Modal
				visible={isProfileEditModalVisible}
				title={t('modal.editProfile')}
				isCloseIconVisible={false}
				actionType="none"
			>
				<form css={formSt} onSubmit={profileFormSubmitHandler}>
					<div css={inputSt}>
						<Textinput
							label={t('commonWords.userName')}
							helpText={isEnteredNameChanged && !isEnteredNameValid && t(enteredNameHelpText)}
							validation={isEnteredNameChanged ? enteredNameValidation : 'none'}
							value={enteredName}
							onChange={nameChangeHandler}
							maxLength={MAX_USER_NAME_CHAR_NUM}
							onBlur={nameBlurHandler}
							showIcon
						/>
						<FileUploadForm
							id="profile"
							name="profile_img"
							onDelete={fileDeleteHandler}
							onSuccess={fileUploadHandler}
							onFailBySize={fileUploadFailBySizeHandler}
							onFailByType={fileUploadFailByTypeHandler}
							label={t('modal.profileImg')}
							helpText={t('modal.profileImgMsg')}
						/>
					</div>
					<div css={buttonSt}>
						<Button type="button" size="large" width="100%" mould="secondary" onClick={cancelButtonClickHandler}>
							{t('commonWords.cancel')}
						</Button>
						<Button size="large" width="100%" disabled={(isEnteredNameChanged && !isEnteredNameValid) || isLoading}>
							{t('modal.profileImgSave')}
						</Button>
					</div>
				</form>
			</Modal>
		</Portal>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
`;

const inputSt = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const buttonSt = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

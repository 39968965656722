import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import type { RootState } from 'common/redux/store';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import postEUMNetworkStatDataApi from 'features/nfEUM/api/postEUMNetworkStatData.api';
import type { PostEUMNetworkStatDataApiData } from 'features/nfEUM/types/postEUMNetworkStatData.type';
import { PostEUMSummaryApiData } from 'features/nfEUM/types/postEUMSummary.type';
import { PostEUMUriStatusApiData } from 'features/nfEUM/types/postEUMUriStatus.type';
import postEUMUriStatusApi from 'features/nfEUM/api/postEUMUriStatus.api';
import postEUMSummaryApi from 'features/nfEUM/api/postEUMSummary.api';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface PostEUMIntervalUpdateThunkResult {
	networkStatData: PostEUMNetworkStatDataApiData[];
	summaryData: PostEUMSummaryApiData;
	uriStatusData: PostEUMUriStatusApiData[];
	newSelectedDate: number;
}

export const postEUMIntervalUpdateThunk = createAsyncThunk<
	PostEUMIntervalUpdateThunkResult,
	void,
	{ state: RootState }
>('eum/interval/update/post', async (_, thunkAPI) => {
	const {
		account: { tenantUrl, organizationId, customerDomain },
		eum: { selectedDate },
		projectInfo: { projectList, selectedProjectId },
		plan: { priceType },
	} = thunkAPI.getState();

	const lastTimeStamp = Number(moment(selectedDate).subtract(1, 'm').format('X'));

	const newMoment = moment(selectedDate).add(1, 'm');
	const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
	const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

	try {
		const updatedData = await Promise.all([
			postEUMNetworkStatDataApi({
				tenantUrl,
				organizationId,
				beginTime: lastTimeStamp,
				endTime: Number(moment(selectedDate).add(8, 's').format('X')),
				type: 'M',
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				projectId: selectedProjectId,
				projectKey: selectedProjectKey,
			}),
			postEUMUriStatusApi({
				tenantUrl,
				organizationId,
				timestamp: Number(moment(selectedDate).add(1, 'm').format('X')),
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				projectId: selectedProjectId,
				projectKey: selectedProjectKey,
			}),
			postEUMSummaryApi({
				tenantUrl,
				organizationId,
				timestamp: Number(moment(selectedDate).add(1, 'm').format('X')),
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				projectId: selectedProjectId,
				projectKey: selectedProjectKey,
			}),
		]);

		const newSelectedDate = newMoment.valueOf();

		thunkAPI.dispatch(setInitialToast());

		return {
			networkStatData: updatedData[0].data,
			uriStatusData: updatedData[1].data,
			summaryData: updatedData[2].data,
			newSelectedDate,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

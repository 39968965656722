import axios, { AxiosError } from 'axios';
import { COGNITO_TOKEN, ONPREM_EUM_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import { PostEUMSummaryApiResponse } from 'features/nfEUM/types/postEUMSummary.type';

export interface PostEUMSummaryApiProps {
	tenantUrl: string;
	organizationId: number;
	timestamp: number;
	mainDomain: string | null;
	projectId: number;
	projectKey: string;
}

export default async function postEUMSummaryApi({
	tenantUrl,
	organizationId,
	timestamp,
	mainDomain,
	projectId,
	projectKey,
}: PostEUMSummaryApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);
	const baseUrl =
		process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
			? getOnPremBaseUrl(ONPREM_EUM_API_PATH)
			: `https://${tenantUrl.slice(0, -5)}:9091/eum`;
	const body = { timestamp, mainDomain, projectId, projectKey };

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.post<PostEUMSummaryApiResponse>(`${baseUrl}/select/summary/m_surffy_eum_log`, body, {
			headers: {
				Authorization: cognitoToken,
				userKey,
				organizationId,
			},
		});
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/**
 * description: 파일 업로드 유효성 체크 함수
 */

export interface CheckFileValidationProps {
	fileName: string;
	fileSize: number;
	acceptableFileExtensions?: string[];
	maxFileSize?: number;
}

export const checkFileValidation = ({
	fileName,
	fileSize,
	acceptableFileExtensions = ['jpg', 'jpeg', 'png'],
	maxFileSize = 10 * 1024 * 1024,
}: CheckFileValidationProps) => {
	const splitedFileName = fileName.split('.');

	const fileExtension = splitedFileName[splitedFileName.length - 1].toLowerCase();
	const isFileTypeValid = acceptableFileExtensions.includes(fileExtension);
	const isFileSizeValid = maxFileSize > fileSize;

	return { isFileSizeValid, isFileTypeValid };
};

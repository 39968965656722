import axios, { AxiosError } from 'axios';
import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { GetTenantHealthCheckListApiResponse } from 'common/types/getTenantHealthCheckList.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

interface GetTenantHealthCheckListApiProps {
	tenantId: string;
	organizationId: number;
}

export default async function getTenantHealthCheckApi({ tenantId, organizationId }: GetTenantHealthCheckListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetTenantHealthCheckListApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/tenant/health-check`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsPaymentErrorModalVisible } from 'common/context/modalSlice';

export default function PaymentErrorModal() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const { isPaymentErrorModalVisible, paymentErrorModalBody, paymentErrorModalTitle } = useSelector(
		(state: RootState) => state.modal
	);

	const primaryButtonClickHandler = () => {
		dispatch(toggleIsPaymentErrorModalVisible({}));
	};

	return (
		<Portal>
			<Modal
				visible={isPaymentErrorModalVisible}
				title={paymentErrorModalTitle}
				primaryBtnLabel={t('modal.ok')}
				primaryBtnFunction={primaryButtonClickHandler}
				isCloseIconVisible={false}
				actionType="single"
				overlayColor="transparent"
			>
				<div css={bodySt}>{paymentErrorModalBody}</div>
			</Modal>
		</Portal>
	);
}

const bodySt = css`
	white-space: pre;

	line-height: 160%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { WarningIcon, paletteSDS } from 'surf-design-system';

export default function AskModalErrorGuide() {
	const { t } = useTranslation();

	return (
		<div css={errSt}>
			<div>
				<WarningIcon fill={paletteSDS.red[500]} width="24px" height="24px" />
			</div>
			<div>{t('modal.askModalErrorMsg')}</div>
		</div>
	);
}

const errSt = css`
	display: flex;
	gap: 0.5rem;
	border: 1px solid ${paletteSDS.red[200]};
	border-radius: 8px;
	width: 20.75rem;
	height: 4.25rem;
	padding: 0.75rem 1rem;
	background-color: ${paletteSDS.red[50]};
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 160%;
	white-space: pre-wrap;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { MemberData } from 'features/setting/types/getMemberList.onPrem.type';

import { getMemberListThunk } from 'features/setting/thunk/getMemberList.v2.onPrem.thunk';
import { putMemberPermissionThunk } from 'features/setting/thunk/putMemberPermission.v2.onPrem.thunk';

interface MemberSliceData {
	searchValue?: string;
	memberStatus: string;
	members: MemberData[];
	isMembersLoaded: boolean;
	isLoading: boolean;
	// eslint-disable-next-line
	error: any;
}

const initialState: MemberSliceData = {
	searchValue: undefined,
	memberStatus: 'all',
	members: [],
	isMembersLoaded: false,
	error: null,
	isLoading: false,
};

const memberSlice = createSlice({
	name: 'onPremMember',
	initialState,
	reducers: {
		setSearchValue: (state, action: PayloadAction<string>) => {
			return { ...state, searchValue: action.payload };
		},
		resetSearchValue: state => {
			return { ...state, searchValue: undefined };
		},
		setMemberStatus: (state, action: PayloadAction<string>) => {
			return { ...state, memberStatus: action.payload };
		},
	},
	extraReducers(builder) {
		builder.addCase(getMemberListThunk.fulfilled, (state, action) => {
			return {
				...state,
				members: action.payload.members.slice(),
				isMembersLoaded: true,
				error: null,
			};
		});

		builder.addCase(getMemberListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(putMemberPermissionThunk.pending, state => {
			return { ...state, isLoading: true };
		});

		builder.addCase(putMemberPermissionThunk.fulfilled, (state, action) => {
			const newMembers = state.members.map(member => {
				if (member.key === action.payload.key) {
					return { ...action.payload };
				}
				return member;
			});

			return {
				...state,
				isLoading: false,
				members: newMembers,
			};
		});

		builder.addCase(putMemberPermissionThunk.rejected, state => {
			return { ...state, isLoading: false };
		});
	},
});

export const { setMemberStatus, setSearchValue, resetSearchValue } = memberSlice.actions;

export default memberSlice.reducer;

import { SegValue } from 'features/nfBasic/types/postSegmentStatData.type';

interface SumSegValue {
	totalTickets: number;
	totalInflow: number;
	totalOutflow: number;
	nfBlock: number;
	byPass: number;
}

export function setSumStatData(segValue: SegValue[]): SumSegValue {
	// 3초 병합하는 stat data
	const statData: SumSegValue = {
		totalTickets: 0,
		totalInflow: 0,
		totalOutflow: 0,
		nfBlock: 0,
		byPass: 0,
	};

	segValue.forEach((data: SegValue) => {
		statData.totalTickets += data.totalTickets;
		statData.totalInflow += data.totalInflow;
		statData.totalOutflow += data.totalOutflow;
		statData.nfBlock += data.nfBlock;
		statData.byPass += data.byPass;
	});
	return statData;
}

/* Copyright(C) 2020 STCLab All rights reserved
 *  @file   Funnel.js
 *  @short  모니터링 페이지 - Funnel(깔대기) 라이브러리
 *  		HTML5 Canvas Netfunnel Expression
 *  @author 차창용 ( cycha@stclab.com )
 * */


// --------------------------------------------------------
// DEFINE
// --------------------------------------------------------
// var FunnelImgWait 	=  new Image(); FunnelImgWait.src  	=  "/img/admin/Funnel/netfunnel_backimage3.png";
// var FunnelImgRunSize 	=  new Image(); FunnelImgRunSize.src	=  "/img/admin/Funnel/netfunnel_backimage2.png";
// var FunnelImgStaticBG 	=  new Image(); FunnelImgStaticBG.src	=  "/img/admin/Funnel/act_static_bg_top.png";
// var '' 	=  new Image(); ''.src	=  staticBGBase64;
// var null	=  new Image(); null.src	=  "/img/admin/Funnel/act_dynamic_bg_top.png";
// var FunnelImgWaveHigh 	=  new Image(); FunnelImgWaveHigh.src	=  "/img/admin/Funnel/WaveHigh.gif";
// var FunnelImgWaveMid 	=  new Image(); FunnelImgWaveMid.src	=  "/img/admin/Funnel/WaveMid.gif";
// var FunnelImgWaveLow 	=  new Image(); FunnelImgWaveLow.src	=  "/img/admin/Funnel/WaveLow.gif";
// var FunnelImgByPass	=  new Image(); FunnelImgByPass.src	=  "/img/admin/Funnel/bypass.png";
// var FunnelImgBlock 	=  new Image(); FunnelImgBlock.src	=  "/img/admin/Funnel/block.png";
// var FunnelImgByPassBG	=  new Image(); FunnelImgByPassBG.src	=  "/img/admin/Funnel/bypass_bg.png";
// var FunnelImgBlockBG 	=  new Image(); FunnelImgBlockBG.src	=  "/img/admin/Funnel/block_bg.png";
// var FunnelImgByPassICBG	=  new Image(); FunnelImgByPassICBG.src	=  "/img/admin/Funnel/bypass_ic.png";
// var FunnelImgBlockICBG 	=  new Image(); FunnelImgBlockICBG.src	=  "/img/admin/Funnel/block_ic.png";
// var FunnelImgServiceBG	=  new Image(); FunnelImgServiceBG.src	=  "/img/service_apm/service_funnel_bg.png";
// var FunnelImgServiceBGTP=  new Image(); FunnelImgServiceBGTP.src=  "/img/service_apm/service_funnel_bg_tp.png";

export const WAIT_AREA_MAP_SPEED_HEIGHT_SEGMENT ={
	'FAST': 155,
	'MEDIUM': 125,
	'SLOW': 100
}

export const WAIT_AREA_MAP_SPEED_HEIGHT_PROJECT ={
	'FAST': 55,
	'MEDIUM': 25,
	'SLOW': -25
}


const humanReadableNumber = function (text,fix) { //text =input
	if(typeof fix == 'undefined') var fix=1;
    if (!isNaN(Number(text))) {	// check is number.
        var uv = 0;
        var byteUnits = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
        var numBytext = parseFloat(text);

        while (numBytext > 1000) {
            numBytext /= 1000;
            uv++;
        };
        if(fix== 0){
            if(uv >= 1){
        		return String(numBytext.toFixed(1)+byteUnits[uv]);
            }
        	return String(parseInt(numBytext)+byteUnits[uv]);
        }
		if (numBytext === 0) {
			return String(numBytext+byteUnits[uv]);
		} else {
			return String(numBytext.toFixed(fix)+byteUnits[uv]);
		}
    }else{
		console.log("Input value is not a number.");
		return text;
	}
	return false;
};


//----------------------------------------------------------
// Funnel Config
//----------------------------------------------------------
var FunnelConfig = function()
{
	this.init = function(config){
		const speedStatus = config && config.speedStatus;
		const wait_area_height =( speedStatus && WAIT_AREA_MAP_SPEED_HEIGHT_SEGMENT[speedStatus] )|| 100
		
		// 캔버스 태그 설정
    this.canvas = {
		height : 358,
        width  : 90,
        bgimage_back:null,
        bgimage_top:{
					// 프레임 그리지 않기 위해서 비워둠
            static:null,
            dynamic:null,
        },
        block:null,
        blockbg:null,
        blockicbg:null,
        bypass:null,
        bypassbg:null,
        bypassicbg:null,
        warncanvas :{
        	x : 5 ,
        	y : 160 ,
        	width : 144 ,
        	height : 36 ,
        }
    };


		// 상단 - 최초 진입 물방울 영역
    this.wait_drop = {
			start_x : {min:5 ,max:85, step:10},
			start_y : 0,
			move    : 5,
			radiac  : 3,
			end_y   : 90,
			bypass_end_y : 270 ,
			block_end_y : 100 ,
			angle : 90,
			speed : 5 ,
			radians : 0 ,
			style   : "#0089D6",
			dropsize: 0.3,
			radius  : 3,
			dropsize_max: 0.6,
			radius_max  : 6,
			block_style : "#D91F29",
			bypass_style : "#747474",
			bypass_start_x : -2000 ,
			slide   : {
					start_y:60,
					end_y:90,
					area_x:{
							start:30,
							end:60
					}
			}
    };

		// 상단 - 쌓이는 물 영역 + 스플래시 설정
    this.wait_area = {
			start_x : 0,
			start_y : -6,
			height  : wait_area_height,
			width   : 90,
			interval: 8,
			interval_back: 0,
			min_height: 1,
			style:{
				// 쌓이는 물 색상
					normal:"transparent",
					warn:"transparent",
			},
			gradient:{
					style:"rgba(255,255,255,0)",
					// style:"rgba(255,255,255,0)",
					max_wait_tm:120,
					max_y:120
			},
			splash_pos:[
					[ [0,0 ],[-1,0],[1,0] ],
					[ [0,-1 ],[-2,-1],[3,-1] ],
					[ [0,-1 ],[-2,-1],[3,-1] ],
					[ [0,-2 ],[-3,-2],[3,-2] ],
					[ [0,-3 ],[-7,-4],[7,-4] ],
					[ [0,-3 ],[-7,-4],[7,-4] ],
					[ [0,-4 ],[-8,-6],[8,-6] ],
					[ [0,-5 ],[-9,-6],[9,-6] ],
					[ [0,-5 ],[-9,-8],[9,-8] ],
					[ [0,-6 ],[-13,-6],[13,-6] ],
					[ [0,-4 ],[-16,-6],[13,-6] ],
					[ [0,-1 ],[-17,-4],[15,-4] ]
			],
			splash_max:10
		};

		// 중단 - 진입량 물방울
		this.entry_drop = {
			start_x : {min:28 ,max:60, step:5},
			start_y : 100, //160
			end_y   : 275,
			move    : 5,
			radiac  : 3,
			dropsize: 0.32,
			radius  : 3,
			dropsize_max: 0.6,
			radius_max  : 6,
			style:"#0089D6",
			slide   : {
					start_y:130,
					end_y:150,
					area_x:{
							start:40,
							end:50
					}
			}
	};


	// 중단 - 탱크에 %로 물차는 부부분(사용안함)
	this.wait_area_succ = {
		start_x : 28 ,
		start_y : 150 ,
		height  : 0 ,
		width   : 100,
		interval: 5,
		min_height: 3,
		style_move:"transparent",
		style_fix:"transparent",
		style_begin_color:"rgba(0,0,0,0)",
		style_end_color:"#CE3636", //CC181E
		style_alpha_color:"1",
		style_min_num:40,
		style_max_num:100,
		gradient:{
				style:"#CE3636",
				max_wait_tm:120,
				max_y:120
		}
};

// 하단 - 완료처리량
this.complete_area = {
	start_x : {min:35 ,max:55, step:5},
	start_y : {min:245 ,max:255, step:6},
	move    : 5,
	drop_start : 280 , // 가상공간에서 생성된 물방울들이 실제로 화면에 보이는 y축;
	end_y   : 360,
	speed   : 20,
	time    : 3 ,
	style   : "#0089D6",
	complete_divide : 1 ,
	complete_gap : 9,
	complete_max : 300
};

this.drop = {
	running_time:2000,      // 지정범위를 통과하는데 걸리는 시간 (Millisecond)
	tps_basis:2000,         // 신규 생성 물방울의 tps 계산 기준시간 (Millisecond)
	tps_max:50,   	// 최소 물방울 사이즈 tps기준
	tps_hard_max:300  // 최대 물방울 사이즈 tps기준
}

	
	
	// 숫자 표시 사이즈 설정(사용안함)
	this.entry_area  = {
		text_limit :150 ,
		text_limit_max :150,
		text_limit_max_yt : 225,
		text_limit_yt : 352,
		style:"#87cefa"
};

	// 처리시간 분포도 설정(사용안함)
    this.xview = {
		start_x:29,
		start_y:400,
		col_num:10,
		row_num:6,
		width:100,
		height:30,
		color:["#87CEFA" ,"#62B4E8" ,"#4E9AFB", "#4E6CFB" , "#704EFB" , "#8D4EFB" , "#B74EFB" ,"#DD4EFB" , "#FB4ED9" , "#FB4EA4" ] ,
		radius_min:2,
		radius_max:7,
		radius_factor:2
	};

    this.text = {
		// 신규진입량(tps)
		new_ck_enter:{
			pos:{x:127, y:15,pos:"topright"},
			font:{size:11,color:'rgb(255, 255, 255)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(21, 21, 21,0.8)'
		},
		// 대기자수(명)
		wait_user:{
			pos:{x:127, y:35,pos:"topright"},
			font:{size:11,color:'rgb(255, 255, 255)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(21, 21, 21,0.8)'
		},
		// 대기시간(초)
		wait_time:{
			pos:{x:7, y:150,pos:"topleft"},
			font:{size:11,color:'rgb(255, 255, 255)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(21, 21, 21,0)'
		},
		// 진입허용수 최대값
		limit_max:{
			pos:{x:147, y:225,pos:"topright"},
			font:{color:'rgb(255, 255, 255)'},
			background:'rgba(255, 255, 255,0)'
		},
		// 해당 Service/Action 의 진입허용수
		limit:{
			pos:{x:147, y:0, ymax:352 ,pos:"topright"},
			font:{color:'rgb(255, 255, 255)'},
			background:'rgba(255, 255, 255,0)'
		},
		// 사용량(진입허용수 중 사용하고 있는 수)
		used_size:{
			pos:{x:123, y:30,pos:"topright"},
			font:{color:'rgb(255, 255, 255)'},
			background:'rgba(255, 255, 255,0)'
		},
		// 사용량(진입허용수 중 사용하고 있는 수)
		used_size_num:{
			pos:{x:95, y:30,pos:"topright"},
			font:{color:'rgb(255, 255, 255)'},
			background:'rgba(255, 255, 255,0)'
		},

		used_size_num_per:{
			pos:{x:90, y:30,pos:"topright"},
			font:{color:'rgb(255, 255, 255)'},
			background:'rgba(255, 255, 255,0)'
		},

		used_size_num_zero:{
			pos:{x:100, y:30,pos:"topright"},
			font:{color:'rgb(255, 255, 255)'},
			background:'rgba(255, 255, 255,0)'
		},
		// 진입허용수 (tps)
		chk_enter_succ:{
//			pos:{x:103, y:175,pos:"topleft"},
			pos:{x:147, y:175,pos:"topright"},
			font:{size:11,color:'rgb(255, 255, 255)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(255, 255, 255,0)'
		},
		// 완료수 (tps)
		complete_succ:{
//			pos:{x:103, y:380 ,pos:"topleft"},
			pos:{x:147, y:380 ,pos:"topright"},
			font:{size:11,color:'rgb(255, 255, 255)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(255, 255, 255,0)'
		},
		// byPass
		bypass:{
			pos:{x:127, y:15,pos:"topright"},
			font:{size:11,color:'rgb(140, 140, 140)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(21, 21, 21,0.8)'
		},
		// block
		block:{
			pos:{x:127, y:15,pos:"topright"},
			font:{size:11,color:'rgb(255, 0, 0)'},
			unit_font:{size:8,color:'#81C3EC'},
			background:'rgba(21, 21, 21,0.8)'
		},
	}

    this.draw_interval = 70; // 캔버스 프레임
    this.draw_warn_interval = 500;
    this.type = "action";
   }
};

var ActionConfig = function(config){
	this.init(config);
};
ActionConfig.prototype = new FunnelConfig();

var ServiceConfig = function(config){
	this.init(config);

	this.canvas.width = 106;
  this.canvas.height = 447;
    // this.canvas.bgimage_back = FunnelImgServiceBG;
    // this.canvas.bgimage_top = FunnelImgServiceBGTP;
  this.canvas.warncanvas = {
    	x : 77 ,
    	y : 210 ,
    	width : 0 ,
    	height : 0
  };
		
	// 상단 - 최초 진입 물방울 영역
	this.wait_drop.start_x = { min:5, max:102, step:10 }
	this.wait_drop.end_y = 108;
	this.wait_drop.slide = {
			start_y:70,
			end_y:this.wait_drop.end_y,
			area_x:{ start:30, end:80 }
	};
	this.wait_drop.bypass_end_y = 366 ;
	this.wait_drop.bypass_start_x = 150;

	// 상단 - 쌓이는 물 영역 + 스플래시 설정
		const speedStatus = config && config.speedStatus;
		const wait_area_height =( speedStatus && WAIT_AREA_MAP_SPEED_HEIGHT_PROJECT[speedStatus] )|| 100

	this.wait_area.start_x = 10;
	this.wait_area.start_y = 60;
	this.wait_area.height = wait_area_height;
	this.wait_area.width = 0;

	// 중단 - 진입량 물방울
	this.entry_drop.start_x = { min:28, max:79, step:10 };
	this.entry_drop.start_y = 120;
	this.entry_drop.end_y = 366;
	this.entry_drop.slide = {
			start_y:150,
			end_y:190,
			area_x:{ start:48, end:58 }
	};

	// 중단 - 탱크에 %로 물차는 부부분(사용안함)
	this.wait_area_succ.start_x = 60;
	this.wait_area_succ.start_y = 326;
	this.wait_area_succ.height = 154;
	this.wait_area_succ.width = 218;
	this.wait_area_succ.min_height = 3;
	this.wait_area_succ.interval = 8;

	// 하단 - 완료처리량
	this.complete_area.start_x = { min:35, max:70, step:0 };
	this.complete_area.start_y = { min:340, max:350, step:10 };
	this.complete_area.drop_start =370;
	this.complete_area.end_y = 470;
	this.complete_area.complete_divide  = 10;
	this.complete_area.complete_gap = 10 ;

// 처리시간 분포도 설정(사용안함)
	this.xview.start_x = 81;
	this.xview.start_y = 690;
	this.xview.width = 140;
	this.xview.height = 60;


	// Text
	this.text.new_ck_enter.pos = { x:230, y:20, pos:"topleft" };
	this.text.new_ck_enter.background ='rgba(255, 255, 255,0)';
	this.text.wait_user.pos = { x:230, y:40, pos:"topleft" };
	this.text.wait_user.background = 'rgba(255, 255, 255,0)';
	this.text.wait_time.pos = { x:90, y:190, pos:"topright" };
	this.text.wait_time.background = 'rgba(255, 255, 255,0)';
	this.text.chk_enter_succ.pos = { x:190, y:240, pos:"topleft" };
	this.text.complete_succ.pos = { x:110, y:510, pos:"topright" };
	this.text.limit.pos = { x:225, y:0, ymax:470, pos:"topleft" };
	this.text.limit_max.pos = { x:225, y:299, ymax:300, pos:"topleft" };
	this.text.used_size.pos = { x:210, y:0, pos:"topright" };
	this.text.used_size_num.pos = { x:182, y:0, pos:"topright" };
	this.text.used_size_num_per.pos = { x:177, y:0, pos:"topright" };
	this.text.used_size_num_zero.pos = { x:187, y:0, pos:"topright" };
	this.text.block.pos = { x:230, y:60, pos:"topleft" };
	this.text.block.background = 'rgba(255, 255, 255,0)';
	this.text.bypass.pos = { x:230, y:80, pos:"topleft" };
	this.text.bypass.background = 'rgba(255, 255, 255,0)';

    this.type = "service";
};
ServiceConfig.prototype = new FunnelConfig();


//----------------------------------------------------------
// Funnel Util
//----------------------------------------------------------
var FunnelUtil = {
    random_seed:0,
    random_area:10,
    random:function(min,max,area){
        FunnelUtil.random_seed = (FunnelUtil.random_seed + 3)%20;
        if(max < min){
            var tval = max;
            max = min;
            min = tval;
        }

        if(area == undefined) area = FunnelUtil.random_area;
        if(area < 1) area = 1;

        var t_min   = min;
        var t_max   = max;
        var dt      = new Date();
        var ms      = dt.getMilliseconds();
        var tms     = ms*FunnelUtil.random_seed+FunnelUtil.random_seed;
        var gap     = t_max - t_min;
        var start_area = (FunnelUtil.random_seed+ms)%area;
        var start_pos = (gap/area)*start_area;
        while(tms < 100){
            if(tms < 10) tms += 10;
            tms = tms * 2;
        }

        var area_size = gap/area;
        return t_min + start_pos + area_size * Math.random();
	},

	/**
	 * Draw Area
	 */
	draw_area:function(context,left,top,width,height,style,usewave){
		if(!context) return;


	}
}

//----------------------------------------------------------
// DropRenderer
//----------------------------------------------------------
var DropRenderer = {
	simple:function(context,left,top,radius,style,alpha){
		context.save();
		if(alpha != undefined){
			context.globalAlpha = alpha;
		}
		context.translate(left, top);
		context.beginPath();
	 	context.arc(0, 0, radius, 0, Math.PI*2, true);
		context.closePath();
		context.fillStyle = style;
		context.fill();
		context.restore();
	},
	splash:function(context,left,top,radius,style,alpha){
		context.save();
		if(alpha != undefined){
			context.globalAlpha = alpha;
		}
		context.translate(left, top);

		// arc 1
		context.beginPath();
	 	context.arc(0, -1*radius*2, 0.5, 0, Math.PI*2, true);
		context.closePath();
		context.fillStyle = style;
		context.fill();

		// arc 2
		context.beginPath();
	 	context.arc(-4, -1*radius*2+2, 0.5, 0, Math.PI*2, true);
		context.fillStyle = style;
		context.fill();

		// arc 2
		context.beginPath();
	 	context.arc(4, -1*radius*2+2, 0.6, 0, Math.PI*2, true);
		context.closePath();
		context.fillStyle = style;
		context.fill();

		context.restore();
	},
	raindrop:function(context,left,top,bf,style){
		context.save();
		context.globalAlpha = 0.5;
		context.fillStyle = style;
		context.translate(left, top);
		context.beginPath();
		context.moveTo(0,0);
		context.bezierCurveTo(0*bf,5*bf,0*bf,10*bf,5*bf,15*bf);
		context.bezierCurveTo(10*bf,20*bf,12*bf,26*bf,10*bf,30*bf);
		context.bezierCurveTo(6*bf,40*bf,-6*bf,40*bf,-10*bf,30*bf);
		context.bezierCurveTo(-12*bf,26*bf,-10*bf,20*bf,-5*bf,15*bf);
		context.bezierCurveTo(0*bf,10*bf,0*bf,5*bf,0*bf,0*bf);
		context.closePath();
		context.fill();
		context.restore();
	}
};

////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////완료량 물방울 ////////////////////////////////////////////////////////
var CompleteDrop = function(){

};
CompleteDrop.prototype ={
		_context : null,
		_config : null,
		_xpos : 0,
		_ypos : 0,
		_end_ypos : 0 ,
		_end_xpos : 0 ,
		_drop_speed : 0 ,
		_drop_speed_real :0 ,
		_start_xpos : 0,
		_start_ypos : 0,
		_move_xpos  : 0,
		_move_ypos  : 0,
		_drop_time  : 0.01 ,
		_del_flag   : false,
		_seed_x : 0,
		_col_index:0,
		_area:{
			left:{ width:0, height:0 },
			right:{ width:0, height:0 }
		},

		setData:function(context , config   ,drop_speed  , seed_x , seed_y,col_index){

			this._context 		= context;
			this._config		= config;
			this._col_index		= col_index;
			this._seed_x		=	seed_x;
			if(this._col_index > 9 ){
				this._col_index = 9;
			}

			if(	this._seed_x > 9 ){
				this._seed_x = 9;
			}
		//	this._xpos 			= FunnelUtil.random(config.start_x.min,config.start_x.max,2,this._seed_x);
		//	this._ypos 			= FunnelUtil.random(config.start_y.min,config.start_y.max,2,seed_y);
			this._xpos 			= FunnelUtil.random(config.start_x.min,config.start_x.max,2,config.start_x.step);
			this._ypos 			= FunnelUtil.random(config.start_y.min,config.start_y.max,2,config.start_y.step);
			this._end_ypos		= config.end_y;
			this._end_xpos		= (this._seed_x*config.complete_gap)+config.complete_divide;

			this._move_xpos		=	this._xpos;
			this._move_ypos		=	this._ypos;

			this._drop_speed_real 	= drop_speed;
		},
		_nextPos : function(){
			if(this._del_flag == true) return null;

				this._move_xpos_part_first  = this._move_xpos;
				this._move_xpos_part_second = this._move_xpos_part_first;
				this._move_xpos_result 		= this._end_xpos;
				this._move_ypos_part_first  = this._move_ypos+60;
				this._move_ypos_part_second = this._move_ypos_part_first+60;
				this._move_ypos_result		= this._end_ypos;

				var cx = 3 * (this._move_xpos_part_first   - this._move_xpos)
				var bx = 3 * (this._move_xpos_part_second  - this._move_xpos_part_first) - cx;
				var ax = this._move_xpos_result  - this._move_xpos - cx - bx;

				var cy = 3 * (this._move_ypos_part_first   - this._move_ypos);
				var by = 3 * (this._move_ypos_part_second  - this._move_ypos_part_first ) - cy;
				var ay = this._move_ypos_result - this._move_ypos- cy - by;

				var p_xpos = ax*(this._drop_speed*this._drop_speed*this._drop_speed) + bx*(this._drop_speed*this._drop_speed)
						   + cx*this._drop_speed + this._move_xpos;
				var p_ypos = ay*(this._drop_speed*this._drop_speed*this._drop_speed) + by*(this._drop_speed*this._drop_speed)
						   + cy*this._drop_speed + this._move_ypos;

			    this._drop_speed += this._drop_speed_real;

			    if(this._drop_speed > 1){
			    	this._drop_speed = 1;
			    }
			    this._start_xpos = p_xpos;
				this._start_ypos = p_ypos;

			if(this._start_ypos >= this._end_ypos){
				this._del_flag = true;
			}else{
				if(p_ypos > this._end_ypos - this._drop_speed){
					p_ypos = this._end_ypos;
				}else{
					p_xpos  = p_xpos;
					p_ypos += this._drop_speed;
				}
			}
			return [ p_xpos, p_ypos];
		},

		getCurrPos:function(){
			return { left:this._start_xpos, top:this._start_ypos };
		},
		getColIndex:function(){
			return this._col_index;
		},

		/**
		 * Drop Render
		 */
		render:function(splash){
			// Draw
			var curr_pos = this.getCurrPos();
			if( curr_pos.top < this._config.drop_start) {
				// 가상공간
				DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,0,this._config.style,1);
			}else{
				// 실제 나오는 공간
				// (curr_pos.left, curr_pos.top);
				DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._config.radius,this._config.style,1);
			}
			if(this._nextPos() == null){
				return false;
			}
			return true;
		}
}
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////진입량 물방울 //////////////////////////////////////////////////////
var EntryDrop = function(){
	this._context = null;
	this._config = null;
	this._radius = 3;
	this._dropsize = 0.3;
	this._xpos = 0;
	this._drop_speed = 0;
	this._curr_ypos	= 0;
	this._del_flag = false;
	this._area={
		left:{ width:0, height:0 },
		right:{ width:0, height:0 }
	};
};
EntryDrop.prototype = {

	_nextPos:function(){
		if(this._del_flag == true) return null;

		var p_xpos = this._xpos;
		var p_ypos = this._curr_ypos;

		if(this._curr_ypos >= this._config.end_y){
			this._del_flag = true;
		}else{
			if(this._curr_ypos + this._drop_speed > this._config.end_y){
				this._curr_ypos = this._config.end_y;
			}else{
				this._curr_ypos += this._drop_speed;
			}

			// is Curve Area ??
			if(this._curr_ypos > this._config.slide.start_y && this._curr_ypos < this._config.slide.end_y){
				if( p_xpos < this._config.slide.area_x.start ){
					var gap_width = this._xpos - this._config.start_x.min;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.left.height) / this._area.left.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.left.width) / this._area.left.height);
						this._xpos += add;
					}

				}else if( p_xpos > this._config.slide.area_x.end ){
					var gap_width = this._config.start_x.max - this._xpos;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.right.height) / this._area.right.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.right.width) / this._area.right.height);
						this._xpos -= add;
					}
				}
			}
		}
		if(this._xpos == 0){
			//console.log("xpos="+this._xpos+", ypos="+this.ypos);
		}

		return [ p_xpos, p_ypos ];
	},
	setData:function(context,config,drop_speed,radius,dropsize ){

		this._context 		= context;
		this._config		= config;
		this._radius		= radius;
		this._dropsize		= dropsize;
		this._curr_ypos		= config.start_y;
		this._xpos 			= FunnelUtil.random(config.start_x.min,config.start_x.max,config.start_x.step);
		this._drop_speed 	= drop_speed;

		this._area.left.width 	= this._config.slide.area_x.start - this._config.start_x.min;
		this._area.left.height 	= this._config.slide.end_y - this._config.slide.start_y;
		this._area.right.width 	= this._config.start_x.max - this._config.slide.area_x.end;
		this._area.right.height = this._area.left.height;
	},

	getCurrPos:function(){
		return { left:this._xpos, top:this._curr_ypos };
	},

	/**
		* Drop Render
		*/
	render:function(splash){
		// Draw
		var curr_pos = this.getCurrPos();
		if (this._config.rain_drop_top == undefined) this._config.rain_drop_top = 12;

		if(curr_pos.top > this._config.start_y && curr_pos.top < this._config.start_y + this._config.rain_drop_top ) { //12
			// RainDrop
			DropRenderer.raindrop(this._context,curr_pos.left,curr_pos.top-5,this._dropsize,this._config.style);
		}else if(curr_pos.top == this._config.end_y){
			// Disappear
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style,0.3);
		}else{
			// Simple Drop
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style);
		}
		if(splash){
			DropRenderer.splash(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style);
		}

		// Update
		if(this._nextPos() == null){
			return false;
		}
		return true;
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// 신규량 물방울 /////////////////////////////////////////////////
var WaitDrop = function(){

};
WaitDrop.prototype = {
	_context : null,
	_config : null,
	_radius : 3,
	_dropsize : 0.3,
	_xpos : 0,
	_curr_ypos  : 0,
	_drop_speed : 0,
	_del_flag : false,
	_area:{
		left:{ width:0, height:0 },
		right:{ width:0, height:0 }
	},

	_nextPos:function(){
		if(this._del_flag == true) return null;

		var p_xpos = this._xpos;
		var p_ypos = this._curr_ypos;

		if(this._curr_ypos >= this._config.end_y){
			this._del_flag = true;
		}else{
			if(this._curr_ypos + this._drop_speed > this._config.end_y){
				this._curr_ypos = this._config.end_y;
			}else{
				this._curr_ypos += this._drop_speed;
			}

			// is Curve Area ??
			if(this._curr_ypos > this._config.slide.start_y && this._curr_ypos < this._config.slide.end_y){
				if( p_xpos < this._config.slide.area_x.start ){
					var gap_width = this._xpos - this._config.start_x.min;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.left.height) / this._area.left.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.left.width) / this._area.left.height);
						this._xpos += add;
					}

				}else if( p_xpos > this._config.slide.area_x.end ){
					var gap_width = this._config.start_x.max - this._xpos;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.right.height) / this._area.right.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.right.width) / this._area.right.height);
						this._xpos -= add;
					}
				}
			}
		}
		return [ p_xpos, p_ypos ];
	},
	setData:function(context,config,drop_speed,radius,dropsize){

		this._context 		= context;
		this._config		= config;
		this._radius		= radius;
		this._dropsize		= dropsize;
		this._xpos 		= FunnelUtil.random(config.start_x.min,config.start_x.max,config.start_x.step);
		this._drop_speed 	= drop_speed;

		this._area.left.width 	= this._config.slide.area_x.start - this._config.start_x.min;
		this._area.left.height 	= this._config.slide.end_y - this._config.slide.start_y;
		this._area.right.width 	= this._config.start_x.max - this._config.slide.area_x.end;
		this._area.right.height = this._area.left.height;
	},

	getCurrPos:function(){
		return { left:this._xpos, top:this._curr_ypos };
	},

	getSpeed:function(){
		return this._drop_speed;
	},

	/**
	 * Drop Render
	 */
	render:function(){
		// Draw
		var curr_pos = this.getCurrPos();
		if (this._config.rain_drop_top == undefined) this._config.rain_drop_top = 12;
		if(curr_pos.top > 0 && curr_pos.top < this._config.rain_drop_top) {
			// RainDrop
			DropRenderer.raindrop(this._context,curr_pos.left,curr_pos.top-5,this._dropsize,this._config.style);
		}else if(curr_pos.top == this._config.end_y){
			// Disappear
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style,0.3);
		}else{
			// Simple Drop
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style);
		}

		// Update
		if(this._nextPos() == null){
			return false;
		}
		return true;
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// Bypass 물방울 /////////////////////////////////////////////////
var BypassWaitDrop = function(){

};
BypassWaitDrop.prototype = {
	_context : null,
	_config : null,
	_entryconfig : null,
	_xpos : 0,
	_entryxpos :0 ,
	_curr_ypos  : 0,
	_drop_speed : 0,
	_del_flag : false,
	_area:{
		left:{ width:0, height:0 },
		right:{ width:0, height:0 }
	},
	_areabypass:{
		left:{ width:0, height:0 },
		right:{ width:0, height:0 }
	},

	_nextPos:function(){
		if(this._del_flag == true) return null;

		var p_xpos = this._xpos;
		var p_ypos = this._curr_ypos;

		if(this._curr_ypos >= this._config.bypass_end_y){
			this._del_flag = true;
		}else{
			if(this._curr_ypos + this._drop_speed > this._config.bypass_end_y){
				this._curr_ypos = this._config.bypass_end_y;
			}else if(this._curr_ypos >= this._config.end_y){
				this._xpos = this._entryxpos;
				this._curr_ypos += this._drop_speed;
			}else{
				this._curr_ypos += this._drop_speed;
			}

			if(this._curr_ypos > this._entryconfig.slide.start_y && this._curr_ypos < this._entryconfig.slide.end_y){
				 if( p_xpos < this._entryconfig.slide.area_x.start ){
					var gap_width = this._entryxpos - this._entryconfig.start_x.min;
					var gap_height = this._curr_ypos - this._entryconfig.slide.start_y;

					var clip_height = (gap_width * this._areabypass.left.height) / this._areabypass.left.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._areabypass.left.width) / this._areabypass.left.height);
						this._entryxpos += add;
					}

				}else if( p_xpos > this._entryconfig.slide.area_x.end ){
					var gap_width = this._entryconfig.start_x.max - this._entryxpos;
					var gap_height = this._curr_ypos - this._entryconfig.slide.start_y;

					var clip_height = (gap_width * this._areabypass.right.height) / this._areabypass.right.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._areabypass.right.width) / this._areabypass.right.height);
						this._entryxpos -= add;
					}
				}
			}else if(this._curr_ypos > this._config.slide.start_y && this._curr_ypos < this._config.slide.end_y){
				if( p_xpos < this._config.slide.area_x.start ){
					var gap_width = this._xpos - this._config.start_x.min;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.left.height) / this._area.left.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.left.width) / this._area.left.height);
						this._xpos += add;
					}

				}else if( p_xpos > this._config.slide.area_x.end ){
					var gap_width = this._config.start_x.max - this._xpos;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.right.height) / this._area.right.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.right.width) / this._area.right.height);
						this._xpos -= add;
					}
				}
			}
		}
		return [ p_xpos, p_ypos ];
	},
	setData:function(context,config,drop_speed , entryconfig,radius,dropsize){

		this._context 		= context;
		this._config		= config;
		this._entryconfig   = entryconfig;
		this._xpos 			= FunnelUtil.random(config.start_x.min,config.start_x.max,config.start_x.step);
		this._entryxpos 	= FunnelUtil.random(entryconfig.start_x.min,entryconfig.start_x.max,entryconfig.start_x.step);
		this._drop_speed 	= drop_speed;
		this._radius		= radius;
		this._dropsize		= dropsize;

		// 신규량 구간
		this._area.left.width 	= this._config.slide.area_x.start - this._config.start_x.min;
		this._area.left.height 	= this._config.slide.end_y - this._config.slide.start_y;
		this._area.right.width 	= this._config.start_x.max - this._config.slide.area_x.end;
		this._area.right.height = this._area.left.height;

		// 진입량 구간
		this._areabypass.left.width 	= this._entryconfig.slide.area_x.start - this._entryconfig.start_x.min;
		this._areabypass.left.height 	= this._entryconfig.slide.end_y - this._entryconfig.slide.start_y;
		this._areabypass.right.width 	= this._entryconfig.start_x.max - this._entryconfig.slide.area_x.end;
		this._areabypass.right.height  = this._areabypass.left.height;

	},

	getCurrPos:function(){
		return { left:this._xpos, top:this._curr_ypos };
	},

	getSpeed:function(){
		return this._drop_speed;
	},

	/**
	 * Drop Render
	 */
	render:function(){
		// Draw
		var curr_pos = this.getCurrPos();
		if (this._config.rain_drop_top == undefined) this._config.rain_drop_top = 12;
		if(curr_pos.top > 0 && curr_pos.top < this._config.rain_drop_top) {
			// RainDrop
			DropRenderer.raindrop(this._context,curr_pos.left,curr_pos.top-5,this._dropsize,this._config.style);
		}else if(curr_pos.top == this._config.end_y){
			// Disappear
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.bypass_style,0.3);
		}else if(curr_pos.top > this._config.end_y && curr_pos.top < this._config.end_y + this._config.rain_drop_top){
			DropRenderer.raindrop(this._context,curr_pos.left,curr_pos.top-5,this._dropsize,this._config.bypass_style);
		}else if(curr_pos.top > this._config.end_y ){
			// Simple Drop
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.bypass_style);
		}else{
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style);
		}

		// Update
		if(this._nextPos() == null){
			return false;
		}
		return true;
	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// Block Wait Drop //////////////////////////////////////////////////////////////////////
var BlockWaitDrop = function(){

};
BlockWaitDrop.prototype = {
	_context : null,
	_config : null,
	_xpos : 0,
	_curr_ypos  : 0,
	_drop_speed : 0,
	_del_flag : false,
	_xunits : 0,
	_yunits : 0,
	_radians : 0 ,
	_radius : 3,
	_dropsize : 0.3,
	_area:{
		left:{ width:0, height:0 },
		right:{ width:0, height:0 }
	},

	_nextPos:function(){
		if(this._del_flag == true) return null;

		var p_xpos = this._xpos;
		var p_ypos = this._curr_ypos;

		if(this._curr_ypos < 0){
			this._del_flag = true;
		}else{

			this._xpos		+= this._xunits;
			this._curr_ypos	+= this._yunits;
				if( this._curr_ypos + this._drop_speed >= this._config.end_y || this._curr_ypos < 0 ){
					var angle = 360 - this._config.angle;
					this.getBlock(angle);
				}

			// is Curve Area ??
			if(this._curr_ypos > this._config.slide.start_y && this._curr_ypos < this._config.slide.end_y){
				if( p_xpos < this._config.slide.area_x.start ){
					var gap_width = this._xpos - this._config.start_x.min;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.left.height) / this._area.left.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.left.width) / this._area.left.height);
						this._xpos += add;
					}

				}else if( p_xpos > this._config.slide.area_x.end ){
					var gap_width = this._config.start_x.max - this._xpos;
					var gap_height = this._curr_ypos - this._config.slide.start_y;

					var clip_height = (gap_width * this._area.right.height) / this._area.right.width;

					if(clip_height < gap_height){
						// adjust
						var gap = gap_height - clip_height;
						var add = Math.ceil((gap * this._area.right.width) / this._area.right.height);
						this._xpos -= add;
					}
				}
			}
		}
		return [ p_xpos, p_ypos ];
	},
	setData:function(context,config,drop_speed,radius,dropsize){

		this._context 		= context;
		this._config		= config;
		this._radius		= radius;
		this._dropsize		= dropsize;

		this._xpos 			= FunnelUtil.random(config.start_x.min,config.start_x.max,config.start_x.step);
		this._drop_speed 	= drop_speed;
		this._radians		= this._config.angle * Math.PI/180;
		this._xunits	=	Math.cos(this._radians)*this._drop_speed;
		this._yunits	=	Math.sin(this._radians)*this._drop_speed;

		this._area.left.width 	= this._config.slide.area_x.start - this._config.start_x.min;
		this._area.left.height 	= this._config.slide.end_y - this._config.slide.start_y;
		this._area.right.width 	= this._config.start_x.max - this._config.slide.area_x.end;
		this._area.right.height = this._area.left.height;


	},

	getCurrPos:function(){
		return { left:this._xpos, top:this._curr_ypos };
	},
	getBlock:function(angle){
		this._radians	=   angle* Math.PI/180;
		this._xunits	=	Math.cos(this._radians)*this._drop_speed;
		this._yunits	=	Math.sin(this._radians)*this._drop_speed;
	},
	getSpeed:function(){
		return this._drop_speed;
	},

	/**
	 * Drop Render
	 */
	render:function(){
		// Draw
		var curr_pos = this.getCurrPos();
		if (this._config.rain_drop_top == undefined) this._config.rain_drop_top = 12;
		if(curr_pos.top > 0 && curr_pos.top < this._config.rain_drop_top && this._yunits > 0 && this._yunits > 0 ) {
			// RainDrop
			DropRenderer.raindrop(this._context,curr_pos.left,curr_pos.top-5,this._dropsize,this._config.style);
		}else if(curr_pos.top == this._config.end_y && this._yunits == 0){
			// Disappear
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style,0.3);
		}else if(this._yunits < 0){
			// Simple Drop
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.block_style);
		}else if (curr_pos.top == this._config.end_y || this._yunits < 0){
			// block
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style,0.3);
		}else{
			// simple Drop
			DropRenderer.simple(this._context,curr_pos.left,curr_pos.top,this._radius,this._config.style);
		}

		// Update
		if(this._nextPos() == null){
			return false;
		}
		return true;
	}
}


//////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// 신규량 물 차오르는 부분 //////////////////////////////////////
var WaitAreaSplash = function(){
	this._context=null;
	this._config=null;
	this._start_xpos=0;
	this._start_ypos=0;
	this._curr_step=0;
	this._del_flag=false;
};
WaitAreaSplash.prototype = {
	_nextPos:function(){
		this._curr_step += 1;
		if(this._curr_step >= this._config.splash_pos.length){
			this._del_flag = true;
			return null;
		}
		return this._curr_step;
	},
	setData:function(context,config,start_xpos,start_ypos){
		this._context 		= context;
		this._config		= config;
		this._start_xpos 	= start_xpos;
		this._start_ypos 	= start_ypos;
	},
	getCurrPos:function(){
		return this._curr_step;
	},
	render:function(wave){
		var t_step_pos = this._config.splash_pos[this.getCurrPos()];
		var alpha = 1 - 0.05*this.getCurrPos();

		// Draw
		this._context.save();

		this._context.translate(this._start_xpos, this._start_ypos);
		this._context.globalAlpha = alpha;
		this._context.fillStyle = "#87cefa";

		for(var i=0;i < t_step_pos.length ; i++){

			this._context.beginPath();
			this._context.arc(t_step_pos[i][0], t_step_pos[i][1], 1.5, 0, Math.PI*2, true);
			this._context.closePath();
			this._context.fill();
		}

		this._context.restore();

		// Update
		if(this._nextPos() == null){
			return false;
		}
		return true;
	}
};

var WaitArea = function(){
	this._context=null;
	this._config=null;
	this._target_div=null;
	this._start_ypos=0;
	this._end_ypos=0;
	this._ypos=0;
	this._splash=[];
	this._wave_image=null;
	this._gradient=null;
	this._draw_end=false;
};

WaitArea.prototype = {
		_nextPos:function(){
			var t_ypos = this._ypos ;
			t_ypos = this._ypos - this._config.interval;
			if(t_ypos < this._end_ypos){
				this._draw_end = true;
				t_ypos = this._end_ypos;
			}

			this._ypos = t_ypos;

			return this._ypos;
		},

		_nextPosBack:function(){
			var t_ypos = this._ypos ;
			t_ypos = this._ypos + this._config.interval;
			if(t_ypos > this._end_ypos){
				this._draw_end = true;
				t_ypos = this._end_ypos;
			}

			this._ypos = t_ypos;

			return this._ypos;
		},


	setData:function(context,config,target_div,start_ypos,end_ypos){

		this._context 	= context;
		this._config	= config;
		this._target_div = target_div;

		this._start_ypos= start_ypos;
		this._end_ypos 	= end_ypos;
		this._ypos = start_ypos;
		this._gradient = null;
		this._draw_end = false;
	},

	getCurrHeight:function(){
		return this._ypos;
	},

	getCurrPos:function(){
		return (this._config.height - this._ypos + this._config.start_y - this._config.min_height);
	},

	render:function(splash,wait_tm){
		// Draw
		var nextY	= this.getCurrPos();
		// Push Splash Data
		for(var i=0; i < splash.length ; i++){
			if(this._splash.length > this._config.splash_max) {
				break;
			}
			var t_wait_area_splash = new WaitAreaSplash();
			t_wait_area_splash.setData(this._context,this._config,splash[i][0],splash[i][1]);
			this._splash.push(t_wait_area_splash);
		}


		this._context.save();

		// 그라데이션
		if(this._gradient == null || this._draw_end == false){
			var add_pos = 0;
			var t_style = this._config.style.normal;
			if(wait_tm > this._config.gradient.max_wait_tm ){
				add_pos = this._config.gradient.max_y;
				t_style = this._config.style.warn;
			}else{
				add_pos = this._config.gradient.max_y * (wait_tm/this._config.gradient.max_wait_tm);
			}

			this._gradient = this._context.createLinearGradient(this._config.start_x, nextY,
																this._config.start_x ,
																nextY + this.getCurrHeight()+this._config.min_height + add_pos);
			this._gradient.addColorStop(0, t_style);
			this._gradient.addColorStop(0.1, t_style);
			this._gradient.addColorStop(1, this._config.gradient.style);
		}
        this._context.fillStyle = this._gradient;

		// Draw Rect
		this._context.fillRect( this._config.start_x, nextY, this._config.width , this.getCurrHeight()+this._config.min_height);
		this._context.restore();

		// Draw Splash Data
		var len = this._splash.length;
		for(var i=0;i < len; i++){
			var t_wait_area_splash = this._splash.shift();

			if(!t_wait_area_splash.render()){
				continue;
			}
			this._splash.push(t_wait_area_splash);
		}

		// Update
		if(this._start_ypos > this._end_ypos){
			this._nextPos();
		}else{
			this._nextPosBack();
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// 진입량 물 차오르는 부분 //////////////////////////////////////
var WaitAreaSuccData	=	function(){
};
WaitAreaSuccData.prototype = {
		_context:null,
		_config:null,
		_start_ypos:0,
		_end_ypos:0,
		_ypos:0,
		_used_size_per : 0,
		_real_size : 0,
		_start_color : null,
		_end_color : null,
		_div : 0 ,
		_pos : 0 ,
		_gradient : null ,
		_draw_end : false,

		_nextPos:function(){
			var t_ypos = this._ypos ;
				t_ypos = this._ypos - this._config.interval;

			if(t_ypos < this._end_ypos){
				this._draw_end = true;
				t_ypos = this._end_ypos;
			}

			this._ypos = t_ypos;

			return this._ypos;
		},

		_nextPosBack:function(){
			var t_ypos = this._ypos ;

				t_ypos = this._ypos + this._config.interval;
			if(t_ypos > this._end_ypos){
				this._draw_end = true;
				t_ypos = this._end_ypos;
			}

			this._ypos = t_ypos;

			return this._ypos;
		},


		setData:function(context,config,start_ypos,end_ypos, used_size_pre , used_size_curr ,real_used_pre , real_used_curr){
			this._context 	= context;
			this._config	= config;
			this._start_ypos= start_ypos;
			this._end_ypos 	= end_ypos;
			this._ypos = start_ypos;
			this._used_size = used_size_pre;
			this._real_size_per = real_used_curr;
			this._gradient = null;
			this._draw_end = false;
		},

		getCurrHeight:function(){
			return this._ypos;
		},

		getCurrUsedSizePos:function(){
			return this._used_size;
		},

		getCurrPos:function(){
			// console.log('여기네', this._config.height , this._ypos , this._config.start_y , this._config.min_height)
			return (this._config.height - this._ypos + this._config.start_y - this._config.min_height);
		},

		getWaitTransColor:function(start_color,end_color,div,pos){
			var s_div = end_color - start_color;
			if( div < 1) return(start_color);
			return( (start_color + parseInt((s_div/div * pos),10)).toString(16));
		},

		GetWaitAreaColorProc:function(start_color,end_color,div,pos){

			this._div			= div;
			this._pos			= pos;
			this._start_color	= start_color;
			this._end_color		= end_color


			if(start_color.length !=7 || end_color.length !=7)
			return(start_color);

			return("#" + this.getWaitTransColor( parseInt(start_color.substring(1,3),16) , parseInt(end_color.substring(1,3),16),div,pos)
				       + this.getWaitTransColor( parseInt(start_color.substring(3,5),16) , parseInt(end_color.substring(3,5),16),div,pos)
					   + this.getWaitTransColor( parseInt(start_color.substring(5,7),16) , parseInt(end_color.substring(5,7),16),div,pos));
		},

		GetWaitAreaColor:function(num){
			/*
			if(this._config.style_max_num <= this._config.style_min_num)
				return this._config.style_begin_color;
			if(num >= this._config.style_max_num)
				return(this._config.style_end_color);
			*/

			if(num <= this._config.style_min_num)
				return(this._config.style_begin_color);

			var start_color = this._config.style_begin_color;
			var end_color   = this._config.style_end_color;
			this._config.style_min_num = 90;
			var pos = (num-this._config.style_min_num);
			var div = this._config.style_max_num - this._config.style_min_num ;
			return this.GetWaitAreaColorProc(this._config.style_begin_color, this._config.style_end_color , div , pos);
		},

		render:function(wave){
			// Draw
			var nextY	= this.getCurrPos();
			var colorGet	= this.GetWaitAreaColor(this._real_size_per);
			var colorlenght = colorGet.length;
			var div = this._div;
			var pos = this._pos;
			var start_color = this._start_color;
			var end_color = this._end_color;
			var used_size	= this._used_size;

			this._context.save();
			// 그라데이션
			if(this._gradient == null || this._draw_end == false){
				var style = this._config.style_fix;
				// console.log(this._config.start_x, nextY,
				// 	this._config.start_x ,
				// 	nextY + this.getCurrHeight()+this._config.min_height)
		
				this._gradient = this._context.createLinearGradient(this._config.start_x, nextY, this._config.start_x ,	nextY + this.getCurrHeight()+this._config.min_height);
				this._gradient.addColorStop(0, this._config.style_end_color);
				this._gradient.addColorStop(0.9, this._config.style_begin_color);
				this._gradient.addColorStop(1, this._config.style_begin_color);
			}
			/*
			this._context.fillStyle = this._config.style_fix;
			this._context.fillRect( this._config.start_x, nextY, this._config.width , this.getCurrHeight()+this._config.min_height);
			*/

			if(this._real_size_per > 90){
					this._context.fillStyle =   this._gradient;
					this._context.fillRect( this._config.start_x, nextY, this._config.width , this.getCurrHeight()+this._config.min_height);
			}else if(this._real_size_per <= 90 && this._real_size_per != 0){
				this._context.fillStyle = this._config.style_fix;
				this._context.fillRect( this._config.start_x, nextY, this._config.width , this.getCurrHeight()+this._config.min_height);
			}
			this._context.restore();

			// Update
			if(this._start_ypos > this._end_ypos){
				this._nextPos();
			}else{
				this._nextPosBack();
			}
		}
}

var WaitAreaSuccDataMove	=	function(){
};
WaitAreaSuccDataMove.prototype = {
	_context:null,
	_config:null,
	_start_ypos:0,
	_end_ypos:0,
	_ypos:0,

	_nextPos:function(){

		var t_ypos = this._ypos ;
		if(t_ypos > this._end_ypos){
			t_ypos = this._end_ypos;
		}
		this._ypos = t_ypos;
		return this._ypos;
	},

	_nextPosBack:function(){
		var t_ypos = this._ypos ;
		t_ypos = this._ypos + this._config.interval;
		if(t_ypos > this._end_ypos){
			t_ypos = this._end_ypos;
		}

		this._ypos = t_ypos;

		return this._ypos;
	},

	setData:function(context,config,start_ypos,end_ypos){

		this._context 	= context;
		this._config	= config;
		this._start_ypos= start_ypos;
		this._end_ypos 	= end_ypos;
		this._ypos = start_ypos;
	},

	getCurrHeight:function(){
		return this._ypos;
	},

	getCurrPos:function(){
//	    console.log('this._config.height', this._config.height);

		return (this._config.height - this._ypos + this._config.start_y - this._config.min_height);
	},

	render:function(wave){
		// Draw
		var nextY	= this.getCurrPos();

		this._context.fillStyle = this._config.style_move;
		this._context.fillRect( this._config.start_x, nextY, this._config.width , this.getCurrHeight()+this._config.min_height);

		// Update
		if(this._start_ypos > this._end_ypos){
			this._nextPos();
		}else{
			this._nextPosBack();
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////
///////////////////////           Xview Data          ////////////////////////////////
var XviewDrop = function(){
};
XviewDrop.prototype = {
	_context:null,
	_config:null,
	_col_index:0,
	_count:0,
	updateCount:function(count){
		if(count == undefined || count == null){
			count = 1;
		}
		this._count += count;
	},
	setData:function(context,config,col_index ,color){
		this._context = context;
		this._config = config;
		this._col_index = col_index;
		this._count = 0;
		this._color = color;
	},
	render:function(row_index){
		if(this._count <= 0){
			return;
		}

		// Calulate Circle Radius
		var radius = this._count / this._config.radius_factor;
		if(radius < this._config.radius_min){
			radius = this._config.radius_min;
		}
		if(radius > this._config.radius_max){
			radius = this._config.radius_max;
		}

		// Calculate Position
		var t_height = Math.floor(this._config.height / this._config.row_num);
		var t_width = Math.floor(this._config.width / this._config.col_num);
		var top = this._config.start_y + t_height*row_index + Math.floor(t_height/2);
		var left = this._config.start_x + t_width*this._col_index + Math.floor(t_width/2);

		// alpha
		var alpha = 1 - (this._config.radius_max - radius)/10;

		// Draw Drop
		if ( this._config.resize_radius == undefined ) {
			DropRenderer.simple(this._context,left,top,radius,this._color,alpha);
		} else {
			DropRenderer.simple(this._context,left,top,radius + this._config.resize_radius,this._color,alpha);
		}

	}
}

///////////////////////////////////////////////////////////////////////////////////////
/**
 * Funnel Class
 *
 * Canvas를 그려주기위한 Class
 */
//////////////////////////////////////////////////////////////////////////////////////
var Funnel = function(){
	//--------------------------------------------------------
	// Member Variables
	//--------------------------------------------------------
	this._target_div 	= null;
	this._canvas 		= null;
	this._canvas_bg 	= null;
	this._canvas_warn	= null;
	this._canvas_text 	= null;
	this._context		= null;
	this._context_bg	= null;
	this._context_warn  = null;
	this._context_text	= null;
	this._draw_loop		= null;
	this._warn_div		= null;
	this._draw_loop_warn		= null;

	this._prev_data		= null;
	this._curr_data		= null;

	this.wait_area_data 				= null;
	this.entry_area_data 				= null;
	this.complete_area_data 			= null;
	this.wait_area_succ_data 			= null;
	this.wait_area_succ_run_size_data 	= null;
	this.wait_drop_datas 				= null;
	this.wait_entry_datas 				= null;
	this.wait_complete_datas 			= null;
	this.xview_datas 					= null;

	this._service_id = null;
	this._action_id  = null;
	// 0 : dynamic
	// 1 : static
	this._action_type = null;
	this._textLinks  = null;
	this._warn_status = "bgonly";
	this._tooltip = null;
}


Funnel.prototype._init = function(target_id)
{
	// Config Setup
	this._config.complete_area.radius = this._config.xview.radius_min;

	this.wait_drop_datas = {
		speed_per_loop:1,
		speed_counter:0,
		gap_per_drop:0,
		last_uptime:0,
		radius:0,
		dropsize:0,
		datas:[]
	};
	this.wait_block_drop_datas = {
			speed_per_loop:1,
			speed_counter:0,
			gap_per_drop:0,
			last_uptime:0,
			radius:0,
			dropsize:0,
			datas:[]
	};
	this.wait_bypass_drop_datas = {
			speed_per_loop:1,
			speed_counter:0,
			gap_per_drop:0,
			last_uptime:0,
			radius:0,
			dropsize:0,
			datas:[]
	};
	this.wait_entry_datas = {
		speed_per_loop:1,
		speed_counter:0,
		gap_per_drop:0,
		last_uptime:0,
		radius:0,
		dropsize:0,
		datas:[]
	};

	this.wait_complete_datas = new Array();
	this.xview_datas = new Array();
	this._initXviewDatas();

	this._textLinks = new Array();

	// Init Canvas
	this._initCanvas(target_id);
}

Funnel.prototype._clearText = function()
{
}

/**
 * Draw Text
 *
 * @param obj Target div object
 * @param msg Text Message to write
 * @param opt Options
 *           - pos Position { left:nnn, top:mmm, pos:topleft|topright|bottomleft|bottomright|topcenter|bottomcenter ]
 *           - size { w:nnn , h:mmm }, 0 is follow msg size
 *           - align left|center|right
 *           - font Font properties { size:nn , color:xxx, alpha:zz, family:yy }
 *           - background Background style
 */
Funnel.prototype._drawText = function(context,msg,unit,opt,prop_id,id){
	var def_padding = 2;
	var def_font = { size :10, color:'rgb(255, 255, 252)', family:"nanum" };
	if ( this._config.text.resize != undefined) {
		def_font.size = def_font.size * this._config.text.resize ;
	}
	var def_unit_font = { size :10, color:'rgb(255, 255, 252)', family:"nanum" };
	if ( this._config.text.resize != undefined) {
		def_unit_font.size = def_unit_font.size * this._config.text.resize;
	}
	var def_background = 'rgba(21, 21, 21, 0.9)';
	var def_size = { w:0, h:0 }
	var div_obj = null;

	if(!opt) return;

	// Init Align
	if(opt.align == undefined || opt.align == null){
		opt.align = "left";
	}
	// Init Font Setting
	if(opt.font == undefined || opt.font == null){
		opt.font = def_font;
	}else{
		if(opt.font.size == undefined) opt.font.size = def_font.size;
		if(opt.font.color == undefined) opt.font.color = def_font.color;
		if(opt.font.family == undefined) opt.font.family = def_font.family;
	}

	// Init Unit Font Setting
	if(opt.unit_font == undefined || opt.unit_font == null){
		opt.unit_font = def_unit_font;
	}else{
		if(opt.unit_font.size == undefined) opt.unit_font.size = def_unit_font.size;
		if(opt.unit_font.color == undefined) opt.unit_font.color = def_unit_font.color;
		if(opt.unit_font.family == undefined) opt.unit_font.family = def_unit_font.family;
	}

	// Init Background Setting
	if(opt.background == undefined || opt.background == null){
		opt.background = def_background;
	}

	// Init Size Setting
	if(opt.size == undefined || opt.size == null){
		opt.size = def_size;
	}else{
		if(opt.size.w == undefined) opt.size.w = def_size.w;
		if(opt.size.h == undefined) opt.size.h = def_size.h;
		if(opt.size.w < 0) opt.size.w = 0;
		if(opt.size.h < 0) opt.size.h = 0;
	}

	// Font Size Check
	context.font = ""+opt.font.size+"px "+opt.font.family;
	var text_width = context.measureText(msg).width;
	var text_height = opt.font.size-2;

	// Unit Font Size Check
	context.font = ""+opt.unit_font.size+"px "+opt.unit_font.family;
	var unit_text_width = context.measureText(unit).width;
	var unit_text_height = opt.unit_font.size-2;

	var size = {w:0,h:0};
	if(opt.size.w == 0){ size.w = text_width + unit_text_width + 2*def_padding; }else{ size.w = opt.size.w }
	if(opt.size.h == 0){ size.h = text_height + unit_text_height + 2*def_padding; }else{ size.h = opt.size.h }

	// Write Background Rectangle
	var x = opt.pos.x;
	var y = opt.pos.y;
	if(opt.pos.pos == "topright"){
		x = x - size.w;
	}else if(opt.pos.pos == "bottomleft"){
		y = y - size.h;
	}else if(opt.pos.pos == "bottomright"){
		x = x - size.w;
		y = y - size.h;
	}else if(opt.pos.pos == "topcenter"){
		x = x - size.w/2;
	}else if(opt.pos.pos == "bottomcenter"){
		x = x - size.w/2;
		y = y - size.h;
	}else if(opt.pos.pos == "center"){
		x = x - size.w/2;
		y = y - size.h/2;
	}

	context.fillStyle = opt.background;
	context.fillRect( x, y, size.w , size.h);
	context.stroke();

	var t_x = x+def_padding;
	var t_y = y+def_padding+opt.font.size;
	// t_y = y+def_padding+opt.font.size + 100;
	if(opt.align == "center"){
		t_x = x + (size.w / 2) - (text_width+unit_text_width)/2;
	}else if(opt.align == "right"){
		t_x = x + size.w - (text_width+unit_text_width) - def_padding;
	}
	context.font = ""+opt.font.size+"px "+opt.font.family;
	context.fillStyle = opt.font.color;
	context.fillText(msg,t_x,t_y);

	context.font = ""+opt.unit_font.size+"px "+opt.unit_font.family;
	context.fillStyle = opt.unit_font.color;
	t_x = t_x + text_width;
	context.fillText(unit,t_x,t_y);
	// this._textLinks.push(x + ";" + y + ";" + size.w + ";" + size.h + ";" + prop_id + ';' + (prop_id === 'used_size' ? msg + unit : msg) + ';' + id);
	this._textLinks.push(x + ";" + y + ";" + size.w + ";" + size.h + ";" + prop_id + ';' + msg + unit + ';' + id);
};

Funnel.prototype.getWaitAreaPos = function(data){
	if(data == null) return 0;
	var max_wait_user = 500; //200
	var curr_wait_user = data.wait_user;
	return Math.floor(( curr_wait_user * this._config.wait_area.height ) / max_wait_user);
}

Funnel.prototype.getWaitAreaPosLimit = function(data){
	if(data == null) return 0;
	var max_wait_user = 500; //200
	var curr_wait_user = data.wait_user;
	if( max_wait_user < curr_wait_user){
		max_wait_user  = curr_wait_user;
	}
	return Math.floor((curr_wait_user * this._config.wait_area.height ) / max_wait_user);
}

Funnel.prototype.getWaitAreaSuccPos	= function(data){
	if(data	==	null) return 0;
	var service_limit_user	=	data.limit_max;
	var max_wait_user	=	data.limit;
	var curr_wait_user	=	data.used_size;
	var limitfixsize	=  ( max_wait_user * this._config.wait_area_succ.height) / service_limit_user ;
	return	Math.floor((((curr_wait_user>max_wait_user)?max_wait_user:curr_wait_user) * limitfixsize) / max_wait_user);
}

Funnel.prototype.getWaitAreaSuccRunSizePos	= function(data){
	if(data	==	null) return 0;
	var service_limit_user	=	data.limit_max;
	var max_wait_user	= data.limit;
	var curr_wait_user	=	data.used_size;
	return	Math.floor( ( max_wait_user * this._config.wait_area_succ.height) / service_limit_user );
}


Funnel.prototype.getWaitAreaSuccRunSizePosSize	= function(data){
	if(data	==	null) return 0;
	var service_limit_user	=	data.limit_max;
	var max_wait_user	= data.limit;
	var curr_wait_user	=	data.used_size;
	var limitfixsize	=  ( max_wait_user * this._config.wait_area_succ.height) / service_limit_user ;
	var succpos			=  (((curr_wait_user>max_wait_user)?max_wait_user:curr_wait_user) * limitfixsize) / max_wait_user
	return	Math.floor( ( succpos *100) / limitfixsize );
}


Funnel.prototype.getWaitTm	= function(data){
	if(data	==	null) return 0;
	var wait_tm	= data.wait_tm.toFixed(1);
	return	wait_tm;

}
Funnel.prototype.getBypass	= function(data){
	if(data	==	null) return 0;
	var bypass	= data.bypass.toFixed(1);
	return	bypass;

}
Funnel.prototype.getBlock	= function(data){
	if(data	==	null) return 0;
	var block	= data.block.toFixed(1);
	return	block;

}

Funnel.prototype.getAccessMode	= function(data){
	if(data	==	null) return 0;
	var access_mode	= data.access_mode;
	return	access_mode;

}
Funnel.prototype.getWaitUser = function(data){
	if(data	==	null) return 0;
	var wait_user	=	data.wait_user;
	//console.log("wait_user" , wait_user);
	return	wait_user;

}
Funnel.prototype.getLimitMax = function(data){
	if(data	==	null) return 0;
	var limit_max	=	data.limit_max;
	return	limit_max;

}
Funnel.prototype.getLimit = function(data){
	if(data	==	null) return 0;
	var limit	=	data.limit;
	return	limit;

}
Funnel.prototype.getActionLimit	= function(data){
	if(data	==	null) return 0;
	var action_limit	=	data.action_limit;
	return	action_limit;

}
Funnel.prototype.getActionlimitReal = function(data){
	if(data	==	null) return 0;
	var service_limit_user	=	data.service_limit;
	var max_wait_user	=	data.action_limit;
	var runsizelimit	= Math.floor( ( max_wait_user * this._config.wait_area_succ.height) / service_limit_user );
	var runsize = Math.floor((max_wait_user * 125) / service_limit_user);
	return	runsize;

}

Funnel.prototype.getUsedSizeReal = function(data){
	if(data	==	null) return 0;
	var curr_wait_user	=	data.used_size;
	var limit	= data.limit;
	return	 Math.floor((curr_wait_user * 100)/limit);

}

Funnel.prototype.getUsedSizeRealNm = function(data){
	if(data	==	null) return 0;
	var curr_wait_user	=	data.used_size;
	var limit	= data.limit;
	return	 curr_wait_user;

}
Funnel.prototype.getNewKeyChk = function(data){
	if(data	==	null) return 0;
	var getNewKeyChk = data.new_key_chk_enter.toFixed(1);
	return	 getNewKeyChk;

}
Funnel.prototype.getChkEnterSucc = function(data){
	if(data	==	null) return 0;
	var getChkEnterSucc = 0;
	if (data.chk_enter_succ !== 0) {
		getChkEnterSucc = data.chk_enter_succ.toFixed(1);
	}
	return getChkEnterSucc;

}
Funnel.prototype.getCompleteSucc = function(data){
	if(data	==	null) return 0;
	var getCompleteSucc = data.complete_succ.toFixed(1);
	return getCompleteSucc;

}
//--------------------------------------------------------
// Private Functions
//--------------------------------------------------------
Funnel.prototype._initCanvas = function(target_id)
{
	var target_div = document.getElementById(target_id);
	// Canvas Proc
	this._canvas = document.createElement("CANVAS");
	target_div.appendChild(this._canvas);
	this._canvas.height = this._config.canvas.height;
	this._canvas.width = this._config.canvas.width;
	this._canvas.style.zIndex = 90;
	this._canvas.style.top = 0;
	this._canvas.style.left = 0;
	this._canvas.style.position = "absolute";
	this._context = this._canvas.getContext("2d");

	// Canvas Bg
	this._canvas_bg = document.createElement("CANVAS");
	// target_div.appendChild(this._canvas_bg);
	this._canvas_bg.height = this._config.canvas.height;
	this._canvas_bg.width = this._config.canvas.width;
	this._canvas_bg.style.zIndex = 92;
	this._canvas_bg.style.top = 0;
	this._canvas_bg.style.left = 0;
	this._canvas_bg.style.position = "absolute";
	this._context_bg = this._canvas_bg.getContext("2d");

	// Canvas Text
	this._canvas_text = document.createElement("CANVAS");
	// target_div.appendChild(this._canvas_text);
	this._canvas_text.height = this._config.canvas.height;
	this._canvas_text.width = this._config.canvas.width;
	this._canvas_text.style.zIndex = 94;
	this._canvas_text.style.top = 0;
	this._canvas_text.style.left = 0;
	this._canvas_text.style.position = "absolute";
	this._context_text = this._canvas_text.getContext("2d");

	// Canvas [Block , Bypass] Action
	this._canvas_warn	= document.createElement("CANVAS");
	this._canvas_warn.id = 'warn';
	// target_div.appendChild(this._canvas_warn);
	this._canvas_warn.height = this._config.canvas.warncanvas.height ;
	this._canvas_warn.width = this._config.canvas.warncanvas.width ;
	this._canvas_warn.style.zIndex = 96;
	this._canvas_warn.style.top = this._config.canvas.warncanvas.y + "px";
	this._canvas_warn.style.left =this._config.canvas.warncanvas.x + "px";
	this._canvas_warn.style.position = "absolute";
	this._context_warn = this._canvas_warn.getContext("2d");
	this._canvas_warn.display ="inline";

	var self = this;
	// this._canvas_text.addEventListener("mousemove",function(ev){
	// 	self._onTextMouseOver(ev);
	// }, false);
	// this._canvas_text.addEventListener("mouseleave",function(ev){
	// 	self._onTextMouseLeave(ev);
	// }, false);
	// this._canvas_text.addEventListener("dblclick", function(e){
	// 	self._onTextClick(e);
	// }, false);

	// this._tooltipInit(target_div, {show: true, size: 12, weight: 'normal', fontColor: '#000', color: '#f08213'});
	this._target_div = target_div;
}

Funnel.prototype._tooltipInit = function(ele, settings) {
	if (ele === undefined) { return false; }
	var container = document.createElement('div');
	container.setAttribute('class', 'funnel-chart-tooltip');
	container.style.position = "fixed";
	container.style.height = "auto";
	container.style.boxShadow = "1px 1px 2px 2px rgba(0,0,0,0.1)";
	container.style.zIndex = "1000";
	container.style.display = "none";
	container.style.WebkitTransition = "left .2s ease-out, bottom .2s ease-out";
	container.style.MozTransition = "left .2s ease-out, bottom .2s ease-out";
	container.style.MsTransition = "left .2s ease-out, bottom .2s ease-out";
	container.style.transition = "left .2s ease-out, bottom .2s ease-out";

	var toolTipHtml = '<div style=" width: auto;';
	toolTipHtml += 'height: auto;';
	toolTipHtml += 'min-width: 50px;';
	toolTipHtml += 'line-height: auto;';
	toolTipHtml += 'margin: 0px 0px 0px 0px;';
	toolTipHtml += 'padding: 5px;';
	// toolTipHtml += 'font-family: Calibri, Arial, Georgia, serif;';
	toolTipHtml += 'font-weight: ' + settings.weight + ';';
	// toolTipHtml += 'font-style: italic;';
	toolTipHtml += 'font-size: ' + settings.size + 'px;';
	toolTipHtml += 'color: ' + settings.fontColor + ';';
	toolTipHtml += 'text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);';
	toolTipHtml += 'text-align: left;';
	toolTipHtml += 'border: 2px solid ' + settings.color + ';';
	toolTipHtml += 'background: rgba(255,255,255,.9);';
	toolTipHtml += 'text-indent: 0px;';
	toolTipHtml += 'white-space: nowrap;';
	toolTipHtml += 'border-radius: 2px;';
	toolTipHtml += '-moz-user-select:none;';
	toolTipHtml += '-khtml-user-select: none;';
	toolTipHtml += '-webkit-user-select: none;';
	toolTipHtml += '-ms-user-select: none;';
	toolTipHtml += 'user-select: none;';
	toolTipHtml += '"></div>';

	container.innerHTML = toolTipHtml;
	this._tooltip = container;
	if (ele.querySelectorAll('.performanceTiming-chart-tooltip').length) {
	} else {
		ele.style.position = 'relative';
		ele.appendChild(this._tooltip);
	}
};

Funnel.prototype._onTextMouseLeave = function(ev)
{
	if (this._tooltip.style.display === 'block') {
		this._tooltip.style.display = 'none';
	}
};


Funnel.prototype._onTextMouseOver = function(ev)
{
	var x, y;

	// Get the mouse position relative to the canvas element
	if (ev.layerX || ev.layerX == 0) { // For Firefox
		x = ev.layerX;
		y = ev.layerY;
	}

	// Link hover
	for (var i = this._textLinks.length - 1; i >= 0; i--) {
		var params = new Array();

		// Get link params back from array
		params = this._textLinks[i].split(";");

		var linkX = parseInt(params[0]),
			linkY = parseInt(params[1]),
			linkWidth = parseInt(params[2]),
			linkHeight = parseInt(params[3]),
			linkProp = params[4];
			value = params[5];
			id = params[6];

// console.log('value : ', value, this._textLinks[i]);
		// Check if cursor is in the link area
		if (x >= linkX && x <= (linkX + linkWidth) && y >= linkY && y <= (linkY + linkHeight)){
			// cursor pointer
			document.body.style.cursor = "pointer";

			// tooltip
			var selector = this._tooltip.querySelectorAll('.funnel-chart-tooltip > div');
			selector[0].innerHTML = '<span style="font-size: 11px; color: gray;">' +
					// this.lang[linkProp] + ' : ' + id + ' : ' + value +
					this.lang[linkProp] + ' : ' + '<span style="color: black; font-style: bold;">' + value + '</span>' +
					'</span>';
			this._tooltip.style.top = ev.clientY - linkHeight - 20 + 'px';
			this._tooltip.style.left = ev.clientX - linkWidth - 5 + 'px';
			this._tooltip.style.display = 'block';

			break;
		}
		else {
			document.body.style.cursor = "";
		}
	};

	// if (this._tooltip.style.display === 'block') {
	// 	var width = ev.style.width;
	// 	var height = ev.style.height;
	// 	this._tooltip.style.top = ev.pageX - height - 5;
	// 	this._tooltip.style.left= ev.pageY - width - 5;
	// }
	// console.log('offset : ', ev.layerX, ev.layerY, this._canvas_text.style.top, this._canvas_text.style.left);

	// var target = this._target_div.getBoundingClientRect();
	// var mouseX = parseInt(ev.clientX - target.left);
	// var mouseY = parseInt(ev.clientY - target.top);
	// var hit = false;
	// for (var i in this._textLinks) {
	// 	var text = this._textLinks[i].split(';');
	// 	var x = text[0];
	// 	var y = text[1];
	// 	var width = text[2];
	// 	var height = text[3];
	// 	var id = text[4];
	// 	if ()
	// }

};

Funnel.prototype._onTextClick = function(e)
{
	var x, y;

	// Get the mouse position relative to the canvas element
	if (e.layerX || e.layerX == 0) { // For Firefox
		x = e.layerX;
		y = e.layerY;
	}

	//console.log("clicked - 1 , x="+x+", y="+y);

	// Link hover
	for (var i = this._textLinks.length - 1; i >= 0; i--) {
		var params = new Array();

		// Get link params back from array
		params = this._textLinks[i].split(";");

		var linkX = parseInt(params[0]),
			linkY = parseInt(params[1]),
			linkWidth = parseInt(params[2]),
			linkHeight = parseInt(params[3]),
			linkProp = params[4];

		// Check if cursor is in the link area
		if (x >= linkX && x <= (linkX + linkWidth) && y >= linkY && y <= (linkY + linkHeight)){
			//console.log("clicked - 2");
			this.addShowPanel(linkProp);
			break;
		}
		else {
		}
	};
}

Funnel.prototype._initDrawCanvas = function()
{
	this._context.clearRect (0, 0, this._canvas.width, this._canvas.height);
}

Funnel.prototype._postDrawCanvas = function()
{

}

Funnel.prototype._calcRadius = function(config,tps)
{
	var t_radius = config.radius;
	var t_dropsize = config.dropsize;

	if(tps > this._config.drop.tps_max && tps <= this._config.drop.tps_hard_max){
		t_radius = config.radius +
				(config.radius_max - config.radius)*(
					(tps - this._config.drop.tps_max )/ (this._config.drop.tps_hard_max - this._config.drop.tps_max)
				);
		t_dropsize = config.dropsize +
				(config.dropsize_max - config.dropsize)*(
					(tps - this._config.drop.tps_max )/ (this._config.drop.tps_hard_max - this._config.drop.tps_max)
				);
	}else if(tps > this._config.drop.tps_hard_max){
		t_radius = config.radius_max;
		t_dropsize = config.dropsize_max;
	}

	return { radius:t_radius, dropsize:t_dropsize };
}

Funnel.prototype._getSpeed = function(datas,tps,speed){
	var t_datas = datas;
	var tps_max = this._config.drop.tps_max
	var idx = t_datas.speed_counter++ %3;
	var result = speed;
	if(idx == 1){ result = result - 1;}
	else if(idx == 2){ result = result + 1; }
	if(result < 1) result = 1;
	return result;

}

Funnel.prototype._pushDataProc = function(datas)
{
	var t_datas = datas;
    var cts = (new Date()).getTime();

    if(t_datas.last_uptime <= 0){
		t_datas.last_uptime = cts - t_datas.gap_per_drop;
	}

    var gap = cts - t_datas.last_uptime;
	if(gap > t_datas.gap_per_drop + this._config.draw_interval){
		gap = t_datas.gap_per_drop + this._config.draw_interval;
		t_datas.last_uptime = cts - gap;
	}
    if(t_datas.gap_per_drop > gap) return;

	var cnt;
    if(t_datas.gap_per_drop >= this._config.draw_interval){
        // 1개만 생성하면 된다.
		t_datas.last_uptime = cts - (gap - t_datas.gap_per_drop);
		cnt = 1;
    }else{
        // 1개 이상의 Drop을 생성해야 한다.
        cnt = Math.floor(gap/t_datas.gap_per_drop);
        t_datas.last_uptime = cts - (gap - t_datas.gap_per_drop*cnt);
    }
	return cnt;
}

Funnel.prototype._draw = function(){
	// Push Data
	this._pushDataWaitDrop();
	this._pushDataBlockDrop();
	this._pushDataEntryDrop();
	this._pushDataBypassDrop();

	this._initDrawCanvas();


	// Wait Data Draw Block
	var blocklen = this.wait_block_drop_datas.datas.length;
	var wait_area_block_pos = -1;
	var splash = [];
	if(this.wait_area_block_data) wait_area_block_pos = this.wait_area_block_data.getCurrPos();
	while(blocklen > 0){
		blocklen -= 1;
		var t_wait_drop_data_block = this.wait_block_drop_datas.datas.shift();
		var t_wait_drop_pos = t_wait_drop_data_block.getCurrPos();

		var speed = t_wait_drop_data_block.getSpeed();
		if( wait_area_block_pos > 0 &&
			t_wait_drop_pos.top > wait_area_block_pos-speed &&
			t_wait_drop_pos.top <= wait_area_block_pos){
			splash.push([t_wait_drop_pos.left,wait_area_block_pos]);
		}

		if(!t_wait_drop_data_block.render(splash)){
			continue;
		}

		this.wait_block_drop_datas.datas.push(t_wait_drop_data_block);
	}

	// Wait Data Draw Bypass
	var bypasslen = this.wait_bypass_drop_datas.datas.length;
	var wait_area_bypass_pos = -1;
	var splash = [];
	if(this.wait_area_bypass_data) wait_area_bypass_pos = this.wait_area_bypass_data.getCurrPos();
	while(bypasslen> 0){
		bypasslen -= 1;
		var t_wait_drop_data_bypass = this.wait_bypass_drop_datas.datas.shift();
		var t_wait_drop_pos = t_wait_drop_data_bypass.getCurrPos();

		var speed = t_wait_drop_data_bypass.getSpeed();
		if( wait_area_bypass_pos > 0 &&
			t_wait_drop_pos.top > wait_area_bypass_pos-speed &&
			t_wait_drop_pos.top <= wait_area_bypass_pos){
			splash.push([t_wait_drop_pos.left,wait_area_bypass_pos]);
		}

		if(!t_wait_drop_data_bypass.render(splash)){
			continue;
		}

		this.wait_bypass_drop_datas.datas.push(t_wait_drop_data_bypass);
	}
	// Wait Data Draw
	var len = this.wait_drop_datas.datas.length;
	var wait_area_pos = -1;
	var splash = [];
	if(this.wait_area_data) wait_area_pos = this.wait_area_data.getCurrPos();
	while(len > 0){
		len -= 1;
		var t_wait_drop_data = this.wait_drop_datas.datas.shift();
		var t_wait_drop_pos = t_wait_drop_data.getCurrPos();

		var speed = t_wait_drop_data.getSpeed();
		if( wait_area_pos > 0 &&
			t_wait_drop_pos.top > wait_area_pos-speed &&
			t_wait_drop_pos.top <= wait_area_pos){
			splash.push([t_wait_drop_pos.left,wait_area_pos]);
		}

		if(!t_wait_drop_data.render(splash)){
			continue;
		}

		this.wait_drop_datas.datas.push(t_wait_drop_data);
	}

	// Entry  Data  Draw
	var Entrylen = this.wait_entry_datas.datas.length;
	var entry_area_pos = -1;
	if(this.entry_area_data) entry_area_pos = this.entry_area_data.getCurrPos();
	while(Entrylen > 0){
		Entrylen -= 1;
		var t_wait_Entry_data =this.wait_entry_datas.datas.shift();
		var t_wait_Entry_pos = t_wait_Entry_data.getCurrPos();

		if( entry_area_pos > 0 &&
			t_wait_Entry_pos.top > entry_area_pos-3 &&
			t_wait_Entry_pos.top < entry_area_pos+3){
		}
		if(!t_wait_Entry_data.render()){
			continue;
		}
		this.wait_entry_datas.datas.push(t_wait_Entry_data);

	}

	// Complete Data Draw
	var Completelen = this.wait_complete_datas.length;
	var complete_area_pos = -1
	if(this.complete_area_data) complete_area_pos = this.complete_area_data.getCurrPos();

	while(Completelen>0){
		Completelen -= 1;
		var t_wait_complete_data = this.wait_complete_datas.shift();
		var t_wait_complete_pos	=	t_wait_complete_data.getCurrPos();
		
		if( complete_area_pos > 0 &&
				t_wait_complete_pos.top > complete_area_pos-3 &&
				t_wait_complete_pos.top < complete_area_pos+3){
		}

		if(!t_wait_complete_data.render()){
			// Add to Xview Data;
			var t_xview_drop = this.xview_datas[this._config.xview.row_num - 1][t_wait_complete_data.getColIndex()];
			t_xview_drop.updateCount();
			continue;
		}

		this.wait_complete_datas.push(t_wait_complete_data);
	}
	
	// xview Data Draw
	var xview_len = this.xview_datas.length;
	var row_num = 0;
	for(var i = xview_len-1;i>=0 ; i--){
		var t_row = this.xview_datas[i];
		for(var j=0; j < this._config.xview.col_num ; j++){
			var t_xview_drop = t_row[j];
			t_xview_drop.render(row_num);
		}
		row_num += 1;
	}
	
	// Wait Data Draw
				// %%렌더 비활성화 사용하지 않는 부분 계산 제거
	/*
	{
		if(this.wait_area_data) {
			var nextY = this.wait_area_data.getCurrPos();
			this.wait_area_data.render(splash,this._curr_data.wait_tm);
		}

		// Wait Data Draw wait_area_succ_data
		if(this.wait_area_succ_run_size_data) {
			var nextY = this.wait_area_succ_run_size_data.getCurrPos();
//			console.log(nextY);
			this.wait_area_succ_run_size_data.render();
		}
		// Wait Data Draw wait_area_succ_data
		if(this.wait_area_succ_data) {
			var nextY = this.wait_area_succ_data.getCurrPos();
			this.wait_area_succ_data.render();
		}

	}
	// Wait Data Draw wait_area_succ_data
	if(this.wait_area_succ_run_size_data) {
		var nextY = this.wait_area_succ_run_size_data.getCurrPos();
		this.wait_area_succ_run_size_data.render();
	}
	// Wait Data Draw wait_area_succ_data
	if(this.wait_area_succ_data) {
		var nextY_Per = this.wait_area_succ_data.getCurrPos();
		this.wait_area_succ_data.render();
		var run_size = this.getUsedSizeReal(this._curr_data);
		var run_size_num = this.getUsedSizeRealNm(this._curr_data);
		if(run_size > 0){
			var ty =nextY_Per + 10;
			if(ty > this._config.entry_drop.end_y - 20){
				ty = this._config.entry_drop.end_y- 20;
			}else if(ty < this._config.entry_drop.start_y + 20){
				ty = this._config.entry_drop.start_y + 20;
			}
			this._config.text.used_size.pos.y = ty;
			this._config.text.used_size_num.pos.y = ty;
			this._config.text.used_size_num_per.pos.y = ty;
			this._config.text.used_size_num_zero.pos.y = ty;
			this._context_text.clearRect (this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.start_y,
									this._config.text.used_size.pos.x - this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.height);

			this._context_text.clearRect (this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.start_y,
									this._config.text.used_size_num.pos.x - this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.height);

			this._context_text.clearRect (this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.start_y,
									this._config.text.used_size_num_zero.pos.x - this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.height);

			this._context_text.clearRect (this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.start_y,
									this._config.text.used_size_num_per.pos.x - this._config.wait_area_succ.start_x,
									this._config.wait_area_succ.height);

			var hr_run_size_num =  humanReadableNumber(run_size_num , 0);

			run_size>100 ? 100 : run_size;
			hr_run_size_num = hr_run_size_num + '(' + run_size;

			
			

			// this._drawText(this._context_text,((run_size>100)?100:run_size),"%)",this._config.text.used_size,"used_size");
	
			
// this._drawText(this._context_text,hr_run_size_num,"%)",this._config.text.used_size,"used_size", this._curr_data.id);

//			if(run_size == 100){
//				this._drawText(this._context_text,humanReadableNumber(run_size_num , 0),"(",this._config.text.used_size_num_per,"used_size");
//			}else if(run_size < 10){
//				this._drawText(this._context_text,humanReadableNumber(run_size_num , 0),"(",this._config.text.used_size_num_zero,"used_size");
//			}else{
//				this._drawText(this._context_text,humanReadableNumber(run_size_num , 0),"(",this._config.text.used_size_num,"used_size");
//			}

		}
	}
	
*/
	//Canvas basic Draw
	this._postDrawCanvas();


}

Funnel.prototype._pushDataWaitDrop = function(){
    if(this._curr_data == null) return;

    var tps = this._curr_data.new_key_chk_enter;
	if(tps <= 0) return;

	var cnt = this._pushDataProc(this.wait_drop_datas);

	for(var i=0;i<cnt ; i++){
		var t_wait_drop_data = new WaitDrop();
		var resizeRadius = this._config.wait_drop.resize_radius == undefined ? this.wait_drop_datas.radius : this.wait_drop_datas.radius + this._config.wait_drop.resize_radius;
		var resizeDropsize = this._config.wait_drop.resize_dropsize == undefined ? this.wait_drop_datas.dropsize : this.wait_drop_datas.dropsize + this._config.wait_drop.resize_dropsize;
		t_wait_drop_data.setData(this._context
							,this._config.wait_drop
							,this._getSpeed(this.wait_drop_datas,tps,this.wait_drop_datas.speed_per_loop)
							,resizeRadius
							// ,this.wait_drop_datas.radius
							,resizeDropsize
							// ,this.wait_drop_datas.dropsize
							);

		this.wait_drop_datas.datas.push(t_wait_drop_data);
	}
}

Funnel.prototype._pushDataBypassDrop = function(){
    if(this._curr_data == null) return;

    var tps = this._curr_data.bypass;
	if(tps <= 0) return;

	var cnt = this._pushDataProc(this.wait_bypass_drop_datas);

	for(var i=0;i<cnt ; i++){
        var t_wait_drop_data_bypass = new BypassWaitDrop();
        var resizeRadius = this._config.wait_drop.resize_radius == undefined ? this.wait_drop_datas.radius : this.wait_drop_datas.radius + this._config.wait_drop.resize_radius;
        var resizeDropsize = this._config.wait_drop.resize_dropsize == undefined ? this.wait_drop_datas.dropsize : this.wait_drop_datas.dropsize + this._config.wait_drop.resize_dropsize;

        t_wait_drop_data_bypass.setData(this._context
							,this._config.wait_drop
							,this._getSpeed(this.wait_drop_datas,tps,this.wait_drop_datas.speed_per_loop)
							,this._config.entry_drop
							// ,this.wait_bypass_drop_datas.radius
							,resizeRadius
							// ,this.wait_bypass_drop_datas.dropsize
							,resizeDropsize
							);
		this.wait_bypass_drop_datas.datas.push(t_wait_drop_data_bypass);
	}
}

Funnel.prototype._pushDataBlockDrop = function(){

    if(this._curr_data == null) return;


    var tps = this._curr_data.block;
	if(tps <= 0) return;

	var cnt = this._pushDataProc(this.wait_block_drop_datas);

	for(var i=0;i<cnt ; i++){
        var t_wait_drop_data_block = new BlockWaitDrop();
        var resizeRadius = this._config.wait_drop.resize_radius == undefined ? this.wait_drop_datas.radius : this.wait_drop_datas.radius + this._config.wait_drop.resize_radius;
        var resizeDropsize = this._config.wait_drop.resize_dropsize == undefined ? this.wait_drop_datas.dropsize : this.wait_drop_datas.dropsize + this._config.wait_drop.resize_dropsize;
        t_wait_drop_data_block.setData(this._context
							,this._config.wait_drop
							,this._getSpeed(this.wait_drop_datas,tps,this.wait_drop_datas.speed_per_loop)
							// ,this.wait_block_drop_datas.radius
							,resizeRadius
							// ,this.wait_block_drop_datas.dropsize
							,resizeDropsize
							);
		this.wait_block_drop_datas.datas.push(t_wait_drop_data_block);
	}

}
Funnel.prototype._pushDataComplete = function(){
	if(this._curr_data == null) return;

	var data = this._curr_data;
	var cnt = data.xview.length ;
	//var speed = Math.ceil(data.complete_succ/3)+5;
	var speed = 6;

	// Create Drop
	for(var i=0; i<cnt ; i++){
		var cnt_real = data.xview[i];
		if(cnt_real != 0){
			for(var j=1; j<=cnt_real; j++){
				var t_speed = speed;
				if(j%3==0){
					t_speed = speed - 1;
				}
				if(j%3==1){
					t_speed = speed + 1;
				}
				if(cnt_real > this._config.complete_area.complete_max){
					cnt_real = this._config.complete_area.complete_max;
				}
				var t_wait_complete_data = new CompleteDrop();
				t_wait_complete_data.setData(this._context,
											this._config.complete_area,
											t_speed/200 ,
											i ,
											cnt_real,
											i);
				this.wait_complete_datas.push(t_wait_complete_data);
			}
		}
	}

}
Funnel.prototype._pushDataEntryDrop = function(){

    if(this._curr_data == null) return;


	var tps = this._curr_data.chk_enter_succ;
	if(tps <= 0) return;

	var cnt = this._pushDataProc(this.wait_entry_datas);

	for(var i=0;i<cnt ; i++){
		var t_wait_entry_data = new EntryDrop();
		var resizeRadius = this._config.entry_drop.resize_radius == undefined ? this.wait_entry_datas.radius : this.wait_entry_datas.radius + this._config.entry_drop.resize_radius;
		var resizeDropsize = this._config.entry_drop.resize_dropsize == undefined ? this.wait_entry_datas.dropsize : this.wait_entry_datas.dropsize + this._config.entry_drop.resize_dropsize
       t_wait_entry_data.setData(this._context
							,this._config.entry_drop
							,this._getSpeed(this.wait_entry_datas,tps,this.wait_drop_datas.speed_per_loop)
							,resizeRadius
							// ,this.wait_entry_datas.radius
							,resizeDropsize
							// ,this.wait_entry_datas.dropsize
							);
		this.wait_entry_datas.datas.push(t_wait_entry_data);
	}
}

Funnel.prototype._initXviewDatas = function(){
	for(var r=0;r<this._config.xview.row_num;r++){
		var t_row = [];
		for(var i=0;i<this._config.xview.col_num;i++){
			var t_xview_drop = new XviewDrop();
			t_xview_drop.setData(this._context,this._config.xview,i , this._config.xview.color[i] );
			t_row.push(t_xview_drop);
		}
		this.xview_datas.push(t_row);
	}
}
Funnel.prototype._rolloverXview = function(){
	var t_row = [];
	for(var i=0;i<this._config.xview.col_num;i++){
		var t_xview_drop = new XviewDrop();
		t_xview_drop.setData(this._context,this._config.xview,i , this._config.xview.color[i]);
		t_row.push(t_xview_drop);
	}
	this.xview_datas.push(t_row);
	if(this.xview_datas.length > this._config.xview.row_num){
		this.xview_datas.shift();
	}
}
Funnel.prototype._pushData = function(){

	// Wait Area Block
	var b_tps = this._curr_data.block;
	if(b_tps > 0){
		var prev_wait_area_block_pos = this.getWaitAreaPos(this._prev_data);
		var curr_wait_area_block_pos = this.getWaitAreaPos(this._curr_data);
		var t_wait_area_data_block = new WaitArea();
		t_wait_area_data_block.setData(
			this._context,
			this._config.wait_area,
			prev_wait_area_block_pos,
			curr_wait_area_block_pos
		);
		this.wait_area_block_data = t_wait_area_data_block;
	}

	// Wait Area Bypass
	var b_tps = this._curr_data.bypass;
	if(b_tps > 0){
		var prev_wait_area_bypass_pos = this.getWaitAreaPos(this._prev_data);
		var curr_wait_area_bypass_pos = this.getWaitAreaPos(this._curr_data);
		var t_wait_area_data_bypass = new WaitArea();
		t_wait_area_data_bypass.setData(
			this._context,
			this._config.wait_area,
			prev_wait_area_bypass_pos,
			curr_wait_area_bypass_pos
		);
		this.wait_area_bypass_data = t_wait_area_data_bypass;
	}

	// Wait Area
	if(this.wait_area_data == null){
		this.wait_area_data = new WaitArea();
	}
	var prev_wait_area_pos = this.getWaitAreaPosLimit(this._prev_data);
	var curr_wait_area_pos = this.getWaitAreaPosLimit(this._curr_data);
	this.wait_area_data.setData(
		this._context,
		this._config.wait_area,
		this._target_div,
		prev_wait_area_pos,
		curr_wait_area_pos
	);

	// Wait Area Succ [move limit]
	var prev_wait_area_succ_pos = this.getWaitAreaSuccPos(this._prev_data);
	var curr_wait_area_succ_pos = this.getWaitAreaSuccPos(this._curr_data);
	var used_size_pre 			= this.getWaitAreaSuccRunSizePosSize(this._prev_data);
	var used_size_curr 			= this.getWaitAreaSuccRunSizePosSize(this._curr_data);
	var prev_used_size = this.getUsedSizeReal(this._prev_data);
	var curr_used_size= this.getUsedSizeReal(this._curr_data);
	var t_wait_area_succ_data = new WaitAreaSuccData();
	t_wait_area_succ_data.setData(
		this._context,
		this._config.wait_area_succ,
		prev_wait_area_succ_pos,
		curr_wait_area_succ_pos,
		prev_used_size,
		curr_used_size,
		used_size_pre,
		used_size_curr);
	this.wait_area_succ_data = t_wait_area_succ_data;

	// Wait Area Succ [fix limit]
	var prev_wait_area_succ_run_size_pos = this.getWaitAreaSuccRunSizePos(this._prev_data);
	var curr_wait_area_succ_run_size_pos = this.getWaitAreaSuccRunSizePos(this._curr_data);
//	console.log('curr_wait_area_succ_run_size_pos', curr_wait_area_succ_run_size_pos);
	var t_wait_area_succ_run_size_data = new WaitAreaSuccDataMove();
	t_wait_area_succ_run_size_data.setData(
			this._context,
			this._config.wait_area_succ,
			prev_wait_area_succ_run_size_pos,
			curr_wait_area_succ_run_size_pos);
	this.wait_area_succ_run_size_data = t_wait_area_succ_run_size_data;

	// Calc Wait Drop Properties
	{
		this.wait_drop_datas.speed_per_loop = (this._config.wait_drop.end_y - this._config.wait_drop.start_y)/
											(this._config.drop.running_time/this._config.draw_interval);
		var t_tps = this._curr_data.new_key_chk_enter;

		var arc_size = this._calcRadius(this._config.wait_drop,t_tps);
		this.wait_drop_datas.radius = arc_size.radius;
		this.wait_drop_datas.dropsize = arc_size.dropsize;

		if(t_tps > this._config.drop.tps_max) t_tps = this._config.drop.tps_max;
		this.wait_drop_datas.gap_per_drop = this._config.drop.tps_basis/t_tps;
	}

	// Block Wait Drop Properties
	{
		this.wait_block_drop_datas.speed_per_loop = (this._config.wait_drop.end_y - this._config.wait_drop.start_y)/
											(this._config.drop.running_time/this._config.draw_interval);
		var t_tps = this._curr_data.block;

		var arc_size = this._calcRadius(this._config.wait_drop,t_tps);
		this.wait_block_drop_datas.radius = arc_size.radius;
		this.wait_block_drop_datas.dropsize = arc_size.dropsize;

		if(t_tps > this._config.drop.tps_max) t_tps = this._config.drop.tps_max;
		this.wait_block_drop_datas.gap_per_drop = this._config.drop.tps_basis/t_tps;
	}
	// bypass Wait Drop Properties
	{
		this.wait_bypass_drop_datas.speed_per_loop = (this._config.wait_drop.end_y - this._config.wait_drop.start_y)/
											(this._config.drop.running_time/this._config.draw_interval);
		var t_tps = this._curr_data.bypass;

		var arc_size = this._calcRadius(this._config.wait_drop,t_tps);
		this.wait_bypass_drop_datas.radius = arc_size.radius;
		this.wait_bypass_drop_datas.dropsize = arc_size.dropsize;

		if(t_tps > this._config.drop.tps_max) t_tps = this._config.drop.tps_max;
		this.wait_bypass_drop_datas.gap_per_drop = this._config.drop.tps_basis/t_tps;
	}

	//Data Entry Drop
	{
		this.wait_entry_datas.speed_per_loop = (this._config.entry_drop.end_y - this._config.entry_drop.start_y)/
												(this._config.drop.running_time/this._config.draw_interval);
		var t_tps = this._curr_data.chk_enter_succ;

		var arc_size = this._calcRadius(this._config.entry_drop,t_tps);
		this.wait_entry_datas.radius = arc_size.radius;
		this.wait_entry_datas.dropsize = arc_size.dropsize;

		if(t_tps > this._config.drop.tps_max) t_tps = this._config.drop.tps_max;
		this.wait_entry_datas.gap_per_drop = this._config.drop.tps_basis/t_tps;
	}

	//Complete Drop
	this._pushDataComplete();

	//Complete Xview
	this._rolloverXview();
}
//--------------------------------------------------------
// Public Functions
//--------------------------------------------------------

// Block bypass 상태시 액션 별 처리
Funnel.prototype._draw_warn = function(){
	var data = this._curr_data;

	this._context_warn.clearRect (0, 0, this._config.canvas.warncanvas.width, this._config.canvas.warncanvas.height);

	var t_image = null;
	if(data.access_mode == 1){
		// bypass
		if(this._warn_status == "bgtext"){
			t_image = this._config.canvas.bypassbg;
			this._warn_status = "bgonly";
		}else if(this._warn_status == "bgonly"){
			t_image = this._config.canvas.bypass;
			this._warn_status = "bgtext";
		}else{
			t_image = this._config.canvas.bypass;
			this._warn_status = "bgtext";
		}
	}else if(data.access_mode ==2){
		// block
		if(this._warn_status == "bgtext"){
			t_image = this._config.canvas.blockbg;
			this._warn_status = "bgonly";
		}else if(this._warn_status == "bgonly"){
			t_image = this._config.canvas.block;
			this._warn_status = "bgtext";
		}else{
			t_image = this._config.canvas.block;
			this._warn_status = "bgtext";
		}
	}else if(data.access_mode == 0){
		if(data.ic_access_mode == 1){
			// bypass
			if(this._warn_status == "bgtext"){
				t_image = this._config.canvas.bypassbg;
				this._warn_status = "bgonly";
			}else if(this._warn_status == "bgonly"){
				t_image = this._config.canvas.bypassicbg;
				this._warn_status = "bgtext";
			}else{
				t_image = this._config.canvas.bypass;
				this._warn_status = "bgtext";
			}
		}else if(data.ic_access_mode ==2){
			// block
			if(this._warn_status == "bgtext"){
				t_image = this._config.canvas.blockbg;
				this._warn_status = "bgonly";
			}else if(this._warn_status == "bgonly"){
				t_image = this._config.canvas.blockicbg;
				this._warn_status = "bgtext";
			}else{
				t_image = this._config.canvas.block;
				this._warn_status = "bgtext";
			}
		}
	}
	this._context_warn.drawImage(t_image, 0 , 0, this._config.canvas.warncanvas.width, this._config.canvas.warncanvas.height);
}

Funnel.prototype.setData = function(data){

	if(this._draw_loop != null){
		clearInterval(this._draw_loop);
		this._draw_loop = null;
	}

	if(this._draw_loop_warn != null){
		clearInterval(this._draw_loop_warn);
		this._draw_loop_warn= null;
	}

	if(this._target_div.style.display == "none"){
		this._clearText();
		return;
	}

	this._prev_data = this._curr_data;
	this._curr_data = data;
	this._pushData();

	// Block , Bypass 의 Canvas 영역으로 인해서 진입량 tps가 클릭이 불가능하여 access_mode가 0일 경우는 display none

	if(data.access_mode == 0 && this._config.type == "action" && data.ic_access_mode == 0){
		this._canvas_warn.style.display = "none";
	}else{
		this._canvas_warn.style.display = "inline";
	}
	// Start Draw Loop
	var self = this;
	this._draw_loop = setInterval(function(){
		self._draw();
	},this._config.draw_interval);

	// Bg Canvas ReDraw
	{
		if(this._prev_data == null || this._prev_data.type != this._curr_data.type){
			this._context_bg.clearRect (0, 0, this._canvas.width, this._canvas.height);
			var t_bgimage = null;
			if(this._config.type == "action"){
				// Action Type Check
				if(this._curr_data.type == 1){
					this._action_type = 1;
					t_bgimage = this._config.canvas.bgimage_top.static;
				}else{
					this._action_type = 0;
					t_bgimage = this._config.canvas.bgimage_top.dynamic;
				}
			}else if(this._config.type == "service"){
				t_bgimage = this._config.canvas.bgimage_top;
				// 퍼넬 프레임 안그리기위해 비활성화
				// this._context_bg.drawImage(this._config.canvas.bgimage_back, 0 , 0, this._config.canvas.width, this._config.canvas.height);
			}

			// 퍼넬 프레임 안그리기위해 비활성화
			// this._context_bg.drawImage(t_bgimage, 0 , 0, this._config.canvas.width, this._config.canvas.height);
		}
	}
	{// Bg Canvas Block Bypass
		if(data.access_mode != 0){
			if(this._draw_loop_warn == null){
				var self = this;
				this._draw_loop_warn = setInterval(function(){
					self._draw_warn();
				},this._config.draw_warn_interval);
			}
		}else if(data.access_mode == 0 && data.ic_access_mode == 1){
			if(this._draw_loop_warn == null){
				var self = this;
				this._draw_loop_warn = setInterval(function(){
					self._draw_warn();
				},this._config.draw_warn_interval);
			}
		}else if(data.access_mode == 0 && data.ic_access_mode == 2){
			if(this._draw_loop_warn == null){
				var self = this;
				this._draw_loop_warn = setInterval(function(){
					self._draw_warn();
				},this._config.draw_warn_interval);
			}
		}else{
			this._context_warn.clearRect (0, 0, this._config.canvas.warncanvas.width, this._config.canvas.warncanvas.height);
			if(this._draw_loop_warn != null){
				clearInterval(this._draw_loop_warn);
				this._draw_loop_warn= null;
			}
		}
	}
	// Text Canvas ReDraw
	{
		this._context_text.clearRect (0, 0, this._canvas.width, this._canvas.height);
		this._textLinks = new Array();

		var limit = this.getLimit(this._curr_data);
		var limit_max = this.getLimitMax(this._curr_data);
		var limit_yt = this.getWaitAreaSuccRunSizePos(this._curr_data);

		var getChkEnterSucc = this.getChkEnterSucc(this._curr_data);
		var getCompleteSucc = this.getCompleteSucc(this._curr_data);
		var getNewKeyChk 	= this.getNewKeyChk(this._curr_data);
		var wait_tm			= this.getWaitTm(this._curr_data);
		var wait_user		= this.getWaitUser(this._curr_data);
		var bypass			= this.getBypass(this._curr_data);
		var block			= this.getBlock(this._curr_data);
		var access_mode		= this.getAccessMode(this._curr_data);

		var limitMax		= 0;
		// if(this.getChkEnterSucc(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			// this._drawText(this._context_text,humanReadableNumber(getChkEnterSucc)," tps",this._config.text.chk_enter_succ,"chk_enter_succ", this._curr_data.id);
		// }
		// if(this.getCompleteSucc(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			// this._drawText(this._context_text,humanReadableNumber(getCompleteSucc)," tps",this._config.text.complete_succ,"complete_succ", this._curr_data.id);
		// }
		// if(this.getNewKeyChk(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			// this._drawText(this._context_text,humanReadableNumber(getNewKeyChk)," tps",this._config.text.new_ck_enter,"new_key_chk_enter", this._curr_data.id);
		// }
		if(this.getAccessMode(this._curr_data) == 1 && bypass > 0 && this._config.type == "action"){
			// this._drawText(this._context_text,humanReadableNumber(bypass)," tps",this._config.text.bypass,"bypass", this._curr_data.id);
		}
		if(this.getAccessMode(this._curr_data) == 2 && block > 0 && this._config.type == "action"){
			// this._drawText(this._context_text,humanReadableNumber(block)," tps",this._config.text.block,"block", this._curr_data.id);
		}

		if( bypass > 0 && this._config.type == "service" ){
			// this._drawText(this._context_text,humanReadableNumber(bypass)," tps",this._config.text.bypass,"bypass", this._curr_data.id);
		}
		if( block > 0 && this._config.type == "service"){
			// this._drawText(this._context_text,humanReadableNumber(block)," tps",this._config.text.block,"block", this._curr_data.id);
		}
		// if(this.getWaitTm(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			// this._drawText(this._context_text,humanReadableNumber(wait_tm)," s",this._config.text.wait_time,"wait_tm", this._curr_data.id);
		// }
		// if(this.getWaitUser(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			// this._drawText(this._context_text,humanReadableNumber(wait_user,0),"[[.unit.persons]]",this._config.text.wait_user,"wait_user", this._curr_data.id);
		// }

		if( humanReadableNumber(limit_max,0) == 0){
			limitMax = "";
			// this._drawText(this._context_text,limit_max,"",this._config.text.limit_max,"limit_max", this._curr_data.id);
		}else{
			// this._drawText(this._context_text,humanReadableNumber(limit_max , 0),"",this._config.text.limit_max,"limit_max", this._curr_data.id);
		}

		// if (this._config.text.limit.pos.y_scale != undefined && this._config.text.limit.pos.y_scale > 1) {
			// this._config.text.limit.pos.y = this._config.text.limit.pos.ymax - limit_yt;
		// } else {
			this._config.text.limit.pos.y = this._config.text.limit.pos.ymax - limit_yt;
			// console.log('ymax :' + this._config.text.limit.pos.ymax);
			// console.log('limit_yt :' + limit_yt);
		// }

		if(humanReadableNumber(limit,0) == humanReadableNumber(limit_max,0)){
			limit = "";
			// this._drawText(this._context_text,limit,"",this._config.text.limit,"limit", this._curr_data.id);
		}else{
			// this._drawText(this._context_text,humanReadableNumber(limit,0),"",this._config.text.limit,"limit", this._curr_data.id);
		}
	}
}
Funnel.prototype.addShowPanel = function(prop_id){
	// Add to Show Panel
	this._callback(this._service_id,this._action_id,prop_id);
}

// Funnel Chart 리사이즈 여부 확인
Funnel.prototype.isResize = function() {

    if(this._prev_data == null || this._prev_data.type != this._curr_data.type){

    }

    return false;

}

Funnel.prototype.getAdjustPoint = function(def_data, def_size, chg_size) {

	return chg_size * (def_data / def_size);
}

Funnel.prototype.resizeDrawCanvas = function(width, height, type) {
	if (width == null || height == null) return;
	if (!(typeof width == "number") || !(typeof height == "number")) return;
	if (width < 1 || height < 1) return;

	var default_width = this._default_config.canvas.width;
	var default_height = this._default_config.canvas.height;

	//Wait Area
	var wait_area = this._default_config.wait_area;
	this._config.wait_area.start_x = this.getAdjustPoint(wait_area.start_x, default_width,  width);
	this._config.wait_area.start_y = this.getAdjustPoint(wait_area.start_y, default_height, height);
	this._config.wait_area.width   = this.getAdjustPoint(wait_area.width,   default_width,  width);
	this._config.wait_area.height  = this.getAdjustPoint(wait_area.height,  default_height, height);

	var curr_wait_area_pos = this.getWaitAreaPosLimit(this._curr_data);
	if ( curr_wait_area_pos ) {
	    this.wait_area_data._ypos = curr_wait_area_pos;
	    this.wait_area_data._end_ypos = curr_wait_area_pos;
	}

	//Wait Area Succ
	var wait_area_succ = this._default_config.wait_area_succ;
	this._config.wait_area_succ.start_x = this.getAdjustPoint(wait_area_succ.start_x, default_width,  width);
	this._config.wait_area_succ.start_y = this.getAdjustPoint(wait_area_succ.start_y, default_height, height);
	this._config.wait_area_succ.width   = this.getAdjustPoint(wait_area_succ.width,  default_width,  width);
	this._config.wait_area_succ.height  = this.getAdjustPoint(wait_area_succ.height, default_height, height);

	var curr_wait_area_succ_pos = this.getWaitAreaSuccPos(this._curr_data);
	if ( curr_wait_area_succ_pos ) {
	    this.wait_area_succ_data._ypos = curr_wait_area_succ_pos;
	    this.wait_area_succ_data._end_ypos = curr_wait_area_succ_pos;
	}

	var curr_wait_area_succ_run_size_pos = this.getWaitAreaSuccRunSizePos(this._curr_data);
	if ( curr_wait_area_succ_run_size_pos ) {
	    this.wait_area_succ_run_size_data._ypos = curr_wait_area_succ_run_size_pos;
	    this.wait_area_succ_run_size_data._end_ypos = curr_wait_area_succ_run_size_pos;
	}

	//Wait Drop
	var wait_drop = this._default_config.wait_drop;
	this._config.wait_drop.start_x.min        = this.getAdjustPoint(wait_drop.start_x.min,        default_width,   width);
	this._config.wait_drop.start_x.max        = this.getAdjustPoint(wait_drop.start_x.max,        default_width,   width);
	this._config.wait_drop.end_y              = this.getAdjustPoint(wait_drop.end_y,              default_height,  height);
	this._config.wait_drop.slide.start_y      = this.getAdjustPoint(wait_drop.slide.start_y,      default_height,  height);
	this._config.wait_drop.slide.end_y        = this.getAdjustPoint(wait_drop.slide.end_y,        default_height,  height);
	this._config.wait_drop.slide.area_x.start = this.getAdjustPoint(wait_drop.slide.area_x.start, default_width,   width);
	this._config.wait_drop.slide.area_x.end   = this.getAdjustPoint(wait_drop.slide.area_x.end,   default_width,   width);
	this._config.wait_drop.bypass_start_x     = this.getAdjustPoint(wait_drop.bypass_start_x,     default_width,   width);
	this._config.wait_drop.bypass_end_y       = this.getAdjustPoint(wait_drop.bypass_end_y,       default_height,  height);
//	this._config.wait_drop.block_start_x        = this.getAdjustPoint(wait_drop.block_start_x,        default_height,  height);
	this._config.wait_drop.block_end_y        = this.getAdjustPoint(wait_drop.block_end_y,        default_height,  height);

	this._config.wait_drop.resize_radius =  width * (wait_drop.radius / default_width) - wait_drop.radius;
	this._config.wait_drop.resize_dropsize = width * (wait_drop.dropsize / default_width) - wait_drop.dropsize;
	this._config.wait_drop.rain_drop_top = this.getAdjustPoint(12, default_height,  height);

	//Entry Area
	var entry_area = this._default_config.entry_area;
	this._config.entry_area.text_limit        = this.getAdjustPoint(entry_area.text_limit,        default_height,  height);
	this._config.entry_area.text_limit_max    = this.getAdjustPoint(entry_area.text_limit_max,    default_height,  height);
	this._config.entry_area.text_limit_yt     = this.getAdjustPoint(entry_area.text_limit_yt,     default_height,  height);
	this._config.entry_area.text_limit_max_yt = this.getAdjustPoint(entry_area.text_limit_max_yt, default_height,  height);

	//Entry Drop
	var entry_drop = this._default_config.entry_drop;
	this._config.entry_drop.start_x.min = this.getAdjustPoint(entry_drop.start_x.min, default_width, width);
	this._config.entry_drop.start_x.max = this.getAdjustPoint(entry_drop.start_x.max, default_width, width);
	this._config.entry_drop.start_y     = this.getAdjustPoint(entry_drop.start_y,     default_height,height);
	this._config.entry_drop.end_y       = this.getAdjustPoint(entry_drop.end_y,       default_height,height);

	this._config.entry_drop.slide.area_x.start = this.getAdjustPoint(entry_drop.slide.area_x.start, default_width, width);
	this._config.entry_drop.slide.area_x.end   = this.getAdjustPoint(entry_drop.slide.area_x.end  , default_width, width);
	this._config.entry_drop.slide.start_y      = this.getAdjustPoint(entry_drop.slide.start_y,      default_height,height);
	this._config.entry_drop.slide.end_y        = this.getAdjustPoint(entry_drop.slide.end_y,        default_height,height);

	this._config.entry_drop.resize_radius   = width * (entry_drop.radius / default_width) - entry_drop.radius;
	this._config.entry_drop.resize_dropsize = width * (entry_drop.dropsize / default_width) - entry_drop.dropsize;
	this._config.entry_drop.rain_drop_top = this.getAdjustPoint(12, default_height,  height);

	//XView
	var xview = this._default_config.xview;
	this._config.xview.start_x       = this.getAdjustPoint(xview.start_x, default_width,  width);
	this._config.xview.start_y       = this.getAdjustPoint(xview.start_y, default_height, height);
	this._config.xview.width         = this.getAdjustPoint(xview.width,   default_width,  width);
	this._config.xview.height        = this.getAdjustPoint(xview.height,  default_height, height);
//	this._config.xview.resize_radius = this.getAdjustPoint(xview.radius_min, default_width,  width);
	this._config.xview.radius_min = this.getAdjustPoint(xview.radius_min, default_width,  width);
	this._config.xview.radius_max = this.getAdjustPoint(xview.radius_max, default_width,  width);

	//Complete Drop
	var complete_area = this._default_config.complete_area;
	this._config.complete_area.start_x.min  = this.getAdjustPoint(complete_area.start_x.min,  default_width,  width);
	this._config.complete_area.start_x.max  = this.getAdjustPoint(complete_area.start_x.max,  default_width,  width);
	this._config.complete_area.start_x.step = this.getAdjustPoint(complete_area.start_x.step, default_width,  width);
	this._config.complete_area.start_y.min  = this.getAdjustPoint(complete_area.start_y.min,   default_height, height);
	this._config.complete_area.start_y.max  = this.getAdjustPoint(complete_area.start_y.max,   default_height, height);
	this._config.complete_area.start_y.step = this.getAdjustPoint(complete_area.start_y.step,  default_height, height);
	this._config.complete_area.drop_start   = this.getAdjustPoint(complete_area.drop_start,    default_height, height);

	this._config.complete_area.end_y = this._config.xview.start_y;

	if ( this._config.type == "service") {
		this._config.complete_area.complete_divide = this._config.xview.start_x + width * (6.5 / default_width);
	} else {
		this._config.complete_area.complete_divide = this._config.xview.start_x + width * (4 / default_width);
	}

	this._config.complete_area.complete_gap = Math.floor(this._config.xview.width / 10);
	this._config.complete_area.radius = width * (2 / default_width);

	//Text
	var canvas_scale = Math.floor(width / default_width);

	var size = canvas_scale > 1 ? this._default_config.text.new_ck_enter.font.size * canvas_scale : size = this._default_config.text.new_ck_enter.font.size;
	var unit_size = canvas_scale > 1 ? this._default_config.text.new_ck_enter.unit_font.size * canvas_scale : unit_size = this._default_config.text.new_ck_enter.unit_font.size;

//	this._config.text.resize = canvas_scale >= 1 ? canvas_scale : 0;
	this._config.text.resize = canvas_scale >= 1 ? canvas_scale : undefined;

	// 신규진입량(tps)
	var new_ck_enter = this._default_config.text.new_ck_enter;
	this._config.text.new_ck_enter.pos.x = this.getAdjustPoint(new_ck_enter.pos.x, default_width,  width);
	this._config.text.new_ck_enter.pos.y = this.getAdjustPoint(new_ck_enter.pos.y, default_height, height);
	this._config.text.new_ck_enter.font.size = size;
	this._config.text.new_ck_enter.unit_font.size = unit_size;

	// 대기자수(명)
	var wait_user = this._default_config.text.wait_user;
	this._config.text.wait_user.pos.x = this.getAdjustPoint(wait_user.pos.x, default_width,  width);
	if ( canvas_scale > 1 ) {
		this._config.text.wait_user.pos.y = this.getAdjustPoint(wait_user.pos.y, default_height, height) + canvas_scale;
	} else {
		this._config.text.wait_user.pos.y = this.getAdjustPoint(wait_user.pos.y, default_height, height);
	}
	this._config.text.wait_user.font.size = size;
	this._config.text.wait_user.unit_font.size = unit_size;

	// 대기시간(초)
	var wait_time = this._default_config.text.wait_time;
	this._config.text.wait_time.pos.x = this.getAdjustPoint(wait_time.pos.x, default_width,  width);
	this._config.text.wait_time.pos.y = this.getAdjustPoint(wait_time.pos.y, default_height, height);
	this._config.text.wait_time.font.size = size;
	this._config.text.wait_time.unit_font.size = unit_size;

	// 진입허용수 최대값
	var limit_max = this._default_config.text.limit_max;
	this._config.text.limit_max.pos.x = this.getAdjustPoint(limit_max.pos.x, default_width,  width);
	this._config.text.limit_max.pos.y = this.getAdjustPoint(limit_max.pos.y, default_height, height);

	// 해당 Service/Action 의 진입허용수
	var limit = this._default_config.text.limit;
	this._config.text.limit.pos.x    = this.getAdjustPoint(limit.pos.x,    default_width,  width);
	this._config.text.limit.pos.y    = this.getAdjustPoint(limit.pos.y,    default_height, height);
	this._config.text.limit.pos.ymax = this.getAdjustPoint(limit.pos.ymax, default_height, height);

	//사용량(진입허용수 중 사용하고 있는 수)
	var used_size = this._default_config.text.used_size;
	this._config.text.used_size.pos.x = this.getAdjustPoint(used_size.pos.x, default_width,  width);
	this._config.text.used_size.pos.y = this.getAdjustPoint(used_size.pos.y, default_height, height);

	var used_size_num = this._default_config.text.used_size_num;
	this._config.text.used_size_num.pos.x = this.getAdjustPoint(used_size_num.pos.x, default_width,  width);
	this._config.text.used_size_num.pos.y = this.getAdjustPoint(used_size_num.pos.y, default_height, height);

	var used_size_num_per = this._default_config.text.used_size_num_per;
	this._config.text.used_size_num_per.pos.x  = this.getAdjustPoint(used_size_num_per.pos.x, default_width,  width);
	this._config.text.used_size_num_per.pos.y  = this.getAdjustPoint(used_size_num_per.pos.y, default_height, height);

	var used_size_num_zero = this._default_config.text.used_size_num_zero;
	this._config.text.used_size_num_zero.pos.x = this.getAdjustPoint(used_size_num_zero.pos.x, default_width,  width);
	this._config.text.used_size_num_zero.pos.y = this.getAdjustPoint(used_size_num_zero.pos.y, default_height, height);

	// 진입허용수 (tps)
	var chk_enter_succ = this._default_config.text.chk_enter_succ;
	this._config.text.chk_enter_succ.pos.x = this.getAdjustPoint(chk_enter_succ.pos.x, default_width,  width);
	this._config.text.chk_enter_succ.pos.y = this.getAdjustPoint(chk_enter_succ.pos.y, default_height, height);
	this._config.text.chk_enter_succ.font.size = size;
	this._config.text.chk_enter_succ.unit_font.size = unit_size;

	// 완료수 (tps)
	var complete_succ = this._default_config.text.complete_succ;
	this._config.text.complete_succ.pos.x = this.getAdjustPoint(complete_succ.pos.x, default_width,  width);
	this._config.text.complete_succ.pos.y = this.getAdjustPoint(complete_succ.pos.y, default_height, height);
	this._config.text.complete_succ.font.size = size;
	this._config.text.complete_succ.unit_font.size = unit_size;

	//bypass
	var bypass = this._default_config.text.bypass;
	this._config.text.bypass.pos.x = this.getAdjustPoint(bypass.pos.x, default_width,  width);
	this._config.text.bypass.pos.y = this.getAdjustPoint(bypass.pos.y, default_height, height);
	this._config.text.bypass.font.size = size;
	this._config.text.bypass.unit_font.size = unit_size;

	//block
	var block = this._default_config.text.block;
	this._config.text.block.pos.x = this.getAdjustPoint(block.pos.x, default_width,  width);
	this._config.text.block.pos.y = this.getAdjustPoint(block.pos.y, default_height, height);
	this._config.text.block.font.size = size;
	this._config.text.block.unit_font.size = unit_size;

	//Canvas
	this._canvas.width         = width;
	this._canvas.height        = height;
	this._canvas_bg.width      = width;
	this._canvas_bg.height     = height;
	this._canvas_text.width    = width;
	this._canvas_text.height   = height;
	this._config.canvas.width  = width;
	this._config.canvas.height = height;

	//CanvasWarn
	var warncanvas = this._default_config.canvas.warncanvas;
	this._config.canvas.warncanvas.x      = this.getAdjustPoint(warncanvas.x, default_width,  width);
	this._config.canvas.warncanvas.y      = this.getAdjustPoint(warncanvas.y, default_height,  height);
	this._config.canvas.warncanvas.width  = this.getAdjustPoint(warncanvas.width, default_width,  width);
	this._config.canvas.warncanvas.height = this.getAdjustPoint(warncanvas.height, default_height,  height);

	this._canvas_warn.style.top  = this._config.canvas.warncanvas.y + 'px';

	if ( this._config.type == "service" ) {
	    var canvas_warn_left = parseInt(this._canvas.style.left.substring(0, this._canvas.style.left.length-2)) + this._config.canvas.warncanvas.x;
	    this._canvas_warn.style.left = canvas_warn_left + 'px';
	} else {
	    this._canvas_warn.style.left = this._config.canvas.warncanvas.x + 'px';
	}
	this._canvas_warn.width      = this._config.canvas.warncanvas.width;
	this._canvas_warn.height     = this._config.canvas.warncanvas.height;

	this._context_bg.clearRect (0, 0, this._canvas.width, this._canvas.height);
	var t_bgimage = null;

	if(this._config.type == "action"){
		// Action Type Check
		if(this._action_type == 1){
			t_bgimage = this._config.canvas.bgimage_top.static;
		}else{
			t_bgimage = this._config.canvas.bgimage_top.dynamic;
		}
	}else if(this._config.type == "service"){
		t_bgimage = this._config.canvas.bgimage_top;
		this._context_bg.drawImage(this._config.canvas.bgimage_back, 0 , 0, this._config.canvas.width, this._config.canvas.height);
	}
	this._context_bg.drawImage(t_bgimage, 0 , 0, this._config.canvas.width, this._config.canvas.height);

	// Text Canvas ReDraw
	{
		this._context_text.clearRect (0, 0, this._canvas.width, this._canvas.height);
		this._textLinks = new Array();
		var limit = this.getLimit(this._curr_data);
		var limit_max = this.getLimitMax(this._curr_data);
		var limit_yt = this.getWaitAreaSuccRunSizePos(this._curr_data);

		var getChkEnterSucc = this.getChkEnterSucc(this._curr_data);
		var getCompleteSucc = this.getCompleteSucc(this._curr_data);
		var getNewKeyChk 	= this.getNewKeyChk(this._curr_data);
		var wait_tm			= this.getWaitTm(this._curr_data);
		var wait_user		= this.getWaitUser(this._curr_data);
		var bypass			= this.getBypass(this._curr_data);
		var block			= this.getBlock(this._curr_data);
		var access_mode		= this.getAccessMode(this._curr_data);

		var limitMax		= 0;
		if(this.getChkEnterSucc(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			this._drawText(this._context_text,humanReadableNumber(getChkEnterSucc)," tps",this._config.text.chk_enter_succ,"chk_enter_succ", this._curr_data.id);
		}
		if(this.getCompleteSucc(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			this._drawText(this._context_text,humanReadableNumber(getCompleteSucc)," tps",this._config.text.complete_succ,"complete_succ", this._curr_data.id);
		}
		if(this.getNewKeyChk(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			this._drawText(this._context_text,humanReadableNumber(getNewKeyChk)," tps",this._config.text.new_ck_enter,"new_key_chk_enter", this._curr_data.id);
		}
		if(this.getAccessMode(this._curr_data) == 1 && bypass > 0 && this._config.type == "action"){
			this._drawText(this._context_text,humanReadableNumber(bypass)," tps",this._config.text.bypass,"bypass", this._curr_data.id);
		}
		if(this.getAccessMode(this._curr_data) == 2 && block > 0 && this._config.type == "action"){
			this._drawText(this._context_text,humanReadableNumber(block)," tps",this._config.text.block,"block", this._curr_data.id);
		}

		if( bypass > 0 && this._config.type == "service" ){
			this._drawText(this._context_text,humanReadableNumber(bypass)," tps",this._config.text.bypass,"bypass", this._curr_data.id);
		}
		if( block > 0 && this._config.type == "service"){
			this._drawText(this._context_text,humanReadableNumber(block)," tps",this._config.text.block,"block", this._curr_data.id);
		}
		if(this.getWaitTm(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			this._drawText(this._context_text,humanReadableNumber(wait_tm)," s",this._config.text.wait_time,"wait_tm", this._curr_data.id);
		}
		if(this.getWaitUser(this._curr_data) != 0 && this.getAccessMode(this._curr_data) == 0){
			this._drawText(this._context_text,humanReadableNumber(wait_user,0),"[[.unit.persons]]",this._config.text.wait_user,"wait_user", this._curr_data.id);
		}

		if( humanReadableNumber(limit_max,0) == 0){
			limitMax = "";
			this._drawText(this._context_text,limit_max,"",this._config.text.limit_max,"limit_max", this._curr_data ? this._curr_data.id : '');
		}else{
			this._drawText(this._context_text,humanReadableNumber(limit_max , 0),"",this._config.text.limit_max,"limit_max", this._curr_data.id);
		}

		// if (this._config.text.limit.pos.y_scale != undefined && this._config.text.limit.pos.y_scale > 1) {
			// this._config.text.limit.pos.y = this._config.text.limit.pos.ymax - limit_yt;
		// } else {
			this._config.text.limit.pos.y = this._config.text.limit.pos.ymax - limit_yt;
			// console.log('ymax :' + this._config.text.limit.pos.ymax);
			// console.log('limit_yt :' + limit_yt);
		// }

		if(humanReadableNumber(limit,0) == humanReadableNumber(limit_max,0)){
			limit = "";
			this._drawText(this._context_text,limit,"",this._config.text.limit,"limit", this._curr_data ? this._curr_data.id : '');
		}else{
			this._drawText(this._context_text,humanReadableNumber(limit,0),"",this._config.text.limit,"limit", this._curr_data.id);
		}
	}

}

Funnel.prototype.lang = {
	new_key_chk_enter: '[[.word.newkey_q]]',
	wait_user: '[[.word.wait_user]]',
	wait_tm: '[[.word.wait_time]]',
	chk_enter_succ: '[[.word.enter_q]]',
	limit_max: '[[.word.enter_allowed_q_max]]',
	limit: '[[.word.enter_allowed_q]]',
	used_size: '[[.word.resource_usage_q]] ([[.word.resource_usage_rate]])',
	complete_succ: '[[.word.complete_q]]',
	bypass: '[[.bypass]]',
	block: '[[.blocking]]',
};

///////////////////////////////////////////////////////////////////////////////////////
/**
 * ServiceFunnel Class
 *
 * Canvas를 그려주기위한 Class
 */
//////////////////////////////////////////////////////////////////////////////////////
export var ServiceFunnel = function(target_id,service_id,config,callback){
	this._self = this;
	this._config = new ServiceConfig(config);
	this._default_config = new ServiceConfig(config);
	this._callback = callback;
	if(service_id != undefined){
		this._service_id = service_id;
	}
	this._init(target_id);
};
ServiceFunnel.prototype = new Funnel();


export var ActionFunnel = function(target_id,service_id,action_id, config,callback){
	this._self = this;
	this._config = new ActionConfig(config);
	this._default_config = new ActionConfig(config);
	this._callback = callback;
	if(service_id != undefined){ this._service_id = service_id; }
	if(action_id != undefined){ this._action_id = action_id; }
	this._init(target_id);
};
ActionFunnel.prototype = new Funnel();
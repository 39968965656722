/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import { useRef } from 'react';
import { paletteSDS } from 'surf-design-system';

import FunnelProjectTooltip from 'features/nfBasic/components/FunnelProjectTooltip';
import FunnelSegmentTooltip from 'features/nfBasic/components/FunnelSegmentTooltip';

interface FunnelProjectValueProps {
	textColor: string;
	isHover: boolean;
	value: number;
	decimal: number;
	tooltipText: string;
	nameStr: string;
	unit: string;
}

export default function FunnelProjectValue({
	textColor = '',
	isHover = false,
	value = 0,
	decimal = 0,
	tooltipText = '',
	nameStr = '',
	unit = '',
}: FunnelProjectValueProps) {
	const divRef = useRef<HTMLDivElement>(null);

	return (
		<div css={FunnelProjectValueCss} ref={divRef}>
			<span css={valueCss(textColor, isHover)}>{value === 0 ? 0 : value.toFixed(decimal)}</span>
			{tooltipText ? (
				<FunnelProjectTooltip isShow={isHover} value={value} text={tooltipText} title={nameStr} />
			) : (
				<FunnelSegmentTooltip
					isShow={isHover}
					value={`${value.toFixed(decimal)}${unit}`}
					name={nameStr}
					top={0}
					parentWidth={divRef.current?.offsetWidth}
				/>
			)}
		</div>
	);
}

const FunnelProjectValueCss = css`
	position: relative;
`;

const valueCss = (textColor: string, isHover: boolean) => css`
	padding: 0 4px;
	background-color: ${isHover ? paletteSDS.gray[900] : 'transparent'};
	border-radius: 4px;
	color: ${isHover ? paletteSDS.systemColor.white : textColor};
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.16px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link, paletteSDS, themeSDS } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';

export default function SignupTitle() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div css={authUpperContentsWrapSt}>
			<div css={authHeaderBtnWrapSt}>
				<div css={authUpperHeaderWrapSt}>
					<div css={titleSt}>{t('singnUp.createAccount')}</div>
					<div css={descWrap}>
						<div css={gap4Column}>
							<div css={freeBetaSt}>{t('freeBeta.try')}</div>
							<div css={titleBackToSigninCss}>
								<div css={whiteSpace}>{t('singnUp.alrAccount')}</div>
								<Link size="medium" onClick={() => navigate(`/${getI18nPath()}/signin`)}>
									{t('signIn.signIn')}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const authUpperContentsWrapSt = css`
	display: flex;
	flex-direction: column;
`;

const authHeaderBtnWrapSt = css`
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 2rem;
`;
const authUpperHeaderWrapSt = css`
	display: flex;
	flex-direction: column;

	gap: 1rem;
`;

const titleSt = css`
	text-align: center;
	letter-spacing: -0.008em;
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.gray[900]};
`;

// const titleDescriptionCss = css`
// 	font-size: ${themeSDS.fontSize[16]};
// 	font-weight: ${themeSDS.fontWeight.regular};
// `;

const titleBackToSigninCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.25rem;

	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[14]};
`;

const whiteSpace = css`
	margin: 0 0.2rem;
`;

const descWrap = css`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`;

const gap4Column = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const freeBetaSt = css`
	color: ${paletteSDS.gray['900']};
	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.medium};

	text-align: center;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { paletteSDS } from 'surf-design-system';

export interface EUMChartBoxProps {
	children?: React.ReactNode;
}

export default function URLChartBox({ children }: EUMChartBoxProps) {
	return <div css={chartBoxCss}>{children}</div>;
}
const chartBoxCss = css`
	height: 403px;
	width: 804px;
	padding: 16px;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	background-color: ${paletteSDS.white};
`;

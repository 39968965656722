/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import { CloseIcon, paletteSDS, themeSDS } from 'surf-design-system';
// component

export interface FileUploadElementProps {
	id: string;
	name: string;
	onDelete: (id: string) => void;
}

/**
 * author: ian
 * createdAt: 2022-07-13
 * updatedAt: 2022-07-13
 * description: Ask modal에서 사용되는 파일 추가 및 삭제 컴포넌트
 * todo: 채널톡 데이터 송수신 방식에 따른 파일리스트 타입 설정
 */
export default function FileUploadElement({ id, name, onDelete }: FileUploadElementProps) {
	const handleClickCloseIcon = () => {
		onDelete(id);
	};

	return (
		<div css={editibleListWrap}>
			<div css={guidanceSt} />
			<div css={nameIconWrap}>
				<div css={listSt}>{name}</div>
				<div>
					<CloseIcon size="xSmall" fill={paletteSDS.blue[700]} onClick={handleClickCloseIcon} cursor="pointer" />
				</div>
			</div>
		</div>
	);
}

const editibleListWrap = css`
	display: flex;
	gap: 1rem;

	width: 100%;
	padding-right: 0.25rem;
	height: 1.5rem;
	align-items: center;
`;

const guidanceSt = css`
	width: 0.25rem;
	height: 1.5rem;
	border-radius: 4px;

	background: ${paletteSDS.blue[100]};
`;

const nameIconWrap = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;

	width: 100%;
	max-width: calc(100% - 1.5rem);
`;

const listSt = css`
	width: 100%;

	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray[700]};

	overflow: hidden;
	text-overflow: ellipsis;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { setIsSegmentMaxNumModalVisible } from 'common/context/modalSlice';

export default function OnPremSegmentMaxNumModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isSegmentMaxNumModalVisible } = useSelector((state: RootState) => state.modal);

	const primaryButtonClickHandler = () => {
		dispatch(setIsSegmentMaxNumModalVisible(false));
	};

	return (
		<Portal>
			<Modal
				visible={isSegmentMaxNumModalVisible}
				actionType="single"
				primaryBtnFunction={primaryButtonClickHandler}
				primaryBtnLabel={t('modal.segNumConfirm')}
				isCloseIconVisible={false}
				title={t('modal.segCreationExceed')}
			>
				<div css={modalTxtSt}>{t('modal.segNumLicense')}</div>
			</Modal>
		</Portal>
	);
}

const modalTxtSt = css`
	white-space: break-spaces;
	line-height: 160%;
`;

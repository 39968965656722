export const LANG_KEY = 'i18nextLng';
export const DEFAULT_IMG_URL = 'default_image_url';
export const COGNITO_TOKEN = 'cognitoToken';
export const COGNITO_REFRESH_TOKEN = 'cognitoRefreshToken';
export const USER_KEY = 'userKey';
export const NETFUNNEL = 'NETFUNNEL';
export const DATASURFER = 'DATASURFER';

export const LANG_HEADER = {
	KOREAN: 'ko-KR',
	ENGLISH: 'en-US',
	NONE: '',
};

export const NOTI_ASK = 'ASK';
export const NOTI_COMMON = 'COMMON';
export const NOTI_PRD = 'PRD';
export const NOTI_PRJ = 'PRJ';
export const NOTI_NOTICE = 'NOTICE';
export const NOTI_NF = 'NETFUNNEL';
export const NOTI_DS = 'DATASURFER';
export const NOTI_DISCONNECT = 'DISCONNECT';
export const NOTI_NUM_PER_PAGE = 10;
export const NOTI_START_PAGE = 1;
export const NOTI_ID_QUERY = 'id';
export const NOTI_PAGE_QUERY = 'page';

export const NOTI_VWR = 'VWR';
export const NOTI_DOMAIN = 'DOMAIN';
export const NOTI_SEG = 'SEG';
export const NOTI_SSEG = 'SSEG';

export const PLAN_SUBSCRIPTION_REMINDER_MODAL_RENDER = 'RENDER';

export const DEFAULT_PRJ_KEY = 1;

export const LANG_PATH = {
	KOREAN: 'ko',
	ENGLISH: 'en',
};

export const POST_NOTI_TEXT_KEY = {
	ask: {
		header: 'ASK',
		type: 'ASK',
		iconType: 'ASK',
		targetType: 'USER',
		ko: {
			title: '문의하신 내용이 정상적으로 접수되었습니다.',
			body: '문의하신 내용이 정상적으로 접수되었습니다. 최대한 빠르게 답변을 전달해드릴 수 있도록 하겠습니다.\n감사합니다.',
		},
		en: {
			title: 'Your request has been successfully submitted.',
			body: 'Your request has been successfully submitted. We will get back to you as soon as possible.\nThank you.',
		},
	},
	ownerTransfer: {
		header: 'ASK',
		type: 'ASK',
		iconType: 'ASK',
		targetType: 'USER',
		ko: {
			title: '오너 권한 이전 요청이 정상적으로 접수되었습니다.',
			body: '오너 권한 이전 요청이 정상적으로 접수되었습니다. 요청 사항 확인 후 전달해드리겠습니다.\n감사합니다.',
		},
		en: {
			title: 'Ownership transfer request has been sent.',
			body: 'Ownership transfer request has been sent. We will get back to you after check your request.\nThank you.',
		},
	},
};

export const NF_CONTROLS_VIEW_MODE_KEY = 'viewMode';

export const PLAN_SMALL_ID = 1;
export const PLAN_MEDIUM_ID = 2;
export const PLAN_LARGE_ID = 3;
export const PLAN_FREE_TRIAL_ID = 4;
export const PLAN_FREE_BETA_ID = 5;
export const PLAN_MOIS_2022_ID = 6;
export const PLAN_LGE_ID = 7;
export const PLAN_NH_BANK_ID = 8;
export const PLAN_ENTERPRISE_ID = -1;

export const TENANT_FAILED_ASK_TITLE = {
	KOREAN: '[Error] 고객 환경 구성 실패',
	ENGLISH: '[Error] Service configuration failure',
};

export const PROJECT_ID_INDEX = 6;

export const IOS_PRODUCT_CODE = 'NETFUNNEL_IOS_AGENT';
export const AOS_PRODUCT_CODE = 'NETFUNNEL_AOS_AGENT';

export const ONPREM_PRODUCT_MODE = 'ONPREM';

export const ONPREM_CENTER_API_PATH = 'center';
export const ONPREM_ASSETS_PATH = 'assets';
export const ONPREM_EUM_API_PATH = 'eum';
export const ONPREM_AGENTS_PATH = 'agents';

import { createAsyncThunk } from '@reduxjs/toolkit';

import postUserProfileImageApi from 'features/setting/api/postUserProfileImage.api';

export interface PostUserProfileImageThunkProps {
	image: File;
}

export interface PostUserProfileImageThunkResult {
	imageUrl: string;
}

export const postUserProfileImageThunk = createAsyncThunk<
	PostUserProfileImageThunkResult,
	PostUserProfileImageThunkProps
>('userProfileImage/Post', async (args: PostUserProfileImageThunkProps, thunkAPI) => {
	const { image } = args;

	try {
		const {
			data: { imageUrl },
		} = await postUserProfileImageApi({ image });

		return {
			imageUrl: `${imageUrl}?${Date.now()}`,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

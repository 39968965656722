import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import type { languageType } from 'common/types/getUserProfile.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutUserLanguageApiResponse } from 'features/setting/types/putUserLanguage.type';

export interface PutUserLanguageApiProps {
	language: languageType;
}

export async function putUserLanguageApi({ language }: PutUserLanguageApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutUserLanguageApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/user/language`,
			{
				language,
			},
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

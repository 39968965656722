/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import LicenseStatusLabel from 'features/license/components/LicenseStatusLabel';

export default function LicenseDetailCard() {
	const { t } = useTranslation();

	const {
		detail: { projectName, customerServiceDomain, segCount, licensedSegCount, licenseExpiredAt, licenseExpiredYn },
		isLoaded,
	} = useSelector((state: RootState) => state.license);

	if (!isLoaded) {
		return <div />;
	}

	return (
		<div css={containerCss}>
			<p css={prjNameCss}>{projectName}</p>
			<p css={domainCss}>{customerServiceDomain}</p>
			<div css={detailWrap}>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.segments')}</div>
					<div>
						{segCount}&nbsp;/&nbsp;{licensedSegCount}
					</div>
				</div>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.licenseStatus')}</div>
					<div>
						<LicenseStatusLabel licenseExpiredYn={licenseExpiredYn} />
					</div>
				</div>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.licenseEndDate')}</div>
					<div>{moment(licenseExpiredAt).utcOffset('+UTC09:00').format('YYYY-MM-DD')}</div>
				</div>
			</div>
		</div>
	);
}

const containerCss = css`
	width: 100%;
	padding: 2rem;
	background-color: ${paletteSDS.gray[50]};
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 8px;
`;

const prjNameCss = css`
	font-size: ${themeSDS.fontSize[28]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 128%;
`;

const domainCss = css`
	margin-top: 0.5rem;
	color: ${paletteSDS.gray[600]};
	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.light};
	line-height: 140%;
`;

const detailWrap = css`
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
`;

const detailContentsCss = css`
	display: flex;

	> div:nth-of-type(1) {
		width: 11.25rem;
		color: ${paletteSDS.gray[900]};
		font-weight: ${themeSDS.fontWeight.semiBold};
		text-align: left;
	}
`;

import { validationSDS } from 'surf-design-system';

export interface OrganizationNameForm {
	organizationName: string;
	organizationNameValidation: validationSDS;
	isOrganizationNameValid: boolean;
	isOrganizationNameChanged: boolean;
	organizationNameHelpText: string;
}

export interface OrganizationNameFormAction {
	type?: string;
	payload?: string;
}

export const initialOrganizationNameForm: OrganizationNameForm = {
	organizationName: '',
	isOrganizationNameChanged: false,
	isOrganizationNameValid: false,
	organizationNameHelpText: 'modal.changeOrgNameMsg',
	organizationNameValidation: 'failed',
};

export const UPDATE_NAME = 'UPDATE_NAME';
export const VALIDATE_NAME = 'VALIDATE_NAME';

export const organizationNameFormReducer = (
	state: OrganizationNameForm,
	action: OrganizationNameFormAction
): OrganizationNameForm => {
	switch (action.type) {
		case UPDATE_NAME: {
			const isPayloadValid = (action.payload || '').trim().length > 0;
			return {
				...state,
				isOrganizationNameChanged: true,
				isOrganizationNameValid: isPayloadValid,
				organizationName: action.payload || '',
				organizationNameValidation: isPayloadValid ? 'none' : 'failed',
			};
		}

		case VALIDATE_NAME: {
			const isNameStateValid = state.organizationName.trim().length > 0;
			return {
				...state,
				isOrganizationNameValid: state.isOrganizationNameChanged ? isNameStateValid : false,
				organizationNameValidation: state.isOrganizationNameChanged && isNameStateValid ? 'none' : 'failed',
			};
		}

		default:
			return initialOrganizationNameForm;
	}
};

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PostOrganizationAddressApiResponse } from '../types/postOrganizationAddress.type';

export interface PostOrganizationAddressApiProps {
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
	organizationId: number;
}

export default async function postOrganizationAddressApi({
	vat,
	city,
	country,
	streetAddress,
	streetAddressDetail,
	organizationId,
	region,
	postalCode,
}: PostOrganizationAddressApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.post<PostOrganizationAddressApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/organization/${organizationId}/address`,
			{
				country,
				streetAddress,
				streetAddressDetail,
				city,
				region,
				postalCode,
				vat,
			},
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import axiosInstance from 'common/utils/axiosInstance';
import { ONPREM_CENTER_API_PATH } from 'common/data/constants';
import type { GetTimezoneListApiResponse } from 'features/setting/types/getTimezoneList.type';

export default async function getTimezoneListApi() {
	const { data } = await axiosInstance.get<GetTimezoneListApiResponse>(`/v1/timezones`, {
		baseURL:
			process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
				? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
				: process.env.REACT_APP_BASE_URL,
	});
	return data;
}

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';
import { PutProjectActivatedYnApiResponse } from '../types/putProjectActivatedYn.type';

export interface PutProjectActivatedYnApiProps {
	tenantId: string;
	tenantUrl: string;
	projectId: number;
	organizationId: number;
	activatedYn: boolean;
}

export const putProjectActivatedYnApi = async ({
	tenantId,
	tenantUrl,
	projectId,
	organizationId,
	activatedYn,
}: PutProjectActivatedYnApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken && organizationId !== 0) {
		const { data } = await axios.put<PutProjectActivatedYnApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}/activation`,
			{
				activatedYn,
			},
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

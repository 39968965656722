/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AddIcon, Button, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { setInitialToast } from 'common/context/toastSlice';
import VWRTable from 'features/vwr/components/VWRTable';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VWRDeleteModal from 'features/vwr/components/VWRDeleteModal';
import VWRDeleteCompleteModal from 'features/vwr/components/VWRDeleteCompleteModal';
import { getVWRListThunk } from 'features/vwr/thunk/getVWRList.v2.thunk';

export default function VWRList() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { isDeleteModalVisible } = useSelector((state: RootState) => state.modal.vwr);

	const addButtonClickHandler = () => {
		navigate('create');
	};

	useEffect(() => {
		dispatch(getVWRListThunk());

		return () => {
			dispatch(setInitialToast());
		};
	}, [dispatch]);

	return (
		<div css={container}>
			<div css={titleBox}>
				<div css={titleSt}>{t('projectMgmt.vwrSetting')}</div>
				<Button
					iconPosition="left"
					icon={<AddIcon fill={paletteSDS.gray['50']} />}
					onClick={addButtonClickHandler}
					size="medium"
				>
					{t('vwr.add')}
				</Button>
			</div>
			<VWRTable />
			{isDeleteModalVisible ? <VWRDeleteModal /> : null}
			<VWRDeleteCompleteModal />
		</div>
	);
}

const container = css`
	width: 960px;

	padding: 32px 24px 80px;

	margin: 0 auto;

	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const titleSt = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['24']};
`;

const titleBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import OnPremMemberCard from 'features/setting/components/OnPremMemberCard';

const { fontWeight, fontSize } = themeSDS;
const { gray, systemColor } = paletteSDS;

export default function OnPremMemberTable() {
	const { t } = useTranslation();

	const { userRole } = useSelector((state: RootState) => state.account);

	const { isMembersLoaded, members } = useSelector((state: RootState) => state.onPremMember);

	return (
		<div css={memberListCss}>
			<div css={listHeaderCss}>
				<div css={longTextCss}>{t('commonWords.userName')}</div>
				<div css={longTextCss}>ID</div>
				<div css={shortTextCss}>{t('commonWords.role')}</div>
				<div css={shortTextCss}>{t('organizationSettings.sts')}</div>
				{userRole === 'OWNER' && <div css={[shortTextCss, actionCss]}>{t('commonWords.actions')}</div>}
			</div>
			<ul css={ulCss}>
				{isMembersLoaded ? members.map(member => <OnPremMemberCard key={member.key} member={member} />) : null}
			</ul>
		</div>
	);
}

const memberListCss = css`
	width: 100%;
	overflow: hidden;
	transition: all 1s ease;

	& li:first-of-type {
		border-top: none;
	}

	& li:not(:last-child) {
		border-bottom: none;
	}
`;

const ulCss = css`
	height: 35rem;
	min-height: 3rem;
	overflow-y: hidden;

	&::-webkit-scrollbar {
		width: 1.875rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${systemColor.overlay};
		border-radius: 0.9375rem;
		background-clip: padding-box;
		border: 0.625rem solid transparent;
	}

	&:hover {
		overflow: overlay;
	}
`;

const listHeaderCss = css`
	display: flex;
	align-items: center;
	flex-basis: 1;
	width: 100%;
	height: 3rem;
	padding: 0.75rem 1rem;
	border-top: 1px solid ${gray[300]};
	border-bottom: 1px solid ${gray[300]};
`;

const textCss = css`
	color: ${gray[900]};
	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;
`;

const longTextCss = css`
	width: 29%;
	min-width: 12.5rem;
	${textCss}
`;

const shortTextCss = css`
	width: 29%;
	min-width: 5rem;
	${textCss}
`;

const actionCss = css`
	width: 10%;
	min-width: 5rem;
`;

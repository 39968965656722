/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Modal, Portal, themeSDS } from 'surf-design-system';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsPendingModalVisible } from 'common/context/modalSlice';

/**
 * author: Finn
 * createdAt: 2022-07-11
 * updatedAt: 2022-08-17
 * description: 트라이얼 기간 종료 모달
 * todo:
 */
export default function PendingModal() {
	const { isPendingModalVisible } = useSelector((state: RootState) => state.modal);

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	/**
	 * description: 모달 닫기 제어 함수
	 */
	const onCloseModal = () => {
		dispatch(toggleIsPendingModalVisible());
	};

	const modalBody = (
		<React.Fragment>
			<p>{t('console.main.pendingNotiMsg1')}</p>
			<p>{t('modal.pendingModalMessage')}</p>
		</React.Fragment>
	);

	const modalTitle = t('modal.pendingNotiTitle');

	return (
		<Portal>
			<Modal
				visible={isPendingModalVisible}
				title={modalTitle}
				actionType="single"
				primaryBtnLabel={t('modal.ok')}
				primaryBtnFunction={onCloseModal}
				onClickOverlay={onCloseModal}
				onClickCloseButton={onCloseModal}
			>
				<div css={textSt}>{modalBody}</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	line-height: 1.375rem;
	font-size: ${themeSDS.fontSize[14]};
`;

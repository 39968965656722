import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import type { userRoleType } from 'common/types/getOrganizationList.type';

import postMemberListApi from 'features/setting/api/postMemeberList.api';
import type { PostMemberListFailData } from 'features/setting/types/postMemberList.type';

export interface PostMemberListThunkProps {
	email: string[];
	role: userRoleType;
}

// todo 초대 성공 멤버들 리스트에 추가(api response 에 추가 정보 필요)
export interface PostMemberListThunkResult {
	success: string[];
	fail: PostMemberListFailData[];
}

export const postMemberListThunk = createAsyncThunk<
	PostMemberListThunkResult,
	PostMemberListThunkProps,
	{ state: RootState }
>('memberList/Post', async (args, thunkAPI) => {
	const { email, role } = args;

	const {
		account: { organizationId },
	} = thunkAPI.getState();
	try {
		const {
			data: { success, fail },
		} = await postMemberListApi({ organizationId, email: [...email], selectedProductType: 'NETFUNNEL', role });

		return { success, fail };

		// thunkAPI.dispatch(toggleIsInviteMemberModalVisible());
		//
		// if (success.length === 0) {
		// 	thunkAPI.dispatch(toggleIsInviteAllFailToastVisible());
		// 	return;
		// }
		//
		// if (fail.length === 0) {
		// 	thunkAPI.dispatch(toggleIsInviteAllSuccessToastVisible());
		// 	thunkAPI.dispatch(getAllMemberListThunk());
		// 	return;
		// }
		//
		// thunkAPI.dispatch(toggleIsInviteNotAllSuccessToastVisible());

		// eslint-disable-next-line
	} catch (error: any) {
		// eslint-disable-next-line
		console.log(error);

		return thunkAPI.rejectWithValue(error);
	}
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { setIsSegmentMaxNumModalVisible } from 'common/context/modalSlice';

import { isAxiosError } from 'common/api/isAxiosError';

import postPathControlSegmentApi from 'features/nfControl/api/postPathControlSegment.v2.api';
import type { PostBasicControlSegmentApiError } from 'features/nfControl/type/postBasicControlSegment.v2.type';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';
import type { MemberInfo } from 'features/nfControl/context/segmentCreateDynamicForm.onPrem.reducer';
import postOperatorApi from 'features/nfControl/api/postOperator.v2.api';

interface PostPathControlSegmentOnPremThunkProps {
	segmentName: string;
	accessYn: boolean;
	maxInflow: string;
	endUri: string;
	startUri: string;
	onSuccess: () => void;
	vwrPageId: number;
	members: MemberInfo[];
}

const postPathControlSegmentOnPremThunk = createAsyncThunk<
	Omit<SegmentMetaData, 'segmentGraphYn' | 'segmentOrder'>,
	PostPathControlSegmentOnPremThunkProps,
	{ state: RootState }
>('path/segment/post/v2/onPrem', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentName, accessYn, maxInflow, startUri, endUri, onSuccess, vwrPageId, members } = args;

	try {
		const {
			data: { id, segmentKey, segmentColor: segmentColorResponse },
		} = await postPathControlSegmentApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			body: {
				segmentName,
				accessYn,
				maxInflow: Number(maxInflow),
				startUri,
				endUri,
				vwrPageId,
			},
		});

		await postOperatorApi({
			tenantId: tenantId || '',
			organizationId,
			segmentId: id,
			userList: members.map(member => member.userKey),
		});

		onSuccess();

		return {
			segmentColor: segmentColorResponse,
			segmentName,
			accessYn,
			maxInflow: Number(maxInflow),
			segmentKey,
			id,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (isAxiosError<PostBasicControlSegmentApiError>(error) && error.response?.data.errorCode === 'S1013') {
			onSuccess();
			thunkAPI.dispatch(setIsSegmentMaxNumModalVisible(true));
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default postPathControlSegmentOnPremThunk;

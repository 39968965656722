/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { paletteSDS, themeSDS } from 'surf-design-system';

export interface Label {
	color: string;
	label: string;
	id: number;
}

export interface TXNChartTitleProps {
	title: string;
	labels?: Label[];
}

export default function TXNChartTitle({ title, labels }: TXNChartTitleProps) {
	return (
		<div css={chartTitleWrap}>
			<div css={chartTitle}>{title}</div>
			{labels && (
				<div css={chartLabelContainer}>
					{labels.map(el => (
						<div css={chartLabelWrap} key={el.id}>
							<div css={labelColor(el.color)} />
							<div css={labelSt}>{el.label}</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

const chartTitleWrap = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const chartTitle = css`
	font-size: ${themeSDS.fontSize[16]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;
	color: ${paletteSDS.systemColor.black};
`;

const chartLabelContainer = css`
	display: flex;
	align-items: center;
	gap: 0.5156rem;
`;

const chartLabelWrap = css`
	display: flex;
	align-items: center;
	gap: 0.2656rem;
`;

const labelColor = (color: string) => css`
	width: 7.5px;
	height: 7.5px;
	border-radius: 50%;

	background: ${color};
`;

const labelSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray[700]};
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { paletteSDS, themeSDS, Button } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import ButtonGroup, { ButtonGroupElement } from 'common/components/ButtonGroup';

import { downloadCSVV2 } from 'features/plan/utils/csvFunc';

import type { period } from '../api/getProjectUsageDetail.api';
import { getProjectUsageDetailThunk } from '../thunk/getProjectUsageDetail.v2.thunk';
import ActiveUsersChart from './ActiveUsersChart';
import WaitingUsersChart from './WaitingUsersChart';

export default function SaasOverallUsage() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [chartType, setChartType] = useState<period>('daily');
	const {
		initialMomentValue,
		chart: {
			yearly: { startYear, endYear },
		},
		selectedProjectId,
	} = useSelector((state: RootState) => state.usage);
	const { organizationId, tenantId } = useSelector((state: RootState) => state.account);

	const periodButtons: ButtonGroupElement[] = useMemo(
		() => [
			{
				id: 'daily',
				onClick: () => {
					setChartType('daily');
				},
				label: t('planSubscription.day'),
			},
			{
				id: 'monthly',
				onClick: () => {
					setChartType('monthly');
				},
				label: t('planSubscription.month'),
			},
			{
				id: 'yearly',
				onClick: () => {
					setChartType('yearly');
				},
				label: t('planSubscription.year'),
			},
		],
		[t]
	);

	const csvButtonClickHandler = async () => {
		downloadCSVV2({
			organizationId,
			interval: chartType,
			date: Number(moment(initialMomentValue).format('X')),
			tenantId: tenantId as string,
			projectId: selectedProjectId,
		});
	};

	let updatedAt = `${moment().utcOffset(0).format('MMM')} 1 - ${moment().utcOffset(0).format('MMM')} ${moment()
		.utcOffset(0)
		.daysInMonth()} (UTC)`;

	if (chartType === 'monthly') {
		updatedAt = `${moment().utcOffset(0).format('YYYY')} Jan - ${moment().utcOffset(0).format('YYYY')} Dec (UTC)`;
	}

	if (chartType === 'yearly') {
		updatedAt = `${startYear} - ${endYear} (UTC)`;
	}

	useEffect(() => {
		dispatch(getProjectUsageDetailThunk());
	}, [dispatch, selectedProjectId]);

	return (
		<div css={overallUsageContainerSt}>
			<div css={overallUsageUpperTitleWrapSt}>
				<div css={gap8px}>
					<div css={titleSt}>{t('planSubscription.overallUsage')}</div>
					<div css={dividerSt} />
					<div css={rangeSt}>{updatedAt}</div>
				</div>
				<div css={gap8px}>
					<ButtonGroup selectedId={chartType} buttonGroup={periodButtons} />
					<Button mould="secondary" size="small" onClick={csvButtonClickHandler}>
						{t('planSubscription.downloadCsv')}
					</Button>
				</div>
			</div>
			<div css={[overallChartContainerSt, toolTipSt]}>
				<ActiveUsersChart chartType={chartType} />
				<WaitingUsersChart chartType={chartType} />
			</div>
		</div>
	);
}

const overallUsageContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const overallUsageUpperTitleWrapSt = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const overallChartContainerSt = css`
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	width: 100%;
`;

export const toolTipSt = css`
	.apexcharts-tooltip {
		height: 24px;

		padding: 6px;

		border: 1px solid ${paletteSDS.gray[400]};
		border-radius: 4px;

		background: ${paletteSDS.systemColor.white};
	}

	.container {
		display: flex;
		gap: 8px;
	}

	.curr {
		display: flex;
		align-items: center;
		gap: 4.25px;
	}

	.color {
		width: 7.5px;
		height: 7.5px;
		border-radius: 50%;
	}

	.value {
		color: ${paletteSDS.gray[900]};
		font-size: ${themeSDS.fontSize[12]};
		font-weight: ${themeSDS.fontWeight.regular};
		line-height: 100%;
	}

	.total {
		display: flex;
		gap: 4px;
	}

	.total_label {
		color: ${paletteSDS.gray[900]};
		font-size: ${themeSDS.fontSize[12]};
		font-weight: ${themeSDS.fontWeight.bold};
		line-height: 100%;
	}
`;

const gap8px = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const titleSt = css`
	font-size: ${themeSDS.fontSize[20]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
	color: ${paletteSDS.gray[900]};
`;

const dividerSt = css`
	width: 1px;
	height: 15px;

	background: ${paletteSDS.gray['300']};
`;

const rangeSt = css`
	color: ${paletteSDS.gray[800]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;
`;

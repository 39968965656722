import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutOperatorApiResponse } from 'features/nfControl/type/putOperator.v2.type';

export interface PutOperatorApiProps {
	tenantId: string;
	organizationId: number;
	segmentId: number;
	userList: string[];
}

export default async function putOperatorApi({ tenantId, organizationId, segmentId, userList }: PutOperatorApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.put<PutOperatorApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/privilege/segment/${segmentId}/user-segment`,
			{
				userKey: userList.slice(),
			},
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createSlice } from '@reduxjs/toolkit';

import type { SegmentStatDataValues } from 'features/nfBasic/types/postSegmentStatData.type';
import { X_AXIS_NUM, X_AXIS_VALUE_INTERVAL } from 'features/nfBasic/data/constants';
import type { FunnelData } from 'features/nfBasic/types/funnelLib.type';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';

import deleteBasicControlSegmentThunk from 'features/nfControl/thunk/deleteBasicControlSegment.v2.thunk';
import deletePathControlSegmentThunk from 'features/nfControl/thunk/deletePathControlSegment.v2.thunk';
import getBasicControlSegmentListThunk from 'features/nfControl/thunk/getBasicControlSegmentList.v2.thunk';
import getPathControlSegmentListThunk from 'features/nfControl/thunk/getPathControlSegmentList.v2.thunk';
import postBasicControlSegmentThunk from 'features/nfControl/thunk/postBasicControlSegment.v2.thunk';
import postMoisBasicControlSegmentThunk from 'features/nfControl/thunk/postMoisBasicControlSegment.v2.thunk';
import postPathControlSegmentThunk from 'features/nfControl/thunk/postPathControlSegment.v2.thunk';
import postProjectStatDataThunk from 'features/nfControl/thunk/postProjectStatData.v2.thunk';
import postSegmentStatDataInitialThunk from 'features/nfControl/thunk/postSegmentStatDataInitial.v2.thunk';
import postSegmentStatDataUpdateThunk from 'features/nfControl/thunk/postSegmentStatDataUpdate.v2.thunk';
import putBasicControlSegmentOrderThunk from 'features/nfControl/thunk/putBasicControlSegmentOrder.v2.thunk';
import putBasicControlSegmentGraphInfoThunk from 'features/nfControl/thunk/putBasicControlSegmentGraphInfo.v2.thunk';
import putBasicControlSegmentThunk from 'features/nfControl/thunk/putBasicControlSegment.v2.thunk';
import putPathControlSegmentOrderThunk from 'features/nfControl/thunk/putPathControlSegmentOrder.v2.thunk';
import putPathControlSegmentGraphInfoThunk from 'features/nfControl/thunk/putPathControlSegmentGraphInfo.v2.thunk';
import putPathControlSegmentThunk from 'features/nfControl/thunk/putPathControlSegment.v2.thunk';
import postBasicControlSegmentOnPremThunk from 'features/nfControl/thunk/postBasicControlSegment.v2.onPrem.thunk';
import putBasicControlSegmentOnPremThunk from 'features/nfControl/thunk/putBasicControlSegment.v2.onPrem.thunk';
import postPathControlSegmentOnPremThunk from 'features/nfControl/thunk/postPathControlSegment.v2.onPrem.thunk';
import putPathControlSegmentOnPremThunk from 'features/nfControl/thunk/putPathControlSegment.v2.onPrem.thunk';

export type segmentConnection = 'disconnected' | 'connected' | 'hasConnected';

export interface ChartData {
	x: number;
	y: number | null;
}

export interface SegmentData {
	prevSegCardValue: SegmentStatDataValues;
	segCardValue: SegmentStatDataValues;
	waitTicketY: ChartData[];
	processTimeY: ChartData[];
	outflowRateY: ChartData[];
	maxWaitTicket: number;
	maxProcessTime: number;
	segmentGraphYn: boolean;
	segmentColor: string;
	id: number;
	segmentName: string;
	accessYn: boolean;
	segmentKey: string;
	classic: FunnelData;
}

export interface NfControlSliceData {
	project: {
		isLoaded: boolean;
		time: number;
		totalTickets: number;
		maxWaitTime: number;
		maxNumbersWaiting: number;
		maxProcessTime: number;
		classic: FunnelData;
		// eslint-disable-next-line
		error: any;
	};
	segment: {
		basic: {
			isLoading: boolean;
			meta: SegmentMetaData[];
			value: SegmentData[];
			isAnimate: boolean;
			isMetaLoaded: boolean;
			isValueLoaded: boolean;
			// eslint-disable-next-line
			error: any;
			isLoaded: boolean;
			connection: segmentConnection;
		};
		path: {
			isLoading: boolean;
			meta: SegmentMetaData[];
			value: SegmentData[];
			isAnimate: boolean;
			isMetaLoaded: boolean;
			isValueLoaded: boolean;
			// eslint-disable-next-line
			error: any;
			isLoaded: boolean;
			connection: segmentConnection;
		};
	};
	lastTimeStamp: number;
}

const initialState: NfControlSliceData = {
	project: {
		time: 0,
		totalTickets: 0,
		maxWaitTime: 0,
		maxNumbersWaiting: 0,
		maxProcessTime: 0,
		classic: {
			id: 'projectFunnel',
			type: 1,
			new_key_chk_enter: 0,
			wait_user: 0,
			block: 0,
			wait_tm: 0,
			chk_enter_succ: 0,
			limit: 0,
			complete_succ: 0,
			xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			used_size: 0,
			// 미사용
			bypass: 0,
			access_mode: 0,
			ic_access_mode: 0,
			wait_max: 10,
			limit_max: 100,
			limitmax: 500,
		},
		isLoaded: false,
		error: null,
	},
	segment: {
		basic: {
			isLoading: false,
			meta: [],
			value: [],
			isAnimate: true,
			isLoaded: false,
			error: null,
			connection: 'connected',
			isMetaLoaded: false,
			isValueLoaded: false,
		},
		path: {
			isLoading: false,
			meta: [],
			value: [],
			isAnimate: true,
			isLoaded: false,
			error: null,
			connection: 'connected',
			isMetaLoaded: false,
			isValueLoaded: false,
		},
	},
	lastTimeStamp: 0,
};

const nfControlSlice = createSlice({
	name: 'nfControl',
	initialState,
	reducers: {
		resetBasicControlError: state => {
			return { ...state, segment: { ...state.segment, basic: { ...state.segment.basic, error: null } } };
		},
		resetPathControlError: state => {
			return { ...state, segment: { ...state.segment, path: { ...state.segment.path, error: null } } };
		},
		setInitialNfControlData: () => {
			return { ...initialState };
		},
		cleanupBasicControlSegmentRawData: state => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						value: state.segment.basic.value.map(seg => ({
							...seg,
							waitTicketY: seg.waitTicketY.slice(seg.waitTicketY.length - X_AXIS_NUM - 5),
							outflowRateY: seg.outflowRateY.slice(seg.outflowRateY.length - X_AXIS_NUM - 5),
							processTimeY: seg.processTimeY.slice(seg.processTimeY.length - X_AXIS_NUM - 5),
						})),
						isAnimate: false,
					},
				},
			};
		},
		cleanupPathControlSegmentRawData: state => {
			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						value: state.segment.path.value.map(seg => ({
							...seg,
							waitTicketY: seg.waitTicketY.slice(seg.waitTicketY.length - X_AXIS_NUM - 5),
							outflowRateY: seg.outflowRateY.slice(seg.outflowRateY.length - X_AXIS_NUM - 5),
							processTimeY: seg.processTimeY.slice(seg.processTimeY.length - X_AXIS_NUM - 5),
						})),
						isAnimate: false,
					},
				},
			};
		},
	},
	extraReducers(builder) {
		builder.addCase(deleteBasicControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(deleteBasicControlSegmentThunk.rejected, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: false } },
			};
		});

		builder.addCase(deleteBasicControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			const { id, segmentOrder } = action.payload;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta
							.filter(el => el.id !== id)
							.map(item =>
								item.segmentOrder < segmentOrder ? item : { ...item, segmentOrder: item.segmentOrder - 1 }
							),
						value: value.filter(el => el.id !== id),
					},
				},
			};
		});

		builder.addCase(deletePathControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: true, error: null } },
			};
		});

		builder.addCase(deletePathControlSegmentThunk.rejected, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: false } },
			};
		});

		builder.addCase(deletePathControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.path;

			const { id, segmentOrder } = action.payload;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						isLoading: false,
						error: null,
						meta: meta
							.filter(el => el.id !== id)
							.map(item =>
								item.segmentOrder < segmentOrder ? item : { ...item, segmentOrder: item.segmentOrder - 1 }
							),
						value: value.filter(el => el.id !== id),
					},
				},
			};
		});

		builder.addCase(getBasicControlSegmentListThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoaded: true,
						isMetaLoaded: true,
						error: null,
						meta: [...action.payload.meta],
					},
				},
			};
		});

		builder.addCase(getBasicControlSegmentListThunk.rejected, state => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoaded: true,
						isMetaLoaded: true,
					},
				},
			};
		});

		builder.addCase(getPathControlSegmentListThunk.fulfilled, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.basic,
						isLoaded: true,
						isMetaLoaded: true,
						error: null,
						meta: [...action.payload.meta],
					},
				},
			};
		});

		builder.addCase(getPathControlSegmentListThunk.rejected, state => {
			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.basic,
						isLoaded: true,
						isMetaLoaded: true,
					},
				},
			};
		});

		builder.addCase(putBasicControlSegmentGraphInfoThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(putBasicControlSegmentGraphInfoThunk.fulfilled, (state, action) => {
			const { meta } = state.segment.basic;

			const { segmentGraphYn, id } = action.payload;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta.map(el => (el.id === id ? { ...el, segmentGraphYn } : el)),
					},
				},
			};
		});

		builder.addCase(putBasicControlSegmentGraphInfoThunk.rejected, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: false } },
			};
		});

		builder.addCase(putPathControlSegmentGraphInfoThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: true, error: null } },
			};
		});

		builder.addCase(putPathControlSegmentGraphInfoThunk.fulfilled, (state, action) => {
			const { meta } = state.segment.path;

			const { segmentGraphYn, id } = action.payload;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						isLoading: false,
						error: null,
						meta: meta.map(el => (el.id === id ? { ...el, segmentGraphYn } : el)),
					},
				},
			};
		});

		builder.addCase(putPathControlSegmentGraphInfoThunk.rejected, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: false, error: null } },
			};
		});

		builder.addCase(putBasicControlSegmentOrderThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			const { pickedCardIndex, droppedCardIndex } = action.payload;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						meta: meta.map((el, idx) => {
							if (idx === droppedCardIndex) {
								return { ...meta[pickedCardIndex], segmentOrder: droppedCardIndex + 1 };
							}

							if (idx === pickedCardIndex) {
								return { ...meta[droppedCardIndex], segmentOrder: pickedCardIndex + 1 };
							}

							return el;
						}),
						value: value.map((el, idx) => {
							if (idx === droppedCardIndex) {
								return { ...value[pickedCardIndex] };
							}

							if (idx === pickedCardIndex) {
								return { ...value[droppedCardIndex] };
							}

							return el;
						}),
					},
				},
			};
		});

		builder.addCase(putPathControlSegmentOrderThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.path;

			const { pickedCardIndex, droppedCardIndex } = action.payload;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						meta: meta.map((el, idx) => {
							if (idx === droppedCardIndex) {
								return { ...meta[pickedCardIndex], segmentOrder: droppedCardIndex + 1 };
							}

							if (idx === pickedCardIndex) {
								return { ...meta[droppedCardIndex], segmentOrder: pickedCardIndex + 1 };
							}

							return el;
						}),
						value: value.map((el, idx) => {
							if (idx === droppedCardIndex) {
								return { ...value[pickedCardIndex] };
							}

							if (idx === pickedCardIndex) {
								return { ...value[droppedCardIndex] };
							}

							return el;
						}),
					},
				},
			};
		});

		builder.addCase(putBasicControlSegmentThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(putBasicControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(putBasicControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										maxInflow: action.payload.maxInflow,
								  }
								: el
						),
						value: value.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										segCardValue: { ...el.segCardValue, maxInflow: action.payload.maxInflow },
										classic: { ...el.classic, limit: action.payload.maxInflow },
								  }
								: el
						),
					},
				},
			};
		});

		builder.addCase(putBasicControlSegmentOnPremThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(putBasicControlSegmentOnPremThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(putBasicControlSegmentOnPremThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										maxInflow: action.payload.maxInflow,
								  }
								: el
						),
						value: value.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										segCardValue: { ...el.segCardValue, maxInflow: action.payload.maxInflow },
										classic: { ...el.classic, limit: action.payload.maxInflow },
								  }
								: el
						),
					},
				},
			};
		});

		builder.addCase(putPathControlSegmentThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					path: { ...state.segment.path, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(putPathControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: true, error: null } },
			};
		});

		builder.addCase(putPathControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.path;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						isLoading: false,
						error: null,
						meta: meta.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										maxInflow: action.payload.maxInflow,
								  }
								: el
						),
						value: value.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										segCardValue: { ...el.segCardValue, maxInflow: action.payload.maxInflow },
										classic: { ...el.classic, limit: action.payload.maxInflow },
								  }
								: el
						),
					},
				},
			};
		});

		builder.addCase(putPathControlSegmentOnPremThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					path: { ...state.segment.path, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(putPathControlSegmentOnPremThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: true, error: null } },
			};
		});

		builder.addCase(putPathControlSegmentOnPremThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.path;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						isLoading: false,
						error: null,
						meta: meta.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										maxInflow: action.payload.maxInflow,
								  }
								: el
						),
						value: value.map(el =>
							el.id === action.payload.id
								? {
										...el,
										segmentName: action.payload.segmentName,
										accessYn: action.payload.accessYn,
										segCardValue: { ...el.segCardValue, maxInflow: action.payload.maxInflow },
										classic: { ...el.classic, limit: action.payload.maxInflow },
								  }
								: el
						),
					},
				},
			};
		});

		builder.addCase(postBasicControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(postBasicControlSegmentThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(postBasicControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta.concat({
							segmentColor: action.payload.segmentColor,
							id: action.payload.id,
							segmentGraphYn: false,
							segmentKey: action.payload.segmentKey,
							segmentName: action.payload.segmentName,
							segmentOrder: meta.length + 1,
							maxInflow: action.payload.maxInflow,
							accessYn: action.payload.accessYn,
						}),
						value: value.concat({
							prevSegCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							segCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							segmentGraphYn: false,
							segmentColor: action.payload.segmentColor,
							segmentKey: action.payload.segmentKey,
							accessYn: action.payload.accessYn,
							segmentName: action.payload.segmentName,
							maxProcessTime: 0,
							maxWaitTicket: 0,
							id: action.payload.id,
							classic: {
								id: action.payload.segmentKey,
								type: 1,
								new_key_chk_enter: 0,
								wait_user: 0,
								block: 0,
								wait_tm: 0,
								chk_enter_succ: 0,
								limit: action.payload.maxInflow,
								complete_succ: 0,
								xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
								used_size: 0,
								// 미사용
								bypass: 0,
								access_mode: 0,
								ic_access_mode: 0,
								wait_max: 10,
								limit_max: 100,
								limitmax: 500,
							},
						}),
					},
				},
			};
		});

		builder.addCase(postBasicControlSegmentOnPremThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(postBasicControlSegmentOnPremThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(postBasicControlSegmentOnPremThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta.concat({
							segmentColor: action.payload.segmentColor,
							id: action.payload.id,
							segmentGraphYn: false,
							segmentKey: action.payload.segmentKey,
							segmentName: action.payload.segmentName,
							segmentOrder: meta.length + 1,
							maxInflow: action.payload.maxInflow,
							accessYn: action.payload.accessYn,
						}),
						value: value.concat({
							prevSegCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							segCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							segmentGraphYn: false,
							segmentColor: action.payload.segmentColor,
							segmentKey: action.payload.segmentKey,
							accessYn: action.payload.accessYn,
							segmentName: action.payload.segmentName,
							maxProcessTime: 0,
							maxWaitTicket: 0,
							id: action.payload.id,
							classic: {
								id: action.payload.segmentKey,
								type: 1,
								new_key_chk_enter: 0,
								wait_user: 0,
								block: 0,
								wait_tm: 0,
								chk_enter_succ: 0,
								limit: action.payload.maxInflow,
								complete_succ: 0,
								xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
								used_size: 0,
								// 미사용
								bypass: 0,
								access_mode: 0,
								ic_access_mode: 0,
								wait_max: 10,
								limit_max: 100,
								limitmax: 500,
							},
						}),
					},
				},
			};
		});

		builder.addCase(postMoisBasicControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, basic: { ...state.segment.basic, isLoading: true, error: null } },
			};
		});

		builder.addCase(postMoisBasicControlSegmentThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(postMoisBasicControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.basic;

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						isLoading: false,
						error: null,
						meta: meta.concat({
							segmentColor: action.payload.segmentColor,
							id: action.payload.id,
							segmentGraphYn: false,
							segmentKey: action.payload.segmentKey,
							segmentName: action.payload.segmentName,
							segmentOrder: meta.length + 1,
							maxInflow: action.payload.maxInflow,
							accessYn: action.payload.accessYn,
						}),
						value: value.concat({
							prevSegCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							segCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							segmentGraphYn: false,
							segmentColor: action.payload.segmentColor,
							segmentKey: action.payload.segmentKey,
							accessYn: action.payload.accessYn,
							segmentName: action.payload.segmentName,
							maxProcessTime: 0,
							maxWaitTicket: 0,
							id: action.payload.id,
							classic: {
								id: action.payload.segmentKey,
								type: 1,
								new_key_chk_enter: 0,
								wait_user: 0,
								block: 0,
								wait_tm: 0,
								chk_enter_succ: 0,
								limit: action.payload.maxInflow,
								complete_succ: 0,
								xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
								used_size: 0,
								// 미사용
								bypass: 0,
								access_mode: 0,
								ic_access_mode: 0,
								wait_max: 10,
								limit_max: 100,
								limitmax: 500,
							},
						}),
					},
				},
			};
		});

		builder.addCase(postPathControlSegmentThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: true, error: null } },
			};
		});

		builder.addCase(postPathControlSegmentThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					path: { ...state.segment.path, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(postPathControlSegmentThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.path;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						isLoading: false,
						error: null,
						meta: meta.concat({
							segmentColor: action.payload.segmentColor,
							id: action.payload.id,
							segmentGraphYn: false,
							segmentKey: action.payload.segmentKey,
							segmentName: action.payload.segmentName,
							segmentOrder: meta.length + 1,
							maxInflow: action.payload.maxInflow,
							accessYn: action.payload.accessYn,
						}),
						value: value.concat({
							prevSegCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							segCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							segmentGraphYn: false,
							segmentColor: action.payload.segmentColor,
							segmentKey: action.payload.segmentKey,
							accessYn: action.payload.accessYn,
							segmentName: action.payload.segmentName,
							maxProcessTime: 0,
							maxWaitTicket: 0,
							id: action.payload.id,
							classic: {
								id: action.payload.segmentKey,
								type: 1,
								new_key_chk_enter: 0,
								wait_user: 0,
								block: 0,
								wait_tm: 0,
								chk_enter_succ: 0,
								limit: action.payload.maxInflow,
								complete_succ: 0,
								xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
								used_size: 0,
								bypass: 0,
								// 미사용
								access_mode: 0,
								ic_access_mode: 0,
								wait_max: 10,
								limit_max: 100,
								limitmax: 500,
							},
						}),
					},
				},
			};
		});

		builder.addCase(postPathControlSegmentOnPremThunk.pending, state => {
			return {
				...state,
				segment: { ...state.segment, path: { ...state.segment.path, isLoading: true, error: null } },
			};
		});

		builder.addCase(postPathControlSegmentOnPremThunk.rejected, (state, action) => {
			return {
				...state,
				segment: {
					...state.segment,
					path: { ...state.segment.path, isLoading: false, error: { ...(action.payload as object) } },
				},
			};
		});

		builder.addCase(postPathControlSegmentOnPremThunk.fulfilled, (state, action) => {
			const { meta, value } = state.segment.path;

			return {
				...state,
				segment: {
					...state.segment,
					path: {
						...state.segment.path,
						isLoading: false,
						error: null,
						meta: meta.concat({
							segmentColor: action.payload.segmentColor,
							id: action.payload.id,
							segmentGraphYn: false,
							segmentKey: action.payload.segmentKey,
							segmentName: action.payload.segmentName,
							segmentOrder: meta.length + 1,
							maxInflow: action.payload.maxInflow,
							accessYn: action.payload.accessYn,
						}),
						value: value.concat({
							prevSegCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							segCardValue: {
								totalTickets: 0,
								numbersWaiting: 0,
								waitTime: 0,
								totalInflow: 0,
								maxInflow: action.payload.maxInflow,
								totalOutflow: 0,
								processTime: 0,
								usedSize: 0,
								nfBlock: 0,
								byPass: 0,
							},
							waitTicketY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							processTimeY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							outflowRateY: Array.from<unknown, ChartData>({ length: X_AXIS_NUM }, (_, currIdx) => ({
								x: state.lastTimeStamp - (X_AXIS_NUM - 1 - currIdx) * X_AXIS_VALUE_INTERVAL,
								y: null,
							})),
							segmentGraphYn: false,
							segmentColor: action.payload.segmentColor,
							segmentKey: action.payload.segmentKey,
							accessYn: action.payload.accessYn,
							segmentName: action.payload.segmentName,
							maxProcessTime: 0,
							maxWaitTicket: 0,
							id: action.payload.id,
							classic: {
								id: action.payload.segmentKey,
								type: 1,
								new_key_chk_enter: 0,
								wait_user: 0,
								block: 0,
								wait_tm: 0,
								chk_enter_succ: 0,
								limit: action.payload.maxInflow,
								complete_succ: 0,
								xview: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
								used_size: 0,
								bypass: 0,
								// 미사용
								access_mode: 0,
								ic_access_mode: 0,
								wait_max: 10,
								limit_max: 100,
								limitmax: 500,
							},
						}),
					},
				},
			};
		});

		builder.addCase(postProjectStatDataThunk.rejected, state => {
			return { ...state, project: { ...state.project, isLoaded: true } };
		});

		builder.addCase(postProjectStatDataThunk.fulfilled, (state, action) => {
			return { ...state, project: { isLoaded: true, error: null, classic: state.project.classic, ...action.payload } };
		});

		builder.addCase(postSegmentStatDataInitialThunk.rejected, (state, action) => {
			if (action.meta.arg.isPathControl) {
				return {
					...state,
					segment: {
						...state.segment,
						path: { ...state.segment.path, isValueLoaded: true, isLoaded: true, isAnimate: true },
					},
				};
			}

			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isValueLoaded: true, isLoaded: true, isAnimate: true },
				},
			};
		});

		builder.addCase(postSegmentStatDataInitialThunk.fulfilled, (state, action) => {
			const { classic, value, lastTimeStamp, isPathControl } = action.payload;

			if (isPathControl) {
				return {
					...state,
					segment: {
						...state.segment,
						path: { ...state.segment.path, value: [...value], isValueLoaded: true, isLoaded: true, isAnimate: true },
					},
					project: { ...state.project, classic: { ...classic } },
					lastTimeStamp,
				};
			}

			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, value: [...value], isValueLoaded: true, isLoaded: true, isAnimate: true },
				},
				project: { ...state.project, classic: { ...classic } },
				lastTimeStamp,
			};
		});

		builder.addCase(postSegmentStatDataUpdateThunk.rejected, (state, action) => {
			if (action.meta.arg.isPathControl) {
				return {
					...state,
					segment: {
						...state.segment,
						path: { ...state.segment.path, isValueLoaded: true, isLoaded: true, isAnimate: true },
					},
				};
			}

			return {
				...state,
				segment: {
					...state.segment,
					basic: { ...state.segment.basic, isValueLoaded: true, isLoaded: true, isAnimate: true },
				},
			};
		});

		builder.addCase(postSegmentStatDataUpdateThunk.fulfilled, (state, action) => {
			const { classic, value, lastTimeStamp, isPathControl, meta } = action.payload;

			if (isPathControl) {
				return {
					...state,
					segment: {
						...state.segment,
						path: {
							...state.segment.path,
							value: [...value],
							isValueLoaded: true,
							isLoaded: true,
							isAnimate: true,
							meta: [...meta],
						},
					},
					project: { ...state.project, classic: { ...classic } },
					lastTimeStamp,
				};
			}

			return {
				...state,
				segment: {
					...state.segment,
					basic: {
						...state.segment.basic,
						value: [...value],
						isValueLoaded: true,
						isLoaded: true,
						isAnimate: true,
						meta: [...meta],
					},
				},
				project: { ...state.project, classic: { ...classic } },
				lastTimeStamp,
			};
		});
	},
});

export default nfControlSlice.reducer;
export const {
	resetPathControlError,
	resetBasicControlError,
	setInitialNfControlData,
	cleanupPathControlSegmentRawData,
	cleanupBasicControlSegmentRawData,
} = nfControlSlice.actions;

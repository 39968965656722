import { createAsyncThunk } from '@reduxjs/toolkit';

import getNotificationListApi from 'common/api/getNotificationList.api';
import { GetNotificationListData } from 'common/types/getNotificationList.type';
import type { RootState } from 'common/redux/store';

export interface GetNotificationListThunkProps {
	pageNum: number;
}

export interface GetNotificationListThunkResult {
	notificationList: GetNotificationListData;
	selectedPage: number;
}

export const getNotificationListThunk = createAsyncThunk<
	GetNotificationListThunkResult,
	GetNotificationListThunkProps,
	{ state: RootState }
>('noti/get', async (args, thnkAPI) => {
	const { pageNum } = args;
	try {
		const {
			account: { organizationId, language },
		} = thnkAPI.getState();

		const { data: headerNotificationData } = await getNotificationListApi({
			pageNum: pageNum - 1,
			pageSize: 10,
			organizationId,
			language,
		});

		return { notificationList: headerNotificationData, selectedPage: pageNum };
		// eslint-disable-next-line
	} catch (error: any) {
		return thnkAPI.rejectWithValue(error.response.data);
	}
});

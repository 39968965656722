import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import type { GetOrganizationPaymentInfoData } from '../types/getOrganizationPaymentInfo.type';
import getOrganizationPaymentInfoApi from '../api/getOrganizationPaymentInfo.api';

export const getOrganizationPaymentInfoThunk = createAsyncThunk<
	GetOrganizationPaymentInfoData,
	void,
	{ state: RootState }
>('payment/get', async (_, thunkAPI) => {
	const {
		account: { organizationId },
	} = thunkAPI.getState();

	try {
		const { data } = await getOrganizationPaymentInfoApi({ organizationId });

		if (data) {
			return { ...data };
		}

		return { id: '', name: '', number: 0 };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

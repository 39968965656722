/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { ProjectActiveIcon, ProjectAccessIcon } from '../assets';

/**
 * author: Finn
 * createdAt: 2022-07-13
 * updatedAt: 2022-08-17
 * description: 프로젝트 매니지먼트 하단의 기능 설명 컴포넌트
 * todo:
 */
export default function ManagementGuide() {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<div css={helpBoxSt}>
				<div css={iconSt}>
					<ProjectActiveIcon />
				</div>
				<div css={textBoxSt}>
					<p>{t('projectMgmt.activeDescrTitle')}</p>
					<p>{t('projectMgmt.activeDescr')}</p>
				</div>
			</div>
			<div css={helpBoxSt}>
				<div css={iconSt}>
					<ProjectAccessIcon />
				</div>
				<div css={textBoxSt}>
					<p>{t('projectMgmt.accessDescrTitle')}</p>
					<p>{t('projectMgmt.accessDescr')}</p>
				</div>
			</div>
		</React.Fragment>
	);
}

const helpBoxSt = css`
	display: flex;
	height: 3.4375rem;

	:last-of-type {
		margin-bottom: 0;
	}
`;

const iconSt = css`
	display: flex;
	align-items: center;

	width: 2.5rem;
	height: 2.5rem;
	margin-right: 0.5rem;
`;

const textBoxSt = css`
	display: flex;
	flex-direction: column;

	p:nth-of-type(1) {
		height: 1.8125rem;
		line-height: 1.8125rem;

		font-weight: ${themeSDS.fontWeight.medium};
		font-size: ${themeSDS.fontSize[18]};
		color: ${paletteSDS.gray[900]};
	}

	p:nth-of-type(2) {
		height: 1.625rem;
		line-height: 1.625rem;

		font-weight: ${themeSDS.fontWeight.regular};
		font-size: ${themeSDS.fontSize[16]};
		color: ${paletteSDS.gray[700]};
	}
`;

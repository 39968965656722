/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ChevronLeftIcon, EditIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { setInitialToast } from 'common/context/toastSlice';
import DividerWithTxt from 'common/components/DividerWithTxt';
import { resetVWRData, toggleIsUpdateVWRNameVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';
import { getProjectModeUrl } from 'common/utils/projectFunc';

import VWRPreview from 'features/vwr/components/VWRPreview';
import { setInitializeVWRData } from 'features/vwr/context/vwrSlice.v2';
import VWRNameModal from 'features/vwr/components/VWRNameModal';
import VWREditForm from 'features/vwr/components/VWREditForm';
import { getVWRDataThunk } from 'features/vwr/thunk/getVWRData.v2.thunk';
import { getVWRListThunk } from 'features/vwr/thunk/getVWRList.v2.thunk';

export default function VWREdit() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { vwrId } = useParams();

	const { vwrList, isLoaded } = useSelector((state: RootState) => state.vwr2);

	const { isVWRNameModalVisible, name } = useSelector((state: RootState) => state.modal.vwr);

	const { language } = useSelector((state: RootState) => state.account);

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const currentVWR = vwrList.find(el => el.id === Number(vwrId));

	const editIconClickHandler = () => {
		dispatch(toggleIsUpdateVWRNameVisible());
	};

	const listButtonClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${selectedProjectId}/settings/vwr`);
	};

	useLayoutEffect(() => {
		dispatch(getVWRDataThunk({ id: Number(vwrId) }));
		dispatch(getVWRListThunk());
		return () => {
			dispatch(setInitializeVWRData());
			dispatch(setInitialToast());
			dispatch(resetVWRData());
		};
	}, [dispatch, t, vwrId]);

	if (!isLoaded) return null;

	return (
		<div css={container}>
			<div css={gap16pxColumn}>
				<div css={headerBox}>
					<div css={titleBox}>
						<div css={titleSt}>{!currentVWR?.defaultYn ? name : t('vwr.default')}</div>
						{!currentVWR?.defaultYn ? (
							<EditIcon size="xSmall" fill={paletteSDS.blue['700']} onClick={editIconClickHandler} />
						) : null}
					</div>
					<Button
						size="small"
						icon={<ChevronLeftIcon fill={paletteSDS.blue['700']} size="xSmall" />}
						iconPosition="left"
						onClick={listButtonClickHandler}
						mould="secondary"
					>
						{t('vwr.list')}
					</Button>
				</div>
				<DividerWithTxt />
			</div>
			<div css={vwrBox}>
				<VWRPreview />
				<VWREditForm />
			</div>
			{isVWRNameModalVisible ? <VWRNameModal mode="edit" /> : null}
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 960px;

	margin: 0 auto;

	padding: 32px 24px 80px;
`;

const gap16pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const headerBox = css`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const titleBox = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const titleSt = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['20']};

	line-height: 140%;
`;

const vwrBox = css`
	display: flex;
	gap: 40px;
	align-items: flex-start;
`;

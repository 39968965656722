/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { RootState } from 'common/redux/store';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import useTXNDonutChartOptions from 'features/nfEUM/hooks/useTXNDonutChartOptions';
import { useTranslation } from 'react-i18next';
import TXNChartTitle from 'features/nfEUM/components/TXNChartTitle';
import DounutChartLegend from 'features/nfEUM/components/DonutChartLegend';

export default function TXNNativeOsChart() {
	const { t } = useTranslation();
	const { nativeOS } = useSelector((state: RootState) => state.eumTXNShare);
	const { apexChartOption } = useTXNDonutChartOptions({
		chartType: nativeOS.labels[0] === 'No Data' ? 'noData' : 'os',
		labelsData: nativeOS.labels,
		seriesData: nativeOS.series,
		annotation: nativeOS.annotation,
	});

	return (
		<div>
			<TXNChartTitle title="OS" />
			<div css={chart}>
				<ReactApexChart type="donut" width="220px" height="220px" options={apexChartOption} series={nativeOS.series} />
				<DounutChartLegend
					chartName={nativeOS.labels[0] === 'No Data' ? 'noData' : 'os'}
					labels={nativeOS.labels[0] === 'No Data' ? [t('eum.noData')] : nativeOS.labels}
					series={nativeOS.labels[0] === 'No Data' ? [] : nativeOS.series}
				/>
			</div>
		</div>
	);
}

const chart = css`
	display: flex;
	justify-content: flex-start;
	gap: 16px;
	width: 100%;

	/* .apx-legend-position-right {
		top: 16px !important;
		left: 220px !important;
		right: 0 !important;
		display: flex;
		justify-content: center !important;
		height: 180px !important;
	}

	.apexcharts-legend-series {
		display: flex;
		align-items: center;
		gap: 3px;
	}

	.apexcharts-legend-text {
		display: flex;
		align-items: center !important;
		gap: 3px;
	} */
`;

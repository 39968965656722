/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { paletteSDS, themeSDS, WarningIcon } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import NetFunnelStatusLabel from 'features/license/components/NetFunnelStatusLabel';
import { addNumberComma } from 'features/plan/utils/formatFunc';

export default function LicenseUsageSummary() {
	const { t } = useTranslation();

	const {
		detail: {
			licenseExpiredAt,
			licenseExpiredYn,
			createdAt,
			licensedPeakCcu,
			licensedTotalExceededPeakCcu,
			maxDailyPeakCcu,
			totalExceededLicPeakCcu,
			licenseReachedYn,
		},
		isLoaded,
	} = useSelector((state: RootState) => state.license);

	if (!isLoaded) {
		return <div />;
	}

	return (
		<div css={containerCss}>
			<p css={titleCss}>{t('onPrem.license.usageSummary')}</p>
			<div css={detailWrap}>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.period')}</div>
					<div>
						{moment(createdAt).utcOffset('+UTC09:00').format('YYYY-MM-DD')}&nbsp;~&nbsp;
						{moment(licenseExpiredAt).utcOffset('+UTC09:00').format('YYYY-MM-DD')}
					</div>
				</div>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.peakCCU')}</div>
					<div>
						{addNumberComma(maxDailyPeakCcu.toString())}&nbsp;/&nbsp;{addNumberComma(licensedPeakCcu.toString())}
					</div>
				</div>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.excessTimes')}</div>
					<div>
						{addNumberComma(totalExceededLicPeakCcu.toString())}&nbsp;/&nbsp;
						{addNumberComma(licensedTotalExceededPeakCcu.toString())}
					</div>
				</div>
				<div css={detailContentsCss}>
					<div>{t('onPrem.license.nfStatus')}</div>
					<div>
						<NetFunnelStatusLabel licenseExpiredYn={licenseExpiredYn} licenseReachedYn={licenseReachedYn} />
					</div>
				</div>
			</div>
			{licenseExpiredYn ? (
				<div css={disabledGuideBox}>
					<WarningIcon cursor="default" fill={paletteSDS.red['500']} />
					<div css={disabledGuide}>{t('onPrem.license.byPassGuide')}</div>
				</div>
			) : null}
			{!licenseExpiredYn && licenseReachedYn ? (
				<div css={disabledGuideBox}>
					<WarningIcon cursor="default" fill={paletteSDS.red['500']} />
					<div css={disabledGuide}>{t('onPrem.license.overageBypassInfo')}</div>
				</div>
			) : null}
		</div>
	);
}

const containerCss = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 100%;
	padding: 2rem;
	background-color: ${paletteSDS.gray[50]};

	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 8px;
`;

const titleCss = css`
	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[20]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
`;

const detailWrap = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
`;

const detailContentsCss = css`
	display: flex;

	> div:nth-of-type(1) {
		width: 11.25rem;
		color: ${paletteSDS.gray[900]};
		font-weight: ${themeSDS.fontWeight.semiBold};
		text-align: left;
	}
`;

const disabledGuideBox = css`
	display: flex;
	gap: 8px;

	width: 100%;

	padding: 12px 16px 12px 16px;

	background: ${paletteSDS.red['50']};

	border: 1px solid ${paletteSDS.red['200']};
	border-radius: 8px;
`;

const disabledGuide = css`
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.black};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;

	white-space: pre;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import URLToolBar from 'features/nfEUM/components/URLToolBar';
import URLStatusTable from 'features/nfEUM/components/URLStatusTable';

export default function URLStatusIndicator() {
	return (
		<div css={container}>
			<URLToolBar />
			<URLStatusTable />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 16px;

	min-width: 1148px;

	width: 100%;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { toggleIsBillingInfoChangeToastVisible, toggleIsCompanyAddressToastVisible } from 'common/context/toastSlice';

import postOrganizationAddressApi from '../api/postOrganizationAddress.api';

export interface PostOrganizationAddressThunkProps {
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
	isFreebeta: boolean;
}

export interface PostOrganizationAddressThunkResult {
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
}

export const postOrganizationAddressThunk = createAsyncThunk<
	PostOrganizationAddressThunkResult,
	PostOrganizationAddressThunkProps,
	{ state: RootState }
>('address/post', async (args, thunkAPI) => {
	const {
		account: { organizationId },
	} = thunkAPI.getState();

	const { country, streetAddress, streetAddressDetail, city, region, postalCode, vat, isFreebeta } = args;

	try {
		await postOrganizationAddressApi({
			organizationId,
			country,
			streetAddress,
			streetAddressDetail,
			city,
			region,
			postalCode,
			vat,
		});

		if (isFreebeta) {
			thunkAPI.dispatch(toggleIsBillingInfoChangeToastVisible());
		} else {
			thunkAPI.dispatch(toggleIsCompanyAddressToastVisible());
		}

		return { country, streetAddress, streetAddressDetail, city, region, postalCode, vat };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Loader, Modal, paletteSDS, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import { putProjectActivatedYnThunk } from 'features/nfSetting/thunk/putProjectActivatedYn.v1.thunk';
import { resetProjectError } from 'features/nfSetting/context/projectInfoSlice';

export default function PutActivatedYnModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isLoading } = useSelector((state: RootState) => state.projectInfo);

	const { projectName, activatedYn, selectedProjectId } = useSelector(
		(state: RootState) => state.modal.projectManagementModal
	);

	const primaryButtonClickHandler = async () => {
		await dispatch(putProjectActivatedYnThunk({ activatedYn: !activatedYn, selectedProjectId }));
	};

	const cancelButtonClickHandler = () => {
		dispatch(initializeProjectManagementModal());
	};

	useEffect(() => {
		return () => {
			dispatch(resetProjectError());
		};
	}, [dispatch]);

	return (
		<Portal>
			<Modal
				visible
				title={t(`${activatedYn ? 'modal.deactPrj' : 'modal.actPrj'}`)}
				isCloseIconVisible={false}
				actionType="double"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t(`${activatedYn ? 'modal.deact' : 'modal.act'}`)}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={cancelButtonClickHandler}
				secondaryBtnDisable={isLoading}
				primaryBtnDisable={isLoading}
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<span>
					<Trans
						i18nKey={`${activatedYn ? 'modal.prjModalMsg' : 'modal.actPrjMsg'}`}
						values={{ title: projectName }}
						components={{ bold: <b /> }}
					/>
				</span>
			</Modal>
		</Portal>
	);
}

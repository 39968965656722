/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { paletteSDS } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import useSurffyAccountSetting from 'common/hooks/useSurffyAccountSetting';
import SaasGNB from 'common/components/SaasGNB';
import Footer from 'common/components/Footer';
import AskModal from 'common/components/AskModal';
import { NOTI_START_PAGE, PLAN_FREE_TRIAL_ID, PLAN_SUBSCRIPTION_REMINDER_MODAL_RENDER } from 'common/data/constants';
import { getNotificationListThunk } from 'common/thunk/getNotificationList.thunk';
import PendingModal from 'common/components/PendingModal';
import TenantFailModal from 'common/components/TenantFailModal';
import SaasToasts from 'common/components/SaasToasts';
import FreetrialEndModal from 'common/components/FreetrialEndModal';
import PlanSubscriptionReminderModal from 'features/home/components/PlanSubscriptionReminderModal';
import moment from 'moment';
import { toggleIsPlanSubscriptionReminderModalVisible } from 'common/context/modalSlice';

export default function SaasLayout() {
	const dispatch = useAppDispatch();
	const location = useLocation();

	const { pathname } = location;

	const { isLoaded: isAccountLoaded, language, freeTrialEndAt } = useSelector((state: RootState) => state.account);

	const { isAskModalVisible } = useSelector((state: RootState) => state.modal);

	const { isLoaded: isPlanLoaded, planId } = useSelector((state: RootState) => state.plan);

	useSurffyAccountSetting({});

	useEffect(() => {
		if (isAccountLoaded) {
			dispatch(getNotificationListThunk({ pageNum: NOTI_START_PAGE }));
		}
	}, [dispatch, isAccountLoaded, language, pathname]);

	useEffect(() => {
		if (
			isPlanLoaded &&
			planId === PLAN_FREE_TRIAL_ID &&
			localStorage.getItem(PLAN_SUBSCRIPTION_REMINDER_MODAL_RENDER) === null &&
			moment().utcOffset(0).diff(moment(freeTrialEndAt).utcOffset(0), 'days') === 3
		) {
			dispatch(toggleIsPlanSubscriptionReminderModalVisible());
			localStorage.setItem(PLAN_SUBSCRIPTION_REMINDER_MODAL_RENDER, 'RENDER');
		}
	}, [planId, freeTrialEndAt, isPlanLoaded, dispatch]);

	return (
		<div css={container}>
			<SaasGNB />
			<SaasToasts />
			<main css={mainBox}>
				{!isAccountLoaded || !isPlanLoaded ? (
					<div />
				) : (
					<div css={outletContainer}>
						<div css={outletBox}>
							<Outlet />
						</div>
						<Footer />
					</div>
				)}
			</main>
			{isAskModalVisible ? <AskModal /> : null}
			<PendingModal />
			<TenantFailModal />
			<FreetrialEndModal />
			<PlanSubscriptionReminderModal />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
`;

const mainBox = css`
	width: 100%;
	height: 100%;

	overflow: auto;

	background: ${paletteSDS.gray['75']};
`;

const outletContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	height: 100%;
`;

const outletBox = css`
	flex: auto;
	flex-shrink: 0;
`;

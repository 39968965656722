/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, Button, Loader, Textinput } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import MainDomainDescription from 'common/components/MainDomainDescription';
import { LANG_PATH } from 'common/data/constants';

import {
	createProjectFormReducer,
	initialCreateProjectForm,
	UPDATE_CUSTOMER_DOMAIN,
	VALIDATE_CUSTOMER_DOMAIN,
	UPDATE_PROJECT_NAME,
	VALIDATE_PROJECT_NAME,
	UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
	UPDATE_PROJECT_NAME_HELP_TEXT,
} from 'features/home/context/createProjectForm.reducer';
import { postProjectThunk } from 'features/nfSetting/thunk/postProject.v2.thunk';
import { resetProjectError } from 'features/nfSetting/context/projectInfoSlice';

export default function CreateProjectForm() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { language } = useSelector((state: RootState) => state.account);

	const { isLoading, error } = useSelector((state: RootState) => state.projectInfo);

	const [createProjectFormData, setCreateProjectFormData] = useReducer(
		createProjectFormReducer,
		initialCreateProjectForm
	);

	const {
		projectName,
		projectNameHelpText,
		projectNameValidation,
		isProjectNameChanged,
		isProjectNameValid,
		mainDomain,
		mainDomainHelpText,
		mainDomainValidation,
		isMainDomainChanged,
		isMainDomainValid,
	} = createProjectFormData;

	const mainDomainChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setCreateProjectFormData({ type: UPDATE_CUSTOMER_DOMAIN, payload: e.target.value });
	};

	const mainDomainBlurHandler = () => {
		setCreateProjectFormData({ type: VALIDATE_CUSTOMER_DOMAIN });
	};

	const projectNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setCreateProjectFormData({ type: UPDATE_PROJECT_NAME, payload: e.target.value });
	};

	const projectNameBlurHandler = () => {
		setCreateProjectFormData({ type: VALIDATE_PROJECT_NAME });
	};

	const createProjectFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();

		const postProjectAction = await dispatch(postProjectThunk({ projectName, customerServiceDomain: mainDomain }));

		const { projectId: newProjectId } = unwrapResult(postProjectAction);

		navigate(`/${LANG_PATH[language]}/console/product/nf/home/agent/${newProjectId}`);
	};

	useEffect(() => {
		if (error && error?.errorCode === 'P1002') {
			setCreateProjectFormData({
				type: UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
				payload: 'console.nfHome.mainDomainAlreadyInUse',
			});
		}

		if (error && error?.errorCode === 'P1001') {
			setCreateProjectFormData({
				type: UPDATE_PROJECT_NAME_HELP_TEXT,
				payload: 'console.nfHome.projectNameAlreadyInUse',
			});
		}

		if (error && error?.errorCode === 'P1009') {
			setCreateProjectFormData({
				type: UPDATE_PROJECT_NAME_HELP_TEXT,
				payload: 'console.nfHome.projectNameAlreadyInUse',
			});

			setCreateProjectFormData({
				type: UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
				payload: 'console.nfHome.mainDomainAlreadyInUse',
			});
		}

		if (error && error?.errorCode === 'P1010') {
			setCreateProjectFormData({
				type: UPDATE_CUSTOMER_DOMAIN_HELP_TEXT,
				payload: 'ServiceDomainSetting.errorServiceDomainMsg1',
			});
		}
	}, [error]);

	useEffect(() => {
		return () => {
			dispatch(resetProjectError());
		};
	}, [dispatch]);

	return (
		<form css={container} onSubmit={createProjectFormSubmitHandler}>
			<div css={inputContainer}>
				<div css={gap16pxColumn}>
					<div css={titleSt}>{t('console.nfHome.registerMainDomain')}</div>
					<MainDomainDescription gap={16} padding="24px 50.5px 16px" left={178.5} />
					<Textinput
						label={t('console.nfHome.mainDomain')}
						width="100%"
						value={mainDomain}
						onChange={mainDomainChangeHandler}
						onBlur={mainDomainBlurHandler}
						validation={isMainDomainChanged ? mainDomainValidation : 'none'}
						helpText={isMainDomainChanged && !isMainDomainValid && t(mainDomainHelpText)}
						showIcon
						maxLength={253}
						placeholder="example.com"
					/>
				</div>
				<div css={gap16pxColumn}>
					<div css={titleSt}>{t('console.nfHome.registerProjectName')}</div>
					<Textinput
						label={t('console.nfHome.projectName')}
						width="100%"
						value={projectName}
						onChange={projectNameChangeHandler}
						onBlur={projectNameBlurHandler}
						validation={isProjectNameChanged ? projectNameValidation : 'none'}
						helpText={isProjectNameChanged && !isProjectNameValid && t(projectNameHelpText)}
						showIcon
						maxLength={50}
					/>
				</div>
			</div>
			<Button
				size="large"
				mould="custom"
				width="100%"
				disabled={!isProjectNameValid || isLoading || !isMainDomainValid}
				buttonDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
				buttonColor={paletteSDS.blue['700']}
				buttonHoverColor={paletteSDS.blue['800']}
				labelColor={paletteSDS.gray['50']}
			>
				{isLoading ? <Loader height="16px" /> : t('console.nfHome.confirm')}
			</Button>
		</form>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const inputContainer = css`
	display: flex;
	flex-direction: column;
	gap: 48px;
`;

const gap16pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const titleSt = css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['16']};
	color: ${paletteSDS.gray['900']};

	line-height: 140%;
`;

import { paletteSDS } from 'surf-design-system';
import { latlong } from 'features/nfEUM/data/latlong';
import { mapTitleToJsForm } from 'features/nfEUM/utils/mapFunc';

export function createCustomMarker(image) {
	let holder = document.createElement('div');
	let holderSt = holder.style;
	holder.className = `map-marker`;
	holder.title = image.title;
	holderSt.position = 'absolute';
	holderSt.display = 'flex';
	holderSt.flexDirection = 'column';

	let flag = document.createElement('div');
	let flagSt = flag.style;
	flagSt.alignItems = 'center';
	flagSt.width = 'fit-content';
	flagSt.height = 'fit-content';
	flagSt.minHeight = '22px';
	flagSt.display = 'flex';
	flagSt.padding = '2px 8px';
	flagSt.background = image.color;
	flagSt.color = paletteSDS.white;
	flagSt.fontSize = '12px';
	flag.innerText = !image.title ? 'unknown' : image.title;
	holder.appendChild(flag);

	let stick = document.createElement('div');
	let stickSt = stick.style;
	stickSt.position = 'absolute';
	stickSt.top = 0;
	stickSt.left = 0;
	stickSt.borderLeft = `1px solid ${image.color}`;
	stickSt.width = '100%';
	stickSt.height = '60px';
	holder.appendChild(stick);

	let pointer = document.createElement('div');
	let pointerSt = pointer.style;
	pointerSt.position = 'absolute';
	pointerSt.top = '57.5px';
	pointerSt.left = '-2.5px';
	pointerSt.borderRadius = '50%';
	pointerSt.width = '5px';
	pointerSt.height = '5px';
	pointerSt.backgroundColor = image.color;
	holder.appendChild(pointer);

	let processTime = document.createElement('div');
	let processTimeSt = processTime.style;
	processTimeSt.marginTop = '4px';
	processTimeSt.marginLeft = '6px';
	processTimeSt.width = 'fit-content';
	processTimeSt.color = paletteSDS.black;
	processTimeSt.textShadow = '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white';
	processTimeSt.fontSize = '12px';
	processTime.innerText = `${image.line[0]}`;
	holder.appendChild(processTime);

	let loadTime = document.createElement('div');
	let loadTimeSt = loadTime.style;
	loadTimeSt.marginLeft = '6px';
	loadTimeSt.width = 'fit-content';
	loadTimeSt.color = paletteSDS.black;
	loadTimeSt.textShadow = '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white';
	loadTimeSt.fontSize = '12px';
	loadTime.innerText = `${image.line[1]}s`;
	holder.appendChild(loadTime);

	image.chart.chartDiv.appendChild(holder);

	return holder;
}

export function initiateCustomMarker(event) {
	let map = event.chart;
	map.dataProvider.images.slice(0, 5).forEach(image => {
		image.externalElement = createCustomMarker(image);
		image.externalElement.style.top = map.coordinatesToStageXY(image.longitude, image.latitude).y - 60 + 'px';
		image.externalElement.style.left = map.coordinatesToStageXY(image.longitude, image.latitude).x + 'px';
	});
}

export function updateCustomMarkers(event, homeBtnClicked) {
	let markers = document.querySelectorAll('.map-marker');
	for (let i of markers) {
		i.parentNode.removeChild(i);
	}

	let map = event.chart;
	if (homeBtnClicked) {
		map.dataProvider.zoomLevel = 1;
		map.dataProvider.zoomLatitude = map.dataProvider.zoomLatitudeC = 44.0103;
		map.dataProvider.zoomLongitude = map.dataProvider.zoomLongitudeC = 10.3268;
	} else {
		map.dataProvider.zoomLevel = map.zoomLevel();
		map.dataProvider.zoomLatitude = map.dataProvider.zoomLatitudeC = map.zoomLatitude();
		map.dataProvider.zoomLongitude = map.dataProvider.zoomLongitudeC = map.zoomLongitude();
	}

	map.dataProvider.images.slice(0, 5).forEach(image => {
		image.externalElement = createCustomMarker(image);
		image.externalElement.style.top = map.coordinatesToStageXY(image.longitude, image.latitude).y - 60 + 'px';
		image.externalElement.style.left = map.coordinatesToStageXY(image.longitude, image.latitude).x + 'px';
	});
}

export function mapObjectClickHandler(event, callBack) {
	let mapName = event.mapObject.title;
	if (mapName === 'United States') mapName = mapTitleToJsForm('usa');
	else mapName = mapTitleToJsForm(event.mapObject.title);

	let mapUrl = '/lib/ammap/maps/js/' + mapName + '.js';
	if (event.mapObject.parentObject.map === 'worldLow') {
		const newScript = document.createElement('script');
		newScript.type = 'text/javascript';
		newScript.src = mapUrl;
		newScript.async = true;
		newScript.onload = callBack;
		document.head.appendChild(newScript);
	}

	let map = event.chart;
	map.dataProvider.zoomLevel = 1;
	map.dataProvider.zoomLatitude = map.dataProvider.zoomLatitudeC = map.getAreaCenterLatitude(event.mapObject);
	map.dataProvider.zoomLongitude = map.dataProvider.zoomLongitudeC = map.getAreaCenterLongitude(event.mapObject);
}

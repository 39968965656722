/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect, useReducer, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js/core';

import { Button, paletteSDS, Textinput, themeSDS, WarningIcon } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';

import {
	AUTO_FILL,
	initialSigninForm,
	signinFormReducer,
	UPDATE_ID,
	UPDATE_PASSWORD,
	UPDATE_PW_HELP_TEXT,
	VALIDATE_ID,
} from 'features/signin/context/onPremSigninForm.reducer';
import postLoginOnPremApi from 'features/signin/api/postLogin.onPrem.api';
import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';
import axiosInstance from 'common/utils/axiosInstance';

export default function OnPremSigninForm() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { sendRequest: postLogin, isLoading, response, error } = useApiRequestWithArg(postLoginOnPremApi);

	const [signinFormData, setSigninFormData] = useReducer(signinFormReducer, initialSigninForm);
	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
	const [isDisabledGuideVisible, setIsDisabledGuideVisible] = useState(false);

	const idRef = useRef<HTMLInputElement>(null);

	const {
		id,
		password,
		passwordValidation,
		passwordHelpText,
		isPasswordChange,
		isPasswordValid,
		idHelpText,
		idValidation,
		isIdChanged,
		isIdValid,
	} = signinFormData;

	const idChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSigninFormData({ type: UPDATE_ID, payload: e.target.value });
	};

	const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSigninFormData({ type: UPDATE_PASSWORD, payload: e.target.value });
	};

	const idBlurHandler = () => {
		setSigninFormData({ type: VALIDATE_ID });
	};

	const inputLabelClickHandler = () => {
		setPasswordType(prevType => {
			if (prevType === 'password') {
				return 'text';
			}
			return 'password';
		});
	};

	const passwordInputLabel = useMemo(
		() => (
			<div css={pwValidationLabelCss}>
				<div>{t('commonWords.password')}</div>
				<button css={toggleButtonCss} tabIndex={-1} type="button" onClick={inputLabelClickHandler}>
					{passwordType === 'password' ? t('signIn.showPwd') : t('signIn.hidePwd')}
				</button>
			</div>
		),
		[passwordType, t]
	);

	const signinFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();
		setIsDisabledGuideVisible(false);
		postLogin({ id, password: CryptoJS.SHA256(password).toString() });
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (idRef.current && idRef.current.matches(':-internal-autofill-selected')) {
				setSigninFormData({ type: AUTO_FILL });
			}
		}, 800);

		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (response) {
			window.sessionStorage.setItem(COGNITO_TOKEN, response.data.token);
			window.sessionStorage.setItem(USER_KEY, response.data.userKey);

			axiosInstance.defaults.headers.Authorization = response.data.token;
			axiosInstance.defaults.headers.userKey = response.data.userKey;
			navigate(`/${getI18nPath()}/on_prem/account_setting`);
		}
		// eslint-disable-next-line
	}, [response]);

	useEffect(() => {
		if (error && error?.response?.data.errorCode === 'U1000') {
			setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'onPrem.signin.idPasswordIncorrect' });
			return;
		}

		if (error && error?.response?.data.errorCode === 'AU1002') {
			setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'onPrem.signin.idPasswordIncorrect' });
			return;
		}

		if (error && error?.response?.data.errorCode === 'AU1003') {
			setIsDisabledGuideVisible(true);
			return;
		}

		if (error && error?.response?.data.errorCode === 'AU1005') {
			setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'onPrem.signin.ipNotAllowed' });
			return;
		}

		if (error && error?.response?.data.errorCode === 'AU1006') {
			setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'onPrem.signin.signUpMsg2' });
			return;
		}

		if (error && error?.response?.data.errorCode === 'AU1007') {
			setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'onPrem.signin.signUpMsg2' });
		}
	}, [error]);

	return (
		<form css={formSt} onSubmit={signinFormSubmitHandler}>
			<div css={headerSt}>{t('onPrem.signin.signin')}</div>
			{isDisabledGuideVisible ? (
				<div css={disabledGuideBox}>
					<WarningIcon cursor="default" fill={paletteSDS.red['500']} emotionCss={warningIconCss} />
					<div css={disabledGuide}>{t('onPrem.signin.disabledGuide')}</div>
				</div>
			) : null}
			<div css={buttonWrap}>
				<Textinput
					width="100%"
					label={t('onPrem.signin.id')}
					name="id"
					value={id}
					onChange={idChangeHandler}
					validation={isIdChanged ? idValidation : 'none'}
					helpText={isIdChanged && !isIdValid && t(idHelpText)}
					onBlur={idBlurHandler}
					maxLength={30}
					ref={idRef}
					autoComplete="on"
				/>
				<Textinput
					valueType={passwordType}
					name="password"
					label={passwordInputLabel}
					value={password}
					onChange={passwordChangeHandler}
					validation={isPasswordChange ? signinFormData.passwordValidation : 'none'}
					helpText={isPasswordChange && passwordValidation === 'failed' && t(passwordHelpText)}
					maxLength={20}
					autoComplete="on"
				/>
			</div>
			<Button width="100%" type="submit" disabled={!isIdValid || !isPasswordValid || isLoading}>
				{t('signIn.signIn')}
			</Button>
		</form>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	width: 414px;

	padding: 64px 16px;
`;

const buttonWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	width: 382px;
`;

const headerSt = css`
	width: 100%;

	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.blue[900]};
	line-height: 140%;

	text-align: center;
`;

const disabledGuideBox = css`
	display: flex;
	gap: 8px;

	width: 100%;

	padding: 12px 16px 12px 16px;

	background: ${paletteSDS.red['50']};

	border: 1px solid ${paletteSDS.red['200']};
	border-radius: 8px;
`;

const disabledGuide = css`
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.black};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;

	word-break: keep-all;
	white-space: pre-line;
`;

const pwValidationLabelCss = css`
	display: flex;
	width: 382px;
	justify-content: space-between;
`;

const toggleButtonCss = css`
	justify-content: center;
	align-items: center;
	border: none;
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.blue[700]};
	background-color: transparent;
	cursor: pointer;
`;

const warningIconCss = css`
	flex: none;
`;

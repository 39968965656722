import axios from 'axios';

import { defaultLogoImageBase64 } from '../data/defaultImageBase64';

export interface ConvertFileToUrlSuccessProps {
	src: string;
	name: string;
}

export const convertFileToUrl = (file: File, onSuccess: ({ src, name }: ConvertFileToUrlSuccessProps) => void) => {
	const fileReader = new FileReader();

	fileReader.onload = () => {
		if (typeof fileReader.result === 'string') {
			onSuccess({ src: fileReader.result, name: file.name });
		}
	};

	fileReader.readAsDataURL(file);
};

export const convertUrlToImgFile = async (url: string, fileName: string) => {
	if (url === 'default_logo') {
		const { data } = await axios.get<Blob>(defaultLogoImageBase64, { responseType: 'blob' });

		return new File([data], 'default_logo.png', { type: 'image/png' });
	}

	if (url === '') {
		return new File([], '', { type: 'image/png' });
	}

	const { data } = await axios.get<Blob>(url, { responseType: 'blob' });

	let fileType = 'image/png';

	if (fileName.includes('jpg')) {
		fileType = 'image/jpg';
	}

	if (fileName.includes('jpeg')) {
		fileType = 'image/jpeg';
	}

	const convertedFile = new File([data], fileName, { type: fileType });

	return convertedFile;
};

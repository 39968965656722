/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { CheckIcon, paletteSDS } from 'surf-design-system';
import { useTranslation } from 'react-i18next';

export default function OnpremAgentGuideEnd() {
	const { t } = useTranslation();
	return (
		<div css={container}>
			<CheckIcon size="large" fill={paletteSDS.gray[500]} cursor="default" />
			{/* <div css={title}>{t('agent.endAgentGuide')}</div> */}
			<div css={desc}>{t('agent.endAgentGuideDesc')}</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	width: 100%;
`;

const title = css`
	color: ${paletteSDS.gray[800]};
	font-size: 16px;
	font-weight: 600;
	line-height: 160%;
`;

const desc = css`
	color: ${paletteSDS.gray[600]};
	font-size: 14px;
	font-weight: 400;
	line-height: 160%;
`;

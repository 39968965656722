/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AddIcon, Button, ChevronLeftIcon, ChevronRightIcon, paletteSDS } from 'surf-design-system';

import {
	toggleIsIntervalSegmentCreateModalVisible,
	toggleIsIntervalSegmentMaxNumModalVisible,
} from 'common/context/modalSlice';
import { RootState, useAppDispatch } from 'common/redux/store';

import IntervalSegmentDeleteConfirmModal from 'features/nfInterval/components/IntervalSegmentDeleteConfirmModal';
import IntervalSegmentMaxNumModal from 'features/nfInterval/components/IntervalSegmentMaxNumModal';
import IntervalSegmentCreateSideModal from 'features/nfInterval/components/IntervalSegmentCreateSideModal';
import IntervalSegmentUpdateSideModal from 'features/nfInterval/components/IntervalSegmentUpdateSideModal';
import SegmentGraphModal from 'features/nfBasic/components/SegmentMaxGraphModal';
import PathControlSegmentCardClassic from './PathControlSegmentCardClassic';
import putPathControlSegmentOrderThunk from '../thunk/putPathControlSegmentOrder.v2.thunk';

export default function PathControlSegmentBoardClassic() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { meta, value, isValueLoaded } = useSelector((state: RootState) => state.nfControl.segment.path);

	const { userRole } = useSelector((state: RootState) => state.account);

	const { planOptions } = useSelector((state: RootState) => state.plan);

	const segmentRef = useRef<HTMLDivElement>(null);
	const [pickedCardIndex, setPickedCardIndex] = useState<number>(0);
	const [segWrapperWidth, setSegWrapperWidth] = useState(segmentRef.current?.offsetWidth);
	const [segmentScrollPosition, setSegmentScrollPosition] = useState<number>(0);
	const [isScrollBtnActivated, setIsScrollBtnActivated] = useState(true);
	const [throttle, setThrottle] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	// description: resize 감지, segWrapper width 사이즈 변경 감지
	useEffect(() => {
		const handleResizeWindow = () => {
			if (throttle) return;

			setThrottle(true);
			setTimeout(() => {
				setSegWrapperWidth(() => segmentRef.current?.offsetWidth);
				setThrottle(false);
			}, 300);
		};
		window.addEventListener('resize', handleResizeWindow);

		return () => {
			window.removeEventListener('resize', handleResizeWindow);
		};
	}, [setSegWrapperWidth, throttle]);

	useEffect(() => {
		if (segmentRef.current) setSegWrapperWidth(segmentRef.current.offsetWidth);
	}, [segmentRef.current?.offsetWidth, meta.length]);

	// description: 스크롤버튼 활성화 여부 판단
	useEffect(() => {
		if (isFirstRender) return;

		if ((segWrapperWidth as number) < meta.length * 222) {
			setSegmentScrollPosition(() => 0);
			setIsScrollBtnActivated(() => true);
		} else {
			setIsScrollBtnActivated(() => false);
		}
	}, [isFirstRender, segWrapperWidth, meta.length]);

	// description: 스크롤버튼 핸들러
	const handleClickScrollLeft = () => {
		if (segmentScrollPosition + 222 > 0) setSegmentScrollPosition(0);
		else setSegmentScrollPosition(prev => prev + 222);
	};

	const handleClickScrollRight = () => {
		if ((segmentRef.current?.offsetWidth as number) < meta.length * 222) {
			if (222 * meta.length - Number(segWrapperWidth) + segmentScrollPosition < 222)
				setSegmentScrollPosition(prev => prev - (222 * meta.length - Number(segWrapperWidth) + segmentScrollPosition));
			else setSegmentScrollPosition(prev => prev - 222);
		}
	};

	// description: segment 순서 변경
	const segmentCardDragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
		const obj = e.currentTarget;
		// eslint-disable-next-line
		setPickedCardIndex([...(obj.parentNode?.children as any)].indexOf(obj));
	};

	const segmentCardDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const segmentCardDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
		const obj = e.currentTarget;
		// eslint-disable-next-line
		const droppedCardIndex = [...(obj.parentNode?.children as any)].indexOf(obj);

		if (droppedCardIndex !== pickedCardIndex && meta.length > 1) {
			dispatch(putPathControlSegmentOrderThunk({ pickedCardIndex, droppedCardIndex }));
		}
	};

	// description: 세그먼트 추가 버튼 관련
	const addButtonClickHandler = () => {
		if (meta.length < planOptions[7].value) {
			dispatch(toggleIsIntervalSegmentCreateModalVisible());
		} else {
			dispatch(toggleIsIntervalSegmentMaxNumModalVisible());
		}
	};

	if (isFirstRender) setIsFirstRender(false);

	if (!isValueLoaded) return <div css={boardClassicCss} />;

	return (
		<div css={boardClassicCss}>
			<div css={titleCss}>
				<span css={titleTextCss}>{t('projectMonitoring.segment')}</span>
				<div css={titleRightCss}>
					<Button
						size="small"
						icon={<AddIcon fill={paletteSDS.gray[50]} />}
						iconPosition="left"
						onClick={addButtonClickHandler}
						disabled={userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR'}
					>
						{t('projectMonitoring.add')}
					</Button>
					<div css={segmentScrollBtnWrapCss}>
						<button
							css={segmentScrollBtnCss(isScrollBtnActivated)}
							onClick={isScrollBtnActivated ? handleClickScrollLeft : undefined}
						>
							<ChevronLeftIcon width="20" height="20" fill={paletteSDS.blue[700]} />
						</button>
						<button
							css={segmentScrollBtnCss(isScrollBtnActivated)}
							onClick={isScrollBtnActivated ? handleClickScrollRight : undefined}
						>
							<ChevronRightIcon width="20" height="20" fill={paletteSDS.blue[700]} />
						</button>
					</div>
				</div>
			</div>
			<div ref={segmentRef} css={segmentWrapperCss}>
				{meta.length > 0 ? (
					<ul css={segmentListCss(segmentScrollPosition, meta.length)}>
						{value.map((data, idx) => (
							<PathControlSegmentCardClassic
								key={data.id}
								data={data}
								meta={meta[idx]}
								onDragOver={segmentCardDragOverHandler}
								onDragStart={segmentCardDragStartHandler}
								onDrop={segmentCardDropHandler}
							/>
						))}
					</ul>
				) : null}
			</div>
			<IntervalSegmentCreateSideModal isClassic />
			<IntervalSegmentUpdateSideModal isClassic />
			<IntervalSegmentDeleteConfirmModal />
			<IntervalSegmentMaxNumModal />
			<SegmentGraphModal />
		</div>
	);
}

const boardClassicCss = css`
	width: 100%;
	height: 590px;
	background-color: ${paletteSDS.systemColor.white};
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	overflow: hidden;
`;

const titleCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 72px;
	padding: 16px 24px;
	border-bottom: 1px solid ${paletteSDS.gray[300]};
`;

const titleTextCss = css`
	color: ${paletteSDS.systemColor.black};
	font-weight: 600;
	font-size: 16px;
	line-height: 100%;
`;

const segmentWrapperCss = css`
	width: 100%;
	overflow: hidden;
`;

const segmentListCss = (segmentScrollPosition: number, cardsLength: number) => css`
	display: flex;
	width: ${cardsLength * 222}px;
	background: ${paletteSDS.systemColor.white};
	transform: ${`translateX(${segmentScrollPosition}px)`};
	transition: 0.5s all;
	/* overflow-y: hidden; */
	/* overflow-x: auto; */

	&::-webkit-scrollbar {
		display: none;
	}
`;

const titleRightCss = css`
	display: flex;
	gap: 8px;
`;

const segmentScrollBtnWrapCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 4px;
	overflow: hidden;
	z-index: 10;
`;

const segmentScrollBtnCss = (activated: boolean) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 2rem;
	background-color: ${paletteSDS.gray[50]};
	border: none;

	svg {
		fill: ${activated ? paletteSDS.blue[700] : paletteSDS.gray[300]};
	}

	&:hover {
		svg {
			fill: ${activated ? paletteSDS.blue[800] : paletteSDS.gray[300]};
		}
	}
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putBasicControlSegmentApi from 'features/nfControl/api/putBasicControlSegment.v2.api';

import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';
import type { SegmentType, URIId } from 'features/nfControl/type/getBasicControlSegmentDetail.v2.type';
import type { MemberInfo } from 'features/nfControl/context/segmentCreateDynamicForm.onPrem.reducer';
import { resetBasicControlModal } from 'common/context/modalSlice';
import putOperatorApi from 'features/nfControl/api/putOperator.v2.api';

export interface PutBasicControlSegmentOnPremThunkProps {
	segmentName: string;
	accessYn: boolean;
	maxInflow: string;
	deleteUriId: URIId[];
	addUriPath: string[];
	segmentId: number;
	vwrPageId: number;
	segmentType: SegmentType;
	dynamicMinInflow: number;
	dynamicMaxInflow: number;
	dynamicSectionMinSec: number;
	dynamicSectionMaxSec: number;
	members: MemberInfo[];
}

const putBasicControlSegmentOnPremThunk = createAsyncThunk<
	Omit<SegmentMetaData, 'segmentGraphYn' | 'segmentKey' | 'segmentColor' | 'segmentOrder'>,
	PutBasicControlSegmentOnPremThunkProps,
	{ state: RootState }
>('put/basic/segment/v2/onPrem', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const {
		segmentName,
		accessYn,
		maxInflow,
		deleteUriId,
		addUriPath,
		segmentId,
		dynamicMaxInflow,
		dynamicMinInflow,
		segmentType,
		dynamicSectionMinSec,
		dynamicSectionMaxSec,
		vwrPageId,
		members,
	} = args;

	try {
		await putBasicControlSegmentApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			body: {
				segmentName,
				accessYn,
				maxInflow: Number(maxInflow),
				deleteUriId,
				addUriPath: addUriPath.map(el => ({ path: el })),
				dynamicMaxInflow,
				dynamicMinInflow,
				segmentType,
				dynamicSectionMinSec,
				dynamicSectionMaxSec,
				vwrPageId,
			},
			segmentId,
		});

		await putOperatorApi({
			tenantId: tenantId || '',
			organizationId,
			segmentId,
			userList: members.map(member => member.userKey),
		});

		thunkAPI.dispatch(resetBasicControlModal());

		return { segmentName, accessYn, maxInflow: Number(maxInflow), id: segmentId };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default putBasicControlSegmentOnPremThunk;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	Modal,
	Textinput,
	Portal,
	paletteSDS,
	themeSDS,
	Button,
	Radio,
	Dropdown,
	colorPalette,
	InfoIcon,
	Loader,
} from 'surf-design-system';

import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import { useAppDispatch, RootState } from 'common/redux/store';
import Tooltip from 'common/components/Tooltip';
import Astrix from 'common/components/Astrix';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import getProjectMacroInfoApi from 'features/nfBasic/api/getProjectMacroInfo.api';
import { putProjectMacroInfoThunk } from 'features/nfSetting/thunk/putProjectMacroInfo.v1.thunk';

import {
	macroSettingFormReducer,
	UPDATE_CHECK_MACRO_INTERVAL,
	UPDATE_IS_MACRO_BLOCKED,
	UPDATE_LIMIT_NUM_PER_PERIOD,
	UPDATE_LIMIT_NUM_PER_SEC,
	UPDATE_BLOCK_TIME,
	initialMacroSettingForm,
	INITIALIZE_FORM,
	VALIDATE_LIMIT_NUM_PER_PERIOD,
	VALIDATE_LIMIT_NUM_PER_SEC,
} from 'features/nfSetting/context/macroSettingForm.reducer';
import { resetProjectError } from 'features/nfSetting/context/projectInfoSlice';

export default function MacroSettingModal() {
	const { t } = useTranslation();

	const { response, sendRequest } = useApiRequestWithArg(getProjectMacroInfoApi);

	const dispatch = useAppDispatch();

	const { organizationId, tenantUrl, tenantId } = useSelector((state: RootState) => state.account);
	const { isLoading } = useSelector((state: RootState) => state.projectInfo);

	const { selectedProjectId } = useSelector((state: RootState) => state.modal.projectManagementModal);

	const [macroSettingFormData, setMacroSettingFormData] = useReducer(macroSettingFormReducer, initialMacroSettingForm);

	const {
		enteredCheckMacroInterval,
		enteredIsMacroBlocked,
		enteredLimitNumPerPeriod,
		enteredLimitNumPerSec,
		enteredBlockTime,
	} = macroSettingFormData;

	useEffect(() => {
		sendRequest({ organizationId, tenantId: tenantId || '', tenantUrl, projectId: selectedProjectId });
	}, [sendRequest, organizationId, tenantId, tenantUrl, selectedProjectId]);

	useEffect(() => {
		if (response) {
			const { blockTime, blockYn, checkCycle, limitNumPerCycle, limitNumPerSec } = response.data;
			setMacroSettingFormData({
				type: INITIALIZE_FORM,
				payload: [
					(blockTime || '').toString(),
					(checkCycle || '').toString(),
					blockYn ? 'true' : 'false',
					limitNumPerCycle.toString(),
					limitNumPerSec.toString(),
				].join(','),
			});
		}
	}, [response]);

	useEffect(() => {
		return () => {
			dispatch(resetProjectError());
		};
	}, [dispatch]);

	const checkMacroIntervalChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setMacroSettingFormData({ type: UPDATE_CHECK_MACRO_INTERVAL, payload: e.target.value });
	};

	const blockTimeChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = e => {
		setMacroSettingFormData({ type: UPDATE_BLOCK_TIME, payload: e.target.value });
	};

	const isMacroBlockedChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setMacroSettingFormData({ type: UPDATE_IS_MACRO_BLOCKED, payload: e.target.value });
	};

	const limitNumPerPeriodChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setMacroSettingFormData({ type: UPDATE_LIMIT_NUM_PER_PERIOD, payload: e.target.value });
	};

	const limitNumPerSecChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setMacroSettingFormData({ type: UPDATE_LIMIT_NUM_PER_SEC, payload: e.target.value });
	};

	const limitNumPerPeriodBlurHandler = () => {
		setMacroSettingFormData({ type: VALIDATE_LIMIT_NUM_PER_PERIOD });
	};

	const limitNumPerSecBlurHandler = () => {
		setMacroSettingFormData({ type: VALIDATE_LIMIT_NUM_PER_SEC });
	};

	const closeHandler = () => {
		dispatch(initializeProjectManagementModal());
	};

	const macroSettingSubmitHandler: React.FormEventHandler = async e => {
		e.preventDefault();
		await dispatch(
			putProjectMacroInfoThunk({
				blockYn: enteredIsMacroBlocked === 'true',
				blockType: 'IP',
				limitNumPerCycle: enteredLimitNumPerPeriod === '' ? 0 : Number(enteredLimitNumPerPeriod),
				limitNumPerSec: enteredLimitNumPerSec === '' ? 0 : Number(enteredLimitNumPerSec),
				checkCycle: enteredCheckMacroInterval === '' ? 0 : Number(enteredCheckMacroInterval),
				blockTime: Number(enteredBlockTime),
				onClose: closeHandler,
				selectedProjectId,
			})
		);
	};

	if (response === null) {
		return null;
	}

	return (
		<Portal>
			<Modal
				visible
				title={t('projectMgmt.blockMacroSetting')}
				actionType="none"
				isCloseIconVisible
				onClickCloseButton={closeHandler}
			>
				<form onSubmit={macroSettingSubmitHandler} css={formWrap}>
					<div css={inputWrap}>
						<div css={contentWrap}>
							<div css={tooltipWrap}>
								<div css={labelSt}>{t('projectMgmt.macroMode')}</div>
								<Tooltip message={t('projectMgmt.blockModeTooltip')} />
							</div>
							<Radio
								radioList={[
									{ id: '1', name: 'ON', value: 'true' },
									{ id: '2', name: 'OFF', value: 'false' },
								]}
								groupName="!"
								direction="horizontal"
								defaultValue={enteredIsMacroBlocked}
								onChange={isMacroBlockedChangeHandler}
							/>
						</div>
						<div css={cautionWrap}>
							<InfoIcon fill={`${paletteSDS.blue[500]}`} width="24" height="24" />
							<div css={cautionSt}>{t('projectMgmt.botBlockCaution')}</div>
						</div>
						<div css={contentWrap}>
							<div css={labelSt}>{t('projectMgmt.type')}</div>
							<div
								css={[ipLabelSt, enteredIsMacroBlocked === 'true' ? colorPalette.blue[700] : colorPalette.gray[500]]}
							>
								IP
							</div>
						</div>
						<div css={periodWrap}>
							<div css={contentWrap}>
								<div css={tooltipWrap}>
									<div css={labelSt}>{t('projectMgmt.cycleSetting')}</div>
									<Tooltip message={t('projectMgmt.cycleTooltip')} />
								</div>
								<Dropdown
									menu={[
										{ value: '', name: t('projectMgmt.select') },
										{ value: '5', name: '5s' },
										{ value: '10', name: '10s' },
										{ value: '15', name: '15s' },
										{ value: '20', name: '20s' },
									]}
									placeholder={t('projectMgmt.select')}
									onChange={checkMacroIntervalChangeHandler}
									disabled={enteredIsMacroBlocked === 'false'}
									size="small"
									bindedValue={enteredCheckMacroInterval}
									width="120px"
								/>
							</div>
							<div css={contentWrap}>
								<div css={labelSt}>
									&nbsp;&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;&nbsp;{t('projectMgmt.limitPerCycle')}
								</div>
								<Textinput
									valueType="number"
									value={enteredLimitNumPerPeriod}
									disabled={enteredIsMacroBlocked === 'false' || enteredCheckMacroInterval === ''}
									onChange={limitNumPerPeriodChangeHandler}
									width="120px"
									size="small"
									maxLength={5}
									max={99999}
									min={0}
									step={1}
									onBlur={limitNumPerPeriodBlurHandler}
								/>
							</div>
						</div>
						<div css={contentWrap}>
							<div css={labelSt}>{t('projectMgmt.limitPerSecond')}</div>
							<Textinput
								valueType="number"
								value={enteredLimitNumPerSec}
								disabled={enteredIsMacroBlocked === 'false'}
								onChange={limitNumPerSecChangeHandler}
								width="120px"
								size="small"
								maxLength={5}
								max={99999}
								step={1}
								min={1}
								onBlur={limitNumPerSecBlurHandler}
							/>
						</div>
						<div css={contentWrap}>
							<div css={tooltipWrap}>
								<div css={labelSt}>
									{t('projectMgmt.blockPeriodSetting')}
									<Astrix />
								</div>
								<Tooltip message={t('projectMgmt.blockPeriodTooltip')} />
							</div>
							<Dropdown
								menu={[
									{ value: '5', name: '5s' },
									{ value: '10', name: '10s' },
									{ value: '15', name: '15s' },
									{ value: '20', name: '20s' },
								]}
								size="small"
								placeholder={t('projectMgmt.select')}
								onChange={blockTimeChangeHandler}
								disabled={enteredIsMacroBlocked === 'false'}
								bindedValue={enteredBlockTime}
								width="120px"
							/>
						</div>
					</div>
					<Button
						mould="custom"
						width="100%"
						disabled={
							(enteredIsMacroBlocked === 'true'
								? (enteredLimitNumPerPeriod === '' && enteredLimitNumPerSec === '') ||
								  enteredBlockTime === '' ||
								  (enteredCheckMacroInterval !== '' && enteredLimitNumPerPeriod === '')
								: false) || isLoading
						}
						buttonDisabledColor={isLoading ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
						buttonColor={paletteSDS.blue['700']}
						buttonHoverColor={paletteSDS.blue['800']}
						labelColor={paletteSDS.gray['50']}
					>
						{isLoading ? <Loader height="16px" /> : t('projectMgmt.save')}
					</Button>
				</form>
			</Modal>
		</Portal>
	);
}

const contentWrap = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const formWrap = css`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const inputWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const periodWrap = css`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`;

const tooltipWrap = css`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const labelSt = css`
	font-size: ${themeSDS.fontSize[14]};
	color: ${paletteSDS.gray[700]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;
`;

const ipLabelSt = css`
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 160%;
`;

const cautionWrap = css`
	display: flex;
	gap: 0.5rem;

	background: ${paletteSDS.blue[50]};

	border: 1px solid ${paletteSDS.blue[200]};
	border-radius: 8px;

	padding: 1rem;
`;

const cautionSt = css`
	width: calc(100% - 2rem);

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray[900]};

	white-space: pre-wrap;
`;

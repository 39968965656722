/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { paletteSDS } from 'surf-design-system';
import { loadTimeColor } from 'features/nfEUM/data/constants';
import { useTranslation } from 'react-i18next';

export default function TXNMapLegends() {
	const { t } = useTranslation();
	return (
		<div css={container}>
			<div css={title}>
				{t('eum.loadTime')}
				{t('eum.sec')}
			</div>
			<div css={listWrapper}>
				{new Array(10).fill(0).map((el, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<div key={i} css={listContent}>
						<div css={colorSquare(i)} />
						{i === 9 ? <div>{i} ~</div> : <div>{`${i} ~ ${i + 1}`}</div>}
					</div>
				))}
			</div>
		</div>
	);
}

const container = css`
	position: absolute;
	left: 8px;
	bottom: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	color: ${paletteSDS.gray[600]};
	font-weight: 400;
	font-size: 10px;
	line-height: 128%;
	letter-spacing: 0.16px;
`;

const title = css`
	font-weight: 600;
`;

const listWrapper = css`
	display: flex;
	flex-direction: column-reverse;
`;

const listContent = css`
	display: flex;
	gap: 4px;
`;

const colorSquare = (index: number) => css`
	width: 10px;
	height: 10px;
	background-color: ${loadTimeColor[index as keyof typeof loadTimeColor]};
`;

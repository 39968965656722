/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useEffect, useMemo, useReducer, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS, Textinput, themeSDS } from 'surf-design-system';

import { encrypt } from 'common/utils/cryptoFunc';
import { getI18nPath } from 'common/utils/languageFunc';

import SigninBlockModal from 'features/signin/components/SigninBlockModal';

import {
	AUTO_FILL,
	initialSigninForm,
	signinFormReducer,
	UPDATE_EMAIL,
	UPDATE_PASSWORD,
	UPDATE_PW_HELP_TEXT,
	VALIDATE_EMAIL,
} from '../context/signinForm.reducer';

import { MAX_EMAIL_CHAR_NUM } from '../data/constants';
import { cognitoSignin } from '../api/cognitoSignin';

export default function SigninForm() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [signinFormData, setSigninFormData] = useReducer(signinFormReducer, initialSigninForm);
	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
	const [isLoading, setIsLoading] = useState(false);

	const emailRef = useRef<HTMLInputElement>(null);

	const {
		email,
		password,
		passwordValidation,
		passwordHelpText,
		isPasswordChange,
		isPasswordValid,
		emailHelpText,
		emailValidation,
		isEmailChanged,
		isEmailValid,
	} = signinFormData;

	const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSigninFormData({ type: UPDATE_EMAIL, payload: e.target.value });
	};

	const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setSigninFormData({ type: UPDATE_PASSWORD, payload: e.target.value });
	};

	const emailBlurHandler = () => {
		setSigninFormData({ type: VALIDATE_EMAIL });
	};

	const inputLabelClickHandler = () => {
		setPasswordType(prevType => {
			if (prevType === 'password') {
				return 'text';
			}
			return 'password';
		});
	};

	const signinFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();

		if (!isEmailValid || !isPasswordValid) return;

		setIsLoading(true);

		await cognitoSignin({
			Username: signinFormData.email,
			Password: signinFormData.password,
			onChangePasswordRequired: () => {
				navigate(`/${getI18nPath()}/signin/resetpw`, {
					state: {
						a: true,
						b: email,
						c: encrypt(password),
					},
				});
			},
			onFail: err => {
				if (err.message === 'Incorrect username or password.') {
					setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'signIn.error.signUpMsg' });
					setIsLoading(false);
					return;
				}

				if (err.message === 'CUSTOM_AUTH is not enabled for the client.') {
					setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: '' });
					setIsLoading(false);
					return;
				}

				setSigninFormData({ type: UPDATE_PW_HELP_TEXT, payload: 'signIn.error.signUpMsg2' });
				setIsLoading(false);
			},
			onSuccess: () => {
				setIsLoading(false);
				navigate(`/${getI18nPath()}/account_setting`);
			},
		});
	};

	const passwordInputLabel = useMemo(
		() => (
			<div css={pwValidationLabelCss}>
				<div>{t('commonWords.password')}</div>
				<button css={toggleButtonCss} tabIndex={-1} type="button" onClick={inputLabelClickHandler}>
					{passwordType === 'password' ? t('signIn.showPwd') : t('signIn.hidePwd')}
				</button>
			</div>
		),
		[passwordType, t]
	);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (emailRef.current && emailRef.current.matches(':-internal-autofill-selected')) {
				setSigninFormData({ type: AUTO_FILL });
			}
		}, 800);

		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<form css={formSt} onSubmit={signinFormSubmitHandler}>
			<div css={buttonWrap}>
				<Textinput
					width="100%"
					label={t('commonWords.email')}
					name="email"
					value={email}
					onChange={emailChangeHandler}
					validation={isEmailChanged ? emailValidation : 'none'}
					helpText={isEmailChanged && emailValidation === 'failed' && t(emailHelpText)}
					onBlur={emailBlurHandler}
					maxLength={MAX_EMAIL_CHAR_NUM}
					ref={emailRef}
					autoComplete="on"
				/>
				<Textinput
					valueType={passwordType}
					width="100%"
					name="password"
					label={passwordInputLabel}
					value={password}
					onChange={passwordChangeHandler}
					validation={isPasswordChange ? signinFormData.passwordValidation : 'none'}
					helpText={isPasswordChange && passwordValidation === 'failed' && t(passwordHelpText)}
					maxLength={20}
					autoComplete="on"
				/>
			</div>
			<Button width="100%" type="submit" disabled={!isEmailValid || !isPasswordValid || isLoading}>
				{t('signIn.signIn')}
			</Button>
			<SigninBlockModal />
		</form>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

const pwValidationLabelCss = css`
	display: flex;
	width: 25rem;
	justify-content: space-between;
`;

const toggleButtonCss = css`
	justify-content: center;
	align-items: center;
	border: none;
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.blue[700]};
	background-color: transparent;
	cursor: pointer;
`;

const buttonWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

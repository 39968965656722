import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import { DeleteVWRApiResponse } from 'features/vwr/types/deleteVWR.v2.type';

export interface DeleteVWRApiProps {
	tenantId: string;
	projectId: number;
	organizationId: number;
	id: number;
}

export const deleteVWRApi = async ({ tenantId, projectId, organizationId, id }: DeleteVWRApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken) {
		const { data } = await axios.delete<DeleteVWRApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/vwr-page/${id}`,
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

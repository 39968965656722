/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import NonKoreanBillingInfoForm from 'features/plan/components/NonKoreanBillingInfoForm';
import PlanTitle from 'features/plan/components/PlanTitle';
import PaymentMethod from 'features/plan/components/PaymentMethod';
import CompanyAddressContent from 'features/plan/components/CompanyAddressContent';

export default function NonKoreanBillingInfo() {
	const { t } = useTranslation();

	const { name } = useSelector((state: RootState) => state.payment);

	const { country, streetAddress, streetAddressDetail, city, region, postalCode, vat } = useSelector(
		(state: RootState) => state.address
	);

	const [isViewMode, setIsViewMode] = useState<boolean>(name !== '');

	const viewModeChangeHandler = () => {
		setIsViewMode(prevState => !prevState);
	};

	if (!isViewMode) {
		return <NonKoreanBillingInfoForm onChangeViewMode={viewModeChangeHandler} />;
	}

	return (
		<div css={container}>
			<div css={titleWrap}>
				<PlanTitle title={t('freeBeta.billingInfo')} />
			</div>
			<div css={lowerBox}>
				<div css={contentBox}>
					<h5>{t('planSubscription.companyAddress')}</h5>
					<CompanyAddressContent title={t('planSubscription.country')} value={country} key="country" />
					<CompanyAddressContent
						title={t('planSubscription.streetAddress')}
						value={streetAddress}
						key="streetAddress"
					/>
					<CompanyAddressContent
						title={t('planSubscription.suietUnit')}
						value={streetAddressDetail}
						key="streetAddressDetail"
					/>
					<CompanyAddressContent title={t('planSubscription.city')} value={city} key="city" />
					<CompanyAddressContent title={t('planSubscription.stateProvReg')} value={region} key="region" />
					<CompanyAddressContent title={t('planSubscription.zipCode')} value={postalCode} key="postalCode" />
					<CompanyAddressContent title={t('planSubscription.vat')} value={vat} key="vat" />
				</div>
				<div css={paymentContainer}>
					<PaymentMethod isLinkVisible={false} />
					<Button mould="secondary" width="180px" onClick={viewModeChangeHandler}>
						{t('planSubscription.edit')}
					</Button>
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
`;

const titleWrap = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const lowerBox = css`
	display: flex;
	justify-content: space-between;

	padding: 32px;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
`;

const paymentContainer = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
`;

const contentBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabElement, Tabs } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsSubscriptionCancelToastVisible } from 'common/context/toastSlice';
import { LANG_PATH } from 'common/data/constants';

import PaymentFormModal from 'features/plan/components/PaymentFormModal';

export default function PlanUsageLayout() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const [params, setParams] = useSearchParams();

	const [selectedTabId, setSelectedTabId] = useState<number | null>(null);

	const { userRole, language } = useSelector((state: RootState) => state.account);

	const planUsageTabElements: TabElement[] = [
		{
			id: 0,
			onClick: () => {
				navigate('plan');
			},
			tabLabel: t('planSubscription.plan'),
		},
		{
			id: 1,
			onClick: () => {
				navigate('billing_history');
			},
			tabLabel: t('planSubscription.billingHistory'),
		},
		// {
		// 	id: 2,
		// 	onClick: () => {
		// 		navigate('/console/plan_usage/company_address');
		// 	},
		// 	tabLabel: t('planSubscription.companyAddress'),
		// },
		{
			id: 3,
			onClick: () => {
				navigate('payment_method');
			},
			tabLabel: t('freeBeta.billingInfo'),
		},
		{
			id: 4,
			onClick: () => {
				navigate('usage');
			},
			tabLabel: t('planSubscription.planUsage'),
		},
	];

	useEffect(() => {
		if (pathname.includes('plan_usage/usage')) {
			setSelectedTabId(4);
		} else if (pathname.includes('payment_method')) {
			setSelectedTabId(3);
		} else if (pathname.includes('company_address')) {
			setSelectedTabId(2);
		} else if (pathname.includes('billing_history')) {
			setSelectedTabId(1);
		} else {
			setSelectedTabId(0);
		}
	}, [pathname]);

	useEffect(() => {
		if (params.get('cancel') === 'true') {
			dispatch(toggleIsSubscriptionCancelToastVisible());
			params.delete('cancel');
			setParams(params);
		}
	}, [params, setParams, dispatch]);

	useEffect(() => {
		if (userRole !== 'OWNER') {
			navigate(`/${LANG_PATH[language]}/home`);
		}
		// eslint-disable-next-line
	}, [userRole, language]);

	return (
		<div css={containerSt}>
			<Tabs tabList={planUsageTabElements} selectedTabId={selectedTabId} />
			<Outlet />
			<PaymentFormModal />
		</div>
	);
}

const containerSt = css`
	width: 960px;

	padding: 32px 24px 80px 24px;

	margin: 0 auto;
`;

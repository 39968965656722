import { useMemo } from 'react';
import type { ApexOptions } from 'apexcharts';
import { paletteSDS } from 'surf-design-system';
import { formatCompactNumber, formatLocaleString } from 'features/nfEUM/utils/chartFunc';

export interface ColumnChartData {
	x: string;
	y: number;
}

const { gray } = paletteSDS;

export interface OptionsParameter {
	maxY: number;
	annotation: ColumnChartData;
}

export interface ApexChartSeries {
	data: ColumnChartData[];
}

export interface CustomTooltipProps {
	seriesIndex: number;
	dataPointIndex: number;
	w: {
		globals: {
			seriesNames: string;
			colors: string;
			initialSeries: Array<ApexChartSeries>;
		};
	};
	series: Array<ColumnChartData[]>;
}

export default function useTXNColumnChartOptions({ maxY, annotation }: OptionsParameter) {
	const options: ApexOptions = useMemo(
		() => ({
			chart: {
				id: 'column',
				stacked: true,
				animations: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			annotations: {
				points: [
					{
						marker: {
							size: 0,
						},
						x: annotation.x,
						y: annotation.y,
						label: {
							text: annotation.y === 0 ? '' : `High ${formatLocaleString(annotation.y)}`,
							borderColor: 'none',
							style: {
								background: 'none',
								fontWeight: 600,
								fontSize: '10',
								color: '#000',
							},
						},
					},
				],
			},
			plotOptions: {
				bar: {
					columnWidth: '25%',
				},
			},
			xaxis: {
				type: 'category',
				axisTicks: { show: false },
				axisBorder: { show: false },
				tickPlacement: 'between',
				tickAmount: 24,
				range: 23,
				labels: {
					show: true,
					rotate: 0,
					rotateAlways: false,
					trim: true,
					// eslint-disable-next-line
					style: {
						colors: `${gray[600]}`,
						fontWeight: '400',
						fontSize: '10',
						cssClass: 'apexcharts-xaxis-label',
					},
					hideOverlappingLabels: true,
				},
				tooltip: { enabled: false },
			},
			yaxis: {
				decimalsInFloat: 0,
				forceNiceScale: true,
				tickAmount: 3,
				labels: {
					formatter(value: number) {
						const result = formatCompactNumber(value);
						return result;
					},
					style: { colors: `${gray[700]}`, fontWeight: '400', fontSize: '10' },
					offsetY: 5,
					offsetX: -8,
				},
				max: maxY,
				min: 0,
			},
			grid: {
				show: true,
				borderColor: `${gray[100]}`,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
			},
			tooltip: {
				enabled: true,
				followCursor: true,
				custom({ series, seriesIndex, dataPointIndex, w }) {
					const tooltip = `
						<div class="box" style="
						box-sizing: border-box;
						padding: 4px 8px;
						color: ${gray[900]};
						line-height: 160%;
						font-weight: 400;
						font-size: 12px;">
							<div class="loadWeb">
								<div class="dot" style="
								display: inline-block;
								margin-right: 4px;
								border-radius: 50%;
								width: 7.5px;
								height: 7.5px;
								background-color: ${w.globals.seriesColors[seriesIndex]}
								"></div>
								<span class="data" style="
								font-size: 10px;
								font-weight: 600; ">
								${formatLocaleString(series[seriesIndex][dataPointIndex])}
								</span>
							</div>
						</div>
					`;
					return tooltip;
				},
			},
			legend: {
				show: false,
			},
			fill: {
				opacity: 1,
			},
			dataLabels: {
				enabled: false,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxY, annotation]
	);

	return {
		apexLineOption: options,
	};
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';

export default function OnPremGNBNetFunnelHomeTab() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { language } = useSelector((state: RootState) => state.account);

	const isHomeTabActivated = pathname.includes('/on_prem/console/product/nf/home');

	const homeTabClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/console/product/nf/home`);
	};

	return (
		<div css={container} onClick={homeTabClickHandler} aria-hidden>
			<div css={labelSt(isHomeTabActivated)}>{t('console.sidebar.home')}</div>
			<div css={borderBottomSt(isHomeTabActivated)} />
		</div>
	);
}

const container = css`
	position: relative;

	display: flex;
	align-items: center;

	width: fit-content;
	height: 100%;

	padding: 22px 24px;

	cursor: pointer;

	:hover {
		background: ${paletteSDS.gray['100']};
	}
`;

const labelSt = (isHomeTabActivated: boolean) => css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};
	color: ${isHomeTabActivated ? paletteSDS.blue['700'] : paletteSDS.gray['700']};

	line-height: 140%;
`;

const borderBottomSt = (isHomeTabActivated: boolean) => css`
	position: absolute;

	left: 0;
	bottom: 0;

	width: 100%;
	height: ${isHomeTabActivated ? '6px' : '0px'};

	background: ${paletteSDS.blue['700']};
`;

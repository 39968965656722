/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// etc
import { backgroundPalette, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

import { PLAN_ENTERPRISE_ID, PLAN_LARGE_ID, PLAN_MEDIUM_ID } from 'common/data/constants';
import { RootState } from 'common/redux/store';

import { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';

import { PlanInfo } from '../types/getPlanList.type';
import { addNumberComma } from '../utils/formatFunc';
import PlanEnterpriseOption from './PlanEnterpriseOption';
/**
 * author: Jay
 * createdAt: 2022-08-08
 * updatedAt: 2022-08-08
 * description: 플랜 옵션 상세 항목 컴포넌트
 * todo:
 */
interface PlanOptionValueProps {
	plan: PlanInfo;
}
export default function PlanOptionValue({ plan }: PlanOptionValueProps) {
	const { t } = useTranslation();

	const { language } = useSelector((state: RootState) => state.account);

	const planLangs = plan.planLangs.find(el => el.langType === language) as PlanLangs;

	if (plan.planId === PLAN_ENTERPRISE_ID) {
		return <PlanEnterpriseOption />;
	}

	let planColor = '#13a1f6';

	if (plan.planId === PLAN_MEDIUM_ID) {
		planColor = '#4a70ff';
	}

	if (plan.planId === PLAN_LARGE_ID) {
		planColor = '#4046ca';
	}

	const planColorCss = css`
		color: ${planColor};
	`;

	const bigBoxCss = [
		bigBoxSt,
		colorPalette.black,
		backgroundPalette.gray[50],
		themeCss.fontSize[14],
		themeCss.fontWeight.bold,
	];

	const smallBoxCss = [
		smallBoxSt,
		colorPalette.black,
		backgroundPalette.gray[50],
		themeCss.fontSize[14],
		themeCss.fontWeight.bold,
	];

	const memberBoxCss = [
		memberBoxSt,
		colorPalette.black,
		backgroundPalette.gray[50],
		themeCss.fontSize[14],
		themeCss.fontWeight.bold,
	];

	return (
		<div css={container}>
			<div css={[planColorCss, titleBoxSt, backgroundPalette.white, themeCss.fontSize[16], themeCss.fontWeight.bold]}>
				{planLangs.name}
			</div>
			<div css={bigBoxCss}>{addNumberComma(plan.planOptions[0].value.toString())}</div>
			<div css={bigBoxCss}>{addNumberComma(plan.planOptions[1].value.toString())}</div>
			<div css={bigBoxCss}>{addNumberComma(plan.planOptions[2].value.toString())}</div>
			<div css={smallBoxCss} />
			<div css={memberBoxCss}>{plan.planOptions[3].value}</div>
			<div css={memberBoxCss}>1</div>
			<div css={smallBoxCss} />
			<div css={memberBoxCss}>{plan.planOptions[4].value}</div>
			<div css={memberBoxCss}>{plan.planOptions[5].value}</div>
			<div css={memberBoxCss}>{plan.planOptions[6].value}</div>
			<div css={bigBoxCss}>{t('planSubscription.free')}</div>
		</div>
	);
}

const titleBoxSt = css`
	width: 100%;
	height: 48px;

	line-height: 48px;
	text-align: center;
`;

const bigBoxSt = css`
	width: 100%;
	height: 64px;

	text-align: center;
	line-height: 64px;

	border-top: 1px solid ${paletteSDS.gray[300]};
`;

const smallBoxSt = css`
	width: 100%;
	height: 32px;

	text-align: center;
	line-height: 32px;

	border-top: 1px solid ${paletteSDS.gray[300]};
`;

const memberBoxSt = css`
	width: 100%;
	height: 32px;

	text-align: center;
	line-height: 32px;
`;

const container = css`
	width: 100%;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import Avatar from 'common/components/Avatar';
import type { userRoleType, userStatusType } from 'common/types/getOrganizationList.type';
import { RootState } from 'common/redux/store';
import { DEFAULT_IMG_URL } from 'common/data/constants';

import ChangePermissionModal from './ChangeMemberPermissionModal';
import { MemberData } from '../types/getMemberList.type';

const { fontWeight, fontSize } = themeSDS;
const { gray, blue, green } = paletteSDS;

interface MemberCardProps {
	member: MemberData;
}

/**
 * author: Root
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-16(Jay)
 * description: 조직 멤버 개별 상태 렌더링(이름, 이메일, role, status, 상태 변경 모달 활성화-OWNER)
 * todo:
 */
export default function MemberCard({ member }: MemberCardProps) {
	const { t } = useTranslation();
	const { userRole } = useSelector((state: RootState) => state.account);

	const { isLoaded } = useSelector((state: RootState) => state.plan);

	const { role, state, email, imageUrl, name } = member;

	const [isChangePermissionModalVisible, setChangePermissionModalVisible] = useState(false);

	const actionButtonClickHandler = () => {
		setChangePermissionModalVisible(prev => !prev);
	};

	let memberRoleTextKey = t('commonWords.roleOwner');
	let memberStatusTextKey = t('organizationSettings.active');

	if (role === 'ADMINISTRATOR') {
		memberRoleTextKey = t('commonWords.roleAdmin');
	}

	if (role === 'OPERATOR') {
		memberRoleTextKey = t('commonWords.roleOperator');
	}

	if (role === 'MONITOR') {
		memberRoleTextKey = t('commonWords.roleMonitor');
	}

	if (state === 'DISABLED') {
		memberStatusTextKey = t('organizationSettings.disable');
	}

	let actionButton: React.ReactNode = <div css={actionCss(role)}>-</div>;

	if (userRole === 'OWNER' && role !== 'OWNER') {
		actionButton = (
			<div css={actionCss(role)} onClick={actionButtonClickHandler} aria-hidden>
				{t('organizationSettings.edit')}
			</div>
		);
	}

	if (userRole !== 'OWNER') {
		actionButton = null;
	}

	return (
		<li css={memberCardCss}>
			<div css={stateIndicatorCss(state)} />
			<div css={contentCss}>
				<div css={abatarCss}>
					<div css={pictureCss(state)}>
						<Avatar
							imgUrl={imageUrl === DEFAULT_IMG_URL ? undefined : `${imageUrl}?${Date.now()}`}
							size="medium"
							border={`1px solid ${gray[50]}`}
							placeholder={email[0].toUpperCase()}
							disable={state === 'DISABLED'}
						/>
					</div>
					<div css={userNameCss(state)}>{name}</div>
				</div>
				<div css={longTextCss(state)}>{email}</div>
				<div css={shortTextCss(state)}>{memberRoleTextKey}</div>
				<div css={shortTextCss(state)}>{memberStatusTextKey}</div>
				{actionButton}
			</div>
			{isChangePermissionModalVisible && isLoaded ? (
				<ChangePermissionModal
					visible={isChangePermissionModalVisible}
					memberData={member}
					toggleShowModal={actionButtonClickHandler}
				/>
			) : null}
		</li>
	);
}

const memberCardCss = css`
	display: flex;
	width: 100%;
	border-top: 1px solid ${gray[300]};
	border-bottom: 1px solid ${gray[300]};
`;

const stateIndicatorCss = (status: userStatusType) => css`
	width: 0.25rem;
	height: 4.5rem;
	background-color: ${status === 'ACTIVE' ? green[500] : gray[500]};
`;

const contentCss = css`
	display: flex;
	align-items: center;
	flex-basis: 1;
	padding: 0.75rem 1rem;
	width: 100%;
`;

const abatarCss = css`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 27%;
	min-width: 12.5rem;
`;

const pictureCss = (status: userStatusType) => css`
	opacity: ${status === 'ACTIVE' ? 1 : 0.5};
`;

const userNameCss = (status: userStatusType) => css`
	color: ${status === 'ACTIVE' ? gray[900] : gray[600]};
	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.bold};
	line-height: 100%;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const longTextCss = (status: userStatusType) => css`
	width: 27%;
	min-width: 12.5rem;
	color: ${status === 'ACTIVE' ? gray[900] : gray[600]};

	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.regular};
	line-height: 100%;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const shortTextCss = (status: userStatusType) => css`
	width: 27%;
	min-width: 5rem;
	color: ${status === 'ACTIVE' ? gray[900] : gray[600]};

	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.regular};
	line-height: 100%;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const actionCss = (role: userRoleType) => css`
	width: 9%;
	min-width: 5rem;
	color: ${blue[700]};
	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;
	${role !== 'OWNER' && `cursor: pointer`};
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';

import { paletteSDS } from 'surf-design-system';

import FunnelProjectValue from 'features/nfBasic/components/FunnelProjectValue';

interface FunnelTextProjectProps {
	value: number;
	disabled?: boolean;
	tooltipText?: string;
	decimal?: number;
	name: string[];
	tooltipName?: string[];
	flexJustify?: string;
	textColor?: string;
	unit?: string;
	top?: number;
	left?: number;
	right?: number;
}

function FunnelTextProject({
	value,
	disabled = false,
	decimal = 0,
	name = [''],
	tooltipName = [],
	textColor = paletteSDS.systemColor.black,
	flexJustify = 'flex-start',
	unit = '',
	tooltipText = '',
	top = 0,
	left,
	right,
}: FunnelTextProjectProps) {
	const [isHover, setIsHover] = useState<boolean>(false);
	const nameStr = tooltipName && tooltipName.length > 0 ? tooltipName.join(' ') : name.join(' ');

	const textMouseEnterHandler = () => {
		setIsHover(() => true);
	};

	const textMouseLeaveHandler = () => {
		setIsHover(() => false);
	};

	return (
		<div
			css={FunnelTextProjectCss(top, left, right, isHover, textColor, flexJustify)}
			onMouseEnter={textMouseEnterHandler}
			onMouseLeave={textMouseLeaveHandler}
		>
			{name.map(word => (
				<div key={word} css={nameCss(disabled)}>
					{word}
				</div>
			))}
			{!disabled && (
				<FunnelProjectValue
					value={value}
					decimal={decimal}
					isHover={isHover}
					nameStr={nameStr}
					tooltipText={tooltipText}
					unit={unit}
					textColor={textColor}
				/>
			)}
		</div>
	);
}

export default React.memo(FunnelTextProject);

const FunnelTextProjectCss = (
	top: number,
	left: number | undefined,
	right: number | undefined,
	isHover: boolean,
	textColor: string,
	flexJustify: string
) => css`
	position: absolute;
	top: ${top}px;
	left: ${left}px;
	right: ${right}px;
	display: flex;
	flex-direction: column;
	align-items: ${flexJustify};
	justify-content: center;
	width: fit-content;
	z-index: 1001;

	& > span {
		color: ${isHover ? paletteSDS.systemColor.white : textColor};
	}
`;

const nameCss = (disabled: boolean) => css`
	color: ${disabled ? paletteSDS.gray[300] : paletteSDS.gray[600]};
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.32px;
	white-space: nowrap;
	word-break: keep-all;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

import { Dropdown, Modal, Portal, paletteSDS, themeSDS, Textinput } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsInviteMemberModalVisible } from 'common/context/modalSlice';
import Astrix from 'common/components/Astrix';
import { toggleIsInviteAllFailToastVisible, toggleIsInviteAllSuccessToastVisible } from 'common/context/toastSlice';

import PermissionDescriptionTextBox from 'features/setting/components/PermissionDescriptionTextBox';
import { getAllMemberListThunk } from 'features/setting/thunk/getAllMemberList.thunk';
import {
	initialInviteMemberForm,
	inviteMemberFormReducer,
	UPDATE_MEMBERS,
	UPDATE_ROLE,
	VALIDATE_MEMBERS,
	UPDATE_MEMBERS_HELP_TEXT,
} from '../context/inviteMemberForm.reducer';

import { postMemberListThunk } from '../thunk/postMemberList.thunk';

const { fontSize, fontWeight } = themeSDS;

export default function InviteMemberModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { allMembers, isLoading } = useSelector((state: RootState) => state.member);
	const { isInviteMemberModalVisible } = useSelector((state: RootState) => state.modal);
	const { planOptions, isLoaded, priceType } = useSelector((state: RootState) => state.plan);

	const memberRoleCount = {
		ADMINISTRATOR: allMembers.filter(e => e.role === 'ADMINISTRATOR' && e.state === 'ACTIVE').length,
		OPERATOR: allMembers.filter(e => e.role === 'OPERATOR' && e.state === 'ACTIVE').length,
		MONITOR: allMembers.filter(e => e.role === 'MONITOR' && e.state === 'ACTIVE').length,
	};

	const roleDropDownMenu =
		priceType === 'BYOL'
			? [
					{ name: t('commonWords.roleAdmin'), value: 'ADMINISTRATOR' },
					{ name: t('commonWords.roleOperator'), value: 'OPERATOR' },
			  ]
			: [
					{ name: t('commonWords.roleAdmin'), value: 'ADMINISTRATOR' },
					{ name: t('commonWords.roleOperator'), value: 'OPERATOR' },
					{ name: t('commonWords.roleMonitor'), value: 'MONITOR' },
			  ];

	const [inviteMemberFormData, setInviteMemberFormData] = useReducer(inviteMemberFormReducer, {
		...initialInviteMemberForm,
		memberNum: {
			...initialInviteMemberForm.memberNum,
			ADMINISTRATOR: memberRoleCount.ADMINISTRATOR,
			OPERATOR: memberRoleCount.OPERATOR,
			MONITOR: memberRoleCount.MONITOR,
		},
		maxMemberNum: {
			...initialInviteMemberForm.maxMemberNum,
			ADMINISTRATOR: planOptions[4].value,
			OPERATOR: planOptions[5].value,
			MONITOR: planOptions[6].value,
		},
	});

	const { members, isMembersChanged, isMembersValid, membersHelpText, membersValidation, role, membersList } =
		inviteMemberFormData;

	const modalVisibleChangeHandler = () => {
		dispatch(toggleIsInviteMemberModalVisible());
	};

	const handleClickInviteButton = async () => {
		const postAction = await dispatch(postMemberListThunk({ email: membersList.slice(), role }));

		const { fail } = unwrapResult(postAction);

		if (fail.length === 0) {
			dispatch(toggleIsInviteAllSuccessToastVisible());
			dispatch(toggleIsInviteMemberModalVisible());
			dispatch(getAllMemberListThunk());
			return;
		}

		if (fail.reduce((prev, curr) => curr.errorType === 'USER_ORGANIZATION_ALREADY_EXIST' || prev, false)) {
			setInviteMemberFormData({ type: UPDATE_MEMBERS_HELP_TEXT, payload: 'organizationSettings.errorInvitationMsg4' });
			return;
		}

		dispatch(toggleIsInviteMemberModalVisible());
		dispatch(toggleIsInviteAllFailToastVisible());
	};

	const membersChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInviteMemberFormData({ type: UPDATE_MEMBERS, payload: e.target.value });
	};

	const membersBlurHandler = () => {
		setInviteMemberFormData({ type: VALIDATE_MEMBERS });
	};

	const roleChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setInviteMemberFormData({ type: UPDATE_ROLE, payload: e.target.value });
	};

	if (!isLoaded) return null;

	return (
		<Portal>
			<Modal
				title={t('modal.invitMem')}
				onClickCloseButton={modalVisibleChangeHandler}
				visible={isInviteMemberModalVisible}
				actionType="double"
				primaryBtnLabel={t('modal.invite')}
				primaryBtnFunction={handleClickInviteButton}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={modalVisibleChangeHandler}
				isCloseIconVisible={false}
				primaryBtnDisable={!isMembersValid || isLoading || role === 'NONE'}
			>
				<div css={modalBodyCss}>
					<div css={guideTextCss}>{t('modal.inviteMembersMsg')}</div>
					<Textinput
						value={members}
						label={t('modal.emails')}
						helpText={isMembersChanged && !isMembersValid && t(membersHelpText)}
						validation={isMembersChanged ? membersValidation : 'none'}
						onChange={membersChangeHandler}
						onBlur={membersBlurHandler}
					/>
					{/* <Textarea */}
					{/* 	value={members} */}
					{/* 	label={t('modal.emails')} */}
					{/* 	height="8rem" */}
					{/* 	placeholder={t('modal.emailsMsg')} */}
					{/* 	helpText={isMembersChanged && !isMembersValid && t(membersHelpText)} */}
					{/* 	validation={isMembersChanged ? membersValidation : 'none'} */}
					{/* 	onChange={membersChangeHandler} */}
					{/* 	onBlur={membersBlurHandler} */}
					{/* /> */}
					<Dropdown
						name="role"
						menu={roleDropDownMenu}
						label={
							<span>
								<Astrix />
								{t('commonWords.role')}
							</span>
						}
						width="100%"
						bindedValue={role}
						onChange={roleChangeHandler}
						placeholder={t('modal.selectRolePlaceholder')}
					/>
					<PermissionDescriptionTextBox role={role} />
				</div>
			</Modal>
		</Portal>
	);
}

const guideTextCss = css`
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.regular};
	line-height: 160%;
	color: ${paletteSDS.gray[900]};
`;

const modalBodyCss = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

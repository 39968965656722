import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getLicenseDetailApi from 'features/license/api/getLicenseDetail.api';

import type { LicenseData } from 'features/license/types/getLicenseList.type';

interface GetLicenseDetailThunkProps {
	licenseId: number;
}

export const getLicenseDetailThunk = createAsyncThunk<LicenseData, GetLicenseDetailThunkProps, { state: RootState }>(
	'license/detail/get',
	async (args, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
		} = thunkAPI.getState();

		const { licenseId } = args;

		try {
			const { data } = await getLicenseDetailApi({
				tenantId: tenantId || '',
				organizationId,
				projectId: licenseId,
			});

			return {
				...data,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

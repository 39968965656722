/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useMemo, useReducer, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Textinput } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';
import { decrypt } from 'common/utils/cryptoFunc';
import { toggleIsChangePasswordModalVisible } from 'common/context/modalSlice';
import { getI18nPath } from 'common/utils/languageFunc';
import PasswordType from 'common/components/PasswordType';

import { MAX_PASSWORD_CHAR_NUM } from 'features/signup/data/constants';

import {
	UPDATE_PW_HELP_TEXT,
	UPDATE_PASSWORD,
	UPDATE_VERIFY_PASSWORD,
	VALIDATE_PASSWORD,
	VALIDATE_VERIFY_PASSWORD,
	changePasswordFormReducer,
	initialChangePasswordForm,
} from '../context/changePasswordForm.reducer';

import { cognitoChangePassword } from '../api/cognitoChangePassword';

export interface ChangePasswordState {
	a: boolean;
	b: string;
	c: string;
}

export default function ChangePasswordForm() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { state } = location;
	const [searchParams] = useSearchParams();

	const userEmail = useMemo(() => searchParams.get('email'), [searchParams]);
	const userCode = useMemo(() => searchParams.get('code'), [searchParams]);

	const changePasswordUserState = useMemo(() => {
		if (state) {
			const changePasswordState = state as ChangePasswordState;

			return {
				isChangePasswordRequired: changePasswordState.a,
				userName: changePasswordState.b,
				password: decrypt(changePasswordState.c),
			};
		}
		return {
			isChangePasswordRequired: false,
			userName: '',
			password: '',
		};
	}, [state]);

	const [changePasswordFormData, setChangePasswordFormData] = useReducer(
		changePasswordFormReducer,
		initialChangePasswordForm
	);
	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
	const [isLoading, setIsLoading] = useState(false);

	const {
		password,
		isPasswordChanged,
		isPasswordValid,
		isVerifyPasswordChanged,
		isVerifyPasswordValid,
		verifyPassword,
		verifyPasswordHelpText,
		verifyPasswordValidation,
		passwordHelpText,
		passwordValidation,
	} = changePasswordFormData;

	const changePasswordFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault();

		setIsLoading(true);

		cognitoChangePassword({
			isNeedChangePw: changePasswordUserState.isChangePasswordRequired,
			username: changePasswordUserState.userName,
			challengeCode: changePasswordUserState.password,
			password,
			receiveEmail: userEmail,
			code: userCode,
			onSuccess: () => {
				setIsLoading(false);
				dispatch(toggleIsChangePasswordModalVisible());
			},
			onFailure: err => {
				setIsLoading(false);
				setChangePasswordFormData({ type: UPDATE_PW_HELP_TEXT, payload: err.message as string });
			},
			onSuccessAuth: () => {
				setIsLoading(false);
				navigate(`/${getI18nPath()}/signin`);
			},
			onFailureAuth(err) {
				setIsLoading(false);
				setChangePasswordFormData({ type: UPDATE_PW_HELP_TEXT, payload: err.message as string });
			},
			onSuccessNeedChangePw() {
				setIsLoading(false);
				dispatch(toggleIsChangePasswordModalVisible());
			},
			onFailureNeedChangePw(err) {
				setIsLoading(false);
				setChangePasswordFormData({ type: UPDATE_PW_HELP_TEXT, payload: err.message as string });
			},
		});
	};

	const inputLabelClickHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
		e.stopPropagation();

		setPasswordType(prevType => {
			if (prevType === 'password') {
				return 'text';
			}
			return 'password';
		});
	};

	const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_PASSWORD, payload: e.target.value });
	};

	const verifyPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_VERIFY_PASSWORD, payload: e.target.value });
	};

	const passwordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_PASSWORD });
	};

	const verifyPasswordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_VERIFY_PASSWORD });
	};

	return (
		<form css={formSt} onSubmit={changePasswordFormSubmitHandler}>
			<div css={buttonWrap}>
				<Textinput
					label={<PasswordType passwordType={passwordType} onClick={inputLabelClickHandler} />}
					valueType={passwordType}
					value={password}
					validation={isPasswordChanged ? passwordValidation : 'none'}
					helpText={isPasswordChanged && !isPasswordValid && t(passwordHelpText)}
					onChange={passwordChangeHandler}
					onBlur={passwordBlurHandler}
					maxLength={MAX_PASSWORD_CHAR_NUM}
					showIcon
				/>
				<Textinput
					valueType="password"
					label={t('resetPassword.verifyPwd')}
					value={verifyPassword}
					validation={isVerifyPasswordChanged ? verifyPasswordValidation : 'none'}
					helpText={isVerifyPasswordChanged && !isVerifyPasswordValid && t(verifyPasswordHelpText)}
					onChange={verifyPasswordChangeHandler}
					onBlur={verifyPasswordBlurHandler}
					showIcon
				/>
			</div>
			<Button width="100%" type="submit" disabled={!isPasswordValid || !isVerifyPasswordValid || isLoading}>
				{t('resetPassword.resetPwd')}
			</Button>
		</form>
	);
}

const formSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

const buttonWrap = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

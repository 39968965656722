import { createAsyncThunk } from '@reduxjs/toolkit';

import putNotificationReadYnListApi from 'common/api/putNotificationReadYnList.api';
import type { RootState } from 'common/redux/store';

export interface PutNotificationReadYnListThunkProps {
	notificationIds: number[];
}

export interface PutNotificationReadYnListThunkResult {
	notificationIds: number[];
}

export const putNotificationReadYnListThunk = createAsyncThunk<
	PutNotificationReadYnListThunkResult,
	PutNotificationReadYnListThunkProps,
	{ state: RootState }
>('noti/put/read-all', async (args: PutNotificationReadYnListThunkProps, thunkAPI) => {
	const { notificationIds } = args;

	const {
		account: { organizationId, language },
	} = thunkAPI.getState();

	try {
		await putNotificationReadYnListApi({ organizationId, language, notificationIds });

		return { notificationIds };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ChevronLeftIcon, paletteSDS, themeSDS } from 'surf-design-system';

import ButtonGroup, { ButtonGroupElement } from 'common/components/ButtonGroup';
import { RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';

export default function LicenseDetailLayout() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const { language } = useSelector((state: RootState) => state.account);

	const [selectedPath, setSelectedPath] = useState<string>('');

	const agentButtons: ButtonGroupElement[] = [
		{
			id: 'detail',
			onClick: () => {
				setSelectedPath('detail');
				navigate('detail');
			},
			label: t('onPrem.layout.license'),
		},
		{
			id: 'usage',
			onClick: () => {
				setSelectedPath('usage');
				navigate('usage');
			},
			label: t('onPrem.layout.licenseUsage'),
		},
	];

	const listClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/home/usage_license/license`);
	};

	useEffect(() => {
		if (pathname.includes('detail')) {
			setSelectedPath('detail');
		} else {
			setSelectedPath('usage');
		}
	}, [pathname]);

	return (
		<div css={container}>
			<div css={titleSt}>{t('onPrem.layout.licenseDetail')}</div>
			<div css={headerBox}>
				<ButtonGroup selectedId={selectedPath} buttonGroup={agentButtons} />
				<Button
					icon={<ChevronLeftIcon fill={paletteSDS.blue['700']} />}
					mould="secondary"
					iconPosition="left"
					size="small"
					onClick={listClickHandler}
				>
					{t('onPrem.license.list')}
				</Button>
			</div>
			<Outlet />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 100%;
`;

const headerBox = css`
	display: flex;
	justify-content: space-between;

	height: 34px;
`;

const titleSt = css`
	height: 100%;

	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize[24]};
	line-height: 128%;
	color: ${paletteSDS.gray['900']};
`;

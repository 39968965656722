import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { PutBasicControlSegmentGraphInfoApiResponse } from 'features/nfControl/type/putBasicControlSegmentGraphInfo.v2.type';

interface PutBasicControlSegmentGraphInfoApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
	segmentGraphYn: boolean;
	segmentId: number;
}

export default async function putBasicControlSegmentGraphInfoApi({
	tenantId,
	segmentGraphYn,
	organizationId,
	projectId,
	segmentId,
}: PutBasicControlSegmentGraphInfoApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.put<PutBasicControlSegmentGraphInfoApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/segment/${segmentId}/info/graph`,
			{ segmentGraphYn },
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';

import { GetLicenseProjectUsageApiResponse } from 'features/license/types/getLicenseProjectUsage.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface GetLicenseUsageApiProps {
	organizationId: number;
	tenantId: string;
	projectId: number;
	searchStartDate: number;
	searchEndDate: number;
	pageNum: number;
}

export default async function getLicenseUsageApi({
	tenantId,
	organizationId,
	projectId,
	pageNum,
	searchEndDate,
	searchStartDate,
}: GetLicenseUsageApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetLicenseProjectUsageApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/license/project/${projectId}/usage`,
			{
				params: {
					pageNum,
					pageSize: 10,
					searchEndDate,
					searchStartDate,
				},
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

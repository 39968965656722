/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { createRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { paletteSDS, NotificationsIcon } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Avatar from 'common/components/Avatar';
import ColorCircle from 'common/components/ColorCircle';
import SurffyProductTitle from 'common/components/SurffyProductTitle';
import { toggleHeaderNotiOpen } from 'common/context/notificationSlice';
import { SurffyLogoDark } from 'common/assets/icons';
import { toggleIsOnPremProjectMenuVisible, toggleIsProfileMenuVisible } from 'common/context/layoutSlice';
import { DEFAULT_IMG_URL, LANG_PATH } from 'common/data/constants';
import useOutboundClick from 'common/hooks/useOutboundClick';
import OnPremHeaderNotification from 'common/components/OnPremHeaderNotification';
import OnPremProfileMenu from 'common/components/OnPremProfileMenu';
import OnPremServerHealth from 'common/components/OnPremServerHealth';
import OnPremProjectMenu from 'common/components/OnPremProjectMenu';
import OnPremGNBNetFunnelHomeTab from 'common/components/OnPremGNBNetFunnelHomeTab';
import OnPremGNBNetFunnelProjectTab from 'common/components/OnPremGNBNetFunnelProjectTab';

export default function OnPremGNB() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const { language, imageUrl, userId } = useSelector((state: RootState) => state.account);

	const { isAllRead, isHeaderNotiOpened } = useSelector((state: RootState) => state.noti);

	const notificationsIconRef = createRef<SVGSVGElement>();
	const avatarRef = createRef<HTMLImageElement>();
	const headerNotificationRef = createRef<HTMLDivElement>();
	const profileMenuRef = createRef<HTMLDivElement>();
	const projectTabRef = createRef<HTMLDivElement>();
	const projectMenuRef = createRef<HTMLDivElement>();

	const surffyLogoClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/home`);
	};

	const notificationsIconClickHandler = () => {
		dispatch(toggleHeaderNotiOpen());
	};

	const avatarClickHandler = () => {
		dispatch(toggleIsProfileMenuVisible());
	};

	const projectTabClickHandler = () => {
		dispatch(toggleIsOnPremProjectMenuVisible());
	};

	useOutboundClick(headerNotificationRef, notificationsIconClickHandler, [notificationsIconRef]);

	useOutboundClick(profileMenuRef, avatarClickHandler, [avatarRef]);

	useOutboundClick(projectMenuRef, projectTabClickHandler, [projectTabRef]);

	return (
		<React.Fragment>
			<div css={container}>
				<div css={leftBox}>
					<div css={surffyIconBox}>
						<SurffyLogoDark onClick={surffyLogoClickHandler} css={logoSt} />
						<SurffyProductTitle />
					</div>
					{pathname.includes('/product/nf') && (
						<div css={tabBox}>
							<OnPremGNBNetFunnelHomeTab />
							<OnPremGNBNetFunnelProjectTab ref={projectTabRef} />
						</div>
					)}
				</div>
				<div css={rightBox}>
					{/* {pathname.includes('/product/nf') && <OnPremServerHealth />} */}
					<div css={iconBox(isHeaderNotiOpened)}>
						<NotificationsIcon
							fill={!isAllRead ? paletteSDS.blue['700'] : paletteSDS.gray['300']}
							onClick={notificationsIconClickHandler}
							ref={notificationsIconRef}
						/>
						{!isAllRead ? <ColorCircle emotionCss={colorCircleCss} /> : null}
					</div>
					<div css={avatarBox}>
						<Avatar
							imgUrl={imageUrl === DEFAULT_IMG_URL ? undefined : imageUrl}
							placeholder={userId.charAt(0).toUpperCase()}
							onClick={avatarClickHandler}
							emotionCss={css`
								object-position: 50%;
								cursor: pointer;
							`}
							ref={avatarRef}
						/>
					</div>
				</div>
			</div>
			<OnPremHeaderNotification ref={headerNotificationRef} />
			<OnPremProfileMenu ref={profileMenuRef} />
			<OnPremProjectMenu ref={projectMenuRef} />
		</React.Fragment>
	);
}

const container = css`
	position: relative;

	flex: none;

	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 64px;

	background: ${paletteSDS.gray['50']};

	border-bottom: 1px solid ${paletteSDS.gray['300']};

	overflow: unset;
`;

const surffyIconBox = css`
	display: flex;
	align-items: center;

	gap: 8px;

	width: 244px;
	height: 40px;

	padding: 8px 24px;
`;

const iconBox = (isActivated: boolean) => css`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 40px;
	height: 40px;

	margin-left: 8px;

	border-radius: 50%;

	background: ${isActivated && paletteSDS.blue['100']};

	:hover {
		background: ${paletteSDS.blue['100']};
	}
`;

const avatarBox = css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 64px;
`;

const rightBox = css`
	display: flex;
	align-items: center;
	gap: 0;
`;

const logoSt = css`
	cursor: pointer;
`;

const colorCircleCss = css`
	position: absolute;
	top: 10px;
	left: 20px;
`;

const leftBox = css`
	display: flex;
	align-items: center;
	gap: 0;

	overflow: auto;
`;

const tabBox = css`
	display: flex;
	align-items: center;
	gap: 0;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsTenantFailModalVisible } from 'common/context/modalSlice';

export default function TenantFailModal() {
	const { isTenantFailModalVisible } = useSelector((state: RootState) => state.modal);

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	/**
	 * description: 모달 닫기 제어 함수
	 */
	const onCloseModal = () => {
		dispatch(toggleIsTenantFailModalVisible());
	};

	const modalBody = (
		<React.Fragment>
			<p>{t('modal.tenantFail')}</p>
			<p>{t('modal.notAvailableUseSurffy')}</p>
		</React.Fragment>
	);

	const modalTitle = t('modal.pendingNotiTitle');

	return (
		<Portal>
			<Modal
				visible={isTenantFailModalVisible}
				title={modalTitle}
				actionType="single"
				primaryBtnLabel={t('modal.ok')}
				primaryBtnFunction={onCloseModal}
				onClickOverlay={onCloseModal}
				onClickCloseButton={onCloseModal}
			>
				<div css={textSt}>{modalBody}</div>
			</Modal>
		</Portal>
	);
}

const textSt = css`
	line-height: 1.375rem;
	font-size: ${themeSDS.fontSize[14]};
`;

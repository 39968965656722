import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import { getVWRNameValidateApi } from 'features/vwr/api/getVWRNameValidate.v2.api';

interface GetVWRNameValidateThunkProps {
	enteredName: string;
	mode: 'create' | 'edit';
}

interface GetVWRNameValidateThunkResult {
	isValidate: boolean;
	name: string;
	mode: 'create' | 'edit';
}

export const getVWRNameValidateThunk = createAsyncThunk<
	GetVWRNameValidateThunkResult,
	GetVWRNameValidateThunkProps,
	{ state: RootState }
>('vwr/get/name/validate', async (args, thunkAPI) => {
	const { enteredName, mode } = args;

	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const {
			data: { isValidate },
		} = await getVWRNameValidateApi({
			tenantId: tenantId as string,
			projectId: selectedProjectId,
			organizationId,
			name: enteredName,
		});

		return { mode, name: enteredName, isValidate };
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

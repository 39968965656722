import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import { toggleIsVWRPutConfirmModalVisible } from 'common/context/modalSlice';
import { toggleIsVWRUpdatedToastVisible } from 'common/context/toastSlice';

import { defaultLogoImageBase64 } from '../data/defaultImageBase64';
import { putVWRDataApi } from '../api/putVWRData.api';
import { convertUrlToImgFile } from '../utils/fileFunc';
import type { VWRElement } from '../context/vwrSlice';

export const putVWRDataThunk = createAsyncThunk<VWRElement, void, { state: RootState }>(
	'vwr/put',
	async (_, thunkAPI) => {
		const {
			account: { tenantId, tenantUrl, organizationId, language },
			prjMeta: { selectedProjectId },
		} = thunkAPI.getState();

		const {
			isFollowingWaitYnChanged,
			isGuideTextChanged,
			isImagePathChanged,
			isLogoImagePathChanged,
			isTitleChanged,
			isWaitCancelYnChanged,
			logoImagePathName,
			logoImagePathSrc,
			title,
			followingWaitYn,
			guideText,
			imagePathName,
			imagePathSrc,
			waitCancelYn,
			waitTimeDisplayYn,
			isWaitTimeDisplayYnChanged,
		} = thunkAPI.getState().vwr.form;

		const followingWaitYnData = isFollowingWaitYnChanged ? followingWaitYn : undefined;
		const guideTextData = isGuideTextChanged ? guideText : undefined;
		const imagePathData = isImagePathChanged ? await convertUrlToImgFile(imagePathSrc, imagePathName) : undefined;
		const logoImagePathData = isLogoImagePathChanged
			? await convertUrlToImgFile(logoImagePathSrc, logoImagePathName)
			: undefined;
		const titleData = isTitleChanged ? title : undefined;
		const waitCancelYnData = isWaitCancelYnChanged ? waitCancelYn : undefined;
		const waitTimeDisplayYnData = isWaitTimeDisplayYnChanged ? waitTimeDisplayYn : undefined;

		try {
			const {
				data: {
					imagePath,
					logoImagePath,
					logoImageFileName,
					imageFileName,
					title: responseTitle,
					guideText: responseGuideText,
					followingWaitYn: responseFollowingWaitYn,
					waitCancelYn: responseWaitCancelYn,
					waitTimeDisplayYn: responseWaitTimeDisplayYn,
				},
			} = await putVWRDataApi({
				logoImageFile: logoImagePathData,
				followingWaitYn: followingWaitYnData,
				guideText: guideTextData,
				waitCancelYn: waitCancelYnData,
				tenantId: tenantId as string,
				projectId: selectedProjectId,
				coverImageFile: imagePathData,
				tenantUrl,
				title: titleData,
				organizationId,
				waitTimeDisplayYn: waitTimeDisplayYnData,
			});

			thunkAPI.dispatch(toggleIsVWRPutConfirmModalVisible());
			thunkAPI.dispatch(toggleIsVWRUpdatedToastVisible());

			let editedResponseTitle = responseTitle;

			if (title === '' && language === 'ENGLISH') {
				editedResponseTitle = 'Waiting Needed for Online Access';
			}

			if (title === '' && language === 'KOREAN') {
				editedResponseTitle = '온라인 접속 대기 안내';
			}

			return {
				imagePathSrc: imagePath !== '' ? `${imagePath}?${Date.now()}` : '',
				imagePathName: imageFileName,
				logoImagePathSrc: logoImagePath !== '' ? `${logoImagePath}?${Date.now()}` : defaultLogoImageBase64,
				logoImagePathName: logoImageFileName,
				title: editedResponseTitle,
				guideText: responseGuideText,
				waitCancelYn: responseWaitCancelYn,
				followingWaitYn: responseFollowingWaitYn,
				waitTimeDisplayYn: responseWaitTimeDisplayYn,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { paletteSDS, DashboardOutlineIcon, FunnelIcon, themeSDS } from 'surf-design-system';

import { NF_CONTROLS_VIEW_MODE_KEY } from 'common/data/constants';

type ViewMode = 'default' | 'classic';

export default function NetFunnelControlViewSwitch() {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [viewMode, setViewMode] = useState<ViewMode>(pathname.includes('default') ? 'default' : 'classic');

	const defaultButtonClickHandler = () => {
		window.localStorage.setItem(NF_CONTROLS_VIEW_MODE_KEY, 'default');
		navigate('default');
	};

	const classicButtonClickHandler = () => {
		window.localStorage.setItem(NF_CONTROLS_VIEW_MODE_KEY, 'classic');
		navigate('classic');
	};

	useEffect(() => {
		if (pathname.includes('default')) {
			setViewMode('default');
		} else {
			setViewMode('classic');
		}
	}, [pathname]);

	return (
		<div css={container}>
			<div css={selectedBox(viewMode)} />
			<div css={defaultButtonBox} aria-hidden onClick={defaultButtonClickHandler}>
				<DashboardOutlineIcon fill={viewMode === 'default' ? paletteSDS.blue['700'] : paletteSDS.gray['500']} />
				<div css={buttonLabelSt(viewMode === 'default')}>Default View</div>
			</div>
			<div css={classicButtonBox} aria-hidden onClick={classicButtonClickHandler}>
				<FunnelIcon fill={viewMode === 'classic' ? paletteSDS.blue['700'] : paletteSDS.gray['500']} />
				<div css={buttonLabelSt(viewMode === 'classic')}>Classic View</div>
			</div>
		</div>
	);
}

const container = css`
	position: relative;

	display: flex;
	width: 280px;
	height: 32px;

	background: ${paletteSDS.gray['100']};
	border-radius: 40px;

	z-index: 1;
`;

const defaultButtonBox = css`
	display: flex;
	gap: 4px;
	align-items: center;

	width: 143px;
	height: 100%;

	padding: 4px 16px;

	cursor: pointer;

	z-index: 10;
`;

const classicButtonBox = css`
	display: flex;
	gap: 4px;
	align-items: center;

	width: 143px;
	height: 100%;

	padding: 4px 16px 4px 10px;

	cursor: pointer;

	z-index: 10;
`;

const selectedBox = (mode: ViewMode) => css`
	position: absolute;

	width: ${mode === 'default' ? 143 : 137}px;

	height: 32px;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['300']};

	border-radius: 40px;

	transform: ${mode === 'default' ? 'translate3d(0, 0, 0)' : 'translate3d(143px, 0, 0)'};
	transition: all 0.5s;
`;

const buttonLabelSt = (isSelected: boolean) => css`
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
	line-height: 140%;
	color: ${isSelected ? paletteSDS.blue['700'] : paletteSDS.gray['500']};

	white-space: nowrap;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import type { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';
import getSubscriptionListApi from 'features/plan/api/getSubscriptionList.v2.api';

export interface GetSubscriptionThunkResult {
	products: SubscriptionData[];
}

export const getSubscriptionListThunk = createAsyncThunk<GetSubscriptionThunkResult, void, { state: RootState }>(
	'get/subscription/list',
	async (args, thunkAPI) => {
		const {
			account: { organizationId },
		} = thunkAPI.getState();

		try {
			const { data } = await getSubscriptionListApi({
				organizationId,
			});

			return { products: data.slice() };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Loader, Modal, paletteSDS, Portal } from 'surf-design-system';
// etc

import { RootState, useAppDispatch } from 'common/redux/store';

import deletePathControlSegmentThunk from 'features/nfControl/thunk/deletePathControlSegment.v2.thunk';
import { deleteIntervalSegmentThunk } from '../thunk/deleteIntervalSegment.thunk';

/**
 * author: noah, ian
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-30
 * @params: 삭제되는 segment의 data
 */

interface SegDelModalProps {
	visible: boolean;
	onClose: () => void;
	id: number;
	segmentName: string;
	segmentOrder: number;
}

export default function IntervalSegmentDeleteModal({
	visible,
	onClose,
	id,
	segmentName,
	segmentOrder,
}: SegDelModalProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isLoading } = useSelector((state: RootState) => state.interval.segment);

	const { priceType } = useSelector((state: RootState) => state.plan);

	const handleClickConfirmBtn = async () => {
		if (priceType === 'BYOL') {
			dispatch(deletePathControlSegmentThunk({ segmentId: id, segmentOrder, onClose }));
			return;
		}

		await dispatch(deleteIntervalSegmentThunk({ segmentId: id, segmentOrder, onClose }));
	};

	return (
		<Portal>
			<Modal
				visible={visible}
				isCloseIconVisible={false}
				actionType="double"
				primaryBtnLabel={isLoading ? <Loader height="16px" /> : t('modal.segConfirm')}
				primaryBtnFunction={handleClickConfirmBtn}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnDisable={isLoading}
				secondaryBtnFunction={onClose}
				center
				primaryBtnMould="custom"
				primaryBtnButtonColor={paletteSDS.blue['700']}
				primaryBtnDisabledColor={paletteSDS.blue['700']}
				primaryBtnHoverColor={paletteSDS.blue['800']}
				primaryBtnLabelColor={paletteSDS.gray['50']}
			>
				<div css={childrenCss}>
					<p>{t('modal.segDelModal', { title: segmentName })}</p>
				</div>
			</Modal>
		</Portal>
	);
}

const childrenCss = css`
	text-align: center;
	line-height: 160%;

	word-break: break-all;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';
import { paletteSDS } from 'surf-design-system';
import useTXNColumnChartOptions from 'features/nfEUM/hooks/useTXNColumnChartOptions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'common/redux/store';
import TXNChartCount from 'features/nfEUM/components/TXNChartCount';
import TXNChartTitle from 'features/nfEUM/components/TXNChartTitle';
import { calcMaxIntFn, formatLocaleString } from 'features/nfEUM/utils/chartFunc';

export default function TXNTodayUVChart() {
	const { t } = useTranslation();
	const { visit } = useSelector((state: RootState) => state.eumTXNToday);

	const { apexLineOption } = useTXNColumnChartOptions({
		maxY: calcMaxIntFn(visit.annotation.y, 0),
		annotation: visit.annotation,
	});

	return (
		<div>
			<TXNChartTitle
				title={t('eum.todayUV')}
				labels={[
					{ color: paletteSDS.categoryColor.blue, label: 'Web', id: 0 },
					{ color: paletteSDS.categoryColor.mint, label: 'Native', id: 1 },
				]}
			/>
			<TXNChartCount count={formatLocaleString(visit.totalCount)} />
			<div css={chartCss}>
				<ReactApexChart
					type="bar"
					width="640px"
					height="136px"
					series={[
						{
							name: 'web',
							data: visit.data.web,
							color: paletteSDS.categoryColor.blue,
						},
						{
							name: 'native',
							data: visit.data.native,
							color: paletteSDS.categoryColor.mint,
						},
					]}
					options={apexLineOption}
				/>
			</div>
		</div>
	);
}

const chartCss = css`
	margin-top: -10px;
`;

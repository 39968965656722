import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import { getProjectLicenseApi } from 'features/license/api/getProjectLicense.api';
import { ProjectLicenseStatus } from 'features/license/types/getProjectLicense.type';

interface GetProjectLicenseThunkProps {
	projectId: number;
}

export const getProjectLicenseThunk = createAsyncThunk<
	ProjectLicenseStatus,
	GetProjectLicenseThunkProps,
	{ state: RootState }
>('prj/get/project/license/v2', async (arg, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();

	const { projectId } = arg;

	try {
		const { data } = await getProjectLicenseApi({ tenantId: tenantId as string, organizationId, projectId });
		return data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

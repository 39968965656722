/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from 'common/redux/store';

import { Modal, Portal } from 'surf-design-system';

import { setIsSegmentGraphModalVisible } from 'common/context/modalSlice';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 그래프 등록이 5개 초과일 시 팝업
 */

export default function SegmentGraphModal() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { isSegmentGraphModalVisible } = useSelector((state: RootState) => state.modal);

	const handleClickCancelBtn = () => {
		dispatch(setIsSegmentGraphModalVisible(false));
	};

	return (
		<Portal>
			<Modal
				visible={isSegmentGraphModalVisible}
				isBackgroundActive
				center
				isCloseIconVisible={false}
				actionType="single"
				primaryBtnLabel={t('modal.segConfirm')}
				primaryBtnFunction={handleClickCancelBtn}
			>
				<div css={childrenCss}>{t('modal.graphViewMsg')}</div>
			</Modal>
		</Portal>
	);
}

const childrenCss = css`
	text-align: center;
	line-height: 160%;
`;

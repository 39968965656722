/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { paletteSDS } from 'surf-design-system';

import { ActionFunnel } from 'features/nfBasic/utils/funnelLib';
import segFrameImg from 'features/nfBasic/assets/segmentImg/shape.png';
import waveFast from 'features/nfBasic/assets/segmentImg/waveFast.gif';
import waveMedium from 'features/nfBasic/assets/segmentImg/waveMedium.gif';
import waveSlow from 'features/nfBasic/assets/segmentImg/waveSlow.gif';
import FunnelSegmentTooltip from 'features/nfBasic/components/FunnelSegmentTooltip';
import type { FunnelData, SpeedStatus } from 'features/nfBasic/types/funnelLib.type';
import { getResourceUsageRate, getWaveHeightSegment, restrictTextPosSegment } from 'features/nfBasic/utils/funnelFunc';

interface FunnelSegmentProps {
	funnelData: FunnelData;
	speedStatus: SpeedStatus;
	disabled: boolean;
}

function NetFunnelSegmentClassic({ funnelData, speedStatus = 'FAST', disabled = false }: FunnelSegmentProps) {
	const { t } = useTranslation();

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isHover, setIsHover] = useState<boolean>(false);

	const divRef = useRef<HTMLDivElement>(null);
	// TODO: 라이브러리 타입 정의하기
	const dataRef = useRef<any>(null);

	const waveImg = getWaveImg(speedStatus);

	const [resourceUsageRate, setResourceUsageRate] = useState(
		getResourceUsageRate(funnelData.used_size, funnelData.limit)
	);

	useEffect(() => {
		if (isFirstRender) {
			dataRef.current = new ActionFunnel(funnelData.id, 'service_1', funnelData.id, { speedStatus }, () => {});
			dataRef.current.setData(funnelData);
			setIsFirstRender(false);
		}
	}, [funnelData.id, funnelData, speedStatus, isFirstRender]);

	useEffect(() => {
		if (!dataRef.current) return;

		const isVisible = document.visibilityState === 'visible';
		if (isVisible) {
			dataRef.current.setData(funnelData);
			setResourceUsageRate(getResourceUsageRate(funnelData.used_size, funnelData.limit));
		}
	}, [funnelData, isFirstRender]);

	useEffect(() => {
		return () => {
			setIsFirstRender(() => true);
		};
	}, []);

	const textMouseEnterHandler = () => {
		setIsHover(() => true);
	};

	const textMouseLeaveHandler = () => {
		setIsHover(() => false);
	};

	return (
		<div css={FunnelCss(disabled)}>
			<div id={funnelData.id} css={particleCss} />
			{!disabled && (
				<div css={inflowWrapperCss}>
					<div css={emptyCss} />
					<div css={inflowCss(speedStatus, funnelData.wait_tm)}>{waveImg}</div>
				</div>
			)}

			<div css={filterWrapperCss}>
				<div css={blurFilterCss(disabled ? 0 : resourceUsageRate)} />
				<div css={gagueCss} />
			</div>
			<img css={funnelFrameImgCss} src={segFrameImg} alt="funnelFrame" />
			{!disabled && (
				<div>
					<div css={emptyContainerCss}>
						<div
							css={waitPercentTextCss(resourceUsageRate, isHover)}
							onMouseEnter={textMouseEnterHandler}
							onMouseLeave={textMouseLeaveHandler}
							ref={divRef}
						>
							<span>
								{funnelData.used_size}({resourceUsageRate}%)
							</span>
						</div>
					</div>
					<div css={emptyContainerCss}>
						<div css={waitPercentTooltipCss(resourceUsageRate)}>
							<FunnelSegmentTooltip
								isShow={isHover}
								value={`${funnelData.used_size}(${resourceUsageRate}%)`}
								name={t('projectMonitoring.resourceUsageRate')}
								parentWidth={divRef.current?.clientWidth}
								top={0}
								left={35}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default React.memo(NetFunnelSegmentClassic);

const FunnelCss = (disabled: boolean) => css`
	position: relative;
	width: 90px;
	height: 358px;
	background-color: ${disabled ? paletteSDS.gray[75] : '#d9edff'};
`;

const particleCss = css`
	position: absolute;
	width: 90px;
	height: 358px;
`;

const inflowWrapperCss = css`
	position: absolute;
	top: 34px;
	width: 90px;
	height: 60px;
	overflow: hidden;
`;

const emptyCss = css`
	width: 1px;
	height: 1px;
`;

const inflowCss = (speedStatus: SpeedStatus, waitTime: number) => css`
	position: absolute;
	bottom: 0px;
	width: 90px;
	height: ${getWaveHeightSegment(speedStatus, waitTime)}%;
	background-color: #0089d6;
	transition: all 0.5s;
	opacity: 0.5;

	& > img {
		display: ${waitTime === 0 ? 'none' : 'block'};
	}
`;

const waveFastCss = css`
	position: absolute;
	top: -3px;
	width: 90px;
`;

const waveMediumCss = css`
	position: absolute;
	top: -2px;
	width: 90px;
`;

const waveSlowCss = css`
	position: absolute;
	top: -3px;
	width: 90px;
`;

const emptyContainerCss = css`
	position: absolute;
	top: 168px;
	left: 0px;
	width: 90px;
	height: 110px;
`;

const waitPercentTextCss = (percent: number, isHover: boolean) => css`
	position: absolute;
	left: 50%;
	top: ${100 - restrictTextPosSegment(percent)}%;
	transform: translate3d(-50%, 0, 0);
	padding: 0 4px;
	background-color: ${isHover ? paletteSDS.gray[900] : ''};
	border-radius: 4px;
	font-size: 14px;
	color: ${(() => {
		if (isHover) return paletteSDS.systemColor.white;
		return percent < 60 ? 'black' : paletteSDS.systemColor.white;
	})()};
	line-height: 140%;
	letter-spacing: 0.16px;
	white-space: nowrap;
	z-index: 1001;
	transition: top 0.5s;
`;

const waitPercentTooltipCss = (percent: number) => css`
	position: absolute;
	left: 50%;
	top: ${100 - restrictTextPosSegment(percent)}%;
	transform: translate3d(-50%, 0, 0);
	z-index: 1005;
	transition: top 0.5s;
`;

const filterWrapperCss = css`
	position: absolute;
	top: 168px;
	left: 0px;
	width: 90px;
	height: 110px;
	overflow: hidden;
	border-radius: 10px;
	z-index: 1000;
`;

const blurFilterCss = (height: number) => css`
	width: 100%;
	height: ${100 - height}%;
	background: rgba(90, 90, 90, 0.15);
	/* backdrop-filter: blur(3px); */
	transition: height 0.5s;
	transform: translate3d(0, 0, 0);
`;

const gagueCss = css`
	width: 100%;
	height: 100%;
	background-color: ${paletteSDS.blue[600]};
`;

const funnelFrameImgCss = css`
	position: absolute;
	top: 0;
	width: 90px;
	height: 358px;
	z-index: 1001;
`;

const getWaveImg = (speedStatus: SpeedStatus) => {
	if (speedStatus === 'FAST') return <img css={waveFastCss} src={waveFast} alt="waveFast" />;
	if (speedStatus === 'MEDIUM') return <img css={waveMediumCss} src={waveMedium} alt="waveMedium" />;
	return <img css={waveSlowCss} src={waveSlow} alt="waveSlow" />;
};

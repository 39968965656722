/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS } from 'surf-design-system';

interface AgentCodeBoxProps {
	agentCode: string;
	height: string;
	lang?: string;
}
export default function AgentCodeBox({ agentCode, height, lang = 'swift' }: AgentCodeBoxProps) {
	const { t } = useTranslation('nfAgent');
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 500);
		}
	}, [isCopied]);

	return (
		<div css={codeBlockContainer}>
			<div css={codeBlockBox}>
				<CopyToClipboard text={agentCode} onCopy={() => setIsCopied(true)}>
					<div css={copyButtonSt(isCopied)}>{isCopied ? t('installAOS.copied') : t('installAOS.copy')}</div>
				</CopyToClipboard>
				<CodeBlock
					text={agentCode}
					theme={dracula}
					language={lang}
					codeBlock
					wrapLongLines
					customStyle={{
						height: { height },
						backgroundColor: '#1E1F29',
						borderRadius: '8px',
						padding: '24px 16px',
					}}
				/>
			</div>
		</div>
	);
}

const codeBlockContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	height: fit-content;

	background: #1e1f29;
	border-radius: 8px;
`;

const codeBlockBox = css`
	position: relative;
`;

const copyButtonSt = (isCopied: boolean) => css`
	position: absolute;

	top: 0;
	right: 0;

	width: 74px;
	height: 26px;

	padding: 7px 0px;

	background: ${isCopied ? paletteSDS.blue['500'] : paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};
	text-align: center;

	border-radius: 0px 8px;

	cursor: pointer;

	&:hover {
		background: ${!isCopied && paletteSDS.gray['700']};
	}
`;

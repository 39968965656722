/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { colorPalette, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import PlanTitle from './PlanTitle';
import { addNumberComma, getUsagePercentage } from '../utils/formatFunc';

import {
	MAU_IDX,
	MAU_OVERAGE_IDX,
	MONITOR_IDX,
	MWU_IDX,
	MWU_OVERAGE_IDX,
	ADMIN_IDX,
	OPERATOR_IDX,
	CCU_OVERAGE_IDX,
} from '../data/contants';
/**
 * author: Finn
 * createdAt: 2022-07-11
 * updatedAt: 2022-09-19
 * description: 사용 중인 플랜 기능 텍스트 컴포넌트
 * todo: 다국어 처리, 넘어오는 데이터 확인 후 동적으로 기준보다 높은 수치 빨간 텍스트로 변경 (인라인 스타일 제거)
 */

export default function PlanUsageSummary() {
	const { planOptions, planOverages, priceUnit, usage } = useSelector((state: RootState) => state.plan);

	const { t } = useTranslation();

	const {
		admin,
		operator,
		monitor,
		activeUsers,
		sectionActiveUsers,
		waitingUsers,
		sectionWaitingUsers,
		peakConcurrentConnectingUserOverDays,
		peakConcurrentConnectingUserOverTotalCount,
		peakConcurrentConnectingUsers,
		sectionPeakConcurrentConnectingUsers,
		sectionVwrCount,
		vwrCount,
	} = usage;

	const currentUsageMau = getUsagePercentage(activeUsers + sectionActiveUsers, planOptions[MAU_IDX].value);
	const currentUsageMwu = getUsagePercentage(waitingUsers + sectionWaitingUsers, planOptions[MWU_IDX].value);

	return (
		<div css={containerSt}>
			<PlanTitle title={t('planSubscription.planUsageSummaryTitle')} />
			<div css={infoContainerSt}>
				<div css={infoBoxSt}>
					<div css={infoHeaderSt}>
						<p css={infoTitleSt}>{t('planSubscription.mau')}</p>
						<p css={infoSubTitleSt}>
							{t('planSubscription.mauMsg', {
								unit: priceUnit === 'KRW' ? '₩' : '$',
								price: planOverages[MAU_OVERAGE_IDX].price,
								unitValue: planOverages[MAU_OVERAGE_IDX].perUnitValue,
							})}
						</p>
					</div>
					<div css={gap20Column}>
						<div css={totalBox}>
							<div css={labelSt}>{t('planSubscription.totalChar')}</div>
							<div css={totalValueBox}>
								<div css={[totalValueBold, currentUsageMau > 100 && colorPalette.red['500']]}>{currentUsageMau}%</div>
								<div css={totalValueMedium}>
									<span css={currentUsageMau > 100 && colorPalette.red['500']}>
										{addNumberComma((activeUsers + sectionActiveUsers).toString())}
									</span>
									&nbsp;/&nbsp;
									{addNumberComma(planOptions[MAU_IDX].value.toString())}
								</div>
							</div>
						</div>
						<div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.basic')}</div>
								<div css={valueSt}>{addNumberComma(activeUsers.toString())}</div>
							</div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.path')}</div>
								<div css={valueSt}>{addNumberComma(sectionActiveUsers.toString())}</div>
							</div>
						</div>
					</div>
				</div>
				<div css={infoBoxSt}>
					<div css={infoHeaderSt}>
						<p css={infoTitleSt}>{t('planSubscription.mwu')}</p>
						<p css={infoSubTitleSt}>
							{t('planSubscription.mauMsg', {
								unit: priceUnit === 'KRW' ? '₩' : '$',
								price: planOverages[MWU_OVERAGE_IDX].price,
								unitValue: planOverages[MWU_OVERAGE_IDX].perUnitValue,
							})}
						</p>
					</div>
					<div css={gap20Column}>
						<div css={totalBox}>
							<div css={labelSt}>{t('planSubscription.totalChar')}</div>
							<div css={totalValueBox}>
								<div css={[totalValueBold, currentUsageMwu > 100 && colorPalette.red['500']]}>{currentUsageMwu}%</div>
								<div css={totalValueMedium}>
									<span css={currentUsageMwu > 100 && colorPalette.red['500']}>
										{addNumberComma((waitingUsers + sectionWaitingUsers).toString())}
									</span>
									&nbsp;/&nbsp;
									{addNumberComma(planOptions[MWU_IDX].value.toString())}
								</div>
							</div>
						</div>
						<div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.basic')}</div>
								<div css={valueSt}>{addNumberComma(waitingUsers.toString())}</div>
							</div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.path')}</div>
								<div css={valueSt}>{addNumberComma(sectionWaitingUsers.toString())}</div>
							</div>
						</div>
					</div>
				</div>
				<div css={infoBoxSt}>
					<div css={infoHeaderSt}>
						<p css={infoTitleSt}>{t('planSubscription.ccu')}</p>
						<p css={infoSubTitleSt}>
							{t('planSubscription.mauMsg', {
								unit: priceUnit === 'KRW' ? '₩' : '$',
								price: planOverages[CCU_OVERAGE_IDX].price,
								unitValue: planOverages[CCU_OVERAGE_IDX].perUnitValue,
							})}
						</p>
					</div>
					<div css={gap20Column}>
						<div css={totalBox}>
							<div css={labelSt}>{t('planSubscription.totalOverage')}</div>
							<div css={totalValueBox}>
								<div css={[totalValueBold]}>
									{addNumberComma(peakConcurrentConnectingUserOverTotalCount.toString())}
								</div>
							</div>
						</div>
						<div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.daysExceeded')}</div>
								<div css={valueSt}>
									{t('planSubscription.daysExceededValue', { value: peakConcurrentConnectingUserOverDays })}
								</div>
							</div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.basic')}</div>
								<div css={valueSt}>{addNumberComma(peakConcurrentConnectingUsers.toString())}</div>
							</div>
							<div css={valueLabelBox}>
								<div css={labelSt}>{t('planSubscription.path')}</div>
								<div css={valueSt}>{addNumberComma(sectionPeakConcurrentConnectingUsers.toString())}</div>
							</div>
						</div>
					</div>
				</div>
				<div css={infoBoxSt}>
					<div css={infoHeaderSt}>
						<p css={infoTitleSt}>{t('planSubscription.vwr')}</p>
					</div>
					<div>
						<div css={valueLabelBox}>
							<div css={labelSt}>{t('planSubscription.basic')}</div>
							<div css={valueSt}>{vwrCount}</div>
						</div>
						<div css={valueLabelBox}>
							<div css={labelSt}>{t('planSubscription.path')}</div>
							<div css={valueSt}>{sectionVwrCount}</div>
						</div>
					</div>
				</div>
				<div css={infoBoxSt}>
					<div css={infoHeaderSt}>
						<p css={infoTitleSt}>{t('planSubscription.numInvitedMem')}</p>
					</div>
					<div>
						<div css={valueLabelBox}>
							<div css={labelSt}>{t('planSubscription.admin')}</div>
							<div css={valueSt}>
								{admin}&nbsp;/&nbsp;{planOptions[ADMIN_IDX].value}
							</div>
						</div>
						<div css={valueLabelBox}>
							<div css={labelSt}>{t('planSubscription.operator')}</div>
							<div css={valueSt}>
								{operator}&nbsp;/&nbsp;{planOptions[OPERATOR_IDX].value}
							</div>
						</div>
						<div css={valueLabelBox}>
							<div css={labelSt}>{t('planSubscription.monitor')}</div>
							<div css={valueSt}>
								{monitor}&nbsp;/&nbsp;{planOptions[MONITOR_IDX].value}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	width: 60rem;
`;

const infoContainerSt = css`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

const infoBoxSt = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 452px;
	height: 220px;
	padding: 20px;

	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray[300]};
	background-color: ${paletteSDS.white};
`;

const infoHeaderSt = css``;

const infoTitleSt = css`
	min-height: 1.625rem;
	line-height: 160%;
	font-weight: ${themeSDS.fontWeight.medium};
`;

const infoSubTitleSt = css`
	min-height: 1.375rem;
	line-height: 160%;
	font-size: ${themeSDS.fontSize[14]};
	color: ${paletteSDS.gray[700]};
`;

const totalBox = css`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	text-align: right;
`;

const totalValueBox = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const totalValueBold = css`
	font-size: 28px;
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.black};
`;

const totalValueMedium = css`
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
	color: ${paletteSDS.black};
`;

const gap20Column = css`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const valueLabelBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const labelSt = css`
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray['700']};

	line-height: 160%;
`;

const valueSt = css`
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.medium};
	color: ${paletteSDS.black};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { setInitialToast, setIsEUMNetworkErrorToastVisible } from 'common/context/toastSlice';
import getEUMLoadApi from 'features/nfEUM/api/getEUMLoad.api';
import {
	GetEUMLoadApiDataError,
	GetEUMLoadApiDataLoad,
	GetEUMLoadApiDataLoadTime,
} from 'features/nfEUM/types/getEUMLoad.type';
import { getDomainWithoutSubdomain } from 'features/nfEUM/utils/domainFunc';

export interface GetTXNRealtimeThunkResult {
	loadData: GetEUMLoadApiDataLoad;
	loadTimeData: GetEUMLoadApiDataLoadTime;
	errorData: GetEUMLoadApiDataError;
}

export const getTXNRealtimeThunk = createAsyncThunk<GetTXNRealtimeThunkResult, void, { state: RootState }>(
	'eum/txn/realtime/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantUrl, organizationId, customerDomain },
			eumTXNRealtime: { beginTime, endTime },
			projectInfo: { projectList, selectedProjectId },
			plan: { priceType },
		} = thunkAPI.getState();

		const selectedDomain = projectList.find(el => el.id === selectedProjectId)?.customerServiceDomain || '';
		const selectedProjectKey = projectList.find(el => el.id === selectedProjectId)?.projectKey || '';

		try {
			const apiResponse = await getEUMLoadApi({
				tenantUrl,
				organizationId,
				mainDomain:
					priceType !== 'FREE' || process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
						? getDomainWithoutSubdomain(selectedDomain)
						: customerDomain,
				beginTime,
				endTime,
				projectKey: selectedProjectKey,
			});

			let loadData: GetEUMLoadApiDataLoad = {
				nativeVisitList: [{ time: 0, value: 0 }],
				webVisitList: [{ time: 0, value: 0 }],
			};

			let loadTimeData: GetEUMLoadApiDataLoadTime = {
				nativeLoad: [{ time: 0, value: 0 }],
				webLoad: [{ time: 0, value: 0 }],
			};

			let errorData: GetEUMLoadApiDataError = {
				nativeErrorList: [{ time: 0, value: 0 }],
				webErrorList: [{ time: 0, value: 0 }],
			};

			if (apiResponse.data) {
				loadData = apiResponse.data.userLoad;
				loadTimeData = apiResponse.data.userLoadTime;
				errorData = apiResponse.data.userError;
			}
			thunkAPI.dispatch(setInitialToast());
			return {
				loadData,
				loadTimeData,
				errorData,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			thunkAPI.dispatch(setIsEUMNetworkErrorToastVisible(true));

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import type { MemberData } from '../types/getMemberList.type';
import getMemberListApi from '../api/getMemberList.api';

export interface GetMemberListThunkProps {
	searchValue?: string;
	memberStatus: string;
}

export interface GetMemberListThunkResult {
	members: MemberData[];
	searchValue?: string;
	memberStatus: string;
}

export const getMemberListThunk = createAsyncThunk<
	GetMemberListThunkResult,
	GetMemberListThunkProps,
	{ state: RootState }
>('memberList/get', async (args, thunkAPI) => {
	const { searchValue, memberStatus } = args;

	const {
		account: { organizationId },
	} = thunkAPI.getState();

	try {
		const {
			data: { content },
		} = await getMemberListApi({
			searchValue,
			organizationId,
			memberStatus: memberStatus === 'all' ? undefined : memberStatus,
		});

		return { members: content, searchValue, memberStatus };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

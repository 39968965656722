// TODO: localhost로 띄우는 경우에 대한 조건 수정, 빌더 패턴으로 변경, test코드 작성

export const getOnPremBaseUrl = (nginxPath?: string): string => {
	let url;
	if (window.location.origin.includes('localhost')) {
		url = nginxPath ? `${process.env.REACT_APP_BASE_URL}/${nginxPath}` : `${process.env.REACT_APP_BASE_URL}`;
	} else {
		url = nginxPath
			? `${window.location.protocol}//${window.location.hostname.concat('/', nginxPath)}`
			: `${window.location.protocol}//${window.location.hostname}`;
		if (window.location.port) url = setOnPremUrlPort(url);
	}

	return url;
};

export const setOnPremUrlPort = (orgURL: string) => {
	const url = new URL(orgURL);
	url.port = window.location.port;
	return url.toString();
};

export const getOnPremAgentNFServer = () => {
	const url = `${window.location.protocol}//${window.location.hostname}`;
	return url;
};

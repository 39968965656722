import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getBrowserLanguage, getI18nLanguage } from 'common/utils/languageFunc';
import { LANG_KEY } from 'common/data/constants';

import translationKo from 'common/locale/translation.ko.json';
import translationEn from 'common/locale/translation.en.json';
import homeKo from 'features/home/locale/home.ko.json';
import homeEn from 'features/home/locale/home.en.json';
import agentKo from 'features/nfSetting/locale/agent.ko.json';
import agentEn from 'features/nfSetting/locale/agent.en.json';

// 언어 감지 1
const browserLanguage = getBrowserLanguage();

let initialLanguage = 'en';

if (browserLanguage === 'ko' || browserLanguage === 'ko-KR') {
	initialLanguage = 'ko';
}

const curLang = getI18nLanguage();

if (curLang === 'KOREAN') initialLanguage = 'ko';
if (curLang === 'ENGLISH') initialLanguage = 'en';

localStorage.setItem(LANG_KEY, initialLanguage);

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources: {
			en: {
				translation: translationEn,
				home: homeEn,
				nfAgent: agentEn,
			},
			ko: {
				translation: translationKo,
				home: homeKo,
				nfAgent: agentKo,
			},
		},
		lng: initialLanguage,
		fallbackLng: 'en', // lang이 어느것도 설정되지 않으면 여기서 설정합니다.
		interpolation: {
			escapeValue: false, // XSS 방지가 기본적으로 true로 되어 있습니다.
		},
	});

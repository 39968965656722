/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { LANG_PATH } from 'common/data/constants';
import { toggleIsProjectMenuVisible } from 'common/context/layoutSlice';

import { setSelectedProjectId } from 'features/nfSetting/context/projectInfoSlice';
import { languageType } from 'common/types/getUserProfile.type';

const SaasProjectMenu = forwardRef<HTMLDivElement>((_, ref) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { isFreetrialNotiVisible, userRole, language } = useSelector((state: RootState) => state.account);

	const { isProjectMenuVisible } = useSelector((state: RootState) => state.layout);

	const { projectList } = useSelector((state: RootState) => state.projectInfo);

	const createProjectClickHandler = () => {
		dispatch(toggleIsProjectMenuVisible());
		navigate(`/${LANG_PATH[language]}/console/product/nf/home/create_project`);
	};

	if (!isProjectMenuVisible) return null;

	return (
		<Portal id="project-menu">
			<div css={container(isFreetrialNotiVisible, language)} ref={ref}>
				{projectList.map(project => {
					const projectClickHandler = () => {
						dispatch(toggleIsProjectMenuVisible());
						dispatch(setSelectedProjectId(project.id));

						navigate(`/${LANG_PATH[language]}/console/product/nf/${project.id}/main`);
					};

					return (
						<div css={menuSt} aria-hidden onClick={projectClickHandler} key={project.id}>
							<span css={projectNameSt}>{project.projectName}</span>
						</div>
					);
				})}
				{userRole === 'OWNER' || userRole === 'ADMINISTRATOR' ? (
					<div css={menuBox}>
						<div css={menuSt} aria-hidden onClick={createProjectClickHandler}>
							<span css={projectNameSt}>{t('console.sidebar.createProject')}</span>
						</div>
					</div>
				) : null}
			</div>
		</Portal>
	);
});

export default SaasProjectMenu;

const container = (isFreetrialNotiVisible: boolean, language: languageType) => css`
	position: absolute;

	top: ${isFreetrialNotiVisible ? '110px' : '56px'};
	left: ${language === 'ENGLISH' ? '331px' : '304px'};

	width: 192px;
	height: fit-content;

	padding: 8px 0;

	border: 1px solid ${paletteSDS.gray['200']};

	border-radius: 4px;
	background-color: ${paletteSDS.gray['50']};

	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);

	z-index: 10000;
`;

const menuBox = css`
	width: 100%;
	height: fit-content;

	border-top: 1px solid ${paletteSDS.gray['200']};
`;

const menuSt = css`
	display: flex;
	align-items: center;

	width: 100%;

	height: 40px;

	padding: 0 16px;

	cursor: pointer;

	:hover {
		background: ${paletteSDS.gray['100']};
	}
`;

const projectNameSt = css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['700']};

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js/core';

import { paletteSDS, themeSDS, Textinput, Button } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';
import { toggleIsChangePasswordModalVisible } from 'common/context/modalSlice';
import PasswordType from 'common/components/PasswordType';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';

import OnPremChangePasswordModal from 'features/setting/components/OnPremChangePasswordModal';
import putUserPasswordApi from 'features/setting/api/putUserPassword.v2.onPrem.api';

import {
	changePasswordFormReducer,
	initialChangePasswordForm,
	UPDATE_CURRENT_PASSWORD,
	UPDATE_PASSWORD,
	UPDATE_VERIFY_PASSWORD,
	VALIDATE_CURRENT_PASSWORD,
	VALIDATE_PASSWORD,
	VALIDATE_VERIFY_PASSWORD,
	UPDATE_CURRENT_PW_HELP_TEXT,
} from 'features/setting/context/changePasswordForm.reducer';

export default function ChangePassword() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { sendRequest: putUserPassword, response, isLoading, error } = useApiRequestWithArg(putUserPasswordApi);

	const [changePasswordFormData, setChangePasswordFormData] = useReducer(
		changePasswordFormReducer,
		initialChangePasswordForm
	);
	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');

	const {
		password,
		isPasswordChanged,
		isPasswordValid,
		isVerifyPasswordChanged,
		isVerifyPasswordValid,
		verifyPassword,
		verifyPasswordHelpText,
		verifyPasswordValidation,
		passwordHelpText,
		passwordValidation,
		currentPassword,
		currentPasswordHelpText,
		currentPasswordValidation,
		isCurrentPasswordChanged,
		isCurrentPasswordValid,
	} = changePasswordFormData;

	const changePasswordFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault();

		putUserPassword({
			prevPassword: CryptoJS.SHA256(currentPassword).toString(),
			newPassword: CryptoJS.SHA256(password).toString(),
		});
	};

	const inputLabelClickHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
		e.stopPropagation();

		setPasswordType(prevType => {
			if (prevType === 'password') {
				return 'text';
			}
			return 'password';
		});
	};

	const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_PASSWORD, payload: e.target.value });
	};

	const currentPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_CURRENT_PASSWORD, payload: e.target.value });
	};

	const verifyPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_VERIFY_PASSWORD, payload: e.target.value });
	};

	const passwordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_PASSWORD });
	};

	const currentPasswordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_CURRENT_PASSWORD });
	};

	const verifyPasswordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_VERIFY_PASSWORD });
	};

	useEffect(() => {
		if (response && response.statusCode === 204) {
			dispatch(toggleIsChangePasswordModalVisible());
		}
	}, [dispatch, response]);

	useEffect(() => {
		if (error && error?.response?.data.errorCode === 'AU1004') {
			setChangePasswordFormData({ type: UPDATE_CURRENT_PW_HELP_TEXT, payload: 'resetPassword.error.incorrect' });
		}
	}, [error]);

	return (
		<form css={containerSt} onSubmit={changePasswordFormSubmitHandler}>
			<div css={pageTitleSt}>{t('personalSettings.changePwd')}</div>
			<div css={boxCss}>
				<Textinput
					label={<PasswordType passwordType={passwordType} onClick={inputLabelClickHandler} />}
					valueType={passwordType}
					value={currentPassword}
					validation={isCurrentPasswordChanged ? currentPasswordValidation : 'none'}
					helpText={isCurrentPasswordChanged && !isCurrentPasswordValid && t(currentPasswordHelpText)}
					onChange={currentPasswordChangeHandler}
					onBlur={currentPasswordBlurHandler}
					maxLength={20}
					showIcon
				/>
				<Textinput
					value={password}
					label={t('personalSettings.newPwd')}
					validation={isPasswordChanged ? passwordValidation : 'none'}
					helpText={isPasswordChanged && !isPasswordValid && t(passwordHelpText)}
					onChange={passwordChangeHandler}
					onBlur={passwordBlurHandler}
					maxLength={20}
					showIcon
					valueType={passwordType}
				/>
				<Textinput
					valueType={passwordType}
					label={t('resetPassword.verifyPwd')}
					value={verifyPassword}
					validation={isVerifyPasswordChanged ? verifyPasswordValidation : 'none'}
					helpText={isVerifyPasswordChanged && !isVerifyPasswordValid && t(verifyPasswordHelpText)}
					onChange={verifyPasswordChangeHandler}
					onBlur={verifyPasswordBlurHandler}
					showIcon
				/>
			</div>
			<Button
				width="400px"
				type="submit"
				disabled={!isPasswordValid || !isVerifyPasswordValid || !isCurrentPasswordValid || isLoading}
			>
				{t('resetPassword.resetPwd')}
			</Button>
			<OnPremChangePasswordModal />
		</form>
	);
}
const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const boxCss = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 400px;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;
	margin-bottom: 1.5rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import {
	NOTI_PRJ,
	NOTI_DOMAIN,
	NOTI_SEG,
	NOTI_SSEG,
	NOTI_VWR,
	NOTI_COMMON,
	NOTI_ASK,
	NOTI_NOTICE,
	LANG_PATH,
	PLAN_FREE_TRIAL_ID,
	NF_CONTROLS_VIEW_MODE_KEY,
	ONPREM_PRODUCT_MODE,
} from 'common/data/constants';
import { NotificationData } from 'common/types/getNotificationList.type';
import { putNotificationReadYnThunk } from 'common/thunk/putNotificationReadYn.thunk';
import { toggleHeaderNotiOpen, setExpandedNotificationId } from 'common/context/notificationSlice';
import { toggleIsFreetrialEndModalVisible } from 'common/context/modalSlice';

import NotificationIcon from './NotificationIcon';

export interface HeaderNotificationContentProps {
	notification: NotificationData;
}

export default function HeaderNotificationContent({ notification }: HeaderNotificationContentProps) {
	const { isFreetrialEnd, language } = useSelector((state: RootState) => state.account);

	const { priceType, planId } = useSelector((state: RootState) => state.plan);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	/**
	 * description: 헤더 알림 개별 클릭 제어 함수
	 */
	const headerNotificationClickHandler = async () => {
		if (!notification.readYn) {
			await dispatch(
				putNotificationReadYnThunk({
					notificationId: notification.id,
				})
			);
		}

		switch (notification.notiType) {
			case NOTI_COMMON:
				break;

			case NOTI_NOTICE:
			case NOTI_ASK:
				dispatch(toggleHeaderNotiOpen());
				dispatch(
					setExpandedNotificationId({ notificationId: notification.id, notificationType: notification.notiType })
				);

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					navigate(`/${LANG_PATH[language]}/on_prem/home/notification`);
					break;
				}

				navigate(`/${LANG_PATH[language]}/home/notification`);

				break;

			case NOTI_VWR:
				dispatch(toggleHeaderNotiOpen());

				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_PRJ:
				dispatch(toggleHeaderNotiOpen());

				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_DOMAIN:
				dispatch(toggleHeaderNotiOpen());

				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_SEG:
				dispatch(toggleHeaderNotiOpen());

				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_SSEG:
				dispatch(toggleHeaderNotiOpen());

				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			default:
				break;
		}
	};

	return (
		<li css={liSt} key={notification.id} onClick={headerNotificationClickHandler} aria-hidden>
			<div css={iconSt}>
				<NotificationIcon iconType={notification.iconType} />
			</div>
			<div css={contentSt}>
				<div css={headerSt(notification.readYn)}>{notification.header}</div>
				<div css={titleSt(notification.readYn)}>{notification.title}</div>
				<div css={dateSt(notification.readYn)}>{moment(notification.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
			</div>
		</li>
	);
}

const liSt = css`
	display: flex;
	gap: 12px;

	width: 100%;
	padding: 12px 16px 12px 16px;
	border-bottom: 1px solid ${paletteSDS.gray[300]};
`;

const iconSt = css`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	align-items: center;

	width: 32px;

	padding: 4px 0;
`;

const contentSt = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 244px;
`;

const headerSt = (readYn: boolean) => css`
	width: 100%;
	height: 24px;

	line-height: 140%;
	color: ${readYn ? paletteSDS.gray[300] : paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	letter-spacing: 0.16px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const titleSt = (readYn: boolean) => css`
	width: 100%;
	height: 1.375rem;
	line-height: 140%;

	color: ${readYn ? paletteSDS.gray[500] : paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.medium};

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const dateSt = (readYn: boolean) => css`
	display: flex;
	gap: 4px;

	height: 0.75rem;
	line-height: 100%;
	color: ${readYn ? paletteSDS.gray[400] : paletteSDS.gray[800]};
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ChevronRightIcon, paletteSDS, themeSDS } from 'surf-design-system';

import type { RootState } from 'common/redux/store';
import type { ProjectInformation } from 'features/nfSetting/types/getProjectList.v2.type';
import { LANG_PATH, NF_CONTROLS_VIEW_MODE_KEY } from 'common/data/constants';

export default function NetfunnelMainPage() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { selectedProjectId, projectList } = useSelector((state: RootState) => state.projectInfo);

	const { language, userRole } = useSelector((state: RootState) => state.account);

	const viewMode = window.localStorage.getItem(NF_CONTROLS_VIEW_MODE_KEY);

	const selectedProjectData = projectList.find(el => el.id === selectedProjectId) as ProjectInformation;

	const basicControlClickHandler = () => {
		navigate(
			`/${LANG_PATH[language]}/on_prem/console/product/nf/${selectedProjectId}/controls/basic/${viewMode || 'default'}`
		);
	};

	const pathControlClickHandler = () => {
		navigate(
			`/${LANG_PATH[language]}/on_prem/console/product/nf/${selectedProjectId}/controls/path/${viewMode || 'default'}`
		);
	};

	const uriClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/console/product/nf/${selectedProjectId}/eum/url`);
	};

	const transactionClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/console/product/nf/${selectedProjectId}/eum/transaction`);
	};

	const projectManagementClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/console/product/nf/${selectedProjectId}/settings/management/project`);
	};

	return (
		<div css={container}>
			<div css={headerBox}>
				<div css={titleSt}>{t('console.nfMain.greeting')}</div>
				<div css={infoBox}>
					<div css={guidanceSt} />
					<div css={listBox}>
						<div css={domainBox}>
							<div css={domainTitleSt}>{t('console.nfMain.mainDomain')}</div>
							<div css={domainContentSt}>{selectedProjectData.customerServiceDomain}</div>
						</div>
						<div css={domainBox}>
							<div css={domainTitleSt}>{t('console.nfMain.projectName')}</div>
							<div css={domainContentSt}>{selectedProjectData.projectName}</div>
						</div>
						<div css={domainBox}>
							<div css={domainTitleSt}>{t('console.nfHome.projectKey')}</div>
							<div css={domainContentSt}>{selectedProjectData.projectKey}</div>
						</div>
					</div>
				</div>
				{userRole === 'OWNER' || userRole === 'ADMINISTRATOR' ? (
					<Button mould="secondary" size="small" onClick={projectManagementClickHandler}>
						{t('console.nfMain.projectManagement')}
					</Button>
				) : null}
			</div>
			<div css={tableContainer}>
				<div css={tdBox(false)} onClick={basicControlClickHandler} aria-hidden>
					<div css={labelBox}>{t('console.nfMain.basic')}</div>
					<div css={descriptionSt}>{t('console.nfMain.basicDescription')}</div>
					<div css={iconBox}>
						<ChevronRightIcon fill={paletteSDS.blue['700']} height="16px" width="16px" />
					</div>
				</div>
				<div css={tdBox(false)} onClick={pathControlClickHandler} aria-hidden>
					<div css={labelBox}>{t('console.nfMain.path')}</div>
					<div css={descriptionSt}>{t('console.nfMain.pathDescription')}</div>
					<div css={iconBox}>
						<ChevronRightIcon fill={paletteSDS.blue['700']} height="16px" width="16px" />
					</div>
				</div>
				<div css={tdBox(false)} onClick={transactionClickHandler} aria-hidden>
					<div css={labelBox}>{t('console.nfMain.transaction')}</div>
					<div css={descriptionSt}>{t('console.nfMain.transactionDescription')}</div>
					<div css={iconBox}>
						<ChevronRightIcon fill={paletteSDS.blue['700']} height="16px" width="16px" />
					</div>
				</div>
				<div css={tdBox(true)} onClick={uriClickHandler} aria-hidden>
					<div css={labelBox}>{t('console.nfMain.url')}</div>
					<div css={descriptionSt}>{t('console.nfMain.urlDescription')}</div>
					<div css={iconBox}>
						<ChevronRightIcon fill={paletteSDS.blue['700']} height="16px" width="16px" />
					</div>
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	padding: 32px 24px 80px;
	gap: 24px;

	width: 960px;

	margin: 0 auto;
`;

const headerBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const titleSt = css`
	font-size: ${themeSDS.fontSize['24']};
	font-weight: ${themeSDS.fontWeight.bold};
	color: ${paletteSDS.gray['900']};

	line-height: 140%;
`;

const infoBox = css`
	display: flex;
	align-items: flex-start;
	gap: 16px;
`;

const guidanceSt = css`
	flex-shrink: 0;

	width: 4px;
	height: 90px;
	border: 4px;

	background: ${paletteSDS.blue['100']};
`;

const listBox = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 892px;
	height: 90px;
`;

const domainBox = css`
	display: flex;
	gap: 8px;
`;

const domainTitleSt = css`
	flex-shrink: 0;

	width: 160px;

	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray['700']};

	line-height: 160%;
`;

const domainContentSt = css`
	flex-shrink: 1;
	width: 100%;

	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray['900']};

	line-height: 160%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const tableContainer = css`
	display: flex;
	flex-direction: column;

	height: 162px;

	background: ${paletteSDS.gray['50']};

	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray['300']};

	overflow: hidden;
`;

const tdBox = (isEnd: boolean) => css`
	display: flex;

	height: 40px;

	border-bottom: ${!isEnd && `1px solid ${paletteSDS.gray['200']}`};

	:hover {
		background: ${paletteSDS.gray['100']};
	}

	cursor: pointer;
`;

const labelBox = css`
	flex: none;
	display: flex;
	align-items: center;

	width: 190px;

	padding: 0 16px;

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray['900']};
	line-height: 128%;
`;

const descriptionSt = css`
	flex: none;
	display: flex;
	align-items: center;

	width: 672px;

	padding: 0 16px;

	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray['900']};
	line-height: 128%;
`;

const iconBox = css`
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 48px;

	padding: 0 16px;
`;

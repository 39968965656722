/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import ColorCircle from 'common/components/ColorCircle';
import Table from 'common/components/Table';
import type { TableColumn, TableRow } from 'common/components/Table';

import { addNumberComma } from 'features/plan/utils/formatFunc';

export default function URLStatusTable() {
	const { t } = useTranslation();

	const urlStatusColumns: TableColumn[] = useMemo(
		() => [
			{
				id: 0,
				label: t('eum.urlName'),
				maxWidth: '491px',
				minWidth: '260px',
				padding: '16px',
				backgroundColor: paletteSDS.gray[200],
			},
			{
				id: 1,
				label: t('eum.count'),
				maxWidth: '220px',
				minWidth: '156px',
				padding: '11px 8px 11px 8px',
				backgroundColor: paletteSDS.gray[200],
			},
			{
				id: 2,
				label: t('eum.averageProcessTime'),
				maxWidth: '220px',
				minWidth: '156px',
				padding: '11px 8px 11px 8px',
				backgroundColor: paletteSDS.gray[200],
			},
			{
				id: 3,
				label: t('eum.maxProcessTime'),
				maxWidth: '220px',
				minWidth: '156px',
				padding: '11px 8px 11px 8px',
				backgroundColor: paletteSDS.gray[200],
			},
		],
		[t]
	);

	const urlStatusRows: TableRow[] = useSelector(
		(state: RootState) =>
			state.eum.uriStatus.map(el => ({
				id: el.uri,
				items: [
					{
						elementId: `${el.uri}-color`,
						element: el.seg_color === '' ? '' : <ColorCircle color={el.seg_color} />,
						width: '30px',
						maxWidth: '30px',
						minWidth: '30px',
						padding: '11px 0 11px 16px',
						backgroundColor: el.seg_color === '' ? undefined : paletteSDS.gray['50'],
					},
					{
						elementId: `${el.uri}-uri`,
						element: el.uri,
						maxWidth: '461px',
						minWidth: '230px',
						fontSize: themeSDS.fontSize['16'],
						padding: '11px 8px 11px 8px',
						backgroundColor: el.seg_color === '' ? undefined : paletteSDS.gray['50'],
						showTextBox: true,
						textBoxMaxWidth: '960px',
						showCopyIcon: true,
					},
					{
						elementId: 2,
						element: addNumberComma(el.count.toString()),
						maxWidth: '220px',
						minWidth: '156px',
						fontSize: themeSDS.fontSize['16'],
						padding: '11px 8px 11px 8px',
						backgroundColor: el.seg_color === '' ? undefined : paletteSDS.gray['50'],
					},
					{
						elementId: 3,
						element: (el.avg_pt / 1000).toFixed(1),
						maxWidth: '220px',
						minWidth: '156px',
						fontSize: themeSDS.fontSize['16'],
						padding: '11px 8px 11px 8px',
						backgroundColor: el.seg_color === '' ? undefined : paletteSDS.gray['50'],
					},
					{
						elementId: 4,
						element: (el.max_pt / 1000).toFixed(1),
						maxWidth: '220px',
						minWidth: '156px',
						fontSize: themeSDS.fontSize['16'],
						padding: '11px 8px 11px 8px',
						backgroundColor: el.seg_color === '' ? undefined : paletteSDS.gray['50'],
					},
				],
			})),
		shallowEqual
	);

	const { hasError } = useSelector((state: RootState) => state.eum);

	if (urlStatusRows.length === 0 || hasError) {
		return (
			<div>
				<Table columns={urlStatusColumns} rows={urlStatusRows} />
				<div css={emptyUriSt}>{t('eum.noURLData')}</div>
			</div>
		);
	}

	return <Table columns={urlStatusColumns} rows={urlStatusRows} />;
}

const emptyUriSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 240px;

	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.regular};
`;

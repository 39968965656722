import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import deleteSegmentGraphYnApi from '../api/deleteSegmentGraphYn.api';

export interface DeleteSegmentGraphYnThunkResult {
	id: number;
}

export interface DeleteSegmentGraphYnThunkProps {
	segmentId: number;
}

interface DeleteSegmentGraphYnThunkError {
	id: number;
	// eslint-disable-next-line
	error: any;
}

export const deleteSegmentGraphYnThunk = createAsyncThunk<
	DeleteSegmentGraphYnThunkResult,
	DeleteSegmentGraphYnThunkProps,
	{ state: RootState; rejectValue: DeleteSegmentGraphYnThunkError }
>('segment/graphYn/delete', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId } = args;

	try {
		await deleteSegmentGraphYnApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			segId: segmentId,
		});

		return { id: segmentId };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ error: error.response.data, id: segmentId });
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';

import { Link, themeSDS, paletteSDS } from 'surf-design-system';

import { getI18nPath } from 'common/utils/languageFunc';

import packageJson from '../../../package.json';

export default function Footer() {
	const { t } = useTranslation();

	const termOfUseClickHandler = () => {
		window.open(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}/terms`, '_blank');
	};

	const privacyClickHandler = () => {
		window.open(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}/privacy`, '_blank');
	};

	const cookieClickHandler = () => {
		window.open(`${process.env.REACT_APP_LANDING_URL}/${getI18nPath()}/cookies`, '_blank');
	};

	return (
		<footer css={containerSt}>
			<div css={gap8Column}>
				<div css={versionSt}>
					<Trans
						i18nKey="commonWords.footerVersion"
						components={{ bold: <strong /> }}
						values={{ version: packageJson.version }}
					/>
				</div>
			</div>
			<div css={lowerBox}>
				<div css={footerSt}>
					<Trans
						i18nKey="commonWords.footer"
						// eslint-disable-next-line
						components={{ support: <a href="mailto:support@stclab.com" /> }}
					/>
				</div>
				<div css={copyrightBox}>
					<div css={linkBox}>
						<Link size="medium" onClick={termOfUseClickHandler}>
							{t('commonWords.termsOfUse')}
						</Link>
						<Link size="medium" onClick={privacyClickHandler}>
							{t('commonWords.privacyPolicy')}
						</Link>
						<Link size="medium" onClick={cookieClickHandler}>
							{t('commonWords.cookiePolicy')}
						</Link>
					</div>
					<div css={footerResponsibleSt}>{t('commonWords.footerResponsible')}</div>
					<div css={incSt}>{t('commonWords.stclabInc', { year: moment().utcOffset('+09:00').year() })}</div>
				</div>
			</div>
		</footer>
	);
}

const containerSt = css`
	flex: none;
	width: 100%;
	min-height: 147px;
	padding: 24px;
	background-color: ${paletteSDS.gray[50]};
	border-top: 1px solid ${paletteSDS.gray[300]};
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const footerSt = css`
	font-size: ${themeSDS.fontSize['13']};
	line-height: 160%;

	color: ${paletteSDS.gray[700]};
	font-weight: ${themeSDS.fontWeight.regular};

	white-space: pre-line;
	word-break: break-word;
`;

const footerResponsibleSt = css`
	line-height: 160%;
	font-size: ${themeSDS.fontSize['11']};

	color: ${paletteSDS.gray[700]};
	font-weight: ${themeSDS.fontWeight.regular};

	white-space: nowrap;

	@media (max-width: 480px) {
		white-space: pre-line;
	}
`;

const versionSt = css`
	line-height: 160%;
	font-size: ${themeSDS.fontSize['14']};

	color: ${paletteSDS.gray[700]};
	font-weight: ${themeSDS.fontWeight.regular};

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const lowerBox = css`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 8px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 24px;
	}
`;

const copyrightBox = css`
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: flex-end;

	@media (max-width: 768px) {
		align-items: flex-start;
	}
`;

const linkBox = css`
	display: flex;
	align-items: center;
	gap: 16px;

	& > span {
		white-space: nowrap;
	}

	@media (max-width: 768px) {
		min-height: 98px;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
`;

const incSt = css`
	line-height: 160%;
	font-size: ${themeSDS.fontSize['13']};

	color: ${paletteSDS.gray[700]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { toggleIsPreferenceChangeToastVisible } from 'common/context/toastSlice';
import { putTimezoneApi } from '../api/putTimezone.api';

export interface PutTimezoneThunkProps {
	timezone: string;
}

export interface PutTimezoneThunkResult {
	timezone: string;
}

export const putTimezoneThunk = createAsyncThunk<PutTimezoneThunkResult, PutTimezoneThunkProps>(
	'timezone/put',
	async (args: PutTimezoneThunkProps, thunkAPI) => {
		const { timezone: enteredTimezone } = args;

		try {
			const {
				data: { timezone },
			} = await putTimezoneApi({ timezone: enteredTimezone });

			thunkAPI.dispatch(toggleIsPreferenceChangeToastVisible());

			return { timezone };
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

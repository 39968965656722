/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS, Button, FileDownloadIcon } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';
import { fileDownloadFromUrl } from 'common/utils/fileFunc';
import { ONPREM_AGENTS_PATH } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import IOSAgentCodeBox from 'features/nfSetting/components/IOSAgentCodeBox';
import AgentCodeBox from 'features/nfSetting/components/AgentCodeBox';
import { apiMethodFirstCodeBlock, nfAgentCodeBlock } from 'features/nfSetting/utils/onpremIOSCodeBlockFunc';
import OnpremAgentGuideEnd from './OnpremAgentGuideEnd';

interface InstallIOSAgentProps {
	titleFontSize?: string;
}

export default function OnPremInstallIOSAgent({ titleFontSize = '18px' }: InstallIOSAgentProps) {
	const { t } = useTranslation();

	const { tenantId } = useSelector((state: RootState) => state.account);

	const fileDownloadButtonClickHandler = async () => {
		await fileDownloadFromUrl(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_AGENTS_PATH)
					: process.env.REACT_APP_AGENT_URL
			}/ios/netfunnel-iOS-agent_latest.zip`,
			`netfunnel-iOS-agent_latest.zip`
		);
	};

	return (
		<div css={gapColumn('24px')}>
			<div css={[titleSt(titleFontSize), { fontSize: '22px' }]}>{t('agent.applyTitle')}</div>
			<div css={gapColumn('24px')}>
				<div css={titleSt(titleFontSize)}>{t('agent.aosGuide1Title')}</div>
				<div css={gapColumn('16px')}>
					<div css={descSt}>{t('agent.iosGuide1Desc')}</div>
				</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={gapColumn('24px')}>
				<div css={titleSt(titleFontSize)}>{t('agent.aosGuide2Title')}</div>
				<div css={gapColumn('4px')}>
					<div css={descSt}>
						<strong>{` · ${t('agent.iOSVersion')}: `}</strong>
						{t('agent.iOSVersionTxt')}
					</div>
					<div css={descSt}>
						<strong>{` · ${t('agent.networking')}: `}</strong>
						{t('agent.networkingTxt')}
					</div>
				</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={titleSt(titleFontSize)}>{t('agent.aosGuide4Title')}</div>
			<div css={[gapColumn('24px'), { paddingLeft: '16px' }]}>
				<div css={gapColumn('16px')}>
					<div css={subTitleSt}>1. {t('agent.aosGuide41Title')}</div>
					<div css={descSt}>
						<strong>{`1. ${t('agent.frameworkDownload')}: `}</strong>
						{t('agent.frameworkDownloadTxt')}
					</div>
					<Button
						mould="secondary"
						icon={<FileDownloadIcon fill={paletteSDS.blue['700']} />}
						iconPosition="right"
						onClick={fileDownloadButtonClickHandler}
						size="medium"
					>
						{t('agent.downloadInstallFile')}
					</Button>
					<div css={desc2St}>
						<strong>{`2. ${t('agent.frameworkRegistration')}: `}</strong>
						{t('agent.frameworkRegistrationTxt')}
					</div>
				</div>
				<div css={gapColumn('16px')}>
					<div css={subTitleSt}>2. {t('agent.aosGuide42Title')}</div>
					<div css={descSt}>{t('agent.iOSImport')}</div>
					<AgentCodeBox agentCode={nfAgentCodeBlock()} height="38px" />
					<div css={descSt}>{t('agent.override')}</div>
					<div css={gapColumn('8px')}>
						<div css={rowSt}>
							<span css={dotSt}>·</span>
							<span css={codeTxt}>tenantURL</span>:<span css={descSt}>{t('agent.tenantTxt')}</span>
						</div>
						<div css={rowSt}>
							<span css={dotSt}>·</span>
							<span css={codeTxt}>projectDetailURL</span>:<span css={descSt}>{t('agent.projectDetailTxt')}</span>
						</div>
						<div css={rowSt}>
							<span css={dotSt}>·</span>
							<span css={codeTxt}>eumURL</span>:<span css={descSt}>{t('agent.eumTxt')}</span>
						</div>
						<div css={rowSt}>
							<span css={dotSt}>·</span>
							<span css={codeTxt}>delegate</span>:<span css={descSt}>{t('agent.delegateTxt')}</span>
						</div>
						<div css={rowSt}>
							<span css={dotSt}>·</span>
							<span css={codeTxt}>oneTimeCallback</span>:<span css={descSt}>{t('agent.callbackTxt')}</span>
						</div>
					</div>
					<IOSAgentCodeBox agentCode={apiMethodFirstCodeBlock(tenantId)} height="215px" />
				</div>
			</div>
			<DividerWithTxt />
			<OnpremAgentGuideEnd />
		</div>
	);
}

const gapColumn = (gap: string) => css`
	display: flex;
	flex-direction: column;
	gap: ${gap};
`;

const titleSt = (titleFontSize: string) => css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${titleFontSize};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	white-space: pre-line;
	word-break: break-all;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const requirementBox = css`
	padding: 12px 16px;

	background: ${paletteSDS.gray['50']};
	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray['200']};

	white-space: pre-line;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const codeBlockBox = css`
	position: relative;

	height: fit-content;

	border-radius: 8px;
`;

const copyButtonSt = css`
	position: absolute;

	top: 0;
	right: 0;

	width: 61px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;

	cursor: pointer;
`;

const copiedSt = css`
	position: absolute;

	top: 0;
	right: 61px;

	width: 75px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.blue['500']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

const descSt = css`
	display: flex;
	align-items: center;
	gap: 4px;
	color: var(--gray-700, #666);
	font-size: 14px;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
	letter-spacing: 0.16px;
`;

const desc2St = css`
	gap: 4px;
	color: var(--gray-700, #666);
	font-size: 14px;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
	letter-spacing: 0.16px;
`;

const subTitleSt = css`
	color: var(--gray-800, #4c4c4c);
	font-size: 16px;
	font-weight: 500;
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.16px;
`;

const rowSt = css`
	display: flex;
	gap: 4px;
`;
const dotSt = css`
	width: 8px;
	text-align: center;
`;

const codeTxt = css`
	display: flex;
	align-items: center;
	border: 1px solid ${paletteSDS.gray[200]};
	border-radius: 4px;
	padding: 6px 8px;
	width: fit-content;
	height: 24px;
	background-color: ${paletteSDS.gray[200]};
	color: ${paletteSDS.gray[700]};
	font-size: 12px;
	font-weight: 400;
	line-height: 100%; /* 12px */
`;

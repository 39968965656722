/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { paletteSDS, Switch, EditIcon } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import Table from 'common/components/Table';
import type { TableColumn, TableRow } from 'common/components/Table';
import {
	setIsToggleActivatedYnModalVisible,
	setIsToggleAccessYnModalVisible,
	setIsUpdateNameModalVisible,
} from 'common/context/modalSlice';
import UpdateProjectNameModal from 'features/nfSetting/components/UpdateProjectNameModal';
import PutAccessYnModal from 'features/nfSetting/components/PutAccessYnModal';
import PutActivatedYnModal from 'features/nfSetting/components/PutActivatedYnModal';

export default function OnPremProjectManagementTable() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { deactivatedBySysYn } = useSelector((state: RootState) => state.license.prjLicense);

	const projectColumns: TableColumn[] = useMemo(
		() => [
			{
				id: 1,
				label: t('commonWords.prjTitle'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '200px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 2,
				label: t('commonWords.prjKey'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '200px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 3,
				label: t('projectMgmt.prjDate'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '120px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 4,
				label: t('projectMgmt.prjLastUpdate'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '120px',
				elementCss: css`
					flex: none;
				`,
			},
			{
				id: 5,
				label: t('projectMgmt.active'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '90px',
				elementCss: css`
					flex: none;
					justify-content: center;
				`,
			},
			{
				id: 6,
				label: t('projectMgmt.access'),
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '90px',
				elementCss: css`
					flex: none;
					justify-content: center;
				`,
			},
			{
				id: 8,
				label: '',
				padding: '0px 16px',
				backgroundColor: paletteSDS.gray['200'],
				width: '90px',
				elementCss: css`
					flex: none;
				`,
			},
		],
		[t]
	);

	const projectRows: TableRow[] = useSelector((state: RootState) =>
		state.projectInfo.projectList
			.filter(project => project.id === state.projectInfo.selectedProjectId)
			.map(el => ({
				id: el.id,
				items: [
					{
						elementId: 1,
						element: <span>{el.projectName}</span>,
						padding: '0px 16px',
						width: '200px',
						elementCss: css`
							flex: none;
							background: none;

							& > div > span {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						`,
					},
					{
						elementId: 2,
						element: el.projectKey,
						padding: '0px 16px',
						width: '200px',
						elementCss: css`
							flex: none;
							background: none;
						`,
					},
					{
						elementId: 3,
						element: moment.utc(el.createdAt).format('YYYY-MM-DD'),
						padding: '0px 16px',
						width: '120px',
						elementCss: css`
							flex: none;
							background: none;
						`,
					},
					{
						elementId: 4,
						element: moment.utc(el.updatedAt).format('YYYY-MM-DD'),
						padding: '0px 16px',
						width: '120px',
						elementCss: css`
							flex: none;
							background: none;
						`,
					},
					{
						elementId: 5,
						element: (
							<Switch
								selected={el.activatedYn}
								disabled={deactivatedBySysYn}
								onClick={() =>
									dispatch(
										setIsToggleActivatedYnModalVisible({
											selectedProjectId: el.id,
											projectName: el.projectName,
											activatedYn: el.activatedYn,
										})
									)
								}
								size="xSmall"
								id={`activated-${el.id}`}
							/>
						),
						padding: '0px 16px',
						width: '90px',
						elementCss: css`
							flex: none;
							background: none;
							& > div {
								display: flex;
								justify-content: center;
							}
						`,
					},
					{
						elementId: 6,
						element: (
							<Switch
								selected={el.accessYn}
								onClick={() =>
									dispatch(
										setIsToggleAccessYnModalVisible({
											projectName: el.projectName,
											selectedProjectId: el.id,
											accessYn: el.accessYn,
										})
									)
								}
								disabled={!el.activatedYn}
								size="xSmall"
								id={`access-${el.id}`}
							/>
						),
						padding: '0px 16px',
						width: '90px',
						elementCss: css`
							flex: none;
							background: none;
							& > div {
								display: flex;
								justify-content: center;
							}
						`,
					},
					{
						elementId: 8,
						element: (
							<EditIcon
								size="small"
								onClick={() =>
									dispatch(
										setIsUpdateNameModalVisible({
											selectedProjectId: el.id,
											projectName: el.projectName,
											projectKey: el.projectKey,
										})
									)
								}
								fill={paletteSDS.blue['700']}
							/>
						),
						padding: '0px 16px',
						width: '90px',
						elementCss: css`
							flex: none;
							background: none;
							& > div {
								display: flex;
								justify-content: center;
							}
						`,
					},
				],
				rowCss: rowSt,
			}))
	);

	const { isUpdateNameModalVisible, isToggleAccessYnModalVisible, isToggleActivatedYnModalVisible } = useSelector(
		(state: RootState) => state.modal.projectManagementModal
	);

	return (
		<React.Fragment>
			<Table columns={projectColumns} rows={projectRows} />
			{isUpdateNameModalVisible ? <UpdateProjectNameModal /> : null}
			{isToggleAccessYnModalVisible ? <PutAccessYnModal /> : null}
			{isToggleActivatedYnModalVisible ? <PutActivatedYnModal /> : null}
		</React.Fragment>
	);
}

const rowSt = css`
	background: ${paletteSDS.gray['50']};
`;

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PostProjectApiResponse } from 'features/nfSetting/types/postProject.v2.type';

export interface PostProjectApiProps {
	organizationId: number;
	customerServiceDomain: string;
	projectName: string;
	tenantId: string;
}

export async function postProjectApi({
	organizationId,
	customerServiceDomain,
	tenantId,
	projectName,
}: PostProjectApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	const body = { customerServiceDomain, projectName };

	if (cognitoToken && userKey) {
		const { data } = await axios.post<PostProjectApiResponse>(`${process.env.REACT_APP_BASE_URL}/v2/project`, body, {
			headers: {
				Authorization: cognitoToken,
				userKey,
				organizationId,
				tenantId,
			},
		});
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { NotificationData, notiType } from 'common/types/getNotificationList.type';

import { getNotificationListThunk } from '../thunk/getNotificationList.thunk';
import { putNotificationReadYnThunk } from '../thunk/putNotificationReadYn.thunk';
import { putNotificationReadYnListThunk } from '../thunk/putNotificationReadYnList.thunk';

export interface ChangeExpadedNotificationProps {
	notificationId: number | null;
	notificationType: notiType;
}

interface NotificationSliceData {
	selectedPage: number;
	notifications: NotificationData[];
	totalPages: number;
	expandedNotificationId: number | null;
	isLoaded: boolean;
	isAllRead: boolean;
	isHeaderNotiOpened: boolean;
	headerNotifications: NotificationData[];
	// eslint-disable-next-line
	error: any;
}

const initialState: NotificationSliceData = {
	selectedPage: 1,
	notifications: [],
	totalPages: 1,
	expandedNotificationId: null,
	isLoaded: false,
	error: null,
	headerNotifications: [],
	isHeaderNotiOpened: false,
	isAllRead: true,
};

const notificationSlice = createSlice({
	name: 'noti',
	initialState,
	reducers: {
		changeExpandedNotificationId: (state, action: PayloadAction<ChangeExpadedNotificationProps>) => {
			const { notificationId, notificationType } = action.payload;

			let newExpandedNotificationId: number | null = notificationId;

			if (notificationType === 'COMMON' || notificationType === 'PRD' || notificationType === 'PRJ') {
				newExpandedNotificationId = null;
			}

			if (state.expandedNotificationId === notificationId) {
				newExpandedNotificationId = null;
			}

			return { ...state, expandedNotificationId: newExpandedNotificationId };
		},
		setExpandedNotificationId: (state, action: PayloadAction<ChangeExpadedNotificationProps>) => {
			const { notificationId, notificationType } = action.payload;

			let newExpandedNotificationId: number | null = notificationId;

			if (notificationType === 'COMMON' || notificationType === 'PRD' || notificationType === 'PRJ') {
				newExpandedNotificationId = null;
			}

			return { ...state, expandedNotificationId: newExpandedNotificationId };
		},
		setIsSpecificNotiOpen: (state, action: PayloadAction<boolean>) => {
			return { ...state, isNotificationExpanded: action.payload };
		},
		toggleHeaderNotiOpen: state => {
			return { ...state, isHeaderNotiOpened: !state.isHeaderNotiOpened };
		},
		setInitialNotification: () => {
			return { ...initialState };
		},

		resetExpandedNotificationId: state => {
			return { ...state, expandedNotificationId: null };
		},
	},
	extraReducers(builder) {
		builder.addCase(getNotificationListThunk.fulfilled, (state, action) => {
			return {
				...state,
				notifications: action.payload.notificationList.notifications.content,
				totalPages: action.payload.notificationList.notifications.totalPages,
				isLoaded: true,
				expandedNotificationId: action.payload.notificationList.notifications.content
					.map(el => el.id)
					.includes(state.expandedNotificationId || -1)
					? state.expandedNotificationId
					: null,
				selectedPage: action.payload.selectedPage,
				headerNotifications:
					action.payload.selectedPage === 1
						? action.payload.notificationList.notifications.content.slice(0, 6)
						: state.headerNotifications.slice(),
				isAllRead:
					action.payload.selectedPage === 1
						? action.payload.notificationList.notifications.content.slice(0, 6).every(el => el.readYn)
						: state.isAllRead,
			};
		});

		builder.addCase(getNotificationListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload, isLoaded: true };
		});

		builder.addCase(putNotificationReadYnThunk.fulfilled, (state, action) => {
			const { notificationId } = action.payload;

			const newNotifications = state.notifications.map(noti => {
				if (noti.id === notificationId) {
					return { ...noti, readYn: true };
				}
				return noti;
			});

			const newHeaderNotifications = state.headerNotifications.map(noti => {
				if (noti.id === notificationId) {
					return { ...noti, readYn: true };
				}
				return noti;
			});

			return {
				...state,
				notifications: newNotifications,
				headerNotifications: newHeaderNotifications,
				isAllRead: newHeaderNotifications.every(el => el.readYn),
			};
		});

		builder.addCase(putNotificationReadYnThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});

		builder.addCase(putNotificationReadYnListThunk.fulfilled, (state, action) => {
			const newNotifications = state.notifications.map(noti => {
				if (action.payload.notificationIds.includes(noti.id)) {
					return { ...noti, readYn: true };
				}
				return noti;
			});

			return {
				...state,
				isAllRead: true,
				headerNotifications: state.headerNotifications.map(noti => ({ ...noti, readYn: true })),
				notifications: newNotifications,
			};
		});

		builder.addCase(putNotificationReadYnListThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});
	},
});

export const {
	setIsSpecificNotiOpen,
	changeExpandedNotificationId,
	toggleHeaderNotiOpen,
	setInitialNotification,
	setExpandedNotificationId,
	resetExpandedNotificationId,
} = notificationSlice.actions;

export default notificationSlice.reducer;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';
import { paletteSDS } from 'surf-design-system';
import useTXNLineChartOptions from 'features/nfEUM/hooks/useTXNLineChartOptions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'common/redux/store';
import TXNChartTitle from 'features/nfEUM/components/TXNChartTitle';
import { calcMaxFloatFn } from 'features/nfEUM/utils/chartFunc';

export default function TXNLoadTimeChart() {
	const { t } = useTranslation();
	const { loadTime } = useSelector((state: RootState) => state.eumTXNRealtime);
	const { timeZone } = useSelector((state: RootState) => state.account);
	const maxY = Math.max(calcMaxFloatFn(loadTime.annotation.web.y, loadTime.annotation.native.y), 4);

	const { apexLineOption } = useTXNLineChartOptions({
		maxY,
		timeZone,
		isChartTypeArea: false,
		annotation: loadTime.annotation,
	});

	return (
		<div>
			<TXNChartTitle
				title={`${t('eum.loadTime')}(s)`}
				labels={[
					{ color: paletteSDS.categoryColor.blue, label: 'Web', id: 0 },
					{ color: paletteSDS.categoryColor.mint, label: 'Native', id: 1 },
				]}
			/>
			<div css={chartCss}>
				<ReactApexChart
					type="line"
					width="426px"
					height="144px"
					series={[
						{
							name: 'web',
							data: loadTime.data.web,
							color: paletteSDS.categoryColor.blue,
						},
						{
							name: 'native',
							data: loadTime.data.native,
							color: paletteSDS.categoryColor.mint,
						},
					]}
					options={apexLineOption}
				/>
			</div>
		</div>
	);
}

const chartCss = css`
	margin-top: -10px;
`;

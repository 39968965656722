import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';
import { PutProjectAccessYnApiResponse } from '../types/putProjectAccessYn.type';

export interface PutProjectAccessYnApiProps {
	tenantId: string;
	tenantUrl: string;
	projectId: number;
	organizationId: number;
	accessYn: boolean;
}

export const putProjectAccessYnApi = async ({
	tenantId,
	tenantUrl,
	projectId,
	organizationId,
	accessYn,
}: PutProjectAccessYnApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken && organizationId !== 0) {
		const { data } = await axios.put<PutProjectAccessYnApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}/access-mode`,
			{
				accessYn,
			},
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

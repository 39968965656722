/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState, useAppDispatch } from 'common/redux/store';

import LicenseUsageSummary from 'features/license/components/LicenseUsageSummary';
import LicenseUsageTable from 'features/license/components/LicenseUsageTable';
import LicenseUsageToolBar from 'features/license/components/LicenseUsageToolBar';
import { setInitializeLicenseUsageData } from 'features/license/context/licenseSlice';
import { getLicenseDetailThunk } from 'features/license/thunk/getLicenseDetail.thunk';
import { getLicenseUsageThunk } from 'features/license/thunk/getLicenseUsage.thunk';

export default function LicenseUsage() {
	const dispatch = useAppDispatch();

	const { licenseId } = useParams();

	const { searchStartDate, searchEndDate, selectedPage } = useSelector((state: RootState) => state.license.usage);

	useLayoutEffect(() => {
		dispatch(setInitializeLicenseUsageData(Number(licenseId)));
		dispatch(getLicenseDetailThunk({ licenseId: Number(licenseId) }));
	}, [dispatch, licenseId]);

	useEffect(() => {
		dispatch(getLicenseUsageThunk());
	}, [dispatch, searchEndDate, searchStartDate, selectedPage]);

	return (
		<div css={usageContainerCss}>
			<LicenseUsageSummary />
			<LicenseUsageToolBar />
			<LicenseUsageTable />
		</div>
	);
}

const usageContainerCss = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnChartData } from 'features/nfEUM/hooks/useTXNColumnChartOptions';
import moment from 'moment';
import { columnChartInitData } from 'features/nfEUM/data/initialData';
import { getTXNTodayThunk } from 'features/nfEUM/thunk/getTXNToday.thunk';
import { columnChartAnnotationFn, columnChartTotalCountFn } from 'features/nfEUM/utils/chartFunc';

export interface TXNTodayState {
	data: { web: ColumnChartData[]; native: ColumnChartData[] };
	annotation: ColumnChartData;
	totalCount: number;
}

export interface TXNIntervalTime {
	beginTime: number;
	endTime: number;
}

export interface TXNTodaySliceData extends TXNIntervalTime {
	hasError: boolean;
	isLoaded: boolean;
	load: TXNTodayState;
	visit: TXNTodayState;
}

const initialState: TXNTodaySliceData = {
	hasError: false,
	isLoaded: false,
	beginTime: 0,
	endTime: 0,
	load: {
		data: { web: columnChartInitData, native: columnChartInitData },
		annotation: {
			x: '',
			y: 40000,
		},
		totalCount: 0,
	},
	visit: {
		data: { web: columnChartInitData, native: columnChartInitData },
		annotation: {
			x: '',
			y: 40000,
		},
		totalCount: 0,
	},
};

const eumTXNTodaySlice = createSlice({
	name: 'eumTXNToday',
	initialState,
	reducers: {
		setTodayIntervalTime: (state, action: PayloadAction<TXNIntervalTime>) => {
			const { payload } = action;
			return { ...state, beginTime: payload.beginTime, endTime: payload.endTime };
		},
		resetTodayData: () => {
			return { ...initialState };
		},
	},
	extraReducers: builder => {
		builder.addCase(getTXNTodayThunk.fulfilled, (state, action) => {
			const { payload } = action;

			// loadDataWeb
			let loadDataWeb: ColumnChartData[] = state.load.data.web;
			if (payload.loadData.webCall.length > 0) {
				loadDataWeb = payload.loadData.webCall.map((el, i) => {
					const stateX: string = state.load.data.web[i].x;
					const dataX: string = moment.unix(el.time).format('HH');
					const stateY: number = state.load.data.web[i].y;
					if (stateX === dataX) {
						return { x: stateX, y: el.value };
					}
					return { x: stateX, y: stateY };
				});
			}

			// loadDataNative
			let loadDataNative: ColumnChartData[] = state.load.data.native;
			if (payload.loadData.nativeCall.length > 0) {
				loadDataNative = payload.loadData.nativeCall.map((el, i) => {
					const stateX: string = state.load.data.native[i].x;
					const dataX: string = moment.unix(el.time).format('HH');
					const stateY: number = state.load.data.native[i].y;
					if (stateX === dataX) {
						return { x: stateX, y: el.value };
					}
					return { x: stateX, y: stateY };
				});
			}

			// loadEtc
			const loadAnnotation: ColumnChartData = columnChartAnnotationFn(loadDataWeb, loadDataNative);
			const loadTotalCount: number = columnChartTotalCountFn(loadDataWeb, loadDataNative);

			// visitDataWeb
			let visitDataWeb: ColumnChartData[] = state.visit.data.web;
			if (payload.visitData.webVisitors.length > 0) {
				visitDataWeb = payload.visitData.webVisitors.map((el, i) => {
					const stateX: string = state.visit.data.web[i].x;
					const dataX: string = moment.unix(el.time).format('HH');
					const stateY: number = state.visit.data.web[i].y;
					if (stateX === dataX) {
						return { x: stateX, y: el.value };
					}
					return { x: stateX, y: stateY };
				});
			}

			// visitDataNative
			let visitDataNative: ColumnChartData[] = state.visit.data.native;
			if (payload.visitData.nativeVisitors.length > 0) {
				visitDataNative = payload.visitData.nativeVisitors.map((el, i) => {
					const stateX: string = state.visit.data.native[i].x;
					const dataX: string = moment.unix(el.time).format('HH');
					const stateY: number = state.visit.data.native[i].y;
					if (stateX === dataX) {
						return { x: stateX, y: el.value };
					}
					return { x: stateX, y: stateY };
				});
			}

			// visitEtc
			const visitAnnotation: ColumnChartData = columnChartAnnotationFn(visitDataWeb, visitDataNative);
			const visitTotalCount: number = columnChartTotalCountFn(visitDataWeb, visitDataNative);

			return {
				...state,
				isLoaded: true,
				hasError: false,
				load: {
					...state.load,
					data: {
						web: [...loadDataWeb, ...state.load.data.web.slice(loadDataWeb.length, 24)],
						native: [...loadDataNative, ...state.load.data.native.slice(loadDataNative.length, 24)],
					},
					annotation: loadAnnotation,
					totalCount: loadTotalCount,
				},
				visit: {
					...state.visit,
					data: {
						web: [...visitDataWeb, ...state.visit.data.web.slice(visitDataWeb.length, 24)],
						native: [...visitDataNative, ...state.visit.data.native.slice(visitDataNative.length, 24)],
					},
					annotation: visitAnnotation,
					totalCount: visitTotalCount,
				},
			};
		});
	},
});

export const { setTodayIntervalTime, resetTodayData } = eumTXNTodaySlice.actions;

export default eumTXNTodaySlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTXNMetricsThunk } from 'features/nfEUM/thunk/getTXNMetrics.thunk';
import { GetEUMMetricsApiData } from 'features/nfEUM/types/getEUMMetrics.type';

export type MetricsSortColumn = 'host' | 'uri' | 'load' | 'loadTime' | 'category';

export type MetricsSortTypeValue = 'asc' | 'desc';

export interface MetricsSortType {
	host: MetricsSortTypeValue;
	uri: MetricsSortTypeValue;
	load: MetricsSortTypeValue;
	loadTime: MetricsSortTypeValue;
	category: MetricsSortTypeValue;
}

export interface TXNIntervalTime {
	beginTime: number;
	endTime: number;
}

export interface TXNMetricSliceData extends TXNIntervalTime {
	hasError: boolean;
	isLoaded: boolean;
	searchValue: string;
	sortColumn: MetricsSortColumn;
	sortType: MetricsSortType;
	metricsData: GetEUMMetricsApiData[];
	filteredMetricsData: GetEUMMetricsApiData[];
	loadSum: number;
	loadTimeAvg: string;
	maxLoad: number;
	maxLoadTime: number;
}

const initialState: TXNMetricSliceData = {
	hasError: false,
	isLoaded: false,
	beginTime: 0,
	endTime: 0,
	searchValue: '',
	sortColumn: 'host',
	sortType: { host: 'desc', uri: 'desc', load: 'desc', loadTime: 'desc', category: 'desc' },
	metricsData: [],
	filteredMetricsData: [],
	loadSum: 0,
	loadTimeAvg: '',
	maxLoad: 0,
	maxLoadTime: 0,
};

const eumTXNMetricsSlice = createSlice({
	name: 'eumTXNMetrics',
	initialState,
	reducers: {
		setMetricsSearchValue: (state, action: PayloadAction<string>) => {
			return {
				...state,
				searchValue: action.payload,
				filteredMetricsData: state.metricsData.filter(el => el.uri.includes(action.payload)),
			};
		},
		setMetricsIntervalTime: (state, action: PayloadAction<TXNIntervalTime>) => {
			const { payload } = action;
			return { ...state, beginTime: payload.beginTime, endTime: payload.endTime };
		},
		resetMetricsData: () => {
			return { ...initialState };
		},
		setMetricsSort: (state, action: PayloadAction<MetricsSortColumn>) => {
			const { payload } = action;
			switch (payload) {
				case 'host':
					return {
						...state,
						sortColumn: payload,
						sortType: {
							host: state.sortType.host === 'desc' ? 'asc' : 'desc',
							uri: 'desc',
							load: 'desc',
							loadTime: 'desc',
							category: 'desc',
						},
					};
				case 'uri':
					return {
						...state,
						sortColumn: payload,
						sortType: {
							host: 'desc',
							uri: state.sortType.uri === 'desc' ? 'asc' : 'desc',
							load: 'desc',
							loadTime: 'desc',
							category: 'desc',
						},
					};
				case 'load':
					return {
						...state,
						sortColumn: payload,
						sortType: {
							host: 'desc',
							uri: 'desc',
							load: state.sortType.load === 'desc' ? 'asc' : 'desc',
							loadTime: 'desc',
							category: 'desc',
						},
					};
				case 'loadTime':
					return {
						...state,
						sortColumn: payload,
						sortType: {
							host: 'desc',
							uri: 'desc',
							load: 'desc',
							loadTime: state.sortType.loadTime === 'desc' ? 'asc' : 'desc',
							category: 'desc',
						},
					};
				case 'category':
					return {
						...state,
						sortColumn: payload,
						sortType: {
							host: 'desc',
							uri: 'desc',
							load: 'desc',
							loadTime: 'desc',
							category: state.sortType.category === 'desc' ? 'asc' : 'desc',
						},
					};
				default:
					return { ...state };
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(getTXNMetricsThunk.fulfilled, (state, action) => {
			const { payload } = action;

			const filteredMetricsData = payload.metricsData.filter(el => el.uri.includes(state.searchValue));

			let loadSum = 0;
			filteredMetricsData.forEach(el => {
				loadSum += el.count;
			});

			let maxLoad = 0;
			payload.metricsData.forEach(el => {
				if (el.count > maxLoad) maxLoad = el.count;
			});

			let maxLoadTime = 0;
			payload.metricsData.forEach(el => {
				if (el.pt > maxLoadTime) maxLoadTime = el.pt;
			});

			let loadTimeSum = 0;
			filteredMetricsData.forEach(el => {
				loadTimeSum += el.pt;
			});

			return {
				...state,
				hasError: false,
				isLoaded: true,
				metricsData: payload.metricsData,
				filteredMetricsData,
				loadSum,
				loadTimeAvg: filteredMetricsData.length === 0 ? '0' : (loadTimeSum / filteredMetricsData.length).toFixed(0),
				maxLoad,
				maxLoadTime,
			};
		});
		builder.addCase(getTXNMetricsThunk.rejected, state => {
			return {
				...state,
				hasError: true,
				isLoaded: true,
				metricsData: [],
				filteredMetricsData: [],
				loadSum: 0,
				maxLoad: 0,
				maxLoadTime: 0,
			};
		});
	},
});

export const { setMetricsSearchValue, setMetricsIntervalTime, resetMetricsData, setMetricsSort } =
	eumTXNMetricsSlice.actions;
export default eumTXNMetricsSlice.reducer;

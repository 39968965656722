import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { postEUMIntervalUpdateThunk } from 'features/nfEUM/thunk/postEUMIntervalUpdate.thunk';
import { postEUMIntervalStartThunk } from 'features/nfEUM/thunk/postEUMIntervalStart.thunk';

import type { PostEUMSummaryApiData } from 'features/nfEUM/types/postEUMSummary.type';
import type { PostEUMUriStatusApiData } from 'features/nfEUM/types/postEUMUriStatus.type';
import type { ChartData } from 'features/nfBasic/context/monitoringSlice';

import { X_AXIS_NUM } from 'features/nfEUM/data/constants';
import moment from 'moment';
import { postEUMStepDateThunk } from 'features/nfEUM/thunk/postEUMStepDate.thunk';
import { postEUMSelectDateThunk } from 'features/nfEUM/thunk/postEUMSelectDate.thunk';

interface EUMSliceData {
	hasError: boolean;
	isLoaded: boolean;
	selectedDate: number;
	initialDate: number;
	summary: PostEUMSummaryApiData;
	uriStatus: PostEUMUriStatusApiData[];
	isIntervalActivated: boolean;
	networkStatus: {
		isAnimate: boolean;
		maxProcessTime: number;
		maxCount: number;
		processTimeY: ChartData[];
		countY: ChartData[];
	};
}

const initialState: EUMSliceData = (() => {
	return {
		hasError: false,
		isLoaded: false,
		selectedDate: 0,
		initialDate: 0,
		isIntervalActivated: true,
		summary: {
			overall_uri_data: {
				max_count: 0,
				max_pt: 0,
				avg_count: 0,
				avg_pt: 0,
			},
			seg_uri_data: {
				max_count: 0,
				max_pt: 0,
				avg_count: 0,
				avg_pt: 0,
			},
		},
		uriStatus: [],
		networkStatus: {
			isAnimate: false,
			maxProcessTime: 0,
			maxCount: 0,
			processTimeY: [],
			countY: [],
		},
	};
})();

const eumSlice = createSlice({
	name: 'eum',
	initialState,
	reducers: {
		cleanupEUMChartData: state => {
			return {
				...state,
				networkStatus: {
					...state.networkStatus,
					processTimeY: state.networkStatus.processTimeY.slice(X_AXIS_NUM - 4),
					countY: state.networkStatus.countY.slice(X_AXIS_NUM - 4),
					isAnimate: false,
				},
			};
		},

		resetEUMData: () => {
			return { ...initialState };
		},

		initializeState: (state, action: PayloadAction<number>) => {
			return { ...state, selectedDate: action.payload, initialDate: action.payload };
		},

		increaseSelectedDate: state => {
			return {
				...state,
				selectedDate: Number(moment(state.selectedDate).add(1, 'day').valueOf()),
				isIntervalActivated: false,
			};
		},

		decreaseSelectedDate: state => {
			return {
				...state,
				selectedDate: Number(moment(state.selectedDate).subtract(1, 'day').valueOf()),
				isIntervalActivated: false,
			};
		},

		activateInterval: state => {
			return { ...state, selectedDate: moment().valueOf(), isIntervalActivated: true };
		},

		setSelectedDate: (state, action: PayloadAction<number>) => {
			return { ...state, selectedDate: action.payload, isIntervalActivated: false };
		},

		setInitialDate: (state, action: PayloadAction<number>) => {
			return { ...state, initialDate: action.payload };
		},
	},
	extraReducers: builder => {
		builder.addCase(postEUMIntervalStartThunk.fulfilled, (state, action) => {
			return {
				...state,
				hasError: false,
				isLoaded: true,
				summary: { ...action.payload.summaryData },
				uriStatus: action.payload.uriStatusData.slice(),
				isIntervalActivated: true,
				networkStatus: {
					isAnimate: true,
					maxCount: Math.max(...action.payload.countY.map(el => el.y || 0)) * 1.2,
					maxProcessTime: Math.max(...action.payload.processTimeY.map(el => el.y || 0)) * 1.2,
					processTimeY: action.payload.processTimeY.slice(),
					countY: action.payload.countY.slice(),
				},
			};
		});

		builder.addCase(postEUMIntervalStartThunk.rejected, state => {
			return { ...state, hasError: true, isLoaded: true };
		});

		builder.addCase(postEUMIntervalUpdateThunk.fulfilled, (state, action) => {
			const { networkStatData } = action.payload;
			const processTimeY =
				networkStatData.length !== 0
					? [{ x: networkStatData[0].ts, y: Number((networkStatData[0].avg_pt / 1000).toFixed(1)) }]
					: [{ x: state.networkStatus.processTimeY[state.networkStatus.processTimeY.length - 1].x + 60, y: 0 }];

			const countY =
				networkStatData.length !== 0
					? [{ x: networkStatData[0].ts, y: networkStatData[0].sum_count }]
					: [{ x: state.networkStatus.countY[state.networkStatus.countY.length - 1].x + 60, y: 0 }];

			return {
				...state,
				hasError: false,
				selectedDate: action.payload.newSelectedDate,
				initialDate: action.payload.newSelectedDate,
				networkStatus: {
					isAnimate: true,
					processTimeY: state.networkStatus.processTimeY.concat(processTimeY),
					countY: state.networkStatus.countY.concat(countY),
					maxCount:
						Math.max(
							Math.max(...state.networkStatus.countY.slice(-X_AXIS_NUM).map(maxCountEl => maxCountEl.y || 0)),
							Math.max(...countY.map(el => el.y))
						) * 1.2,
					maxProcessTime:
						Math.max(
							...state.networkStatus.processTimeY.slice(-X_AXIS_NUM).map(maxProcessTimeEl => maxProcessTimeEl.y || 0),
							Math.max(...processTimeY.map(el => el.y))
						) * 1.2,
				},
				summary: { ...action.payload.summaryData },
				uriStatus: action.payload.uriStatusData.slice(),
			};
		});

		builder.addCase(postEUMIntervalUpdateThunk.rejected, state => {
			return {
				...state,
				hasError: true,
				selectedDate: moment(state.selectedDate).add(1, 'minute').valueOf(),
			};
		});

		builder.addCase(postEUMStepDateThunk.fulfilled, (state, action) => {
			return {
				...state,
				hasError: false,
				isLoaded: true,
				isIntervalActivated: false,
				summary: { ...action.payload.summaryData },
				uriStatus: action.payload.uriStatusData.slice(),
				selectedDate: action.payload.newSelectedDate,
				networkStatus: {
					isAnimate: true,
					maxCount: Math.max(...action.payload.countY.map(el => el.y || 0)) * 1.2,
					maxProcessTime: Math.max(...action.payload.processTimeY.map(el => el.y || 0)) * 1.2,
					processTimeY: action.payload.processTimeY.slice(),
					countY: action.payload.countY.slice(),
				},
			};
		});

		builder.addCase(postEUMStepDateThunk.rejected, state => {
			return { ...state, hasError: true, isLoaded: true, isIntervalActivated: false };
		});

		builder.addCase(postEUMSelectDateThunk.fulfilled, (state, action) => {
			return {
				...state,
				hasError: false,
				isLoaded: true,
				isIntervalActivated: false,
				selectedDate: action.payload.newSelectedDate,
				summary: { ...action.payload.summaryData },
				uriStatus: action.payload.uriStatusData.slice(),
				networkStatus: {
					isAnimate: true,
					maxCount: Math.max(...action.payload.countY.map(el => el.y || 0)) * 1.2,
					maxProcessTime: Math.max(...action.payload.processTimeY.map(el => el.y || 0)) * 1.2,
					processTimeY: action.payload.processTimeY.slice(),
					countY: action.payload.countY.slice(),
				},
			};
		});

		builder.addCase(postEUMSelectDateThunk.rejected, state => {
			return { ...state, hasError: true, isLoaded: true, isIntervalActivated: false };
		});
	},
});

export const {
	cleanupEUMChartData,
	increaseSelectedDate,
	decreaseSelectedDate,
	resetEUMData,
	activateInterval,
	setSelectedDate,
	initializeState,
	setInitialDate,
} = eumSlice.actions;

export default eumSlice.reducer;

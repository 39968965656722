import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import type { PostIntervalSegmentApiResponse } from '../types/postIntervalSegment.type';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 세그먼트 추가 api
 * todo: tenant 별 db가 생기면 주석된 주소로 변경 필요
 */

export interface PostIntervalSegmentApiBody {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: number;
	startUri: string;
	endUri: string;
}

export interface PostSegmentApiProps {
	tenantUrl: string;
	tenantId: string;
	organizationId: number;
	prjId: number;
	body: PostIntervalSegmentApiBody;
}

export default async function postIntervalSegmentApi({
	tenantUrl,
	tenantId,
	organizationId,
	prjId,
	body,
}: PostSegmentApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.post<PostIntervalSegmentApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${prjId}/section-segment`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

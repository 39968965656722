/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { backgroundPalette, Button, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { changeEnterpriseAskModalVisible } from 'common/context/modalSlice';
import { NETFUNNEL, PLAN_ENTERPRISE_ID, PLAN_LARGE_ID, PLAN_MEDIUM_ID } from 'common/data/constants';

import type { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';
import PlanFeatureDetail from 'features/plan/components/PlanFeatureDetail';
import type { PlanInfo } from 'features/plan/types/getPlanList.type';
import type { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';

export interface PlanFeatureCardProps {
	selectedPlan: PlanInfo;
}

export default function PlanFeatureCard({ selectedPlan }: PlanFeatureCardProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { language } = useSelector((state: RootState) => state.account);
	const { planLangs, planId } = useSelector((state: RootState) => state.plan);
	const { products } = useSelector((state: RootState) => state.subscription);

	const selectedPlanLang = selectedPlan.planLangs.find(item => item.langType === language) as PlanLangs;

	const userPlanLang = planLangs.find(el => el.langType === language) as PlanLangs;

	const netFunnelSubscriptionInfo = products.find(el => el.productType === NETFUNNEL) as SubscriptionData;

	let planColor = '#13a1f6';
	let buttonColor = '#13a1f6';
	//
	let buttonLabel = 'planSubscription.startPlan';

	if (selectedPlan.planId === PLAN_MEDIUM_ID) {
		planColor = '#4a70ff';
		buttonColor = '#4a70ff';
	}

	if (selectedPlan.planId === PLAN_LARGE_ID) {
		planColor = '#4046ca';
		buttonColor = '#4046ca';
	}

	if (selectedPlan.planId === PLAN_ENTERPRISE_ID) {
		({ 800: planColor, 400: buttonColor } = paletteSDS.blue);
		buttonLabel = 'planSubscription.talkToSales';
	}

	const talkToSalesClickHandler = () => {
		dispatch(changeEnterpriseAskModalVisible(t('modal.talkToSalesTitle')));
	};

	const planColorCss = css`
		color: ${planColor};
	`;

	const regularCss = [themeCss.fontSize[13], themeCss.fontWeight.regular, colorPalette.gray[800]];

	const boldCss = [themeCss.fontSize[16], themeCss.fontWeight.bold, colorPalette.gray[900]];

	return (
		<div css={cardContainerSt}>
			<div css={planStatusWrapSt}>
				<div css={currentPlanWrapSt}>
					<div css={regularCss}>{t('planSubscription.currentPlan')}</div>
					<div css={boldCss}>{userPlanLang.name}</div>
				</div>
				<div css={selectedPlanWrapSt}>
					<div css={regularCss}>{t('planSubscription.choosePlan')}</div>
					<div css={[planColorCss, themeCss.fontWeight.bold, selectedPlanSt]}>{selectedPlanLang.name}</div>
				</div>
			</div>
			<div css={[featureWrapSt, selectedPlan.planId !== -1 ? backgroundPalette.gray[50] : backgroundPalette.blue[800]]}>
				<div
					css={[
						selectedPlan.planId !== -1 ? colorPalette.gray[900] : colorPalette.gray[50],
						themeCss.fontWeight.bold,
						themeCss.fontSize[16],
					]}
				>
					{t('planSubscription.features')}
				</div>
				{selectedPlan.planId !== PLAN_ENTERPRISE_ID ? (
					<PlanFeatureDetail featureData={selectedPlan.planOptions} />
				) : (
					<div css={[enterpriseContentSt, colorPalette.gray[50]]}>{t('planSubscription.talkToSalesBody')}</div>
				)}
				{selectedPlan.planId === PLAN_ENTERPRISE_ID ? (
					<Button
						width="100%"
						mould="custom"
						buttonColor={buttonColor}
						buttonHoverColor={paletteSDS.blue[800]}
						labelColor={paletteSDS.gray[50]}
						size="large"
						type={selectedPlan.planId !== PLAN_ENTERPRISE_ID ? 'submit' : 'button'}
						onClick={selectedPlan.planId === PLAN_ENTERPRISE_ID ? talkToSalesClickHandler : () => {}}
						disabled={netFunnelSubscriptionInfo.status === 'SUBSCRIBING' && planId === selectedPlan.planId}
					>
						{t(buttonLabel)}
					</Button>
				) : null}
			</div>
		</div>
	);
}

const cardContainerSt = css`
	display: flex;
	flex-direction: column;
	gap: 0px;

	width: 19.1875rem;

	border-radius: 8px;

	background: ${paletteSDS.gray['75']};
`;

const planStatusWrapSt = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	height: 7.6875rem;

	padding: 1.5rem;

	border: 1px solid ${paletteSDS.gray[300]};

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	background: ${paletteSDS.white};
`;

const featureWrapSt = css`
	height: 20.1875rem;

	padding: 1.5rem;

	border-left: 1px solid ${paletteSDS.gray[300]};
	border-right: 1px solid ${paletteSDS.gray[300]};
	border-bottom: 1px solid ${paletteSDS.gray[300]};

	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

const currentPlanWrapSt = css`
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 1.25rem;
`;

const selectedPlanWrapSt = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 3.4375rem;
`;

const selectedPlanSt = css`
	font-size: 1.75rem;

	text-align: right;
	letter-spacing: -0.008em;
`;

const enterpriseContentSt = css`
	display: flex;
	justify-content: center;
	align-items: center;

	height: 206px;

	text-align: center;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useAppDispatch } from 'common/redux/store';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { paletteSDS } from 'surf-design-system';
import { resetShareData, setShareIntervalTime } from 'features/nfEUM/context/eumTXNShareSlice';
import { getTXNShareThunk } from 'features/nfEUM/thunk/getTXNShare.thunk';
import TXNShareChart from 'features/nfEUM/components/TXNShareChart';

let intervalTimer: NodeJS.Timer | undefined;

export default function TXNShare() {
	const dispatch = useAppDispatch();
	const [currIntervalSec, setCurrIntervalSec] = useState(false);

	useEffect(() => {
		clearInterval(intervalTimer);

		intervalTimer = setInterval(async () => {
			if (moment().seconds() % 3 === 0 && !currIntervalSec) {
				setCurrIntervalSec(true);

				dispatch(
					setShareIntervalTime({
						beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
						endTime: moment().subtract(7, 's').unix(),
					})
				);
				dispatch(getTXNShareThunk());
			} else if (moment().seconds() % 3 !== 0) setCurrIntervalSec(false);
		}, 300);
	}, [currIntervalSec, dispatch]);

	useLayoutEffect(() => {
		dispatch(
			setShareIntervalTime({
				beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
				endTime: moment().subtract(7, 's').unix(),
			})
		);
		dispatch(getTXNShareThunk());

		return () => {
			clearInterval(intervalTimer);
			dispatch(resetShareData());
		};
	}, [dispatch]);
	return (
		<div css={donutChart}>
			<TXNShareChart />
		</div>
	);
}

const donutChart = css`
	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 4px;
	width: 466px;
	height: 662px;
	padding: 24px;
	background-color: ${paletteSDS.white};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import React, { forwardRef } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/react';

import aPlaceholder from 'common/assets/images/A.png';
import bPlaceholder from 'common/assets/images/B.png';
import cPlaceholder from 'common/assets/images/C.png';
import dPlaceholder from 'common/assets/images/D.png';
import ePlaceholder from 'common/assets/images/E.png';
import fPlaceholder from 'common/assets/images/F.png';
import gPlaceholder from 'common/assets/images/G.png';
import hPlaceholder from 'common/assets/images/H.png';
import iPlaceholder from 'common/assets/images/I.png';
import jPlaceholder from 'common/assets/images/J.png';
import kPlaceholder from 'common/assets/images/K.png';
import lPlaceholder from 'common/assets/images/L.png';
import mPlaceholder from 'common/assets/images/M.png';
import nPlaceholder from 'common/assets/images/N.png';
import oPlaceholder from 'common/assets/images/O.png';
import pPlaceholder from 'common/assets/images/P.png';
import qPlaceholder from 'common/assets/images/Q.png';
import rPlaceholder from 'common/assets/images/R.png';
import sPlaceholder from 'common/assets/images/S.png';
import tPlaceholder from 'common/assets/images/T.png';
import uPlaceholder from 'common/assets/images/U.png';
import vPlaceholder from 'common/assets/images/V.png';
import wPlaceholder from 'common/assets/images/W.png';
import xPlaceholder from 'common/assets/images/X.png';
import yPlaceholder from 'common/assets/images/Y.png';
import zPlaceholder from 'common/assets/images/Z.png';
import dummySrc from 'common/assets/images/dummy.png';

export type AvatarSize = 'xLarge' | 'large' | 'medium' | 'small';

export interface AvatarProps {
	imgUrl?: string;
	width?: string;
	height?: string;
	border?: string;
	borderRadius?: string;
	disable?: boolean;
	placeholder?: string;
	size?: AvatarSize;
	alt?: string;
	onClick?: React.MouseEventHandler<HTMLImageElement>;
	emotionCss?: SerializedStyles | SerializedStyles[];
}

const placeholderUrl = (placeholder: string | undefined) => {
	switch (placeholder) {
		case 'A':
			return aPlaceholder;
		case 'B':
			return bPlaceholder;
		case 'C':
			return cPlaceholder;
		case 'D':
			return dPlaceholder;
		case 'E':
			return ePlaceholder;
		case 'F':
			return fPlaceholder;
		case 'G':
			return gPlaceholder;
		case 'H':
			return hPlaceholder;
		case 'I':
			return iPlaceholder;
		case 'J':
			return jPlaceholder;
		case 'K':
			return kPlaceholder;
		case 'L':
			return lPlaceholder;
		case 'M':
			return mPlaceholder;
		case 'N':
			return nPlaceholder;
		case 'O':
			return oPlaceholder;
		case 'P':
			return pPlaceholder;
		case 'Q':
			return qPlaceholder;
		case 'R':
			return rPlaceholder;
		case 'S':
			return sPlaceholder;
		case 'T':
			return tPlaceholder;
		case 'U':
			return uPlaceholder;
		case 'V':
			return vPlaceholder;
		case 'W':
			return wPlaceholder;
		case 'X':
			return xPlaceholder;
		case 'Y':
			return yPlaceholder;
		case 'Z':
			return zPlaceholder;
		default:
			return dummySrc;
	}
};

/**
 * author: ian
 * createdAt: 2022-07-24
 * updatedAt: 2022-07-26
 * description: 프로필 사진에 사용되는 아바타
 */
const Avatar = forwardRef<HTMLImageElement, AvatarProps>(
	(
		{
			width,
			height,
			border,
			borderRadius = '50%',
			disable = false,
			placeholder,
			imgUrl = placeholderUrl(placeholder),
			size = 'small',
			alt = 'avatar',
			onClick,
			emotionCss,
		},
		ref
	) => {
		const avatarCss = [avatarSize[size], avatarSt(width, height, border, borderRadius, disable), emotionCss];

		const handleError: React.ReactEventHandler<HTMLImageElement> = e => {
			e.currentTarget.src = dummySrc;
		};

		return (
			<React.Fragment>
				<img css={avatarCss} src={imgUrl} alt={alt} onError={handleError} onClick={onClick} aria-hidden ref={ref} />
			</React.Fragment>
		);
	}
);

export default Avatar;

const avatarSt = (
	width: string | undefined,
	height: string | undefined,
	border: string | undefined,
	borderRadius: string | undefined,
	disable: boolean
) => css`
	width: ${width};
	height: ${height};

	border: ${border};
	border-radius: ${borderRadius};

	object-fit: cover;
	object-position: 100% 0;

	opacity: ${disable && '0.4'};
`;

const avatarSize = {
	xLarge: css`
		width: 4rem;
		height: 4rem;
	`,
	large: css`
		width: 3rem;
		height: 3rem;
	`,
	medium: css`
		width: 2.5rem;
		height: 2.5rem;
	`,
	small: css`
		width: 2rem;
		height: 2rem;
	`,
};

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import moment from 'moment';

import { themeSDS, paletteSDS } from 'surf-design-system';

export default function OnPremFooter() {
	return <footer css={containerSt}>{moment().utcOffset('+09:00').year()}&nbsp;© STCLab Inc.</footer>;
}

const containerSt = css`
	flex: none;

	color: ${paletteSDS.gray[500]};
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize['13']};
	line-height: 160%;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import { toggleIsVWRPutConfirmModalVisible } from 'common/context/modalSlice';
import { toggleIsVWRUpdatedToastVisible, toggleIsVWRUpdateFailToastVisible } from 'common/context/toastSlice';

import { defaultLogoImageBase64 } from 'features/nfBasic/data/defaultImageBase64';
import { putVWRDataApi } from 'features/vwr/api/putVWRData.v2.api';
import { convertUrlToImgFile } from 'features/nfBasic/utils/fileFunc';
import type { VWRElement } from 'features/vwr/context/vwrSlice.v2';

interface PutVWRDataThunkResult extends VWRElement {
	name: string;
}

export const putVWRDataThunk = createAsyncThunk<PutVWRDataThunkResult, void, { state: RootState }>(
	'vwr/put/v2',
	async (_, thunkAPI) => {
		const {
			account: { tenantId, organizationId, language },
			projectInfo: { selectedProjectId },
			modal: {
				vwr: { id, name: enteredVwrPageName },
			},
		} = thunkAPI.getState();

		const {
			isFollowingWaitYnChanged,
			isGuideTextChanged,
			isImagePathChanged,
			isLogoImagePathChanged,
			isTitleChanged,
			isWaitCancelYnChanged,
			logoImagePathName,
			logoImagePathSrc,
			title,
			followingWaitYn,
			guideText,
			imagePathName,
			imagePathSrc,
			waitCancelYn,
			waitTimeDisplayYn,
			isWaitTimeDisplayYnChanged,
		} = thunkAPI.getState().vwr2.form;

		const followingWaitYnData = isFollowingWaitYnChanged ? followingWaitYn : undefined;
		const guideTextData = isGuideTextChanged ? guideText : undefined;
		const imagePathData = isImagePathChanged ? await convertUrlToImgFile(imagePathSrc, imagePathName) : undefined;
		const logoImagePathData = isLogoImagePathChanged
			? await convertUrlToImgFile(logoImagePathSrc, logoImagePathName)
			: undefined;
		const titleData = isTitleChanged ? title : undefined;
		const waitCancelYnData = isWaitCancelYnChanged ? waitCancelYn : undefined;
		const waitTimeDisplayYnData = isWaitTimeDisplayYnChanged ? waitTimeDisplayYn : undefined;

		try {
			const {
				data: {
					imagePath,
					logoImagePath,
					logoImageFileName,
					imageFileName,
					title: responseTitle,
					guideText: responseGuideText,
					followingWaitYn: responseFollowingWaitYn,
					waitCancelYn: responseWaitCancelYn,
					waitTimeDisplayYn: responseWaitTimeDisplayYn,
					name,
				},
			} = await putVWRDataApi({
				logoImageFile: logoImagePathData,
				followingWaitYn: followingWaitYnData,
				guideText: guideTextData,
				waitCancelYn: waitCancelYnData,
				tenantId: tenantId as string,
				projectId: selectedProjectId,
				coverImageFile: imagePathData,
				title: titleData,
				organizationId,
				waitTimeDisplayYn: waitTimeDisplayYnData,
				id,
				name: enteredVwrPageName,
			});

			thunkAPI.dispatch(toggleIsVWRPutConfirmModalVisible());
			thunkAPI.dispatch(toggleIsVWRUpdatedToastVisible());

			let editedResponseTitle = responseTitle;

			if (title === '' && language === 'ENGLISH') {
				editedResponseTitle = 'Waiting Needed for Online Access';
			}

			if (title === '' && language === 'KOREAN') {
				editedResponseTitle = '온라인 접속 대기 안내';
			}

			return {
				imagePathSrc: imagePath !== '' ? `${imagePath}?${Date.now()}` : '',
				imagePathName: imageFileName,
				logoImagePathSrc: logoImagePath !== '' ? `${logoImagePath}?${Date.now()}` : defaultLogoImageBase64,
				logoImagePathName: logoImageFileName,
				title: editedResponseTitle,
				guideText: responseGuideText,
				waitCancelYn: responseWaitCancelYn,
				followingWaitYn: responseFollowingWaitYn,
				waitTimeDisplayYn: responseWaitTimeDisplayYn,
				name,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			thunkAPI.dispatch(toggleIsVWRPutConfirmModalVisible());
			thunkAPI.dispatch(toggleIsVWRUpdateFailToastVisible());

			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

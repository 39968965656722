import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import i18next from 'i18next';

import { isAxiosError } from 'common/api/isAxiosError';

import {
	NonKoreaCompanyAddress,
	PostOrganizationPaymentInfoApiError,
} from 'features/plan/types/postOrganizationPaymentInfo.type';
import { toggleIsPaymentErrorModalVisible } from 'common/context/modalSlice';
import { toggleIsBillingInfoChangeToastVisible } from 'common/context/toastSlice';

import postOrganizationPaymentInfoApi from '../api/postOrganizationPaymentInfo.api';

export interface PostOrganizationPaymentInfoNonKoreanThunkProps {
	cardNumber: string;
	expirateDate: string;
	cvc: string;
	address: NonKoreaCompanyAddress;
	onSuccess: () => void;
}

export interface PostOrganizationPaymentInfoNonKoreanThunkResult {
	id: string;
	name: string;
	number: number;
	address: NonKoreaCompanyAddress;
}

export const postOrganizationPaymentInfoNonKoreanThunk = createAsyncThunk<
	PostOrganizationPaymentInfoNonKoreanThunkResult,
	PostOrganizationPaymentInfoNonKoreanThunkProps,
	{ state: RootState }
>('payment/post/non-korean', async (args, thunkAPI) => {
	const {
		account: { organizationId },
	} = thunkAPI.getState();

	const { t } = i18next;

	const { cardNumber, expirateDate, cvc, address, onSuccess } = args;

	try {
		const { data } = await postOrganizationPaymentInfoApi({
			organizationId,
			cardNumber: cardNumber.replaceAll(' ', ''),
			cvc,
			expirateDate: expirateDate.replaceAll('-', ''),
			address,
		});

		thunkAPI.dispatch(toggleIsBillingInfoChangeToastVisible());
		onSuccess();

		return {
			id: data.id,
			number: data.number,
			name: data.name,
			address: { ...(data.address as NonKoreaCompanyAddress) },
		};
		// eslint-disable-next-line
	} catch (error: any) {
		if (isAxiosError<PostOrganizationPaymentInfoApiError>(error) && error.response?.data.errorCode === 'IM1000') {
			thunkAPI.dispatch(
				toggleIsPaymentErrorModalVisible({
					title: t('modal.pymtMethodError'),
					body: `${t('modal.pymtMethodErrorMsg1')}\n${t('modal.pymtMethodErrorMsg2')}`,
				})
			);
		}

		if (isAxiosError<PostOrganizationPaymentInfoApiError>(error) && error.response?.data.errorCode === 'CA1000') {
			thunkAPI.dispatch(
				toggleIsPaymentErrorModalVisible({ title: t('modal.debitError'), body: t('modal.debitNotAccepted') })
			);
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS, themeSDS } from 'surf-design-system';
import { RootState, useAppDispatch } from 'common/redux/store';
import { getOrganizationDetailThunk } from 'common/thunk/getOrganizationDetail.thunk';
import { getI18nPath } from 'common/utils/languageFunc';

export default function InstallAgentLayout() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();

	const { pathname } = location;

	let titleTextKey = 'agent.installWeb';

	if (pathname.includes('aos')) {
		titleTextKey = 'agent.installAOS';
	}

	if (pathname.includes('ios')) {
		titleTextKey = 'agent.installIOS';
	}

	const { error } = useSelector((state: RootState) => state.account);

	const backButtonClickHandler = () => {
		navigate(`/${getI18nPath()}/signup/agent/select`);
	};

	const doneButtonClickHandler = () => {
		navigate(`/${getI18nPath()}/home`);
	};

	useLayoutEffect(() => {
		dispatch(getOrganizationDetailThunk());
	}, [error, dispatch]);

	return (
		<div css={container}>
			<div css={gap32Column}>
				<div css={title}>{t(titleTextKey)}</div>
				<Outlet />
				<div css={buttonBox}>
					<Button width="100%" mould="secondary" onClick={backButtonClickHandler}>
						{t('agent.back')}
					</Button>
					<Button width="100%" onClick={doneButtonClickHandler}>
						{t('agent.done')}
					</Button>
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	padding: 5rem;
`;

const gap32Column = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 600px;
`;

const buttonBox = css`
	display: flex;
	gap: 8px;
`;

const title = css`
	width: 100%;
	text-align: center;

	color: ${paletteSDS.gray['900']};
	font-size: ${themeSDS.fontSize['24']};
	font-weight: ${themeSDS.fontWeight.bold};
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabElement, Tabs, paletteSDS, themeSDS } from 'surf-design-system';

export default function AgentLayout() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const [selectedAgent, setSelectedAgent] = useState<number | null>(null);

	const agentButtons: TabElement[] = [
		{
			id: 0,
			onClick: () => {
				navigate('web');
			},
			tabLabel: 'Web',
		},
		{
			id: 1,
			onClick: () => {
				navigate('aos');
			},
			tabLabel: 'Android',
		},
		{
			id: 2,
			onClick: () => {
				navigate('ios');
			},
			tabLabel: 'iOS',
		},
	];

	useEffect(() => {
		if (pathname.includes('web')) {
			setSelectedAgent(0);
		} else if (pathname.includes('aos')) {
			setSelectedAgent(1);
		} else {
			setSelectedAgent(2);
		}
	}, [pathname]);

	return (
		<div css={container}>
			<div css={titleSt}>{t('breadCrumb.agent')}</div>
			<Tabs selectedTabId={selectedAgent} tabList={agentButtons} marginBottom="2rem" />
			<Outlet />
		</div>
	);
}

const container = css`
	width: 960px;

	padding: 32px 24px 80px 24px;

	margin: 0 auto;
`;

const titleSt = css`
	height: 100%;

	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize[24]};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { createRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, MoreHorizontalIcon } from 'surf-design-system';

import useOutboundClick from 'common/hooks/useOutboundClick';
import { RootState, useAppDispatch } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';
import { getProjectModeUrl } from 'common/utils/projectFunc';

import { setSelectedProjectId } from 'features/nfSetting/context/projectInfoSlice';

interface NetfunnelProjectTableIconProps {
	projectId: number;
}

export default function NetfunnelProjectTableIcon({ projectId }: NetfunnelProjectTableIconProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { language } = useSelector((state: RootState) => state.account);

	const [isVisible, setIsVisible] = useState(false);

	const iconRef = createRef<SVGSVGElement>();
	const menuRef = createRef<HTMLDivElement>();

	const iconClickHandler = () => {
		setIsVisible(prevState => !prevState);
	};

	const menClickHandler = () => {
		setIsVisible(false);
		dispatch(setSelectedProjectId(projectId));
		navigate(
			`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${projectId}/settings/management/project`
		);
	};

	useOutboundClick(menuRef, iconClickHandler, [iconRef]);

	return (
		<div css={container}>
			<MoreHorizontalIcon size="small" fill={paletteSDS.blue['700']} ref={iconRef} onClick={iconClickHandler} />
			{isVisible ? (
				<div ref={menuRef} css={menuSt} onClick={menClickHandler} aria-hidden>
					{t('console.nfHome.projectManagement')}
				</div>
			) : null}
		</div>
	);
}

const container = css`
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 32px;
	height: 48px;
`;

const menuSt = css`
	position: absolute;

	top: 36px;
	right: 0;

	min-width: 128px;
	height: 32px;

	padding: 9px 16px;

	background: ${paletteSDS.gray['50']};
	border: 1px solid ${paletteSDS.gray['200']};
	border-radius: 4px;

	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['11']};

	line-height: 128%;

	white-space: nowrap;

	z-index: 10;

	cursor: pointer;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { setIsSegmentDeleteConfirmModalVisible } from 'common/context/modalSlice';

import deleteBasicControlSegmentApi from 'features/nfControl/api/deleteBasicControlSegment.v2.api';

export interface DeleteBasicControlSegmentThunkResult {
	id: number;
	segmentOrder: number;
}

export interface DeleteBasicControlSegmentThunkProps {
	segmentId: number;
	segmentOrder: number;
	onClose: () => void;
}

const deleteBasicControlSegmentThunk = createAsyncThunk<
	DeleteBasicControlSegmentThunkResult,
	DeleteBasicControlSegmentThunkProps,
	{ state: RootState }
>('basic/segment/delete/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	const { segmentId, segmentOrder, onClose } = args;

	try {
		const { data } = await deleteBasicControlSegmentApi({
			tenantId: tenantId || '',
			projectId: selectedProjectId,
			organizationId,
			segId: segmentId,
		});

		onClose();

		thunkAPI.dispatch(setIsSegmentDeleteConfirmModalVisible(true));

		return { ...data, segmentOrder };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default deleteBasicControlSegmentThunk;

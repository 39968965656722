/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import { Button, Modal, Textinput, Portal, paletteSDS, themeSDS, Textarea } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import { changeIsAskModalVisible } from 'common/context/modalSlice';

import {
	initialAskModalForm,
	askModalFormReducer,
	UPDATE_DESC,
	UPDATE_TITLE,
	UPDATE_FILES,
	VALIDATE_DESC,
	VALIDATE_TITLE,
	EMPTY_FILE,
	DELETE_FILE,
} from 'common/context/askModalForm.reducer';
import { getNotificationListThunk } from 'common/thunk/getNotificationList.thunk';
import { NETFUNNEL, NOTI_START_PAGE } from 'common/data/constants';
import Astrix from 'common/components/Astrix';
import AskModalErrorGuide from 'common/components/AskModalErrorGuide';
import postZendeskFilesApi from 'common/api/postZendeskFiles.api';
import postZendeskRequestApi from 'common/api/postZendeskRequest.api';
import postNotificationApi from 'common/api/postNotification.api';

import type { PlanLangs } from 'features/plan/types/getOrganizationPlan.type';
import FileUploadElement from 'features/setting/components/FileUploadElement';
import type { SubscriptionData } from 'features/plan/types/getSubscriptionList.v2.type';

export default function AskModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [isZendeskError, setIsZendeskError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { isAskModalVisible, askModalInitialTitle } = useSelector((state: RootState) => state.modal);

	const { userEmail, userName, userRole, expiredAt, createdAt, organizationId, organizationName, language } =
		useSelector((state: RootState) => state.account);

	const { planLangs } = useSelector((state: RootState) => state.plan);

	const { products } = useSelector((state: RootState) => state.subscription);

	const planLang = planLangs.find(el => el.langType === 'KOREAN') as PlanLangs;

	const netFunnelSubscriptionInfo = products.find(el => el.productType === NETFUNNEL) as SubscriptionData;

	const [askModalFormData, setAskModalFormData] = useReducer(askModalFormReducer, {
		...initialAskModalForm,
		title: askModalInitialTitle || '',
	});

	const {
		title,
		titleHelpText,
		titleValidation,
		isTitleChanged,
		isTitleValid,
		description,
		descriptionHelpText,
		descriptionValidation,
		isDescriptionChanged,
		isDescriptionValid,
		isFileUploadFail,
		fileList,
		fileUploadFailHelpText,
	} = askModalFormData;

	const addFileClickHandler = () => {
		inputRef.current.click();
	};

	/**
	 * description: 파일 등록 시 파일 타입과 파일 크기를 검사하고 이상 없을 시 state 갱신
	 */
	const fileChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (!e.target.files) {
			if (inputRef.current) {
				inputRef.current.value = '';
			}

			setAskModalFormData({ type: EMPTY_FILE });
			return;
		}

		const inputFileList = e.target.files;

		if (inputRef.current) {
			inputRef.current.value = '';
		}

		const filesPayload: File[] = [];

		for (let i = 0; i < inputFileList.length || 0; i++) {
			const item = inputFileList.item(i);
			if (item) {
				filesPayload.push(item);
			}
		}

		setAskModalFormData({ type: UPDATE_FILES, files: filesPayload });
	};

	const titleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setAskModalFormData({ type: UPDATE_TITLE, payload: e.target.value });
	};

	const titleBlurHandler = () => {
		setAskModalFormData({ type: VALIDATE_TITLE });
	};

	const descriptionChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
		setAskModalFormData({ type: UPDATE_DESC, payload: e.target.value });
	};

	const descriptionBlurHandler = () => {
		setAskModalFormData({ type: VALIDATE_DESC });
	};

	const fileDeleteHandler = (id: string) => {
		setAskModalFormData({ type: DELETE_FILE, payload: id });
	};

	/**
	 * description: 문의 제목과 상세내용 값과 핸들 함수들
	 */

	/**
	 * description: 제출 버튼 클릭 시 오너십 이전 메일 발송
	 */
	const askFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const tokenData = await Promise.all(fileList.map(file => postZendeskFilesApi({ file: file.file })));

			await postZendeskRequestApi({
				description,
				title,
				tokenData,
				userEmail,
				userName,
				userRole,
				planSubscription: netFunnelSubscriptionInfo.status === 'SUBSCRIBING',
				userPlan: planLang.name,
				expiredAt,
				createdAt,
				organizationId,
				organizationName,
			});

			await postNotificationApi({ organizationId, language, postNotiType: 'ask' });

			setIsZendeskError(false);
			setIsLoading(false);

			dispatch(changeIsAskModalVisible());
		} catch (error) {
			setIsLoading(false);
			setIsZendeskError(true);
		}

		dispatch(getNotificationListThunk({ pageNum: NOTI_START_PAGE }));
	};

	return (
		<Portal>
			<Modal
				visible={isAskModalVisible}
				title={t('modal.askModalTitle')}
				onClickCloseButton={() => dispatch(changeIsAskModalVisible())}
				actionType="none"
			>
				<form css={containerSt} onSubmit={askFormSubmitHandler}>
					<div css={inputContainer}>
						{isZendeskError ? <AskModalErrorGuide /> : null}
						<div css={guideSt}>
							<Trans i18nKey="modal.askModalMsg" components={{ br: <br /> }} />
						</div>
						<Textinput
							label={
								<span>
									{t('modal.askTitle')}
									<Astrix />
								</span>
							}
							value={title}
							onChange={titleChangeHandler}
							validation={isTitleChanged ? titleValidation : 'none'}
							onBlur={titleBlurHandler}
							helpText={isTitleChanged && !isTitleValid && t(titleHelpText)}
							width="100%"
							valueCount
							maxLength={30}
						/>
						<Textarea
							label={
								<span>
									{t('modal.askDescr')}
									<Astrix />
								</span>
							}
							value={description}
							onChange={descriptionChangeHandler}
							height="12rem"
							helpText={isDescriptionChanged && !isDescriptionValid && t(descriptionHelpText)}
							validation={isDescriptionChanged ? descriptionValidation : 'none'}
							onBlur={descriptionBlurHandler}
						/>
						<div css={addFileContainerCss}>
							<div css={addFileGuideWrap}>
								<div css={topGuideSt}>{t('modal.attch')}</div>
								<div css={bottomGuideSt}>{t('modal.attchMsg')}</div>
							</div>
							<div>
								<Button mould="secondary" size="small" onClick={addFileClickHandler} type="button">
									{t('modal.addFiles')}
								</Button>
							</div>
							{fileList.map(element => (
								<FileUploadElement
									key={element.id}
									id={element.id}
									name={element.file.name}
									onDelete={fileDeleteHandler}
								/>
							))}
							{isFileUploadFail && <div css={fileErrorSt}>{t(fileUploadFailHelpText)}</div>}
							<input
								type="file"
								onChange={fileChangeHandler}
								multiple
								css={inputSt}
								ref={inputRef}
								accept=".jpg, .jpeg, .png"
							/>
						</div>
					</div>
					<Button width="100%" disabled={!isDescriptionValid || !isTitleValid || isLoading}>
						{t('modal.askSubmit')}
					</Button>
				</form>
			</Modal>
		</Portal>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
`;

const inputContainer = css`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const guideSt = css`
	color: ${paletteSDS.gray[900]};

	white-space: pre-wrap;
	word-break: break-all;
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;
`;

const addFileGuideWrap = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const topGuideSt = css`
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray[700]};

	word-break: break-all;
`;

const bottomGuideSt = css`
	font-size: ${themeSDS.fontSize[12]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.gray[700]};

	white-space: pre;

	line-height: 160%;
`;

const addFileContainerCss = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
const fileErrorSt = css`
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
	color: ${paletteSDS.red[500]};

	word-break: break-all;
`;

const inputSt = css`
	display: none;
`;

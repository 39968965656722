import { paletteSDS } from 'surf-design-system';

const { categoryColor, gray, blue } = paletteSDS;

export const X_AXIS_NUM = 6;
export const X_AXIS_VALUE_INTERVAL = 60;

export const X_AXIS_NUM_SELECT = 25;
export const X_AXIS_VALUE_INTERVAL_SELECT = 3600;

export const loadTimeColor = {
	9: paletteSDS.red[500],
	8: categoryColor.redOrange,
	7: categoryColor.orange,
	6: categoryColor.yellow,
	5: categoryColor.mentis,
	4: categoryColor.green,
	3: categoryColor.mint,
	2: categoryColor.skyBlue,
	1: categoryColor.blue,
	0: categoryColor.oceanBlue,
};

export const dountChartColor = {
	noData: [gray[100]],
	browser: [categoryColor.blueViolet, '#b174fc', '#E3CCFF', '#AC40DF', '#562B8B'],
	os: [blue[700], blue[300], blue[100], blue[400], blue[800]],
	app: [categoryColor.skyBlue, '#72CAFF', '#CFEDFF', '#1E66F1', '#2E498C'],
};

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsSignupBlockModalVisible } from 'common/context/modalSlice';

export default function SignupBlockModal() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { isSignupBlockModalVisible } = useSelector((state: RootState) => state.modal);

	const dispatch = useAppDispatch();

	const primaryBtnClickHandler = () => {
		dispatch(toggleIsSignupBlockModalVisible());
		navigate('/signin');
	};

	return (
		<Portal>
			<Modal
				visible={isSignupBlockModalVisible}
				actionType="single"
				primaryBtnLabel={t('blockModal.confirm')}
				primaryBtnFunction={primaryBtnClickHandler}
				onClickOverlay={primaryBtnClickHandler}
				isCloseIconVisible={false}
			>
				<div css={contentSt}>{t('blockModal.signupDescription')}</div>
			</Modal>
		</Portal>
	);
}

const contentSt = css`
	white-space: pre-wrap;

	line-height: 160%;

	word-break: break-word;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { paletteSDS } from 'surf-design-system';
import { RootState } from 'common/redux/store';
import useUsageStackChartOptions from 'features/plan/hooks/useUsageStackChartOptions';

import UsageChartBox from './UsageChartBox';
import UsageChartTitle from './UsageChartTitle';

// todo : data 개수에 따른 category 및 데이터 값 설정
export default function WaitingUsersMonthlyChart() {
	const { t } = useTranslation();

	const { chart } = useSelector((state: RootState) => state.usage);

	const chartDataByType = chart.monthly;

	const { options } = useUsageStackChartOptions({
		maxY: chartDataByType.basicMonitoring.maxWaitingUser + chartDataByType.pathControl.maxWaitingUser,
		chartType: 'monthly',
		date: chartDataByType.basicMonitoring.waitingUser.filter(el => el !== null).length,
	});

	return (
		<UsageChartBox>
			<UsageChartTitle
				title={t('planSubscription.waitingUsers')}
				labels={[
					{ id: 0, color: paletteSDS.categoryColor.hotPink, label: t('planSubscription.basic') },
					{ id: 1, color: paletteSDS.categoryColor.blueViolet, label: t('planSubscription.path') },
				]}
			/>
			{chartDataByType.isLoaded ? (
				<ReactApexChart
					series={[
						{
							name: 'basic',
							data: chartDataByType.basicMonitoring.waitingUser,
							color: paletteSDS.categoryColor.hotPink,
						},
						{
							name: 'path',
							data: chartDataByType.pathControl.waitingUser,
							color: paletteSDS.categoryColor.blueViolet,
						},
					]}
					options={options}
					height="250px"
					type="bar"
				/>
			) : (
				<div />
			)}
		</UsageChartBox>
	);
}

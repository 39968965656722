import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getRealtimeChargeDetailApi from '../api/getRealtimeChargeDetail.api';

import type { GetRealtimeChargeDetailApiData } from '../types/getRealtimeChargeDetail.type';

export const getRealtimeChargeDetailThunk = createAsyncThunk<
	GetRealtimeChargeDetailApiData,
	void,
	{ state: RootState }
>('realtimeCharge/detail/get', async (_, thnkAPI) => {
	const {
		account: { organizationId },
	} = thnkAPI.getState();

	try {
		const { data } = await getRealtimeChargeDetailApi({ organizationId });

		return { ...data };
		// eslint-disable-next-line
	} catch (error: any) {
		return thnkAPI.rejectWithValue(error.response.data);
	}
});

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PutServiceDomainBeApiResponse, PutServiceDomainTenantApiResponse } from '../types/putServiceDomain.type';

export interface PutServiceDomainTenantApiProps {
	organizationId: number;
	customerServiceDomain: string;
	tenantUrl: string;
	tenantId: string;
	projectId: number;
}

export interface PutServiceDomainBeApiProps {
	defaultSubDomain?: string;
	organizationId: number;
	defaultCustomerUrl?: string;
}

export async function putServiceDomainTenantApi({
	organizationId,
	customerServiceDomain,
	tenantUrl,
	tenantId,
	projectId,
}: PutServiceDomainTenantApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	const body = { customerServiceDomain };

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutServiceDomainTenantApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}/domain`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

export async function putServiceDomainBeApi({
	organizationId,
	defaultCustomerUrl,
	defaultSubDomain,
}: PutServiceDomainBeApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	const body = { defaultCustomerUrl, defaultSubDomain };

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutServiceDomainBeApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/organization/domain`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
				},
			}
		);
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import { LANG_PATH } from 'common/data/constants';

import getPaymentChargeApi from 'features/plan/api/getPaymentCharge.api';
import type { BillingHistoryData, paymentFlagType } from 'features/plan/types/getBillingHistory.type';
import { addNumberComma, getDateFormatLanguage, priceUnitFormat } from 'features/plan/utils/formatFunc';

export interface PaymentChargeProps {
	bill: BillingHistoryData;
}

export default function PaymentCharge({ bill }: PaymentChargeProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { response, sendRequest } = useApiRequestWithArg(getPaymentChargeApi);

	const { organizationId, language } = useSelector((state: RootState) => state.account);

	const billedDate = getDateFormatLanguage(bill.billedDate, language);

	/**
	 * description: 청구 내역 data를 naviate 에 state로 전달
	 */
	const viewDetailClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home/plan_usage/billing_history/detail/${bill.id}`);
	};

	/**
	 * description: 상세 청구비용
	 */

	useEffect(() => {
		sendRequest({ organizationId, billId: bill.id });
	}, [sendRequest, organizationId, bill.id]);

	if (response === null) return null;

	return (
		<div key={bill.id} css={billingListSt}>
			<div css={statusSt}>
				<div css={dotSt(bill.paymentFlag)} />
				<div>
					{bill.paymentFlag === 'YES' ? t('planSubscription.billingStsPaid') : t('planSubscription.billingStsUnpaid')}
				</div>
			</div>
			<div>{billedDate}</div>
			<div>{bill.planName}</div>
			<div>
				{priceUnitFormat(response.data.chargeDetailModels[0].priceUnit)}
				{addNumberComma(String(bill.charge))}
			</div>
			<div>{bill.paymentMethod}</div>
			<Button size="small" mould="tertiary" onClick={viewDetailClickHandler}>
				{t('planSubscription.viewDetail')}
			</Button>
		</div>
	);
}

const billingListSt = css`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	align-items: center;
	padding: 0 1rem;

	width: 60rem;
	min-width: 60rem;
	height: 3rem;

	border-left: 1px solid ${paletteSDS.gray[500]};
	border-right: 1px solid ${paletteSDS.gray[500]};
	border-bottom: 1px solid ${paletteSDS.gray[500]};

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};
`;

const statusSt = css`
	display: flex;
	align-items: center;
	gap: 0.375rem;
`;
const dotSt = (status: paymentFlagType) => css`
	width: 8px;
	height: 8px;
	border-radius: 50%;

	${status === 'YES' && `background-color: ${paletteSDS.blue[400]}`}
	${status === 'NO' && `background-color: ${paletteSDS.red[400]}`}
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getVWRDataThunk } from 'features/vwr/thunk/getVWRData.v2.thunk';
import { getVWRPageNumberThunk } from 'features/vwr/thunk/getVWRPageNumber.v2.thunk';

import type { BasicControlSegmentDetailData } from 'features/nfControl/type/getBasicControlSegmentDetail.v2.type';
import type { PathControlSegmentDetailData } from 'features/nfControl/type/getPathControlSegmentDetail.v2.type';
import getPathControlSegmentDetailThunk from 'features/nfControl/thunk/getPathControlSegmentDetail.v2.thunk';
import getBasicControlSegmentDetailThunk from 'features/nfControl/thunk/getBasicControlSegmentDetail.v2.thunk';

interface ModalData {
	showModal: boolean;
	pendingModal: boolean;
	isLogoutModalVisible: boolean;
	isSegmentAddModalVisible: boolean;
	isSegmentEditModalVisible: boolean;
	isSegmentDeleteModalVisible: boolean;
	isSegmentGraphModalVisible: boolean;
	isSegmentMaxNumModalVisible: boolean;
	isSegmentDeleteConfirmModalVisible: boolean;
	isAskModalVisible: boolean;
	askModalInitialTitle?: string;
	isOveragesModalVisible: boolean;
	isBlockRegisterDomainModalVisible: boolean;
	isFindPasswordModalVisible: boolean;
	isChangePasswordModalVisible: boolean;
	isInitialConsoleAccessModalVisible: boolean;
	isFreetrialEndModalVisible: boolean;
	isPendingModalVisible: boolean;
	isVWRPutConfirmModalVisible: boolean;
	isServiceDomainSettingModalVisible: boolean;
	isPlanChangeConfirmModalVisible: boolean;
	isPaymentFormModalVisible: boolean;
	isPaymentConfirmModalVisible: boolean;
	isPaymentErrorModalVisible: boolean;
	paymentErrorModalTitle: string;
	paymentErrorModalBody: string;
	isProfileEditModalVisible: boolean;
	isInviteMemberModalVisible: boolean;
	isOrganizationNameModalVisible: boolean;
	isIntervalSegmentCreateModalVisible: boolean;
	isOauthRegisterCognitoRequiredModalVisible: boolean;
	isOauthAlreadySignedUpModalVisible: boolean;
	isOauthAlreadyExistUserModalVisible: boolean;
	isOauthBadRequestModalVisible: boolean;
	isIntervalSegmentMaxNumModalVisible: boolean;
	isIntervalSegmentDeleteConfirmModalVisible: boolean;
	isSigninBlockModalVisible: boolean;
	isSignupBlockModalVisible: boolean;
	isMoisSegmentCreateModalVisible: boolean;
	isFreeBetaEndModalVisible: boolean;
	isTenantFailModalVisible: boolean;
	isPlanSubscriptionReminderModalVisible: boolean;
	projectManagementModal: {
		isUpdateNameModalVisible: boolean;
		isToggleActivatedYnModalVisible: boolean;
		isToggleAccessYnModalVisible: boolean;
		isBotBlockingSettingModalVisible: boolean;
		selectedProjectId: number;
		projectKey: string;
		projectName: string;
		accessYn: boolean;
		activatedYn: boolean;
	};
	isOwnerTransferModalVisible: boolean;
	vwr: {
		isDeleteModalVisible: boolean;
		isDeleteCompleteModalVisible: boolean;
		isVWRNameModalVisible: boolean;
		name: string;
		id: number;
	};
	isUpdateMainDomainConfirmModalVisible: boolean;
	basicControl: {
		isUpdateModalVisible: boolean;
		initialData: BasicControlSegmentDetailData;
	};
	pathControl: {
		isUpdateModalVisible: boolean;
		initialData: PathControlSegmentDetailData;
	};
}

interface BasicControlModalData {
	isUpdateModalVisible: boolean;
	initialData: BasicControlSegmentDetailData;
}

interface PathControlModalData {
	isUpdateModalVisible: boolean;
	initialData: PathControlSegmentDetailData;
}

const initialBasicControlData: BasicControlModalData = {
	isUpdateModalVisible: false,
	initialData: {
		accessYn: true,
		vwrPageId: 1,
		vwrPage: [],
		dynamicMaxInflow: null,
		dynamicMinInflow: null,
		dynamicSectionMaxSec: null,
		dynamicSectionMinSec: null,
		segmentOrder: 1,
		segmentColor: '',
		segmentKey: '',
		segmentName: '',
		segmentType: 'STATIC',
		segmentGraphYn: false,
		uris: [],
		id: 1,
		maxInflow: 1,
	},
};

const initialPathControlData: PathControlModalData = {
	isUpdateModalVisible: false,
	initialData: {
		accessYn: true,
		vwrPageId: 1,
		vwrPage: [],
		segmentOrder: 1,
		segmentColor: '',
		segmentKey: '',
		segmentName: '',
		segmentGraphYn: false,
		id: 1,
		maxInflow: 1,
		startUri: '',
		endUri: '',
	},
};

const initialState: ModalData = {
	showModal: false,
	pendingModal: false,
	isLogoutModalVisible: false,
	isSegmentAddModalVisible: false,
	isSegmentEditModalVisible: false,
	isSegmentDeleteModalVisible: false,
	isSegmentGraphModalVisible: false,
	isSegmentMaxNumModalVisible: false,
	isSegmentDeleteConfirmModalVisible: false,
	isAskModalVisible: false,
	isOveragesModalVisible: false,
	isBlockRegisterDomainModalVisible: false,
	isFindPasswordModalVisible: false,
	isChangePasswordModalVisible: false,
	isInitialConsoleAccessModalVisible: false,
	isFreetrialEndModalVisible: false,
	isPendingModalVisible: false,
	isVWRPutConfirmModalVisible: false,
	isServiceDomainSettingModalVisible: false,
	isPlanChangeConfirmModalVisible: false,
	isPaymentFormModalVisible: false,
	isPaymentConfirmModalVisible: false,
	isPaymentErrorModalVisible: false,
	paymentErrorModalBody: '',
	paymentErrorModalTitle: '',
	isProfileEditModalVisible: false,
	isInviteMemberModalVisible: false,
	isOrganizationNameModalVisible: false,
	isIntervalSegmentCreateModalVisible: false,
	isOauthRegisterCognitoRequiredModalVisible: false,
	isOauthAlreadyExistUserModalVisible: false,
	isOauthAlreadySignedUpModalVisible: false,
	isOauthBadRequestModalVisible: false,
	isIntervalSegmentMaxNumModalVisible: false,
	isIntervalSegmentDeleteConfirmModalVisible: false,
	isSigninBlockModalVisible: false,
	isSignupBlockModalVisible: false,
	isMoisSegmentCreateModalVisible: false,
	isFreeBetaEndModalVisible: false,
	isTenantFailModalVisible: false,
	projectManagementModal: {
		isUpdateNameModalVisible: false,
		isToggleActivatedYnModalVisible: false,
		isToggleAccessYnModalVisible: false,
		isBotBlockingSettingModalVisible: false,
		selectedProjectId: 0,
		projectKey: '',
		projectName: '',
		accessYn: false,
		activatedYn: false,
	},
	isOwnerTransferModalVisible: false,
	vwr: {
		name: '',
		id: 0,
		isDeleteCompleteModalVisible: false,
		isDeleteModalVisible: false,
		isVWRNameModalVisible: false,
	},
	isUpdateMainDomainConfirmModalVisible: false,
	basicControl: { ...initialBasicControlData },
	pathControl: { ...initialPathControlData },
	isPlanSubscriptionReminderModalVisible: false,
};

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setIsUpdateNameModalVisible: (
			state,
			action: PayloadAction<{ selectedProjectId: number; projectKey: string; projectName: string }>
		) => {
			return {
				...state,
				projectManagementModal: { ...state.projectManagementModal, isUpdateNameModalVisible: true, ...action.payload },
			};
		},
		setIsToggleActivatedYnModalVisible: (
			state,
			action: PayloadAction<{ selectedProjectId: number; projectName: string; activatedYn: boolean }>
		) => {
			return {
				...state,
				projectManagementModal: {
					...state.projectManagementModal,
					isToggleActivatedYnModalVisible: true,
					...action.payload,
				},
			};
		},
		setIsToggleAccessYnModalVisible: (
			state,
			action: PayloadAction<{ selectedProjectId: number; projectName: string; accessYn: boolean }>
		) => {
			return {
				...state,
				projectManagementModal: {
					...state.projectManagementModal,
					isToggleAccessYnModalVisible: true,
					...action.payload,
				},
			};
		},
		setIsBotBlockingSettingModalVisible: (state, action: PayloadAction<{ selectedProjectId: number }>) => {
			return {
				...state,
				projectManagementModal: {
					...state.projectManagementModal,
					isBotBlockingSettingModalVisible: true,
					...action.payload,
				},
			};
		},
		initializeProjectManagementModal: state => {
			return {
				...state,
				projectManagementModal: {
					isUpdateNameModalVisible: false,
					isToggleActivatedYnModalVisible: false,
					isToggleAccessYnModalVisible: false,
					isBotBlockingSettingModalVisible: false,
					selectedProjectId: 0,
					projectKey: '',
					projectName: '',
					accessYn: false,
					activatedYn: false,
				},
			};
		},
		setShowModal: state => {
			return { ...state, showModal: true };
		},
		setShowPendingModal: state => {
			return { ...state, pendingModal: true };
		},
		setHideModal: state => {
			return { ...state, showModal: false };
		},
		setHidePendingModal: state => {
			return { ...state, pendingModal: false };
		},
		setIsLogoutModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isLogoutModalVisible: action.payload };
		},
		setIsSegmentAddModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSegmentAddModalVisible: action.payload };
		},
		setIsSegmentEditModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSegmentEditModalVisible: action.payload };
		},
		setIsSegmentDeleteModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSegmentDeleteModalVisible: action.payload };
		},
		setIsSegmentGraphModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSegmentGraphModalVisible: action.payload };
		},
		setIsSegmentMaxNumModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSegmentMaxNumModalVisible: action.payload };
		},
		setIsSegmentDeleteConfirmModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSegmentDeleteConfirmModalVisible: action.payload };
		},
		setIsIntervalSegmentDeleteConfirmModalVisible: (state, action: PayloadAction<boolean>) => {
			return { ...state, isIntervalSegmentDeleteConfirmModalVisible: action.payload };
		},
		closeAllMonitoringModals: state => {
			return {
				...state,
				isSegmentAddModalVisible: false,
				isSegmentEditModalVisible: false,
				isSegmentDeleteModalVisible: false,
				isSegmentGraphModalVisible: false,
				isSegmentMaxNumModalVisible: false,
				isSegmentDeleteConfirmModalVisible: false,
				isAskModalVisible: false,
				isOveragesModalVisible: false,
			};
		},
		changeIsAskModalVisible: state => {
			return {
				...state,
				isAskModalVisible: !state.isAskModalVisible,
				askModalInitialTitle: undefined,
			};
		},
		changeEnterpriseAskModalVisible: (state, action: PayloadAction<string | undefined>) => {
			return {
				...state,
				isAskModalVisible: !state.isAskModalVisible,
				askModalInitialTitle: action.payload,
			};
		},
		changeIsOveragesModalVisible: state => {
			return {
				...state,
				isOveragesModalVisible: !state.isOveragesModalVisible,
			};
		},
		changeIsBlockRegisterDomainModalVisible: state => {
			return {
				...state,
				isBlockRegisterDomainModalVisible: !state.isBlockRegisterDomainModalVisible,
			};
		},
		changeIsFindPasswordModalVisible: state => {
			return {
				...state,
				isFindPasswordModalVisible: !state.isFindPasswordModalVisible,
			};
		},
		toggleIsChangePasswordModalVisible: state => {
			return {
				...state,
				isChangePasswordModalVisible: !state.isChangePasswordModalVisible,
			};
		},
		toggleIsInitialConsoleAccessModalVisible: state => {
			return {
				...state,
				isInitialConsoleAccessModalVisible: !state.isInitialConsoleAccessModalVisible,
			};
		},
		toggleIsFreetrialEndModalVisible: state => {
			return {
				...state,
				isFreetrialEndModalVisible: !state.isFreetrialEndModalVisible,
			};
		},
		toggleIsPendingModalVisible: state => {
			return {
				...state,
				isPendingModalVisible: !state.isPendingModalVisible,
			};
		},
		toggleIsVWRPutConfirmModalVisible: state => {
			return {
				...state,
				isVWRPutConfirmModalVisible: !state.isVWRPutConfirmModalVisible,
			};
		},
		toggleIsServiceDomainSettingModalVisible: state => {
			return {
				...state,
				isServiceDomainSettingModalVisible: !state.isServiceDomainSettingModalVisible,
			};
		},
		toggleIsPlanChangeConfirmModalVisible: state => {
			return {
				...state,
				isPlanChangeConfirmModalVisible: !state.isPlanChangeConfirmModalVisible,
			};
		},
		toggleIsPaymentFormModalVisible: state => {
			return {
				...state,
				isPaymentFormModalVisible: !state.isPaymentFormModalVisible,
			};
		},
		toggleIsPaymentConfirmModalVisible: state => {
			return {
				...state,
				isPaymentConfirmModalVisible: !state.isPaymentConfirmModalVisible,
			};
		},
		toggleIsPaymentErrorModalVisible: (state, action: PayloadAction<{ title?: string; body?: string }>) => {
			return {
				...state,
				isPaymentErrorModalVisible: !state.isPaymentErrorModalVisible,
				paymentErrorModalBody: action.payload.body || '',
				paymentErrorModalTitle: action.payload.title || '',
			};
		},
		toggleIsProfileEditModalVisible: state => {
			return {
				...state,
				isProfileEditModalVisible: !state.isProfileEditModalVisible,
			};
		},
		toggleIsInviteMemberModalVisible: state => {
			return {
				...state,
				isInviteMemberModalVisible: !state.isInviteMemberModalVisible,
			};
		},
		toggleIsOrganizationNameModalVisible: state => {
			return {
				...state,
				isOrganizationNameModalVisible: !state.isOrganizationNameModalVisible,
			};
		},
		toggleIsIntervalSegmentCreateModalVisible: state => {
			return {
				...state,
				isIntervalSegmentCreateModalVisible: !state.isIntervalSegmentCreateModalVisible,
			};
		},
		toggleIsOauthAlreadySignedUpModalVisible: state => {
			return {
				...state,
				isOauthAlreadySignedUpModalVisible: !state.isOauthAlreadySignedUpModalVisible,
			};
		},
		toggleIsOauthAlreadyExistUserModalVisible: state => {
			return {
				...state,
				isOauthAlreadyExistUserModalVisible: !state.isOauthAlreadyExistUserModalVisible,
			};
		},
		toggleIsOauthBadRequestModalVisible: state => {
			return {
				...state,
				isOauthBadRequestModalVisible: !state.isOauthBadRequestModalVisible,
			};
		},
		toggleIsOauthRegisterCognitoRequiredModalVisible: state => {
			return {
				...state,
				isOauthRegisterCognitoRequiredModalVisible: !state.isOauthRegisterCognitoRequiredModalVisible,
			};
		},
		toggleIsIntervalSegmentMaxNumModalVisible: state => {
			return {
				...state,
				isIntervalSegmentMaxNumModalVisible: !state.isIntervalSegmentMaxNumModalVisible,
			};
		},
		toggleIsSigninBlockModalVisible: state => {
			return {
				...state,
				isSigninBlockModalVisible: !state.isSigninBlockModalVisible,
			};
		},
		toggleIsSignupBlockModalVisible: state => {
			return {
				...state,
				isSignupBlockModalVisible: !state.isSignupBlockModalVisible,
			};
		},
		toggleIsMoisSegmentCreateModalVisible: state => {
			return { ...state, isMoisSegmentCreateModalVisible: !state.isMoisSegmentCreateModalVisible };
		},
		toggleIsFreebetaEndModalVisible: state => {
			return { ...state, isFreeBetaEndModalVisible: !state.isFreeBetaEndModalVisible };
		},
		toggleIsTenantFailModalVisible: state => {
			return { ...state, isTenantFailModalVisible: !state.isTenantFailModalVisible };
		},
		setInitialModalVisible: () => {
			return { ...initialState };
		},
		toggleIsOwnerTransferModalVisible: state => {
			return { ...state, isOwnerTransferModalVisible: !state.isOwnerTransferModalVisible };
		},
		setVWRName: (state, action: PayloadAction<{ name: string }>) => {
			return { ...state, vwr: { ...state.vwr, name: action.payload.name } };
		},
		setIsDeleteVWRModalVisible: (state, action: PayloadAction<{ name: string; id: number }>) => {
			return { ...state, vwr: { ...state.vwr, isDeleteModalVisible: true, ...action.payload } };
		},
		toggleIsUpdateVWRNameVisible: state => {
			return { ...state, vwr: { ...state.vwr, isVWRNameModalVisible: !state.vwr.isVWRNameModalVisible } };
		},
		toggleIsDeleteVWRCompleteModalVisible: state => {
			return { ...state, vwr: { ...state.vwr, isDeleteCompleteModalVisible: !state.vwr.isDeleteCompleteModalVisible } };
		},
		closeVWRModal: state => {
			return {
				...state,
				vwr: {
					...state.vwr,
					isDeleteCompleteModalVisible: false,
					isDeleteModalVisible: false,
					isVWRNameModalVisible: false,
				},
			};
		},
		toggleIsUpdateMainDomainConfirmModalVisible: state => {
			return { ...state, isUpdateMainDomainConfirmModalVisible: !state.isUpdateMainDomainConfirmModalVisible };
		},
		resetVWRData: state => {
			return {
				...state,
				vwr: {
					id: 0,
					name: '',
					isDeleteModalVisible: false,
					isVWRNameModalVisible: false,
					isDeleteCompleteModalVisible: false,
				},
			};
		},
		resetBasicControlModal: state => {
			return { ...state, basicControl: { ...initialBasicControlData } };
		},
		resetPathControlModal: state => {
			return { ...state, pathControl: { ...initialPathControlData } };
		},
		toggleIsPlanSubscriptionReminderModalVisible: state => {
			return { ...state, isPlanSubscriptionReminderModalVisible: !state.isPlanSubscriptionReminderModalVisible };
		},
	},
	extraReducers(builder) {
		builder.addCase(getVWRDataThunk.fulfilled, (state, action) => {
			return { ...state, vwr: { ...state.vwr, id: action.payload.id, name: action.payload.name } };
		});

		builder.addCase(getVWRPageNumberThunk.fulfilled, (state, action) => {
			return { ...state, vwr: { ...state.vwr, name: action.payload.name } };
		});

		builder.addCase(getPathControlSegmentDetailThunk.fulfilled, (state, action) => {
			return { ...state, pathControl: { isUpdateModalVisible: true, initialData: { ...action.payload } } };
		});

		builder.addCase(getBasicControlSegmentDetailThunk.fulfilled, (state, action) => {
			return { ...state, basicControl: { isUpdateModalVisible: true, initialData: { ...action.payload } } };
		});
	},
});

export const {
	setIsSegmentAddModalVisible,
	setIsSegmentGraphModalVisible,
	setIsSegmentMaxNumModalVisible,
	setIsSegmentDeleteConfirmModalVisible,
	changeIsAskModalVisible,
	closeAllMonitoringModals,
	changeEnterpriseAskModalVisible,
	changeIsOveragesModalVisible,
	changeIsBlockRegisterDomainModalVisible,
	changeIsFindPasswordModalVisible,
	toggleIsChangePasswordModalVisible,
	toggleIsInitialConsoleAccessModalVisible,
	toggleIsFreetrialEndModalVisible,
	toggleIsPendingModalVisible,
	toggleIsVWRPutConfirmModalVisible,
	toggleIsServiceDomainSettingModalVisible,
	toggleIsPlanChangeConfirmModalVisible,
	setInitialModalVisible,
	toggleIsPaymentFormModalVisible,
	toggleIsPaymentConfirmModalVisible,
	toggleIsPaymentErrorModalVisible,
	toggleIsProfileEditModalVisible,
	toggleIsInviteMemberModalVisible,
	toggleIsOrganizationNameModalVisible,
	toggleIsIntervalSegmentCreateModalVisible,
	toggleIsOauthRegisterCognitoRequiredModalVisible,
	toggleIsOauthBadRequestModalVisible,
	toggleIsOauthAlreadySignedUpModalVisible,
	toggleIsOauthAlreadyExistUserModalVisible,
	toggleIsIntervalSegmentMaxNumModalVisible,
	setIsIntervalSegmentDeleteConfirmModalVisible,
	toggleIsSigninBlockModalVisible,
	toggleIsSignupBlockModalVisible,
	toggleIsMoisSegmentCreateModalVisible,
	toggleIsFreebetaEndModalVisible,
	toggleIsTenantFailModalVisible,
	setIsBotBlockingSettingModalVisible,
	setIsToggleAccessYnModalVisible,
	setIsUpdateNameModalVisible,
	setIsToggleActivatedYnModalVisible,
	initializeProjectManagementModal,
	toggleIsOwnerTransferModalVisible,
	setIsDeleteVWRModalVisible,
	setIsLogoutModalVisible,
	closeVWRModal,
	toggleIsDeleteVWRCompleteModalVisible,
	toggleIsUpdateMainDomainConfirmModalVisible,
	toggleIsUpdateVWRNameVisible,
	setVWRName,
	resetBasicControlModal,
	resetPathControlModal,
	resetVWRData,
	toggleIsPlanSubscriptionReminderModalVisible,
} = modalSlice.actions;

export default modalSlice.reducer;

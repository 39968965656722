import moment from 'moment';

/**
 * description: free-trial 기간 종료 판별 함수로 return 값이 true이면 종료된 것
 */
export function checkFreetrialEnd(endDate: string) {
	const today = moment();
	const endDay = moment(endDate);

	if (endDate === '') {
		return false;
	}

	return !moment(endDay).isSameOrAfter(today);
}

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

import type { GetOperatorListOnPremApiResponse } from 'features/nfControl/type/getOperatorList.v2.onPrem.type';

export interface GetOperatorListOnPremApiProps {
	tenantId: string;
	organizationId: number;
	searchValue: string;
}

export default async function getOperatorListOnPremApi({
	tenantId,
	organizationId,
	searchValue,
}: GetOperatorListOnPremApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.get<GetOperatorListOnPremApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/privilege/user/role/operator/segment`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
				params: {
					searchValue,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

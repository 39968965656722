import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { PutServiceDomainTenantApiResponse } from 'features/nfSetting/types/putServiceDomain.v2.type';

export interface PutServiceDomainTenantApiProps {
	organizationId: number;
	customerServiceDomain: string;
	tenantId: string;
	projectId: number;
}

export async function putServiceDomainTenantApi({
	organizationId,
	customerServiceDomain,
	tenantId,
	projectId,
}: PutServiceDomainTenantApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	const body = { customerServiceDomain };

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutServiceDomainTenantApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}/domain`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}

	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

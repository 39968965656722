/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import DividerWithTxt from 'common/components/DividerWithTxt';
import { useEffect, useState } from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { paletteSDS, themeSDS } from 'surf-design-system';

interface AOSAgentCodeBoxProps {
	agentCode: { java: string; kotlin: string };
	height: string;
}
export default function AOSAgentCodeBox({ agentCode, height }: AOSAgentCodeBoxProps) {
	const { t } = useTranslation('nfAgent');
	const [isCopied, setIsCopied] = useState(false);
	const [lang, setLang] = useState<'java' | 'kotlin'>('kotlin');
	const code = agentCode[lang];

	const javaClickHandler = () => {
		setIsCopied(false);
		setLang('java');
	};

	const kotlinClickHandler = () => {
		setIsCopied(false);
		setLang('kotlin');
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 500);
		}
	}, [isCopied]);

	return (
		<div css={codeBlockContainer}>
			<div css={codeBlockHeader}>
				<div css={langBox}>
					<div css={lang === 'kotlin' ? selectedLangSt : unSelectedLangSt} aria-hidden onClick={kotlinClickHandler}>
						Kotlin
					</div>
					<div css={lang === 'java' ? selectedLangSt : unSelectedLangSt} aria-hidden onClick={javaClickHandler}>
						Java
					</div>
				</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['800']} />
			<div css={codeBlockBox}>
				<CopyToClipboard text={code} onCopy={() => setIsCopied(true)}>
					<div css={copyButtonSt(isCopied)}>{isCopied ? t('installAOS.copied') : t('installAOS.copy')}</div>
				</CopyToClipboard>
				<CodeBlock
					text={code}
					theme={dracula}
					language={lang}
					codeBlock
					wrapLongLines
					customStyle={{ height: { height }, backgroundColor: '#1E1F29', borderRadius: '8px', padding: '16px' }}
				/>
			</div>
		</div>
	);
}

const codeBlockContainer = css`
	display: flex;
	flex-direction: column;
	gap: 0;

	height: fit-content;

	background: #1e1f29;
	border-radius: 8px;
`;

const codeBlockHeader = css`
	display: flex;
	border-radius: 8px;
	height: 50px;
	padding: 12px 24px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	background: #1e1f29;
`;

const langBox = css`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const codeBlockBox = css`
	position: relative;
`;

const copyButtonSt = (isCopied: boolean) => css`
	position: absolute;

	top: 0;
	right: 0;

	width: 74px;
	height: 26px;

	padding: 7px 0px;

	background: ${isCopied ? paletteSDS.blue['500'] : paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};
	text-align: center;

	border-bottom-left-radius: 8px;

	cursor: pointer;

	&:hover {
		background: ${!isCopied && paletteSDS.gray['700']};
	}
`;

const selectedLangSt = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.white};

	cursor: pointer;
`;

const unSelectedLangSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 160%;
	color: ${paletteSDS.white};

	opacity: 0.24;

	cursor: pointer;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { dracula, CodeBlock } from 'react-code-blocks';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { paletteSDS, themeSDS, Button, FileDownloadIcon, Link } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';
import { iosAgentCodeBlock } from 'common/utils/codeBlockFunc';
import { fileDownloadFromUrl } from 'common/utils/fileFunc';
import { IOS_PRODUCT_CODE } from 'common/data/constants';
import { getI18nLanguage } from 'common/utils/languageFunc';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import { getProductInfoApi } from 'common/api/getProductInfo.api';

interface InstallIOSAgentProps {
	titleFontSize?: string;
}

export default function InstallIOSAgent({ titleFontSize = '20px' }: InstallIOSAgentProps) {
	const { t } = useTranslation();

	const { response, sendRequest } = useApiRequestWithArg(getProductInfoApi);

	const { tenantUrl } = useSelector((state: RootState) => state.account);

	const [isImportCodeCopied, setIsImportCodeCopied] = useState(false);
	const [isIosAgentCodeCopied, setIsIosAgentCodeCopied] = useState(false);

	const linkClickHandler = () => {
		const i18nLanguage = getI18nLanguage();

		if (i18nLanguage === 'KOREAN') {
			window.open('https://app.archbee.com/public/PREVIEW-1S-PNQ3_nKKf6X3NAl_nd/PREVIEW-yUeGL_b_a8ztvFJHMHpxz');
			return;
		}
		window.open('https://app.archbee.com/public/PREVIEW-Bz-n9CZknDmJxjwMgHzRC/PREVIEW-UTYnhUc4ryVnW4NIBSHOU');
	};

	const importCode = `import UIKit\nimport SurffyAgent`;

	const iosAgentCode = iosAgentCodeBlock(tenantUrl);

	useEffect(() => {
		sendRequest({ productCode: IOS_PRODUCT_CODE });
	}, [sendRequest]);

	if (response === null) {
		return <div style={{ flex: 'auto' }} />;
	}

	const fileDownloadButtonClickHandler = async () => {
		await fileDownloadFromUrl(
			`${process.env.REACT_APP_AGENT_URL}/surffy-agent_${response.data.productVersion.replace('v', '')}.zip`,
			`surffy-agent_${response.data.productVersion.replace('v', '')}.zip`
		);
	};

	return (
		<div css={gap48Column}>
			<div css={gap8Column}>
				<div css={titleSt(titleFontSize)}>{t('agent.requirement')}</div>
				<div css={requirementBox}>
					<Trans i18nKey="agent.iOSRequirement" components={{ bold: <strong /> }} />
				</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={gap24Column}>
				<div css={gap8Column}>
					<div css={titleSt(titleFontSize)}>{t('agent.firstIOSTitle')}</div>
					<div css={guideSt}>
						<Trans i18nKey="agent.firstIOSGuide" components={{ bold: <strong /> }} />
					</div>
				</div>
				<Button
					mould="secondary"
					icon={<FileDownloadIcon fill={paletteSDS.blue['700']} />}
					iconPosition="right"
					onClick={fileDownloadButtonClickHandler}
					size="medium"
				>
					{t('agent.downloadFrameworkFile')}
				</Button>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={gap24Column}>
				<div css={titleSt(titleFontSize)}>{t('agent.secondIOSTitle')}</div>
				<div css={codeBlockBox}>
					{isImportCodeCopied ? <div css={copiedSt}>Copied!</div> : null}
					<CopyToClipboard
						text={importCode}
						onCopy={() => {
							setIsIosAgentCodeCopied(false);
							setIsImportCodeCopied(true);
						}}
					>
						<div css={copyButtonSt}>Copy</div>
					</CopyToClipboard>
					<CodeBlock
						language="swift"
						codeBlock
						text={importCode}
						theme={dracula}
						customStyle={{
							backgroundColor: '#1E1F29',
							borderRadius: '8px',
							padding: '24px 16px',
						}}
					/>
				</div>
			</div>
			<DividerWithTxt />
			<div css={gap24Column}>
				<div css={gap8Column}>
					<div css={titleSt(titleFontSize)}>{t('agent.thirdIOSTitle')}</div>
					<div css={guideSt}>
						<Trans
							components={{ bold: <Link inline size="small" onClick={linkClickHandler} /> }}
							i18nKey="agent.thirdIOSGuide"
						/>
					</div>
				</div>
				<div css={codeBlockBox}>
					{isIosAgentCodeCopied ? <div css={copiedSt}>Copied!</div> : null}
					<CopyToClipboard
						text={iosAgentCode}
						onCopy={() => {
							setIsImportCodeCopied(false);
							setIsIosAgentCodeCopied(true);
						}}
					>
						<div css={copyButtonSt}>Copy</div>
					</CopyToClipboard>
					<CodeBlock
						text={iosAgentCode}
						theme={dracula}
						language="swift"
						codeBlock
						wrapLongLines
						customStyle={{
							backgroundColor: '#1E1F29',
							borderRadius: '8px',
							padding: '24px 16px',
						}}
					/>
				</div>
			</div>
		</div>
	);
}

const gap48Column = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap24Column = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const titleSt = (titleFontSize: string) => css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${titleFontSize};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	white-space: pre-line;
	word-break: break-all;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const requirementBox = css`
	padding: 12px 16px;

	background: ${paletteSDS.gray['50']};
	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray['200']};

	white-space: pre-line;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const codeBlockBox = css`
	position: relative;

	height: fit-content;

	border-radius: 8px;
`;

const copyButtonSt = css`
	position: absolute;

	top: 0;
	right: 0;

	width: 61px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;

	cursor: pointer;
`;

const copiedSt = css`
	position: absolute;

	top: 0;
	right: 61px;

	width: 75px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.blue['500']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { dracula, CodeBlock } from 'react-code-blocks';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { paletteSDS, themeSDS, InfoIcon, colorPalette } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { webAgentCodeBlock } from 'common/utils/codeBlockFunc';
import DividerWithTxt from 'common/components/DividerWithTxt';

interface InstallWebAgentProps {
	titleFontSize?: string;
}

export default function InstallWebAgent({ titleFontSize = '20px' }: InstallWebAgentProps) {
	const { t } = useTranslation();

	const { tenantUrl } = useSelector((state: RootState) => state.account);

	const [isCopied, setIsCopied] = useState(false);

	const copyCode = webAgentCodeBlock(tenantUrl);

	return (
		<div css={gap32Column}>
			<div css={gap8Column}>
				<div css={titleSt(titleFontSize)}>{t('agent.firstWebTitle')}</div>
				<div css={codeBlockBox}>
					<CopyToClipboard text={copyCode} onCopy={() => setIsCopied(true)}>
						<div css={copyButtonSt}>Copy</div>
					</CopyToClipboard>
					{isCopied ? <div css={copiedSt}>Copied!</div> : null}
					<CodeBlock
						text={copyCode}
						theme={dracula}
						language="html"
						codeBlock
						wrapLines
						customStyle={{
							backgroundColor: '#1E1F29',
							borderRadius: '8px',
							padding: '24px 16px',
						}}
					/>
				</div>
			</div>
			<DividerWithTxt dividerColor={paletteSDS.gray['300']} />
			<div css={gap24Column}>
				<div css={gap8Column}>
					<div css={titleSt(titleFontSize)}>{t('agent.secondWebTitle')}</div>
					<div css={guideSt}>{t('agent.secondWebGuide')}</div>
				</div>
				<div css={noticeBox}>
					<InfoIcon
						fill={paletteSDS.blue['500']}
						emotionCss={css`
							flex: none;
						`}
					/>
					<div css={[guideSt, colorPalette.black]}>
						<Trans i18nKey="agent.webNotice" components={{ bold: <strong /> }} />
					</div>
				</div>
			</div>
		</div>
	);
}

const gap32Column = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap24Column = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const titleSt = (titleFontSize: string) => css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${titleFontSize};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray['900']};

	white-space: pre-line;

	& > strong {
		font-weight: ${themeSDS.fontWeight.bold};
	}
`;

const noticeBox = css`
	display: flex;
	gap: 10px;
	width: 100%;

	padding: 12px 16px;

	white-space: pre;

	background: ${paletteSDS.gray['50']};
	border-radius: 8px;
	border: 1px solid ${paletteSDS.gray['200']};
`;

const codeBlockBox = css`
	position: relative;

	height: fit-content;

	border-radius: 8px;
`;

const copyButtonSt = css`
	position: absolute;

	top: 0;
	right: 0;

	width: 61px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.gray['800']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-top-right-radius: 8px;
	border-bottom-left-radius: 8px;

	cursor: pointer;
`;

const copiedSt = css`
	position: absolute;

	top: 0;
	right: 61px;

	width: 75px;
	height: 26px;

	padding: 7px 16px;

	background: ${paletteSDS.blue['500']};

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 100%;
	color: ${paletteSDS.gray['50']};

	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';

import { period } from '../api/getProjectUsageDetail.api';

import ActiveUsersDailyChart from './ActiveUsersDailyChart';
import ActiveUsersMonthlyChart from './ActiveUsersMonthlyChart';
import ActiveUsersYearlyChart from './ActiveUsersYearlyChart';

export interface ActiveUsersChartProps {
	chartType: period;
}

// todo : data 개수에 따른 category 및 데이터 값 설정
export default function ActiveUsersChart({ chartType }: ActiveUsersChartProps) {
	if (chartType === 'daily') {
		return <ActiveUsersDailyChart />;
	}

	if (chartType === 'monthly') {
		return <ActiveUsersMonthlyChart />;
	}

	return <ActiveUsersYearlyChart />;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
// components
import { Dropdown, Modal, Portal, themeSDS, DropdownElementList } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import type { userRoleType, userStatusType } from 'common/types/getOrganizationList.type';

import PermissionDescriptionTextBox from 'features/setting/components/PermissionDescriptionTextBox';
import type { MemberData } from '../types/getMemberList.type';

import { putMemberPermissionThunk } from '../thunk/putMemberPermission.thunk';

const { fontSize, fontWeight } = themeSDS;

interface ChangePermissionModalProps {
	visible: boolean;
	memberData: MemberData;
	toggleShowModal: () => void;
}

/**
 * author: Root
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-01(Jay)
 * description: 멤버 권한(role, state) 변경 모달
 * todo:
 */
export default function ChangePermissionModal({ visible, memberData, toggleShowModal }: ChangePermissionModalProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [role, setRole] = useState(memberData.role);
	const [memberState, setMemberState] = useState(memberData.state);
	const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

	const { isLoading, allMembers } = useSelector((state: RootState) => state.member);
	const { planOptions, priceType } = useSelector((state: RootState) => state.plan);

	const roleDropdownMenu: DropdownElementList =
		priceType !== 'BYOL'
			? [
					{
						name: t('commonWords.roleAdmin'),
						value: 'ADMINISTRATOR',
						disabled:
							memberData.role !== 'ADMINISTRATOR' &&
							allMembers.filter(el => el.state !== 'DISABLED').filter(member => member.role === 'ADMINISTRATOR')
								.length === planOptions[4].value,
					},
					{
						name: t('commonWords.roleOperator'),
						value: 'OPERATOR',
						disabled:
							memberData.role !== 'OPERATOR' &&
							allMembers.filter(el => el.state !== 'DISABLED').filter(member => member.role === 'OPERATOR').length ===
								planOptions[5].value,
					},
					{
						name: t('commonWords.roleMonitor'),
						value: 'MONITOR',
						disabled:
							memberData.role !== 'MONITOR' &&
							allMembers.filter(el => el.state !== 'DISABLED').filter(member => member.role === 'MONITOR').length ===
								planOptions[6].value,
					},
			  ]
			: [
					{
						name: t('commonWords.roleAdmin'),
						value: 'ADMINISTRATOR',
						disabled:
							memberData.role !== 'ADMINISTRATOR' &&
							allMembers.filter(el => el.state !== 'DISABLED').filter(member => member.role === 'ADMINISTRATOR')
								.length === planOptions[4].value,
					},
					{
						name: t('commonWords.roleOperator'),
						value: 'OPERATOR',
						disabled:
							memberData.role !== 'OPERATOR' &&
							allMembers.filter(el => el.state !== 'DISABLED').filter(member => member.role === 'OPERATOR').length ===
								planOptions[5].value,
					},
			  ];

	const stateDropdownMenu = [
		{ name: t('organizationSettings.active'), value: 'ACTIVE' },
		{ name: t('organizationSettings.disable'), value: 'DISABLED' },
	];

	/**
	 * description: 멤버 role 변경
	 */
	const handleChangeRoleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRole(e.target.value as userRoleType);
	};

	/**
	 * description: 멤버 state(active / disable) 변경
	 */
	const handleChangeStateInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setMemberState(e.target.value as userStatusType);
	};

	/**
	 * description: 멤버 권한(role / state) 변경 저장 버튼
	 */
	const handleClickSaveButton = async () => {
		dispatch(
			putMemberPermissionThunk({
				role,
				state: memberState,
				memberKey: memberData.key,
				product: memberData.productType,
				onClose: toggleShowModal,
			})
		);
	};

	return (
		<Portal>
			<Modal
				title={t('modal.changePermission')}
				visible={visible}
				actionType={isDropdownMenuOpen ? 'none' : 'double'}
				primaryBtnLabel={t('modal.editSave')}
				primaryBtnFunction={handleClickSaveButton}
				primaryBtnDisable={isLoading}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={toggleShowModal}
				isCloseIconVisible={false}
				center
			>
				<div css={modalBodyCss(isDropdownMenuOpen)}>
					<div>
						<div css={guideTextCss}>
							<Trans
								i18nKey="modal.changePermsMsg"
								values={{ email: memberData.email }}
								components={{ bold: <strong /> }}
							/>
						</div>
					</div>
					<Dropdown
						name="role"
						menu={roleDropdownMenu}
						bindedValue={role}
						label={t('commonWords.role')}
						width="100%"
						onChange={handleChangeRoleInput}
					/>
					<PermissionDescriptionTextBox role={role} />
					<Dropdown
						name="status"
						menu={stateDropdownMenu}
						bindedValue={memberState}
						label={t('organizationSettings.sts')}
						width="100%"
						onChange={handleChangeStateInput}
						onClickMenu={isOpen => {
							setIsDropdownMenuOpen(!isOpen);
						}}
					/>
				</div>
			</Modal>
		</Portal>
	);
}

const modalBodyCss = (isDropdownMenuOpen: boolean) => css`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	height: ${isDropdownMenuOpen ? '372px' : '282px'};
`;

const guideTextCss = css`
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.regular};
	line-height: 160%;
`;

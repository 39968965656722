/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { paletteSDS } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';
import OnPremAuthHeader from 'common/components/OnPremAuthHeader';
import OnPremFooter from 'common/components/OnPremFooter';

import { putI18nLanguage } from 'common/utils/languageFunc';
import { resetAccountInfos } from 'common/context/accountInfoSlice';

export default function OnPremAuthLayout() {
	const { locale } = useParams();
	const dispatch = useAppDispatch();

	useLayoutEffect(() => {
		(async () => {
			await putI18nLanguage(locale || 'en');
		})();

		dispatch(resetAccountInfos());
	}, [locale, dispatch]);

	return (
		<div css={containerSt}>
			<OnPremAuthHeader />
			<main css={outletFooterBoxSt}>
				<Outlet />
				<OnPremFooter />
			</main>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;

	overflow: auto;

	background: ${paletteSDS.gray[75]};
`;

const outletFooterBoxSt = css`
	flex: auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding: 16px;

	width: 100%;
	overflow: auto;
`;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { paletteSDS, themeSDS } from 'surf-design-system';
// etc

import GaugeBar from './GaugeBar';
import { MAX_GAUGE, MIN_GAUGE, TRACK_STROKE, BOUNDARY_FAST, BOUNDARY_MED } from '../data/constants';

/**
 * author: root, noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-08
 */

const { fontWeight, fontSize } = themeSDS;
const { gray, blue, systemColor, categoryColor } = paletteSDS;

interface ProcessStatusBarProps {
	value: number;
	isBlocked?: boolean;
	disabled?: boolean;
}

export default function ProcessStatusBar({ value, isBlocked = false, disabled }: ProcessStatusBarProps) {
	const { t } = useTranslation();

	const processStatusBarRef = useRef<HTMLDivElement>(null);

	let status = `${t('projectMonitoring.segStsSlow')}`;

	if (value < BOUNDARY_FAST) status = `${t('projectMonitoring.segStsFast')}`;
	if (value >= BOUNDARY_FAST && value < BOUNDARY_MED) status = `${t('projectMonitoring.segStsMed')}`;

	if (disabled)
		return (
			<div css={disabledCss} ref={processStatusBarRef}>
				{t('projectMonitoring.bypass')}
			</div>
		);

	if (isBlocked)
		return (
			<div css={blockedCss} ref={processStatusBarRef}>
				{t('projectMonitoring.segStsBlck')}
			</div>
		);

	return (
		<div css={processStatusBarCss} ref={processStatusBarRef}>
			<div css={statusCss(status)}>
				<div css={textWrapperCss}>
					<div>{status}</div>
					<div>{`${value}S`}</div>
				</div>
				<GaugeBar
					min={MIN_GAUGE}
					max={MAX_GAUGE}
					value={value}
					width={processStatusBarRef?.current?.offsetWidth || 0}
					trackStroke={TRACK_STROKE}
					trackColor="transparent"
					gaugeColor={statusColor[status].gauge}
				/>
			</div>
		</div>
	);
}

const processStatusBarCss = css`
	width: 100%;
	height: 2rem;
`;

const disabledCss = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 2rem;
	background-color: ${gray[75]};

	font-weight: ${fontWeight.medium};
	font-size: ${fontSize['14']};
	line-height: 100%;

	color: ${gray['700']};
`;

const blockedCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 2rem;
	background-color: ${gray[600]};
	color: ${systemColor.white};
`;

interface ColorSet {
	background: string;
	gauge: string;
}

interface StatusColor {
	[key: string]: ColorSet;
	Fast: ColorSet;
	Medium: ColorSet;
	Slow: ColorSet;
}

const statusColor: StatusColor = {
	Fast: { background: blue[400], gauge: blue[600] },
	원활: { background: blue[400], gauge: blue[600] },
	Medium: { background: categoryColor.mint, gauge: '#13827D' },
	대기: { background: categoryColor.mint, gauge: '#13827D' },
	Slow: { background: categoryColor.orange, gauge: '#CA5500' },
	지연: { background: categoryColor.orange, gauge: '#CA5500' },
};

const statusCss = (status: string) => css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	background-color: ${statusColor[status].background};
	color: ${systemColor.white};
	font-size: ${fontSize[14]};
	font-weight: ${fontWeight.regular};
`;

const textWrapperCss = css`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 1.75rem;
	padding: 0.5625rem 0.75rem;
`;

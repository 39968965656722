import { configureStore, Middleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import logger from 'redux-logger';

import layoutSlice from 'common/context/layoutSlice';
import accountInfoSlice from 'common/context/accountInfoSlice';
import modalSlice from 'common/context/modalSlice';
import notificationSlice from 'common/context/notificationSlice';
import projectMetaSlice from 'common/context/projectMetaSlice';
import toastSlice from 'common/context/toastSlice';
import tenantSlice from 'common/context/tenantSlice';

import vwrSlice from 'features/nfBasic/context/vwrSlice';
import planInfoSlice from 'features/plan/context/planInfoSlice';
import paymentInfoSlice from 'features/plan/context/paymentInfoSlice';
import addressSlice from 'features/plan/context/addressSlice';
import billingHistorySlice from 'features/plan/context/billingHistorySlice';
import usageSlice from 'features/plan/context/usageSlice';
import monitoringSlice from 'features/nfBasic/context/monitoringSlice';
import memberSlice from 'features/setting/context/memberSlice';
import intervalMonitoringSlice from 'features/nfInterval/context/intervalMonitoringSlice';
import eumSlice from 'features/nfEUM/context/eumSlice';
import eumTXNMapSlice from 'features/nfEUM/context/eumTXNMapSlice';
import projectInfoSlice from 'features/nfSetting/context/projectInfoSlice';
import vwrSliceV2 from 'features/vwr/context/vwrSlice.v2';
import nfControlSlice from 'features/nfControl/context/nfControlSlice';
import eumTXNRealtimeSlice from 'features/nfEUM/context/eumTXNRealtimeSlice';
import eumTXNTodaySlice from 'features/nfEUM/context/eumTXNTodaySlice';
import eumTXNMetricsSlice from 'features/nfEUM/context/eumTXNMetricsSlice';
import eumShareSlice from 'features/nfEUM/context/eumTXNShareSlice';
import onPremMemberSlice from 'features/setting/context/onPremMemberSlice';
import licenseSlice from 'features/license/context/licenseSlice';
import subscriptionSlice from 'features/plan/context/subscriptionSlice';

const rootReducer = {
	layout: layoutSlice,
	account: accountInfoSlice,
	modal: modalSlice,
	prjMeta: projectMetaSlice,
	noti: notificationSlice,
	vwr: vwrSlice,
	plan: planInfoSlice,
	payment: paymentInfoSlice,
	address: addressSlice,
	toast: toastSlice,
	billing: billingHistorySlice,
	usage: usageSlice,
	monitoring: monitoringSlice,
	member: memberSlice,
	interval: intervalMonitoringSlice,
	eum: eumSlice,
	eumTXNMap: eumTXNMapSlice,
	projectInfo: projectInfoSlice,
	vwr2: vwrSliceV2,
	nfControl: nfControlSlice,
	eumTXNRealtime: eumTXNRealtimeSlice,
	eumTXNShare: eumShareSlice,
	eumTXNToday: eumTXNTodaySlice,
	eumTXNMetrics: eumTXNMetricsSlice,
	onPremMember: onPremMemberSlice,
	license: licenseSlice,
	tenant: tenantSlice,
	subscription: subscriptionSlice,
};

const surffyMiddleware: Middleware[] = [];

if (process.env.REACT_APP_RELEASE_ENV === 'develop') {
	surffyMiddleware.push(logger);
}

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(surffyMiddleware),
	devTools: process.env.REACT_APP_RELEASE_ENV === 'develop',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

import axios from 'axios';

import { PostOauthTokenApiResponse } from 'common/types/postOauthToken.type';

export interface PostOauthTokenApiProps {
	code: string;
}

export default async function postOauthTokenApi({ code }: PostOauthTokenApiProps) {
	const oauthUrl = `https://${process.env.REACT_APP_AWS_OAUTH_DOMAIN}/oauth2/token`;
	const clientId = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID as string;
	const redirectUri = `${window.location.protocol}//${window.location.host}/callback`;

	const { data } = await axios.post<PostOauthTokenApiResponse>(
		oauthUrl,
		new URLSearchParams({
			grant_type: 'authorization_code',
			client_id: clientId,
			code,
			redirect_uri: redirectUri,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	);

	return data;
}

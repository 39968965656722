import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { DeleteSegmentGraphYnApiResponse } from '../types/deleteSegmentGraphYn.type';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 세그먼트 data를 그래프에 표시할지 여부 api
 * todo: tenant 별 db가 생기면 주석된 주소로 변경 필요
 */

export interface DeleteSegmentGraphYnApiProps {
	tenantUrl: string;
	tenantId: string;
	organizationId: number;
	prjId: number;
	segId: number;
}

export default async function deleteSegmentGraphYnApi({
	tenantUrl,
	tenantId,
	organizationId,
	prjId,
	segId,
}: DeleteSegmentGraphYnApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const url = `${process.env.REACT_APP_BASE_URL}/v1/project/${prjId}/segment/${segId}/graph-option`;

		const { data } = await axios.delete<DeleteSegmentGraphYnApiResponse>(url, {
			data: { segmentGraphYn: false },
			headers: {
				Authorization: cognitoToken,
				userKey,
				organizationId,
				tenantId,
			},
		});
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

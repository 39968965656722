/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, ChevronRightIcon, InfoOutlineIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';
import useApiRequestWithArg from 'common/hooks/useApiRequestWithArg';
import { LANG_PATH } from 'common/data/constants';

import SurffyStatus from 'features/home/components/SurffyStatus';
import getLicenseInfoApi from 'features/license/api/getLicenseInfo.api';

export default function OnPremSurffyHomeRunning() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { sendRequest: getLicenseInfo, response } = useApiRequestWithArg(getLicenseInfoApi);

	const { userName, language, organizationId, tenantId, userRole } = useSelector((state: RootState) => state.account);

	const netFunnelClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/console/product/nf/home`);
	};

	const manageLicenseClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/on_prem/home/usage_license/license`);
	};

	useEffect(() => {
		getLicenseInfo({ organizationId, tenantId: tenantId as string });
	}, [getLicenseInfo, organizationId, tenantId]);

	if ((response?.data.expiredLicenseCnt || 0) === 0) {
		return (
			<div css={container}>
				<h3>{t('console.main.mainMsg', { name: userName })}</h3>
				<SurffyStatus />
				<DividerWithTxt />
				<h4>{t('console.main.surffyInUse')}</h4>
				<div css={productBox}>
					<div css={netfunnelSt}>NetFUNNEL</div>
					<div css={gap4pxColumn}>
						<div css={gap8px}>
							<div css={productInfoLabelSt}>{t('onPrem.surffyHome.totalLicense')}</div>
							<div css={productInfoSt}>{response?.data.ownLicenseCnt || 0}</div>
						</div>
						<div css={gap8px}>
							<div css={productInfoLabelSt}>{t('onPrem.surffyHome.expiringLicense')}</div>
							<div css={productInfoSt}>{response?.data.expiredLicenseCnt || 0}</div>
						</div>
					</div>
					<Button
						mould="primary"
						icon={<ChevronRightIcon fill={paletteSDS.gray['50']} />}
						size="large"
						iconPosition="right"
						onClick={netFunnelClickHandler}
					>
						{t('onPrem.surffyHome.goToNf')}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div css={container}>
			<h3>{t('console.main.mainMsg', { name: userName })}</h3>
			<SurffyStatus />
			<DividerWithTxt />
			<h4>{t('console.main.surffyInUse')}</h4>
			<div css={gap8pxColumn}>
				<div css={productBox}>
					<div css={netfunnelSt}>NetFUNNEL</div>
					<div css={gap4pxColumn}>
						<div css={gap8px}>
							<div css={productInfoLabelSt}>{t('onPrem.surffyHome.totalLicense')}</div>
							<div css={productInfoSt}>{response?.data.ownLicenseCnt || 0}</div>
						</div>
						<div css={gap8px}>
							<div css={productInfoLabelSt}>{t('onPrem.surffyHome.expiringLicense')}</div>
							<div css={productInfoSt}>{response?.data.expiredLicenseCnt || 0}</div>
						</div>
					</div>
					<Button
						mould="primary"
						icon={<ChevronRightIcon fill={paletteSDS.gray['50']} />}
						size="large"
						iconPosition="right"
						onClick={netFunnelClickHandler}
					>
						{t('onPrem.surffyHome.goToNf')}
					</Button>
				</div>
				<div css={licenseBox}>
					<div css={licenseTitle}>
						{t(
							response?.data.ownLicenseCnt === response?.data.expiredLicenseCnt
								? 'onPrem.surffyHome.allLicenseExpired'
								: 'onPrem.surffyHome.youHaveExpiredLicense'
						)}
					</div>
					<div css={licenseGuideBox}>
						<InfoOutlineIcon cursor="default" fill={paletteSDS.blue['500']} />
						<div css={licenseGuide}>{t('onPrem.surffyHome.expiredLicenseGuide')}</div>
					</div>
					{userRole === 'OWNER' ? (
						<Button mould="secondary" onClick={manageLicenseClickHandler} size="medium">
							{t('onPrem.surffyHome.manageLicense')}
						</Button>
					) : null}
				</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	padding: 32px 24px 80px;
`;

const productBox = css`
	display: flex;
	align-items: flex-start;
	gap: 8px;

	width: 100%;
	height: 104px;

	padding: 24px;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};
	border-radius: 8px;
`;

const netfunnelSt = css`
	width: 160px;

	color: ${paletteSDS.blue['800']};
	font-weight: 800;
	font-size: ${themeSDS.fontSize['20']};
	line-height: 140%;
`;

const gap8pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const gap4pxColumn = css`
	flex: auto;

	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const gap8px = css`
	display: flex;
	align-items: center;
	gap: 8px;

	width: 100%;
`;

const productInfoLabelSt = css`
	flex: none;

	width: 160px;

	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize['16']};

	line-height: 160%;
`;

const productInfoSt = css`
	flex: auto;

	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['16']};

	line-height: 140%;
`;

const licenseBox = css`
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	gap: 16px;

	width: 100%;

	padding: 24px;

	background: ${paletteSDS.gray['50']};

	border: 1px solid ${paletteSDS.gray['400']};
	border-radius: 8px;
`;

const licenseTitle = css`
	align-self: flex-start;

	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize['20']};
	line-height: 140%;
`;

const licenseGuideBox = css`
	display: flex;
	gap: 8px;

	width: 100%;

	padding: 12px 16px 12px 16px;

	background: ${paletteSDS.blue['50']};

	border: 1px solid ${paletteSDS.blue['200']};
	border-radius: 8px;
`;

const licenseGuide = css`
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.black};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;

	white-space: pre;
`;

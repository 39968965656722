import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import {
	toggleIsServiceDomainSettingModalVisible,
	toggleIsUpdateMainDomainConfirmModalVisible,
} from 'common/context/modalSlice';
import { toggleIsPutServiceDomainFailToastVisible } from 'common/context/toastSlice';

import { putServiceDomainTenantApi } from 'features/nfSetting/api/putServiceDomain.v1.api';

export interface PutServiceDomainThunkProps {
	enteredCustomerDomain: string;
}

export interface PutServiceDomainThunkResult {
	projectId: number;
	customerDomain: string;
}

export const putServiceDomainThunk = createAsyncThunk<
	PutServiceDomainThunkResult,
	PutServiceDomainThunkProps,
	{ state: RootState }
>('account/put/customerDomain/v2', async (args: PutServiceDomainThunkProps, thunkAPI) => {
	const { enteredCustomerDomain } = args;

	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const result = await putServiceDomainTenantApi({
			organizationId,
			customerServiceDomain: enteredCustomerDomain,
			tenantId: tenantId as string,
			projectId: selectedProjectId,
		});

		const { data: tenantData } = result;

		thunkAPI.dispatch(toggleIsUpdateMainDomainConfirmModalVisible());

		thunkAPI.dispatch(toggleIsServiceDomainSettingModalVisible());

		return { projectId: tenantData.id, customerDomain: enteredCustomerDomain };
		// eslint-disable-next-line
	} catch (error: any) {
		thunkAPI.dispatch(toggleIsUpdateMainDomainConfirmModalVisible());

		thunkAPI.dispatch(toggleIsPutServiceDomainFailToastVisible());

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { GetRealtimeChargeApiResponse } from '../types/getRealtimeCharge.type';

export interface GetRealtimeChargeApiProps {
	organizationId: number;
}

export default async function getRealtimeChargeApi({ organizationId }: GetRealtimeChargeApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetRealtimeChargeApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/payment/charge/real-time`,
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';

import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal, Textinput, Portal } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { toggleIsOrganizationNameModalVisible } from 'common/context/modalSlice';
import { MAX_ORGANIZATION_CHAR_NUM } from '../data/constants';

import {
	organizationNameFormReducer,
	initialOrganizationNameForm,
	UPDATE_NAME,
	VALIDATE_NAME,
} from '../context/organizationNameForm.reducer';

import { putOrganizationNameThunk } from '../thunk/putOrganizationName.thunk';

/**
 * author: Jay
 * createdAt: 2022-07-29
 * updatedAt: 2022-08-19
 * description: 조직명 변경 모달
 * todo:
 */

export default function OrganizationNameModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { organizationName, isLoading } = useSelector((state: RootState) => state.account);
	const { isOrganizationNameModalVisible } = useSelector((state: RootState) => state.modal);

	const [organizationNameFormData, setOrganizationNameFormData] = useReducer(organizationNameFormReducer, {
		...initialOrganizationNameForm,
		organizationName,
	});

	const {
		organizationName: enteredOrganizationName,
		organizationNameHelpText,
		organizationNameValidation,
		isOrganizationNameChanged,
		isOrganizationNameValid,
	} = organizationNameFormData;

	/**
	 * description: 조직명 변경 제어
	 */
	const nameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOrganizationNameFormData({ type: UPDATE_NAME, payload: e.target.value });
	};

	const nameBlurHandler = () => {
		setOrganizationNameFormData({ type: VALIDATE_NAME });
	};

	/**
	 * description: 이전 조직명-변경할 조직명 동일 여부 확인 validation
	 */

	/**
	 * description:  모달 취소 버튼 클릭시 제어
	 */
	const secondaryButtonClickHandler = () => {
		dispatch(toggleIsOrganizationNameModalVisible());
	};

	/**
	 * description: 조직명 변경 버튼 클릭시 api 호출
	 */
	const primaryButtonClickHandler = async () => {
		await dispatch(putOrganizationNameThunk({ name: enteredOrganizationName }));
		dispatch(toggleIsOrganizationNameModalVisible());
	};
	return (
		<Portal>
			<Modal
				visible={isOrganizationNameModalVisible}
				title={t('modal.changeOrgName')}
				isCloseIconVisible={false}
				actionType="double"
				primaryBtnLabel={t('organizationSettings.save')}
				primaryBtnFunction={primaryButtonClickHandler}
				secondaryBtnLabel={t('commonWords.cancel')}
				secondaryBtnFunction={secondaryButtonClickHandler}
				primaryBtnDisable={!isOrganizationNameValid || isLoading}
			>
				<Textinput
					label={t('organizationSettings.name')}
					helpText={isOrganizationNameChanged && !isOrganizationNameValid && t(organizationNameHelpText)}
					validation={isOrganizationNameChanged ? organizationNameValidation : 'none'}
					showIcon
					maxLength={MAX_ORGANIZATION_CHAR_NUM}
					value={enteredOrganizationName}
					onChange={nameChangeHandler}
					onBlur={nameBlurHandler}
				/>
			</Modal>
		</Portal>
	);
}

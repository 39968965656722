/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Portal } from 'surf-design-system';

import { toggleIsServiceDomainSettingModalVisible } from 'common/context/modalSlice';
import { useAppDispatch, RootState } from 'common/redux/store';

export default function ServiceDomainSettingModal() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isServiceDomainSettingModalVisible } = useSelector((state: RootState) => state.modal);

	const primaryButtonClickHandler = async () => {
		dispatch(toggleIsServiceDomainSettingModalVisible());
	};

	return (
		<Portal>
			<Modal
				title={t('modal.mainDomainUpdateComplete')}
				visible={isServiceDomainSettingModalVisible}
				actionType="single"
				primaryBtnLabel={t('modal.segConfirm')}
				primaryBtnFunction={primaryButtonClickHandler}
				isCloseIconVisible={false}
			>
				<div css={finishCmtSt}>{t('modal.domainConfirmMsg')}</div>
			</Modal>
		</Portal>
	);
}

const finishCmtSt = css`
	text-align: center;
	line-height: 160%;
	white-space: pre-line;
`;

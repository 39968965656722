import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getLicenseUsageApi from 'features/license/api/getLicenseUsage.api';

import type { LicenseUsageContent } from 'features/license/types/getLicenseProjectUsage.type';
import moment from 'moment';

interface GetLicenseUsageThunkResult {
	content: LicenseUsageContent[];
	totalPages: number;
}

export const getLicenseUsageThunk = createAsyncThunk<GetLicenseUsageThunkResult, void, { state: RootState }>(
	'license/usage/get',
	async (_, thunkAPI) => {
		const {
			account: { tenantId, organizationId },
			license: {
				usage: { selectedPage, selectedId, searchEndDate, searchStartDate },
			},
		} = thunkAPI.getState();

		try {
			const { data } = await getLicenseUsageApi({
				tenantId: tenantId || '',
				organizationId,
				searchStartDate: Number(moment(searchStartDate).utcOffset('+UTC09:00').startOf('day').format('X')),
				searchEndDate: Number(moment(searchEndDate).utcOffset('+UTC09:00').endOf('day').format('X')),
				pageNum: selectedPage - 1,
				projectId: selectedId,
			});

			return {
				content: [...data.content],
				totalPages: data.totalPages,
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

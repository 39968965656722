/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { RootState, useAppDispatch } from 'common/redux/store';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CloseIcon, FileDownloadIcon, paletteSDS, SearchIcon, Textinput } from 'surf-design-system';
import { CSVLink } from 'react-csv';
import {
	resetMetricsData,
	setMetricsIntervalTime,
	setMetricsSearchValue,
} from 'features/nfEUM/context/eumTXNMetricsSlice';
import { getTXNMetricsThunk } from 'features/nfEUM/thunk/getTXNMetrics.thunk';
import TXNMetricsTable from 'features/nfEUM/components/TXNMetricsTable';

let intervalTimer: NodeJS.Timer | undefined;

export default function TXNMetrics() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const CSVHeaders = [
		{ label: 'host', key: 'host' },
		{ label: 'url', key: 'uri' },
		{ label: t('eum.load'), key: 'count' },
		{ label: t('eum.loadTime'), key: 'pt' },
		{ label: t('eum.category'), key: 'category' },
	];

	const { metricsData, searchValue } = useSelector((state: RootState) => state.eumTXNMetrics);

	const [isSearchInputFocus, setIsSearchInputFocus] = useState(false);

	const searchInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		dispatch(setMetricsSearchValue(value));
		debounce(() => dispatch(getTXNMetricsThunk()), 300);
	};

	const [currIntervalSec, setCurrIntervalSec] = useState(false);

	useEffect(() => {
		clearInterval(intervalTimer);

		intervalTimer = setInterval(async () => {
			if (moment().seconds() % 3 === 0 && !currIntervalSec) {
				setCurrIntervalSec(true);
				dispatch(
					setMetricsIntervalTime({
						beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
						endTime: moment().subtract(7, 's').unix(),
					})
				);
				dispatch(getTXNMetricsThunk());
			} else if (moment().seconds() % 3 !== 0) setCurrIntervalSec(false);
		}, 300);
	}, [currIntervalSec, dispatch]);

	useLayoutEffect(() => {
		dispatch(
			setMetricsIntervalTime({
				beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
				endTime: moment().subtract(7, 's').unix(),
			})
		);
		dispatch(getTXNMetricsThunk());

		return () => {
			clearInterval(intervalTimer);
			dispatch(resetMetricsData());
		};
	}, [dispatch]);

	return (
		<div css={container}>
			<div css={header}>
				<span>{t('eum.metrics')}</span>
				<div css={inputWrapper}>
					<Textinput
						name="search"
						placeholder={t('eum.searchPath')}
						value={searchValue}
						icon={
							isSearchInputFocus ? (
								<CloseIcon
									fill={paletteSDS.gray['700']}
									size="xSmall"
									onMouseDown={e => e.preventDefault()}
									onClick={() => dispatch(setMetricsSearchValue(''))}
								/>
							) : (
								<SearchIcon fill={paletteSDS.gray['700']} size="xSmall" cursor="default" />
							)
						}
						showIcon
						width="200px"
						size="small"
						onChange={searchInputChangeHandler}
						onFocus={() => setIsSearchInputFocus(true)}
						onBlur={() => setIsSearchInputFocus(false)}
						iconPosition={isSearchInputFocus ? 'right' : 'left'}
						hideIconWhenFocus={false}
					/>
					<CSVLink
						headers={CSVHeaders}
						data={metricsData}
						filename={`${moment().format('YYYYMMDD_HHmmss')}_Surffy_EUM_Metrics`}
					>
						<button type="button" css={csvBtn} aria-label="Download">
							<FileDownloadIcon size="xSmall" fill={paletteSDS.blue[700]} />
						</button>
					</CSVLink>
				</div>
			</div>
			<TXNMetricsTable />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const header = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${paletteSDS.gray[900]};
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
`;

const inputWrapper = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const csvBtn = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${paletteSDS.blue[700]};
	border-radius: 6px;
	width: 32px;
	height: 32px;
	cursor: pointer;
	background-color: ${paletteSDS.gray[50]};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import type { RootState } from 'common/redux/store';
import getProjectUsageSummaryApi from 'features/plan/api/getProjectUsageSummary.v2.api';
import getUsageSummaryApi from 'features/plan/api/getUsageSummary.api';

import type { GetProjectUsageSummaryData } from 'features/plan/types/getProjectUsageSummary.v2.type';

interface GetProjectUsageSummaryThunkResult {
	data: GetProjectUsageSummaryData;
	monitor: number;
}

export const getProjectUsageSummaryThunk = createAsyncThunk<
	GetProjectUsageSummaryThunkResult,
	void,
	{ state: RootState }
>('projectUsageSummary/get', async (args, thunkAPI) => {
	const {
		account: { organizationId, tenantId },
		usage: { initialMomentValue },
		prjMeta: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const { data } = await getProjectUsageSummaryApi({
			organizationId,
			date: Number(moment(initialMomentValue).format('X')),
			tenantId: tenantId as string,
			projectId: selectedProjectId,
		});

		const {
			data: { monitor },
		} = await getUsageSummaryApi({ organizationId });

		return {
			data: { ...data },
			monitor,
		};
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import DividerWithTxt from 'common/components/DividerWithTxt';

export default function CreateProjectLayout() {
	const { t } = useTranslation();

	return (
		<div css={container}>
			<div css={headerBox}>
				<div css={titleSt}>{t('console.nfHome.createProject')}</div>
				<div css={subTitleSt}>{t('console.nfHome.createProjectSubtitle')}</div>
			</div>
			<DividerWithTxt />
			<Outlet />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 414px;
	padding: 32px 24px 80px;

	margin: 0 auto;
`;

const titleSt = css`
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['24']};
	color: ${paletteSDS.gray['900']};

	line-height: 140%;
`;

const subTitleSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize['14']};
	color: ${paletteSDS.gray['700']};

	line-height: 128%;

	letter-spacing: 0.16px;
`;

const headerBox = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import { initializeProjectManagementModal } from 'common/context/modalSlice';

import { putProjectActivatedYnApi } from 'features/nfSetting/api/putProjectActivatedYn.v1.api';

export interface PutProjectActivatedYnThunkProps {
	activatedYn: boolean;
	selectedProjectId: number;
}

export interface PutProjectActivatedYnThunkResult {
	activatedYn: boolean;
	projectId: number;
	updatedAt: string;
}

export const putProjectActivatedYnThunk = createAsyncThunk<
	PutProjectActivatedYnThunkResult,
	PutProjectActivatedYnThunkProps,
	{ state: RootState }
>('nfPrj/put/activatedYn/v2', async (args: PutProjectActivatedYnThunkProps, thunkAPI) => {
	const { activatedYn, selectedProjectId } = args;

	const {
		account: { tenantId, organizationId },
	} = thunkAPI.getState();

	try {
		const {
			data: { id, updatedAt },
		} = await putProjectActivatedYnApi({
			organizationId,
			tenantId: tenantId as string,
			projectId: selectedProjectId,
			activatedYn,
		});

		thunkAPI.dispatch(initializeProjectManagementModal());

		return { projectId: id, activatedYn, updatedAt };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { paletteSDS } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import OnPremGNB from 'common/components/OnPremGNB';
import OnPremToasts from 'common/components/OnPremToasts';
import { NOTI_START_PAGE } from 'common/data/constants';
import { getNotificationListThunk } from 'common/thunk/getNotificationList.thunk';
import useOnPremAccountSetting from 'common/hooks/useOnPremAccountSetting';

import { getProjectListThunk } from 'features/nfSetting/thunk/getProjectList.v2.thunk';
import { getTenantHealthCheckListThunk } from 'common/thunk/getTenantHealthCheckList.thunk';

let healthCheckInterval: NodeJS.Timer | undefined;

export default function OnPremConsoleLayout() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { id } = useParams();

	const { pathname } = location;

	const { isLoaded: isAccountLoaded, language } = useSelector((state: RootState) => state.account);

	const { isLoaded: isProjectMetaLoaded } = useSelector((state: RootState) => state.projectInfo);

	useOnPremAccountSetting({});

	useEffect(() => {
		if (isAccountLoaded) {
			dispatch(getNotificationListThunk({ pageNum: NOTI_START_PAGE }));
			dispatch(getProjectListThunk({ initialProjectId: id ? Number(id) : undefined }));
		}
	}, [dispatch, isAccountLoaded, language, pathname, id]);

	// useEffect(() => {
	// 	dispatch(getTenantHealthCheckListThunk());
	// 	healthCheckInterval = setInterval(() => {
	// 		dispatch(getTenantHealthCheckListThunk());
	// 	}, 60000);
	// 	return () => clearInterval(healthCheckInterval);
	// }, [dispatch]);

	return (
		<div css={container}>
			<OnPremGNB />
			<OnPremToasts />
			<main css={outletBox}>{!isAccountLoaded || !isProjectMetaLoaded ? <div /> : <Outlet />}</main>
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
`;

const outletBox = css`
	width: 100%;
	height: 100%;

	overflow: auto;

	background: ${paletteSDS.gray['75']};
`;

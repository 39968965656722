/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { paletteSDS } from 'surf-design-system';
import { RootState } from 'common/redux/store';
import useUsageStackChartOptions from 'features/plan/hooks/useUsageStackChartOptions';

import UsageChartBox from './UsageChartBox';
import UsageChartTitle from './UsageChartTitle';

// todo : data 개수에 따른 category 및 데이터 값 설정
export default function ActiveUsersDailyChart() {
	const { t } = useTranslation();

	const { chart } = useSelector((state: RootState) => state.usage);

	const chartDataByType = chart.daily;

	const { options } = useUsageStackChartOptions({
		maxY: chartDataByType.basicMonitoring.maxActiveUser + chartDataByType.pathControl.maxActiveUser,
		chartType: 'daily',
		date: chartDataByType.basicMonitoring.activeUser.filter(el => el !== null).length,
	});

	return (
		<UsageChartBox>
			<UsageChartTitle
				title={t('planSubscription.activeUsers')}
				labels={[
					{ id: 0, color: paletteSDS.categoryColor.blue, label: t('planSubscription.basic') },
					{ id: 1, color: paletteSDS.categoryColor.mentis, label: t('planSubscription.path') },
				]}
			/>
			{chartDataByType.isLoaded ? (
				<ReactApexChart
					series={[
						{
							name: 'basic',
							data: chartDataByType.basicMonitoring.activeUser,
							color: paletteSDS.categoryColor.blue,
						},
						{
							name: 'path',
							data: chartDataByType.pathControl.activeUser,
							color: paletteSDS.categoryColor.mentis,
						},
					]}
					type="bar"
					options={options}
					height="250px"
				/>
			) : (
				<div css={emptySt} />
			)}
		</UsageChartBox>
	);
}

const emptySt = css`
	height: 250px;
`;

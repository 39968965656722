import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';

import type { GetLicenseListApiResponse } from 'features/license/types/getLicenseList.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export interface GetLicenseListApiProps {
	organizationId: number;
	tenantId: string;
}

export default async function getLicenseListApi({ tenantId, organizationId }: GetLicenseListApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetLicenseListApiResponse>(
			`${getOnPremBaseUrl(ONPREM_CENTER_API_PATH)}/v2/onprem/license/list`,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

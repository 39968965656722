/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Link, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';
import { SurffyLogo } from 'common/assets/icons';

export default function InvoiceFooter() {
	const { t } = useTranslation();

	const { language } = useSelector((state: RootState) => state.account);

	const handleShowInvoice = () => {
		// eslint-disable-next-line
		alert('매출 전표 링크');
		// window.open('');
	};

	return (
		<div css={containerSt}>
			<div css={upperBox}>
				{t('planSubscription.invoiceMsg')}
				<Link size="medium" onClick={handleShowInvoice}>
					{t('planSubscription.invoiceMsg1')}
				</Link>
			</div>
			<div css={middleBox}>
				<div css={logoBoxSt}>
					<SurffyLogo />
				</div>
				<div css={textSt}>
					<p>{t('planSubscription.invoiceMsg2')}</p>
					<p>
						{t('planSubscription.invoiceMsg3')}
						{language === 'KOREAN' && <a href="mailto:support@stclab.com">support@stclab.com</a>}
						{t('planSubscription.invoiceMsg4')}
						{language === 'ENGLISH' && <a href="mailto:support@stclab.com">support@stclab.com</a>}
					</p>
				</div>
			</div>
			<div css={lowerBox}>
				<p>STCLab Inc. | www.stclab.com</p>
				<p>2nd floor, CampG, 5, Bongeunsa-ro 37-gil, Gangnam-gu, Seoul, Republic of Korea</p>
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const upperBox = css`
	display: flex;

	height: 2.875rem;
	padding: 1rem;

	border: 1px solid ${paletteSDS.gray[400]};
	border-radius: 4px;

	font-size: ${themeSDS.fontSize[14]};
	line-height: 100%;
	background-color: ${paletteSDS.white};

	> span {
		margin-left: 0.25rem;
	}
`;

const middleBox = css`
	display: flex;

	height: 5.1875rem;

	border: 1px solid ${paletteSDS.gray[400]};
	border-radius: 4px;

	background-color: ${paletteSDS.white};
`;

const logoBoxSt = css`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 6.25rem;

	background-color: ${paletteSDS.blue[800]};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

const textSt = css`
	padding: 1.125rem 1.5625rem;
	height: 2.9375rem;
	line-height: 160%;

	> p:nth-of-type(1) {
		font-weight: 600;
	}

	> p:nth-of-type(2) {
		font-size: ${themeSDS.fontSize[14]};

		> a {
			color: ${paletteSDS.blue[700]};
			font-weight: 500;
		}
	}
`;

const lowerBox = css`
	display: flex;
	flex-direction: column;
	justify-content: center;

	height: 2.75rem;
	line-height: 160%;

	color: ${paletteSDS.gray[700]};
	font-size: ${themeSDS.fontSize[14]};
`;

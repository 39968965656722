/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'common/redux/store';
import { setInitialToast } from 'common/context/toastSlice';

import TXNLowerContainer from 'features/nfEUM/components/TXNLowerContainer';
import TXNUpperContainer from 'features/nfEUM/components/TXNUpperContainer';
import { InfoIcon, paletteSDS, themeSDS } from 'surf-design-system';

export default function EUMTransactionPage() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		return () => {
			dispatch(setInitialToast());
		};
	}, [dispatch]);

	return (
		<div css={container}>
			<div css={cautionWrap}>
				<InfoIcon fill={`${paletteSDS.blue[500]}`} width="24" height="24" />
				<div css={cautionSt}>{t('eum.topNoti')}</div>
			</div>
			<TXNUpperContainer />
			<TXNLowerContainer />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`;

const cautionWrap = css`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	border: 1px solid ${paletteSDS.gray[200]};
	border-radius: 8px;
	width: 100%;
	max-width: 1615px;
	height: 48px;
	padding-left: 16px;
	background: ${paletteSDS.gray[50]};
`;

const cautionSt = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 160%;
	color: ${paletteSDS.gray[900]};
	white-space: pre-wrap;
`;

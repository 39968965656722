/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import NotificationIcon from 'common/components/NotificationIcon';
import {
	NOTI_ASK,
	NOTI_DOMAIN,
	NOTI_NOTICE,
	NOTI_PRJ,
	NOTI_SEG,
	NOTI_SSEG,
	NOTI_VWR,
	// LANG_PATH,
	PLAN_FREE_TRIAL_ID,
	// NF_CONTROLS_VIEW_MODE_KEY,
	ONPREM_PRODUCT_MODE,
} from 'common/data/constants';

import { NotificationData } from 'common/types/getNotificationList.type';
import { toggleIsFreetrialEndModalVisible } from 'common/context/modalSlice';

import { changeExpandedNotificationId } from '../context/notificationSlice';
import { putNotificationReadYnThunk } from '../thunk/putNotificationReadYn.thunk';

export interface NotificationContentProps {
	notification: NotificationData;
	notificationIdx: number;
	notificationLength: number;
}

/**
 * author: ian
 * createdAt: 2022-07-08
 * updatedAt: 2022-08-16
 * description: 알림들을 렌더링 하는 컴포넌트
 */
export default function NotificationContent({
	notification,
	notificationIdx,
	notificationLength,
}: NotificationContentProps) {
	const { expandedNotificationId } = useSelector((state: RootState) => state.noti);
	const { isFreetrialEnd } = useSelector((state: RootState) => state.account);
	// const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const { planId } = useSelector((state: RootState) => state.plan);

	// const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const notificationClickHandler = async () => {
		if (!notification.readYn) {
			await dispatch(
				putNotificationReadYnThunk({
					notificationId: notification.id,
				})
			);
		}

		switch (notification.notiType) {
			case NOTI_ASK:
			case NOTI_NOTICE:
				dispatch(
					changeExpandedNotificationId({ notificationId: notification.id, notificationType: notification.notiType })
				);

				break;

			case NOTI_VWR:
				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_PRJ:
				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_DOMAIN:
				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_SEG:
				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			case NOTI_SSEG:
				if (isFreetrialEnd && planId === PLAN_FREE_TRIAL_ID) {
					dispatch(toggleIsFreetrialEndModalVisible());
					break;
				}

				if (process.env.REACT_APP_PRODUCT_MODE === ONPREM_PRODUCT_MODE) {
					break;
				}

				break;

			default:
				break;
		}
	};

	return (
		<div css={listSpecificWrap}>
			<div
				css={listContainerSt(notificationIdx, notificationLength, expandedNotificationId, notification.id)}
				onClick={notificationClickHandler}
				aria-hidden
			>
				<div css={iconWrapSt}>
					<NotificationIcon iconType={notification.iconType} />
				</div>
				<div css={categoryTitleWrapSt}>
					<div css={categorySt(notification.readYn)}>{notification.header}</div>
					<div css={titleSt(notification.readYn)}>{notification.title}</div>
				</div>
				<div css={dateSt(notification.readYn)}>
					{moment(notification.createdAt).format('YYYY-MM-DD')}&nbsp;{moment(notification.createdAt).format('HH:mm:ss')}
				</div>
			</div>
			<div css={specificSt(notificationIdx, notificationLength, notification.id, expandedNotificationId)}>
				{notification.body}
			</div>
		</div>
	);
}

const listSpecificWrap = css`
	display: flex;
	flex-direction: column;
	gap: 0;
`;

// TODO: specific 글자수 제한
const specificSt = (idx: number, length: number, id: number, nowOpenId: number | null) => css`
	width: 100%;
	height: ${id === nowOpenId ? '100%' : '0rem'};

	padding: ${id === nowOpenId && '2rem 2rem 2rem 4rem'};

	background: ${paletteSDS.gray[100]};

	border-left: 1px solid ${paletteSDS.gray[300]};
	border-right: 1px solid ${paletteSDS.gray[300]};
	border-bottom: ${id === nowOpenId && `1px solid ${paletteSDS.gray[300]}`};

	border-bottom-left-radius: ${idx === length - 1 && '4px'};
	border-bottom-right-radius: ${idx === length - 1 && '4px'};

	word-break: break-all;
	white-space: pre;
	line-height: 160%;

	transition: height linear 0.3s, padding-top linear 0.3s, padding-bottom linear 0.3s, border-left linear 0.3s,
		border-right linear 0.3s, color step-start 0.3s;

	color: ${id === nowOpenId ? `${paletteSDS.gray[900]}` : 'transparent'};

	cursor: unset;
`;

const listContainerSt = (idx: number, length: number, nowOpenId: number | null, id: number | undefined) => css`
	display: flex;
	align-items: center;
	gap: 12px;

	padding: 12px 16px 12px 16px;

	border-left: 1px solid ${paletteSDS.gray[300]};
	border-right: 1px solid ${paletteSDS.gray[300]};

	border-top: ${!idx && `1px solid ${paletteSDS.gray[300]}`};
	border-top-left-radius: ${!idx && '4px'};
	border-top-right-radius: ${!idx && '4px'};

	border-bottom: 1px solid ${paletteSDS.gray[300]};
	border-bottom-left-radius: ${idx === length - 1 && nowOpenId !== id && '4px'};
	border-bottom-right-radius: ${idx === length - 1 && nowOpenId !== id && '4px'};

	background: ${paletteSDS.white};

	cursor: pointer;
`;

const iconWrapSt = css`
	display: flex;

	align-items: center;
	justify-content: center;

	width: 32px;
	height: 32px;

	padding: 4px 0;
`;

const categoryTitleWrapSt = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	flex: auto;

	height: 44px;
`;

const categorySt = (readYn: boolean) => css`
	flex: auto;
	max-width: 25rem;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[12]};
	color: ${readYn ? paletteSDS.gray[300] : paletteSDS.gray[700]};

	line-height: 160%;
`;

const titleSt = (readYn: boolean) => css`
	flex: auto;

	width: 708px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[14]};
	color: ${readYn ? paletteSDS.gray[500] : paletteSDS.gray[900]};

	line-height: 160%;
`;

const dateSt = (readYn: boolean) => css`
	display: flex;

	width: 114px;
	height: 44px;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[11]};
	color: ${readYn ? paletteSDS.gray[300] : paletteSDS.gray[500]};

	line-height: 140%;
	letter-spacing: 0.32px;

	white-space: nowrap;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AmCharts from '@amcharts/amcharts3-react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { paletteSDS } from 'surf-design-system';
import { updateCustomMarkers, mapObjectClickHandler } from 'features/nfEUM/utils/ammap';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'common/redux/store';
import { resetMapData, setCountryCode, setMapIntervalTime } from 'features/nfEUM/context/eumTXNMapSlice';
import { getTXNMapCountryThunk } from 'features/nfEUM/thunk/getTXNMapCountry.thunk';
import moment from 'moment';
import { getTXNMapCityThunk } from 'features/nfEUM/thunk/getTXNMapCity.thunk';

let intervalTimer: NodeJS.Timer | undefined;

export default function TXNMap() {
	const dispatch = useAppDispatch();
	const { dataProvider, homeBtnClicked } = useSelector((state: RootState) => state.eumTXNMap);

	const countryMapMode = () => {
		dispatch(getTXNMapCityThunk());
	};

	const [currIntervalSec, setCurrIntervalSec] = useState(false);

	useEffect(() => {
		clearInterval(intervalTimer);

		intervalTimer = setInterval(async () => {
			if (moment().seconds() % 3 === 0 && !currIntervalSec) {
				setCurrIntervalSec(true);
				dispatch(
					setMapIntervalTime({
						beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
						endTime: moment().subtract(7, 's').unix(),
					})
				);
				if (dataProvider.map === 'worldLow') dispatch(getTXNMapCountryThunk());
				else dispatch(getTXNMapCityThunk());
			} else if (moment().seconds() % 3 !== 0) setCurrIntervalSec(false);
		}, 300);
	}, [currIntervalSec, dataProvider.map, dispatch]);

	useLayoutEffect(() => {
		dispatch(
			setMapIntervalTime({
				beginTime: moment().subtract(5, 'm').subtract(7, 's').unix(),
				endTime: moment().subtract(7, 's').unix(),
			})
		);
		dispatch(getTXNMapCountryThunk());

		return () => {
			clearInterval(intervalTimer);
			dispatch(resetMapData());
		};
	}, [dispatch]);

	return (
		<AmCharts.React
			className="my-class"
			style={{
				width: '100%',
				height: '100%',
			}}
			options={{
				type: 'map',
				theme: 'light',
				dataProvider,
				areasSettings: {
					mouseEnabled: true,
					autoZoom: true,
					color: paletteSDS.gray[200],
					rollOverOutlineColor: paletteSDS.gray[500],
					selectedColor: paletteSDS.gray[400],
					selectedOutlineColor: paletteSDS.white,
				},
				imagesSettings: {
					alpha: 0.2,
					color: paletteSDS.categoryColor.blue,
					bringForwardOnHover: false,
					selectable: false,
					centered: true,
				},
				listeners: [
					{
						event: 'positionChanged',
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						method: async (event: any) => {
							updateCustomMarkers(event, homeBtnClicked);
						},
					},
					{
						event: 'clickMapObject',
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						method: async (event: any) => {
							const countryCode = event.mapObject.id;
							if (countryCode !== undefined) {
								dispatch(setCountryCode(countryCode));
								mapObjectClickHandler(event, countryMapMode);
							}
						},
					},
				],
				mouseWheelZoomEnabled: true,
				zoomDuration: 0.3,
				zoomControl: {
					zoomControlEnabled: false,
					homeButtonEnabled: false,
				},
			}}
		/>
	);
}

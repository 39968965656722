/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { DeleteIcon, ShowChartIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { setIsSegmentGraphModalVisible } from 'common/context/modalSlice';
import { calcResourceUsageRate, calcOutflowRate } from 'features/nfBasic/utils/monitoringDataFunc';

import ChangedAmount from 'features/nfBasic/components/ChangedAmount';
import ProcessStatusBar from 'features/nfBasic/components/ProcessStatusBar';
import type { SegmentData } from 'features/nfControl/context/nfControlSlice';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';
import IntervalSegmentDeleteModal from 'features/nfInterval/components/IntervalSegmentDeleteModal';
import putPathControlSegmentGraphInfoThunk from 'features/nfControl/thunk/putPathControlSegmentGraphInfo.v2.thunk';
import getPathControlSegmentDetailThunk from 'features/nfControl/thunk/getPathControlSegmentDetail.v2.thunk';

const { fontSize, fontWeight } = themeSDS;
const { gray, blue, systemColor } = paletteSDS;

interface SegmentCardProps {
	data: SegmentData;
	onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
	onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
	onDrop?: (e: React.DragEvent<HTMLDivElement>) => Promise<void>;
	meta: SegmentMetaData;
}

export default function PathControlSegmentCard({ data, onDragOver, onDragStart, onDrop, meta }: SegmentCardProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { projectList, selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const selectedProjectIndex = projectList.findIndex(el => el.id === selectedProjectId);

	const showGraphSegmentNumber = useSelector((state: RootState) =>
		state.nfControl.segment.path.meta
			.map<number>(el => (el.segmentGraphYn ? 1 : 0))
			.reduce((prev, curr) => prev + curr, 0)
	);

	const { userRole } = useSelector((state: RootState) => state.account);

	const { activatedYn } = projectList[selectedProjectIndex];

	const [isSegmentDeleteModalVisible, setIsSegmentDeleteModalVisible] = useState(false);

	let processTime = Math.ceil(data.segCardValue.processTime).toString();
	const changedAmount = Math.ceil(data.segCardValue.processTime) - Math.ceil(data.prevSegCardValue.processTime);

	if (!activatedYn) {
		processTime = '';
	}

	const debouncedThunk = useMemo(
		() =>
			debounce(() => {
				if (data.segmentGraphYn || (showGraphSegmentNumber < 5 && !data.segmentGraphYn)) {
					dispatch(putPathControlSegmentGraphInfoThunk({ segmentId: data.id, segmentGraphYn: !data.segmentGraphYn }));
					return;
				}

				dispatch(setIsSegmentGraphModalVisible(true));
			}, 300),
		[dispatch, data.segmentGraphYn, data.id, showGraphSegmentNumber]
	);

	const inflowIconClickHandler = useCallback(() => {
		debouncedThunk();
	}, [debouncedThunk]);

	const settingButtonClickHandler = () => {
		if (userRole !== 'MONITOR' && userRole !== 'NONE') {
			dispatch(getPathControlSegmentDetailThunk({ segmentId: data.id }));
		}
	};

	const deleteIconClickHandler = () => {
		if (userRole !== 'MONITOR' && userRole !== 'NONE' && userRole !== 'OPERATOR') {
			setIsSegmentDeleteModalVisible(prevDelete => !prevDelete);
		}
	};

	return (
		<div css={SegmentCardCss} draggable onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop}>
			<div css={headerCss}>
				<div css={nameCss}>
					<div css={dotCss(data.segmentColor)} />
					<div css={segmentNameSt}>{data.segmentName}</div>
				</div>
				<button css={graphIconCss} onClick={inflowIconClickHandler}>
					<ShowChartIcon size="small" fill={!data.segmentGraphYn ? paletteSDS.gray[300] : paletteSDS.blue[700]} />
				</button>
			</div>
			<div css={statusCss}>
				<ProcessStatusBar
					value={Number(data.segCardValue.waitTime.toFixed(1))}
					isBlocked={!data.accessYn}
					disabled={!activatedYn}
				/>
			</div>
			<div css={cardBodyCss}>
				<div css={[commonCss, totalTicketsCss]}>{Math.ceil(data.segCardValue.totalTickets)}</div>
				<div css={[commonCss, numberWaitingCss]}>{activatedYn && Math.ceil(data.segCardValue.numbersWaiting)}</div>
				<div css={commonCss}>{activatedYn && Number(data.segCardValue.waitTime.toFixed(1))}</div>
				<div css={commonCss}>{activatedYn && Math.ceil(data.segCardValue.totalInflow)}</div>
				<div css={commonCss}>{Math.ceil(data.segCardValue.maxInflow)}</div>
				<div css={commonCss}>
					{activatedYn && calcResourceUsageRate(data.segCardValue.usedSize || 0, data.segCardValue.maxInflow)}
				</div>
				<div css={commonCss}>
					{activatedYn && `${calcOutflowRate(data.segCardValue.totalOutflow, data.segCardValue.totalInflow)}%`}
				</div>
			</div>
			<div css={[commonCss, processTimeCss]}>
				<div>{processTime}</div>
				<ChangedAmount value={changedAmount} disabled={!activatedYn} />
			</div>
			<div css={graphCheckboxCss}>
				<button css={settingSt} onClick={settingButtonClickHandler} aria-hidden>
					{t('projectMonitoring.settings')}
				</button>
				<button
					css={deleteIconCss(userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR')}
					onClick={deleteIconClickHandler}
				>
					<DeleteIcon
						fill={
							userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR'
								? paletteSDS.gray[300]
								: paletteSDS.blue[700]
						}
						size="small"
						cursor={
							userRole === 'MONITOR' || userRole === 'NONE' || userRole === 'OPERATOR' ? 'not-allowed' : 'pointer'
						}
					/>
				</button>
			</div>
			<IntervalSegmentDeleteModal
				visible={isSegmentDeleteModalVisible}
				id={meta.id}
				segmentName={meta.segmentName}
				segmentOrder={meta.segmentOrder}
				onClose={deleteIconClickHandler}
			/>
		</div>
	);
}

const SegmentCardCss = css`
	display: flex;
	flex-direction: column;
	width: 160px;
	height: 100%;
	background-color: ${systemColor.white};
	border-right: 1px solid ${gray[300]};
	font-size: ${fontSize[16]};
	font-weight: ${fontWeight.medium};
	line-height: 100%;

	color: ${gray['900']};
`;

const commonCss = css`
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	padding: 12px;
`;

const headerCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 41px;
	padding: 12px 0px 12px 10px;

	& > svg {
		cursor: pointer;

		&:hover {
			fill: ${paletteSDS.blue[600]};
		}
	}
`;

const nameCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
`;

const segmentNameSt = css`
	width: 96px;
	height: 16px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const dotCss = (color: string) => css`
	width: 10px;
	height: 10px;
	background-color: ${color};
	border-radius: 50%;
`;

const statusCss = css``;

const cardBodyCss = css`
	width: 100%;
`;

const totalTicketsCss = css``;

const numberWaitingCss = css`
	color: ${blue[400]};
`;

const processTimeCss = css`
	display: flex;
	justify-content: space-between;
	height: 2.5rem;
`;

const graphCheckboxCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: 53px;

	padding: 8px 0px;

	background-color: ${gray[75]};
	color: ${gray[500]};
`;

const settingSt = css`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 12px;

	height: 40px;

	color: ${paletteSDS.blue[700]};
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[14]};
	line-height: 100%;
	cursor: pointer;
	text-align: center;
	border: none;
	background-color: ${gray[75]};
	&:focus-visible {
		outline: 2px solid ${paletteSDS.blue[500]};
		outline-offset: -3px;
		border-radius: 6px;
	}
	&:hover {
		color: ${paletteSDS.blue[600]};
	}
	&:active {
		color: ${paletteSDS.blue[800]};
	}
`;

const graphIconCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 4px;
	border: none;
	background-color: ${systemColor.white};

	width: 32px;
	height: 32px;
	&:focus-visible {
		outline: 2px solid ${paletteSDS.blue[500]};
		outline-offset: -3px;
		border-radius: 6px;
	}

	& > svg {
		&:hover {
			fill: ${paletteSDS.blue[600]};
		}

		&:active {
			fill: ${paletteSDS.blue[800]};
		}
	}
`;

const deleteIconCss = (disabled: boolean) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 12px;
	border: none;
	background-color: ${gray[75]};

	/* width: 40px; */
	height: 40px;
	&:focus-visible {
		outline: 2px solid ${paletteSDS.blue[500]};
		outline-offset: -3px;
		border-radius: 6px;
	}

	& > svg {
		&:hover {
			fill: ${!disabled && paletteSDS.blue[600]};
		}

		&:active {
			fill: ${!disabled && paletteSDS.blue[800]};
		}
	}
`;

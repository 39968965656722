import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';

import type { PostBasicControlSegmentApiResponse } from 'features/nfControl/type/postBasicControlSegment.v2.type';

import type {
	BasicControlSegmentDetailData,
	URIPath,
} from 'features/nfControl/type/getBasicControlSegmentDetail.v2.type';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

interface PostBasicControlSegmentApiBody
	extends Omit<
		BasicControlSegmentDetailData,
		'vwrPage' | 'segmentColor' | 'id' | 'segmentGraphYn' | 'segmentKey' | 'uris' | 'segmentOrder'
	> {
	uris: URIPath[];
}

export interface PostBasicControlSegmentApiProps {
	tenantId: string;
	organizationId: number;
	projectId: number;
	body: PostBasicControlSegmentApiBody;
}

export default async function postBasicControlSegmentApi({
	tenantId,
	organizationId,
	projectId,
	body,
}: PostBasicControlSegmentApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey && organizationId) {
		const { data } = await axios.post<PostBasicControlSegmentApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v2/project/${projectId}/segment`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';
import putOrganizationNameApi from '../api/putOrganizationName.api';

export interface PutOrganizationNameThunkProps {
	name: string;
}

export interface PutOrganizationNameThunkResult {
	organizationName: string;
}

export const putOrganizationNameThunk = createAsyncThunk<
	PutOrganizationNameThunkResult,
	PutOrganizationNameThunkProps,
	{ state: RootState }
>('OrganizationName/put', async (args: PutOrganizationNameThunkProps, thnkAPI) => {
	const { name } = args;

	const {
		account: { organizationId },
	} = thnkAPI.getState();

	try {
		const { data } = await putOrganizationNameApi({ organizationId, name });

		return { organizationName: data.name };
		// eslint-disable-next-line
	} catch (error: any) {
		return thnkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
// etc
import { backgroundPalette, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

export default function OveragesName() {
	const bigBoxCss = [bigBoxSt, colorPalette.black, backgroundPalette.gray[200]];

	return (
		<div
			css={[
				themeCss.fontSize[13],
				colorPalette.gray[800],
				themeCss.fontWeight.regular,
				borderBottomLeft,
				borderTopLeft,
			]}
		>
			<div css={[titleBoxSt, backgroundPalette.gray[200], borderTopLeft, themeCss.fontWeight.bold, colorPalette.black]}>
				Features
			</div>
			<div css={bigBoxCss}>
				<div css={themeCss.fontWeight.bold}>MAU</div>
				<div>Monthly Active Users</div>
			</div>
			<div css={bigBoxCss}>
				<div css={themeCss.fontWeight.bold}>MWU</div>
				<div>Monthly Waiting Users</div>
			</div>
			<div css={[bigBoxCss, borderBottomLeft]}>
				<div css={themeCss.fontWeight.bold}>Peak CCU</div>
				<div>Peak Concurrent Connecting Users</div>
			</div>
		</div>
	);
}

const borderTopLeft = css`
	border-top-left-radius: 7px;
`;

const borderBottomLeft = css`
	border-bottom-left-radius: 7px;
`;

const titleBoxSt = css`
	width: 210px;
	height: 48px;

	padding-left: 16px;

	line-height: 48px;
`;

const bigBoxSt = css`
	display: flex;
	flex-direction: column;
	justify-content: center;

	gap: 4px;

	width: 210px;
	height: 80px;

	padding-left: 16px;

	border-top: 1px solid ${paletteSDS.gray[300]};
`;

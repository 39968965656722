/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { Outlet } from 'react-router-dom';

import OnPremSNB from 'common/components/OnPremSNB';

export default function OnPremProductLayout() {
	return (
		<div css={container}>
			<OnPremSNB />
			<div css={outletContainer}>
				<Outlet />
			</div>
		</div>
	);
}

const container = css`
	display: flex;

	width: 100%;
	height: 100%;
`;

const outletContainer = css`
	width: 100%;

	overflow: auto;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';
import i18next from 'i18next';

import { isAxiosError } from 'common/api/isAxiosError';

import { PostOrganizationPaymentInfoApiError } from 'features/plan/types/postOrganizationPaymentInfo.type';
import { toggleIsPaymentErrorModalVisible } from 'common/context/modalSlice';

import postOrganizationPaymentInfoApi from '../api/postOrganizationPaymentInfo.api';

export interface PostOrganizationPaymentInfoThunkProps {
	cardNumber: string;
	expirateDate: string;
	birthDate: string;
	cardPassword: string;
	cardType: string;
	businessNumber: string;
}

export interface PostOrganizationPaymentInfoThunkResult {
	id: string;
	name: string;
	number: number;
}

export const postOrganizationPaymentInfoThunk = createAsyncThunk<
	PostOrganizationPaymentInfoThunkResult,
	PostOrganizationPaymentInfoThunkProps,
	{ state: RootState }
>('payment/post', async (args, thunkAPI) => {
	const {
		account: { organizationId },
	} = thunkAPI.getState();

	const { t } = i18next;

	const { cardNumber, cardPassword, expirateDate, birthDate, cardType, businessNumber } = args;

	try {
		const { data } = await postOrganizationPaymentInfoApi({
			organizationId,
			cardNumber: cardNumber.replaceAll(' ', ''),
			cardPassword,
			expirateDate: expirateDate.replaceAll('-', ''),
			birthDate: cardType === 'corp' ? businessNumber : birthDate,
		});

		return { id: data.id, number: data.number, name: data.name };
		// eslint-disable-next-line
	} catch (error: any) {
		if (isAxiosError<PostOrganizationPaymentInfoApiError>(error) && error.response?.data.errorCode === 'IM1000') {
			thunkAPI.dispatch(
				toggleIsPaymentErrorModalVisible({
					title: t('modal.pymtMethodError'),
					body: `${t('modal.pymtMethodErrorMsg1')}\n${t('modal.pymtMethodErrorMsg2')}`,
				})
			);
		}

		if (isAxiosError<PostOrganizationPaymentInfoApiError>(error) && error.response?.data.errorCode === 'CA1000') {
			thunkAPI.dispatch(
				toggleIsPaymentErrorModalVisible({ title: t('modal.debitError'), body: t('modal.debitNotAccepted') })
			);
		}

		return thunkAPI.rejectWithValue(error.response.data);
	}
});

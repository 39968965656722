import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTXNMapCityThunk } from 'features/nfEUM/thunk/getTXNMapCity.thunk';
import { getTXNMapCountryThunk } from 'features/nfEUM/thunk/getTXNMapCountry.thunk';
import { MapImage } from 'features/nfEUM/types/getEUMMap.type';
import { generateCityImage, generateCountryImage, mapTitleToJsForm, regionNames } from 'features/nfEUM/utils/mapFunc';

interface DataProvider {
	map: string;
	getAreasFromMap: boolean;
	images: MapImage[];
}

export interface TXNIntervalTime {
	beginTime: number;
	endTime: number;
}

interface TXNMapSliceData extends TXNIntervalTime {
	isLoaded: boolean;
	hasError: boolean;
	countryCode: string;
	dataProvider: DataProvider;
	homeBtnClicked: boolean;
}

const initDataProvider: DataProvider = {
	map: 'worldLow',
	getAreasFromMap: true,
	images: [],
};

const initialState: TXNMapSliceData = {
	isLoaded: false,
	hasError: false,
	beginTime: 0,
	endTime: 0,
	countryCode: '',
	dataProvider: initDataProvider,
	homeBtnClicked: false,
};

const eumTXNMapSlice = createSlice({
	name: 'eumTXNMap',
	initialState,
	reducers: {
		setMapIntervalTime: (state, action: PayloadAction<TXNIntervalTime>) => {
			const { payload } = action;
			return { ...state, beginTime: payload.beginTime, endTime: payload.endTime };
		},
		setCountryCode: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryCode: action.payload,
			};
		},
		resetMapData: () => {
			return { ...initialState };
		},
		resetDataProvider: state => {
			return { ...state, dataProvider: initDataProvider };
		},
		setHomeBtnClicked: (state, action: PayloadAction<boolean>) => {
			return { ...state, homeBtnClicked: action.payload };
		},
	},
	extraReducers: builder => {
		builder.addCase(getTXNMapCountryThunk.fulfilled, (state, action) => {
			const { payload } = action;
			return {
				...state,
				isLoaded: true,
				hasError: false,
				homeBtnClicked: false,
				dataProvider: {
					...state.dataProvider,
					map: 'worldLow',
					images: generateCountryImage(
						payload.countryData
							.sort((a, b) => {
								if (a.count > b.count) return -1;
								if (a.count < b.count) return 1;
								return 0;
							})
							.map(el => {
								return { region: el.region, pt: el.pt / 1000, count: el.count };
							})
					),
				},
			};
		});
		builder.addCase(getTXNMapCountryThunk.rejected, state => {
			return {
				...state,
				isLoaded: true,
				hasError: true,
				homeBtnClicked: false,
			};
		});
		builder.addCase(getTXNMapCityThunk.fulfilled, (state, action) => {
			const { payload } = action;
			return {
				...state,
				isLoaded: true,
				hasError: false,
				homeBtnClicked: false,
				dataProvider: {
					...state.dataProvider,
					map:
						state.countryCode === 'US'
							? 'usaLow'
							: mapTitleToJsForm(regionNames.of(state.countryCode) || state.dataProvider.map),
					images: generateCityImage(
						payload.cityData.slice(0, 5).map(el => {
							return { region: el.region, pt: el.pt / 1000, count: el.count };
						})
					),
				},
			};
		});
		builder.addCase(getTXNMapCityThunk.rejected, state => {
			return {
				...state,
				isLoaded: true,
				hasError: true,
				homeBtnClicked: false,
			};
		});
	},
});

export const { setMapIntervalTime, setCountryCode, resetMapData, resetDataProvider, setHomeBtnClicked } =
	eumTXNMapSlice.actions;
export default eumTXNMapSlice.reducer;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { Button, themeSDS, paletteSDS, ErrorOutlineIcon } from 'surf-design-system';

import { useAppDispatch, RootState } from 'common/redux/store';
import { LANG_PATH } from 'common/data/constants';

import { putSubscriptionThunk } from 'features/plan/thunk/putSubscription.thunk';

export default function CancelSubscriptionPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { language } = useSelector((state: RootState) => state.account);

	const confirmButtonClickHandler = async () => {
		await dispatch(putSubscriptionThunk());

		navigate({ pathname: `/${LANG_PATH[language]}/home/plan_usage/plan`, search: qs.stringify({ cancel: true }) });
	};

	const cancelButtonClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/home/plan_usage/plan`);
	};

	return (
		<div css={containerSt}>
			<div css={gap32Column}>
				<div
					css={[
						gap8Column,
						css`
							align-items: center;
						`,
					]}
				>
					<ErrorOutlineIcon fill={paletteSDS.red['500']} cursor="default" size="large" />
					<div css={titleSt}>{t('planSubscription.cancelSubscriptionTitle')}</div>
				</div>
				<div css={subTitleSt}>{t('planSubscription.cancelSubscriptionSubTitle')}</div>
			</div>
			<div css={descriptionBox}>
				<div css={descriptionTitle}>{t('planSubscription.cancelSubscriptionDescTitle')}</div>
				<div css={descriptionSt}>{t('planSubscription.cancelSubscriptionDescription')}</div>
			</div>
			<div css={buttonBox}>
				<Button mould="tertiary" size="large" onClick={cancelButtonClickHandler}>
					{t('planSubscription.retainPlan')}
				</Button>
				<Button mould="secondary" size="large" onClick={confirmButtonClickHandler}>
					{t('planSubscription.cancelSubscription')}
				</Button>
			</div>
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 40px;

	padding: 32px 0;
`;

const gap32Column = css`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const gap8Column = css`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const titleSt = css`
	color: ${paletteSDS.gray['900']};
	font-size: 26px;
	font-weight: ${themeSDS.fontWeight.bold};
	line-height: 128%;
	letter-spacing: -0.008em;
`;

const subTitleSt = css`
	color: ${paletteSDS.black};
	font-size: ${themeSDS.fontSize['18']};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;
	letter-spacing: -0.008em;

	text-align: center;

	white-space: pre-line;

	word-break: keep-all;
`;

const descriptionTitle = css`
	color: ${paletteSDS.black};
	font-size: ${themeSDS.fontSize['16']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 160%;
`;

const descriptionSt = css`
	color: ${paletteSDS.gray['900']};
	font-size: ${themeSDS.fontSize['14']};
	font-weight: ${themeSDS.fontWeight.regular};
	line-height: 160%;

	white-space: pre-line;

	word-break: keep-all;
`;

const descriptionBox = css`
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 20px 32px;

	background-color: ${paletteSDS.gray[50]};
	border: 1px solid ${paletteSDS.gray[200]};
	border-radius: 8px;
`;

const buttonBox = css`
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 8px;
`;

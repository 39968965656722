import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, ONPREM_CENTER_API_PATH, USER_KEY } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';
import { PutProjectActivatedYnApiResponse } from 'features/nfSetting/types/putProjectActivatedYn.v2.type';

export interface PutProjectActivatedYnApiProps {
	tenantId: string;
	projectId: number;
	organizationId: number;
	activatedYn: boolean;
}

export const putProjectActivatedYnApi = async ({
	tenantId,
	projectId,
	organizationId,
	activatedYn,
}: PutProjectActivatedYnApiProps) => {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (userKey && cognitoToken && organizationId !== 0) {
		const { data } = await axios.put<PutProjectActivatedYnApiResponse>(
			`${
				process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
					? getOnPremBaseUrl(ONPREM_CENTER_API_PATH)
					: process.env.REACT_APP_BASE_URL
			}/v1/project/${projectId}/activation`,
			{
				activatedYn,
			},
			{
				headers: {
					userKey,
					Authorization: cognitoToken,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
};

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';

import LicenseTable from 'features/license/components/LicenseTable';
import { getLicenseListThunk } from 'features/license/thunk/getLicenseList.thunk';

export default function License() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getLicenseListThunk());
	}, [dispatch]);

	return (
		<div css={container}>
			<div css={titleSt}>{t('onPrem.layout.license')}</div>
			<LicenseTable />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 100%;
`;

const titleSt = css`
	height: 100%;

	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize[24]};
	line-height: 128%;
	color: ${paletteSDS.gray['900']};
`;

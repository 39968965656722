import React from 'react';
import { Provider } from 'react-redux';

import { GlobalStyles } from 'surf-design-system';

import store from 'common/redux/store';
import Router from 'routes/Router';

export default function App() {
	return (
		<>
			<GlobalStyles />
			<Provider store={store}>
				<Router />
			</Provider>
		</>
	);
}

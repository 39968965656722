import axios from 'axios';

import { GetOauthUserInfoApiResponse } from 'features/signin/types/getOauthUserInfo.type';

export interface GetOauthUserInfoApiProps {
	accessToken: string;
}

export default async function getOauthUserInfoApi({ accessToken }: GetOauthUserInfoApiProps) {
	const { data } = await axios.get<GetOauthUserInfoApiResponse>(
		`${process.env.REACT_APP_BASE_URL}/v1/user/oauth/validation`,
		{
			headers: {
				Authorization: accessToken,
			},
		}
	);
	return data;
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, backgroundPalette } from 'surf-design-system';

interface NetFunnelStatusLabelProps {
	licenseExpiredYn: boolean;
	licenseReachedYn: boolean;
}
export default function NetFunnelStatusLabel({ licenseExpiredYn, licenseReachedYn }: NetFunnelStatusLabelProps) {
	const { t } = useTranslation();

	let netfunnelStatusText = t('onPrem.license.fine');
	let netfunnelStatusColor = backgroundPalette.categoryColor.mint;

	if (licenseExpiredYn) {
		netfunnelStatusText = t('onPrem.license.byPass');
		netfunnelStatusColor = backgroundPalette.red['500'];
	}

	if (licenseReachedYn && !licenseExpiredYn) {
		netfunnelStatusText = t('onPrem.license.overageBypass');
		netfunnelStatusColor = backgroundPalette.red['500'];
	}

	return <div css={[labelContainerCss, netfunnelStatusColor]}>{netfunnelStatusText}</div>;
}

const labelContainerCss = css`
	padding: 4px 8px;
	color: ${paletteSDS.white};
	font-size: ${themeSDS.fontSize[13]};
	font-weight: ${themeSDS.fontWeight.semiBold};
	letter-spacing: -0.008em;
	line-height: 128%;
	border-radius: 4px;
`;

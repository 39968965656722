/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from 'common/redux/store';
import DividerWithTxt from 'common/components/DividerWithTxt';

import SurffyHomePending from 'features/home/components/SurffyHomePending';
import SurffyHomeTenantFail from 'features/home/components/SurffyHomeTenantFail';
import HeaderTenantStatus from 'features/home/components/HeaderTenantStatus';
import SurffyProductInfo from 'features/home/components/SurffyProductInfo';
import { paletteSDS, themeSDS } from 'surf-design-system';
import { getSubscriptionListThunk } from 'features/plan/thunk/getSubscriptionList.v2.thunk';

export default function SurffyHomePage() {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('home');

	const { tenantStatus } = useSelector((state: RootState) => state.account);

	const { products } = useSelector((state: RootState) => state.subscription);

	useLayoutEffect(() => {
		dispatch(getSubscriptionListThunk());
	}, [dispatch]);

	if (tenantStatus === 'PENDING') {
		return (
			<div css={container}>
				<SurffyHomePending />
			</div>
		);
	}

	if (tenantStatus === 'FAILED') {
		return (
			<div css={container}>
				<SurffyHomeTenantFail />
			</div>
		);
	}

	return (
		<div css={container}>
			<div css={gap24pxColumn}>
				<HeaderTenantStatus />
				<DividerWithTxt />
				<div css={gap16pxColumn}>
					<h4 css={surffyInUseSt}>{t('surffy.nowInUse')}</h4>
					{products.map(el => (
						<SurffyProductInfo {...el} key={el.productType} />
					))}
				</div>
			</div>
		</div>
	);
}

const container = css`
	width: 960px;

	padding: 32px 24px 80px;

	margin: 0 auto;
`;

const gap24pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const gap16pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const surffyInUseSt = css`
	font-size: ${themeSDS.fontSize['20']};
	font-weight: ${themeSDS.fontWeight.semiBold};
	line-height: 140%;
	color: ${paletteSDS.gray['900']};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import putSegmentOrderApi from '../api/putSegmentOrder.api';

export interface PutSegmentOrderThunkResult {
	pickedCardIndex: number;
	droppedCardIndex: number;
}

export interface PutSegmentOrderThunkProps {
	pickedCardIndex: number;
	droppedCardIndex: number;
}

export const putSegmentOrderThunk = createAsyncThunk<
	PutSegmentOrderThunkResult,
	PutSegmentOrderThunkProps,
	{ state: RootState }
>('segment/order/put', async (args, thunkAPI) => {
	const {
		account: { tenantId, tenantUrl, organizationId },
		prjMeta: { selectedProjectId },
		monitoring: {
			segment: { meta },
		},
	} = thunkAPI.getState();

	const { pickedCardIndex, droppedCardIndex } = args;

	const putSegmentOrderApiBody = meta
		.filter(el => el.segmentOrder === pickedCardIndex + 1 || el.segmentOrder === droppedCardIndex + 1)
		.map(el => {
			if (el.segmentOrder === pickedCardIndex + 1) {
				return {
					id: el.id,
					segmentKey: el.segmentKey,
					segmentOrder: droppedCardIndex + 1,
				};
			}
			return {
				id: el.id,
				segmentKey: el.segmentKey,
				segmentOrder: pickedCardIndex + 1,
			};
		});

	try {
		await putSegmentOrderApi({
			tenantId: tenantId || '',
			tenantUrl,
			prjId: selectedProjectId,
			organizationId,
			body: putSegmentOrderApiBody,
		});

		return { pickedCardIndex, droppedCardIndex };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link, paletteSDS, themeSDS, AddIcon } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { toggleIsPaymentFormModalVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';

import CreditCard from 'features/plan/components/CreditCard';
import PlanTitle from 'features/plan/components/PlanTitle';

export interface PaymentMethodProps {
	footer?: 'none' | 'double' | 'single';
	titleSize?: 'small' | 'large';
	isLinkVisible?: boolean;
}

export default function PaymentMethod({
	footer = 'none',
	titleSize = 'small',
	isLinkVisible = true,
}: PaymentMethodProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { name } = useSelector((state: RootState) => state.payment);

	const { country, language } = useSelector((state: RootState) => state.account);

	const linkClickHandler = () => {
		if (country === 'KR') {
			dispatch(toggleIsPaymentFormModalVisible());
		} else {
			navigate(`/${LANG_PATH[language]}/home/plan_usage/payment_method`);
		}
	};

	const addIconClickHandler = () => {
		if (country === 'KR') {
			dispatch(toggleIsPaymentFormModalVisible());
		} else {
			navigate(`/${LANG_PATH[language]}/home/plan_usage/payment_method`);
		}
	};

	let footerComponent = null;

	if (footer === 'single') {
		footerComponent = <span css={footerSt}>{t('planSubscription.changePymtMethodMsg')}</span>;
	}

	if (footer === 'double') {
		footerComponent = (
			<div>
				<span css={footerSt}>* {t('planSubscription.changePymtMethodMsg')}</span>
				<span css={footerSt}>* {t('modal.addPymtMethodMsg1')}</span>
			</div>
		);
	}

	if (name === '') {
		footerComponent = null;
	}

	return (
		<div css={containerSt}>
			{titleSize === 'large' ? (
				<PlanTitle title={t('planSubscription.pymtMethod')} />
			) : (
				<h5>{t('planSubscription.pymtMethod')}</h5>
			)}
			{name === '' ? (
				<div css={addMethodCss} onClick={addIconClickHandler} aria-hidden>
					<AddIcon size="medium" fill={paletteSDS.gray[900]} />
					<div css={textWithBtnCss}>{t('planSubscription.addpymtMethod')}</div>
				</div>
			) : (
				<CreditCard />
			)}
			{(name !== '' && isLinkVisible) || footer !== 'none' ? (
				<div css={lowerBox}>
					<Link inline onClick={linkClickHandler}>
						{t('planSubscription.changePymtMethod')}
					</Link>
					{footerComponent}
				</div>
			) : null}
		</div>
	);
}

const containerSt = css`
	display: flex;
	flex-direction: column;

	gap: 1.5rem;

	width: 20.875rem;
`;

const lowerBox = css`
	display: flex;
	flex-direction: column;

	gap: 0.5rem;
`;

const footerSt = css`
	height: 2.75rem;
	line-height: 160%;
	font-size: ${themeSDS.fontSize[14]};
	color: ${paletteSDS.gray[700]};
`;

const addMethodCss = css`
	display: flex;
	width: 20.875rem;
	height: 13.125rem;
	justify-content: center;
	align-items: center;
	color: ${paletteSDS.gray[900]};
	border-radius: 20px;
	border: 1px solid ${paletteSDS.gray[500]};
	background-color: ${paletteSDS.gray[50]};
	cursor: pointer;
	&:hover {
		color: ${paletteSDS.blue[800]};
		border: 1px solid ${paletteSDS.blue[600]};
	}
`;

const textWithBtnCss = css`
	display: flex;
	margin-left: 0.9169rem;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { themeSDS } from 'surf-design-system';

import type { userRoleType } from 'common/types/getOrganizationList.type';

interface OrganizationTableElementActionButtonProps {
	onClick: () => void;
	elementOrganizationId: number;
	organizationId: number;
	userRole: userRoleType;
}

export default function OrganizationTableElementActionButton({
	onClick,
	elementOrganizationId,
	organizationId,
	userRole,
}: OrganizationTableElementActionButtonProps) {
	const { t } = useTranslation();

	let content: React.ReactNode = t('personalSettings.switchOrg');

	if (organizationId === elementOrganizationId && userRole === 'OWNER') {
		content = t('modal.ownerTrans');
	}

	if (organizationId === elementOrganizationId && userRole !== 'OWNER') {
		content = `-`;
	}

	return (
		<div css={actionCss} onClick={onClick} aria-hidden>
			{content}
		</div>
	);
}

const actionCss = css`
	width: 100%;
	justify-content: center;
	align-items: center;
	border: none;
	font-size: ${themeSDS.fontSize[14]};
	font-weight: ${themeSDS.fontWeight.regular};

	background-color: transparent;
	cursor: pointer;

	text-align: center;
`;

import { Navigate, Route, Routes } from 'react-router-dom';

import AuthLayout from 'common/layout/AuthLayout';
import { getI18nPath } from 'common/utils/languageFunc';

import PageNotFound from 'pages/common/PageNotFound';
import SurffyVersion from 'pages/common/SurffyVersion';
import Maintenance from 'pages/common/Maintenance';

import OnPremRoute from 'routes/OnPremRoute';
import SaasRoute from 'routes/SaasRoute';

export default function Router() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<Navigate
						to={
							process.env.REACT_APP_PRODUCT_MODE === 'ONPREM'
								? `/${getI18nPath()}/on_prem/signin`
								: `/${getI18nPath()}/signin`
						}
					/>
				}
			/>
			{process.env.REACT_APP_PRODUCT_MODE !== 'ONPREM' ? SaasRoute() : null}
			{process.env.REACT_APP_PRODUCT_MODE === 'ONPREM' ? OnPremRoute() : null}
			<Route path="version" element={<AuthLayout />}>
				<Route path="" element={<SurffyVersion />} />
			</Route>
			<Route path="maintenance" element={<Maintenance />} />
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
}

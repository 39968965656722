/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { paletteSDS, themeSDS } from 'surf-design-system';

import Tooltip from 'common/components/Tooltip';

import { convertNumToKMB, addNumberComma } from 'features/plan/utils/formatFunc';

export interface SummaryCardFooter {
	label: string;
	value: number;
	id: number;
}

export interface UsageSummaryCardProps {
	title: string;
	data?: number;
	footer?: SummaryCardFooter[];
}

export default function SaasUsageSummaryCard({ title, data, footer }: UsageSummaryCardProps) {
	return (
		<div css={usageSummaryCardContainerSt}>
			<div css={usageSummaryCardTitleSt}>{title}</div>
			<div css={dataBox}>
				{data !== undefined ? (
					<Tooltip
						message={addNumberComma(data.toString())}
						position="top"
						isHelpIconVisible={false}
						width="fit-content"
						height="fit-content"
					>
						<div css={usageSummaryCardDataSt}>{convertNumToKMB(data)}</div>
					</Tooltip>
				) : null}
			</div>
			{footer ? (
				<div css={footerContainer}>
					{footer.map(el => (
						<div key={`${title}-${el.label}-${el.id}`} css={footerBox}>
							<div css={footerLabel}>{el.label}</div>
							<Tooltip
								message={addNumberComma(el.value.toString())}
								isHelpIconVisible={false}
								width="fit-content"
								height="fit-content"
								position="top"
							>
								<div css={footerValue}>{convertNumToKMB(el.value)}</div>
							</Tooltip>
						</div>
					))}
				</div>
			) : null}
		</div>
	);
}

const usageSummaryCardContainerSt = css`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	width: 298.67px;
	height: 200px;

	padding: 20px 16px 20px 16px;

	border: 1px solid ${paletteSDS.gray[300]};
	border-radius: 8px;

	background: ${paletteSDS.gray[50]};
`;

const usageSummaryCardTitleSt = css`
	font-weight: ${themeSDS.fontWeight.medium};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 100%;
	color: ${paletteSDS.gray[900]};
	white-space: pre-wrap;
`;

const usageSummaryCardDataSt = css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize[32]};
	line-height: 100%;
	color: ${paletteSDS.blue[700]};
`;

const footerContainer = css`
	display: flex;
	flex-direction: column;

	gap: 6px;
`;

const footerBox = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const footerLabel = css`
	color: ${paletteSDS.gray[700]};
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[14]};
`;

const footerValue = css`
	color: ${paletteSDS.black};
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize[18]};
`;

const dataBox = css`
	display: flex;
	align-items: center;
	flex-direction: row-reverse;

	height: 32px;
	padding-right: 9px;
`;

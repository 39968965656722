/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

import { RootState } from 'common/redux/store';

export default function SurffyStatus() {
	const { t } = useTranslation();

	const { tenantStatus } = useSelector((state: RootState) => state.account);

	let statusTextkey = 'console.main.domainWrk';

	let statusColor = paletteSDS.green['500'];

	if (tenantStatus === 'FAILED') {
		statusTextkey = 'console.main.failed';
		statusColor = paletteSDS.red['500'];
	}

	if (tenantStatus === 'EXPIRED' || tenantStatus === 'DELETED' || tenantStatus === 'STOPPED') {
		statusTextkey = 'console.main.stopped';
		statusColor = paletteSDS.red['500'];
	}

	if (tenantStatus === 'PENDING') {
		statusTextkey = 'console.main.domainpPndg';
		statusColor = paletteSDS.gray['500'];
	}

	return (
		<div css={container}>
			<div css={guidanceSt} />
			<div css={gap8px}>
				<div css={statusLabelSt}>{t('console.main.status')}</div>
				<div css={statusSt(statusColor)}>{t(statusTextkey)}</div>
			</div>
		</div>
	);
}

const container = css`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const gap8px = css`
	display: flex;
	gap: 8px;
	align-items: center;
`;

const guidanceSt = css`
	width: 4px;
	height: 26px;
	border: 4px;

	background: ${paletteSDS.blue['100']};
`;

const statusLabelSt = css`
	display: flex;
	align-items: center;

	width: 128px;
	height: 26px;

	font-size: ${themeSDS.fontSize['16']};
	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.regular};
`;

const statusSt = (color: string) => css`
	height: 20px;

	padding: 4px 6px;

	color: ${color};

	font-size: ${themeSDS.fontSize['12']};
	font-weight: ${themeSDS.fontWeight.regular};

	line-height: 100%;

	border: 1px solid ${color};
	border-radius: 4px;

	background: ${paletteSDS.gray['50']};
`;

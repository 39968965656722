/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import { Dropdown, Textinput, CloseIcon, SearchIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';

import { setSearchValue, setMemberStatus, resetSearchValue } from 'features/setting/context/onPremMemberSlice';
import { getMemberListThunk } from 'features/setting/thunk/getMemberList.v2.onPrem.thunk';

const { fontWeight, fontSize } = themeSDS;
const { gray } = paletteSDS;

export default function OnPremMemberSearchBar() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { searchValue, memberStatus } = useSelector((state: RootState) => state.onPremMember);

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(getMemberListThunk());
		}, 1000);

		return () => clearTimeout(timer);
	}, [searchValue, memberStatus, dispatch]);

	const memberStatusChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		dispatch(setMemberStatus(value));
	};

	const searchValueChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		dispatch(setSearchValue(value));
	};

	const closeIconClickHandler = () => {
		dispatch(resetSearchValue());
	};

	return (
		<div css={memberSearchBarCss}>
			<h3 css={titleCss}>{t('organizationSettings.mem')}</h3>
			<div css={rowCss}>
				<div css={leftSideCss}>
					<div css={textInputWrapperCss}>
						{searchValue === undefined || searchValue.trim().length === 0 ? (
							<div css={searchIconCss}>
								<SearchIcon />
							</div>
						) : (
							<div css={closeIconCss}>
								<CloseIcon onClick={closeIconClickHandler} />
							</div>
						)}
						<Textinput
							name="keyword"
							value={searchValue || ''}
							placeholder={`${t('commonWords.userName')}, ID, ${t('commonWords.role')}`}
							onChange={searchValueChangeHandler}
							size="medium"
						/>
					</div>
					<Dropdown
						name="state"
						menu={[
							{ name: t('organizationSettings.stsAll'), value: 'all' },
							{ name: t('organizationSettings.stsActive'), value: 'ACTIVE' },
							{
								name: t('organizationSettings.stsDisabled'),
								value: 'DISABLED',
							},
						]}
						bindedValue={memberStatus}
						size="medium"
						width="16.25rem"
						onChange={memberStatusChangeHandler}
					/>
				</div>
			</div>
		</div>
	);
}

const memberSearchBarCss = css`
	display: flex;
	flex-direction: column;
`;

const titleCss = css`
	margin: 0;
	color: ${gray[900]};
	font-size: ${fontSize[24]};
	font-weight: ${fontWeight.bold};
	line-height: 128%;
	letter-spacing: -0.008em;
`;

const rowCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem 0;
`;

const leftSideCss = css`
	display: flex;
`;

const textInputWrapperCss = css`
	position: relative;
	margin-right: 0.5rem;

	input {
		width: 17.5rem;
	}
`;

const searchIconCss = css`
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	z-index: 1000;

	svg {
		fill: ${gray[600]};
	}
`;

const closeIconCss = css`
	position: absolute;
	top: 0.625rem;
	right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
	z-index: 1000;

	svg {
		width: 1.25rem;
		height: 1.25rem;
		fill: ${gray[600]};
	}
`;

import { ONPREM_ASSETS_PATH } from 'common/data/constants';
import { getOnPremBaseUrl } from 'common/utils/onPremBaseURLFunc';

export const nfAgentCodeBlock = () => {
	return `import UIKit\nimport SurffyAgent`;
};

export const apiMethodFirstCodeBlock = (tenantId: string | null) => ({
	swift: `let agent = NetFunnelAgent.shared 
agent.setConfig(tenantURL: "${getOnPremBaseUrl()}",
        projectDetailURL: "${getOnPremBaseUrl(ONPREM_ASSETS_PATH)}/nf-setting/${tenantId}/nf-setting.json"),
        eumURL : "${getOnPremBaseUrl()}",
        delegate: self, 
        oneTimeCallback: "A callback function for when the cancel button is clicked during the wait or block. nil value when unused"
)`,
	objectivec: `//ex>
[super viewDidLoad];
// Do any additional setup after loading the view.
NetFunnelAgent *agent = [NetFunnelAgent shared];
[agent setConfigWithTenantURL:@"${getOnPremBaseUrl()}"
        projectDetailURL:@""${getOnPremBaseUrl(ONPREM_ASSETS_PATH)}/nf-setting/${tenantId}/nf-setting.json"
        eumURL:@"${getOnPremBaseUrl()}"
        delegate:self
        oneTimeCallback:nil];`,
});

export const apiMethodSecondCodeBlock = () => ({
	swift: `class ViewController: UIViewController, SurffyDelegate {
    func SurffyActionSuccess(projectKey: String, segmentKey: String, retcode: Int) {
          print("action success called")
          // Trigged when NetFunnel request succeed and got return code (200)
      }
      
      func SurffyActionBlock(projectKey: String, segmentKey: String, retcode: Int) {
          print("action block called")
          // Trigged when NetFunnel request blocked and got return code (301, 302)
      }
      
      func SurffyActionError(projectKey: String?, segmentKey: String?, retcode: Int?) {
          print("action error called")
          // Trigged when NetFunnel request failed
      }
      
      func SurffyActionBypass(projectKey: String, segmentKey: String, retcode: Int){
          print("action bypass called")
          // Trigged when NetFunnel request bypassed and got return code (300, 303)
      }
      
      func SurffyActionCancel(projectKey: String, segmentKey: String, retcode: Int, cancelTargetAddr: String) {
          print("action cancel called")
          // Trigged when user canceld NetFunnel request
      }
      
      func SurffyCompleteSuccess(projectKey: String, segmentKey: String) {
          print("complete success called")
          // Trigged when NetFunnel completion succeed
      }
      
      func SurffyCompleteError(projectKey: String, segmentKey: String) {
          print("action complete error called")
          // Trigged when NetFunnel completion failed
      }
  }`,
	objectivec: `//ex> ViewController.h
@import SurffyAgent;
@interface ViewController : UIViewController <SurffyDelegate>

//ex> ViewController.m
@interface ViewController ()

@end

@implementation ViewController

- (void)SurffyActionBlockWithProjectKey:(NSString * _Nonnull)projectKey segmentKey:(NSString * _Nonnull)segmentKey retcode:(NSInteger)retcode {
    // Trigged when NetFUNNEL request blocked and got return code (301, 302)
}

- (void)SurffyActionBypassWithProjectKey:(NSString * _Nonnull)projectKey segmentKey:(NSString * _Nonnull)segmentKey retcode:(NSInteger)retcode {
    // Trigged when NetFUNNEL request bypassed and got return code (300, 303)
}

- (void)SurffyActionCancelWithProjectKey:(NSString * _Nonnull)projectKey segmentKey:(NSString * _Nonnull)segmentKey retcode:(NSInteger)retcode cancelTargetAddr:(NSString * _Nonnull)cancelTargetAddr {
    // Trigged when user canceld NetFUNNEL request
}

- (void)SurffyActionErrorWithProjectKey:(NSString * _Nullable)projectKey segmentKey:(NSString * _Nullable)segmentKey retcode:(NSInteger)retcode {
    // Trigged when NetFUNNEL request failed
}

- (void)SurffyActionSuccessWithProjectKey:(NSString * _Nonnull)projectKey segmentKey:(NSString * _Nonnull)segmentKey retcode:(NSInteger)retcode {
    // Trigged when NetFUNNEL request succeed and got return code (200)
}

- (void)SurffyCompleteErrorWithProjectKey:(NSString * _Nonnull)projectKey segmentKey:(NSString * _Nonnull)segmentKey {
    // Trigged when NetFunnel completion succeed
}

- (void)SurffyCompleteSuccessWithProjectKey:(NSString * _Nonnull)projectKey segmentKey:(NSString * _Nonnull)segmentKey {
    // Trigged when NetFUNNEL completion failed
}

@end`,
});

export const urlInterceptCodeBlock = (tenantUrl: string) => {
	return `let agent = NetFunnelAgent.shared 
agent.setConfig(tenantURL: "https://${tenantUrl}",
                            projectDetailURL: "${process.env.REACT_APP_NF_SETTING_URL}/${
		tenantUrl.split('.')[0]
	}/nf-setting.json",
                            eumURL : "${process.env.REACT_APP_EUM_URL}",
                            oneTimeCallback: "A callback function to be executed immediately after loading Surffy information.Executed only once. Set to nil if not used.",
                            cancelHandler: "A callback function for when the cancel button is clicked during the wait or block. nil value when unused" )
                  
URLProtocol.registerClass(STCNFProtocol.self)`;
};

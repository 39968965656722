import axios, { AxiosError } from 'axios';

import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import type { URIPath, URIId } from '../types/getSegmentList.type';

import type { PutSegmentSettingApiResponse } from '../types/putSegmentSetting.type';

/**
 * author: noah
 * createdAt: 2022-07-29
 * updatedAt: 2022-07-29
 * description: 특정 세그먼트 수정 api
 */

interface PutSegmentSettingApiBody {
	segmentName: string;
	segmentColor: string;
	accessYn: boolean;
	maxInflow: number;
	deleteUriId: URIId[];
	addUriPath: URIPath[];
}

export interface PutSegmentSettingApiProps {
	tenantUrl: string;
	tenantId: string;
	organizationId: number;
	prjId: number;
	segId: number;
	body: PutSegmentSettingApiBody;
}

export default async function putSegmentSettingApi({
	tenantUrl,
	tenantId,
	organizationId,
	prjId,
	segId,
	body,
}: PutSegmentSettingApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.put<PutSegmentSettingApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/project/${prjId}/segment/${segId}`,
			body,
			{
				headers: {
					Authorization: cognitoToken,
					userKey,
					organizationId,
					tenantId,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_OPTION_VALUE;
}

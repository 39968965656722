import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { getVWRDataApi } from 'features/vwr/api/getVWRData.v2.api';
import type { VWRElement } from 'features/vwr/context/vwrSlice.v2';

interface GetVWRDataThunkProps {
	id: number;
}

interface GetVWRDataThunkResult extends VWRElement {
	id: number;
	name: string;
}

export const getVWRDataThunk = createAsyncThunk<GetVWRDataThunkResult, GetVWRDataThunkProps, { state: RootState }>(
	'vwr/get/v2',
	async (args, thunkAPI) => {
		const { id } = args;

		const {
			account: { tenantId, organizationId, language },
			projectInfo: { selectedProjectId },
		} = thunkAPI.getState();

		const { preview } = thunkAPI.getState().vwr;

		try {
			if (tenantId) {
				const {
					data: {
						imagePath,
						logoImagePath,
						title,
						guideText,
						waitCancelYn,
						followingWaitYn,
						imageFileName,
						logoImageFileName,
						waitTimeDisplayYn,
						name,
					},
				} = await getVWRDataApi({ tenantId, projectId: selectedProjectId, organizationId, id });

				let titleData = title;

				if (title === '' && language === 'ENGLISH') {
					titleData = 'Waiting Needed for Online Access';
				}

				if (title === '' && language === 'KOREAN') {
					titleData = '온라인 접속 대기 안내';
				}

				return {
					id,
					name: name || '',
					imagePathSrc: imagePath !== '' ? `${imagePath}?${Date.now()}` : '',
					imagePathName: imageFileName,
					logoImagePathSrc: logoImagePath !== '' ? `${logoImagePath}?${Date.now()}` : '',
					logoImagePathName: logoImageFileName,
					title: titleData,
					guideText,
					waitCancelYn,
					followingWaitYn,
					waitTimeDisplayYn,
				};
			}
			return {
				...preview,
				id,
				name: '',
			};
			// eslint-disable-next-line
		} catch (error: any) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';

import { useAppDispatch } from 'common/redux/store';
import { setInitialToast } from 'common/context/toastSlice';

import ServiceDomainSettingTitle from 'features/nfBasic/components/ServiceDomainSettingTitle';
import ServiceDomainSettingForm from 'features/nfBasic/components/ServiceDomainSettingForm';
import ServiceDomainSettingModal from 'features/nfBasic/components/ServiceDomainSettingModal';

export default function UpdateServiceDomain() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(setInitialToast());
		};
	}, [dispatch]);

	return (
		<div css={containerSt}>
			<ServiceDomainSettingTitle />
			<ServiceDomainSettingForm />
			<ServiceDomainSettingModal />
		</div>
	);
}

const containerSt = css`
	width: 960px;

	margin: 0 auto;

	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import getPathControlSegmentListApi from 'features/nfControl/api/getPathControlSegmentList.v2.api';
import type { SegmentMetaData } from 'features/nfControl/type/getBasicControlSegmentList.v2.type';

interface GetPathControlSegmentDetailThunkResult {
	meta: SegmentMetaData[];
}

const getPathControlSegmentListThunk = createAsyncThunk<
	GetPathControlSegmentDetailThunkResult,
	void,
	{ state: RootState }
>('get/path/segment/list/v2', async (args, thunkAPI) => {
	const {
		account: { tenantId, organizationId },
		projectInfo: { selectedProjectId },
	} = thunkAPI.getState();

	try {
		const { data } = await getPathControlSegmentListApi({
			tenantId: tenantId as string,
			organizationId,
			projectId: selectedProjectId,
		});

		return { meta: data.slice() };

		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

export default getPathControlSegmentListThunk;

import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { RootState, useAppDispatch } from 'common/redux/store';
import { COGNITO_TOKEN, LANG_PATH, USER_KEY } from 'common/data/constants';
import { getI18nPath } from 'common/utils/languageFunc';
import { getAccountInfoOnPremThunk } from 'common/thunk/getAccountInfo.onPrem.thunk';
import axiosInstance from 'common/utils/axiosInstance';

interface UseOnPremAccountSettingProps {
	noTokenUrl?: string;
}

export default function useOnPremAccountSetting({ noTokenUrl }: UseOnPremAccountSettingProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isLogin, error } = useSelector((state: RootState) => state.account);

	useLayoutEffect(() => {
		const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
		const userKey = window.sessionStorage.getItem(USER_KEY);

		axiosInstance.defaults.headers.Authorization = cognitoToken;
		axiosInstance.defaults.headers.userKey = userKey;

		if (!isLogin) {
			if (window.sessionStorage.getItem(COGNITO_TOKEN) === null) {
				window.location.replace(noTokenUrl ?? `/${getI18nPath()}/on_prem/signin`);
				return;
			}
			(async () => {
				if (error) {
					// eslint-disable-next-line
					console.log(error.error);
				}

				const accountInfoAction = await dispatch(getAccountInfoOnPremThunk());

				const accountInfo = unwrapResult(accountInfoAction);

				const pathList = window.location.pathname.split('/');
				pathList[1] = LANG_PATH[accountInfo.language];
				navigate(pathList.join('/'));
			})();
		}
		// eslint-disable-next-line
	}, [dispatch, error, isLogin]);
}

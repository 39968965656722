/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

// etc
import { backgroundPalette, colorPalette, paletteSDS, themeCss } from 'surf-design-system';

export default function PlanEnterpriseOption() {
	return (
		<div
			css={[
				themeCss.fontSize[16],
				colorPalette.white,
				themeCss.fontWeight.bold,
				backgroundPalette.blue[800],
				borderBottomRight,
				borderTopRight,
			]}
		>
			<div css={[titleBoxSt, borderTopRight]}>Enterprise</div>
			<div css={talkToSalesSt}>Talk to Sales</div>
		</div>
	);
}

const borderTopRight = css`
	border-top-right-radius: 7px;
`;

const borderBottomRight = css`
	border-bottom-right-radius: 7px;
`;

const titleBoxSt = css`
	width: 177.5px;
	height: 48px;

	text-align: center;

	line-height: 48px;
`;

const talkToSalesSt = css`
	width: 177.5px;
	height: 360px;

	border-top: 1px solid ${paletteSDS.blue[700]};

	text-align: center;
	line-height: 380px;
`;

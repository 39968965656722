/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { setInitialToast, setIsProjectLicenseByPassToastVisible } from 'common/context/toastSlice';
import { RootState, useAppDispatch } from 'common/redux/store';

import OnPremProjectManagementTable from 'features/nfSetting/components/OnPremProjectManagementTable';
import ManagementGuide from 'features/nfBasic/components/ManagementGuide';
import { getProjectLicenseThunk } from 'features/license/thunk/getProjectLicense.thunk';

export default function ProjectManagement() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);
	const { deactivatedBySysYn } = useSelector((state: RootState) => state.license.prjLicense);

	useEffect(() => {
		dispatch(getProjectLicenseThunk({ projectId: selectedProjectId }));
		// eslint-disable-next-line
	}, [dispatch, selectedProjectId]);

	useEffect(() => {
		dispatch(setIsProjectLicenseByPassToastVisible(deactivatedBySysYn));
		return () => {
			dispatch(setInitialToast());
		};
	}, [dispatch, deactivatedBySysYn]);

	return (
		<div css={container}>
			<div css={pageTitleSt}>{t('projectMgmt.mgmt')}</div>
			<OnPremProjectManagementTable />
			<ManagementGuide />
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 960px;

	padding: 32px 24px 80px 24px;

	margin: 0 auto;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

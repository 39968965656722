/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'common/redux/store';

import {
	toggleIsWindowRefreshedToastVisible,
	setInitialToast,
	setIsProjectInactiveToastVisible,
} from 'common/context/toastSlice';
import type { ProjectInformation } from 'features/nfSetting/types/getProjectList.v2.type';

import { FETCH_INTERVAL, IS_RELOAD_KEY, IS_RELOAD_TRUE } from 'features/nfBasic/data/constants';

import ProjectAccessMode from 'features/nfControl/components/ProjectAccessMode';
import ProjectStatDataSummary from 'features/nfControl/components/ProjectStatDataSummary';
import PathControlTotalEntryRequestChart from 'features/nfControl/components/PathControlTotalEntryRequestChart';
import PathControlProcessTimeChart from 'features/nfControl/components/PathControlProcessTimeChart';
import PathControlOutflowRateChart from 'features/nfControl/components/PathControlOutflowRateChart';
import PathControlSegmentLegend from 'features/nfControl/components/PathControlSegmentLegend';
import PathControlSegmentBoard from 'features/nfControl/components/PathControlSegmentBoard';
import getPathControlSegmentListThunk from 'features/nfControl/thunk/getPathControlSegmentList.v2.thunk';
import postProjectStatDataThunk from 'features/nfControl/thunk/postProjectStatData.v2.thunk';
import postSegmentStatDataInitialThunk from 'features/nfControl/thunk/postSegmentStatDataInitial.v2.thunk';
import postSegmentStatDataUpdateThunk from 'features/nfControl/thunk/postSegmentStatDataUpdate.v2.thunk';
import { setInitialNfControlData, cleanupPathControlSegmentRawData } from 'features/nfControl/context/nfControlSlice';
import ProjectSummaryClock from 'features/nfControl/components/ProjectSummaryClock';

let fetchingInterval: NodeJS.Timer | undefined;
let cleanupInterval: NodeJS.Timer | undefined;

export default function NetfunnelPathControlDefaultView() {
	const dispatch = useAppDispatch();

	const { isMetaLoaded, isValueLoaded } = useSelector((state: RootState) => state.nfControl.segment.path);

	const activatedYn = useSelector(
		(state: RootState) =>
			(state.projectInfo.projectList.find(el => el.id === state.projectInfo.selectedProjectId) as ProjectInformation)
				.activatedYn,
		shallowEqual
	);

	useEffect(() => {
		if (!isMetaLoaded) {
			dispatch(getPathControlSegmentListThunk());
			return undefined;
		}

		if (isMetaLoaded) {
			if (sessionStorage.getItem(IS_RELOAD_KEY) === IS_RELOAD_TRUE) {
				dispatch(toggleIsWindowRefreshedToastVisible());
				sessionStorage.removeItem(IS_RELOAD_KEY);
			}

			(async () => {
				await dispatch(postSegmentStatDataInitialThunk({ isPathControl: true }));
				await dispatch(postProjectStatDataThunk({ isPathControl: true }));
			})();

			fetchingInterval = setInterval(() => {
				dispatch(postProjectStatDataThunk({ isPathControl: true }));
				dispatch(postSegmentStatDataUpdateThunk({ isPathControl: true }));
			}, FETCH_INTERVAL);
			return () => clearInterval(fetchingInterval);
		}

		return undefined;
	}, [dispatch, isMetaLoaded]);

	useEffect(() => {
		if (isValueLoaded) {
			cleanupInterval = setInterval(() => {
				dispatch(cleanupPathControlSegmentRawData());
			}, 120000);

			return () => clearInterval(cleanupInterval);
		}

		return undefined;
	}, [dispatch, isValueLoaded]);

	useEffect(() => {
		dispatch(setIsProjectInactiveToastVisible(!activatedYn));
	}, [dispatch, activatedYn]);

	useEffect(() => {
		return () => {
			dispatch(setInitialNfControlData());
			dispatch(setInitialToast());

			clearInterval(fetchingInterval);
			clearInterval(cleanupInterval);
		};
	}, [dispatch]);

	if (!isMetaLoaded) return <div />;

	return (
		<div css={containerCss}>
			<div css={upperContainerCss}>
				<div css={projectSummaryWrapperCss}>
					<ProjectAccessMode />
					<ProjectStatDataSummary />
					<ProjectSummaryClock />
				</div>
				<div css={chartScrollSt}>
					<div css={chartContainerCss}>
						<PathControlTotalEntryRequestChart />
						<PathControlProcessTimeChart />
						<PathControlOutflowRateChart />
					</div>
				</div>
			</div>
			<div css={lowerContainerCss}>
				<PathControlSegmentLegend />
				<PathControlSegmentBoard />
			</div>
		</div>
	);
}

const containerCss = css`
	display: flex;
	flex-direction: column;
	gap: 8px;

	width: 100%;
`;

const upperContainerCss = css`
	flex: none;

	display: flex;
	gap: 8px;
	height: 400px;
`;

const projectSummaryWrapperCss = css`
	display: flex;
	flex-direction: column;
	width: 265px;

	height: 400px;

	gap: 8px;
`;

const chartScrollSt = css`
	width: 100%;
	height: 400px;

	overflow-x: auto;
	overflow-y: hidden;
`;

const chartContainerCss = css`
	display: flex;
	gap: 8px;

	width: 100%;
	min-width: 1516px;
	height: 100%;
`;

const lowerContainerCss = css`
	flex: none;

	display: flex;
	position: relative;
	width: 100%;
	height: 448px;
	//height: 408px;

	margin-bottom: 40px;

	z-index: 0;
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';

import ChangePasswordTitle from 'features/signin/components/ChangePasswordTitle';
import ChangePasswordForm from 'features/signin/components/ChangePasswordForm';
import BackToSignin from 'features/signin/components/BackToSignin';
import ChangePasswordModal from 'features/signin/components/ChangePasswordModal';

export default function ChangePasswordSigninPage() {
	return (
		<div css={signinContainer}>
			<div css={signinWrapSt}>
				<ChangePasswordTitle />
				<ChangePasswordForm />
				<BackToSignin />
				<ChangePasswordModal />
			</div>
		</div>
	);
}

const signinContainer = css`
	display: flex;
	justify-content: center;

	width: 100%;
	height: fit-content;

	padding: 5rem;
`;

const signinWrapSt = css`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	width: 25rem;
`;

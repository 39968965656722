/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { paletteSDS, DecreaseIcon, themeSDS } from 'surf-design-system';

import { calcProcessTimeRange } from 'features/nfBasic/utils/processTimeRangeFunc';

interface SegmentProcessTimeRangeGaugeProps {
	normalRangeStartValue: string;
	normalRangeEndValue: string;
}

export default function SegmentProcessTimeRangeGauge({
	normalRangeStartValue,
	normalRangeEndValue,
}: SegmentProcessTimeRangeGaugeProps) {
	const [startNumber, setStartNumber] = useState(Number(normalRangeStartValue));
	const [endNumber, setEndNumber] = useState(Number(normalRangeEndValue));

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (Number(normalRangeStartValue) < Number(normalRangeEndValue)) {
				setStartNumber(Number(normalRangeStartValue));
				setEndNumber(Number(normalRangeEndValue));
			}
		}, 100);

		return () => {
			clearTimeout(timeout);
		};
	}, [normalRangeEndValue, normalRangeStartValue]);

	const { good, normal, bad } = calcProcessTimeRange(
		Math.floor(startNumber * 10) / 10,
		Math.floor(endNumber * 10) / 10
	);

	return (
		<div css={gaugeContainer}>
			<div css={leftPointerBox(good)}>
				<div css={pointerSt}>{Math.floor(startNumber * 10) / 10}</div>
				<DecreaseIcon size="xSmall" fill={paletteSDS.categoryColor.yellow} cursor="default" />
			</div>
			<div css={rightPointerBox(normal + good)}>
				<div css={pointerSt}>{Math.floor(endNumber * 10) / 10}</div>
				<DecreaseIcon size="xSmall" fill={paletteSDS.categoryColor.redOrange} cursor="default" />
			</div>
			<div />
			<div css={barContainer}>
				<div css={goodBarSt(good)} />
				<div css={normalBarSt(good, normal)} />
				<div css={badBarSt(bad)} />
			</div>
		</div>
	);
}

const gaugeContainer = css`
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 38px;
`;

const barContainer = css`
	display: flex;
	width: 100%;
`;

const goodBarSt = (ratio: number) => css`
	width: ${ratio}%;
	height: 10px;

	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;

	background: ${paletteSDS.categoryColor.mentis};

	transition: width 0.3s ease-in-out;
`;

const normalBarSt = (goodRatio: number, normalRatio: number) => css`
	width: ${normalRatio}%;
	height: 10px;

	background: ${paletteSDS.categoryColor.yellow};

	transition: width 0.3s ease-in-out;

	border-bottom-right-radius: ${normalRatio + goodRatio === 100 && '5px'};
	border-top-right-radius: ${normalRatio + goodRatio === 100 && '5px'};
`;

const badBarSt = (ratio: number) => css`
	width: ${ratio}%;
	height: 10px;

	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;

	background: ${paletteSDS.categoryColor.redOrange};

	transition: width 0.3s ease-in-out;
`;

const leftPointerBox = (ratio: number) => css`
	position: absolute;

	left: calc(${ratio}% - 12px);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	width: 24px;
	height: 28px;
`;

const rightPointerBox = (ratio: number) => css`
	position: absolute;

	left: calc(${ratio}% - 12px);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	width: 24px;
	height: 28px;
`;

const pointerSt = css`
	width: 100%;
	height: 12px;

	font-size: ${themeSDS.fontSize['12']};
	color: ${paletteSDS.gray['700']};
	font-weight: ${themeSDS.fontWeight.medium};

	text-align: center;
`;

import { DEFAULT_IMG_URL } from 'common/data/constants';

export const X_AXIS_NUM = 21;
export const X_AXIS_INTERVAL_TIME = 3;
export const X_AXIS_VALUE_INTERVAL = 1;

export const maxWaitTicketY = 50;
export const intervalWaitTicketY = 5;
export const tickamountWaitTicketY = 11;
export const waitTicketDecimalInFloat = 1;
export const waitTicketMaxYratio = 1.5;

export const maxOutflowRateY = 100;
export const intervalOutflowRateY = 20;
export const tickamountOutflowRateY = 5;
export const outflowRateDecimalInFloat = 0;

export const maxProcessTimeY = 3;
export const intervalProcessTimeY = 0.3;
export const tickamountProcessTimeY = 11;
export const processTimeDecimalInFloat = 1;
export const processTimeMaxYratio = 1.5;

export const SEG_TIME_RANGE = 60;
export const PRJ_TIME_RANGE = 3;

export const MAX_INFLOW_LENGTH = 8;
export const MAX_SEG_TITLE_LENGTH = 50;

export const TRACK_STROKE = 4;

export const MAX_GAUGE = 120;
export const MIN_GAUGE = 0;

export const BOUNDARY_FAST = 60;
export const BOUNDARY_MED = 120;

export const DEFAULT_TRACK_STROKE = 25;
export const DEFAULT_TRACK_PADDING = 0;

export const FETCH_INTERVAL = 3000;

export const INTERVAL_BEGIN = 1;
export const INTERVAL_END = 4;

export const IS_RELOAD_KEY = 'isReload';
export const IS_RELOAD_TRUE = 'true';

export const DAYS_BEFORE_FREE_TRIAL_END = -2;

export const MOCK_MEMBER_LIST = [
	{
		userKey: 'a',
		imageUrl: DEFAULT_IMG_URL,
		name: 'Jane Doe',
		email: 'janedoe@stclab.com',
	},
	{
		userKey: 'b',
		imageUrl: DEFAULT_IMG_URL,
		name: 'Root',
		email: 'root@stclab.com',
	},
	{
		userKey: 'c',
		imageUrl: 'https://user-images.githubusercontent.com/107383943/216269239-9626b38e-f4f0-463a-b119-5240e9fc4e5e.png',
		name: 'noah',
		email: 'noah@stclab.com',
	},
	{
		userKey: 'd',
		imageUrl: DEFAULT_IMG_URL,
		name: 'bryan',
		email: 'bryan@stclab.com',
	},
	{
		userKey: 'e',
		imageUrl: 'https://user-images.githubusercontent.com/107383943/216269260-462572ea-d7b2-474f-b632-701a568ee482.png',
		name: 'finn',
		email: 'finn@stclab.com',
	},
	{
		userKey: 'dfsdaf',
		imageUrl: 'https://user-images.githubusercontent.com/107383943/216268673-c1f28801-4f17-4ae3-ab5c-cc6f956f3b77.png',
		name: 'jay',
		email: 'jay@stclab.com',
	},
	{
		userKey: 'dasdfasdffsdaf',
		imageUrl: 'https://user-images.githubusercontent.com/107383943/216268721-cab11a06-7ed5-4f03-8198-98e6e36ec552.png',
		name: 'lucia',
		email: 'lucia@stclab.com',
	},
];

export const MOCK_VWR_LIST = [
	{ value: '1', name: 'waitingRoom1' },
	{ value: '2', name: 'waitingRoom2' },
	{ value: '3', name: 'waitingRoom3' },
];

import axios, { AxiosError } from 'axios';
import { COGNITO_TOKEN, USER_KEY } from 'common/data/constants';

import { GetOrganizationAddressApiResponse } from '../types/getOrganizationAddress.type';

export interface GetOrganizationAddressApiProps {
	organizationId: number;
}

export default async function getOrganizationAddressApi({ organizationId }: GetOrganizationAddressApiProps) {
	const cognitoToken = window.sessionStorage.getItem(COGNITO_TOKEN);
	const userKey = window.sessionStorage.getItem(USER_KEY);

	if (cognitoToken && userKey) {
		const { data } = await axios.get<GetOrganizationAddressApiResponse>(
			`${process.env.REACT_APP_BASE_URL}/v1/organization/${organizationId}/address`,
			{
				headers: {
					userKey,
					organizationId,
					Authorization: cognitoToken,
				},
			}
		);
		return data;
	}
	throw AxiosError.ERR_BAD_REQUEST;
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { paletteSDS, themeSDS } from 'surf-design-system';

interface DividerWithTxtProps {
	value?: string;
	textColor?: string;
	dividerColor?: string;
}

export default function DividerWithTxt({
	value,
	dividerColor = paletteSDS.gray['300'],
	textColor,
}: DividerWithTxtProps) {
	return (
		<div css={containerSt}>
			<div css={dividerSt(dividerColor)} />
			{value && <div css={txtSt(textColor)}>{value}</div>}
			<div css={dividerSt(dividerColor)} />
		</div>
	);
}

const containerSt = css`
	display: flex;
	align-items: center;

	width: 100%;
`;

const dividerSt = (dividerColor: string | undefined) => css`
	height: 0.0625rem;
	width: 100%;

	background: ${dividerColor};
`;

const txtSt = (textColor: string | undefined) => css`
	padding: 0rem 1rem;

	font-size: ${themeSDS.fontSize[14]};
	text-align: center;

	color: ${textColor};

	white-space: nowrap;
`;

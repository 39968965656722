/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ChevronLeftIcon, EditIcon, paletteSDS, themeSDS } from 'surf-design-system';

import { RootState, useAppDispatch } from 'common/redux/store';
import { setInitialToast } from 'common/context/toastSlice';
import DividerWithTxt from 'common/components/DividerWithTxt';
import { resetVWRData, toggleIsUpdateVWRNameVisible } from 'common/context/modalSlice';
import { LANG_PATH } from 'common/data/constants';
import { getProjectModeUrl } from 'common/utils/projectFunc';

import VWRPreview from 'features/vwr/components/VWRPreview';
import { setInitializeVWRData } from 'features/vwr/context/vwrSlice.v2';
import VWRNameModal from 'features/vwr/components/VWRNameModal';
import { getVWRPageNumberThunk } from 'features/vwr/thunk/getVWRPageNumber.v2.thunk';
import VWRCreateForm from 'features/vwr/components/VWRCreateForm';

export default function VWRCreate() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { language } = useSelector((state: RootState) => state.account);

	const { selectedProjectId } = useSelector((state: RootState) => state.projectInfo);

	const { isVWRNameModalVisible, name } = useSelector((state: RootState) => state.modal.vwr);

	const editIconClickHandler = () => {
		dispatch(toggleIsUpdateVWRNameVisible());
	};

	const listButtonClickHandler = () => {
		navigate(`/${LANG_PATH[language]}/${getProjectModeUrl()}console/product/nf/${selectedProjectId}/settings/vwr`);
	};

	useLayoutEffect(() => {
		dispatch(getVWRPageNumberThunk());

		return () => {
			dispatch(setInitializeVWRData());
			dispatch(setInitialToast());
			dispatch(resetVWRData());
		};
	}, [dispatch, t]);

	return (
		<div css={container}>
			<div css={gap16pxColumn}>
				<div css={headerBox}>
					<div css={titleBox}>
						<div css={titleSt}>{name}</div>
						<EditIcon size="xSmall" fill={paletteSDS.blue['700']} onClick={editIconClickHandler} />
					</div>
					<Button
						size="small"
						icon={<ChevronLeftIcon fill={paletteSDS.blue['700']} size="xSmall" />}
						iconPosition="left"
						onClick={listButtonClickHandler}
						mould="secondary"
					>
						{t('vwr.list')}
					</Button>
				</div>
				<DividerWithTxt />
			</div>
			<div css={vwrBox}>
				<VWRPreview isCreate />
				<VWRCreateForm />
			</div>
			{isVWRNameModalVisible ? <VWRNameModal mode="create" /> : null}
		</div>
	);
}

const container = css`
	display: flex;
	flex-direction: column;
	gap: 32px;

	width: 960px;

	margin: 0 auto;

	padding: 32px 24px 80px;
`;

const gap16pxColumn = css`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const headerBox = css`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

const titleBox = css`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const titleSt = css`
	color: ${paletteSDS.gray['900']};
	font-weight: ${themeSDS.fontWeight.bold};
	font-size: ${themeSDS.fontSize['20']};

	line-height: 140%;
`;

const vwrBox = css`
	display: flex;
	gap: 40px;
	align-items: flex-start;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'common/redux/store';

import { getSubscriptionListThunk } from 'features/plan/thunk/getSubscriptionList.v2.thunk';
import postSubscriptionApi from 'features/plan/api/postSubscription.api';

export interface PostSubscriptionThunkProps {
	planId: number;
	version: number;
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
}

export interface PostSubscriptionThunkResult {
	planId: number;
	version: number;
	country: string;
	streetAddress: string;
	streetAddressDetail: string;
	city: string;
	region: string;
	postalCode: string;
	vat: string;
}

export const postSubscriptionThunk = createAsyncThunk<
	PostSubscriptionThunkResult,
	PostSubscriptionThunkProps,
	{ state: RootState }
>('subscription/post', async (args, thunkAPI) => {
	const {
		account: { organizationId },
	} = thunkAPI.getState();

	const { planId, version, country, streetAddress, streetAddressDetail, city, region, postalCode, vat } = args;

	try {
		await postSubscriptionApi({
			organizationId,
			planId,
			version,
			country,
			streetAddress,
			streetAddressDetail,
			city,
			region,
			postalCode,
			vat,
		});

		thunkAPI.dispatch(getSubscriptionListThunk());

		return { planId, version, country, streetAddress, streetAddressDetail, city, region, postalCode, vat };
		// eslint-disable-next-line
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
});

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'common/redux/store';

import postOauthTokenApi from 'common/api/postOauthToken.api';
// import { isAxiosError } from 'common/api/isAxiosError';
// import type { PostOauthTokenApiError } from 'common/types/postOauthToken.type';

import getOauthUserInfoApi from 'features/signup/api/getOauthUserInfo.api';
import postOauthUserInfoApi from 'features/signup/api/postOauthUserInfo.api';

import { COGNITO_REFRESH_TOKEN, COGNITO_TOKEN, USER_KEY } from 'common/data/constants';
import {
	toggleIsOauthAlreadyExistUserModalVisible,
	toggleIsOauthBadRequestModalVisible,
	// toggleIsSignupBlockModalVisible,
} from 'common/context/modalSlice';
import { getBrowserLanguage } from 'common/utils/languageFunc';

export interface GoogleOauthSignupThunkProps {
	code: string;
	region: string;
	company: string;
	country: string;
}

export interface GoogleOauthSignupThunkResult {
	isSuccess: boolean;
}

export const googleOauthSignupThunk = createAsyncThunk<
	GoogleOauthSignupThunkResult,
	GoogleOauthSignupThunkProps,
	{ state: RootState }
>('google/signup', async (args, thunkAPI) => {
	const { code, region, company, country } = args;

	try {
		const oauthToken = await postOauthTokenApi({ code });

		const { data: oauthUserInfo } = await getOauthUserInfoApi({ accessToken: oauthToken.id_token });

		await postOauthUserInfoApi({
			idToken: oauthToken.id_token,
			timezone: String(-new Date().getTimezoneOffset()),
			language: getBrowserLanguage(),
			region,
			company,
			country,
		});

		sessionStorage.setItem(COGNITO_TOKEN, oauthToken.id_token);
		sessionStorage.setItem(COGNITO_REFRESH_TOKEN, oauthToken.refresh_token);
		sessionStorage.setItem(USER_KEY, oauthUserInfo.userKey);

		return { isSuccess: true };

		// eslint-disable-next-line
	} catch (error: any) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.status &&
			error.response.data.status === 'USER_ALREADY_EXIST'
		) {
			thunkAPI.dispatch(toggleIsOauthAlreadyExistUserModalVisible());

			return { isSuccess: false };
		}

		// if (isAxiosError<PostOauthTokenApiError>(error) && error.response?.data.error.includes('LD1000')) {
		// 	thunkAPI.dispatch(toggleIsSignupBlockModalVisible());
		// 	return { isSuccess: false };
		// }

		thunkAPI.dispatch(toggleIsOauthBadRequestModalVisible());
		return { isSuccess: false };
	}
});

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS } from 'surf-design-system';

interface MainDomainDescriptionProps {
	gap?: number;
	padding?: string;
	left?: number;
}

export default function MainDomainDescription({
	gap = 24,
	padding = '24px 64px 16px',
	left = 192,
}: MainDomainDescriptionProps) {
	const { t } = useTranslation();

	return (
		<div css={container(gap)}>
			<div css={guideSt}>{t('AddDomain.mainDomainGuide')}</div>
			<div css={contentContainer(padding)}>
				<div css={contentBox}>
					<div css={contentSt}>https://www.&nbsp;</div>
					<div css={contentBlueSt}>example.com</div>
					<div css={contentSt}>&nbsp;/path/</div>
				</div>
				<div css={mainDomainSt(left)}>{t('AddDomain.mainDomain')}</div>
			</div>
		</div>
	);
}

const container = (gap: number) => css`
	max-width: 400px;

	display: flex;
	flex-direction: column;
	gap: ${gap}px;
`;

const guideSt = css`
	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 140%;
	color: ${paletteSDS.gray[900]};
`;

const contentContainer = (padding: string) => css`
	position: relative;

	padding: ${padding};

	height: 84px;

	background: ${paletteSDS.gray['50']};
	border: 1px solid ${paletteSDS.gray['300']};
	border-radius: 8px;
`;

const contentBox = css`
	display: flex;
	gap: 0;

	align-items: center;
`;

const contentSt = css`
	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 100%;
	color: ${paletteSDS.gray[700]};
`;

const contentBlueSt = css`
	display: flex;
	align-items: center;

	height: 26px;

	padding: 4px 8px 6px 8px;

	background: ${paletteSDS.categoryColor.blue};
	border-radius: 2px;

	font-weight: ${themeSDS.fontWeight.regular};
	font-size: ${themeSDS.fontSize[16]};
	line-height: 100%;
	color: ${paletteSDS.gray[50]};
`;

const mainDomainSt = (left: number) => css`
	position: absolute;

	left: ${left}px;
	bottom: 15px;

	font-weight: ${themeSDS.fontWeight.semiBold};
	font-size: ${themeSDS.fontSize[12]};
	line-height: 140%;
	color: ${paletteSDS.blue[400]};
`;

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { useTranslation } from 'react-i18next';

import { paletteSDS, themeSDS, Textinput, Button } from 'surf-design-system';

import { useAppDispatch } from 'common/redux/store';
import { toggleIsChangePasswordModalVisible } from 'common/context/modalSlice';
import { getI18nPath } from 'common/utils/languageFunc';
import PasswordType from 'common/components/PasswordType';

import ChangePasswordModal from 'features/signin/components/ChangePasswordModal';

import {
	changePasswordFormReducer,
	initialChangePasswordForm,
	UPDATE_CURRENT_PASSWORD,
	UPDATE_PASSWORD,
	UPDATE_CURRENT_PW_HELP_TEXT,
	UPDATE_VERIFY_PASSWORD,
	VALIDATE_CURRENT_PASSWORD,
	VALIDATE_PASSWORD,
	VALIDATE_VERIFY_PASSWORD,
} from 'features/setting/context/changePasswordForm.reducer';

export default function ChangePassword() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [changePasswordFormData, setChangePasswordFormData] = useReducer(
		changePasswordFormReducer,
		initialChangePasswordForm
	);
	const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
	const [isLoading, setIsLoading] = useState(false);

	const {
		password,
		isPasswordChanged,
		isPasswordValid,
		isVerifyPasswordChanged,
		isVerifyPasswordValid,
		verifyPassword,
		verifyPasswordHelpText,
		verifyPasswordValidation,
		passwordHelpText,
		passwordValidation,
		currentPassword,
		currentPasswordHelpText,
		currentPasswordValidation,
		isCurrentPasswordChanged,
		isCurrentPasswordValid,
	} = changePasswordFormData;

	const changePasswordFormSubmitHandler: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault();

		setIsLoading(true);

		const cognitoUserPool = new CognitoUserPool({
			UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
			ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID as string,
		});

		const cognitoUser = cognitoUserPool.getCurrentUser();

		if (cognitoUser) {
			cognitoUser.getSession((err: Error) => {
				if (err) {
					navigate(`/${getI18nPath()}/signin`);
				}

				cognitoUser.changePassword(currentPassword, password, (changeError, result) => {
					if (result === 'SUCCESS') {
						setIsLoading(false);
						dispatch(toggleIsChangePasswordModalVisible());
						return;
					}

					if (changeError && changeError.message === 'Incorrect username or password.') {
						setChangePasswordFormData({ type: UPDATE_CURRENT_PW_HELP_TEXT, payload: 'resetPassword.error.incorrect' });
					}

					setIsLoading(false);
				});
			});
		}
	};

	const inputLabelClickHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
		e.stopPropagation();

		setPasswordType(prevType => {
			if (prevType === 'password') {
				return 'text';
			}
			return 'password';
		});
	};

	const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_PASSWORD, payload: e.target.value });
	};

	const currentPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_CURRENT_PASSWORD, payload: e.target.value });
	};

	const verifyPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
		setChangePasswordFormData({ type: UPDATE_VERIFY_PASSWORD, payload: e.target.value });
	};

	const passwordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_PASSWORD });
	};

	const currentPasswordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_CURRENT_PASSWORD });
	};

	const verifyPasswordBlurHandler = () => {
		setChangePasswordFormData({ type: VALIDATE_VERIFY_PASSWORD });
	};

	return (
		<form css={containerSt} onSubmit={changePasswordFormSubmitHandler}>
			<div css={pageTitleSt}>{t('personalSettings.changePwd')}</div>
			<div css={boxCss}>
				<Textinput
					label={<PasswordType passwordType={passwordType} onClick={inputLabelClickHandler} />}
					valueType={passwordType}
					value={currentPassword}
					validation={isCurrentPasswordChanged ? currentPasswordValidation : 'none'}
					helpText={isCurrentPasswordChanged && !isCurrentPasswordValid && t(currentPasswordHelpText)}
					onChange={currentPasswordChangeHandler}
					onBlur={currentPasswordBlurHandler}
					maxLength={20}
					showIcon
				/>
				<Textinput
					value={password}
					label={t('personalSettings.newPwd')}
					validation={isPasswordChanged ? passwordValidation : 'none'}
					helpText={isPasswordChanged && !isPasswordValid && t(passwordHelpText)}
					onChange={passwordChangeHandler}
					onBlur={passwordBlurHandler}
					maxLength={20}
					showIcon
					valueType={passwordType}
				/>
				<Textinput
					valueType={passwordType}
					label={t('resetPassword.verifyPwd')}
					value={verifyPassword}
					validation={isVerifyPasswordChanged ? verifyPasswordValidation : 'none'}
					helpText={isVerifyPasswordChanged && !isVerifyPasswordValid && t(verifyPasswordHelpText)}
					onChange={verifyPasswordChangeHandler}
					onBlur={verifyPasswordBlurHandler}
					showIcon
				/>
			</div>
			<Button
				width="400px"
				type="submit"
				disabled={!isPasswordValid || !isVerifyPasswordValid || !isCurrentPasswordValid || isLoading}
			>
				{t('resetPassword.resetPwd')}
			</Button>
			<ChangePasswordModal />
		</form>
	);
}
const containerSt = css`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const boxCss = css`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 400px;
`;

const pageTitleSt = css`
	height: 1.9375rem;
	line-height: 1.9375rem;
	margin-bottom: 1.5rem;

	color: ${paletteSDS.gray[900]};
	font-size: ${themeSDS.fontSize[24]};
	font-weight: ${themeSDS.fontWeight.bold};

	letter-spacing: -0.008em;
`;

import { ContextType, useCallback, useContext, useEffect, useState } from 'react';
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { Blocker, History, Transition } from 'history';

interface Navigator extends BaseNavigator {
	block: History['block'];
}

type ExtendNavigator = BaseNavigator & Pick<History, 'block'>;

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
	navigator: Navigator;
};

function useBlocker(blocker: Blocker, when = true) {
	const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) {
			return undefined;
		}

		const unblock = (navigator as ExtendNavigator).block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
}

export default function usePrompt(when: boolean) {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<Transition | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
	}, []);

	const handleBlockedNavigation = useCallback(
		(tx: Transition) => {
			if (!confirmedNavigation && tx.location.pathname !== location.pathname) {
				setShowPrompt(true);
				setLastLocation(tx);
				return false;
			}
			return true;
		},
		[confirmedNavigation, location.pathname]
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname);
		}
		// eslint-disable-next-line
	}, [confirmedNavigation, lastLocation]);

	useBlocker(handleBlockedNavigation, when);

	return { showPrompt, confirmNavigation, cancelNavigation };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import type { LicenseData } from 'features/license/types/getLicenseList.type';
import type { LicenseUsageContent } from 'features/license/types/getLicenseProjectUsage.type';
import { getLicenseUsageThunk } from 'features/license/thunk/getLicenseUsage.thunk';
import { getLicenseListThunk } from 'features/license/thunk/getLicenseList.thunk';
import { getLicenseDetailThunk } from 'features/license/thunk/getLicenseDetail.thunk';
import { ProjectLicenseStatus } from 'features/license/types/getProjectLicense.type';
import { getProjectLicenseThunk } from 'features/license/thunk/getProjectLicense.thunk';

export interface LicenseInfoSliceData {
	licenseList: LicenseData[];
	isLoaded: boolean;
	// eslint-disable-next-line
	error: any;
	usage: {
		isLoading: boolean;
		isLoaded: boolean;
		content: LicenseUsageContent[];
		totalPages: number;
		selectedPage: number;
		searchStartDate: number;
		searchEndDate: number;
		selectedId: number;
	};
	detail: LicenseData;
	prjLicense: ProjectLicenseStatus;
}

const initialState: LicenseInfoSliceData = {
	licenseList: [],
	isLoaded: false,
	error: null,
	usage: {
		isLoading: false,
		totalPages: 1,
		selectedPage: 1,
		content: [],
		searchEndDate: 1,
		searchStartDate: 1,
		selectedId: 0,
		isLoaded: false,
	},
	detail: {
		projectId: 1,
		projectName: '',
		maxDailyPeakCcuStatus: 'NORMAL',
		licensedPeakCcu: 0,
		licensedSegCount: 0,
		licensedTotalExceededPeakCcu: 0,
		licenseExpiredAt: '',
		licenseExpiredYn: false,
		licenseReachedYn: false,
		maxDailyPeakCcu: 0,
		customerServiceDomain: '',
		createdAt: '',
		totalExceededLicPeakCcu: 0,
		segCount: 0,
	},
	prjLicense: {
		licensedSegCount: 0,
		activatedYn: true,
		deactivatedBySysYn: false,
		licenseReachedYn: false,
	},
};

const licenseSlice = createSlice({
	name: 'license',
	initialState,
	reducers: {
		setInitialLicenseData: () => {
			return { ...initialState };
		},
		resetLicenseError: state => {
			return { ...state, error: null };
		},
		setInitializeLicenseUsageData: (state, action: PayloadAction<number>) => {
			return {
				...state,
				usage: {
					...state.usage,
					selectedId: action.payload,
				},
			};
		},
		setSearchStartDate: (state, action: PayloadAction<number>) => {
			return { ...state, usage: { ...state.usage, searchStartDate: action.payload } };
		},
		setSearchEndDate: (state, action: PayloadAction<number>) => {
			return { ...state, usage: { ...state.usage, searchEndDate: action.payload } };
		},
		setSelectedPage: (state, action: PayloadAction<number>) => {
			return { ...state, usage: { ...state.usage, selectedPage: action.payload } };
		},
	},

	extraReducers(builder) {
		builder.addCase(getLicenseListThunk.fulfilled, (state, action) => {
			return { ...state, error: null, isLoaded: true, licenseList: [...action.payload.license] };
		});

		builder.addCase(getLicenseListThunk.rejected, state => {
			return { ...state, isLoaded: true };
		});

		builder.addCase(getLicenseUsageThunk.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				error: null,
				usage: {
					...state.usage,
					content: [...action.payload.content],
					totalPages: action.payload.totalPages,
					isLoaded: true,
				},
			};
		});

		builder.addCase(getLicenseUsageThunk.rejected, state => {
			return { ...state, usage: { ...state.usage, isLoaded: true } };
		});

		builder.addCase(getLicenseDetailThunk.fulfilled, (state, action) => {
			const initialStartDate = moment(action.payload.createdAt).utcOffset('+UTC09:00').startOf('day').valueOf();

			const initialEndDate = moment().utcOffset('+UTC09:00').startOf('day').valueOf();

			return {
				...state,
				isLoaded: true,
				error: null,
				detail: { ...action.payload },
				usage: {
					...state.usage,
					selectedId: action.meta.arg.licenseId,
					searchStartDate: initialStartDate,
					searchEndDate: initialEndDate,
				},
			};
		});

		builder.addCase(getLicenseDetailThunk.rejected, (state, action) => {
			const initialDate = moment().utcOffset('+UTC09:00').startOf('day').valueOf();
			return {
				...state,
				isLoaded: true,
				error: action.payload,
				usage: {
					...state.usage,
					selectedId: action.meta.arg.licenseId,
					searchEndDate: initialDate,
					searchStartDate: initialDate,
				},
			};
		});
		builder.addCase(getProjectLicenseThunk.fulfilled, (state, action) => {
			return { ...state, error: null, prjLicense: action.payload || state.prjLicense };
		});

		builder.addCase(getProjectLicenseThunk.rejected, (state, action) => {
			return { ...state, error: action.payload };
		});
	},
});

export const {
	setInitialLicenseData,
	resetLicenseError,
	setSearchStartDate,
	setSearchEndDate,
	setInitializeLicenseUsageData,
	setSelectedPage,
} = licenseSlice.actions;
export default licenseSlice.reducer;
